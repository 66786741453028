define("coffeecup/templates/components/project-type-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N+Rqy18B",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"label\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/project-type-indicator.hbs"
    }
  });

  _exports.default = _default;
});