define("coffeecup/templates/components/manage-table/cells/used-budget-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CqsRSlt2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isProject\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,0,[\"projectValue\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,0,[\"clientValue\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/used-budget-percentage.hbs"
    }
  });

  _exports.default = _default;
});