define("coffeecup/templates/components/manage-table/manage-table-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FifIevpn",
    "block": "{\"symbols\":[\"@config\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"manage-table-cell \",[32,0,[\"classes\"]]]]],[15,5,[32,0,[\"safeWidth\"]]],[12],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[\"manage-table-cell-contents \",[32,1,[\"contentClasses\"]]]]],[12],[2,\"\\n    \"],[1,[32,0,[\"value\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"secondaryValue\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[\"manage-table-cell-contents manage-table-cell-secondary-content \",[32,1,[\"secondaryContentClasses\"]]]]],[12],[2,\"\\n      \"],[1,[32,0,[\"secondaryValue\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/manage-table-cell.hbs"
    }
  });

  _exports.default = _default;
});