define("coffeecup/templates/components/manage-table/cells/project-tracked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ATSYPck",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"classes\"]]],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"manage-table-cell-contents\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"amountSpent\"]],false,true],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"project-board-fmt\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-tracked.hbs"
    }
  });

  _exports.default = _default;
});