define("coffeecup/models/notice-period/unit", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    i18n: Ember.inject.service(),
    label: Ember.computed('i18n.locale', function () {
      return this.get('i18n').t(this.get('id'));
    })
  });

  _exports.default = _default;
});