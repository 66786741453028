define("coffeecup/mixins/___lazy-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LazyFormMixin = Ember.Mixin.create({
    // @see: http://stackoverflow.com/questions/21793072/whats-the-right-way-of-doing-manual-form-data-saving-with-ember-js
    proxy: {},
    rollbackData: {},
    setUnknownProperty: function setUnknownProperty(key, value) {
      this.proxy[key] = value;
    },
    flush: function flush() {
      for (var key in this.proxy) {
        this.rollbackData[key] = this.get(key);
        this.set('model.' + key, this.proxy[key]);
      }
    },
    rollback: function rollback() {
      for (var key in this.rollbackData) {
        this.set('model.' + key, this.rollbackData[key]);
      } //for (var key in this.rollbackData) {
      //  this.set('model.' + key, this.rollbackData[key]);
      //}

    },
    actions: {
      save: function save() {
        this.flush();
        return true;
      }
    }
  });
  var _default = LazyFormMixin;
  _exports.default = _default;
});