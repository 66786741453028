define("coffeecup/templates/components/dashboard/planning-actual-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CQoa1uze",
    "block": "{\"symbols\":[\"__arg0\",\"__arg0\",\"@dashboardModule\",\"@isDashboardPreview\"],\"statements\":[[8,\"dashboard/base-module\",[],[[\"@chart\",\"@isDashboardPreview\",\"@namedBlocksInfo\"],[true,[32,4],[30,[36,0],null,[[\"body\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"body\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"planning-actual\",[],[[\"@chartId\",\"@isDashboard\",\"@isDashboardPreview\",\"@dashboardModule\",\"@namedBlocksInfo\"],[[32,3,[\"idAsInt\"]],true,[32,4],[32,3],[30,[36,0],null,[[\"dropdownMenu\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"dropdownMenu\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"ul\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n          \"],[8,\"dropdown-option\",[[16,\"onClick\",[32,0,[\"duplicateModule\"]]]],[[\"@label\",\"@iconName\"],[\"duplicate\",\"file-plus2\"]],null],[2,\"\\n          \"],[8,\"dropdown-option\",[[16,\"onClick\",[32,0,[\"removeModule\"]]]],[[\"@label\",\"@iconName\"],[\"remove\",\"bin\"]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"-is-named-block-invocation\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/planning-actual-dashboard.hbs"
    }
  });

  _exports.default = _default;
});