define("coffeecup/templates/account/integrations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ge1WfJT",
    "block": "{\"symbols\":[\"integration\"],\"statements\":[[10,\"header\"],[14,0,\"navbar-top\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center full-width header\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"integrations\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"section\"],[14,0,\"form-vertical scrollable has-header\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"cc-card-container wrap\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"integrations\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"cc-card\",[],[[\"@content\",\"@onClick\"],[[32,1],[32,0,[\"viewDetails\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/account/integrations.hbs"
    }
  });

  _exports.default = _default;
});