define("coffeecup/routes/analytics/time-entries/batch-update", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AnalyticsTimeEntriesBatchUpdateRoute = Ember.Route.extend({
    formatter: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render('analytics.time-entries.batch-update', {
        into: 'application',
        outlet: 'modal-batch-update'
      });
    },
    setupController: function setupController(controller, model, transition) {
      this._super.apply(this, arguments);

      var allowedParams = _environment.default.constants.ALLOWED_BATCHUPDATE_PARAMS;
      var k = model.k;
      var v = model.v;

      if (k && allowedParams.indexOf(k) > -1) {
        switch (k) {
          case 'billedAt':
            controller.set('modalSubTitle', v ? 'areYouSureMarkBilled' : 'areYouSureMarkUnBilled');
            controller.set('warning', null);
            break;
        }

        Ember.run.later(function () {
          return controller.set('openModal', true);
        });
      } else {
        transition.abort();
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.send('onHide');
        var listController = this.controllerFor('analytics.time-entries');
        Ember.run.later(function () {
          return listController.refreshAnalytics();
        }, 1000);

        this._super.apply(this, arguments);
      }
    }
  });
  var _default = AnalyticsTimeEntriesBatchUpdateRoute;
  _exports.default = _default;
});