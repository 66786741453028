define("coffeecup/templates/account/integrations/billing/-client-import-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+fPwFJu0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"modal-wrapper modal-confirm scrollable\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-header modal-header-status error\"],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"modal-toggler close\"],[4,[38,0],[[32,0],\"onHide\"],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[14,0,\"icon-cross\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"\\n        Close\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"header-icon\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"icomoon-cross-exit\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-xs-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-body-title\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"clientImportUnsuccessful\"],null]],[2,\"\\n          \"],[10,\"br\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[1,[32,0,[\"errorText\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/account/integrations/billing/-client-import-error.hbs"
    }
  });

  _exports.default = _default;
});