define("coffeecup/templates/components/manage-table/cells/more-options-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GwAZdhYa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell more-options-cell\"],[15,5,[32,0,[\"safeWidth\"]]],[12],[2,\"\\n  \"],[8,\"cc-dropdown\",[],[[\"@smallIcon\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"ul\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"isInactive\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"dropdown-option\",[],[[\"@label\",\"@iconName\",\"@action\",\"@remainOpen\"],[\"activate\",\"unarchive\",[32,0,[\"activate\"]],false]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"dropdown-option\",[],[[\"@label\",\"@iconName\",\"@action\",\"@remainOpen\"],[\"archive\",\"archive\",[32,0,[\"deactivate\"]],false]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[8,\"dropdown-option\",[],[[\"@label\",\"@iconName\",\"@action\",\"@remainOpen\"],[\"duplicate\",\"duplicate\",[32,0,[\"copy\"]],false]],null],[2,\"\\n      \"],[8,\"dropdown-option\",[],[[\"@label\",\"@iconName\",\"@action\",\"@remainOpen\"],[\"delete\",\"bin2\",[32,0,[\"delete\"]],false]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/more-options-cell.hbs"
    }
  });

  _exports.default = _default;
});