define("coffeecup/components/main-menu", ["exports", "coffeecup/mixins/image-preview"], function (_exports, _imagePreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_imagePreview.default, {
    menu: [],
    currentUserService: Ember.inject.service('currentUser'),
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    stripeService: Ember.inject.service('stripe'),
    subscriptionStatus: Ember.computed.alias('stripeService.status'),
    currentUser: Ember.computed.oneWay('currentUserService.currentUser'),
    numberOfOpenAbsenceRequests: 0,
    numberOfOpenValidations: 0,
    _updateMenuStructure: Ember.observer('permissions.activePermissions', function () {
      this.notifyPropertyChange('menu');
    }),
    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });

  _exports.default = _default;
});