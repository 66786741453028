define("coffeecup/templates/components/onboarding/basic/color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g4F8yfp2",
    "block": "{\"symbols\":[\"@nextStep\"],\"statements\":[[10,\"div\"],[14,0,\"text-center w-100\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"onboardingColor\"],null]],[13],[2,\"\\n  \"],[10,\"p\"],[12],[1,[30,[36,0],[\"onboardingColorHint\"],null]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"form-group \",[30,[36,1],[[32,0,[\"accountSettings\",\"errors\",\"colorScheme\",\"firstObject\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[8,\"tool-tipster\",[],[[\"@content\"],[[32,0,[\"accountSettings\",\"errors\",\"colorScheme\",\"firstObject\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"cc-colorpicker\",[],[[\"@color\",\"@errors\",\"@format\",\"@classNames\",\"@action\"],[[32,0,[\"accountSettings\",\"colorScheme\"]],[32,0,[\"accountSettings\",\"errors\",\"colorScheme\",\"firstObject\"]],\"hex\",\"input-group\",[30,[36,2],[[32,0,[\"save\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"input\"],[14,0,\"form-control\"],[14,4,\"text\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[10,\"i\"],[12],[13],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary btn-pill large-pill\"],[24,\"role\",\"button\"],[24,\"aria-pressed\",\"false\"],[24,4,\"button\"],[4,[38,3],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"continue\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/onboarding/basic/color.hbs"
    }
  });

  _exports.default = _default;
});