define("coffeecup/templates/components/analytics-module/analytics/billable-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6rx9U2H6",
    "block": "{\"symbols\":[\"@analytics\"],\"statements\":[[8,\"analytics-module/kpi/kpi-cell\",[],[[\"@amount\",\"@label\",\"@hasData\"],[[30,[36,0],[[32,1,[\"hoursBillableInPercent\"]]],null],[30,[36,1],[\"billableWorkTime\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"kpi-tooltip\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"billableWorkTime\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"hoursBillable\"]],false,true],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"nonBillableWorkTime\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"hoursNonBillable\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row summary-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"budgetByTotalWorkTime\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"hoursTotal\"]],false,true,true],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"percent-fmt-unit\",\"t\",\"hours-fmt\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/analytics/billable-hours.hbs"
    }
  });

  _exports.default = _default;
});