define("coffeecup/utils/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = user;

  function user() {
    return true;
  }
});