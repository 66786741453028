define("coffeecup/routes/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    analytics: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    redirect: function redirect(model, transition) {
      var url = this.router.location.formatURL('/not-found');

      if (window.location.pathname !== url) {
        this.get('analytics').notifyError('404 Not Found', false, transition);
        this.get('notifications').error('404');
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});