define("coffeecup/templates/time-entries/tracking/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RVKgE1TC",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,10],[[35,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,8],null,[[\"openModal\",\"model\",\"focus\",\"currentUser\",\"allowBreaks\",\"timerModeIsDuration\",\"transitionTo\",\"afterSave\",\"afterDelete\",\"allowConcurrentTimeEntries\"],[[35,6],[35,5],[35,4],[35,3],[35,1],[35,2],\"transitionToList\",\"addTimeEntryToList\",\"timeEntryDeleted\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,7],null,[[\"openModal\",\"model\",\"focus\",\"currentUser\",\"timerModeIsDuration\",\"allowBreaks\",\"transitionTo\",\"afterSave\",\"afterDelete\",\"allowConcurrentTimeEntries\"],[[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],\"transitionToList\",\"addTimeEntryToList\",\"timeEntryDeleted\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"allowConcurrentTimeEntries\",\"allowBreaks\",\"timerModeIsDuration\",\"currentUser\",\"focus\",\"model\",\"openModal\",\"modals/edit-break\",\"modals/edit-time-entry\",\"isBreakMode\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/time-entries/tracking/edit.hbs"
    }
  });

  _exports.default = _default;
});