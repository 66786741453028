define("coffeecup/models/analytics/project/timeEntryReference", ["exports", "ember-data/attr", "ember-data-model-fragments/fragment"], function (_exports, _attr, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * serialized Project analytics timeEntryReference model fragment
   * @see //serializers/analytics/project.js for mappings
   */
  var AnalyticsProjectTimeEntryReference = _fragment.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    // hours
    hoursSpent: (0, _attr.default)('number'),
    hoursTotal: (0, _attr.default)('number'),
    hoursNonBillable: (0, _attr.default)('number'),
    // hours-rounded
    hoursRoundedTotal: (0, _attr.default)('number'),
    hoursRoundedSpent: (0, _attr.default)('number'),
    hoursRoundedNonBillable: (0, _attr.default)('number'),
    // budget
    hoursBudget: (0, _attr.default)('number'),
    hoursOutOfBudget: (0, _attr.default)('number'),
    hoursBudgetLeft: Ember.computed('hoursBudget', 'hoursSpent', function () {
      var hoursBudget = this.get('hoursBudget');
      var hoursSpent = this.get('hoursSpent');

      if (hoursBudget && hoursSpent && hoursBudget > hoursSpent) {
        return hoursBudget - hoursSpent;
      }
    }),
    // amounts
    amountSpent: (0, _attr.default)('number'),
    amountBudget: (0, _attr.default)('number'),
    amountOutOfBudget: (0, _attr.default)('number'),
    amountBudgetLeft: Ember.computed('amountBudget', 'amountSpent', function () {
      var amountBudget = this.get('amountBudget');
      var amountSpent = this.get('amountSpent');

      if (amountBudget && amountSpent && amountBudget > amountSpent) {
        return amountBudget - amountSpent;
      }
    }),
    // percentage of the timeEntryReference comitted to the project
    percentage: (0, _attr.default)('number'),

    /**
     * ModelFragments don't support relationships,
     * @see https://github.com/lytics/ember-data.model-fragments/issues/18
     */
    timeEntryReference_id: (0, _attr.default)('string'),
    //timeEntryReference: DS.belongsTo('timeEntryReference'),
    timeEntryReference: Ember.computed('timeEntryReference_id', {
      get: function get() {
        return this.store.peekRecord('timeEntryReference', this.get('timeEntryReference_id'));
      }
    }),
    visible: (0, _attr.default)('boolean') //foo: Ember.computed.alias('id')

  });

  var _default = AnalyticsProjectTimeEntryReference;
  _exports.default = _default;
});