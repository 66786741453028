define("coffeecup/instance-initializers/document-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'document-category',
        id: '1',
        attributes: {
          key: 'employmentContract',
          order: 1,
          model: 'user'
        }
      }, {
        type: 'document-category',
        id: '2',
        attributes: {
          key: 'targetAgreement',
          order: 2,
          model: 'user'
        }
      }, {
        type: 'document-category',
        id: '3',
        attributes: {
          key: 'medicalCertificate',
          order: 3,
          model: 'user'
        }
      }, {
        type: 'document-category',
        id: '4',
        attributes: {
          key: 'applicationDocuments',
          order: 4,
          model: 'user'
        }
      }, {
        type: 'document-category',
        id: '5',
        attributes: {
          key: 'testimonials',
          order: 5,
          model: 'user'
        }
      }, {
        type: 'document-category',
        id: '6',
        attributes: {
          key: 'miscellaneous',
          order: 6,
          model: 'user'
        }
      }, {
        type: 'document-category',
        id: '7',
        attributes: {
          key: 'offer',
          order: 1,
          model: 'project'
        }
      }, {
        type: 'document-category',
        id: '8',
        attributes: {
          key: 'clearance',
          order: 2,
          model: 'project'
        }
      }, {
        type: 'document-category',
        id: '9',
        attributes: {
          key: 'nda',
          order: 3,
          model: 'project'
        }
      }, {
        type: 'document-category',
        id: '10',
        attributes: {
          key: 'miscellaneous',
          order: 4,
          model: 'project'
        }
      }, {
        type: 'document-category',
        id: '11',
        attributes: {
          key: 'contract',
          order: 1,
          model: 'client'
        }
      }, {
        type: 'document-category',
        id: '12',
        attributes: {
          key: 'agreement',
          order: 2,
          model: 'client'
        }
      }, {
        type: 'document-category',
        id: '13',
        attributes: {
          key: 'nda',
          order: 3,
          model: 'client'
        }
      }, {
        type: 'document-category',
        id: '14',
        attributes: {
          key: 'miscellaneous',
          order: 4,
          model: 'client'
        }
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'document-categories',
    after: 'ember-simple-auth',
    initialize: initialize
  };
  _exports.default = _default;
});