define("coffeecup/templates/components/loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ox1vA0q0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"main-loader loader-component\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"squares delay1\"],[15,5,[30,[36,1],[[30,[36,0],[\"background-color: \",[32,0,[\"primaryColor\"]]],null]],null]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"squares delay2\"],[15,5,[30,[36,1],[[30,[36,0],[\"background-color: \",[32,0,[\"primaryColor\"]]],null]],null]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"squares main-square\"],[15,5,[30,[36,1],[[30,[36,0],[\"background-color: \",[32,0,[\"primaryColor\"]]],null]],null]],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/assets/images/logo-cup-white.svg\"],[14,0,\"logo-cup\"],[14,\"alt\",\"\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"html-safe\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/loader.hbs"
    }
  });

  _exports.default = _default;
});