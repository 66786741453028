define("coffeecup/templates/manage/-edit-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nt7wak7v",
    "block": "{\"symbols\":[],\"statements\":[[10,\"footer\"],[14,0,\"clearfix\"],[12],[2,\"\\n  \"],[11,\"button\"],[16,0,[31,[\"btn btn-default \",[30,[36,6],[[35,1,[\"isNew\"]],\"invisible\"],null],\" \",[30,[36,5],[[35,4],\"invisible\"],null]]]],[24,4,\"reset\"],[4,[38,2],[[32,0],\"reset\"],null],[12],[1,[30,[36,3],[\"reset\"],null]],[13],[2,\"\\n\\n\"],[6,[37,6],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,0,\"btn btn-primary pull-right\"],[24,\"data-test\",\"validate-button\"],[4,[38,2],[[32,0],\"validate\",[35,1]],null],[12],[1,[30,[36,3],[\"save\"],null]],[10,\"sup\"],[12],[2,\"\\n        *\"],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"button\"],[16,\"disabled\",[34,0]],[24,0,\"btn btn-primary pull-right\"],[24,\"data-test\",\"save-button\"],[24,4,\"submit\"],[4,[38,2],[[32,0],\"save\",[35,1]],null],[12],[1,[30,[36,3],[\"save\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"submitIsDisabled\",\"model\",\"action\",\"t\",\"hasChanges\",\"unless\",\"if\",\"formIsInvalid\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/manage/-edit-footer.hbs"
    }
  });

  _exports.default = _default;
});