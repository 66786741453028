define("coffeecup/routes/_super-route", ["exports", "coffeecup/config/environment", "ember-simple-auth/mixins/authenticated-route-mixin", "coffeecup/utils/build-url", "moment"], function (_exports, _environment, _authenticatedRouteMixin, _buildUrl, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var SuperRoute = Ember.Route.extend(_authenticatedRouteMixin.default, {
    analytics: Ember.inject.service(),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    cookies: Ember.inject.service(),
    permissions: Ember.inject.service(),
    validationService: Ember.inject.service('validation'),
    accountService: Ember.inject.service('account'),
    currentUserService: Ember.inject.service('currentUser'),
    timeEntryService: Ember.inject.service('time-entry'),
    workingDaysService: Ember.inject.service('working-days'),
    dashboardService: Ember.inject.service('dashboard'),
    stripeService: Ember.inject.service('stripe'),
    getCurrentUser: function getCurrentUser() {
      return this.get('currentUserService.currentUser');
    },
    renderTemplate: function renderTemplate(controller) {
      this._super.apply(this, arguments);

      this._renderNavbarTemplate(controller);
    },

    /**
     * Renders the navbar template for this route into the application's (mobile) nav bar.
     * Template resolution is modelled after the way route templates are resolved in Ember:
     *
     * The template `templates/navbar/{route path}.hbs` is looked up.
     * If it exists it is rendered into the `navbar` outlet of the parent route.
     * Otherwise a default template is rendered which just offers a `navbar` outlet for a child route to render into.
     *
     * The rendered template is bound to the controller, just like the normal route template.
     *
     * @param controller the route controller
     */
    _renderNavbarTemplate: function _renderNavbarTemplate(controller) {
      var navbarTemplateName = "navbar.".concat(this.routeName);

      if (!Ember.getOwner(this).lookup("template:".concat(navbarTemplateName))) {
        navbarTemplateName = 'navbar._default';
      }

      this.render(navbarTemplateName, {
        outlet: 'navbar',
        controller: controller
      });
    },
    _loadCurrentUserAndMasterData: function _loadCurrentUserAndMasterData(resolve, reject, transition) {
      var _this = this;

      var applicationController = this.controllerFor('application');
      var currentUser = this.getCurrentUser();
      var profileUrl = (0, _buildUrl.default)(this.store, 'users/me');

      if (currentUser) {
        this._checkNeedToReLogin(resolve, reject, transition);

        this._checkRoutePermission(resolve, reject, transition);
      } else if (!this.get('session.isAuthenticated')) {
        reject();
      } else {
        applicationController.set('isFirstLoading', true);
        Ember.RSVP.hash({
          profile: this.get('session').getJSON(profileUrl),
          stripeStatus: this.stripeService.fetchStatus()
        }).then(function (context) {
          if (context.profile.user && context.profile.user.id && context.stripeStatus) {
            var id = context.profile.user.id;
            var subscriptions = context.subscriptions;
            Ember.run(function () {
              Ember.RSVP.hash({
                dashboards: _this.store.findAll('dashboard'),
                accountSettings: _this.store.findAll('account-setting'),
                color: _this.store.findAll('color'),
                user: _this.store.findRecord('user', id),
                userAssignments: _this.store.findAll('user-assignment'),
                roles: _this.store.findAll('role'),
                users: _this.store.findAll('user'),
                clients: _this.store.findAll('client'),
                tags: _this.store.findAll('tag'),
                absenceTypes: _this.store.findAll('absence-type'),
                // Project States are temporarily loaded via InstanceInitializer
                // projectStates: this.store.findAll('project-state'),
                tagAssignments: _this.store.findAll('tag-assignment'),
                contacts: _this.store.findAll('contact'),
                userHolidays: _this.store.query('user-holiday', {
                  sort: 'date ASC',
                  limit: 1000,
                  where: {
                    date: {
                      '>=': _this.get('clock.now').subtract(2, 'years').startOf('year').toJSON(),
                      '<=': _this.get('clock.now').add(2, 'years').endOf('year').toJSON()
                    }
                  }
                }),
                projects: _this.store.findAll('project'),
                tasks: _this.store.findAll('task'),
                expenseCategories: _this.store.findAll('expense-category'),
                invoices: _this.store.findAll('invoice'),
                absenceRequests: _this.store.findAll('absence-request'),
                taskAssignments: _this.store.findAll('task-assignment'),
                latestTimeEntry: _this.get('timeEntryService').findLatestTimeEntryForUser(id),
                currentTimeEntries: _this.store.query('time-entry', {
                  where: {
                    user: id,
                    trackingType: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK,
                    or: [{
                      running: true
                    }, {
                      day: {
                        '>=': _this.get('clock.day').format(_environment.default.constants.DATE_FORMAT_DAY),
                        '<': _this.get('clock.day').add(1, 'day').format(_environment.default.constants.DATE_FORMAT_DAY)
                      }
                    }]
                  }
                }),
                userEmployments: _this.store.findAll('user-employment'),
                projectReferencePlatforms: _this.store.findAll('project-reference-platform'),
                // RH-TODO - Load these in a more on-demand format
                timeEntryReferences: _this.store.query('time-entry-reference', {
                  includeArchived: true
                }),
                integrationServiceTypes: _this.store.findAll('integration-service-type'),
                integrationServices: _this.store.findAll('integration-service'),
                userIntegrations: _this.store.findAll('user-integration'),
                clientExternalIntegration: _this.store.findAll('client-external-integration'),
                invoiceExternalIntegration: _this.store.findAll('invoice-external-integration'),
                teams: _this.store.findAll('team'),
                teamAssignments: _this.store.findAll('team-assignment')
              }).then(function (context) {
                _this.get('accountService').updateAccountSettings(context.accountSettings.get('firstObject'));

                applicationController.set('currentUser', context.user);

                _this.get('currentUserService').updateCurrentUser(context.user);

                _this.get('workingDaysService').updateEmploymentEntries(context.userEmployments);

                _this.get('workingDaysService').updateUserHolidays(context.userHolidays.toArray());

                _this.get('dashboardService').updateDashboards(context.dashboards.toArray());

                applicationController.set('roles', context.roles);
                applicationController.set('userAssignments', context.userAssignments);
                applicationController.set('tasks', context.tasks);
                applicationController.set('users', context.users);

                _this.get('permissions').set('currentUser', context.user);

                _this.get('permissions').set('userAssignments', context.userAssignments);

                if (_this.get('permissions.canValidateWorkTime') && _this.get('permissions.worktimeValidationActive') || _this.get('permissions.canViewTeamTimeEntries')) {
                  _this.get('session').getJSON((0, _buildUrl.default)(_this.store, 'validate/invalid', {
                    restrictOnAssignedUsers: true
                  })).then(function (openValidationWeeks) {
                    _this.get('validationService').updateOpenValidationCount(openValidationWeeks);
                  });
                }

                applicationController.set('clients', context.clients);
                applicationController.set('projects', context.projects);
                applicationController.set('tags', context.tags);
                applicationController.set('taskAssignments', context.taskAssignments);
                applicationController.set('absenceRequests', context.absenceRequests);
                applicationController.set('absenceTypes', context.absenceTypes); // applicationController.set('projectStates', context.projectStates);

                applicationController.set('latestTimeEntry', context.latestTimeEntry.get('firstObject'));
                applicationController.set('expenseCategories', context.expenseCategories);
                applicationController.set('projectReferencePlatforms', context.projectReferencePlatforms);
                applicationController.set('integrationServiceTypes', context.integrationServiceTypes);
                applicationController.set('integrationServices', context.integrationServices);
                applicationController.set('userIntegrations', context.userIntegrations);
                applicationController.set('clientExternalIntegration', context.clientExternalIntegration);
                applicationController.set('invoiceExternalIntegration', context.invoiceExternalIntegration);
                applicationController.set('invoices', context.invoices);
                applicationController.set('timeEntryReferences', context.timeEntryReferences);
                applicationController.set('teams', context.teams);
                applicationController.set('teamAssignments', context.teamAssignments);

                _this.get('analytics').start(context.user);

                _this._checkRoutePermission(resolve, reject, transition);
              });
            });
          } else {
            applicationController.set('isFirstLoading', false);
            transition.abort();

            _this.get('notifications').error('401');

            window.setTimeout(function () {
              _this.get('session').invalidate();

              reject();
            }, 2000);
          }
        }).catch(function (response) {
          applicationController.set('isFirstLoading', false);
          transition.abort();

          if (!_this._handleFutureUserError(response)) {
            // Only invalidate session if not already handled as 'future user' error
            _this.get('session').invalidate();
          }

          console.error(response);
          reject();
        });
      }
    },
    _checkNeedToReLogin: function _checkNeedToReLogin(resolve, reject, transition) {
      var currentUser = this.get('currentUserService.currentUser');
      var currentRouteName = this.get('routeName');
      var userNeedsToReLogin = false;
      var cookies = this.get('cookies');
      var oneTimeAccessForTransition = cookies.read('oneTimeAccessForTransition');

      if (oneTimeAccessForTransition && currentRouteName === oneTimeAccessForTransition) {
        Ember.run.scheduleOnce('afterRender', this, function () {
          cookies.clear('oneTimeAccessForTransition');
        });
        resolve();
        return;
      }

      if (this.get('accountService').get('accountSettings.secureUserDetailsRoute')) {
        if (!this.get('currentUserService.isAdmin')) {
          var reLoginRoutes = _environment.default.constants.USER_RE_LOGIN_ROUTES;
          userNeedsToReLogin |= reLoginRoutes.indexOf(currentRouteName) >= 0;
        }
      }

      if (userNeedsToReLogin) {
        cookies.write('reLoginUsername', currentUser.get('email'), {
          path: '/'
        });
        var transitionParams = transition.params && transition.targetName && transition.params[transition.targetName];
        var userId = transitionParams && transitionParams.user_id || currentUser.get('id');
        cookies.write('reLoginForUser', userId, {
          path: '/'
        });
        cookies.write('reLoginForTransition', transition.targetName, {
          path: '/'
        });
        this.get('session').invalidate();
        transition.abort();
      } else {
        resolve();
      }
    },
    _checkRoutePermission: function _checkRoutePermission(resolve, reject, transition) {
      var applicationController = this.controllerFor('application');
      var currentPermissions = applicationController.get('currentPermissions');
      var currentRouteName = this.get('routeName');
      var menu = this.store.peekAll('menu');
      var isUserAllowedToVisitRoute = true;
      var disallowedMenus = menu.filter(function (item) {
        return item.get('hideFromRoles') && item.get('hideFromRoles').some(function (perms) {
          return perms === currentPermissions;
        }) || item.get('hideItem');
      });
      isUserAllowedToVisitRoute &= !disallowedMenus.some(function (item) {
        return currentRouteName.indexOf(item.get('url')) === 0;
      });

      if (isUserAllowedToVisitRoute) {
        resolve();
      } else {
        transition.abort(); // Print basic information about access denied to help in the event of test failure

        if (_environment.default.environment === 'test') {
          console.log('FORBIDDEN ROUTE: ' + currentRouteName);
          console.log('Current User: ' + currentUser.get('fullName') + "(".concat(currentUser.get('role.name'), ")"));
        } // let error = '405 Not Allowed';
        // this.get('analytics').notifyError(error, false, transition);
        // this.get('notifications').error('405');


        this.transitionTo('index');
      }
    },
    _handleFutureUserError: function _handleFutureUserError(response) {
      if (response.responseJSON && _typeof(response.responseJSON.raw) === 'object') {
        var errorInfo = response.responseJSON.raw;

        if (errorInfo.isFutureEmployee) {
          var startDate = _moment.default.utc(errorInfo.startDate).format(_environment.default.constants.DATE_PICKER_FORMAT);

          this.transitionTo('future-user', {
            queryParams: {
              startDate: startDate
            }
          });
          return true;
        }
      }

      return false;
    },
    beforeModel: function beforeModel(transition) {
      var _this2 = this;

      var upstreamPromise = this._super.apply(this, arguments);

      return Ember.RSVP.resolve(upstreamPromise).then(function () {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          // TODO _loadCurrentUserAndMasterData needs to be refactored as soon as possible
          _this2._loadCurrentUserAndMasterData(resolve, reject, transition);
        });
      });
    },
    afterModel: function afterModel(model, transition) {// TODO - Restore end-of-trial prompt
      // const isEnded = this.get('stripeService.isEnded');
      // const manageNotTargetRoute = transition.targetName.indexOf('projects.') === -1;
      // const billingNotTargetRoute = transition.targetName !== 'account.billing';
      // applicationController.set('promptBillingUpdateModal', isEnded && billingNotTargetRoute);
    }
  });
  var _default = SuperRoute;
  _exports.default = _default;
});