define("coffeecup/templates/components/dashboard-module/summary-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "abwVk9cY",
    "block": "{\"symbols\":[\"presentationMode\",\"mode\"],\"statements\":[[10,\"div\"],[14,0,\"summary-bar\"],[12],[2,\"\\n\"],[6,[37,11],[[35,9]],null,[[\"default\"],[{\"statements\":[[6,[37,13],null,[[\"label\",\"icon\",\"spaceDirection\",\"noBorder\",\"smallIcon\",\"showLabel\",\"opens\",\"verticalPosition\",\"class\"],[[30,[36,12],[[35,7]],null],[30,[36,11],[[35,10],\"icon-arrow-65\"],null],null,true,true,true,\"left\",\"above\",\"dropdown mode-select\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,9]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,8],null,[[\"label\",\"value\",\"current\",\"action\"],[[32,2],[32,2],[35,7],[30,[36,6],[[32,0],\"setCurrentMode\"],null]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,11],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"presentation-mode-button-group\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"action\",\"presentationMode\",\"selectedPresentationMode\"],[\"setPresentationMode\",[32,1],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,11],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"summary-label space-right\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"summaryLabel\",\"selectedPresentationMode\",\"dashboard-module/summary-bar/presentation-mode-button\",\"presentationModes\",\"-track-array\",\"each\",\"action\",\"currentMode\",\"dropdown-option\",\"modes\",\"hasMultipleModes\",\"if\",\"t\",\"cc-dropdown\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard-module/summary-bar.hbs"
    }
  });

  _exports.default = _default;
});