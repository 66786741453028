define("coffeecup/templates/planner/milestone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "006fngmC",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"planner/popover-milestone\",[],[[\"@openModal\",\"@model\",\"@options\",\"@isInitialEdit\",\"@transitionTo\",\"@onDelete\",\"@afterSave\"],[true,[32,1],[32,0,[\"options\"]],[32,0,[\"isInitialEdit\"]],[30,[36,0],[\"transitionToPlanner\"],null],[30,[36,0],[\"deleteMilestone\"],null],[30,[36,0],[\"afterSaveMilestone\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/planner/milestone.hbs"
    }
  });

  _exports.default = _default;
});