define("coffeecup/templates/navbar/time-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dLvGViwS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"navbar-time-entries\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"datepicker hidden-sm\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"pull-left btn btn-noborder\"],[4,[38,0],[[32,0],\"prevDay\"],null],[12],[10,\"span\"],[14,0,\"icon-arrow-67 small\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"pull-right btn btn-noborder\"],[4,[38,0],[[32,0],\"nextDay\"],null],[12],[10,\"span\"],[14,0,\"icon-arrow-68 small\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"label-day\"],[12],[1,[34,1]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"label-month-year\"],[12],[1,[34,2]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"btn-group pull-right hidden-sm\"],[12],[2,\"\\n\"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"ul\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n        \"],[19,\"time-entries/tracking/-dropdown-content\",[]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"action\",\"activeDayShortLabel\",\"activeDayMonthYearLabel\",\"cc-dropdown\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/navbar/time-entries.hbs"
    }
  });

  _exports.default = _default;
});