define("coffeecup/templates/components/planner/user-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z35qA1eW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row-summary\"],[12],[2,\"\\n  \"],[8,\"planner/planner-tooltip\",[],[[\"@contentAsHTML\",\"@content\",\"@class\",\"@side\",\"@leftInset\"],[true,[32,0,[\"tooltip\"]],\"summary-inner\",\"top\",0]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"    \"],[10,\"div\"],[15,0,[30,[36,1],[\"round-tag green\",[30,[36,0],[[32,0,[\"capacityIsNegative\"]],\"negative\"],null]],null]],[12],[2,\"\\n      \"],[1,[32,0,[\"roundedCapacity\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/user-summary.hbs"
    }
  });

  _exports.default = _default;
});