define("coffeecup/routes/planner/absences/calendar/edit", ["exports", "coffeecup/routes/planner/absences/calendar/new", "moment"], function (_exports, _new, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    controllerName: 'planner.absences.calendar.new',
    getAbsence: function getAbsence(params) {
      return this.store.findRecord('absence', params.absence_id).then(function (entry) {
        var startDate = _moment.default.utc(entry.get('startDate'));

        var endDate = _moment.default.utc(entry.get('endDate'));

        entry.set('startDate', startDate);
        entry.set('endDate', endDate);
        return entry;
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('planner.absences.calendar.new');
    }
  });

  _exports.default = _default;
});