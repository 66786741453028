define("coffeecup/templates/components/cc-kpi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l0BTngKL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"title \",[30,[36,4],[[35,3],\"text-danger\"],null]]]],[12],[2,\"\\n  \"],[1,[34,5]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"span\"],[15,0,[31,[\"value \",[30,[36,4],[[35,3],\"text-danger\"],null]]]],[12],[2,\"\\n  \"],[1,[34,6]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"span\"],[24,0,\"custom-action primary-font-color\"],[4,[38,1],[[32,0],[35,0]],null],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"customAction\",\"action\",\"customActionLabel\",\"useAlertColor\",\"if\",\"title\",\"value\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/cc-kpi.hbs"
    }
  });

  _exports.default = _default;
});