define("coffeecup/templates/components/planner/milestones-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EUkqBxDe",
    "block": "{\"symbols\":[\"milestone\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"milestone\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"planner/milestone-count\",\"groupedMilestones\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/milestones-row.hbs"
    }
  });

  _exports.default = _default;
});