define("coffeecup/templates/components/avatar-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u6hKYl0H",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"avatar-overlay \",[30,[36,1],[[35,0],\"circular\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"icon-wrapper\"],[12],[10,\"span\"],[14,0,\"icomoon-loop3\"],[12],[13],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"label-wrapper\"],[12],[1,[30,[36,2],[\"changeImage\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"circular\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/avatar-overlay.hbs"
    }
  });

  _exports.default = _default;
});