define("coffeecup/utils/strip-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function stripTags(text, replacement) {
    replacement = replacement || '';

    if (!text || Ember.isEmpty(text)) {
      return '';
    }

    if (typeof text.toString === 'function') {
      text = text.toString();
    }

    return text.replace(/<(?:.|\n)*?>/gm, replacement);
  }

  var _default = stripTags;
  _exports.default = _default;
});