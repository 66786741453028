define("coffeecup/routes/planner/absences/calendar/feed", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    store: Ember.inject.service(),
    setupController: function setupController(controller, context, transition) {
      var applicationController = this.controllerFor('application');
      controller.set('closeModal', false);

      this._super(controller, context, transition);

      Ember.run.later(function () {
        return controller.set('openModal', true);
      });
    },
    actions: {
      transitionToList: function transitionToList() {
        this.transitionTo('planner.absences.calendar');
      },
      refreshCalendarFeed: function refreshCalendarFeed() {
        this.refresh();
      }
    },
    model: function model(params) {
      return this._loadFeed();
    },
    _loadFeed: function _loadFeed() {
      var _this = this;

      var getOrCreate = this.store.query('calendar-feed', {
        limit: 1,
        sort: 'createdAt ASC'
      }).then(function (resultSet) {
        if (resultSet.get('length') == 1) {
          return resultSet.get('firstObject');
        } else {
          var feed = _this.store.createRecord('calendar-feed');

          feed.set('user', _this.getCurrentUser());
          return feed.save().then(function () {
            return feed;
          });
        }
      });
      return getOrCreate;
    }
  });

  _exports.default = _default;
});