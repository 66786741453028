define("coffeecup/models/vacation-budget", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    quantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    vacationType: _emberData.default.attr('number'),
    comment: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    startDate: _emberData.default.attr('datestamp'),
    createdAt: _emberData.default.attr('utc-moment'),
    updatedAt: _emberData.default.attr('utc-moment'),
    year: Ember.computed('startDate', {
      get: function get() {
        return moment(this.get('startDate')).format('YYYY');
      },
      set: function set(k, v) {
        var startDate = moment.utc('' + v, 'YYYY').startOf('year');
        this.set('startDate', startDate);
        return v;
      }
    })
  });

  _exports.default = _default;
});