define("coffeecup/templates/components/input-date-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5htw7Vf4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"has-feedback-small date-range-picker has-icon-right\"],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"class\",\"type\",\"value\",\"placeholder\",\"disabled\",\"readonly\"],[\"form-control\",\"text\",[35,2],[35,1],[35,0],true]]]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"form-control-button date-picker\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"icomoon-calendar\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"disabled\",\"placeholder\",\"formattedStartEndDate\",\"input\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input-date-range.hbs"
    }
  });

  _exports.default = _default;
});