define("coffeecup/validators/validateDate", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-changeset-validations/utils/with-defaults", "ember-changeset-validations/utils/to-date"], function (_exports, _validationErrors, _withDefaults, _toDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDate;
  _exports.errorFormat = void 0;
  var errorFormat = 'MMM Do, YYYY';
  _exports.errorFormat = errorFormat;

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function validateDate() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    options = (0, _withDefaults.default)(options, {
      allowBlank: false,
      errorFormat: errorFormat
    });
    console.log(options);
    return function (key, rawValue) {
      var _options = options,
          allowBlank = _options.allowBlank;
      var _options2 = options,
          before = _options2.before,
          onOrBefore = _options2.onOrBefore,
          after = _options2.after,
          onOrAfter = _options2.onOrAfter,
          message = _options2.message;

      if (allowBlank && (typeof rawValue === 'undefined' || rawValue === null)) {
        return true;
      }

      var value = moment(rawValue).toDate();
      var date = (0, _toDate.default)(value);

      if (!isValidDate(date)) {
        return (0, _validationErrors.default)(key, {
          type: 'date',
          value: 'not a date',
          message: message
        });
      }

      if (before) {
        before = (0, _toDate.default)(before);

        if (date >= before) {
          var beforeParsed = before.toLocaleDateString();
          return (0, _validationErrors.default)(key, {
            type: 'before',
            value: value,
            context: {
              before: beforeParsed
            },
            message: message
          });
        }
      }

      if (onOrBefore) {
        onOrBefore = (0, _toDate.default)(onOrBefore);

        if (date > onOrBefore) {
          var onOrBeforeParsed = onOrBefore.toLocaleDateString();
          return (0, _validationErrors.default)(key, {
            type: 'onOrBefore',
            value: value,
            context: {
              onOrBefore: onOrBeforeParsed
            },
            message: message
          });
        }
      }

      if (after) {
        after = (0, _toDate.default)(after);

        if (date <= after) {
          var afterParsed = after.toLocaleDateString();
          return (0, _validationErrors.default)(key, {
            type: 'after',
            value: value,
            context: {
              after: afterParsed
            },
            message: message
          });
        }
      }

      if (onOrAfter) {
        onOrAfter = (0, _toDate.default)(onOrAfter);
        var onOrAfterParsed = onOrAfter.toLocaleDateString();

        if (date < onOrAfter) {
          return (0, _validationErrors.default)(key, {
            type: 'onOrAfter',
            value: value,
            context: {
              onOrAfter: onOrAfterParsed
            },
            message: message
          });
        }
      }

      return true;
    };
  }
});