define("coffeecup/templates/components/roles/header-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VGixsRyR",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"content\"],[[30,[36,2],[[35,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[\"icon icomoon-\",[34,0]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"tooltip\",\"t\",\"tool-tipster\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/roles/header-icon.hbs"
    }
  });

  _exports.default = _default;
});