define("coffeecup/serializers/analytics/project", ["exports", "coffeecup/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeObject = normalizeObject;
  _exports.default = void 0;

  var _default = _application.default.extend({
    type: 'analytics/project',
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload) {
        return {
          data: null
        };
      }

      var ret = normalizeObject(store, payload.analyticsProject || payload, this.get('type'));
      return {
        data: ret
      };
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      if (!payload) {
        return {
          data: null
        };
      }

      var payloadCleaned = (payload.analyticsProjects || payload).filter(function (record) {
        return typeof record.id !== 'undefined' && record.id !== null;
      });
      var ret = payloadCleaned.map(function (record) {
        return normalizeObject(store, record, _this.get('type'));
      });
      return {
        data: ret
      };
    },

    /**
     @method normalizeQueryResponse
     @param {DS.Store} store
     @param {DS.Model} primaryModelClass
     @param {Object} payload
     @param {String|Number} id
     @param {String} requestType
     @return {Object} JSON-API Document
     */
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload && Ember.isArray(payload.analyticsProjects)) {
        return this.normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType);
      } else {
        var ret = this.normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType);
        return ret && ret.data ? {
          data: [ret.data]
        } : {
          data: []
        };
      }
    }
  });
  /**
   *
   * @param obj
   */


  _exports.default = _default;

  function normalizeObject(store, obj, type) {
    var projectId = Ember.get(obj, 'project');
    var returnObj = {
      id: Ember.get(obj, 'id'),
      type: type,
      relationships: {
        project: {
          data: {
            type: 'project',
            id: Ember.get(obj, 'project')
          }
        }
      },
      attributes: {
        // Hours not rounded
        hoursBilled: Ember.get(obj, 'hours.billed') || 0,
        hoursBudget: Ember.get(obj, 'hours.budget') || 0,
        hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
        hoursOutOfBudget: Ember.get(obj, 'hours.outOfBudget') || 0,
        hoursSpent: Ember.get(obj, 'hours.spent') || 0,
        hoursTotal: Ember.get(obj, 'hours.total') || 0,
        hoursPlanned: Ember.get(obj, 'hours.planned') || 0,
        // Hours rounded
        hoursRoundedBilled: Ember.get(obj, 'hoursRounded.billed') || Ember.get(obj, 'hours.billed') || 0,
        hoursRoundedBudget: Ember.get(obj, 'hoursRounded.budget') || Ember.get(obj, 'hours.budget') || 0,
        hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || Ember.get(obj, 'hours.nonBillable') || 0,
        hoursRoundedOutOfBudget: Ember.get(obj, 'hoursRounded.outOfBudget') || Ember.get(obj, 'hours.outOfBudget') || 0,
        hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || Ember.get(obj, 'hours.spent') || 0,
        hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || Ember.get(obj, 'hours.total') || 0,
        amountSpent: Ember.get(obj, 'amount.spent') || 0,
        amountBudget: Ember.get(obj, 'amount.budget') || 0,
        amountOutOfBudget: Ember.get(obj, 'amount.outOfBudget') || 0,
        amountBilled: Ember.get(obj, 'amount.billed') || 0,
        invoicesTotal: Ember.get(obj, 'invoices.total') || 0,
        invoices: Ember.get(obj, 'invoices.invoices') ? Ember.get(obj, 'invoices.invoices').map(function (invoice) {
          return Ember.Object.extend(invoice).create();
        }) : [],
        userCosts: Ember.get(obj, 'costs') || 0,
        expensesTotal: Ember.get(obj, 'expenses.amount') || 0,
        expensesBilled: Ember.get(obj, 'expenses.amountBilled') || 0,
        expensesNotBilled: Ember.get(obj, 'expenses.amountNotBilled') || 0,
        expensesWithinBudget: Ember.get(obj, 'expenses.amountWithinBudget') || 0,
        expensesNotWithinBudget: Ember.get(obj, 'expenses.amountNotWithinBudget') || 0,
        expensesCosts: Ember.get(obj, 'expenses.costs') || 0,
        expensesCostsWithinBudget: Ember.get(obj, 'expenses.costsWithinBudget') || 0,
        expensesCostsNotWithinBudget: Ember.get(obj, 'expenses.costsNotWithinBudget') || 0,
        documents: Ember.get(obj, 'documents') || 0
      }
    };

    if (Ember.get(obj, 'histogram')) {
      var histogram = Ember.get(obj, 'histogram') || [];
      returnObj.attributes.histogram = histogram.map(normalizeHistogram);
    }

    if (Ember.get(obj, 'users')) {
      returnObj.attributes.users = Ember.get(obj, 'users').map(function (record) {
        return normalizeUsers(store, record, Ember.get(obj, 'hours.total'));
      });
    }

    if (Ember.get(obj, 'tasks')) {
      returnObj.attributes.tasks = Ember.get(obj, 'tasks').map(function (record) {
        return normalizeTasks(store, record, Ember.get(obj, 'hours.total'));
      });
    }

    if (Ember.get(obj, 'timeEntryReferences')) {
      returnObj.attributes.timeEntryReferences = Ember.get(obj, 'timeEntryReferences').map(function (record) {
        return normalizeTimeEntryReferences(store, record, Ember.get(obj, 'hours.total'));
      });
    }

    if (Ember.get(obj, 'teams')) {
      returnObj.attributes.teams = Ember.get(obj, 'teams').map(function (record) {
        return normalizeTeams(store, record, Ember.get(obj, 'hours.total'));
      });
    }

    return returnObj;
  }
  /**
   * histogram model
   * @param obj
   */


  function normalizeHistogram(obj) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'timestamp'),
      date: Ember.get(obj, 'date') || Ember.get(obj, 'period'),
      timestamp: Ember.get(obj, 'timestamp'),
      hoursSpent: Ember.get(obj, 'hours.spent') || 0,
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      userCosts: Ember.get(obj, 'costs') || 0,
      expensesTotal: Ember.get(obj, 'expenses.amount') || 0,
      expensesBilled: Ember.get(obj, 'expenses.amountBilled') || 0,
      expensesNotBilled: Ember.get(obj, 'expenses.amountNotBilled') || 0,
      expensesWithinBudget: Ember.get(obj, 'expenses.amountWithinBudget') || 0,
      expensesNotWithinBudget: Ember.get(obj, 'expenses.amountNotWithinBudget') || 0,
      expensesCosts: Ember.get(obj, 'expenses.costs') || 0,
      expensesCostsWithinBudget: Ember.get(obj, 'expenses.costsWithinBudget') || 0,
      expensesCostsNotWithinBudget: Ember.get(obj, 'expenses.costsNotWithinBudget') || 0,
      hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || Ember.get(obj, 'hours.spent') || 0,
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || Ember.get(obj, 'hours.total') || 0,
      hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || Ember.get(obj, 'hours.nonBillable') || 0,
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      amountBilled: Ember.get(obj, 'amount.billed') || 0,
      amountNonBillable: Ember.get(obj, 'amount.nonBillable') || 0,
      hoursBillable: Ember.computed('hoursTotal', 'hoursNonBillable', function () {
        return this.get('hoursTotal') - this.get('hoursNonBillable');
      }),
      amountNotBilled: Ember.computed('amountSpent', 'amountBilled', function () {
        var amountSpent = this.get('amountSpent') || 0;
        var amountBilled = this.get('amountBilled') || 0;
        return amountSpent - amountBilled;
      }),
      amountNotBilledTotal: Ember.computed('amountNotBilled', 'expensesNotBilled', function () {
        var amountNotBilled = this.get('amountNotBilled') || 0;
        var expensesNotBilled = this.get('expensesNotBilled') || 0;
        return amountNotBilled + expensesNotBilled;
      }),
      amountSpentTotalIfCompleted: Ember.computed.alias('amountSpentTotal'),
      amountSpentTotal: Ember.computed('amountSpent', 'expensesTotal', function () {
        var amountSpent = this.get('amountSpent') || 0;
        var expensesTotal = this.get('expensesTotal') || 0;
        return amountSpent + expensesTotal;
      }),
      profitabilityTotal: Ember.computed('totalCosts', 'amountSpentTotal', function () {
        return this.get('amountSpentTotal') - this.get('totalCosts');
      }),
      totalCosts: Ember.computed('userCosts', 'expensesCosts', function () {
        var expensesCosts = this.get('expensesCosts') || 0;
        var userCosts = this.get('userCosts') || 0;
        return userCosts + expensesCosts;
      }),
      profitabilityInPercent: Ember.computed('profitabilityTotal', 'amountSpentTotalIfCompleted', function () {
        var profitabilityTotal = this.get('profitabilityTotal') || 0;
        var amountSpent = this.get('amountSpentTotalIfCompleted') || 0;
        return amountSpent !== 0 ? profitabilityTotal / amountSpent : 0;
      })
    }).create();
  }
  /**
   * user model
   * @param obj
   * @param hoursTotal
   */


  function normalizeUsers(store, obj, hoursTotal) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'id'),
      user_id: Ember.get(obj, 'id'),
      // hours
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursSpent: Ember.get(obj, 'hours.spent') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      hoursPlanned: Ember.get(obj, 'hours.planned') || 0,
      // hours-rounded
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || Ember.get(obj, 'hours.total') || 0,
      hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || Ember.get(obj, 'hours.spent') || 0,
      hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || Ember.get(obj, 'hours.nonBillable') || 0,
      // amounts
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      // staff costs
      costs: Ember.get(obj, 'costs') || 0,
      // the percentage of hours contributed
      percentage: hoursTotal > 0 ? (Ember.get(obj, 'hours.total') || 0) / hoursTotal : 0,
      user: Ember.computed('user_id', {
        get: function get() {
          return store.peekRecord('user', this.get('user_id'));
        }
      }),
      visible: false
    }).create();
  }
  /**
   * task model
   * @param obj
   * @param {Number} hoursTotal the total amount of hours for the project (necessary for calculating the percentage)
   */


  function normalizeTasks(store, obj, hoursTotal) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'id'),
      task_id: Ember.get(obj, 'id'),
      // hours rounded
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursSpent: Ember.get(obj, 'hours.spent') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      hoursBudget: Ember.get(obj, 'hours.budget') || 0,
      hoursOutOfBudget: Ember.get(obj, 'hours.outOfBudget') || 0,
      // hours rounded
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || Ember.get(obj, 'hours.total') || 0,
      hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || Ember.get(obj, 'hours.spent') || 0,
      hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || Ember.get(obj, 'hours.nonBillable') || 0,
      hoursRoundedBudget: Ember.get(obj, 'hoursRounded.budget') || Ember.get(obj, 'hours.budget') || 0,
      hoursRoundedOutOfBudget: Ember.get(obj, 'hoursRounded.outOfBudget') || Ember.get(obj, 'hours.outOfBudget') || 0,
      // amounts
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      amountBudget: Ember.get(obj, 'amount.budget') || 0,
      amountOutOfBudget: Ember.get(obj, 'amount.outOfBudget') || 0,
      // the percentage of hours contributed
      percentage: hoursTotal > 0 ? (Ember.get(obj, 'hours.total') || 0) / hoursTotal : 0,
      hoursBudgetLeft: Ember.computed('hoursBudget', 'hoursSpent', function () {
        var hoursBudget = this.get('hoursBudget');
        var hoursSpent = this.get('hoursSpent');

        if (hoursBudget && hoursSpent && hoursBudget > hoursSpent) {
          return hoursBudget - hoursSpent;
        }
      }),
      amountBudgetLeft: Ember.computed('amountBudget', 'amountSpent', function () {
        var amountBudget = this.get('amountBudget');
        var amountSpent = this.get('amountSpent');

        if (amountBudget && amountSpent && amountBudget > amountSpent) {
          return amountBudget - amountSpent;
        }
      }),
      task: Ember.computed('task_id', {
        get: function get() {
          return store.peekRecord('task', this.get('task_id'));
        }
      }),
      visible: false
    }).create();
  }
  /**
   * timeEntryReference model
   * @param obj
   * @param {Number} hoursTotal the total amount of hours for the project (necessary for calculating the percentage)
   */


  function normalizeTimeEntryReferences(store, obj, hoursTotal) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'id'),
      timeEntryReference_id: Ember.get(obj, 'id'),
      // hours rounded
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursSpent: Ember.get(obj, 'hours.spent') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      hoursBudget: Ember.get(obj, 'hours.budget') || 0,
      hoursOutOfBudget: Ember.get(obj, 'hours.outOfBudget') || 0,
      // hours rounded
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || Ember.get(obj, 'hours.total') || 0,
      hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || Ember.get(obj, 'hours.spent') || 0,
      hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || Ember.get(obj, 'hours.nonBillable') || 0,
      hoursRoundedBudget: Ember.get(obj, 'hoursRounded.budget') || Ember.get(obj, 'hours.budget') || 0,
      hoursRoundedOutOfBudget: Ember.get(obj, 'hoursRounded.outOfBudget') || Ember.get(obj, 'hours.outOfBudget') || 0,
      // amounts
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      amountBudget: Ember.get(obj, 'amount.budget') || 0,
      amountOutOfBudget: Ember.get(obj, 'amount.outOfBudget') || 0,
      // the percentage of hours contributed
      percentage: hoursTotal > 0 ? (Ember.get(obj, 'hours.total') || 0) / hoursTotal : 0,
      hoursBudgetLeft: Ember.computed('hoursBudget', 'hoursSpent', function () {
        var hoursBudget = this.get('hoursBudget');
        var hoursSpent = this.get('hoursSpent');

        if (hoursBudget && hoursSpent && hoursBudget > hoursSpent) {
          return hoursBudget - hoursSpent;
        }
      }),
      amountBudgetLeft: Ember.computed('amountBudget', 'amountSpent', function () {
        var amountBudget = this.get('amountBudget');
        var amountSpent = this.get('amountSpent');

        if (amountBudget && amountSpent && amountBudget > amountSpent) {
          return amountBudget - amountSpent;
        }
      }),
      timeEntryReference: Ember.computed('timeEntryReference_id', {
        get: function get() {
          return store.peekRecord('timeEntryReference', this.get('timeEntryReference_id'));
        }
      }),
      visible: false
    }).create();
  }

  function normalizeTeams(store, obj, hoursTotal) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'id'),
      teamId: Ember.get(obj, 'id'),
      // hours rounded
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursSpent: Ember.get(obj, 'hours.spent') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      hoursBudget: Ember.get(obj, 'hours.budget') || 0,
      hoursOutOfBudget: Ember.get(obj, 'hours.outOfBudget') || 0,
      // hours rounded
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || Ember.get(obj, 'hours.total') || 0,
      hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || Ember.get(obj, 'hours.spent') || 0,
      hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || Ember.get(obj, 'hours.nonBillable') || 0,
      hoursRoundedBudget: Ember.get(obj, 'hoursRounded.budget') || Ember.get(obj, 'hours.budget') || 0,
      hoursRoundedOutOfBudget: Ember.get(obj, 'hoursRounded.outOfBudget') || Ember.get(obj, 'hours.outOfBudget') || 0,
      // amounts
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      amountBudget: Ember.get(obj, 'amount.budget') || 0,
      amountOutOfBudget: Ember.get(obj, 'amount.outOfBudget') || 0,
      // the percentage of hours contributed
      percentage: hoursTotal > 0 ? (Ember.get(obj, 'hours.total') || 0) / hoursTotal : 0,
      hoursBudgetLeft: Ember.computed('hoursBudget', 'hoursSpent', function () {
        var hoursBudget = this.get('hoursBudget');
        var hoursSpent = this.get('hoursSpent');

        if (hoursBudget && hoursSpent && hoursBudget > hoursSpent) {
          return hoursBudget - hoursSpent;
        }
      }),
      amountBudgetLeft: Ember.computed('amountBudget', 'amountSpent', function () {
        var amountBudget = this.get('amountBudget');
        var amountSpent = this.get('amountSpent');

        if (amountBudget && amountSpent && amountBudget > amountSpent) {
          return amountBudget - amountSpent;
        }
      }),
      team: Ember.computed('teamId', {
        get: function get() {
          return store.peekRecord('team', this.get('teamId'));
        }
      }),
      visible: false
    }).create();
  }
});