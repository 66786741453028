define("coffeecup/models/analytics/project", ["exports", "ember-data", "coffeecup/config/environment", "coffeecup/models/-super-model"], function (_exports, _emberData, _environment, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _ENV$constants = _environment.default.constants,
      MONTH_PERIOD_PICKER_FORMAT = _ENV$constants.MONTH_PERIOD_PICKER_FORMAT,
      DATE_MONTH_PICKER_FORMA = _ENV$constants.DATE_MONTH_PICKER_FORMA;
  var histogramStats = ['hoursSpent', 'hoursTotal', 'hoursNonBillable', 'userCosts', 'expensesTotal', 'expensesBilled', 'expensesNotBilled', 'expensesWithinBudget', 'expensesNotWithinBudget', 'expensesCosts', 'expensesCostsWithinBudget', 'expensesCostsNotWithinBudget', 'hoursRoundedSpent', 'hoursRoundedTotal', 'hoursRoundedNonBillable', 'amountSpent', 'amountBilled', 'amountNonBillable', 'hoursBillable', 'amountNotBilled', 'amountNotBilledTotal', 'amountSpentTotalIfCompleted', 'amountSpentTotal', 'profitabilityTotal', 'totalCosts'];

  var AnalyticsProject = _superModel.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    // Hours not rounded
    hoursBilled: _emberData.default.attr('number'),
    hoursBudget: _emberData.default.attr('number'),
    hoursNonBillable: _emberData.default.attr('number'),
    hoursOutOfBudget: _emberData.default.attr('number'),
    hoursSpent: _emberData.default.attr('number'),
    hoursTotal: _emberData.default.attr('number'),
    hoursPlanned: _emberData.default.attr('number'),
    hoursRoundedBilled: _emberData.default.attr('number'),
    hoursRoundedBudget: _emberData.default.attr('number'),
    hoursRoundedNonBillable: _emberData.default.attr('number'),
    hoursRoundedOutOfBudget: _emberData.default.attr('number'),
    hoursRoundedSpent: _emberData.default.attr('number'),
    hoursRoundedTotal: _emberData.default.attr('number'),

    /**
     * spent amount (not capped by the projects budget)
     */
    amountSpent: _emberData.default.attr('number'),

    /**
     * the amount of budget of the project
     */
    amountBudget: _emberData.default.attr('number'),

    /**
     * the spent amount that was out of budget
     */
    amountOutOfBudget: _emberData.default.attr('number'),

    /**
     * the amount already marked as billed
     */
    amountBilled: _emberData.default.attr('number'),

    /**
     * the sum of all invoices amounts
     */
    invoicesTotal: _emberData.default.attr('number'),
    invoices: _emberData.default.attr(),
    userCosts: _emberData.default.attr('number'),
    expensesTotal: _emberData.default.attr('number'),
    expensesBilled: _emberData.default.attr('number'),
    expensesNotBilled: _emberData.default.attr('number'),
    expensesWithinBudget: _emberData.default.attr('number'),
    expensesNotWithinBudget: _emberData.default.attr('number'),
    expensesCosts: _emberData.default.attr('number'),
    expensesCostsWithinBudget: _emberData.default.attr('number'),
    expensesCostsNotWithinBudget: _emberData.default.attr('number'),
    documents: _emberData.default.attr('number'),
    expensesOutOfBudget: Ember.computed('expensesWithinBudget', 'amountBudgetTotal', function () {
      var amountBudgetTotal = this.get('amountBudgetTotal') || 0;
      var expensesWithinBudget = this.get('expensesWithinBudget') || 0;
      return Math.max(expensesWithinBudget - amountBudgetTotal, 0);
    }),
    totalCosts: Ember.computed('userCosts', 'expensesCosts', function () {
      var expensesCosts = this.get('expensesCosts') || 0;
      var userCosts = this.get('userCosts') || 0;
      return userCosts + expensesCosts;
    }),
    firstMonthInDayFormat: Ember.computed('histogram.firstObject', function () {
      return moment(this.get('histogram.firstObject.date')).startOf('month').format(_environment.default.constants.DATE_FORMAT_DAY);
    }),
    lastMonthInDayFormat: Ember.computed('histogram.lastObject', function () {
      return moment(this.get('histogram.lastObject.date')).endOf('month').format(_environment.default.constants.DATE_FORMAT_DAY);
    }),
    // /**
    //  * chart data
    //  */
    histogram: _emberData.default.attr(),
    monthlyHistogram: Ember.computed.alias('histogram'),
    // /**
    //  * user reports data
    //  */
    users: _emberData.default.attr(),
    // /**
    //  * task reports data
    //  */
    tasks: _emberData.default.attr(),
    // /**
    //  * timeEntryReference report data
    //  */
    timeEntryReferences: _emberData.default.attr(),
    // /**
    //  * teams report data
    //  */
    teams: _emberData.default.attr(),
    // relations
    project: _emberData.default.belongsTo('project', {
      async: false
    }),
    plannedAmount: Ember.computed('hoursPlanned', 'project.hourlyRate', 'project.isBillByUser', 'project.userAssignments.@each.hourlyRate', function () {
      var _this = this;

      if (this.get('project.isBillByUser')) {
        return this.get('users').reduce(function (sum, userAnalytics) {
          var thisUserAssignment = userAnalytics.get('user.userAssignments').findBy('project.id', _this.get('project.id'));
          var userHourlyRate = thisUserAssignment ? thisUserAssignment.get('hourlyRate') : 0;
          var userHoursPlanned = userAnalytics ? userAnalytics.hoursPlanned : 0;
          return sum + userHoursPlanned * userHourlyRate;
        }, 0);
      } else {
        var hoursPlanned = this.get('hoursPlanned') || 0;
        var hourlyRate = this.get('project.hourlyRate') || 0;
        return hoursPlanned * hourlyRate;
      }
    }),
    budgetPlannedInPercent: Ember.computed('plannedAmount', 'amountRemaining', function () {
      var plannedAmount = this.get('plannedAmount') || 0;
      var amountRemaining = this.get('amountRemaining') || 0;
      return amountRemaining > 0 ? plannedAmount / amountRemaining : 0;
    }),
    unplannedAmount: Ember.computed('plannedAmount', 'amountRemaining', function () {
      return this.get('amountRemaining') - this.get('plannedAmount');
    }),
    billableForecast: Ember.computed('plannedAmount', 'amountNotBilledTotal', function () {
      return this.get('plannedAmount') + this.get('amountNotBilledTotal');
    }),

    /**
     * @returns {number} the total budget of the project including the expenses not in budget
     */
    amountBudgetTotal: Ember.computed('amountBudget', 'expensesNotWithinBudget', function () {
      var amountBudget = this.get('amountBudget') || 0;
      var expensesNotWithinBudget = this.get('expensesNotWithinBudget') || 0;
      return amountBudget + expensesNotWithinBudget;
    }),

    /**
     * @returns {number} amount spent that is spent in budget
     */
    amountSpentInBudget: Ember.computed('amountSpent', 'amountOutOfBudget', function () {
      var amountSpent = this.get('amountSpent') || 0;
      var amountOutOfBudget = this.get('amountOutOfBudget') || 0;
      return amountSpent - amountOutOfBudget;
    }),
    hasBudgetTotal: Ember.computed.gt('amountBudgetTotal', 0),
    hasNoBudgetTotal: Ember.computed.not('hasBudgetTotal'),
    amountOutOfBudgetTotal: Ember.computed('expensesOutOfBudget', 'amountOutOfBudget', function () {
      var amountOutOfBudget = this.get('amountOutOfBudget') || 0;
      var expensesOutOfBudget = this.get('expensesOutOfBudget') || 0;
      return amountOutOfBudget + expensesOutOfBudget;
    }),

    /**
     * @returns {number} the total spendings of the project including the expenses
     */
    amountSpentTotal: Ember.computed('amountSpent', 'expensesTotal', function () {
      var amountSpent = this.get('amountSpent') || 0;
      var expensesTotal = this.get('expensesTotal') || 0;
      return amountSpent + expensesTotal;
    }),
    amountSpentTotalIfCompleted: Ember.computed('amountSpentTotal', 'invoicesTotal', 'project.isCompletedFixedBudgetProject', function () {
      var isComplete = this.get('project.isCompletedFixedBudgetProject');
      return isComplete ? this.get('invoicesTotal') : this.get('amountSpentTotal');
    }),

    /**
     * @returns {number} the amount of budget that is not spent by time entries
     *
     * NOTE: This exludes expenses at all. Only use it if really necessary!
     */
    amountBudgetRemaining: Ember.computed('amountBudget', 'amountSpent', function () {
      var amountBudget = this.get('amountBudget') || 0;
      var amountSpent = this.get('amountSpent') || 0;
      return amountBudget - amountSpent;
    }),

    /**
     * @returns {number} the remaining amount that has not yet been charged
     */
    chargeableAmountRemaining: Ember.computed('amountSpentTotal', 'invoicesTotal', function () {
      var amountSpentTotal = this.get('amountSpentTotal') || 0;
      var invoicesTotal = this.get('invoicesTotal') || 0;
      return amountSpentTotal - invoicesTotal;
    }),

    /**
     * @returns {Boolean} true if more charged then spent
     */
    isOverCharged: Ember.computed.lt('chargeableAmountRemaining', 0),

    /**
     * @returns {number} Percentage of chargeable amount remaining
     */
    chargeableAmountRemainingInPercent: Ember.computed('invoicesTotal', 'amountSpentTotal', function () {
      var invoicesTotal = this.get('invoicesTotal') || 0;
      var amountSpentTotal = this.get('amountSpentTotal') || 0;
      return amountSpentTotal > 0 ? invoicesTotal / amountSpentTotal : 0;
    }),

    /**
     * @returns {number} the remaining amount of the project
     */
    amountRemaining: Ember.computed('amountBudgetTotal', 'amountSpentTotal', {
      get: function get() {
        var amountBudgetTotal = this.get('amountBudgetTotal') || 0;
        var amountSpentTotal = this.get('amountSpentTotal') || 0;
        return amountBudgetTotal - amountSpentTotal;
      }
    }),

    /**
     * @returns {number} Percentage of budget spent
     */
    budgetUsedInPercent: Ember.computed('amountSpentTotal', 'amountBudgetTotal', function () {
      var amountSpentTotal = this.get('amountSpentTotal') || 0;
      var amountBudgetTotal = this.get('amountBudgetTotal') || 0;
      return amountBudgetTotal > 0 ? amountSpentTotal / amountBudgetTotal : 1;
    }),

    /**
     * @returns {number} Percentage of budget remaining
     */
    budgetRemainingInPercent: Ember.computed('amountSpentTotal', 'amountBudgetTotal', function () {
      var amountSpentTotal = this.get('amountSpentTotal') || 0;
      var amountBudgetTotal = this.get('amountBudgetTotal') || 0;
      return amountBudgetTotal > 0 ? (amountBudgetTotal - amountSpentTotal) / amountBudgetTotal : 0;
    }),

    /**
     * @returns {Boolean} true if more spent than budget available
     */
    isSpentOverBudget: Ember.computed.lt('amountRemaining', 0),

    /**
     * @returns {number} the remaining hours of the project
     */
    hoursRemaining: Ember.computed('hoursBudget', 'hoursTotal', {
      get: function get() {
        var hoursBudget = this.get('hoursBudget') || 0;
        var hoursSpent = this.get('hoursTotal') || 0;
        return hoursBudget - hoursSpent;
      }
    }),

    /**
     * @returns {number} remaining hours in relation to the hour budget
     */
    hoursRemainingInPercent: Ember.computed('hoursRemaining', 'hoursBudget', function () {
      var hoursRemaining = this.get('hoursRemaining');
      var hoursBudget = this.get('hoursBudget');
      return hoursBudget > 0 ? hoursRemaining / hoursBudget : 0;
    }),

    /**
     * @returns {Boolean} true if no hours are left of budget
     */
    isOverHoursBudget: Ember.computed.lt('hoursRemaining', 0),

    /**
     * @returns {number} the billable amount of projects with fixed budgets
     */
    chargeableRemaining: Ember.computed('amountBudgetTotal', 'invoicesTotal', function () {
      var amountBudgetTotal = this.get('amountBudgetTotal') || 0;
      var invoicesTotal = this.get('invoicesTotal') || 0;
      var chargeableRemaining = Math.floor((amountBudgetTotal - invoicesTotal) * 100) / 100;
      return chargeableRemaining;
    }),
    hasChargeableRemaining: Ember.computed.gt('chargeableRemaining', 0),
    hasNoChargeableRemaining: Ember.computed.not('hasChargeableRemaining'),
    chargeableRemainingInPercent: Ember.computed('invoicesTotal', 'amountBudgetTotal', function () {
      var invoicesTotal = this.get('invoicesTotal') || 0;
      var amountBudgetTotal = this.get('amountBudgetTotal') || 0;
      return amountBudgetTotal > 0 ? 1 - invoicesTotal / amountBudgetTotal : 0;
    }),

    /**
     * @returns {number} the profit calculated by invoices and costs
     */
    profitabilityTotal: Ember.computed('amountSpentTotal', 'invoicesTotal', 'totalCosts', 'project.isCompletedFixedBudgetProject', function () {
      var total = this.get('amountSpentTotal') || 0;
      var invoicesTotal = this.get('invoicesTotal') || 0;
      var totalCosts = this.get('totalCosts') || 0;
      return this.get('project.isCompletedFixedBudgetProject') ? invoicesTotal - totalCosts : total - totalCosts;
    }),

    /**
     * @returns {number} the profit in relation to the invoices
     */
    profitabilityInPercent: Ember.computed('profitabilityTotal', 'amountSpentTotalIfCompleted', function () {
      var profitabilityTotal = this.get('profitabilityTotal') || 0;
      var amountSpent = this.get('amountSpentTotalIfCompleted') || 0;
      return amountSpent !== 0 ? profitabilityTotal / amountSpent : 0;
    }),

    /**
     * @returns {Boolean} true if the costs are higher then the total of invoices
     */
    isNotProfitable: Ember.computed.lt('profitabilityTotal', 0),

    /**
     * @returns {number} all hours that are billable. Billed state is not taken into account
     */
    hoursBillable: Ember.computed('hoursTotal', 'hoursNonBillable', 'hoursOutOfBudget', {
      get: function get() {
        var hoursTotal = this.get('hoursTotal') || 0;
        var hoursNonBillable = this.get('hoursNonBillable') || 0;
        var hoursOutOfBudget = this.get('hoursOutOfBudget') || 0;
        return hoursTotal - hoursNonBillable - hoursOutOfBudget;
      }
    }),

    /**
     * @returns {number} all unbillable and overbudget hours
     */
    hoursNonBillableTotal: Ember.computed('hoursNonBillable', 'hoursOutOfBudget', function () {
      return this.get('hoursNonBillable') + this.get('hoursOutOfBudget');
    }),

    /**
     * @returns {number} percent of billable hours in relation to spent hours of project
     */
    hoursBillableInPercent: Ember.computed('hoursTotal', 'hoursBillable', function () {
      var hoursTotal = this.get('hoursTotal') || 0;
      var hoursBillable = this.get('hoursBillable') || 0;
      return hoursTotal > 0 ? hoursBillable / hoursTotal : 0;
    }),
    amountNotBilled: Ember.computed('amountSpent', 'amountBilled', {
      get: function get() {
        var amountSpent = this.get('amountSpent') || 0;
        var amountBilled = this.get('amountBilled') || 0;
        return amountSpent - amountBilled;
      }
    }),

    /**
     * @returns {number} amount that has not been billed in budget
     */
    amountInBudgetNotBilled: Ember.computed('amountBudget', 'amountBilled', 'amountNotBilled', {
      get: function get() {
        var amountBudget = this.get('amountBudget') || 0;
        var amountBilled = this.get('amountBilled') || 0;
        var amountNotBilled = this.get('amountNotBilled') || 0;
        return Math.max(0, Math.min(amountNotBilled, amountBudget - amountBilled));
      }
    }),
    amountNotBilledTotal: Ember.computed('amountNotBilled', 'expensesNotBilled', function () {
      var amountNotBilled = this.get('amountNotBilled') || 0;
      var expensesNotBilled = this.get('expensesNotBilled') || 0;
      return amountNotBilled + expensesNotBilled;
    }),
    amountNotBilledTotalInPercent: Ember.computed('amountNotBilledTotal', 'amountSpentTotal', function () {
      var amountNotBilledTotal = this.get('amountNotBilledTotal') || 0;
      var amountSpentTotal = this.get('amountSpentTotal') || 0;
      return amountSpentTotal > 0 ? amountNotBilledTotal / amountSpentTotal : 0;
    }),
    isOverBilled: Ember.computed.lt('amountNotBilledTotal', 0),
    amountBilledTotal: Ember.computed('amountBilled', 'expensesBilled', function () {
      var amountBilled = this.get('amountBilled') || 0;
      var expensesBilled = this.get('expensesBilled') || 0;
      return amountBilled + expensesBilled;
    }),
    hasAmountNotBilledTotal: Ember.computed.gt('amountNotBilledTotal', 0),

    /**
     * @returns {boolean} whether this project has a budget set that can be expressed as an amount
     */
    hasAmountBudget: Ember.computed.notEmpty('amountBudget'),

    /**
     * @returns {boolean} whether this project has a budget set that can be expressed in hours
     */
    hasHoursBudget: Ember.computed.notEmpty('hoursBudget'),

    /**
     * showNotInvoicedAmount and notInvoicedAmount are used to calculate differences between the amount billed by time entries and the total amount of invoices.
     * This allows users to have the same amount for spending and invoicing.
     * For Budget projects notInvoicedAmount is capped by the total budget
     */
    showNotInvoicedAmount: Ember.computed.gt('notInvoicedAmount', 0),
    notInvoicedAmount: Ember.computed('amountBilledTotal', 'invoicesTotal', 'chargeableRemaining', function () {
      var amountBilledTotal = this.get('amountBilledTotal') || 0;
      var invoicesTotal = this.get('invoicesTotal') || 0;
      var chargeableRemaining = this.get('chargeableRemaining') || 0;
      var val = Math.max(0, Math.round((amountBilledTotal - invoicesTotal) * 100) / 100);

      if (this.get('project.isModeFixedBudget')) {
        val = Math.min(val, Math.max(0, chargeableRemaining));
      }

      return val;
    }),

    /**
     * @returns {number} the estimated remaining hours for fixed budget projects and exact hours for hour budget projects.
     * First the hourly rate of the projects is calculated by the user costs and the billable hours.
     * Based on the hourly rate the remaining hours are calculated with the remaining budget.
     */
    hoursEstimatedRemaining: Ember.computed('amountBudget', 'amountBudgetRemaining', 'hoursBudget', 'hoursTotal', 'hoursSpent', 'amountSpent', 'project.averageHourlyRate', function () {
      var amountBudget = this.get('amountBudget') || 0;
      var amountBudgetRemaining = this.get('amountBudgetRemaining') || 0;
      var hoursBudget = this.get('hoursBudget') || 0;
      var hoursTotal = this.get('hoursTotal') || 0;
      var hoursSpent = this.get('hoursSpent') || 0;
      var amountSpent = this.get('amountSpent') || 0;
      var currentProjectHourlyRate = this.get('currentProjectHourlyRate') || 0;
      var hasAmountBudget = !!amountBudget;

      if (hasAmountBudget && (isNaN(currentProjectHourlyRate) || !currentProjectHourlyRate)) {
        return hoursBudget || 0;
      }

      return hasAmountBudget ? amountBudgetRemaining / currentProjectHourlyRate : hoursBudget - hoursTotal;
    }),
    currentProjectHourlyRate: Ember.computed('hoursSpent', 'amountSpent', 'project.averageHourlyRate', function () {
      var hoursSpent = this.get('hoursSpent') || 0;
      var amountSpent = this.get('amountSpent') || 0;
      return hoursSpent ? amountSpent / hoursSpent : this.get('project.averageHourlyRate');
    }),
    hoursEstimatedBudget: Ember.computed('amountBudget', 'amountBudgetRemaining', 'hoursBudget', 'hoursTotal', 'hoursSpent', 'amountSpent', 'project.averageHourlyRate', function () {
      var amountBudget = this.get('amountBudget') || 0;
      var hoursBudget = this.get('hoursBudget') || 0;
      var hoursSpent = this.get('hoursSpent') || 0;
      var amountSpent = this.get('amountSpent') || 0;
      var hasAmountBudget = !!amountBudget;
      var currentProjectHourlyRate = this.get('currentProjectHourlyRate') || 0;
      return hasAmountBudget ? amountBudget / currentProjectHourlyRate : hoursBudget;
    }),
    // If bucketCacke is simply set to {}, the value is shared across all instances via the Prototype (!)
    bucketCache: null,
    setUpBucketCache: function () {
      this.bucketCache = {};
    }.on('init'),
    // Incoming dates are YYYYMM, MONTH_PERIOD_PICKER_FORMAT
    createBucket: function createBucket(start, end) {
      var _this$get;

      var monthRange = [];

      if (start === end) {
        monthRange = [moment(start, _environment.default.constants.MONTH_PERIOD_PICKER_FORMAT).format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT)];
      } else {
        var startMoment = moment(start, _environment.default.constants.MONTH_PERIOD_PICKER_FORMAT);
        var endMoment = moment(end, _environment.default.constants.MONTH_PERIOD_PICKER_FORMAT);
        var months = Math.max(endMoment.diff(startMoment, 'months') + 1, 1);
        startMoment.subtract(1, 'months');
        monthRange = new Array(months).fill(0).map(function () {
          return startMoment.add(1, 'months').format(_environment.default.constants.DATE_MONTH_PICKER_FORMAT);
        });
      }

      var monthsForPeriod = ((_this$get = this.get('histogram')) !== null && _this$get !== void 0 ? _this$get : []).filter(function (month) {
        return monthRange.includes(month.date.substring(0, 7));
      });
      var summedAnalytics = monthsForPeriod.reduce(function (periodResult, month) {
        var _iterator = _createForOfIteratorHelper(histogramStats),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _ref, _periodResult$key;

            var key = _step.value;
            periodResult[key] = (_ref = ((_periodResult$key = periodResult[key]) !== null && _periodResult$key !== void 0 ? _periodResult$key : 0) + month[key]) !== null && _ref !== void 0 ? _ref : 0;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        return periodResult;
      }, {}); // Fix percent based stat

      summedAnalytics.profitabilityInPercent = summedAnalytics.amountSpentTotalIfCompleted && summedAnalytics.profitabilityTotal ? summedAnalytics.profitabilityTotal / summedAnalytics.amountSpentTotalIfCompleted : 0;
      var bucketKey = "".concat(start, "-").concat(end);
      this.bucketCache[bucketKey] = summedAnalytics;
    },
    getBucketedValue: function getBucketedValue(propertyKey, start, end) {
      var _this$bucketCache$buc;

      var bucketKey = "".concat(start, "-").concat(end);

      if (!this.bucketCache[bucketKey]) {
        this.createBucket(start, end);
      }

      return (_this$bucketCache$buc = this.bucketCache[bucketKey][propertyKey]) !== null && _this$bucketCache$buc !== void 0 ? _this$bucketCache$buc : 0;
    }
  });

  var _default = AnalyticsProject;
  _exports.default = _default;
});