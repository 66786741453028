define("coffeecup/mixins/has-error", ["exports", "coffeecup/utils/ui/i18n"], function (_exports, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HasErrorMixin = Ember.Mixin.create({
    classNameBindings: ['hasError:has-error'],
    errors: null,
    errorMsg: Ember.computed.alias('errors.validation.firstObject'),
    hasError: Ember.computed('errorMsg', {
      get: function get() {
        return !!this.get('errorMsg');
      }
    }),
    translatedError: Ember.computed('errorMsg', function () {
      return (0, _i18n.translateValidation)(this.get('i18n'), this.get('errorMsg'));
    })
  });
  var _default = HasErrorMixin;
  _exports.default = _default;
});