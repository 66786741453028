define("coffeecup/templates/components/content-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BwHAbA2g",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pull-left\"],[12],[2,\"\\n      \"],[10,\"h4\"],[12],[1,[34,4]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n        \"],[10,\"h4\"],[12],[2,[34,2]],[13],[2,\"\\n\"],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n            \"],[10,\"small\"],[12],[1,[34,1]],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"panel-body \",[30,[36,3],[[35,5],\"large-header\"],null]]]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"panel-footer\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"footerLabel\",\"headerLabelSubtitleRight\",\"headerLabelRight\",\"if\",\"headerLabel\",\"largeHeader\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/content-panel.hbs"
    }
  });

  _exports.default = _default;
});