define("coffeecup/utils/build-url", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildUrl;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   *
   * @param {Ember.store} store reference to ember-data store
   * @param {string} path
   * @param {Object} params (optional)
   * @returns {string} url
   */
  function buildUrl(store, path, params) {
    var host = _environment.default.apiHost || '';
    var namespace = store.adapterFor('application').namespace;
    var url = '';

    if (namespace) {
      url = [host, namespace, path].join('/');
    } else {
      url = [host, path].join('/');
    }

    if (_typeof(params) === 'object') {
      url += '?' + Ember.$.param(params);
    }

    return url;
  }
});