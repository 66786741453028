define("coffeecup/templates/start/-resetpassword-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GTBLxN4N",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"footer\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"© \"],[1,[30,[36,0],[\"YYYY\"],null]],[2,\" \"],[1,[30,[36,1],[\"coffeecup\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"current-date\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/start/-resetpassword-footer.hbs"
    }
  });

  _exports.default = _default;
});