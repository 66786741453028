define("coffeecup/routes/time-entries/verifying/monthly", ["exports", "coffeecup/config/environment", "coffeecup/routes/_super-route", "coffeecup/utils/build-url"], function (_exports, _environment, _superRoute, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    validationService: Ember.inject.service('validation'),
    session: Ember.inject.service(),
    queryParams: {
      month: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      var worktimeController = this.controllerFor('time-entries.verifying');
      var controller = this.controllerFor('time-entries.verifying.monthly');
      var month = params && params.month || worktimeController.get('month');
      controller.set('pageIsLoading', true);
      var baseDate = moment(month, _environment.default.constants.DATE_MONTH_PICKER_FORMAT).endOf('month').isAfter(moment()) ? moment() : moment(month, _environment.default.constants.DATE_MONTH_PICKER_FORMAT).endOf('month');
      var startDate = moment(baseDate).startOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY);
      var endDate = moment(baseDate).endOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY);
      var allUsers = this.store.peekAll('user');
      var anyUserIsValidated = allUsers && allUsers.filter(function (el) {
        return el.get('supervisor.id');
      });

      if (anyUserIsValidated && anyUserIsValidated.length > 0) {
        return new Promise(function (resolve, reject) {
          return _this.get('store').query('validation', {
            where: {
              week: {
                '>=': moment(baseDate).startOf('month').startOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY),
                '<=': baseDate.format(_environment.default.constants.DATE_FORMAT_DAY)
              }
            }
          }).then(function (validations) {
            return _this.get('session').getJSON((0, _buildUrl.default)(_this.store, 'validate/checkUserWorktime', {
              startDate: startDate,
              endDate: endDate
            })).then(function (rawUserData) {
              var weekData = {
                startDate: startDate,
                endDate: endDate
              };
              var userData = rawUserData.userData.map(function (user) {
                user.user = _this.get('store').peekRecord('user', user.id);
                user.validation = user.isAlreadyValidated ? _this.store.peekRecord('validation', user.isAlreadyValidated) : null;
                user.holidays = _this.store.peekAll('user-holiday').filter(function (el) {
                  return el.get('date').isSameOrAfter(startDate, 'day') && el.get('date').isSameOrBefore(endDate, 'day');
                });
                return user;
              });
              weekData.userData = userData;
              var rawData = {};

              if (weekData && weekData.userData && weekData.userData.length > 0) {
                rawData[startDate] = weekData;
              }

              resolve({
                weekData: rawData
              });
            }).catch(reject);
          }).catch(reject);
        });
      } else {
        return {
          noValidationActive: true
        };
      }
    },
    setupController: function setupController(controller, model) {
      if (model.noValidationActive) {
        controller.set('pageIsLoading', false);
        controller.set('noValidationActive', true);
      } else {
        this._super(controller, model);

        controller.set('weeks', model.weekData);
        controller.set('noValidationActive', false);
        controller.set('pageIsLoading', false);

        if (model.loadPreviousWeek) {
          this.controller.send('loadPreviousWeek', moment().startOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY));
        }
      }
    },
    actions: {
      removeFromList: function removeFromList(userData) {
        this.get('userData').removeObject(userData);
      }
    }
  });

  _exports.default = _default;
});