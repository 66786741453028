define("coffeecup/routes/forgot-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ForgotPasswordRoute = Ember.Route.extend({
    setupController: function setupController(controller, model) {} //,

    /*renderTemplate: function () {
      this.render({outlet: 'forgot-password'});
    }*/

  });
  var _default = ForgotPasswordRoute;
  _exports.default = _default;
});