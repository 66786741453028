define("coffeecup/templates/components/analytics-module/project/hour-budget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "szczIe8K",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"analytics-module\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"module-title\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"draggable\",\"title\"],[false,[30,[36,0],[\"budgetHours\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"module-body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"chart-container gauge small\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"chart-frame\"],[12],[2,\"\\n        \"],[1,[30,[36,8],null,[[\"title\",\"useAlertColor\",\"data\",\"description\",\"small\"],[[30,[36,7],[[35,6],\"auto\"],null],[35,5],[35,4],[30,[36,3],[[35,2]],null],true]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"data\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"kpi-wrapper main-kpi\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"kpi-label\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"totalLabel\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"kpi-value\"],[12],[2,\"\\n          \"],[1,[30,[36,7],[[35,9]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"kpi-wrapper main-kpi space-top\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"kpi-label\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"effort\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"kpi-value\"],[12],[2,\"\\n          \"],[1,[30,[36,7],[[35,10]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"dashboard-module/top-bar\",\"hoursRemainingInPercent\",\"percent-fmt\",\"pieChartData\",\"isOverHoursBudget\",\"hoursRemaining\",\"hours-fmt\",\"pie-chart\",\"hoursBudget\",\"hoursSpent\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/project/hour-budget.hbs"
    }
  });

  _exports.default = _default;
});