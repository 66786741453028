define("coffeecup/services/responsive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Extra small screen / phone
  var SCREEN_XS = 480;
  var SCREEN_XS_MIN = SCREEN_XS;
  var SCREEN_PHONE = SCREEN_XS_MIN; // Small screen / tablet
  //** Deprecated `@screen-sm` as of v3.0.1

  var SCREEN_SM = 768;
  var SCREEN_SM_MIN = SCREEN_SM;
  var SCREEN_TABLET = SCREEN_SM_MIN; // Medium screen / desktop

  var SCREEN_MD = 860;
  var SCREEN_MD_MIN = SCREEN_MD;
  var SCREEN_DESKTOP = SCREEN_MD_MIN; // Large screen / wide desktop

  var SCREEN_LG = 1200;
  var SCREEN_LG_MIN = SCREEN_LG;
  var SCREEN_LG_DEsKTOP = SCREEN_LG_MIN; // So media queries don't overlap when required, provide a maximum

  var SCREEN_XS_MAX = SCREEN_SM_MIN - 1;
  var SCREEN_SM_MAX = SCREEN_MD_MIN - 1;
  var SCREEN_MD_MAX = SCREEN_LG_MIN - 1;

  var is_touch_device = function is_touch_device() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    var mq = function mq(query) {
      return window.matchMedia(query).matches;
    };

    if ('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch) {
      return true;
    } // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH


    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
  };
  /**
   * Service with utils for responsive design, particularly targeting mobile devices.
   *
   * @author Alexander Stonehouse
   */


  var ResponsiveService = Ember.Service.extend({
    /**
     * Because there are only two mobile operating systems right???
     *
     * @returns {true if Android or iOS}
     */
    isMobile: function isMobile() {
      return this.isIOS() || this.isAndroid();
    },
    isTouch: function isTouch() {
      return is_touch_device();
    },
    isIOS: function isIOS() {
      var ua = this.getUA();
      return /(ipod|iphone|ipad)/.test(ua) && /applewebkit/.test(ua);
    },
    isAndroid: function isAndroid() {
      return this.getUA().indexOf('android') !== -1;
    },
    isMobileWidth: function isMobileWidth() {
      if ($ && $(window)) {
        return $(window).width() < SCREEN_SM;
      }

      return false;
    },
    isTabletWidth: function isTabletWidth() {
      if ($ && $(window)) {
        return $(window).width() < SCREEN_MD && $(window).width() >= SCREEN_SM;
      }

      return false;
    },
    isDesktopWidth: function isDesktopWidth() {
      if ($ && $(window)) {
        return $(window).width() < SCREEN_LG && $(window).width() >= SCREEN_MD;
      }

      return false;
    },
    isDesktopWideWidth: function isDesktopWideWidth() {
      if ($ && $(window)) {
        return $(window).width() >= SCREEN_LG;
      }

      return false;
    },
    getUA: function getUA() {
      if (navigator && navigator.userAgent && typeof navigator.userAgent === 'string') {
        return navigator.userAgent.toLowerCase();
      } else {
        return '';
      }
    }
  });
  var _default = ResponsiveService;
  _exports.default = _default;
});