define("coffeecup/routes/analytics/projects/details/complete", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    session: Ember.inject.service(),
    setupController: function setupController(controller, context) {
      controller.set('openModal', true);
      controller.set('selectedProject', context.selectedProject);
      controller.set('selectedProjectAnalytics', context.selectedProjectAnalytics.get('firstObject'));
    },
    model: function model(params, transition) {
      var from = this.get('clock.localDayAsUtcMoment').startOf('year');
      var to = from.clone().endOf('year');
      var projectDetailsRoute = transition.routeInfos.filter(function (el) {
        return el.name === 'analytics.projects.details';
      });
      var projectId = projectDetailsRoute.get('firstObject.params.project_id');
      var interval = 'month';
      var histogramType = 'total';
      var selectedProject = this.store.peekRecord('project', projectId);
      var selectedProjectAnalytics = this.store.query('analytics/project', {
        project: projectId,
        histogramInterval: interval,
        histogramType: histogramType
      });
      var projects = this.store.peekAll('project');
      return Ember.RSVP.hash({
        selectedProject: selectedProject,
        selectedProjectAnalytics: selectedProjectAnalytics,
        projects: projects
      });
    },
    actions: {
      transitionAway: function transitionAway() {
        this.transitionTo('analytics.projects.details');
      }
    }
  });

  _exports.default = _default;
});