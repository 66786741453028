define("coffeecup/validations/user", ["exports", "ember-changeset-validations/validators", "coffeecup/validators/validateUnique"], function (_exports, _validators, _validateUnique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      firstname: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      lastname: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      email: {
        length: {
          maximum: 128
        },
        unique: true
      },
      privateEmail: {
        length: {
          maximum: 128
        }
      },
      role: {
        presence: true
      },
      hourlyRate: {
        presence: true
      },
      street: {
        length: {
          maximum: 128
        }
      },
      postCode: {
        length: {
          maximum: 12
        }
      },
      city: {
        length: {
          maximum: 64
        }
      },
      jobLabel: {
        length: {
          maximum: 128
        }
      },
      department: {
        length: {
          maximum: 128
        }
      },
      bankName: {
        length: {
          maximum: 128
        }
      },
      ibanNumber: {
        length: {
          maximum: 128
        }
      },
      bicNumber: {
        length: {
          maximum: 128
        }
      },
      insuranceName: {
        length: {
          maximum: 128
        }
      },
      country: {
        length: {
          maximum: 128
        }
      },
      birthplace: {
        length: {
          maximum: 128
        }
      },
      mobileNumber: {
        length: {
          maximum: 45
        }
      },
      phoneNumber: {
        length: {
          maximum: 45
        }
      },
      faxNumber: {
        length: {
          maximum: 45
        }
      }
    },
    firstname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    lastname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    email: [(0, _validators.validateLength)({
      max: 128
    }), (0, _validateUnique.default)(), (0, _validators.validateFormat)({
      type: 'email'
    })],
    privateEmail: [(0, _validators.validateLength)({
      max: 128
    }), (0, _validators.validateFormat)({
      allowBlank: true,
      type: 'email'
    })],
    role: [(0, _validators.validatePresence)(true)],
    hourlyRate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      lte: 999999
    })],
    street: [(0, _validators.validateLength)({
      max: 128
    })],
    postCode: [(0, _validators.validateLength)({
      max: 12
    })],
    city: [(0, _validators.validateLength)({
      max: 64
    })],
    jobLabel: [(0, _validators.validateLength)({
      max: 128
    })],
    department: [(0, _validators.validateLength)({
      max: 128
    })],
    bankName: [(0, _validators.validateLength)({
      max: 128
    })],
    ibanNumber: [(0, _validators.validateLength)({
      max: 128
    })],
    bicNumber: [(0, _validators.validateLength)({
      max: 128
    })],
    insuranceName: [(0, _validators.validateLength)({
      max: 128
    })],
    country: [(0, _validators.validateLength)({
      max: 128
    })],
    birthplace: [(0, _validators.validateLength)({
      max: 128
    })],
    mobileNumber: [(0, _validators.validateLength)({
      max: 45
    })],
    phoneNumber: [(0, _validators.validateLength)({
      max: 45
    })],
    faxNumber: [(0, _validators.validateLength)({
      max: 45
    })]
  };
  _exports.default = _default;
});