define("coffeecup/utils/browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //utils/browser.js

  /**
   * browser type and browser version detection
   * http://stackoverflow.com/questions/5916900/detect-version-of-browser
   */
  var browser = {
    getType: function getType() {
      var ua = navigator.userAgent,
          tem,
          M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }

      if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/);

        if (tem != null) {
          return 'Opera ' + tem[1];
        }
      }

      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }

      return M[0];
    },
    getVersion: function getVersion() {
      var ua = navigator.userAgent,
          tem,
          M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

      if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE ' + (tem[1] || '');
      }

      if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/);

        if (tem != null) {
          return 'Opera ' + tem[1];
        }
      }

      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

      if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
      }

      return M[1];
    }
  };
  var _default = browser;
  _exports.default = _default;
});