define("coffeecup/templates/components/input/cc-slider-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "99vVfIVr",
    "block": "{\"symbols\":[\"@label\",\"@sliderClass\",\"@disabled\",\"@value\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"checkbox checkbox-slider-b-flat \",[30,[36,1],[[32,3],\"disabled\"],null],\" \",[32,2]]]],[4,[38,2],[[32,0],\"onChange\"],null],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[10,\"input\"],[15,\"checked\",[32,4]],[15,\"disabled\",[32,3]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/cc-slider-checkbox.hbs"
    }
  });

  _exports.default = _default;
});