define("coffeecup/validations/document", ["exports", "ember-changeset-validations/validators", "coffeecup/validators/validateRelation"], function (_exports, _validators, _validateRelation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    date: [(0, _validators.validatePresence)(true)],
    attachment: [(0, _validators.validatePresence)(true)],
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 255
    })],
    category: [(0, _validateRelation.default)({
      presence: true
    })],
    model: [(0, _validators.validatePresence)(true)],
    record: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});