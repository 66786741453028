define("coffeecup/routes/planner/absences/calendar/confirm", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    setupController: function setupController(controller, context, transition) {
      controller.set('closeModal', false);
      controller.set('alreadyAdded', false);

      this._super.apply(this, [controller, context, transition]);

      Ember.run.later(function () {
        return controller.set('openModal', true);
      });
    },
    actions: {
      transitionToList: function transitionToList() {
        this.transitionTo('planner.absences.calendar');
      }
    },
    model: function model(params) {
      return this.store.findRecord('absence-request', params.absence_request_id);
    }
  });

  _exports.default = _default;
});