define("coffeecup/templates/components/analytics-module/kpi/kpi-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MsuJHrsu",
    "block": "{\"symbols\":[\"&default\",\"@isAlert\",\"@label\",\"@negative\",\"@amount\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[14,0,\"kpi-cell\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"kpi-content \",[30,[36,0],[[32,0,[\"hasData\"]],\"has-data\"],null]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"kpi-red-dot\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"icomoon-alert\"],[14,5,\"font-size: 20px;\"],[12],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"kpi-info\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"kpi-label\"],[12],[2,\"\\n        \"],[1,[32,3]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"kpi-amount \",[30,[36,0],[[32,4],\"negative\"],null]]]],[12],[2,\"\\n        \"],[1,[32,5]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,0],[[30,[36,1],[[32,6],\"default\",[27,[32,1]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"cc-ember-tooltip\",[],[[\"@side\",\"@popperContainer\"],[\"bottom\",\"main\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,1,null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"-has-block\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/kpi/kpi-cell.hbs"
    }
  });

  _exports.default = _default;
});