define("coffeecup/utils/initials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateInitials;

  function generateInitials(name) {
    if (Ember.isEmpty(name)) {
      return '';
    }

    if (typeof name.split === 'undefined') {
      return '';
    }

    var nameArray = name.split(' ');
    var i1, i2;

    if (name) {
      i1 = name.charAt(0);

      if (nameArray.length > 2) {
        i2 = nameArray[nameArray.length - 1].charAt(0);
      } else if (nameArray.length == 2) {
        i2 = nameArray[1].charAt(0);
      } else {
        i2 = nameArray[0].charAt(1);
      }
    }

    return i1 + i2;
  }
});