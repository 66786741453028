define("coffeecup/serializers/time-entry", ["exports", "ember-data", "coffeecup/serializers/application", "coffeecup/config/environment", "coffeecup/utils/date"], function (_exports, _emberData, _application, _environment, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      hourlyRate: {
        serialize: false
      },
      // makes the attribute readOnly
      billable: {
        serialize: false
      } // makes the attribute readOnly

    },
    clockService: function clockService() {
      return Ember.getOwner(this).lookup('service:clock');
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      var timerModeIsDuration = Ember.getOwner(this).lookup('controller:application').get('accountSettings.timerModeIsDuration');
      var timerModeIsStartTimeEndTime = Ember.getOwner(this).lookup('controller:application').get('accountSettings.timerModeIsStartTimeEndTime');
      var disallowedFields = _environment.default.constants.TIME_ENTRY_DISALLOWED_FIELDS_IN_DURATION_MODE;
      var nullingerFields = _environment.default.constants.TIME_ENTRY_NULLINGER_FIELDS_IN_DURATION_MODE;

      if (timerModeIsStartTimeEndTime) {
        disallowedFields = _environment.default.constants.TIME_ENTRY_DISALLOWED_FIELDS_IN_START_END_MODE;
        nullingerFields = _environment.default.constants.TIME_ENTRY_NULLINGER_FIELDS_IN_START_END_MODE;
      }

      if (timerModeIsDuration) {
        if (key === 'sorting' && Ember.isEmpty(json[key])) {
          var sortingOrStartTime = snapshot.record.get('sortingOrStartTime');
          json[key] = sortingOrStartTime;
          return;
        }
      }

      if (nullingerFields.indexOf(key) > -1) {
        json[key] = null;
        return;
      }

      if (disallowedFields.indexOf(key) === -1) {
        if (snapshot.record.get('isNew') || snapshot.changedAttributes()[key]) {
          this._super(snapshot, json, key, attributes);
        }
      }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      var timerModeIsStartTimeEndTime = Ember.getOwner(this).lookup('controller:application').get('accountSettings.timerModeIsStartTimeEndTime');
      var timeEntries = payload && Ember.get(payload, 'timeEntries');

      if (!timeEntries && payload && id && Ember.get(payload, 'timeEntry')) {
        timeEntries = [Ember.get(payload, 'timeEntry')];
      }

      if (timerModeIsStartTimeEndTime && timeEntries) {
        var clock = this.clockService();
        var now = Ember.get(clock, 'now');
        var hoursAndMinutes = Ember.get(clock, 'now').startOf('minute');
        now.utc().set({
          hour: hoursAndMinutes.format('HH'),
          minute: hoursAndMinutes.format('mm'),
          second: hoursAndMinutes.format('ss')
        });
        timeEntries.forEach(function (timeEntry) {
          if (Ember.get(timeEntry, 'running')) {
            var startTimeAsDate = moment.utc(Ember.get(timeEntry, 'day'), _environment.default.constants.DATE_FORMAT_DAY);

            if ((0, _date.isDateValid)(startTimeAsDate) && Ember.get(timeEntry, 'startTime')) {
              var startTime = Ember.get(timeEntry, 'startTime').split(':');
              startTimeAsDate.set({
                hour: startTime[0],
                minute: startTime[1],
                second: startTime[2]
              });
            }

            timeEntry.duration = now.diff(startTimeAsDate, 'seconds');
          }
        });
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});