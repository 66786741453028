define("coffeecup/templates/planner/absences/scheduler/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TfyXi1KY",
    "block": "{\"symbols\":[],\"statements\":[[19,\"planner/absences/calendar/new\",[]]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/planner/absences/scheduler/new.hbs"
    }
  });

  _exports.default = _default;
});