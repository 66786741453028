define("coffeecup/routes/planner", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // route/planner.js
  var _default = _superRoute.default.extend({
    viewport: Ember.inject.service('planner.viewport'),
    zoom: Ember.inject.service('planner.zoom'),
    analytics: Ember.inject.service(),
    notifications: Ember.inject.service(),
    permissions: Ember.inject.service(),
    currentUser: Ember.inject.service('currentUser'),
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    lastRequest: null,
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var upstreamPromise = this._super.apply(this, arguments);

      return Ember.RSVP.resolve(upstreamPromise).then(function () {
        // NOTE: upstream has to be resolved in order to get access to the followin properties
        var plannerIsDisabled = !_this.get('permissions.plannerActive');

        var userHasAccessToPlanner = _this.get('permissions.canViewPlanner');

        if ((plannerIsDisabled || !userHasAccessToPlanner) && transition.targetName.indexOf('absences') === -1) {
          transition.abort();

          _this.transitionTo('planner.absences');
        }
      });
    },
    titleToken: function titleToken() {
      return this.get('i18n').t('planning');
    },
    setupController: function setupController(controller, context) {
      this._super.apply(this, arguments);

      var startDate = this.get('viewport.startDate');
      var endDate = this.get('viewport.endDate');
      controller.set('existingTags', this.store.peekAll('tag'));
      controller.set('tagAssignments', this.store.peekAll('tag-assignment'));

      this._getCachedData(startDate, endDate);
    },
    _getCachedData: function _getCachedData(startDate, endDate) {
      var _this2 = this;

      // TODO implement actual local caching ..
      // .. e.g. load all absences from the year, save the result to the controller and filter it there
      var controller = this.get('controller');
      var appController = this.controllerFor('application');
      var fromWithPaddingJSON = startDate.clone().subtract(7, 'days').startOf('day').toJSON();
      var toWithPaddingJSON = endDate.clone().add(7, 'days').endOf('day').toJSON();
      var currentRequest = this.lastRequest = Date.now();
      appController.set('isLoading', true);
      controller.set('disableFrameCalculation', true);
      var where = {
        or: [{
          startDate: {
            '>=': fromWithPaddingJSON,
            '<=': toWithPaddingJSON
          }
        }, {
          endDate: {
            '>=': fromWithPaddingJSON,
            '<=': toWithPaddingJSON
          }
        }, {
          startDate: {
            '<': fromWithPaddingJSON
          },
          endDate: {
            '>': toWithPaddingJSON
          }
        }]
      };
      Ember.RSVP.hash({
        assignments: this.store.query('planner-assignment', {
          limit: 5000,
          where: where
        }),
        absences: this.store.query('absence', {
          limit: 5000,
          where: where
        }),
        absenceRequests: this.store.query('absence-request', {
          limit: 5000,
          where: Ember.merge({
            state: null
          }, where)
        }),
        milestones: this.store.query('project-milestone', {
          limit: 5000,
          where: {
            day: {
              '>=': fromWithPaddingJSON,
              '<=': toWithPaddingJSON
            }
          }
        })
      }).then(function (data) {
        if (currentRequest === _this2.lastRequest) {
          appController.set('isLoading', false);
          Ember.run.later(function () {
            controller.setProperties({
              disableFrameCalculation: false,
              assignments: data.assignments.toArray(),
              absences: data.absences.toArray(),
              milestones: data.milestones.toArray(),
              absenceRequests: data.absenceRequests.toArray()
            });
          });
        }
      }).catch(function (response) {
        appController.set('isLoading', false);
        console.error(response);
      });
    },
    model: function model() {// empty to avoid race conditions with viewportChanged
    },
    refreshDataAfterSocketUpdate: function refreshDataAfterSocketUpdate() {
      var startDate = this.get('viewport.startDate');
      var endDate = this.get('viewport.endDate');

      this._getCachedData(startDate, endDate);
    },
    _handleSocketUpdates: function () {
      var sailsSocket = this.get('sailsSocket');
      sailsSocket.on('absencerequest.created', this, this.refreshDataAfterSocketUpdate);
    }.on('activate'),
    _disableSocketUpdates: function () {
      var sailsSocket = this.get('sailsSocket');

      if (sailsSocket.hasObserverFor('absencerequest.created')) {
        sailsSocket.off('absencerequest.created', this, this.refreshDataAfterSocketUpdate);
      }
    }.on('deactivate'),
    actions: {
      viewportChanged: function viewportChanged(startDate, endDate) {
        this._getCachedData(startDate, endDate);

        this.controller.setUserWorkingHours();
      },
      goToPreviousWeek: function goToPreviousWeek() {
        this.controller.send('goToPreviousWeek');
      },
      goToNextWeek: function goToNextWeek() {
        this.controller.send('goToNextWeek');
      },
      goToThisWeek: function goToThisWeek() {
        this.controller.send('goToThisWeek');
      },
      zoomIn: function zoomIn() {
        this.controller.send('zoomIn');
      },
      zoomOut: function zoomOut() {
        this.controller.send('zoomOut');
      },
      fetchProjectsSummaryData: function fetchProjectsSummaryData() {
        var showSummary = this.get('viewport.showSummary');

        if (showSummary) {
          Ember.RSVP.hash({
            analytics: this.store.findAll('analytics/project')
          });
        }
      }
    }
  });

  _exports.default = _default;
});