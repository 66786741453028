define("coffeecup/routes/time-entries/verifying", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    validationService: Ember.inject.service('validation'),
    queryParams: {
      team: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (this.get('validationService.numberOfOpenValidations')) {//this.transitionTo('time-entries.verifying.list');
      }
    }
  });

  _exports.default = _default;
});