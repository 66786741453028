define("coffeecup/routes/analytics", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var AnalyticsRoute = _superRoute.default.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('analytics');
    },
    setupController: function setupController(controller, context) {
      // Find the parent category for Analytics.
      // In the new structure, the only item is TE analytics
      var menu = context.findBy('url', 'analytics');

      if (!menu) {
        menu = context.findBy('url', 'analytics.time-entries').get('parent');
      }

      controller.set('menu', menu.get('submenu'));

      this._super.apply(this, arguments);
    },
    model: function model() {
      return this.store.peekAll('menu');
    }
  });

  var _default = AnalyticsRoute;
  _exports.default = _default;
});