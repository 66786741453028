define("coffeecup/mixins/project-budget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    project: null,
    isByEffort: Ember.computed.alias('project.isModeByEffort'),
    isNotByEffort: Ember.computed.not('isByEffort'),
    isNotBillable: Ember.computed.alias('project.isNotBillable'),
    isBillable: Ember.computed.alias('project.isBillable'),
    hoursBudget: Ember.computed.alias('project.hoursBudget'),
    amountBudget: Ember.computed.alias('project.amountBudgetTotal'),
    expensesNotWithinBudget: Ember.computed.alias('project.expensesNotWithinBudget'),
    isBudgetByAmount: Ember.computed.alias('project.isModeFixedBudget'),
    isNotModeFixedBudget: Ember.computed.not('isBudgetByAmount'),
    isBudgetByHours: Ember.computed.and('isBillable', 'isNotModeFixedBudget', 'isNotByEffort')
  });

  _exports.default = _default;
});