define("coffeecup/routes/projects/new", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    projectService: Ember.inject.service('project'),
    queryParams: {
      clientId: ''
    },
    model: function model(params, transition) {
      var _this = this;

      var allUsers = this.store.peekAll('user');
      var allTasks = this.store.peekAll('task');
      var currentUser = this.getCurrentUser();
      var clientId = transition.to.queryParams.clientId;
      return this.get('projectService').createDefaultProject(params.clientId).then(function (project) {
        return _this.get('projectService').createTaskAssignments(project, allTasks).then(function () {
          return _this.get('projectService').createUserAssignments(project, allUsers, currentUser).then(function () {
            return _this.store.findAll('user-assignment', {
              reload: true
            }).then(function (items) {
              return _this.transitionTo('projects.edit', project.get('id'), {
                queryParams: {
                  isNewProject: true
                }
              });
            });
          });
        });
      });
    }
  });

  _exports.default = _default;
});