define("coffeecup/templates/components/input/text-field-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uhPdvW4W",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,9],null,[[\"value\",\"update\",\"options\",\"disabled\",\"class\",\"style\",\"escape-press\"],[[35,6],[30,[36,8],[[32,0],[30,[36,7],[[35,6]],null]],null],[35,5],[35,4],[30,[36,3],[\"ember-text-field form-control textfield-price\",\" \",[30,[36,2],[[35,1],\"large-text\"],null],\" \"],null],[35,0],\"cancel\"]]]],[2,\"\\n\"],[10,\"span\"],[15,0,[31,[\"form-control-feedback currency-symbol\\n    \",[30,[36,2],[[35,4],\"disabled\"],null],\"\\n    \",[30,[36,2],[[35,11],\"wide-currency\"],null],\"\\n    \",[30,[36,2],[[35,10],\"custom-currency-width\"],null]]]],[12],[2,\"\\n  \"],[1,[34,12]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"customCurrencyPadding\",\"isLarge\",\"if\",\"concat\",\"disabled\",\"options\",\"currency\",\"mut\",\"action\",\"one-way-currency-mask\",\"customCurrency\",\"isSymbolWide\",\"currencySymbol\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/text-field-price.hbs"
    }
  });

  _exports.default = _default;
});