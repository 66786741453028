define("coffeecup/templates/components/time-entries/header/absence-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yZKM+892",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"absenceType\",\"typeImageHTML\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[35,0,[\"absenceType\",\"typeImageHTML\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0,[\"name\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"custom-icon icomoon-church holiday\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"absence\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/time-entries/header/absence-icon.hbs"
    }
  });

  _exports.default = _default;
});