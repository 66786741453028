define("coffeecup/templates/components/analytics/project-budget-remaining", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LHHHeDhL",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"b\"],[12],[1,[30,[36,2],null,[[\"project\"],[[35,0]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"b\"],[12],[1,[30,[36,1],null,[[\"project\"],[[35,0]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"project\",\"analytics/project-budget-remaining-hours\",\"analytics/project-budget-remaining-amount\",\"isBudgetByAmount\",\"if\",\"isBudgetByHours\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics/project-budget-remaining.hbs"
    }
  });

  _exports.default = _default;
});