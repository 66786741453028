define("coffeecup/initializers/momentjs-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // app/initializers/momentjs-custom.js
  function initialize() {
    moment.fn.isSameOrAfter = function (input, units) {
      return this.isSame(input, units) || this.isAfter(input, units);
    };

    moment.fn.isSameOrBefore = function (input, units) {
      return this.isSame(input, units) || this.isBefore(input, units);
    };

    moment.fn.isWeekend = function () {
      return this.day() % 6 === 0;
    };
    /**
     *  @README https://github.com/kalmecak/moment-business-days
     */


    moment.fn.isHoliday = function () {
      var locale = this.localeData();

      if (locale._holidays) {
        if (locale._holidays.indexOf(this.format(locale._holidayFormat)) >= 0) return true;
      }

      if (locale.holiday) {
        if (locale.holiday(this)) {
          return true;
        } else {
          return false;
        }
      }

      return false;
    };

    moment.fn.isBusinessDay = function () {
      var locale = this.localeData();
      var defaultWorkingWeekdays = [1, 2, 3, 4, 5];
      var workingWeekdays = locale._workingWeekdays || defaultWorkingWeekdays;
      if (this.isHoliday()) return false;
      if (workingWeekdays.indexOf(this.day()) >= 0) return true;
      return false;
    };

    moment.fn.businessDaysIntoMonth = function () {
      var businessDay = this.isBusinessDay() ? this : this.prevBusinessDay();
      var monthBusinessDays = businessDay.monthBusinessDays();
      var businessDaysIntoMonth;
      monthBusinessDays.map(function (day, index) {
        if (day.format('M/DD/YY') === businessDay.format('M/DD/YY')) {
          businessDaysIntoMonth = index + 1;
        }
      });
      return businessDaysIntoMonth;
    };

    moment.fn.businessDiff = function (param) {
      var d1 = this;
      var d2 = param;
      var factor = d2.isBefore(d1) ? 1 : -1;
      var start = (d1 < d2 ? d1 : d2).clone();
      var end = d2 > d1 ? d2 : d1;
      var daysBetween = -1;

      if (start.dateAlignedSame(end, 'day')) {
        return 0;
      }

      while (start.dateAlignedSameOrBefore(end, 'day')) {
        if (start.isBusinessDay()) {
          daysBetween++;
        }

        start.add(1, 'd');
      }

      return factor * daysBetween;
    };

    var secondsInDay = 24 * 60 * 60;

    moment.fn.isDateAligned = function () {
      return this.unix() % secondsInDay === 0;
    };

    moment.fn.dateAlignedSameOrBefore = function (param) {
      var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'day';

      if (unit === 'day' && this.isDateAligned() && param.isDateAligned()) {
        return this <= param;
      }

      return this.isSameOrBefore(param, unit);
    };

    moment.fn.dateAlignedSameOrAfter = function (param) {
      var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'day';

      if (unit === 'day' && this.isDateAligned() && param.isDateAligned()) {
        return this >= param;
      }

      return this.isSameOrAfter(param, unit);
    };

    moment.fn.dateAlignedSame = function (param) {
      var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'day';

      if (unit === 'day' && this.isDateAligned() && param.isDateAligned()) {
        return this.unix() === param.unix();
      }

      return this.isSame(param, unit);
    };

    moment.fn.dateAlignedAfter = function (param) {
      var unit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'day';

      if (unit === 'day' && this.isDateAligned() && param.isDateAligned()) {
        return this > param;
      }

      return this.isAfter(param, unit);
    };

    moment.fn.businessAdd = function (number, period) {
      var day = this.clone();
      var signal = number < 0 ? -1 : 1;
      var remaining = Math.abs(number);
      period = typeof period !== 'undefined' ? period : 'days';

      while (remaining) {
        day.add(signal, period);

        if (day.isBusinessDay()) {
          remaining--;
        }
      }

      return day;
    };

    moment.fn.businessSubtract = function (number, period) {
      return this.businessAdd(-number, period);
    };

    moment.fn.nextBusinessDay = function () {
      var loop = 1;
      var limit = 7;

      while (loop < limit) {
        if (this.add(1, 'd').isBusinessDay()) {
          break;
        }

        loop++;
      }

      return this;
    };

    moment.fn.prevBusinessDay = function () {
      var loop = 1;
      var limit = 7;

      while (loop < limit) {
        if (this.subtract(1, 'd').isBusinessDay()) {
          break;
        }

        loop++;
      }

      return this;
    };

    moment.fn.monthBusinessDays = function (partialEndDate) {
      var me = this.clone();
      var day = me.clone().startOf('month');
      var end = partialEndDate ? partialEndDate : me.clone().endOf('month');
      var daysArr = [];
      var done = false;

      while (!done) {
        if (day.isBusinessDay()) {
          daysArr.push(day.clone());
        }

        if (end.diff(day.add(1, 'd')) < 0) {
          done = true;
        }
      }

      return daysArr;
    };

    moment.fn.monthNaturalDays = function (fromToday) {
      var me = this.clone();
      var day = fromToday ? me.clone() : me.clone().startOf('month');
      var end = me.clone().endOf('month');
      var daysArr = [];
      var done = false;

      while (!done) {
        daysArr.push(day.clone());

        if (end.diff(day.add(1, 'd')) < 0) {
          done = true;
        }
      }

      return daysArr;
    };

    moment.fn.monthBusinessWeeks = function (fromToday) {
      var me = this.clone();
      var day = fromToday ? me.clone() : me.clone().startOf('month');
      var end = me.clone().endOf('month');
      var weeksArr = [];
      var daysArr = [];
      var done = false;

      while (!done) {
        if (day.day() >= 1 && day.day() < 6) {
          daysArr.push(day.clone());
        }

        if (day.day() === 5) {
          weeksArr.push(daysArr);
          daysArr = [];
        }

        if (end.diff(day.add(1, 'd')) < 0) {
          if (daysArr.length < 5) {
            weeksArr.push(daysArr);
          }

          done = true;
        }
      }

      return weeksArr;
    };

    moment.fn.monthNaturalWeeks = function (fromToday) {
      var me = this.clone();
      var day = fromToday ? me.clone() : me.clone().startOf('month');
      var end = me.clone().endOf('month');
      var weeksArr = [];
      var daysArr = [];
      var done = false;

      while (!done) {
        daysArr.push(day.clone());

        if (day.day() === 6) {
          weeksArr.push(daysArr);
          daysArr = [];
        }

        if (end.diff(day.add(1, 'd')) < 0) {
          if (daysArr.length < 7) {
            weeksArr.push(daysArr);
          }

          done = true;
        }
      }

      return weeksArr;
    };
  }

  var _default = {
    name: 'momentjs-custom',
    initialize: initialize
  };
  _exports.default = _default;
});