define("coffeecup/templates/components/color-picker-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HrpcwARw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,5,[34,0]],[12],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"thumb \",[30,[36,3],[[35,2],[35,1]],null]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"fillStyle\",\"cssValue\",\"active\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/color-picker-option.hbs"
    }
  });

  _exports.default = _default;
});