define("coffeecup/templates/projects/expense-categories/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c+nrikNk",
    "block": "{\"symbols\":[],\"statements\":[[8,\"manage/list-header\",[],[[\"@modelName\",\"@headerLabel\",\"@showArchived\",\"@toggleArchived\",\"@searchPath\",\"@itemsGrouped\",\"@canCreateEntity\",\"@placeholder\",\"@closeAction\",\"@selectAction\"],[\"expense-category\",\"expense-categories\",[32,0,[\"showArchived\"]],[32,0,[\"toggleArchived\"]],\"label\",[32,0,[\"expenseCategoriesSorted\"]],[32,0,[\"canCreateExpenseCategory\"]],[30,[36,0],[\"chooseExpenseType\"],null],[32,0,[\"closePane\"]],[32,0,[\"selectItem\"]]]],null],[2,\"\\n\"],[10,\"section\"],[14,0,\"list-table\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"manage-table/manage-table\",[],[[\"@config\",\"@rows\"],[[32,0,[\"tableConfig\"]],[32,0,[\"tableRows\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"empty-list-placeholder\",[],[[\"@header\",\"@icon\",\"@buttonLabel\",\"@linkTo\"],[\"expense-categories.empty\",\"office\",\"expense-categories.create\",\"projects.expense-categories.new\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/projects/expense-categories/index.hbs"
    }
  });

  _exports.default = _default;
});