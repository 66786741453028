define("coffeecup/validations/absence-request", ["exports", "ember-changeset-validations/validators", "coffeecup/validators/validateSameOrAfter", "coffeecup/validators/validateSameOrBefore", "coffeecup/config/environment"], function (_exports, _validators, _validateSameOrAfter, _validateSameOrBefore, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      senderComment: {
        length: {
          maximum: 1000
        }
      },
      receiverComment: {
        length: {
          maximum: 1000
        }
      }
    },
    sender: [(0, _validators.validatePresence)(true)],
    receiver: [(0, _validators.validatePresence)(true)],
    absenceType: [(0, _validators.validatePresence)(true)],
    senderComment: [(0, _validators.validateLength)({
      max: 1000
    })],
    receiverComment: [(0, _validators.validateLength)({
      max: 1000
    })],
    startDate: [(0, _validators.validatePresence)(true), (0, _validateSameOrBefore.default)({
      comparisonKey: 'endDate',
      comparisonUnit: 'day'
    })],
    endDate: [(0, _validators.validatePresence)(true), (0, _validateSameOrAfter.default)({
      comparisonKey: 'startDate',
      comparisonUnit: 'day'
    })],
    rangeType: [(0, _validators.validateInclusion)({
      list: [_environment.default.constants.RANGE_TYPE_ONE_DAY_HALF, _environment.default.constants.RANGE_TYPE_MULTIPLE_DAYS_FULL]
    })]
  };
  _exports.default = _default;
});