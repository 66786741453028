define("coffeecup/routes/planner/absences/index", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    currentUser: Ember.inject.service('currentUser'),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var upstreamPromise = this._super.apply(this, arguments);

      return Ember.RSVP.resolve(upstreamPromise).then(function () {
        if (transition.targetName === 'absences.index') {
          if (_this.get('currentUser.isAdminOrProjectManager')) {
            _this.transitionTo('planner.absences.scheduler');
          } else {
            _this.transitionTo('planner.absences.calendar');
          }
        }
      });
    }
  });

  _exports.default = _default;
});