define("coffeecup/mixins/manage-tags", ["exports", "coffeecup/utils/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),

    /**
     * array of existing tags
     */
    existingTags: [],
    selectedTags: [],
    actions: {
      saveTagAssignments: function saveTagAssignments(tags) {
        var _this = this;

        var model = this.get('model');
        var tagAssignments = this.get('store').peekAll('tag-Assignment').filter(function (el) {
          return el.get('model') === model.get('constructor.modelName') && el.get('record') === model.get('idAsInt');
        });
        var tagPromises = [];
        tags.forEach(function (tag) {
          var existingTag = tagAssignments.findBy('tag.label', tag.get('label'));

          if (!existingTag) {
            var newAssignment = _this.store.createRecord('tag-assignment');

            newAssignment.set('tag', tag);
            newAssignment.set('record', model.get('idAsInt'));
            newAssignment.set('model', model.get('constructor.modelName'));
            tagPromises.push(newAssignment.save());
          } else {
            tagAssignments.removeObject(existingTag);
          }
        });

        if (tagAssignments.get('length') > 0) {
          tagAssignments.forEach(function (tagAssignment) {
            return tagPromises.push(tagAssignment.destroyRecord());
          });
        }

        Ember.RSVP.all(tagPromises).then(function () {
          _this.get('notifications').success('alert.saved', model.get('constructor.modelName'));
        }).catch(function (err) {
          _this.get('notifications').errorWithObject(err, 'alert.errorOnSave', model.get('constructor.modelName'));
        });
      },
      createNewTag: function createNewTag(tag) {
        var _this2 = this;

        var createdTag = this.get('store').createRecord('tag');
        createdTag.set('label', tag);
        this.get('selectedTags').pushObject(createdTag);
        createdTag.save().then(function () {
          _this2.send('saveTagAssignments', _this2.get('selectedTags'));
        });
      },
      showAndCanCreateNewTag: function showAndCanCreateNewTag(term) {
        var existingOption = this.get('existingTags').find(function (tag) {
          return (0, _string.purify)(tag.get('label')) === (0, _string.purify)(term);
        });
        return !existingOption && this.get('currentUserService.currentUser.isAdmin');
      }
    }
  });

  _exports.default = _default;
});