define("coffeecup/templates/team/users/edit/vacation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jU+N/kCk",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,1,\"vacation-details\"],[12],[2,\"\\n\\n    \"],[1,[30,[36,6],null,[[\"years\",\"selectedYear\",\"vacations\",\"newVacationEntry\",\"showMore\",\"disableVacation\",\"publishChange\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[35,0],\"publishChange\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"disableVacation\",\"vacationShowMore\",\"newVacationEntry\",\"vacationDetailObjects\",\"selectedYear\",\"years\",\"vacation-overview\",\"showVacation\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/team/users/edit/vacation.hbs"
    }
  });

  _exports.default = _default;
});