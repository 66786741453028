define("coffeecup/templates/components/input/range-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/tkbYvf0",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"range-selector\"],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n  \"],[8,\"input\",[[24,\"aria-label\",\"Minimum value\"]],[[\"@type\",\"@value\"],[\"text\",[32,0,[\"inputMin\"]]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"slider-bar\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,2],[\"slider-handle \",[30,[36,1],[[32,0,[\"draggingLeft\"]],\"dragging\"],null]],null]],[15,5,[32,0,[\"leftStyle\"]]],[12],[2,\"\\n      |||\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"slider-center\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,2],[\"slider-handle \",[30,[36,1],[[32,0,[\"draggingRight\"]],\"dragging\"],null]],null]],[15,5,[32,0,[\"rightStyle\"]]],[12],[2,\"\\n      |||\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"input\",[[24,\"aria-label\",\"Maximum value\"]],[[\"@type\",\"@value\"],[\"text\",[32,0,[\"inputMax\"]]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/range-slider.hbs"
    }
  });

  _exports.default = _default;
});