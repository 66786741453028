define("coffeecup/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var duration = 750;
  var delay = 250;

  function _default() {
    this.transition(this.hasClass('fade-demo'), this.use('explode', {
      pickOld: '.dashboard-unlock-title',
      use: ['toRight', {
        duration: duration,
        ease: 'in'
      }]
    }, {
      pickOld: '.dashboard-unlock-description',
      use: ['wait', delay, {
        then: 'toRight'
      }, {
        duration: duration,
        ease: 'in'
      }]
    }, {
      pickOld: '.challenge-list',
      use: ['wait', delay * 2, {
        then: 'toRight'
      }, {
        duration: duration,
        ease: 'in'
      }]
    }, {
      pickNew: '.dashboard-unlock-title',
      use: ['wait', delay * 3, {
        then: 'toRight'
      }, {
        duration: duration,
        ease: 'out'
      }]
    }, {
      pickNew: '.dashboard-gratulation-description',
      use: ['wait', delay * 4, {
        then: 'toRight'
      }, {
        duration: duration,
        ease: 'out'
      }]
    }, {
      pickNew: '.dashboard-unlock-btn',
      use: ['wait', delay * 4 + duration / 2, {
        then: 'fade'
      }, {
        duration: duration,
        ease: 'out'
      }]
    }));
    this.transition(this.hasClass('toLeft'), this.use('toLeft', {
      duration: duration
    }));
  }
});