define("coffeecup/validations/expense-category", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      label: {
        presence: true,
        length: {
          maximum: 128
        }
      }
    },
    label: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })]
  };
  _exports.default = _default;
});