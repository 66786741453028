define("coffeecup/templates/components/vacation-list/element/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xmoRGis6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"row content \",[30,[36,4],[[35,3],\"dashed-border-bottom\"],null],\" editable add-entry clickable\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-4 col-sm-2\"],[12],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"value\",\"focus\"],[[35,0,[\"quantity\"]],[35,5]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"col-xs-5 \",[30,[36,4],[[35,3],\"no-edit\"],null]]]],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],[[35,0,[\"comment\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"type\",\"minHeight\",\"rows\",\"value\"],[\"text\",34,1,[35,0,[\"comment\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-3 no-edit hidden-xs\"],[12],[2,\"\\n    \"],[1,[30,[36,7],[[35,0,[\"date\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-2 col-sm-2 text-right\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"btn-group\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,8],[[32,0],\"cancel\"],null],[12],[10,\"span\"],[14,0,\"icomoon-cross-exit\"],[12],[13],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,8],[[32,0],\"save\"],null],[12],[10,\"span\"],[14,0,\"icomoon-checkmark\"],[12],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"vacationBudget\",\"input/cc-text-area\",\"nl2br-fmt\",\"isYearlyVacation\",\"if\",\"focus\",\"input-vacation-amount\",\"date-fmt\",\"action\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/vacation-list/element/edit.hbs"
    }
  });

  _exports.default = _default;
});