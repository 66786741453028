define("coffeecup/services/base-analytics", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    app: {
      // name: ENV.APP.name,
      get name() {
        var urlComponents = document.location.hostname.split('.');

        if (urlComponents.length > 0) {
          var companyURL = urlComponents[0];
          return companyURL;
        }

        return '';
      },

      version: _environment.default.APP.version
    },
    trackErrors: true,
    settings: {
      debug: false
    },
    log: function log() {
      if (!this.get('settings.debug')) {
        return;
      }

      var args = arguments;

      if (args.length > 0 && typeof args[0] === 'string') {
        args[0] = 'ember-cli-analytics: ' + args[0];
      }

      console.debug.apply(console, args);
    },
    insertScript: function insertScript() {},
    prepare: Ember.on('init', function () {
      this.insertScript();
    }),
    start: function start(user) {},
    stop: function stop() {},
    send: function send(type, args) {},
    event: function event(_event, args) {},
    exception: function exception(exOptions) {},
    notifyTransition: function notifyTransition(url) {},
    notifyError: function notifyError(error, fatal, transition) {}
  });

  _exports.default = _default;
});