define("coffeecup/utils/attachment-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.attachmentPreview = attachmentPreview;
  _exports.imagePreview = imagePreview;

  /**
   * Load an attachment into memory.
   *
   * @param Blob file - The file to load
   * @return RSVP.Promise - Resolves with the binary data for the file
   */
  function attachmentPreview(file) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var reader = new mOxie.FileReader();

      reader.onloadend = function () {
        resolve(reader.result);
      };

      reader.onerror = function () {
        reject(reader.error);
      };

      reader.readAsDataURL(file);
    });
  }

  function imagePreview(file) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      // Create an instance of the mOxie Image object. This
      // utility object provides several means of reading in
      // and loading image data from various sources.
      // --
      // Wiki: https://github.com/moxiecode/moxie/wiki/Image
      var img = new mOxie.Image();

      img.onload = function () {
        var dim = 300;

        if (img.height < dim || img.width < dim) {
          dim = Math.min(img.height, img.width);
        } // This will scale the image (in memory) before it
        // tries to render it. This just reduces the amount
        // of Base64 data that needs to be rendered.


        img.downsize({
          width: dim,
          height: dim,
          crop: true
        }); // Now that the image is preloaded, grab the Base64
        // encoded data URL. This will show the image
        // without making an Network request using the
        // client-side file binary.

        resolve(img.getAsDataURL());
      };

      img.onerror = function () {
        reject();
      };

      img.load(file);
    });
  }
});