define("coffeecup/helpers/some", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.some = some;
  _exports.default = void 0;

  function some(params) {
    return params.some(function (param) {
      return !!param;
    });
  }

  var _default = Ember.Helper.helper(some);

  _exports.default = _default;
});