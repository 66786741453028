define("coffeecup/models/timezone", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Timezone = _emberData.default.Model.extend({
    group: _emberData.default.attr('string'),
    time: _emberData.default.attr('string'),
    // computed properties
    label: Ember.computed('time', 'id', {
      get: function get() {
        return '(' + this.get('time') + ') ' + this.get('id');
      }
    })
  });

  var _default = Timezone;
  _exports.default = _default;
});