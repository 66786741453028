define("coffeecup/mixins/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var GroupByMixin = Ember.Mixin.create({
    showOnlySummaryHeaders: false,
    selectedGroupByOption: Ember.computed('groupByOptions.[]', function () {
      return this.get('groupByOptions').find(function (x) {
        return x.id == 'date';
      });
    }),
    multipleClients: Ember.computed('selectedTimeEntries.[]', function () {
      var clientIds = this.get('selectedTimeEntries').map(function (timeEntry) {
        return timeEntry.get('project.client.id');
      });
      var clientSet = new Set(_toConsumableArray(clientIds));
      var multipleClients = clientSet.size > 1;
      return multipleClients;
    }),
    multipleProjects: Ember.computed('selectedTimeEntries.[]', function () {
      var projectIds = this.get('selectedTimeEntries').map(function (timeEntry) {
        return timeEntry.get('project.id');
      });
      var projectSet = new Set(_toConsumableArray(projectIds));
      return projectSet.size > 1;
    }),
    multipleStaff: Ember.computed('selectedTimeEntries.[]', function () {
      var ids = this.get('selectedTimeEntries').map(function (timeEntry) {
        return timeEntry.get('user.id');
      });
      var newSet = new Set(_toConsumableArray(ids));
      return newSet.size > 1;
    }),
    multipleTask: Ember.computed('selectedTimeEntries.[]', function () {
      var ids = this.get('selectedTimeEntries').map(function (timeEntry) {
        return timeEntry.get('task.id');
      });
      var newSet = new Set(_toConsumableArray(ids));
      return newSet.size > 1;
    }),
    multipleTeam: Ember.computed('selectedTimeEntries.[]', function () {
      var ids = this.get('selectedTimeEntries').map(function (timeEntry) {
        return timeEntry.get('team.id');
      });
      var newSet = new Set(_toConsumableArray(ids));
      return newSet.size > 1;
    }),
    multipleReference: Ember.computed('selectedTimeEntries.[]', function () {
      var ids = this.get('selectedTimeEntries').map(function (timeEntry) {
        return timeEntry.get('timeEntryReference.id');
      });
      var newSet = new Set(_toConsumableArray(ids));
      return newSet.size > 1;
    }),
    groupByOptions: Ember.computed('multipleClients', 'multipleProjects', 'multipleStaff', 'multipleTask', 'multipleReference', function () {
      var options = [{
        id: 'date',
        label: this.get('i18n').t('date')
      }];

      if (this.multipleClients) {
        options.push({
          id: 'client',
          label: this.get('i18n').t('client')
        });
      }

      if (this.multipleProjects) {
        options.push({
          id: 'project',
          label: this.get('i18n').t('project')
        });
      }

      if (this.multipleStaff) {
        options.push({
          id: 'user',
          label: this.get('i18n').t('staff')
        });
      }

      if (this.multipleTask) {
        options.push({
          id: 'task',
          label: this.get('i18n').t('task')
        });
      }

      if (this.multipleTeam) {
        options.push({
          id: 'team',
          label: this.get('i18n').t('team')
        });
      }

      if (this.multipleReference) {
        options.push({
          id: 'reference',
          label: this.get('i18n').t('reference')
        });
      }

      return options;
    }),
    actions: {
      selectGroupByOption: function selectGroupByOption(option) {
        this.set('selectedGroupByOption', option);
      }
    }
  });
  var _default = GroupByMixin;
  _exports.default = _default;
});