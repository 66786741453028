define("coffeecup/models/time-rounding-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TimeRoundingType = _emberData.default.Model.extend({
    key: _emberData.default.attr('string'),
    // computed properties
    label: Ember.computed('key', 'i18n.locale', {
      get: function get() {
        var key = this.get('key');
        return this.get('i18n').t(key);
      }
    })
  });

  var _default = TimeRoundingType;
  _exports.default = _default;
});