define("coffeecup/models/task-assignment", ["exports", "coffeecup/config/environment", "ember-data", "coffeecup/models/-super-model", "coffeecup/utils/format-duration"], function (_exports, _environment, _emberData, _superModel, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TaskAssignment = _superModel.default.extend({
    STANDARD_WORKDAY_HOURS: _environment.default.constants.STANDARD_WORKDAY_HOURS,
    billable: _emberData.default.attr('boolean'),
    hourlyRate: _emberData.default.attr('number'),
    budgetHours: _emberData.default.attr('number'),
    // relations
    task: _emberData.default.belongsTo('task', {
      async: true
    }),
    project: _emberData.default.belongsTo('project', {
      async: true
    }),
    //computed properties
    budgetInPersonDays: Ember.computed('budgetHours', 'i18n.locale', {
      get: function get() {
        return (0, _formatDuration.formatAsPersonDays)(this.get('budgetHours'), _environment.default.constants.STANDARD_WORKDAY_HOURS) + ' ' + this.get('i18n').t('abbr.personDays');
      }
    }),
    isActive: Ember.computed.and('task.isActive', 'project.isActive'),
    isInactive: Ember.computed.not('isActive'),
    status: Ember.computed('isActive', function () {
      return this.get('isActive') ? _environment.default.constants.STATUS_ENABLED : _environment.default.constants.STATUS_ARCHIVED;
    }).readOnly(),
    // events
    applyTaskDefaults: function applyTaskDefaults() {
      var task = this.get('task');

      if (task) {
        this.set('hourlyRate', task.get('hourlyRate'));
        this.set('billable', task.get('billable'));
      }
    },
    isValid: Ember.computed('validators.@each.isValid', {
      get: function get() {
        return this.get('validators').compact().filterBy('isValid', false).get('length') === 0;
      }
    }),
    clone: function clone() {
      return this.getProperties(['billable', 'hourlyRate', 'budgetHours', 'task', 'project']);
    }
  });

  var _default = TaskAssignment;
  _exports.default = _default;
});