define("coffeecup/templates/components/resizable-item/handle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WdpdRwvV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"indicator\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"handle-top\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"handle-bottom\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/resizable-item/handle.hbs"
    }
  });

  _exports.default = _default;
});