define("coffeecup/instance-initializers/notice-period", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var i18n = appInstance.lookup('service:i18n');
    var pushData = {
      data: [{
        type: 'notice-period/unit',
        id: 'days',
        attributes: {}
      }, {
        type: 'notice-period/unit',
        id: 'weeks',
        attributes: {}
      }, {
        type: 'notice-period/unit',
        id: 'months',
        attributes: {}
      }, {
        type: 'notice-period/extra',
        id: 'empty',
        attributes: {}
      }, {
        type: 'notice-period/extra',
        id: 'midOrEndOfMonth',
        attributes: {}
      }, {
        type: 'notice-period/extra',
        id: 'midOfMonth',
        attributes: {}
      }, {
        type: 'notice-period/extra',
        id: 'endOfMonth',
        attributes: {}
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'notice-period',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});