define("coffeecup/templates/components/icon-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MEE1FfEx",
    "block": "{\"symbols\":[\"&attrs\",\"@icon\"],\"statements\":[[11,\"div\"],[24,0,\"element-icon-wrapper\"],[17,1],[12],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[\"element-icon icomoon-\",[32,2]]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/icon-element.hbs"
    }
  });

  _exports.default = _default;
});