define("coffeecup/templates/components/planner/week-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t6UvLSup",
    "block": "{\"symbols\":[\"week\",\"day\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"timeline-week \",[30,[36,0],[[32,1,[\"isThisWeek\"]],\"this-week\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"timeline-week-number \",[30,[36,0],[[32,1,[\"isFirstWeekOfYear\"]],\"timeline-new-year\"],null]]]],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"isFirstWeekOfYear\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[32,1,[\"date\"]],\"YYYY\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[32,1,[\"date\"]],\"W\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"timeline-week-dates\"],[12],[2,\"\\n      \"],[1,[30,[36,2],[[32,1,[\"date\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"ol\"],[14,0,\"timeline-week-days\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,1,[\"days\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[15,0,[31,[\"timeline-day \",[30,[36,0],[[32,2,[\"isToday\"]],\"today\"],null]]]],[12],[1,[30,[36,1],[[32,2,[\"date\"]],\"D\"],null]],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[10,\"div\"],[14,0,\"timeline-day-highlight\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"moment-format\",\"week-span\",\"-track-array\",\"each\",\"weekGrid\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/week-grid.hbs"
    }
  });

  _exports.default = _default;
});