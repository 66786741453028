define("coffeecup/templates/components/planner/user-project-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ikiw1EeI",
    "block": "{\"symbols\":[\"assignment\"],\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],null,[[\"assignment\",\"editable\",\"useProjectColor\",\"onClick\",\"onMoved\",\"onSplit\"],[[32,1],[35,5],true,[30,[36,4],[[32,0],\"editAssignment\"],null],[30,[36,4],[[32,0],\"updateAssignment\"],null],[30,[36,4],[[32,0],\"splitAssignment\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,9],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],null,[[\"class\",\"onClick\",\"onSelect\",\"user\",\"blockedTimeframes\",\"projectColor\"],[\"add-assignment\",\"onClick\",\"onSelect\",[35,2],[35,1],[35,0,[\"color\",\"hexString70\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"project\",\"assignments\",\"user\",\"planner/stretchable-overlay\",\"action\",\"editable\",\"planner/planner-assignment\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/user-project-timeline.hbs"
    }
  });

  _exports.default = _default;
});