define("coffeecup/models/-super-model", ["exports", "ember-data", "coffeecup/mixins/format-currency"], function (_exports, _emberData, _formatCurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var SuperModel = _emberData.default.Model.extend(_formatCurrency.default, {
    /**
     * return the model id as an integer
     */
    idAsInt: Ember.computed('id', {
      get: function get() {
        return parseInt(this.get('id'));
      }
    }),
    idAsArray: Ember.computed('id', {
      get: function get() {
        return [this.get('idAsInt')];
      }
    }),
    isActiveAndLoaded: Ember.computed.and('isActive', 'isLoaded'),
    clone: function clone() {
      // Our super model doesn't know about the fields that custom models need to preserve
      // for this reason, we need a serialize function that will return a version of this model
      // e.g.: return this.getProperties(['label', 'hourlyRate', 'billable', 'favorite', 'color']);
      throw new Error("".concat([this], " has to implement clone() method which is required to convert it to JSON."));
    }
  });

  var _default = SuperModel;
  _exports.default = _default;
});