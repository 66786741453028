define("coffeecup/templates/components/button-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r48A8GYL",
    "block": "{\"symbols\":[\"@value\",\"@isIcon\",\"@confirm\"],\"statements\":[[6,[37,1],[[32,0,[\"isShowingConfirmation\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[32,0,[\"confirmButtonClassNames\"]]],[24,4,\"button\"],[4,[38,0],[[32,0],\"confirm\"],null],[4,[38,2],[\"mouseleave\",[30,[36,0],[[32,0],\"handleMouseLeave\"],null]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[15,0,[31,[\"icomoon-\",[32,3]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,3]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[32,0,[\"defaultButtonClassNames\"]]],[24,4,\"button\"],[4,[38,0],[[32,0],\"showConfirmation\"],null],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[15,0,[31,[\"icomoon-\",[32,1]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/button-confirm.hbs"
    }
  });

  _exports.default = _default;
});