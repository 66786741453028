define("coffeecup/templates/components/onboarding/basic/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I4Ejwen0",
    "block": "{\"symbols\":[\"regionInSelect\",\"countryInSelect\"],\"statements\":[[10,\"div\"],[14,0,\"text-center w-100\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"onboardingLocation\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group language-selection\"],[12],[2,\"\\n    \"],[8,\"input/cc-select\",[],[[\"@options\",\"@selected\",\"@searchField\",\"@placeholder\",\"@onchange\"],[[32,0,[\"countries\"]],[32,0,[\"selectedCountry\"]],\"label\",[30,[36,0],[\"select\"],null],[30,[36,3],[[32,0],\"updateProperty\",\"selectedCountry\",\"label\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[30,[36,2],[[32,2,[\"label\"]],[32,2]],null]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,4],[[32,0,[\"showRegionSelector\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[8,\"input/cc-select\",[],[[\"@options\",\"@selected\",\"@searchField\",\"@placeholder\",\"@onchange\"],[[32,0,[\"regions\"]],[32,0,[\"selectedRegionObj\"]],\"label\",[30,[36,0],[\"select\"],null],[30,[36,1],[[32,0,[\"updateProperty\"]],\"selectedRegion\",\"label\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"name\"]],[32,1]],null]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary btn-pill large-pill\"],[24,\"role\",\"button\"],[24,\"aria-pressed\",\"false\"],[24,4,\"button\"],[4,[38,5],[\"click\",[32,0,[\"save\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"continue\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"or\",\"action\",\"if\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/onboarding/basic/location.hbs"
    }
  });

  _exports.default = _default;
});