define("coffeecup/templates/components/roles/rights-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N889Hg3e",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,11],null,[[\"content\"],[[30,[36,10],[[30,[36,8],[[35,3],[35,9],[30,[36,8],[[35,4],[35,7],[35,6]],null]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],null,[[\"value\",\"disabled\",\"translationKey\",\"onChange\"],[[35,4],[35,3],[35,2],[30,[36,1],[[32,0],[35,0]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"onSave\",\"action\",\"translationKey\",\"disabled\",\"checked\",\"input/cc-checkbox\",\"inactiveTooltip\",\"activeTooltip\",\"if\",\"inactiveTooltipDisabled\",\"t\",\"tool-tipster\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/roles/rights-checkbox.hbs"
    }
  });

  _exports.default = _default;
});