define("coffeecup/templates/start/welcome", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "squdjoy1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"table-centered\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"table-centered-cell\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"login\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"logo\"],[14,\"src\",\"/assets/images/logo-white.svg\"],[12],[13],[2,\"\\n\\n      \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[35,0],\"animated animate-fast shake2\"],null]]]],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"wrapper \",[30,[36,1],[[35,2],\"boxflip\"],null]]]],[12],[2,\"\\n          \"],[19,\"start/welcome-front\",[]],[2,\"\\n          \"],[19,\"start/welcome-back\",[]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[19,\"start/resetpassword-footer\",[]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"statusError\",\"if\",\"secondPage\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/start/welcome.hbs"
    }
  });

  _exports.default = _default;
});