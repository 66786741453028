define("coffeecup/templates/components/assignment-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/CYGQlmd",
    "block": "{\"symbols\":[\"@actionIcon\"],\"statements\":[[10,\"span\"],[14,0,\"pill-label\"],[12],[2,\"\\n  \"],[1,[32,0,[\"label\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"span\"],[15,0,[31,[\"icon icomoon-\",[32,1]]]],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/assignment-pill.hbs"
    }
  });

  _exports.default = _default;
});