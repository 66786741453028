define("coffeecup/transforms/ui-state", ["exports", "ember-data", "coffeecup/config/environment", "coffeecup/components/month-period-picker"], function (_exports, _emberData, _environment, _monthPeriodPicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UiState = Ember.Object.extend({
    state: null,
    visibleDaysPerWeek: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').visibleDaysPerWeek || _environment.default.constants.DEFAULT_VISIBLEDAYSPERWEEK;
      },
      set: function set(key, val) {
        this.get('state').visibleDaysPerWeek = val;
        return val;
      }
    }),
    timeEntryBackgroundColor: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').timeEntryBackgroundColor || _environment.default.constants.TIME_ENTRY_COLOR_PROJECT;
      },
      set: function set(key, val) {
        val = parseInt(val, 10);
        this.get('state').timeEntryBackgroundColor = val;
        return val;
      }
    }),
    showStartAndEndTime: Ember.computed('state.{}', {
      get: function get() {
        var showStartAndEndTime = this.get('state').showStartAndEndTime;

        if (Ember.isEmpty(showStartAndEndTime)) {
          return true;
        }

        return showStartAndEndTime;
      },
      set: function set(key, val) {
        this.get('state').showStartAndEndTime = val;
        return val;
      }
    }),
    showTimeEntryQuality: Ember.computed('state.{}', {
      get: function get() {
        var showTimeEntryQuality = this.get('state').showTimeEntryQuality;

        if (Ember.isEmpty(showTimeEntryQuality)) {
          return true;
        }

        return showTimeEntryQuality;
      },
      set: function set(key, val) {
        this.get('state').showTimeEntryQuality = val;
        return val;
      }
    }),
    showRequestPanel: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').showRequestPanel || false;
      },
      set: function set(key, val) {
        this.get('state').showRequestPanel = val;
        return val;
      }
    }),
    footerHideTimeEntries: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').footerHideTimeEntries || false;
      },
      set: function set(key, val) {
        this.get('state').footerHideTimeEntries = val;
        return val;
      }
    }),
    analyticsModuleState: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').analyticsModuleState || {};
      },
      set: function set(key, val) {
        this.get('state').analyticsModuleState = val;
        return val;
      }
    }),
    projectAnalyticsModuleState: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').projectAnalyticsModuleState || {};
      },
      set: function set(key, val) {
        this.get('state').projectAnalyticsModuleState = val;
        return val;
      }
    }),
    clientAnalyticsModuleState: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').clientAnalyticsModuleState || {};
      },
      set: function set(key, val) {
        this.get('state').clientAnalyticsModuleState = val;
        return val;
      }
    }),
    plannerZoomLevel: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').plannerZoomLevel || 1;
      },
      set: function set(key, val) {
        this.get('state').plannerZoomLevel = val;
        return val;
      }
    }),
    showPlannerAssignments: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showPlannerAssignments) ? true : this.get('state').showPlannerAssignments;
      },
      set: function set(key, val) {
        this.get('state').showPlannerAssignments = val;
        return val;
      }
    }),
    showPlannerProjectDuration: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showPlannerProjectDuration) ? true : this.get('state').showPlannerProjectDuration;
      },
      set: function set(key, val) {
        this.get('state').showPlannerProjectDuration = val;
        return val;
      }
    }),
    showAbsencesInPlanner: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showAbsencesInPlanner) ? true : this.get('state').showAbsencesInPlanner;
      },
      set: function set(key, val) {
        this.get('state').showAbsencesInPlanner = val;
        return val;
      }
    }),
    showAbsenceRequestsInPlanner: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showAbsenceRequestsInPlanner) ? true : this.get('state').showAbsenceRequestsInPlanner;
      },
      set: function set(key, val) {
        this.get('state').showAbsenceRequestsInPlanner = val;
        return val;
      }
    }),
    showDailySummaryView: Ember.computed('state.{}', {
      get: function get() {
        var x = Ember.isEmpty(this.get('state').showDailySummaryView) ? false : this.get('state').showDailySummaryView;
        return x;
      },
      set: function set(key, val) {
        this.get('state').showDailySummaryView = val;
        return val;
      }
    }),
    showCommentType: Ember.computed('state.{}', {
      get: function get() {
        var x = Ember.isEmpty(this.get('state').showCommentType) ? 0 : this.get('state').showCommentType;
        return x;
      },
      set: function set(key, val) {
        this.get('state').showCommentType = val;
        return val;
      }
    }),
    plannerSummary: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').plannerSummary) ? true : this.get('state').plannerSummary;
      },
      set: function set(key, val) {
        this.get('state').plannerSummary = val;
        return val;
      }
    }),
    plannerShowMilestoneSummary: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').plannerShowMilestoneSummary) ? true : this.get('state').plannerShowMilestoneSummary;
      },
      set: function set(key, val) {
        this.get('state').plannerShowMilestoneSummary = val;
        return val;
      }
    }),
    showSensitiveData: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showSensitiveData) ? true : this.get('state').showSensitiveData;
      },
      set: function set(key, val) {
        this.get('state').showSensitiveData = val;
        return val;
      }
    }),
    timeEntryLabelStyle: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').timeEntryLabelStyle) ? _environment.default.constants.LONG_TIME_ENTRY_LABEL : this.get('state').timeEntryLabelStyle;
      },
      set: function set(key, val) {
        this.get('state').timeEntryLabelStyle = val;
        return val;
      }
    }),
    showHiddenUsers: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showHiddenUsers) ? false : this.get('state').showHiddenUsers;
      },
      set: function set(key, val) {
        this.get('state').showHiddenUsers = val;
        return val;
      }
    }),
    showPercentOrAbsoluteCapacities: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showPercentOrAbsoluteCapacities) ? false : this.get('state').showPercentOrAbsoluteCapacities;
      },
      set: function set(key, val) {
        this.get('state').showPercentOrAbsoluteCapacities = val;
        return val;
      }
    }),
    showChartOnPlanningPage: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showChartOnPlanningPage) ? true : this.get('state').showChartOnPlanningPage;
      },
      set: function set(key, val) {
        this.get('state').showChartOnPlanningPage = val;
        return val;
      }
    }),
    showHiddenUsersInAbsences: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showHiddenUsersInAbsences) ? false : this.get('state').showHiddenUsersInAbsences;
      },
      set: function set(key, val) {
        this.get('state').showHiddenUsersInAbsences = val;
        return val;
      }
    }),
    selectedDashboardId: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').selectedDashboardId) ? -1 : this.get('state').selectedDashboardId;
      },
      set: function set(key, val) {
        this.get('state').selectedDashboardId = val;
        return val;
      }
    }),
    plannerScopeStartDate: Ember.computed('state.{}', {
      get: function get() {
        var scopeStartDate = this.get('state').plannerScopeStartDate;

        if (!Ember.isEmpty(scopeStartDate)) {
          return moment(scopeStartDate);
        }

        return null;
      },
      set: function set(key, val) {
        this.get('state').plannerScopeStartDate = val;
        return val;
      }
    }),
    plannerScopeEndDate: Ember.computed('state.{}', {
      get: function get() {
        var scopeEndDate = this.get('state').plannerScopeEndDate;

        if (!Ember.isEmpty(scopeEndDate)) {
          return moment(scopeEndDate);
        }

        return null;
      },
      set: function set(key, val) {
        this.get('state').plannerScopeEndDate = val;
        return val;
      }
    }),
    collapsedManageRowKeys: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').collapsedManageRowKeys || '';
      },
      set: function set(key, val) {
        this.get('state').collapsedManageRowKeys = val;
        return val;
      }
    }),
    showProjectBoardSummary: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showProjectBoardSummary) ? true : this.get('state').showProjectBoardSummary;
      },
      set: function set(key, val) {
        this.get('state').showProjectBoardSummary = val;
        return val;
      }
    }),
    showProjectBoardChart: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showProjectBoardChart) ? true : this.get('state').showProjectBoardChart;
      },
      set: function set(key, val) {
        this.get('state').showProjectBoardChart = val;
        return val;
      }
    }),
    showProjectBoardArchivedItems: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showProjectBoardArchivedItems) ? false : this.get('state').showProjectBoardArchivedItems;
      },
      set: function set(key, val) {
        this.get('state').showProjectBoardArchivedItems = val;
        return val;
      }
    }),
    showTeamBoardArchivedItems: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showTeamBoardArchivedItems) ? false : this.get('state').showTeamBoardArchivedItems;
      },
      set: function set(key, val) {
        this.get('state').showTeamBoardArchivedItems = val;
        return val;
      }
    }),
    showedColumns: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showedColumns) ? false : this.get('state').showedColumns;
      },
      set: function set(key, val) {
        this.get('state').showedColumns = val;
        return val;
      }
    }),
    isTooltipHintsVisible: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').isTooltipHintsVisible) ? true : this.get('state').isTooltipHintsVisible;
      },
      set: function set(key, val) {
        this.get('state').isTooltipHintsVisible = val;
        return val;
      }
    }),
    showGroupAnalyticsBy: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').showGroupAnalyticsBy) ? false : this.get('state').showGroupAnalyticsBy;
      },
      set: function set(key, val) {
        this.get('state').showGroupAnalyticsBy = val;
        return val;
      }
    }),
    hideProjectBoardEmptyClients: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').hideProjectBoardEmptyClients) ? false : this.get('state').hideProjectBoardEmptyClients;
      },
      set: function set(key, val) {
        this.get('state').hideProjectBoardEmptyClients = val;
        return val;
      }
    }),
    projectBoardColumnOrder: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').projectBoardColumnOrder) ? null : this.get('state').projectBoardColumnOrder;
      },
      set: function set(key, val) {
        this.get('state').projectBoardColumnOrder = val;
        return val;
      }
    }),
    teamBoardColumnOrder: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').teamBoardColumnOrder) ? null : this.get('state').teamBoardColumnOrder;
      },
      set: function set(key, val) {
        this.get('state').teamBoardColumnOrder = val;
        return val;
      }
    }),
    collapseClientsAutomatically: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').collapseClientsAutomatically) ? false : this.get('state').collapseClientsAutomatically;
      },
      set: function set(key, val) {
        this.get('state').collapseClientsAutomatically = val;
        return val;
      }
    }),
    collapseTeamsAutomatically: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').collapseTeamsAutomatically) ? false : this.get('state').collapseTeamsAutomatically;
      },
      set: function set(key, val) {
        this.get('state').collapseTeamsAutomatically = val;
        return val;
      }
    }),
    projectBoardFilters: Ember.computed('state.{}', {
      get: function get() {
        try {
          var filters = this.get('state').projectBoardFilters; // console.log('projectBoardFilters', filters);

          return Ember.isEmpty(filters) ? [] : filters;
        } catch (e) {
          console.error(e);
          return [];
        }
      },
      set: function set(key, val) {
        // console.log('set called on projectBoardFilters', val, JSON.stringify(val));
        this.get('state').projectBoardFilters = val;
        return val;
      }
    }),
    selectedProjectBoardFilter: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').selectedProjectBoardFilter) ? '' : this.get('state').selectedProjectBoardFilter;
      },
      set: function set(key, val) {
        this.get('state').selectedProjectBoardFilter = val;
        return val;
      }
    }),
    plannedVsActualPeriodScope: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').plannedVsActualPeriodScope) ? _monthPeriodPicker.MonthPeriod.AllTime : this.get('state').plannedVsActualPeriodScope;
      },
      set: function set(key, val) {
        this.get('state').plannedVsActualPeriodScope = val;
        return val;
      }
    }),
    plannedVsActualPeriodDivider: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').plannedVsActualPeriodDivider) ? true : this.get('state').plannedVsActualPeriodDivider;
      },
      set: function set(key, val) {
        this.get('state').plannedVsActualPeriodDivider = val;
        return val;
      }
    }),
    plannedVsActualUnit: Ember.computed('state.{}', {
      get: function get() {
        return Ember.isEmpty(this.get('state').plannedVsActualUnit) ? true : this.get('state').plannedVsActualUnit;
      },
      set: function set(key, val) {
        this.get('state').plannedVsActualUnit = val;
        return val;
      }
    }) // plannerStartDate: computed('state.{}', {
    //   get() {
    //     let plannerStartDate = this.get('state').plannerStartDate;
    //     if (isEmpty(plannerStartDate)) {
    //       return moment.utc().day(1).format(ENV.constants.DATE_FORMAT_DAY);
    //     }
    //     return plannerStartDate;
    //   },
    //   set(key, val) {
    //     this.get('state').plannerStartDate = val;
    //     return val;
    //   }
    // }),

  });

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      if (value && typeof value.get !== 'undefined') {
        return JSON.stringify({
          visibleDaysPerWeek: value.get('visibleDaysPerWeek'),
          timeEntryBackgroundColor: value.get('timeEntryBackgroundColor'),
          showStartAndEndTime: value.get('showStartAndEndTime'),
          showTimeEntryQuality: value.get('showTimeEntryQuality'),
          showRequestPanel: value.get('showRequestPanel'),
          footerHideTimeEntries: value.get('footerHideTimeEntries'),
          analyticsModuleState: value.get('analyticsModuleState'),
          projectAnalyticsModuleState: value.get('projectAnalyticsModuleState'),
          clientAnalyticsModuleState: value.get('clientAnalyticsModuleState'),
          plannerZoomLevel: value.get('plannerZoomLevel'),
          plannerSummary: value.get('plannerSummary'),
          plannerShowMilestoneSummary: value.get('plannerShowMilestoneSummary'),
          showPlannerProjectDuration: value.get('showPlannerProjectDuration'),
          showPlannerAssignments: value.get('showPlannerAssignments'),
          showAbsencesInPlanner: value.get('showAbsencesInPlanner'),
          showAbsenceRequestsInPlanner: value.get('showAbsenceRequestsInPlanner'),
          showDailySummaryView: value.get('showDailySummaryView'),
          showCommentType: value.get('showCommentType'),
          showSensitiveData: value.get('showSensitiveData'),
          timeEntryLabelStyle: value.get('timeEntryLabelStyle'),
          showHiddenUsers: value.get('showHiddenUsers'),
          showHiddenUsersInAbsences: value.get('showHiddenUsersInAbsences'),
          selectedDashboardId: value.get('selectedDashboardId'),
          plannerScopeStartDate: value.get('plannerScopeStartDate'),
          plannerScopeEndDate: value.get('plannerScopeEndDate'),
          collapsedManageRowKeys: value.get('collapsedManageRowKeys'),
          showProjectBoardSummary: value.get('showProjectBoardSummary'),
          showProjectBoardArchivedItems: value.get('showProjectBoardArchivedItems'),
          showProjectBoardChart: value.get('showProjectBoardChart'),
          showTeamBoardArchivedItems: value.get('showTeamBoardArchivedItems'),
          showedColumns: value.get('showedColumns'),
          isTooltipHintsVisible: value.get('isTooltipHintsVisible'),
          hideProjectBoardEmptyClients: value.get('hideProjectBoardEmptyClients'),
          projectBoardColumnOrder: value.get('projectBoardColumnOrder'),
          teamBoardColumnOrder: value.get('teamBoardColumnOrder'),
          collapseClientsAutomatically: value.get('collapseClientsAutomatically'),
          collapseTeamsAutomatically: value.get('collapseTeamsAutomatically'),
          projectBoardFilters: value.get('projectBoardFilters'),
          selectedProjectBoardFilter: value.get('selectedProjectBoardFilter'),
          plannedVsActualPeriodScope: value.get('plannedVsActualPeriodScope'),
          plannedVsActualPeriodDivider: value.get('plannedVsActualPeriodDivider'),
          plannedVsActualUnit: value.get('plannedVsActualUnit'),
          showPercentOrAbsoluteCapacities: value.get('showPercentOrAbsoluteCapacities'),
          showChartOnPlanningPage: value.get('showChartOnPlanningPage'),
          showGroupAnalyticsBy: value.get('showGroupAnalyticsBy') // plannerStartDate: value.get('plannerStartDate'),

        });
      }
    },
    deserialize: function deserialize(value) {
      value = value || '{}';
      return UiState.create({
        state: JSON.parse(value)
      });
    }
  });

  _exports.default = _default;
});