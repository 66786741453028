define("coffeecup/templates/components/analytics-module/analytics/unbilled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lf6kPPhi",
    "block": "{\"symbols\":[\"@analytics\"],\"statements\":[[8,\"analytics-module/kpi/kpi-cell\",[],[[\"@amount\",\"@label\",\"@hasData\"],[[30,[36,0],[[32,1,[\"amountNotBilled\"]],false,false,true],null],[30,[36,1],[\"notBilled\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"kpi-tooltip\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"totalBillable\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"amountSpent\"]],false,false],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"billed\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"amountBilled\"]],false,false],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row summary-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"notBilled\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"amountNotBilled\"]],false,false],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"currency-fmt\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/analytics/unbilled.hbs"
    }
  });

  _exports.default = _default;
});