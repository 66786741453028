define("coffeecup/templates/components/analytics-module/kpi/profit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hU76nQos",
    "block": "{\"symbols\":[\"@analytics\",\"@hasData\"],\"statements\":[[8,\"analytics-module/kpi/kpi-cell\",[],[[\"@icon\",\"@amount\",\"@label\",\"@hasData\"],[\"icomoon-coin-euro\",[30,[36,0],[[32,1,[\"profitabilityInPercent\"]]],null],[30,[36,1],[\"profitability\"],null],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"analytics/profitability-tooltip\",[],[[\"@isClientWithMixedProjects\",\"@amountSpent\",\"@userCosts\",\"@expensesCosts\",\"@profitabilityTotal\"],[true,[32,1,[\"amountSpentTotalIfCompleted\"]],[32,1,[\"userCosts\"]],[32,1,[\"expensesCosts\"]],[32,1,[\"profitabilityTotal\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"percent-fmt-unit\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/kpi/profit.hbs"
    }
  });

  _exports.default = _default;
});