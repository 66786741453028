define("coffeecup/templates/components/pie-timer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B2JHfQ98",
    "block": "{\"symbols\":[\"@diameter\"],\"statements\":[[10,\"svg\"],[15,\"width\",[30,[36,1],[[32,1],\"px\"],null]],[15,\"height\",[30,[36,1],[[32,1],\"px\"],null]],[14,\"viewBox\",\"10 10 80 80\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isModeProgress\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"path\"],[14,0,\"border\"],[14,\"fill\",\"none\"],[14,\"d\",\"M50 15\\n         A 34 34 0 0 1 50 85\\n         A 34 34 0 0 1 50 15\"],[14,\"stroke-width\",\"4\"],[12],[13],[2,\"\\n    \"],[10,\"path\"],[14,\"fill\",\"none\"],[14,0,\"loader\"],[14,\"d\",\"M 50 35\\n         A 15 15 0 0 1 50 65\\n         A 15 15 0 0 1 50 35\"],[14,\"stroke-width\",\"30\"],[15,\"stroke-dasharray\",[30,[36,1],[\"0 0 \",[32,0,[\"dashSize\"]],\" 95\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,0,[\"isModeBusy\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"path\"],[14,0,\"busy-border\"],[14,\"fill\",\"none\"],[14,\"d\",\"M50 15\\n         A 34 34 0 0 1 50 85\\n         A 34 34 0 0 1 50 15\"],[14,\"stroke-width\",\"4\"],[12],[13],[2,\"\\n    \"],[10,\"path\"],[14,\"fill\",\"none\"],[14,0,\"busy-fg\"],[14,\"d\",\"M 50 35\\n         A 15 15 0 0 1 50 65\\n         A 15 15 0 0 1 50 35\"],[14,\"stroke-width\",\"30\"],[12],[2,\"\\n      \"],[10,\"animate\"],[14,\"attributeName\",\"stroke-dasharray\"],[14,\"values\",\"0 0 0 95;0 0 95 95;0 95 95 95\"],[14,\"dur\",\"2.5\"],[14,\"repeatCount\",\"indefinite\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/pie-timer.hbs"
    }
  });

  _exports.default = _default;
});