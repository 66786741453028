define("coffeecup/templates/components/validation/validation-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vt8ywj7L",
    "block": "{\"symbols\":[],\"statements\":[[10,\"button\"],[15,\"onclick\",[30,[36,5],[[32,0],\"onClick\"],null]],[15,\"disabled\",[34,6]],[15,0,[31,[\"validation-row-action-button \",[34,7]]]],[12],[2,\"\\n\"],[6,[37,4],[[35,8]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"flex-center small-space-bottom \",[34,0]]]],[12],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[35,1]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[1,[30,[36,14],null,[[\"openModal\",\"reason\",\"recipient\",\"validation\",\"week\",\"afterSave\"],[[35,13],[35,12],[35,11],[35,10],[35,9],[30,[36,5],[[32,0],\"triggerAnimation\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"label\",\"t\",\"isActive\",\"unless\",\"action\",\"buttonDisabled\",\"color\",\"isPlaceholder\",\"week\",\"validation\",\"validatedUser\",\"reason\",\"openModal\",\"modals/reject-validation\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/validation/validation-button.hbs"
    }
  });

  _exports.default = _default;
});