define("coffeecup/routes/team/users/edit/vacation", ["exports", "coffeecup/routes/planner/absences/calendar/edit", "coffeecup/services/events"], function (_exports, _edit, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({
    controllerName: 'planner.absences.calendar.edit',
    events: Ember.inject.service(),
    userId: 0,
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'team.users.edit.index') {
          this.set('_forceTransition', true);
          this.send('reloadVacationEntries');

          if (this.controller.get('model.reducesOvertime')) {
            this.get('events').trigger(_events.RELOAD_OVERTIME);
          }
        }

        this._super(transition);
      },
      transitionToList: function transitionToList() {
        this.transitionTo('team.users.edit', this.get('userId'));
      }
    },
    beforeModel: function beforeModel(transition) {
      this.set('userId', transition && transition.from && transition.from.parent && transition.from.parent.params && transition.from.parent.params.user_id);
    },
    timeEntryDeleted: function timeEntryDeleted() {}
  });

  _exports.default = _default;
});