define("coffeecup/serializers/project", ["exports", "coffeecup/config/environment", "coffeecup/serializers/application"], function (_exports, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //serializers/project.js
  // Temporary - Map null project states to default value
  var setDefaultProjectState = function setDefaultProjectState(project) {
    if (project.projectState === null || project.projectState === 0) {
      project.projectState = project.status === _environment.default.constants.STATUS_ARCHIVED ? 3 : 2; // 3 - completed, 2 - in progress
    }
  };

  var _default = _application.default.extend({
    attrs: {
      isProjectManager: {
        serialize: false
      } // makes the attribute readOnly

    },
    normalizeResponse: function normalizeResponse(store, model, payload, id, type) {
      if (payload.projects) {
        payload.projects.forEach(setDefaultProjectState);
      } else if (payload.project) {
        setDefaultProjectState(payload.project);
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});