define("coffeecup/templates/components/manage-table/cells/project-budget-mode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "urZ9KMLB",
    "block": "{\"symbols\":[\"@model\",\"@config\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"manage-table-cell \",[32,0,[\"classes\"]]]]],[15,5,[32,0,[\"safeWidth\"]]],[12],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[\"manage-table-cell-contents \",[32,2,[\"contentClasses\"]]]]],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isProject\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"tags-cell-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"tag-item\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"isModeFixedBudget\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"budgetByFixedPrice\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,1,[\"isModeByEffort\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"budgetByEffort\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,1,[\"isModeNotBillable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"nonBillable\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n          \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-budget-mode.hbs"
    }
  });

  _exports.default = _default;
});