define("coffeecup/utils/nl2br", ["exports", "coffeecup/utils/strip-tags"], function (_exports, _stripTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Insert line breaks where newlines (\n) occur in the string
   * Strips HTML tags
   * @param {String}
   * @returns {String}
   */
  function nl2br(text) {
    if (Ember.isEmpty(text)) {
      return '';
    }

    text = (0, _stripTags.default)(text); // nl2br

    var breakTag = '<br />';
    text = (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    return Ember.String.htmlSafe(text);
  }

  var _default = nl2br;
  _exports.default = _default;
});