define("coffeecup/routes/analytics/time-entries", ["exports", "coffeecup/routes/_super-route", "coffeecup/utils/date", "coffeecup/config/environment"], function (_exports, _superRoute, _date, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var AnalyticsTimeEntriesRoute = _superRoute.default.extend({
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    currentUserService: Ember.inject.service('currentUser'),
    titleToken: function titleToken() {
      return this.get('i18n').t('timeEntries');
    },
    queryParams: {
      from: {
        refreshModel: true
      },
      to: {
        refreshModel: true
      },
      client: {
        refreshModel: true
      },
      project: {
        refreshModel: true
      },
      task: {
        refreshModel: true
      },
      team: {
        refreshModel: true
      },
      user: {
        refreshModel: true
      },
      tag: {
        refreshModel: true
      },
      timeEntryReference: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      billableType: {
        refreshModel: true
      },
      invoice: {
        refreshModel: true
      },
      comment: {
        refreshModel: true
      }
    },
    deserializeQueryParam: function deserializeQueryParam(value, urlKey, defaultValueType) {
      if (['client', 'project', 'task', 'user', 'tag', 'timeEntryReference', 'invoice', 'team'].indexOf(urlKey) > -1) {
        return value && value.split('-');
      } else {
        return this._super.apply(this, arguments);
      }
    },
    serializeQueryParam: function serializeQueryParam(value, urlKey, defaultValueType) {
      if (['client', 'project', 'task', 'user', 'tag', 'timeEntryReference', 'invoice', 'team'].indexOf(urlKey) > -1) {
        return typeof value === 'string' ? value : value.join('-');
      } else {
        return this._super.apply(this, arguments);
      }
    },
    // holds last used page query param
    lastPage: 0,
    setupController: function setupController(controller, context, transition) {
      //console.log('##################### SETUP_CONTROLLER #################');
      var model = context.timeEntries;
      controller.set('timeEntriesQuery', context.timeEntriesQuery);
      controller.set('currentPage', model.meta.currentPage);
      controller.set('totalPages', model.meta.totalPages);
      controller.set('currentGroupByCriterion', controller.get('groupByCriterion'));
      controller.set('checkedArray', []);

      if (context.analytics) {
        controller.set('analyticsQuery', context.analyticsQuery);
        controller.set('analytics', context.analytics.get('firstObject'));
      }

      controller.set('selectedTimeEntries', Ember.A()); // unset selected entries

      this._super.apply(this, [controller, model, transition]);
    },
    model: function model(params, transition) {
      var _this = this;

      var controller = this.controllerFor('analytics.time-entries');
      var timerModeIsStartTimeEndTime = this.get('accountSettings.timerModeIsStartTimeEndTime');
      var from = (0, _date.validateDate)(params.from, controller.get('fromDefaultValue'));
      var to = (0, _date.validateDate)(params.to, controller.get('toDefaultValue'));
      var isDefaultUser = !controller.get('currentUserCanFilterStaff');
      var query = {
        trackingType: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK,
        day: {
          '>=': from,
          '<=': to
        }
      }; // add projects, users and tasks

      ['project', 'task', 'user', 'timeEntryReference', 'invoice', 'team'].forEach(function (param) {
        var paramVal = params[param];

        if (paramVal && _typeof(paramVal) === 'object' && paramVal.length > 0) {
          var tmp = [];
          paramVal.map(function (id) {
            if (id && _this.store.peekRecord(param, id)) {
              tmp.push(id);
            } else {
              params[param].removeObject(id);
            }
          });
          if (tmp.length > 0) query[param] = tmp;
        }
      });

      if (params.comment) {
        query.comment = {
          contains: params.comment
        };
      }

      if (isDefaultUser) {
        query.user = [controller.get('currentUser.id')];
      } // add clients


      var clients = params['client'];

      if (clients && _typeof(clients) === 'object' && clients.length > 0) {
        var tmp = [];
        clients.map(function (id) {
          if (id && _this.store.peekRecord('client', id)) {
            tmp.push(id);
          }
        });
        if (tmp.length > 0) query['project.client'] = tmp;
      } // add tags


      var tags = params['tag'];

      if (tags && _typeof(tags) === 'object' && tags.length > 0) {
        var _tmp = [];
        tags.map(function (id) {
          if (id && _this.store.peekRecord('tag', id)) {
            _tmp.push(id);
          }
        });
        if (_tmp.length > 0) query['tagAssignments.tag'] = _tmp;
      } // Shallow copy of query


      var analyticsQuery = jQuery.extend({}, query); // rewrite start and end date params

      analyticsQuery.start_date = moment.utc(from).startOf('day').toJSON();
      analyticsQuery.end_date = moment.utc(to).endOf('day').toJSON();
      delete analyticsQuery.day;

      switch (params.billableType) {
        case _environment.default.constants.SHOW_BILLABLE:
          query['billable'] = true;
          analyticsQuery['billable'] = true;
          break;

        case _environment.default.constants.SHOW_NON_BILLABLE:
          query['billable'] = false;
          analyticsQuery['billable'] = false;
          break;
      } // add billed and billable


      switch (params.status) {
        case _environment.default.constants.SHOW_BILLED_LOCKED:
          analyticsQuery['billed'] = true;
          query['billedAt'] = {
            '!': 'null'
          };
          break;

        case _environment.default.constants.SHOW_BILLABLE_OPEN:
          analyticsQuery['billed'] = false;
          query['billedAt'] = null;
          break;
      } // if the page params didn't change, most likely one of the other params has changed
      // if that's the case we cancel this transition and page to the first page


      if (this.get('lastPage') == params.page && params.page > 0) {
        params.page = 0; //console.log('##################### ABORT #################');

        this.transitionTo({
          queryParams: params
        });
        transition.abort();
      } else {
        //console.log('##################### QUERY #################');
        // store the current query for comparison
        this.set('lastPage', params.page);
        var secondarySortingParam = timerModeIsStartTimeEndTime ? 'startTime' : 'sorting';
        var sortCriteria = 'day';
        var analyticsGroupBy = null;

        switch (controller.get('groupByCriterion')) {
          case _environment.default.constants.GROUP_BY_DATE:
            sortCriteria = 'day';
            analyticsGroupBy = 'day';
            break;

          case _environment.default.constants.GROUP_BY_CLIENT:
            sortCriteria = 'project.client.name';
            analyticsGroupBy = 'client';
            break;

          case _environment.default.constants.GROUP_BY_PROJECT:
            sortCriteria = 'project.name';
            analyticsGroupBy = 'project';
            break;

          case _environment.default.constants.GROUP_BY_STAFF:
            sortCriteria = 'user.firstname';
            analyticsGroupBy = 'user';
            break;

          case _environment.default.constants.GROUP_BY_TEAM:
            sortCriteria = 'team.name';
            analyticsGroupBy = 'team';
            break;

          case _environment.default.constants.GROUP_BY_TASK:
            sortCriteria = 'task.label';
            analyticsGroupBy = 'task';
            break;

          case _environment.default.constants.GROUP_BY_REFERENCE:
            sortCriteria = 'timeEntryReference.shortCode';
            analyticsGroupBy = 'timeEntryReference';
            break;
        } // load new analytics only if page = 0 (@see few lines above) or if no analytics data is present


        if (analyticsGroupBy !== null) {
          analyticsQuery['groupBy'] = analyticsGroupBy;
        }

        var loadAnalytics = params.page == 0 || this.store.peekAll('analytics/time-entry', analyticsQuery).get('length') <= 0;
        var timeEntriesQuery = {
          sort: sortCriteria + ' ' + params.sort + ', ' + secondarySortingParam + ' ' + params.sort,
          limit: params.limit,
          skip: params.page * params.limit,
          where: query
        };
        return Ember.RSVP.hash({
          timeEntriesQuery: timeEntriesQuery,
          timeEntries: this.store.query('time-entry', timeEntriesQuery),
          analyticsQuery: analyticsQuery,
          analytics: loadAnalytics ? this.store.query('analytics/time-entry', analyticsQuery) : null,
          from: from,
          to: to
        });
      }
    },
    actions: {
      editTimeEntry: function editTimeEntry(timeEntry) {
        var route = 'analytics.time-entries.edit';
        this.transitionTo(route, timeEntry.get('id'));
      }
    }
  });

  var _default = AnalyticsTimeEntriesRoute;
  _exports.default = _default;
});