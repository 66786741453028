define("coffeecup/templates/components/manage-table/cells/project-hourly-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J7kjtuLa",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"classes\"]]],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isProject\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,0,[\"projectValue\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,0,[\"clientValue\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[32,0,[\"tooltipContent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"cc-ember-tooltip\",[],[[\"@side\",\"@popperContainer\"],[\"bottom\",\"main\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"kpi-tooltip\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"tooltipContent\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n            \"],[10,\"span\"],[12],[2,\"\\n              \"],[1,[32,1,[\"name\"]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"span\"],[12],[2,\"\\n              \"],[1,[30,[36,0],[[32,1,[\"hourlyRate\"]],false,true],null]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"currency-fmt\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-hourly-rate.hbs"
    }
  });

  _exports.default = _default;
});