define("coffeecup/models/holiday", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    country: _emberData.default.attr('string'),
    region: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    date: _emberData.default.attr('date')
  });

  _exports.default = _default;
});