define("coffeecup/templates/components/input/cc-radiobox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "98za+39u",
    "block": "{\"symbols\":[\"@leftAlign\",\"@name\",\"@checked\",\"@value\",\"@disabled\"],\"statements\":[[10,\"div\"],[14,0,\"radio\"],[15,5,[30,[36,4],[[32,1],\"text-align: left\"],null]],[12],[2,\"\\n  \"],[8,\"radio-button\",[],[[\"@name\",\"@checked\",\"@value\",\"@disabled\",\"@id\"],[[32,2],[32,3],[32,4],[32,5],[32,0,[\"guid\"]]]],null],[2,\"\\n  \"],[10,\"label\"],[15,\"for\",[31,[[32,0,[\"guid\"]]]]],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"space-left\"],[12],[2,\"\\n        \"],[1,[30,[36,4],[[35,3],[30,[36,1],[[35,3]],null]],null]],[2,\"\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,2],null,[[\"content\"],[[30,[36,1],[[35,0]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"infoTooltip\",\"t\",\"t-tip-icon\",\"translateKey\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/cc-radiobox.hbs"
    }
  });

  _exports.default = _default;
});