define("coffeecup/routes/time-entries", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //routes/time-entries.js
  var TimeEntriesRoute = _superRoute.default.extend({});

  var _default = TimeEntriesRoute;
  _exports.default = _default;
});