define("coffeecup/instance-initializers/timezone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'timezone',
        id: 'Europe/Dublin',
        attributes: {
          group: 'Europe',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Lisbon',
        attributes: {
          group: 'Europe',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/London',
        attributes: {
          group: 'Europe',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Amsterdam',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Andorra',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Belgrade',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Berlin',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Brussels',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Budapest',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Copenhagen',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Gibraltar',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Luxembourg',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Madrid',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Malta',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Monaco',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Oslo',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Paris',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Prague',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Rome',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Stockholm',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Tirane',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Vaduz',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Vienna',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Warsaw',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Zurich',
        attributes: {
          group: 'Europe',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Athens',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Bucharest',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Chisinau',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Helsinki',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Istanbul',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Kiev',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Riga',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Simferopol',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Sofia',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Tallinn',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Uzhgorod',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Vilnius',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Zaporozhye',
        attributes: {
          group: 'Europe',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Kaliningrad',
        attributes: {
          group: 'Europe',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Minsk',
        attributes: {
          group: 'Europe',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Moscow',
        attributes: {
          group: 'Europe',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Samara',
        attributes: {
          group: 'Europe',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Europe/Volgograd',
        attributes: {
          group: 'Europe',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Adak',
        attributes: {
          group: 'America',
          time: 'GMT-10:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Anchorage',
        attributes: {
          group: 'America',
          time: 'GMT-09:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Juneau',
        attributes: {
          group: 'America',
          time: 'GMT-09:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Nome',
        attributes: {
          group: 'America',
          time: 'GMT-09:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Sitka',
        attributes: {
          group: 'America',
          time: 'GMT-09:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Yakutat',
        attributes: {
          group: 'America',
          time: 'GMT-09:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Dawson',
        attributes: {
          group: 'America',
          time: 'GMT-08:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Los_Angeles',
        attributes: {
          group: 'America',
          time: 'GMT-08:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Metlakatla',
        attributes: {
          group: 'America',
          time: 'GMT-08:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Santa_Isabel',
        attributes: {
          group: 'America',
          time: 'GMT-08:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Tijuana',
        attributes: {
          group: 'America',
          time: 'GMT-08:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Vancouver',
        attributes: {
          group: 'America',
          time: 'GMT-08:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Whitehorse',
        attributes: {
          group: 'America',
          time: 'GMT-08:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Boise',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Cambridge_Bay',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Chihuahua',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Dawson_Creek',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Denver',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Edmonton',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Hermosillo',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Inuvik',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Mazatlan',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Ojinaga',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Phoenix',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Yellowknife',
        attributes: {
          group: 'America',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Bahia_Banderas',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Belize',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Cancun',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Chicago',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Costa_Rica',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/El_Salvador',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Guatemala',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Indiana/Knox',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Indiana/Tell_City',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Managua',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Matamoros',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Menominee',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Merida',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Mexico_City',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Monterrey',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/North_Dakota/Beulah',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/North_Dakota/Center',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/North_Dakota/New_Salem',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Rainy_River',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Rankin_Inlet',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Regina',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Resolute',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Swift_Current',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Tegucigalpa',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Winnipeg',
        attributes: {
          group: 'America',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Atikokan',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Bogota',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Cayman',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Detroit',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Grand_Turk',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Guayaquil',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Havana',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Indiana/Indianapolis',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Indiana/Marengo',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Indiana/Petersburg',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Indiana/Vevay',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Indiana/Vincennes',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Indiana/Winamac',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Iqaluit',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Jamaica',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Kentucky/Louisville',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Kentucky/Monticello',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Lima',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Montreal',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Nassau',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/New_York',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Nipigon',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Panama',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Pangnirtung',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Port-au-Prince',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Thunder_Bay',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Toronto',
        attributes: {
          group: 'America',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Caracas',
        attributes: {
          group: 'America',
          time: 'GMT-04:30'
        }
      }, {
        type: 'timezone',
        id: 'America/Anguilla',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Antigua',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/San_Luis',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Aruba',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Asuncion',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Barbados',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Blanc-Sablon',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Boa_Vista',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Campo_Grande',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Cuiaba',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Curacao',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Dominica',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Eirunepe',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Glace_Bay',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Goose_Bay',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Grenada',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Guadeloupe',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Guyana',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Halifax',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/La_Paz',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Manaus',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Martinique',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Moncton',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Montserrat',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Port_of_Spain',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Porto_Velho',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Puerto_Rico',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Rio_Branco',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Santiago',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Santo_Domingo',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/St_Kitts',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/St_Lucia',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/St_Thomas',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/St_Vincent',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Thule',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Tortola',
        attributes: {
          group: 'America',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'America/St_Johns',
        attributes: {
          group: 'America',
          time: 'GMT-03:30'
        }
      }, {
        type: 'timezone',
        id: 'America/Araguaina',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/Buenos_Aires',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/Catamarca',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/Cordoba',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/Jujuy',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/La_Rioja',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/Mendoza',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/Rio_Gallegos',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/Salta',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/San_Juan',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/Tucuman',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Argentina/Ushuaia',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Bahia',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Belem',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Cayenne',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Fortaleza',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Godthab',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Maceio',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Miquelon',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Montevideo',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Paramaribo',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Recife',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Santarem',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Sao_Paulo',
        attributes: {
          group: 'America',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Noronha',
        attributes: {
          group: 'America',
          time: 'GMT-02:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Scoresbysund',
        attributes: {
          group: 'America',
          time: 'GMT-01:00'
        }
      }, {
        type: 'timezone',
        id: 'America/Danmarkshavn',
        attributes: {
          group: 'America',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Abidjan',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Accra',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Bamako',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Banjul',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Bissau',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Casablanca',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Conakry',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Dakar',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/El_Aaiun',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Freetown',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Lome',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Monrovia',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Nouakchott',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Ouagadougou',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Sao_Tome',
        attributes: {
          group: 'Africa',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Algiers',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Bangui',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Brazzaville',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Ceuta',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Douala',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Kinshasa',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Lagos',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Libreville',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Luanda',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Malabo',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Ndjamena',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Niamey',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Porto-Novo',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Tripoli',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Tunis',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Windhoek',
        attributes: {
          group: 'Africa',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Blantyre',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Bujumbura',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Cairo',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Gaborone',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Harare',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Johannesburg',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Kigali',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Lubumbashi',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Lusaka',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Maputo',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Maseru',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Mbabane',
        attributes: {
          group: 'Africa',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Addis_Ababa',
        attributes: {
          group: 'Africa',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Asmara',
        attributes: {
          group: 'Africa',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Dar_es_Salaam',
        attributes: {
          group: 'Africa',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Djibouti',
        attributes: {
          group: 'Africa',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Kampala',
        attributes: {
          group: 'Africa',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Khartoum',
        attributes: {
          group: 'Africa',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Mogadishu',
        attributes: {
          group: 'Africa',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Africa/Nairobi',
        attributes: {
          group: 'Africa',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/Palmer',
        attributes: {
          group: 'Antarctica',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/Rothera',
        attributes: {
          group: 'Antarctica',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/Syowa',
        attributes: {
          group: 'Antarctica',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/Mawson',
        attributes: {
          group: 'Antarctica',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/Vostok',
        attributes: {
          group: 'Antarctica',
          time: 'GMT+06:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/Davis',
        attributes: {
          group: 'Antarctica',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/Casey',
        attributes: {
          group: 'Antarctica',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/DumontDUrville',
        attributes: {
          group: 'Antarctica',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/Macquarie',
        attributes: {
          group: 'Antarctica',
          time: 'GMT+11:00'
        }
      }, {
        type: 'timezone',
        id: 'Antarctica/McMurdo',
        attributes: {
          group: 'Antarctica',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Amman',
        attributes: {
          group: 'Asia',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Beirut',
        attributes: {
          group: 'Asia',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Damascus',
        attributes: {
          group: 'Asia',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Gaza',
        attributes: {
          group: 'Asia',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Jerusalem',
        attributes: {
          group: 'Asia',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Nicosia',
        attributes: {
          group: 'Asia',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Aden',
        attributes: {
          group: 'Asia',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Baghdad',
        attributes: {
          group: 'Asia',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Bahrain',
        attributes: {
          group: 'Asia',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Kuwait',
        attributes: {
          group: 'Asia',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Qatar',
        attributes: {
          group: 'Asia',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Riyadh',
        attributes: {
          group: 'Asia',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Tehran',
        attributes: {
          group: 'Asia',
          time: 'GMT+03:30'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Baku',
        attributes: {
          group: 'Asia',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Dubai',
        attributes: {
          group: 'Asia',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Muscat',
        attributes: {
          group: 'Asia',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Tbilisi',
        attributes: {
          group: 'Asia',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Yerevan',
        attributes: {
          group: 'Asia',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Kabul',
        attributes: {
          group: 'Asia',
          time: 'GMT+04:30'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Aqtau',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Aqtobe',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Ashgabat',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Dushanbe',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Karachi',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Oral',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Samarkand',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Tashkent',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Colombo',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:30'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Kolkata',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:30'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Kathmandu',
        attributes: {
          group: 'Asia',
          time: 'GMT+05:45'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Almaty',
        attributes: {
          group: 'Asia',
          time: 'GMT+06:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Bishkek',
        attributes: {
          group: 'Asia',
          time: 'GMT+06:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Dhaka',
        attributes: {
          group: 'Asia',
          time: 'GMT+06:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Qyzylorda',
        attributes: {
          group: 'Asia',
          time: 'GMT+06:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Thimphu',
        attributes: {
          group: 'Asia',
          time: 'GMT+06:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Yekaterinburg',
        attributes: {
          group: 'Asia',
          time: 'GMT+06:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Rangoon',
        attributes: {
          group: 'Asia',
          time: 'GMT+06:30'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Bangkok',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Ho_Chi_Minh',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Hovd',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Jakarta',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Novokuznetsk',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Novosibirsk',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Omsk',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Phnom_Penh',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Pontianak',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Vientiane',
        attributes: {
          group: 'Asia',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Brunei',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Choibalsan',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Chongqing',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Harbin',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Hong_Kong',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Kashgar',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Krasnoyarsk',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Kuala_Lumpur',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Kuching',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Macau',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Makassar',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Manila',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Shanghai',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Singapore',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Taipei',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Ulaanbaatar',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Urumqi',
        attributes: {
          group: 'Asia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Dili',
        attributes: {
          group: 'Asia',
          time: 'GMT+09:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Irkutsk',
        attributes: {
          group: 'Asia',
          time: 'GMT+09:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Jayapura',
        attributes: {
          group: 'Asia',
          time: 'GMT+09:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Pyongyang',
        attributes: {
          group: 'Asia',
          time: 'GMT+09:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Seoul',
        attributes: {
          group: 'Asia',
          time: 'GMT+09:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Tokyo',
        attributes: {
          group: 'Asia',
          time: 'GMT+09:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Yakutsk',
        attributes: {
          group: 'Asia',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Sakhalin',
        attributes: {
          group: 'Asia',
          time: 'GMT+11:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Vladivostok',
        attributes: {
          group: 'Asia',
          time: 'GMT+11:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Anadyr',
        attributes: {
          group: 'Asia',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Kamchatka',
        attributes: {
          group: 'Asia',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Asia/Magadan',
        attributes: {
          group: 'Asia',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/Bermuda',
        attributes: {
          group: 'Atlantic',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/Stanley',
        attributes: {
          group: 'Atlantic',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/South_Georgia',
        attributes: {
          group: 'Atlantic',
          time: 'GMT-02:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/Azores',
        attributes: {
          group: 'Atlantic',
          time: 'GMT-01:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/Cape_Verde',
        attributes: {
          group: 'Atlantic',
          time: 'GMT-01:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/Canary',
        attributes: {
          group: 'Atlantic',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/Faroe',
        attributes: {
          group: 'Atlantic',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/Madeira',
        attributes: {
          group: 'Atlantic',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/Reykjavik',
        attributes: {
          group: 'Atlantic',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Atlantic/St_Helena',
        attributes: {
          group: 'Atlantic',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Perth',
        attributes: {
          group: 'Australia',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Eucla',
        attributes: {
          group: 'Australia',
          time: 'GMT+08:45'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Adelaide',
        attributes: {
          group: 'Australia',
          time: 'GMT+09:30'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Broken_Hill',
        attributes: {
          group: 'Australia',
          time: 'GMT+09:30'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Darwin',
        attributes: {
          group: 'Australia',
          time: 'GMT+09:30'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Brisbane',
        attributes: {
          group: 'Australia',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Currie',
        attributes: {
          group: 'Australia',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Hobart',
        attributes: {
          group: 'Australia',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Lindeman',
        attributes: {
          group: 'Australia',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Melbourne',
        attributes: {
          group: 'Australia',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Sydney',
        attributes: {
          group: 'Australia',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Australia/Lord_Howe',
        attributes: {
          group: 'Australia',
          time: 'GMT+10:30'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Antananarivo',
        attributes: {
          group: 'Indian',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Comoro',
        attributes: {
          group: 'Indian',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Mayotte',
        attributes: {
          group: 'Indian',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Mahe',
        attributes: {
          group: 'Indian',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Mauritius',
        attributes: {
          group: 'Indian',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Reunion',
        attributes: {
          group: 'Indian',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Kerguelen',
        attributes: {
          group: 'Indian',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Maldives',
        attributes: {
          group: 'Indian',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Chagos',
        attributes: {
          group: 'Indian',
          time: 'GMT+06:00'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Cocos',
        attributes: {
          group: 'Indian',
          time: 'GMT+06:30'
        }
      }, {
        type: 'timezone',
        id: 'Indian/Christmas',
        attributes: {
          group: 'Indian',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Midway',
        attributes: {
          group: 'Pacific',
          time: 'GMT-11:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Niue',
        attributes: {
          group: 'Pacific',
          time: 'GMT-11:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Pago_Pago',
        attributes: {
          group: 'Pacific',
          time: 'GMT-11:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Honolulu',
        attributes: {
          group: 'Pacific',
          time: 'GMT-10:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Johnston',
        attributes: {
          group: 'Pacific',
          time: 'GMT-10:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Rarotonga',
        attributes: {
          group: 'Pacific',
          time: 'GMT-10:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Tahiti',
        attributes: {
          group: 'Pacific',
          time: 'GMT-10:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Marquesas',
        attributes: {
          group: 'Pacific',
          time: 'GMT-09:30'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Gambier',
        attributes: {
          group: 'Pacific',
          time: 'GMT-09:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Pitcairn',
        attributes: {
          group: 'Pacific',
          time: 'GMT-08:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Easter',
        attributes: {
          group: 'Pacific',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Galapagos',
        attributes: {
          group: 'Pacific',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Palau',
        attributes: {
          group: 'Pacific',
          time: 'GMT+09:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Chuuk',
        attributes: {
          group: 'Pacific',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Guam',
        attributes: {
          group: 'Pacific',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Port_Moresby',
        attributes: {
          group: 'Pacific',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Saipan',
        attributes: {
          group: 'Pacific',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Efate',
        attributes: {
          group: 'Pacific',
          time: 'GMT+11:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Guadalcanal',
        attributes: {
          group: 'Pacific',
          time: 'GMT+11:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Kosrae',
        attributes: {
          group: 'Pacific',
          time: 'GMT+11:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Noumea',
        attributes: {
          group: 'Pacific',
          time: 'GMT+11:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Pohnpei',
        attributes: {
          group: 'Pacific',
          time: 'GMT+11:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Norfolk',
        attributes: {
          group: 'Pacific',
          time: 'GMT+11:30'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Auckland',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Fiji',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Funafuti',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Kwajalein',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Majuro',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Nauru',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Tarawa',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Wake',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Wallis',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Chatham',
        attributes: {
          group: 'Pacific',
          time: 'GMT+12:45'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Apia',
        attributes: {
          group: 'Pacific',
          time: 'GMT+13:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Enderbury',
        attributes: {
          group: 'Pacific',
          time: 'GMT+13:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Fakaofo',
        attributes: {
          group: 'Pacific',
          time: 'GMT+13:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Tongatapu',
        attributes: {
          group: 'Pacific',
          time: 'GMT+13:00'
        }
      }, {
        type: 'timezone',
        id: 'Pacific/Kiritimati',
        attributes: {
          group: 'Pacific',
          time: 'GMT+14:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+12',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-12:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+11',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-11:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+10',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-10:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+9',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-09:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+8',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-08:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+7',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-07:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+6',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-06:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+5',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-05:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+4',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-04:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+3',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-03:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+2',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-02:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT+1',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT-01:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+00:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-1',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+01:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-2',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+02:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-3',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+03:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-4',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+04:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-5',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+05:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-6',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+06:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-7',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+07:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-8',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+08:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-9',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+09:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-10',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+10:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-11',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+11:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-12',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+12:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-13',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+13:00'
        }
      }, {
        type: 'timezone',
        id: 'GMT-14',
        attributes: {
          group: 'GMT Offsets',
          time: 'GMT+14:00'
        }
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'timezone',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});