define("coffeecup/serializers/user", ["exports", "coffeecup/config/environment", "coffeecup/serializers/application"], function (_exports, _environment, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      imageDirectoryURL: {
        serialize: false
      } // makes the attribute readOnly

    },
    serializeAttribute: function serializeAttribute(record, json, key, attribute) {
      var owner = Ember.getOwner(this);
      var isAdmin = owner.lookup('controller:application').get('currentUser.isAdmin');
      var canManageUsers = owner.lookup('service:permissions').get('canManageUsers');
      var disallowedFields = _environment.default.constants.USER_DISALLOWED_FIELDS;

      if (isAdmin || canManageUsers || disallowedFields.indexOf(key) === -1) {
        this._super(record, json, key, attribute);
      }
    }
  });

  _exports.default = _default;
});