define("coffeecup/utils/dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.verticallyOnScreen = verticallyOnScreen;

  //utils/dom.js
  function verticallyOnScreen(elem) {
    var elemClientRect = elem.getBoundingClientRect();
    var diff = elemClientRect.bottom - elemClientRect.height;
    return elemClientRect.bottom > 0 && diff < window.innerHeight;
  }
});