define("coffeecup/services/google-analytics", ["exports", "coffeecup/services/base-analytics", "coffeecup/config/environment"], function (_exports, _baseAnalytics, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // app/services/google-analytics.js
  var _default = _baseAnalytics.default.extend({
    trackingId: _environment.default.APP.googleAnalyticsTrackingId,
    insertScript: function insertScript() {
      var trackingId = this.get('trackingId');

      if (!trackingId) {
        return;
      }

      this.log('Initializing Google analytics ' + trackingId);
      /* jshint ignore:start */

      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments);
        }, i[r].l = 1 * new Date();
        a = s.createElement(o), m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
      /* jshint ignore:end */

      /* global ga */


      ga('create', trackingId, 'auto');
      ga('set', 'anonymizeIp', true);
      var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      ga('set', 'viewportSize', "".concat(w, "x").concat(h));
    },
    start: function start() {},
    stop: function stop() {},
    send: function send(type, args) {
      if (!window.ga) {
        return;
      }

      ga('send', type, args);
    },
    event: function event(category, action, label) {
      if (!window.ga) {
        return;
      }

      ga('send', 'event', category, action, label);
    },

    /**
     *
     * @param exOptions {appVersion,appName,exDescription,exFatal}
     */
    exception: function exception(exOptions) {
      if (!window.ga) {
        return;
      }

      ga('send', 'exception', exOptions);
    },
    notifyTransition: function notifyTransition(url) {
      this.send('pageview', {
        page: url,
        title: url
      });
    }
  });

  _exports.default = _default;
});