define("coffeecup/templates/components/radial-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kpkx8ZEg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"radial-progress\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"progress \",[30,[36,3],[[35,2],[35,1]],null]]]],[15,\"data-progress\",[34,4]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"circle\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"mask full\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"fill\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"mask half\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"fill\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"fill fix\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"inset\"],[12],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"percentage text-overflow \",[30,[36,3],[[35,0],\"has-verb\"],null]]]],[12],[2,\"\\n        \"],[1,[34,5]],[10,\"span\"],[15,0,[31,[\"percent-sign \",[30,[36,3],[[35,6],\"hidden\"],null]]]],[12],[2,\"%\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"verb\"],[12],[2,\"\\n          \"],[10,\"hr\"],[12],[13],[2,\"\\n          \"],[1,[34,0]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"verb\",\"fulfilledClass\",\"isFulfilled\",\"if\",\"presentedProgress\",\"progressText\",\"hidePercentSign\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/radial-progress.hbs"
    }
  });

  _exports.default = _default;
});