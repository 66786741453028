define("coffeecup/mixins/filtered-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FilteredContentMixin = Ember.Mixin.create({
    /**
     * archived list entries
     */
    showInactive: false,

    /**
     * active list entries
     */
    showActive: true,
    // ---------- Filter(optional)
    filterTerm: null,
    // this is bounded to a input element in the template
    filterTermDebounced: null,
    filterTermRarely: function filterTermRarely() {
      this.set('filterTermDebounced', this.get('filterTerm'));
    },
    // TODO: consider hiding list elements by setting display:none instead of re-rendering the DOM on and on
    _filterTermDebouncer: Ember.observer('filterTerm', function () {
      Ember.run.debounce(this, this.filterTermRarely, 300);
    }),
    // filter can be a function of an array of list item keys
    // function : function(item, index, list)
    // keys     : ['id', 'name']
    filterKeys: ['label'],
    // ---------- Sort(optional)
    // you can assign an array OR a function to sortBy
    sortKeys: ['label:asc', 'id:asc'],
    // ---------- Group(optional)
    // you can provide a function to groupBy
    groupFn: function groupFn(item) {
      return item.get('status');
    },
    // you can provide custom attributes to the title item in a group
    // each attribute is a function with the group title obtained from groupFn
    // as argument
    groupTitleAttrs: [],
    _filteredContentProxy: Ember.computed.filter('model', function (content) {
      var showInactive = this.get('showInactive');
      return !content.get('isNew') && (showInactive || !content.get('isInactive'));
    }).property('model.@each.{isNew,isInactive,isActive}', 'showInactive'),
    // This is a workaround for a bug where items disappeared after editing until the filter is changed
    filteredContent: Ember.computed('_filteredContentProxy.[]', function () {
      return this.get('_filteredContentProxy').toArray();
    }).readOnly(),
    isLoaded: Ember.computed('model.@each.isLoaded', {
      get: function get() {
        return this.get('model').filterBy('isLoaded', true).get('length') > 0;
      }
    }),

    /**
     * set of actions
     */
    actions: {
      /**
       * toggle archived items
       */
      toogleInactive: function toogleInactive() {
        this.toggleProperty('showInactive');
      }
    }
  });
  var _default = FilteredContentMixin;
  _exports.default = _default;
});