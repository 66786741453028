define("coffeecup/templates/components/toggle-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SVZdSU+w",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"toggle-bar\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"semi-circle\"],[12],[13],[2,\"\\n  \"],[10,\"a\"],[14,0,\"btn btn-default btn-noborder\"],[12],[10,\"span\"],[15,0,[31,[[34,0],\" small\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"toggleButtonIconClass\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/toggle-footer.hbs"
    }
  });

  _exports.default = _default;
});