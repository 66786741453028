define("coffeecup/session-stores/application", ["exports", "ember-simple-auth/session-stores/adaptive"], function (_exports, _adaptive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adaptive.default.extend({
    cookieName: 'coffeecup-session',
    localStorageKey: 'coffeecup:session'
  });

  _exports.default = _default;
});