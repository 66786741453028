define("coffeecup/templates/components/planner/planner-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j/hxYtQc",
    "block": "{\"symbols\":[\"@content\",\"@contentAsHTML\",\"&default\"],\"statements\":[[8,\"tool-tipster\",[[16,0,[32,0,[\"classNames\"]]]],[[\"@content\",\"@contentAsHTML\",\"@side\",\"@functionPosition\"],[[32,1],[32,2],[32,0,[\"side\"]],[30,[36,0],[[32,0,[\"positionArrow\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,3,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/planner-tooltip.hbs"
    }
  });

  _exports.default = _default;
});