define("coffeecup/serializers/analytics/project-period", ["exports", "coffeecup/serializers/analytics/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //serializers/analytics/project.js
  var _default = _project.default.extend({
    type: 'analytics/project-period'
  });

  _exports.default = _default;
});