define("coffeecup/templates/components/absence-tracking/export-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LDwa7B72",
    "block": "{\"symbols\":[\"@toggleModal\"],\"statements\":[[8,\"export-modal\",[],[[\"@startDate\",\"@endDate\",\"@exportPath\",\"@title\",\"@toggleModal\"],[[32,0,[\"startDate\"]],[32,0,[\"endDate\"]],\"absences/export\",[30,[36,0],[\"absenceExport\"],null],[32,1]]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/absence-tracking/export-modal.hbs"
    }
  });

  _exports.default = _default;
});