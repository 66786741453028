define("coffeecup/templates/components/planner/milestone-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j2GekloK",
    "block": "{\"symbols\":[],\"statements\":[[8,\"planner/milestone-tooltip\",[],[[\"@milestones\",\"@day\",\"@side\"],[[32,0,[\"milestone\",\"milestones\"]],[32,0,[\"milestone\",\"day\"]],\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"rotated-square\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"count\"],[12],[2,\"\\n    \"],[1,[32,0,[\"milestone\",\"count\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/milestone-count.hbs"
    }
  });

  _exports.default = _default;
});