define("coffeecup/transforms/notice-period", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NoticePeriod = Ember.Object.extend({
    state: null,
    i18n: null,
    isDirty: false,
    amount: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').amount || 4;
      },
      set: function set(key, val) {
        this.get('state').amount = val;
        this.set('isDirty', true);
        return val;
      }
    }),
    unit: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').unit || 'weeks';
      },
      set: function set(key, val) {
        this.get('state').unit = val;
        this.set('isDirty', true);
        return val;
      }
    }),
    extra: Ember.computed('state.{}', {
      get: function get() {
        var extra = this.get('state').extra;

        if (Ember.isEmpty(extra)) {
          return null;
        }

        return extra;
      },
      set: function set(key, val) {
        this.get('state').extra = val === 'empty' ? null : val;
        this.set('isDirty', true);
        return val;
      }
    }),
    localizedLabel: Ember.computed('amount', 'unit', 'extra', function () {
      var amount = this.get('amount');
      var unit = this.get('i18n').t(this.get('unit'));
      var extra = this.get('extra') !== 'empty' ? this.get('i18n').t(this.get('extra')) : '';
      return "".concat(amount, " ").concat(unit, " ").concat(extra);
    })
  });

  var _default = _emberData.default.Transform.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    serialize: function serialize(value) {
      if (value && typeof value.get !== 'undefined') {
        value.set('isDirty', false);
        return JSON.stringify({
          amount: value.get('amount'),
          unit: value.get('unit'),
          extra: value.get('extra')
        });
      }
    },
    deserialize: function deserialize(value) {
      value = value || '{}';
      var noticePeriod = NoticePeriod.create({
        state: JSON.parse(value)
      });
      noticePeriod.set('i18n', this.get('i18n'));
      return noticePeriod;
    }
  });

  _exports.default = _default;
});