define("coffeecup/utils/focus-error-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = focusErrorInput;

  function focusErrorInput(controller, errors) {
    // focus first text field that could not be validated
    for (var key in errors) {
      if (errors.hasOwnProperty(key)) {
        if (errors[key].get('firstObject')) {
          controller.set('focus', key);
          break;
        }
      }
    }
  }
});