define("coffeecup/templates/components/viewport-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tKsT24tD",
    "block": "{\"symbols\":[\"@currentWeekLabel\"],\"statements\":[[10,\"div\"],[14,0,\"date-month-selector\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"date-arrow btn btn-default btn-noborder\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"prevWeek\"],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icomoon-arrow-left4\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"date-range date-range-picker\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"date-string\"],[12],[2,\"\\n      \"],[1,[32,0,[\"weekRange\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icomoon-arrow-down5\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"date-arrow btn btn-default btn-noborder\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"nextWeek\"],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icomoon-arrow-right4\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isCurrentWeek\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"btn btn-default btn-sm btn-current-week\"],[24,\"data-test\",\"button-current-week\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"currentWeek\"],null],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/viewport-date-picker.hbs"
    }
  });

  _exports.default = _default;
});