define("coffeecup/templates/analytics/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3vbkVH5s",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"analytics-entity\"],[12],[2,\"\\n  \"],[19,\"analytics/projects/-header\",[]],[2,\"\\n  \"],[10,\"section\"],[14,0,\"has-header padding-bottom scrollable list\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"flex-box-wrapper scrollable\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"icon\",\"header\"],[\"folder\",\"noProjectsHeader\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"empty-list-placeholder\",\"-outlet\",\"component\",\"hasNoProjects\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/analytics/projects.hbs"
    }
  });

  _exports.default = _default;
});