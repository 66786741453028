define("coffeecup/routes/planner/absences/scheduler/edit", ["exports", "coffeecup/routes/planner/absences/scheduler/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    controllerName: 'planner.absences.scheduler.new',
    getAbsence: function getAbsence(params) {
      return this.store.findRecord('absence', params.absence_id).then(function (entry) {
        var startDate = moment.utc(entry.get('startDate'));
        var endDate = moment.utc(entry.get('endDate'));
        entry.set('startDate', startDate);
        entry.set('endDate', endDate);
        return entry;
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('planner.absences.scheduler.new');
    }
  });

  _exports.default = _default;
});