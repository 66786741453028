define("coffeecup/models/number-format", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var NumberFormat = _emberData.default.Model.extend({
    // computed properties
    label: Ember.computed('id', 'i18n.locale', {
      get: function get() {
        var tID = this.get('id');
        return this.get('i18n').t('numberFormat' + tID);
      }
    })
  });

  var _default = NumberFormat;
  _exports.default = _default;
});