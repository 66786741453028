define("coffeecup/serializers/account-setting", ["exports", "coffeecup/serializers/application", "coffeecup/config/environment"], function (_exports, _application, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //serializers/analytics/account-setting.js
  var _default = _application.default.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse()
    /*store, primaryModelClass, payload, id, requestType*/
    {
      var processed = this._super.apply(this, arguments); // Set default currency relationship if not defined


      if (processed.data && processed.data[0] && processed.data[0].relationships && !processed.data[0].relationships.currency) {
        processed.data[0].relationships.currency = {
          data: {
            id: _environment.default.constants.CURRENCY_DEFAULT,
            type: 'currency'
          }
        };
      }

      return processed;
    }
  });

  _exports.default = _default;
});