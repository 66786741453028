define("coffeecup/models/analytics/project/user", ["exports", "ember-data/attr", "ember-data-model-fragments/fragment"], function (_exports, _attr, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * serialized Project analytics user model fragment
   * @see //serializers/analytics/project.js for mappings
   */
  var AnalyticsProjectUser = _fragment.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    // hours
    hoursTotal: (0, _attr.default)('number'),
    hoursSpent: (0, _attr.default)('number'),
    hoursNonBillable: (0, _attr.default)('number'),
    hoursPlanned: (0, _attr.default)('number'),
    // hours-rounded
    hoursRoundedTotal: (0, _attr.default)('number'),
    hoursRoundedSpent: (0, _attr.default)('number'),
    hoursRoundedNonBillable: (0, _attr.default)('number'),
    // amounts
    amountSpent: (0, _attr.default)('number'),
    // costs
    costs: (0, _attr.default)('number'),
    // percentage of the users commitment to the project
    percentage: (0, _attr.default)('number'),

    /**
     * ModelFragments don't support relationships,
     * @see https://github.com/lytics/ember-data.model-fragments/issues/18
     */
    user_id: (0, _attr.default)('string'),
    user: Ember.computed('user_id', {
      get: function get() {
        return this.store.peekRecord('user', this.get('user_id'));
      }
    }),
    visible: (0, _attr.default)('boolean')
  });

  var _default = AnalyticsProjectUser;
  _exports.default = _default;
});