define("coffeecup/utils/attr-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = attrSafe;

  /**
   * Takes a given name and ensures it is safe for HTML attributes, i.e. lower cases and separated with '-'.
   */
  function attrSafe(name) {
    return name && name.toLowerCase ? name.toLowerCase().replace(/ /g, '-') : name;
  }
});