define("coffeecup/routes/projects/analytics", ["exports", "coffeecup/routes/_super-route", "coffeecup/serializers/analytics/project", "coffeecup/utils/build-url"], function (_exports, _superRoute, _project, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _superRoute.default.extend({
    session: Ember.inject.service(),
    projectService: Ember.inject.service('project'),
    currentUserService: Ember.inject.service('currentUser'),
    notifications: Ember.inject.service(),
    setupController: function setupController(controller, context) {
      var _this = this;

      var project = context.project;
      controller.set('isLoaded', false);
      var projectInvoices = this.store.query('invoice', {
        where: {
          project: project.id
        },
        sort: 'invoicedAt ASC'
      });
      var interval = 'month';
      var histogramType = 'total';
      controller.set('project', project);
      controller.set('projectInvoices', projectInvoices);
      this.get('session').getJSON((0, _buildUrl.default)(this.store, 'analytics/projects', {
        project: project.id,
        histogramInterval: interval,
        histogramType: histogramType
      })).then(function (data) {
        var projectAnalyticsNormalized = (0, _project.normalizeObject)(_this.store, data.analyticsProject, 'analytics/project');

        var existingRecord = _this.store.peekRecord('analytics/project', "single-project-".concat(project.id));

        if (existingRecord) {
          existingRecord.unloadRecord();
        }

        var projectAnalytics = _this.store.createRecord('analytics/project', _objectSpread(_objectSpread({}, projectAnalyticsNormalized.attributes), {}, {
          id: "single-project-".concat(project.id),
          project: project
        }));

        controller.set('projectAnalyticsArray', Ember.A([projectAnalytics]));
        controller.set('isLoaded', true);
      });
    },
    model: function model(params, transition) {
      var projectId = params.project_id;
      var project = this.store.peekRecord('project', projectId);

      if (!projectId || !project || project.get('isDeleted')) {
        transition.abort();
        this.transitionTo('projects');
        console.error('Selected project is undefined', project, params);
      }

      var currentUser = this.get('currentUserService.currentUser');
      var isUserPmOfProject = this.get('projectService').userIsAdminOrPmOfProject(project, currentUser);

      if (!isUserPmOfProject) {
        this.get('notifications').error('notAllowToSeeProject');
        transition.abort();
        this.transitionTo('projects');
      }

      return {
        project: project
      }; // The old version of this route pre-loaded the objects here
      // The tradeoff is between a responsive UI, and content jumping in
      // later. Perhaps a spinner/throbber or another animation would make this
      // less jarring.
      //
      // return hash({
      //   selectedProject,
      //   selectedProjectAnalytics,
      //   projects,
      //   projectInvoices,
      // });
    }
  });

  _exports.default = _default;
});