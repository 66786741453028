define("coffeecup/templates/components/select-items/dashboard-milestones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uBPhTHZG",
    "block": "{\"symbols\":[\"@option\"],\"statements\":[[10,\"span\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"id\"]],null],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"milestonesForAllProjects\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1,[\"id\"]],\"internal\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"milestonesForAllInternalProjects\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"milestonesForX\"],[[\"label\"],[[32,1,[\"name\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"is-eq\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/select-items/dashboard-milestones.hbs"
    }
  });

  _exports.default = _default;
});