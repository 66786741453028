define("coffeecup/services/user", ["exports", "coffeecup/utils/validate-email", "coffeecup/config/environment"], function (_exports, _validateEmail, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WORK_HOURS_FIXED_DAY = _environment.default.constants.WORK_HOURS_FIXED_DAY;

  var _default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    clock: Ember.inject.service(),
    store: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    teamService: Ember.inject.service('team'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    createNewUser: function createNewUser() {
      var email = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      var firstname = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
      var lastname = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
      var role = arguments.length > 3 ? arguments[3] : undefined;
      var sendInvite = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
      var newUser = this.store.createRecord('user');
      var accountSettings = this.get('accountService.accountSettings');
      var defaultLanguage = accountSettings.get('defaultLanguage');

      if (defaultLanguage) {
        newUser.set('language', defaultLanguage);
      }

      var defaultTimezone = accountSettings.get('defaultTimezone');

      if (defaultTimezone) {
        newUser.set('timezone', defaultTimezone);
      }

      var defaultBeginningOfWeek = accountSettings.get('defaultBeginningOfWeek');

      if (defaultBeginningOfWeek) {
        newUser.set('beginningOfWeek', defaultBeginningOfWeek);
      }

      var defaultDateFormat = accountSettings.get('defaultDateFormat');

      if (defaultDateFormat) {
        newUser.set('dateFormat', defaultDateFormat);
      }

      var defaultTimeFormat = accountSettings.get('defaultTimeFormat');

      if (defaultTimeFormat) {
        newUser.set('timeFormat', defaultTimeFormat);
      }

      var defaultDurationFormat = accountSettings.get('defaultDurationFormat');

      if (defaultDurationFormat) {
        newUser.set('durationFormat', defaultDurationFormat);
      }

      var defaultProbationaryPeriodInMonths = accountSettings.get('defaultProbationaryPeriodInMonths');

      if (defaultProbationaryPeriodInMonths) {
        newUser.set('probationaryPeriod', moment().add(defaultProbationaryPeriodInMonths, 'months'));
      } // set role manually since ember-data does not support default values for belongsto relations


      var default_role = role !== null && role !== void 0 ? role : this.store.peekAll('role').findBy('baseType', 1);
      newUser.set('role', default_role);
      newUser.set('passwordStatus', sendInvite ? _environment.default.constants.PASSWORD_STATUS_NEEDS_SET : _environment.default.constants.PASSWORD_STATUS_NOT_INVITED); // optional email from function params

      if (email && typeof email.trim === 'function' && (0, _validateEmail.default)(email.trim())) {
        newUser.set('email', email.trim());
      } // optional firstname from function params


      if (firstname && typeof firstname.trim === 'function') {
        newUser.set('firstname', firstname.trim());
      } // optional lastname from function params


      if (lastname && typeof lastname.trim === 'function') {
        newUser.set('lastname', lastname.trim());
      }

      return newUser;
    },
    saveAndInviteUser: function saveAndInviteUser(user) {
      return user.save();
    },
    usersSortedAndGroupedByInactive: function usersSortedAndGroupedByInactive(users, currentUser) {
      var userSorted = users.sortBy('fullName');

      if (currentUser) {
        userSorted = userSorted.removeObject(currentUser);
        userSorted.unshiftObject(currentUser);
      }

      return this.usersGroupedByInactive(userSorted);
    },
    usersGroupedByInactive: function usersGroupedByInactive(users) {
      var activeUsers = users.filterBy('isActive');
      var inactiveUsers = users.filterBy('isInactive');

      if (inactiveUsers.get('length') > 0) {
        return [Ember.Object.create({
          groupName: this.get('i18n').t('activeLabel'),
          options: activeUsers
        }), Ember.Object.create({
          groupName: this.get('i18n').t('inactiveLabel'),
          options: inactiveUsers
        })];
      } else {
        return [Ember.Object.create({
          groupName: this.get('i18n').t('activeLabel'),
          options: activeUsers
        })];
      }
    }
  });

  _exports.default = _default;
});