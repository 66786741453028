define("coffeecup/templates/components/info-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vjX75UsU",
    "block": "{\"symbols\":[\"@icon\",\"@text\"],\"statements\":[[10,\"div\"],[14,0,\"info-box-primary\"],[12],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[\"info-box-icon \",[32,1]]]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"info-box-text\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,2]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/info-box.hbs"
    }
  });

  _exports.default = _default;
});