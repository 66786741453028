define("coffeecup/routes/planner/absences/scheduler/confirm", ["exports", "coffeecup/routes/planner/absences/calendar/confirm"], function (_exports, _confirm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _confirm.default.extend({
    controllerName: 'planner.absences.calendar.confirm',
    renderTemplate: function renderTemplate() {
      this.render('planner.absences.calendar.confirm');
    },
    actions: {
      transitionToList: function transitionToList() {
        this.transitionTo('planner.absences.scheduler');
      }
    }
  });

  _exports.default = _default;
});