define("coffeecup/models/role", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var Role = _superModel.default.extend({
    name: attr('string'),
    users: hasMany('user', {
      async: true
    }),
    baseType: attr('number'),
    canAccessPlanner: attr('boolean'),
    canManageAbsenceRequests: attr('boolean'),
    canValidateWorkTime: attr('boolean'),
    canManageOwnProjects: attr('boolean'),
    canCreateOwnProjects: attr('boolean'),
    canManageProjectRelations: attr('boolean'),
    canManageAllProjects: attr('boolean'),
    canAccessProjectCosts: attr('boolean'),
    canManagePlanner: attr('boolean'),
    canManageOwnPlanner: attr('boolean'),
    canAccessUsers: attr('boolean'),
    canAccessRemunerations: attr('boolean'),
    canAccessAdminRemunerations: attr('boolean'),
    canManageUsers: attr('boolean'),
    canAutocollectReferences: attr('boolean'),
    isRootAdmin: Ember.computed.equal('baseType', 2),
    isDefaultUser: Ember.computed.equal('baseType', 1),
    isImmutable: Ember.computed.or('isRootAdmin'),
    isNotDeletable: Ember.computed.or('isDefaultUser', 'isRootAdmin'),
    // computed properties
    label: Ember.computed('name', 'i18n.locale', {
      get: function get() {
        var name = this.get('name');

        if (name) {
          if (!this.get('isRootAdmin') && !this.get('isDefaultUser')) {
            return this.get('name');
          }

          name = this.get('i18n').t(name);

          if (typeof name.toString === 'function') {
            name = name.toString();
          }

          return name;
        }
      },
      set: function set(key, value) {
        if (this.get('isRootAdmin') || this.get('isDefaultUser')) {
          return this.get('i18n').t(this.get('name'));
        }

        this.set('name', value);
        return value;
      }
    }),
    order: Ember.computed('name', {
      get: function get() {
        if (this.get('isRootAdmin')) {
          return 0;
        } else if (this.get('isDefaultUser')) {
          return 1;
        } else {
          // adding 10 here to be sure that is the order is correct
          return this.get('idAsInt') + 10;
        }
      }
    })
  });

  var _default = Role;
  _exports.default = _default;
});