define("coffeecup/validations/time-entry", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      comment: {
        length: {
          maximum: 1000
        }
      }
    },
    // project: [validateRelation({presence: false, on: 'isBreak'})],
    // task: [validateRelation({presence: false, on: 'isBreak'})],
    // user: [validateRelation({presence: true})],
    // duration: [validateNumber({lte: 86400})], // Todo: Consider how to add correct duration validation on the client
    comment: [(0, _validators.validateLength)({
      max: 1000
    })]
  };
  _exports.default = _default;
});