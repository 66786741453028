define("coffeecup/templates/components/input/cc-date-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ftab/kom",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],null,[[\"content\"],[[35,7]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"has-feedback-small date-picker\"],[12],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"class\",\"type\",\"value\",\"disabled\",\"placeholder\",\"focus-out\",\"escape-press\",\"autocomplete\"],[[30,[36,5],[\"form-control \",[30,[36,4],[[32,0,[\"openPickerForDisabled\"]],\"clickable\"],null],[30,[36,4],[[32,0,[\"notEditable\"]],\" not-editable\"],null]],null],\"text\",[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"resetIfEmpty\"],null],[30,[36,0],[[32,0],\"cancel\"],null],\"off\"]]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"form-control-button\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icomoon-calendar\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"placeholder\",\"disabled\",\"rawDate\",\"if\",\"concat\",\"input\",\"translatedError\",\"tool-tipster\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/cc-date-field.hbs"
    }
  });

  _exports.default = _default;
});