define("coffeecup/models/client", ["exports", "ember-data", "coffeecup/config/environment", "coffeecup/models/-super-model", "coffeecup/mixins/create-short-tag", "coffeecup/mixins/image-preview", "coffeecup/mixins/cascade-archivation"], function (_exports, _emberData, _environment, _superModel, _createShortTag, _imagePreview, _cascadeArchivation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend(_createShortTag.default, _imagePreview.default, _cascadeArchivation.default, {
    changeTracker: {
      except: ['analytics']
    },
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    hourlyRate: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    imageType: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.IMG_TYPE_INITIALS
    }),
    status: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.STATUS_ENABLED
    }),
    createdAt: _emberData.default.attr('datestamp'),
    easybillId: _emberData.default.attr('number'),
    // relations
    contacts: _emberData.default.hasMany('contact', {
      async: true,
      cascadeArchivation: true
    }),
    projects: _emberData.default.hasMany('project', {
      async: true,
      cascadeArchivation: true
    }),
    externalReference: _emberData.default.belongsTo('client-external-integration', {
      async: false
    }),
    analytics: _emberData.default.belongsTo('analytics/client', {
      async: false
    }),

    /**
     * helper properties
     */
    imageDirectoryURL: _emberData.default.attr('string'),
    // computed properties
    fullName: Ember.computed('label', 'i18n.locale', function () {
      var label = this.get('label');

      if (!label) {
        if (Ember.isNone(this.get('id'))) {
          return '(' + this.get('i18n').t('newClient') + ')';
        } else {
          return this.get('i18n').t('placeholder.empty');
        }
      }

      return label;
    }),
    label: Ember.computed.alias('name'),
    uppercaseCode: Ember.computed('code', {
      get: function get() {
        var code = this.get('code');
        return code ? code.toUpperCase() : '';
      }
    }),
    fullNameAndCode: Ember.computed('code', 'fullName', {
      get: function get() {
        return "".concat(this.get('fullName'), " ").concat(this.get('code'), " ");
      }
    }),
    fullNameWithHiddenCodeAndClientHTML: Ember.computed.alias('fullNameAndCode'),
    activeProjects: Ember.computed('projects.@each.isActive', function () {
      return this.get('projects').filterBy('isActive', true);
    }),
    completedProjects: Ember.computed('projects.@each.isInactive', function () {
      return this.get('projects').filterBy('isInactive', true);
    }),
    firstContact: Ember.computed('contacts.firstObject', function () {
      return this.get('contacts.firstObject');
    }),
    isInactive: Ember.computed.not('isActive'),
    isArchived: Ember.computed.not('isActive'),
    isActive: Ember.computed('status', function () {
      return this.get('status') === _environment.default.constants.STATUS_ENABLED;
    }),
    image: Ember.computed('imageType', 'imageDirectoryURL', function () {
      return this.getClientImage('m');
    }),
    imageSmall: Ember.computed('imageType', 'imageDirectoryURL', function () {
      return this.getClientImage('s');
    }),
    imageLarge: Ember.computed('imageType', 'imageDirectoryURL', function () {
      return this.getClientImage('l');
    }),
    getClientImage: function getClientImage(size) {
      var id = this.get('imageDirectoryURL');
      return this._image(id, size);
    },
    isValid: Ember.computed('validators.@each.isValid', 'contacts.firstObject.validators.@each.isValid', function () {
      return this.get('contacts.firstObject.isValid') && this.get('validators').compact().filterBy('isValid', false).get('length') === 0;
    }),
    clone: function clone() {
      return this.getProperties(['name', 'website', 'currency', 'hourlyRate']);
    }
  });

  _exports.default = _default;
});