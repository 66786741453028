define("coffeecup/models/currency-format", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var CurrencyFormat = _emberData.default.Model.extend({
    // computed properties
    prefixTheSign: Ember.computed('id', {
      get: function get() {
        var id = this.get('id');
        return id.indexOf('$', 0) === 0;
      }
    }),
    label: ''
  });

  var _default = CurrencyFormat;
  _exports.default = _default;
});