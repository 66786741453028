define("coffeecup/mixins/calculate-absencedays", ["exports", "coffeecup/utils/build-url", "coffeecup/config/environment"], function (_exports, _buildUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    usedAbsenceDays: 0,
    availableAbsenceDays: 0,
    vacationBudget: 0,
    hasOverlappingAbsences: false,
    calculateUsedAbsenceDaysUrl: null,
    calculateUsedAbsenceDaysUrlHasChanged: Ember.observer('calculateUsedAbsenceDaysUrl', function () {
      var _this = this;

      var calculateUsedAbsenceDaysUrl = this.get('calculateUsedAbsenceDaysUrl');
      this.set('usedAbsencesDays', null);
      this.set('availableAbsenceDays', null);
      this.get('session').getJSON(calculateUsedAbsenceDaysUrl).then(function (data) {
        // available is then difference between budget and used
        var availableDays = data.vacationBudget;
        var usedAbsenceDays = data.usedAbsenceDays;

        if (_this.get('form.absenceType.defaultCountsAsVacation')) {
          availableDays -= usedAbsenceDays;
        }

        _this.set('usedAbsenceDays', usedAbsenceDays);

        _this.set('availableAbsenceDays', availableDays);

        _this.set('vacationBudget', data.vacationBudget);

        _this.set('hasOverlappingAbsences', data.hasOverlappingAbsences);
      }).catch(function (error) {
        console.log(error);
      });
    }),
    halfDayHasChanged: Ember.observer('form.halfDay', function () {
      var halfDay = this.get('form.halfDay');
      var startDate = moment(this.get('form.startDate'));

      if (halfDay && (!this.get('form.endDate') || !moment(this.get('form.endDate')).isSame(startDate, 'day'))) {
        this.set('form.endDate', startDate.clone());
      }
    }),
    _calculateUsedAbsenceDays: function _calculateUsedAbsenceDays() {
      var halfDay = this.get('form.halfDay');
      var absenceTypeId = this.get('form.absenceType.id');
      var startDate = moment(this.get('form.startDate.content') || this.get('form.startDate'));
      var endDate = moment(this.get('form.endDate.content') || this.get('form.endDate'));
      var absenceId = this.get('form.id') && this.get('model.constructor.modelName') === 'absence' ? this.get('form.id') : -1;

      if (halfDay) {
        endDate = startDate.clone();
      }

      if (startDate.isSameOrBefore(endDate)) {
        var params = {
          user: this.get('form.user.id') ? this.get('form.user.id') : this.get('form.sender.id'),
          startDate: startDate.format(_environment.default.constants.DATE_FORMAT_DAY),
          endDate: endDate.format(_environment.default.constants.DATE_FORMAT_DAY),
          rangeType: this.get('form.rangeType'),
          excludeAbsence: absenceId,
          absenceTypeId: absenceTypeId
        };
        var url = (0, _buildUrl.default)(this.store, 'absences/numberOfUsedUserWorkingDaysForDateRange', params);
        this.set('calculateUsedAbsenceDaysUrl', url);
      }
    },
    calculateUsedAbsenceDays: Ember.observer('form.startDate', 'form.absenceType.id', 'form.user.id', 'form.sender.id', 'form.endDate', 'form.halfDay', function () {
      if (this.get('form.user.id') || this.get('form.sender.id')) {
        Ember.run.once(this, '_calculateUsedAbsenceDays');
      }
    }).on('init')
  });

  _exports.default = _default;
});