define("coffeecup/sentry", ["exports", "@sentry/browser", "@sentry/integrations", "coffeecup/config/environment"], function (_exports, Sentry, Integrations, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startSentry = startSentry;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function startSentry() {
    if (['production', 'staging', 'beta'].indexOf(_environment.default.environment) !== -1) {
      Sentry.init(_objectSpread(_objectSpread({}, _environment.default.APP.sentry), {}, {
        release: _environment.default.APP.version,
        integrations: [new Integrations.Ember()],
        beforeSend: function beforeSend(event, hint) {
          var error = hint.originalException; // ignore aborted route transitions from the Ember.js router

          if (error && error.name && error.name === 'TransitionAborted') {
            return null;
          } // don't send info alerts to save number of alerts


          if (event.level === 'info') {
            return null;
          }

          if (error && error.message && error.message.indexOf('Ember Data Request GET') !== -1 && error.message.indexOf('returned a 40') !== -1) {
            return null;
          }

          if (error && error.message && error.message.indexOf('Ember Data Request GET') !== -1 && error.message.indexOf('returned a 50') !== -1) {
            return null;
          }

          if (error && error.message && error.message === 'The adapter operation was aborted') {
            return null;
          }

          return event;
        }
      }));
    }
  }
});