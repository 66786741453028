define("coffeecup/templates/components/pagination-limiter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lnikUbCH",
    "block": "{\"symbols\":[\"dropdown\",\"limitOption\"],\"statements\":[[11,\"span\"],[24,0,\"dropdown\"],[4,[38,0],[[32,0],\"empty\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n  \"],[8,\"basic-dropdown\",[],[[\"@class\"],[\"dropdown\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"trigger\"]],[],[[\"@class\"],[\"btn-group\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"btn btn-default has-border\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"has-icon-right divider-right\"],[12],[1,[34,1]],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"btn btn-default has-border\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icomoon-arrow-up small\"],[12],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[11,\"ul\"],[24,0,\"dropdown-menu dropdown-menu-narrow\"],[4,[38,0],[[32,0],[32,1,[\"actions\",\"close\"]]],null],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,2],null,[[\"label\",\"value\",\"current\",\"action\"],[[32,2],[32,2],[35,1],[30,[36,0],[[32,0],\"setLimitOption\"],null]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"limit\",\"dropdown-option\",\"limitOptions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/pagination-limiter.hbs"
    }
  });

  _exports.default = _default;
});