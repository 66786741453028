define("coffeecup/templates/components/dashboard/effort-costs/legend-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ifuDMaVj",
    "block": "{\"symbols\":[\"@value\",\"&attrs\",\"@label\",\"@currencySymbol\"],\"statements\":[[11,\"div\"],[24,0,\"chart-legend-item\"],[17,2],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"text-overflow\"],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"b\"],[12],[2,\"\\n    \"],[1,[32,4]],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isLargeNumber\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,1],\"0a\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,0,[\"formattedValue\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"number-fmt\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/effort-costs/legend-item.hbs"
    }
  });

  _exports.default = _default;
});