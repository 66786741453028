define("coffeecup/components/modals/complete-project", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formatter: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    projectService: Ember.inject.service('project'),
    projectType: 'timesAndMaterial',
    isCompleteProject: true,
    projectInvoices: [],
    allExpenses: [],
    useAlertColor: false,
    leftOverLabel: '',
    budget: 0,
    amountTotal: 0,
    totalBilled: 0,
    amountLeftOver: 0,
    percentageBilled: 0,
    hoursBudget: 0,
    pieChartData: [],
    isProjectBillable: Ember.computed.or('selectedProject.isModeFixedBudget', 'selectedProject.isModeByEffort'),
    hasBudget: Ember.computed.or('selectedProjectAnalytics.hoursBudget', 'selectedProjectAnalytics.amountBudget'),
    hasHourBudget: Ember.computed.reads('selectedProjectAnalytics.hoursBudget'),
    kpiRows: [],
    didInsertElement: function didInsertElement() {
      this._loadExpenses();
    },
    _loadExpenses: function _loadExpenses() {
      var _this = this;

      if (this.get('selectedProject.id')) {
        this.get('store').query('expense', {
          where: {
            project: this.get('selectedProject.id')
          }
        }).then(function (expenses) {
          _this.set('allExpenses', expenses);

          _this.set('totalExpenses', expenses.reduce(function (prev, el) {
            return prev + el.get('priceExternal');
          }, 0));

          _this.set('totalBilledExpenses', expenses.filter(function (el) {
            return el.get('billedAt');
          }).reduce(function (prev, el) {
            return prev + el.get('priceExternal');
          }, 0));

          _this._calculateProjectData();
        }).catch(function (error) {
          console.error(error);
        });
      }
    },
    _calculateProjectData: Ember.observer('selectedProject', function () {
      if (this.get('selectedProject.isModeByEffort')) {
        this._prepareTimeMaterialKpis();
      } else if (this.get('selectedProject.isModeFixedBudget')) {
        this._prepareFixedBudgetKpis();
      } else {
        this._prepareNonBillableKpis();
      }
    }),
    _prepareFixedBudgetKpis: function _prepareFixedBudgetKpis() {
      var _this2 = this;

      var invoices = this.get('store').peekAll('invoice').filter(function (el) {
        return el.get('project.id') === _this2.get('selectedProject.id');
      });
      var totalSpent = this.get('selectedProjectAnalytics.amountSpentTotal');
      var totalBudget = this.get('selectedProjectAnalytics.amountBudgetTotal');
      var totalBilled = invoices.reduce(function (prev, el) {
        return prev + el.get('amount');
      }, 0);
      var percentageBilled = totalBudget > 0 ? totalBilled / totalBudget : 1;
      this.set('budget', totalBudget);
      this.set('totalBilled', totalBilled);
      this.set('amountTotal', totalSpent);
      this.set('useAlertColor', percentageBilled > 1);
      this.set('leftOverLabel', percentageBilled > 1 ? 'overCharged' : 'billable');
      this.set('amountLeftOver', Math.abs(totalBudget - totalBilled));
      this.set('kpiRows', this._getFixedKpis('budget', 'budget'));

      this._setChartAttributes(percentageBilled);
    },
    _prepareTimeMaterialKpis: function _prepareTimeMaterialKpis() {
      var allExpenses = this.get('allExpenses');
      var totalExpenses = allExpenses.reduce(function (prev, el) {
        return prev + el.get('priceExternal');
      }, 0);
      var totalSpent = this.get('selectedProjectAnalytics.amountSpentTotal');
      var amountBilledTotal = this.get('selectedProjectAnalytics.amountBilledTotal');
      this.set('totalBilled', amountBilledTotal);
      this.set('amountTotal', totalSpent);
      var percentageBilled = amountBilledTotal / totalSpent;
      this.set('useAlertColor', percentageBilled > 1);
      this.set('amountLeftOver', Math.abs(totalSpent - amountBilledTotal));
      this.set('leftOverLabel', 'billable');
      this.set('kpiRows', this._getFixedKpis('amountTotal', 'effort'));

      this._setChartAttributes(percentageBilled);
    },
    _prepareNonBillableKpis: function _prepareNonBillableKpis() {
      var hoursBudget = this.get('selectedProjectAnalytics.hoursBudget');
      var hoursTotal = this.get('selectedProjectAnalytics.hoursTotal');
      var percentageBilled = hoursTotal / hoursBudget;
      this.set('hoursTotal', hoursTotal);
      this.set('hoursBudget', hoursBudget);
      this.set('leftOverLabel', percentageBilled > 1 ? 'overCharged' : 'billable');
      this.set('useAlertColor', percentageBilled > 1);
      this.set('amountLeftOver', hoursBudget - hoursTotal);
      this.set('kpiRows', this._getHoursKpis());

      this._setChartAttributes(percentageBilled);
    },
    _setChartAttributes: function _setChartAttributes(percentageBilled) {
      if (percentageBilled > 1) {
        this.set('pieChartData', [{
          value: percentageBilled - 1
        }, {
          value: 2 - percentageBilled
        }]);
      } else {
        this.set('pieChartData', [{
          value: percentageBilled
        }, {
          value: 1 - percentageBilled
        }]);
      }

      this.set('percentageBilled', percentageBilled > 1 ? percentageBilled - 1 : 1 - percentageBilled);
    },
    _getFixedKpis: function _getFixedKpis(value, amountLabel) {
      var formatter = this.get('formatter');
      var i18n = this.get('i18n');
      var totalAmount = this.get(value);
      var totalBilled = this.get('totalBilled');
      var amountLeftOver = this.get('amountLeftOver');
      var formattedTotalAmount = formatter.formatCurrency(totalAmount, true);
      var formattedBilled = formatter.formatCurrency(totalBilled, true);
      var formattedLeftOver = formatter.formatCurrency(amountLeftOver, true);
      return [{
        kpis: [{
          title: i18n.t(amountLabel),
          value: formattedTotalAmount,
          hasDivider: true
        }, {
          title: i18n.t('billed'),
          value: formattedBilled,
          hasDivider: true
        }, {
          title: i18n.t(this.get('leftOverLabel')),
          value: formattedLeftOver,
          useAlertColor: this.get('useAlertColor'),
          customActionLabel: totalAmount > totalBilled ? i18n.t('invoiceNow') : null
        }]
      }];
    },
    _getHoursKpis: function _getHoursKpis() {
      var i18n = this.get('i18n');
      var formatter = this.get('formatter');
      var hoursTotal = this.get('hoursTotal');
      var hoursBudget = this.get('hoursBudget');
      var baseKpis = [{
        title: i18n.t('total'),
        value: formatter.formatDuration(hoursTotal)
      }];

      if (this.get('hasHourBudget')) {
        baseKpis.unshift({
          title: i18n.t('allocation'),
          value: formatter.formatDuration(hoursBudget)
        });
      }

      return [{
        kpis: baseKpis
      }];
    },
    actions: {
      onHide: function onHide() {
        this.sendAction('onHide');
      },
      billProject: function billProject() {
        this.sendAction('onHide');
        this.sendAction('billProject');
      },
      completeProject: function completeProject(archive) {
        var _this3 = this;

        var project = this.get('selectedProject');

        if (archive) {
          project.set('status', _environment.default.constants.STATUS_ARCHIVED);
        }

        var projectState = this.get('selectedProjectState');

        if (!projectState) {
          // Grab the first completed state if it wasn't passed as an argument
          var completedStates = this.get('projectStates').filterBy('isComplete');
          projectState = completedStates.firstObject;
        }

        project.set('projectState', projectState);
        project.set('completedAt', this.get('clock.now'));
        project.save().then(function () {
          _this3.get('notifications').success('alert.completed', 'project');

          _this3.send('onHide');
        }).catch(function (err) {
          project.set('completedAt', null);

          _this3.get('notifications').errorWithObject(err, 'alert.errorOnSave', 'project');
        });
      }
    }
  });

  _exports.default = _default;
});