define("coffeecup/routes/analytics/users", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    setupController: function setupController(controller, context, transition) {
      var users = this.store.peekAll('user');
      controller.set('users', users);
      if (transition.targetName !== 'analytics.users.details') controller.changeToUser();
    },
    titleToken: function titleToken() {
      return this.get('i18n').t('team');
    },
    model: function model() {
      return this.store.peekAll('user');
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'analytics.users.index') {
          transition.abort();
        }
      },
      activate: function activate(model) {
        if (model.get('id') === this.get('currentUserService').getCurrentUserId()) {
          this.get('notifications').warning('alert.notPossible');
          return false;
        }

        this._super.apply(this, arguments);
      },
      delete: function _delete(model) {
        if (model.get('id') === this.get('currentUserService').getCurrentUserId()) {
          this.get('notifications').warning('alert.notPossible');
          return false;
        }

        this._super.apply(this, arguments);
      },
      archive: function archive(model) {
        if (model.get('id') === this.get('currentUserService').getCurrentUserId()) {
          this.get('notifications').warning('alert.notPossible');
          return false;
        }

        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});