define("coffeecup/templates/components/absence-tracking/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gokD7aZO",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"absence-calendar-container \",[30,[36,1],[[35,0],\"readonly\"],null]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"readOnly\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/absence-tracking/calendar.hbs"
    }
  });

  _exports.default = _default;
});