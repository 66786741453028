define("coffeecup/templates/components/manage-table/cells/user-billable-worktime-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cSavT+R4",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"manage-table-cell-contents\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"billableWorktimePercetange\"]],\"0a\"],null]],[2,\"%\\n  \"],[13],[2,\"\\n\\n  \"],[8,\"cc-ember-tooltip\",[],[[\"@popperContainer\"],[\"main\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"billableHours\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[[30,[36,2],[[32,0,[\"isStaff\"]],[32,1,[\"analytics\",\"totalBillableHours\"]],[32,0,[\"totalBillableHoursForTeam\"]]],null],false,true],null]],[2,\"\\n          h\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[\"nonBillableHours\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[[30,[36,2],[[32,0,[\"isStaff\"]],[32,1,[\"analytics\",\"nonBillableHours\"]],[32,0,[\"totalNonBillableHoursForTeam\"]]],null],false,true],null]],[2,\"\\n          h\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"number-fmt\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/user-billable-worktime-percentage.hbs"
    }
  });

  _exports.default = _default;
});