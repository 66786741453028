define("coffeecup/templates/components/planner/project-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eMa5+lrZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row-summary\"],[12],[2,\"\\n\"],[2,\"  \"],[8,\"tool-tipster\",[],[[\"@class\",\"@content\",\"@contentAsHTML\"],[\"summary-inner\",[32,0,[\"tooltip\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"    \"],[10,\"div\"],[14,0,\"planned-hours blue\"],[12],[2,\"\\n      \"],[1,[32,0,[\"roundedHoursInScope\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"showRemainingHours\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[30,[36,1],[\"remaining-hours \",[30,[36,0],[[32,0,[\"remainingIsNegative\"]],\"negative\"],null]],null]],[12],[2,\"\\n        \"],[1,[32,0,[\"roundedRemainingHours\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/project-summary.hbs"
    }
  });

  _exports.default = _default;
});