define("coffeecup/routes/projects/edit", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    projectService: Ember.inject.service('project'),
    currentUserService: Ember.inject.service('currentUser'),
    permissions: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return model.get('label');
    },
    listController: 'projects',
    setupController: function setupController(controller, context, transition) {
      this._super(controller, context);

      var projectId = context.get('id');
      var analytics = this.store.query('analytics/project', {
        project: projectId
      });
      var expenses = this.store.query('expense', {
        where: {
          project: projectId
        }
      }).then(function (expenses) {
        controller.set('expenses', expenses.toArray());
      }); // Necessary to fetch new userAssignments into the store when creating a new project

      this.store.query('user-assignment', {
        where: {
          project: projectId
        }
      });
      controller.set('colors', this.store.peekAll('color')); // loaded into store in routes/_super-route.js

      controller.set('pageLoadedAt', this.get('clock.now'));
      var tagAssignments = this.store.peekAll('tag-assignment').filter(function (el) {
        return el.get('model') === 'project' && el.get('record') === context.get('idAsInt');
      });
      var tags = tagAssignments.getEach('tag');
      controller.set('selectedTags', tags);
      controller.set('existingTags', this.store.peekAll('tag'));
      Ember.run.next(function () {
        if (transition.queryParams && transition.queryParams.isNewProject) {
          controller.set('internalProject', false);
        }
      });
    },
    model: function model(params, transition) {
      var projectId = params.project_id;
      var project = this.store.peekRecord('project', projectId);

      if (!projectId || !project || project.get('isDeleted')) {
        transition.abort();
        this.transitionTo('index');
      }

      var currentUser = this.get('currentUserService.currentUser');
      var isUserPmOfProject = this.get('projectService').userIsAdminOrPmOfProject(project, currentUser);

      if (!isUserPmOfProject) {
        this.get('notifications').error('notAllowToSeeProject');
        transition.abort();
        this.transitionTo('index');
      }

      return project;
    },
    actions: {
      activate: function activate(project) {
        var _this = this;

        this.get('projectService').uncompleteProject(project.get('id')).then(function () {
          _this.get('notifications').success('alert.activated', 'project');
        }).catch(function (error) {
          _this.get('notifications').errorWithObject(error, 'alert.errorOnSave', 'project');
        });
      }
    }
  });

  _exports.default = _default;
});