define("coffeecup/routes/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LoginRoute = Ember.Route.extend({
    analytics: Ember.inject.service(),
    session: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this.get('analytics').start();
    },
    renderTemplate: function renderTemplate() {
      this.render('start.login', {
        outlet: 'formbox'
      });
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      var isAuthenticated = this.get('session.isAuthenticated');

      if (isAuthenticated) {
        this.transitionTo('index');
      }
    }
  });
  var _default = LoginRoute;
  _exports.default = _default;
});