define("coffeecup/templates/modal/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G82wDAEQ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"onClose\",\"targetAttachment\",\"translucentOverlay\"],[[30,[36,2],[[32,0],\"onHide\"],null],\"center\",true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"modal-wrapper modal-confirm \",[34,0]]]],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[[34,1,[\"icon\"]],\" modal-icon\"]]],[12],[13],[2,\"\\n        \"],[10,\"h1\"],[12],[1,[35,1,[\"title\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n        \"],[1,[35,1,[\"text\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,2],[[32,0],\"onHide\"],null],[12],[2,\"OK\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"modalName\",\"model\",\"action\",\"modal-dialog\",\"showModal\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/modal/alert.hbs"
    }
  });

  _exports.default = _default;
});