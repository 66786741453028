define("coffeecup/templates/projects/tasks/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HonA/zJB",
    "block": "{\"symbols\":[],\"statements\":[[8,\"manage/list-header\",[],[[\"@modelName\",\"@headerLabel\",\"@showArchived\",\"@toggleArchived\",\"@searchPath\",\"@itemsGrouped\",\"@canCreateEntity\",\"@placeholder\",\"@closeAction\",\"@selectAction\"],[\"task\",\"tasks\",[32,0,[\"showArchived\"]],[32,0,[\"toggleArchived\"]],\"fullNameWithHiddenCodeHTML\",[32,0,[\"tasksSorted\"]],[32,0,[\"canCreateTask\"]],[30,[36,0],[\"chooseTask\"],null],[32,0,[\"closePane\"]],[32,0,[\"selectItem\"]]]],null],[2,\"\\n\"],[10,\"section\"],[14,0,\"list-table\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"manage-table/manage-table\",[],[[\"@config\",\"@rows\"],[[32,0,[\"tableConfig\"]],[32,0,[\"tableRows\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"empty-list-placeholder\",[],[[\"@header\",\"@icon\",\"@buttonLabel\",\"@linkTo\"],[\"noTasksHeader\",\"square-check\",\"noTasksButton\",\"projects.tasks.new\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/projects/tasks/index.hbs"
    }
  });

  _exports.default = _default;
});