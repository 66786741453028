define("coffeecup/mixins/download-attachment", ["exports", "coffeecup/utils/build-url", "coffeecup/utils/open"], function (_exports, _buildUrl, _open) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    actions: {
      downloadAttachment: function downloadAttachment(attachmentId) {
        if (attachmentId) {
          (0, _open.default)(this.get('session').postJSON((0, _buildUrl.default)(this.get('store'), "files/getURL/".concat(attachmentId))));
        }
      }
    }
  });

  _exports.default = _default;
});