define("coffeecup/validations/expense", ["exports", "ember-changeset-validations/validators", "coffeecup/validators/validateRelation"], function (_exports, _validators, _validateRelation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      day: {
        presence: true
      },
      description: {
        length: {
          maximum: 1000
        }
      }
    },
    day: [(0, _validators.validatePresence)(true)],
    description: [(0, _validators.validateLength)({
      max: 1000
    })],
    expenseCategory: [(0, _validateRelation.default)({
      presence: true
    })]
  };
  _exports.default = _default;
});