define("coffeecup/templates/components/dashboard-module/summary-bar/presentation-mode-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Te2xmm1y",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"content\"],[[30,[36,1],[[35,0,[\"tooltip\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[\"icomoon-\",[34,0,[\"icon\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"presentationMode\",\"t\",\"tool-tipster\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard-module/summary-bar/presentation-mode-button.hbs"
    }
  });

  _exports.default = _default;
});