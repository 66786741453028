define("coffeecup/templates/components/multipath-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1VIgKwYB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"path1\"],[12],[13],[10,\"span\"],[14,0,\"path2\"],[12],[13],[10,\"span\"],[14,0,\"path3\"],[12],[13],[10,\"span\"],[14,0,\"path4\"],[12],[13],[10,\"span\"],[14,0,\"path5\"],[12],[13],[10,\"span\"],[14,0,\"path6\"],[12],[13],[10,\"span\"],[14,0,\"path7\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/multipath-icon.hbs"
    }
  });

  _exports.default = _default;
});