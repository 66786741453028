define("coffeecup/services/planner/zoom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUserService: Ember.inject.service('currentUser'),
    level: Ember.computed('currentUserService.currentUser.uiState.plannerZoomLevel', {
      get: function get() {
        return this.get('currentUserService.currentUser.uiState.plannerZoomLevel');
      },
      set: function set(k, v) {
        this.get('currentUserService').saveUISettings({
          plannerZoomLevel: v
        });
        return v;
      }
    }),
    setLevel: function setLevel(lvl) {
      var t = this.get('level');

      if (lvl !== t) {
        switch (lvl) {
          case 1:
          case 2:
            this.set('level', lvl);
        }
      }
    },
    in: function _in() {
      this.setLevel(1);
    },
    out: function out() {
      this.setLevel(2);
    },
    zoomedOut: Ember.computed('level', {
      get: function get() {
        return 2 === this.get('level');
      },
      set: function set(e, t) {
        this[t ? 'out' : 'in']();
        return !!t;
      }
    }),
    zoomedIn: Ember.computed.not('zoomedOut'),
    zoomClass: Ember.computed('level', function () {
      return 'zoom-' + this.get('level') + 'x';
    })
  });

  _exports.default = _default;
});