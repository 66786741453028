define("coffeecup/templates/components/validation/value-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V66dUJiD",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"d-flex align-items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"validation-check-icon flex-center\\n      \",[30,[36,2],[[35,3],\"small\"],null],\"\\n      \",[30,[36,2],[[35,1],\"icomoon-checkmark success\",\"icomoon-cross-exit\"],null]]]],[15,\"title\",[34,0]],[12],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"validation-check-label text-overflow\"],[15,\"title\",[31,[[34,0]]]],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"label\",\"isValid\",\"if\",\"smallIcon\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/validation/value-check.hbs"
    }
  });

  _exports.default = _default;
});