define("coffeecup/templates/components/time-entry-quality", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0A643dUa",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[6,[37,1],[[32,0,[\"showTimeEntryQuality\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"tool-tipster\",[[24,0,\"time-entry-accuracy\"],[17,1]],[[\"@maxWidth\",\"@content\",\"@contentAsHTML\"],[250,[30,[36,0],[[32,0,[\"accuracyTooltip\"]]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"parsedAccuracy\"]]]]],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/time-entry-quality.hbs"
    }
  });

  _exports.default = _default;
});