define("coffeecup/routes/analytics/clients/details", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    session: Ember.inject.service(),
    clientService: Ember.inject.service('client'),
    setupController: function setupController(controller, context) {
      if (context && context.selectedClient && context.selectedClient.id) {
        controller.set('selectedClient', context.selectedClient);
        controller.set('selectedClientAnalytics', context.selectedClientAnalytics.get('firstObject'));
        controller.set('clients', context.clients ? context.clients.toArray() : []);
      }
    },
    model: function model(params, transition) {
      var clientId = params.client_id;
      var selectedClient = clientId && this.store.peekRecord('client', clientId);

      if (!selectedClient || selectedClient.get('isDeleted')) {
        transition.abort();
        var defaultClient = this.get('clientService').getFirstClientByLabel();
        this.transitionTo('analytics.clients.details', defaultClient.get('id'));
      }

      this.controllerFor('analytics.clients').set('selectedClient', selectedClient);
      var selectedClientAnalytics = this.store.query('analytics/client', {
        client: clientId,
        histogramInterval: 'month',
        histogramType: 'total'
      });
      var clients = this.store.peekAll('client');
      return Ember.RSVP.hash({
        selectedClient: selectedClient,
        selectedClientAnalytics: selectedClientAnalytics,
        clients: clients
      });
    }
  });

  _exports.default = _default;
});