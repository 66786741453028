define("coffeecup/serializers/analytics/time-entry", ["exports", "coffeecup/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import DS from "ember-data";
  var _default = _application.default.extend({
    _modelName: 'analytics/time-entry',
    _typeKey: 'analyticsTimeEntries',

    /**
     * Query internally just uses findRecord, because there is only ever a single analytics instance which refers to all
     * entries over the search period.
     *
     * @param store
     * @param primaryModelClass
     * @param payload
     * @param id
     * @param requestType
     * @returns {{data: *[]}}
     */
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      var ret = this.normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType);
      return {
        data: [ret.data]
      };
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload || !payload[this._typeKey]) {
        return {
          data: []
        };
      }

      var ret = normalizeObject(this._modelName, payload[this._typeKey] || payload);
      return {
        data: ret
      };
    },
    normalizeFindAllResponse: function normalizeFindAllResponse()
    /*store, primaryModelClass, payload, id, requestType*/
    {
      throw new Error("".concat([this], " has no extractFindAll method"));
    }
  });

  _exports.default = _default;

  function normalizeTEAttributes(obj) {
    return {
      // Non-rounded hours
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      // Hours based on rounded hours (see project settings)
      hoursSpent: Ember.get(obj, 'hoursRounded.spent') || 0,
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || 0,
      hoursBudget: Ember.get(obj, 'hoursRounded.budget') || 0,
      hoursOutOfBudget: Ember.get(obj, 'hoursRounded.outOfBudget') || 0,
      hoursBilled: Ember.get(obj, 'hoursRounded.billed') || 0,
      // Amounts are always rounded based on project settings
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      amountBudget: Ember.get(obj, 'amount.budget') || 0,
      amountOutOfBudget: Ember.get(obj, 'amount.outOfBudget') || 0,
      amountBilled: Ember.get(obj, 'amount.billed') || 0
    };
  }

  function normalizeGroupedBy(grouped) {
    return Ember.Object.extend({
      hoursSpent: Ember.get(grouped, 'hoursRoundedSpent'),
      hoursTotal: Ember.get(grouped, 'hoursTotal'),
      amountSpent: Ember.get(grouped, 'amountSpent'),
      // There are more properties in the API response, but we only need those 3 ^
      day: Ember.get(grouped, 'day'),
      client: Ember.get(grouped, 'client'),
      project: Ember.get(grouped, 'project'),
      user: Ember.get(grouped, 'user'),
      team: Ember.get(grouped, 'team'),
      task: Ember.get(grouped, 'task'),
      timeEntryReference: Ember.get(grouped, 'timeEntryReference')
    }).create();
  }

  function normalizeObject(modelName, obj) {
    var normalized = {
      id: Ember.get(obj, 'user') || new Date().getTime(),
      // ember-data needs an id, just any for that matter
      type: modelName,
      attributes: normalizeTEAttributes(obj)
    };

    if (Ember.get(obj, 'groupedBy')) {
      normalized.attributes.groupedBy = Ember.get(obj, 'groupedBy').map(function (grouped) {
        return normalizeGroupedBy(grouped);
      });
    }

    return normalized;
  }
});