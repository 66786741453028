define("coffeecup/templates/components/server-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AZwN2xas",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"alert alert-\",[34,2]]]],[14,\"role\",\"alert\"],[12],[2,\"\\n  \"],[1,[34,3]],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[4,[38,0],[[32,0],\"reloadPage\"],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"reloadNow\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"overlay \",[30,[36,7],[[35,6],\"hidden\"],null]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"type\",\"message\",\"reload\",\"if\",\"blocks\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/server-alert.hbs"
    }
  });

  _exports.default = _default;
});