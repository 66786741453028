define("coffeecup/services/planner/active-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    plannerAssignment: null,
    milestone: null,
    setPlannerAssignment: function setPlannerAssignment(plannerAssignmentId) {
      this.set('milestone', null);
      this.set('plannerAssignment', plannerAssignmentId);
    },
    setMilestone: function setMilestone(milestoneId) {
      this.set('plannerAssignment', null);
      this.set('milestone', milestoneId);
    }
  });

  _exports.default = _default;
});