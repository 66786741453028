define("coffeecup/services/clock", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service synchronizes time across (most parts of) the app.
   * Time is updated at most once in a browser frame and should be static for the
   * entirety of an Ember runloop.
   * It exports computed properties that can be observed to react to conditions like
   * change of date. Getters of the public properties always return new moment
   * instances so they can be safely mutated immediately.
   * */
  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    tickFrequency: 1,
    syncFrequency: 3600,
    _serverOffset: 0,
    _nowCache: null,

    get now() {
      return this.get('_nowCache').clone();
    },

    _secondCache: null,

    get second() {
      return this.get('_secondCache').clone();
    },

    _dayCache: null,

    get day() {
      return this.get('_dayCache').clone();
    },

    _weekCache: null,

    get week() {
      return this.get('_weekCache').clone();
    },

    _monthCache: null,

    get month() {
      return this.get('_monthCache').clone();
    },

    _quarterCache: null,

    get quarter() {
      return this.get('_quarterCache').clone();
    },

    _yearCache: null,

    get year() {
      return this.get('_yearCache').clone();
    },

    _localDayAsUtcMomentCache: Ember.computed('_dayCache', function () {
      var localDay = this.get('_dayCache');
      return moment.utc([localDay.year(), localDay.month(), localDay.date()]);
    }).readOnly(),

    get localDayAsUtcMoment() {
      return this.get('_localDayAsUtcMomentCache').clone();
    },

    _updateMomentCaches: function () {
      var timeSeed = this.get('_systemTimestamp') + this.get('_serverOffset');

      if (arguments.length > 0) {
        // allow time to be set externally for debugging
        timeSeed = arguments[0];
      }

      var now = moment(timeSeed);
      this.set('_nowCache', now);
      this.notifyPropertyChange('now');
      var second = now.clone().startOf('second');

      if (!second.isSame(this.get('_secondCache'))) {
        this.set('_secondCache', second);
        this.notifyPropertyChange('second');
        var day = now.clone().startOf('day');

        if (!day.isSame(this.get('_dayCache'))) {
          this.set('_dayCache', day);
          this.notifyPropertyChange('day');
          this.notifyPropertyChange('localDayAsUtcMoment');
          var week = now.clone().startOf('week');

          if (!week.isSame(this.get('_weekCache'))) {
            this.set('_weekCache', week);
            this.notifyPropertyChange('week');
          }

          var month = now.clone().startOf('month');

          if (!month.isSame(this.get('_monthCache'))) {
            this.set('_monthCache', month);
            this.notifyPropertyChange('month');
            var quarter = now.clone().startOf('quarter');

            if (!month.isSame(this.get('_quarterCache'))) {
              this.set('_quarterCache', quarter);
              this.notifyPropertyChange('quarter');
              var year = now.clone().startOf('year');

              if (!year.isSame(this.get('_yearCache'))) {
                this.set('_yearCache', year);
                this.notifyPropertyChange('year');
              }
            }
          }
        }
      }
    }.on('init'),
    _scheduleTick: function () {
      var _this = this;

      var frequency = Ember.get(this, 'tickFrequency');

      if (this.get('isDestroying') || this.get('isDestroyed') || this.get('_timeSeeded')) {
        return;
      }

      window.setTimeout(function () {
        if (!_this.get('isDestroyed')) {
          _this._updateMomentCaches();

          _this._scheduleTick();
        }
      }, frequency * 1000);
    }.on('init'),
    _syncWithServer: function () {
      var _this2 = this;

      if (this.get('isDestroying') || this.get('isDestroyed') || this.get('_timeSeeded')) {
        return;
      }

      if (_environment.default.environment !== 'test') {
        this.get('session').getJSON("".concat(_environment.default.apiHost, "/v1/clock/utc")).then(function (response) {
          var serverTS = response.ts;

          var systemTS = _this2.get('_systemTimestamp');

          _this2.set('_serverOffset', serverTS - systemTS);

          _this2._scheduleSync();
        }, function () {
          return _this2._scheduleSync();
        });
      }
    }.on('init'),
    _scheduleSync: function _scheduleSync() {
      var frequency = Ember.get(this, 'syncFrequency');
      Ember.run.later(this, '_syncWithServer', frequency * 1000);
    },

    get _systemTimestamp() {
      return new Date().getTime();
    },

    _updateTimezone: Ember.observer('currentUser.currentUser.timezone', function () {
      if (moment.tz.setDefault._lock !== this) {
        return;
      }

      var userZone = this.get('currentUser.currentUser.timezone');

      if (moment.tz.zone(userZone)) {
        moment.tz.setDefault(userZone);
        return;
      }

      moment.tz.setDefault();
    }),
    // We try to aquire and later release a "lock" on the moment.tz function so we can catch issues
    // with two instances of this service being active at the same time early.
    _aquireTzDefaultLock: function () {
      if (moment.tz._lock) {
        console.warn('Another instance of clock service seems to be active. This one will not update timezone defaults', this);
        return;
      }

      moment.tz.setDefault._lock = this;

      this._updateTimezone();
    }.on('init'),
    _releaseTzDefaultLock: function () {
      if (moment.tz._lock !== this) {
        return;
      }

      moment.tz.setDefault();
      moment.tz.setDefault._lock = null;
    }.on('destroy'),
    // This method is for debugging purposes only. This overrides and fixes the current time to the passed value.
    // Click: Ember Inspector in Chrome Dev Tools Containers -> service -> clock -> `$E`
    // Seed time via $E._seedTime('2017-12-07')
    _seedTime: function _seedTime(seed) {
      var _this3 = this;

      if (Ember.isNone(seed)) {
        Ember.run(function () {
          _this3.set('_timeSeeded', false);

          _this3._updateMomentCaches();

          _this3._scheduleTick();
        });
        return;
      }

      var seedMoment = moment(seed);

      if (!seedMoment.isValid()) {
        throw new Error("".concat(seed, " is not a valid moment"));
      }

      Ember.run(function () {
        _this3.set('_timeSeeded', true);

        _this3._updateMomentCaches(seed);
      });
    },
    startOfCurrentYear: Ember.computed('year', function () {
      return this.get('now').startOf('year');
    }),
    startOfCurrentYearInDayFormat: Ember.computed('year', function () {
      return this.get('now').startOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
    }),
    endOfCurrentYearInDayFormat: Ember.computed('year', function () {
      return this.get('now').endOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
    })
  });

  _exports.default = _default;
});