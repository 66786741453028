define("coffeecup/routes/planner/projects", ["exports", "coffeecup/routes/_super-route", "coffeecup/utils/string", "coffeecup/config/environment"], function (_exports, _superRoute, _string, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var PLANNER_MODE_PROJECTS = _environment.default.constants.PLANNER_MODE_PROJECTS;

  var _default = _superRoute.default.extend({
    viewport: Ember.inject.service('planner.viewport'),
    activeModal: Ember.inject.service('planner.active-modal'),
    currentUserService: Ember.inject.service('current-user'),
    lastRequest: null,
    titleToken: function titleToken() {
      return this.get('i18n').t('projects');
    },
    setupController: function setupController(controller, context, transition) {
      var _this = this;

      this._super.apply(this, arguments);

      this.controllerFor('planner').set('mode', PLANNER_MODE_PROJECTS);
      this.setScopeDates();

      if (transition.to && transition.to.queryParams && !transition.to.queryParams.startDate) {
        // next() allows the query param to be set correctly after the initialization of the controller
        Ember.run.next(function () {
          controller.set('viewport.startDate', moment(_this.get('viewport.scopeStartDate')).day(1));
        });
      }

      if (controller.get('selectedFilters.userIds')) {
        var userIds = (0, _string.purify)(controller.get('selectedFilters.userIds')).split(',');
        this.controller.set('selectedFilters.users', this.store.peekAll('user').filter(function (user) {
          return userIds.includes(user.get('id'));
        }));
      }

      if (controller.get('selectedFilters.clientIds')) {
        var clientIds = (0, _string.purify)(controller.get('selectedFilters.clientIds')).split(',');
        this.controller.set('selectedFilters.clients', this.store.peekAll('client').filter(function (client) {
          return clientIds.includes(client.get('id'));
        }));
      }

      if (controller.get('selectedFilters.projectIds')) {
        var projectIds = (0, _string.purify)(controller.get('selectedFilters.projectIds')).split(',');
        this.controller.set('selectedFilters.projects', this.store.peekAll('project').filter(function (project) {
          return projectIds.includes(project.get('id'));
        }));
      }

      if (controller.get('selectedFilters.tagIds')) {
        var tagIds = (0, _string.purify)(controller.get('selectedFilters.tagIds')).split(',');
        var projectTags = [];
        tagIds.forEach(function (id) {
          if (id.charAt(0) === 'p') projectTags = [].concat(_toConsumableArray(projectTags), [_this.store.peekRecord('tag', parseInt(id.slice(1)))]);
        });
        this.controller.set('selectedFilters.tags', {
          project: projectTags
        });
      }

      if (controller.get('selectedFilters.teamIds')) {
        var teamIds = (0, _string.purify)(controller.get('selectedFilters.teamIds')).split(',');
        this.controller.set('selectedFilters.teams', this.store.peekAll('team').filter(function (team) {
          return teamIds.includes(team.get('id'));
        }));
      }
    },
    setSummaryStateAndfetchData: function () {
      var plannerSummary = this.get('currentUserService.currentUser.uiState.plannerSummary');

      if (plannerSummary) {
        Ember.run.next(this, 'send', 'fetchProjectsSummaryData');
      }
    }.on('activate'),
    clearSummaryData: function () {
      if (this.get('viewport.showSummary')) {
        this.send('clearProjectsSummaryData');
      }
    }.on('deactivate'),
    model: function model() {},
    setScopeDates: function setScopeDates() {
      var scopeStartDate = this.get('currentUserService.currentUser.uiState.plannerScopeStartDate');
      /* Band-aid fix to get users unstuck if they enter a very, very old date */

      if (scopeStartDate && scopeStartDate.year() < moment().year() - 1) {
        scopeStartDate.year(moment().year() - 1);
      }

      this.set('viewport.selectedScopeStartDate', scopeStartDate);
      var scopeEndDate = this.get('currentUserService.currentUser.uiState.plannerScopeEndDate');
      /* Band-aid fix to get users unstuck if they enter a very, very old date */

      if (scopeEndDate && scopeEndDate.year() < moment().year() - 1) {
        scopeEndDate.year(moment().year() - 1);
      }

      this.set('viewport.selectedScopeEndDate', scopeEndDate);
    },
    actions: {
      editAssignment: function editAssignment(plannerAssignment, isInitialEdit) {
        this.controllerFor('planner.projects.edit').set('isInitialEdit', isInitialEdit);
        this.get('activeModal').setPlannerAssignment(plannerAssignment.get('id'));
        this.transitionTo('planner.projects.edit', plannerAssignment);
      },
      splitAssignment: function splitAssignment(plannerAssignment, splitDate) {
        this.controllerFor('planner.projects.split').set('splitDate', splitDate);
        this.get('activeModal').setPlannerAssignment(plannerAssignment.get('id'));
        plannerAssignment.set('splitDate', splitDate);
        this.transitionTo('planner.projects.split', plannerAssignment);
      },
      clearProjectsSummaryData: function clearProjectsSummaryData() {},
      afterUpdateAssignment: function afterUpdateAssignment(assignment) {
        this.controllerFor('planner').send('afterUpdateAssignment', assignment);
      },
      editMilestone: function editMilestone(milestone, isInitialEdit) {
        this.controllerFor('planner.projects.milestone').set('isInitialEdit', isInitialEdit);

        if (isInitialEdit) {
          var _milestone$project;

          this.controllerFor('planner.projects.milestone').set('options', {
            initialProjectEndDate: milestone === null || milestone === void 0 ? void 0 : (_milestone$project = milestone.project) === null || _milestone$project === void 0 ? void 0 : _milestone$project.endDate
          });
        }

        this.get('activeModal').setMilestone(milestone.get('id'));
        this.transitionTo('planner.projects.milestone', milestone);
      }
    }
  });

  _exports.default = _default;
});