define("coffeecup/templates/components/planner/milestone-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nEXLgkNk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"tool-tipster\",[],[[\"@class\",\"@content\",\"@contentAsHTML\",\"@side\",\"@functionPosition\"],[[32,0,[\"classNames\"]],[32,0,[\"tooltipContent\"]],true,[32,0,[\"side\"]],[30,[36,0],[[32,0,[\"positionArrow\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"fn\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/milestone-tooltip.hbs"
    }
  });

  _exports.default = _default;
});