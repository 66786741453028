define("coffeecup/templates/components/summary-bar-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dF6ek1JX",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"dt\"],[14,0,\"has-tooltip\"],[14,\"data-tooltip-content\",\"Dave is great\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[10,\"dd\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"value\",\"visible\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/summary-bar-entry.hbs"
    }
  });

  _exports.default = _default;
});