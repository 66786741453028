define("coffeecup/utils/number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var numberUtil = {
    /**
     *
     * @param {Integer} min
     * @param {Integer} max
     * @param {Integer} number
     * @return {Integer}
     */
    clamp: function clamp(min, max, number) {
      if (number < min) return min;
      if (number > max) return max;
      return number;
    }
  };
  var _default = numberUtil;
  _exports.default = _default;
});