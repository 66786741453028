define("coffeecup/validations/references", ["exports", "ember-changeset-validations/validators", "coffeecup/services/references"], function (_exports, _validators, _references) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.validateReferenceUrlContent = void 0;
  var protocol = _references.urlPrefix;
  var domain = _references.urlDomain;
  var path = /(\/[\d\w\.\/\%\+\-\=\&amp;\?\:\\\&quot;\'\,\|\~\;]+)/;
  var validUrl = new RegExp('^' + protocol.source + domain.source + path.source + '*?$');

  var validateReferenceUrlContent = function validateReferenceUrlContent(_key, newValue, _oldValue, changes, content) // actually a proxy object
  {
    var _changes$referencePla;

    var currentId = ((_changes$referencePla = changes.referencePlatform) === null || _changes$referencePla === void 0 ? void 0 : _changes$referencePla.id) || content.referencePlatform.get('id');
    var usesUrlInput = changes.referencePlatform ? changes.referencePlatform.usesUrlInput : content.referencePlatform.get('usesUrlInput');

    if (!usesUrlInput) {
      return true;
    } // Require an organization in the URL for certain platforms


    if (['github', 'gitlab', 'azure'].includes(currentId)) {
      var githubUrl = new RegExp('^' + protocol.source + domain.source + path.source + '+$');
      return githubUrl.test(newValue) || 'a valid URL is required';
    }

    return validUrl.test(newValue) || 'a valid URL is required';
  }; // If 'Has Scope' is selected in the form, require presence
  // Otherwise, empty is okay


  _exports.validateReferenceUrlContent = validateReferenceUrlContent;

  var validateScopePresenceIfHasScope = function validateScopePresenceIfHasScope(_key, newValue, _oldValue, changes, content) {
    var noScope = !content.referenceScope;

    if (changes.hasScope) {
      noScope = changes.hasScope === 'n';
    }

    return !!newValue || noScope || 'reference scope is required';
  };

  var validateScopeContent = function validateScopeContent(_key, newValue, _oldValue, changes, content) // actually a proxy object
  {
    var _changes$referencePla2;

    // If the scope field is empty, don't run this validator -- handled by scopePresence above
    if (!newValue) {
      return true;
    }

    var platformId = ((_changes$referencePla2 = changes.referencePlatform) === null || _changes$referencePla2 === void 0 ? void 0 : _changes$referencePla2.id) || content.referencePlatform.get('id');

    switch (platformId) {
      case 'monday':
        return !isNaN(Number(newValue)) || 'reference scope must be a number';

      default:
        return true;
    }
  };

  var _default = {
    validations: {
      referenceUrl: {
        presence: true
      },
      referenceScope: {
        presence: true
      }
    },
    referenceUrl: [(0, _validators.validateLength)({
      max: 255
    }), validateReferenceUrlContent],
    referenceScope: [validateScopePresenceIfHasScope, (0, _validators.validateLength)({
      max: 255
    }), validateScopeContent]
  };
  _exports.default = _default;
});