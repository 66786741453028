define("coffeecup/routes/projects/tasks/edit", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    titleToken: function titleToken(model) {
      return model.get('label');
    },
    listController: 'projects.tasks',
    setupController: function setupController(controller, context, transition) {
      this._super.apply(this, arguments);

      var tagAssignments = this.store.peekAll('tag-assignment').filter(function (el) {
        return el.get('model') === 'task' && el.get('record') === context.get('idAsInt');
      });
      var tags = tagAssignments.getEach('tag');
      controller.set('selectedTags', tags);
      controller.set('existingTags', this.store.peekAll('tag'));
      controller.set('focus', 'label');
      controller.set('colors', this.store.peekAll('color'));
    }
  });

  _exports.default = _default;
});