define("coffeecup/serializers/client", ["exports", "ember-data", "coffeecup/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ClientSerializer = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      imageDirectoryURL: {
        serialize: false
      },
      // makes the attribute readOnly
      // TODO: check if this isn't default behaviour already:
      // http://emberjs.com/api/data/classes/DS.EmbeddedRecordsMixin.html#toc_embeddedrecordsmixin-defaults
      contacts: {
        //serialize: 'records',
        serialize: 'ids',
        deserialize: 'ids'
      }
    }
  });

  var _default = ClientSerializer;
  _exports.default = _default;
});