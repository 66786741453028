define("coffeecup/templates/components/overtime/overview-list/row-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f3cEbYfd",
    "block": "{\"symbols\":[\"@minDate\"],\"statements\":[[10,\"div\"],[14,0,\"row content editable add-entry\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-4 col-sm-2\"],[12],[2,\"\\n    \"],[8,\"input-vacation-amount\",[],[[\"@value\",\"@maxValue\"],[[32,0,[\"overtimeReduction\",\"displayValue\"]],10000]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"canAccessRemunerations\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"hidden-xs col-sm-2\"],[12],[2,\"\\n      \"],[8,\"input/text-field-price\",[],[[\"@maxLength\",\"@name\",\"@isSymbolWide\",\"@currency\"],[\"7\",\"amount\",true,[32,0,[\"overtimeReduction\",\"amount\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[15,0,[31,[\"col-xs-5 \",[30,[36,0],[[32,0,[\"canAccessRemunerations\"]],\"col-sm-3\",\"col-sm-5\"],null]]]],[12],[2,\"\\n    \"],[8,\"input/cc-text-area\",[],[[\"@type\",\"@minHeight\",\"@rows\",\"@value\"],[\"text\",34,1,[32,0,[\"overtimeReduction\",\"comment\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-3 hidden-xs\"],[12],[2,\"\\n    \"],[8,\"input/cc-date-field\",[],[[\"@isDatePickerOpen\",\"@minDate\",\"@date\"],[[32,0,[\"isDatePickerOpen\"]],[32,1],[32,0,[\"overtimeReduction\",\"date\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-2 col-sm-2 text-right\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"btn-group\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-default\"],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"cancel\"]]],null],[12],[10,\"span\"],[14,0,\"icomoon-cross-exit\"],[12],[13],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"save\"]]],null],[12],[10,\"span\"],[14,0,\"icomoon-checkmark\"],[12],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/overtime/overview-list/row-edit.hbs"
    }
  });

  _exports.default = _default;
});