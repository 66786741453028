define("coffeecup/templates/analytics/projects/details/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YmNN1QUU",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"selectedProject\",\"selectedProjectAnalytics\",\"selectedProjectState\",\"projectStates\",\"billProject\",\"onHide\"],[[35,3],[35,2],[35,1],[35,0],\"billProject\",\"transitionAway\"]]]]],\"hasEval\":false,\"upvars\":[\"projectStates\",\"selectedProjectState\",\"selectedProjectAnalytics\",\"selectedProject\",\"modals/complete-project\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/analytics/projects/details/complete.hbs"
    }
  });

  _exports.default = _default;
});