define("coffeecup/models/account-contact", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    company: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    addressAddon: _emberData.default.attr('string'),
    postcode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    country: _emberData.default.attr('string', {
      defaultValue: 'DE'
    }),
    vat: _emberData.default.attr('string'),
    billingEmail: _emberData.default.attr('string'),
    fullName: Ember.computed('firstname', 'lastname', function () {
      if (!this.get('firstname') || !this.get('lastname')) return;
      return "".concat(this.get('firstname'), " ").concat(this.get('lastname'));
    }),
    fullStreet: Ember.computed('street', 'addressAddon', function () {
      if (!this.get('street')) return;
      var addressAddon = this.get('addressAddon') ? "/ ".concat(this.get('addressAddon')) : '';
      return "".concat(this.get('street')).concat(addressAddon);
    }),
    fullCity: Ember.computed('city', 'postcode', function () {
      if (!this.get('city') || !this.get('postcode')) return;
      return "".concat(this.get('postcode'), " ").concat(this.get('city'));
    }),
    accountSettings: _emberData.default.belongsTo('account-setting', {
      async: false
    })
  });

  _exports.default = _default;
});