define("coffeecup/validations/contact", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      firstname: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      lastname: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      email: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      phone: {
        length: {
          maximum: 45
        }
      },
      phoneMobile: {
        length: {
          maximum: 45
        }
      },
      fax: {
        length: {
          maximum: 45
        }
      },
      department: {
        length: {
          maximum: 128
        }
      },
      position: {
        length: {
          maximum: 128
        }
      },
      street: {
        length: {
          maximum: 128
        }
      },
      addressAddon: {
        length: {
          maximum: 128
        }
      },
      postcode: {
        length: {
          maximum: 12
        }
      },
      city: {
        length: {
          maximum: 64
        }
      },
      comment: {
        length: {
          maximum: 10000
        }
      }
    },
    firstname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    lastname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    email: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    }), (0, _validators.validateFormat)({
      type: 'email'
    })],
    phone: [(0, _validators.validateLength)({
      max: 45
    })],
    phoneMobile: [(0, _validators.validateLength)({
      max: 45
    })],
    fax: [(0, _validators.validateLength)({
      max: 45
    })],
    department: [(0, _validators.validateLength)({
      max: 128
    })],
    position: [(0, _validators.validateLength)({
      max: 128
    })],
    street: [(0, _validators.validateLength)({
      max: 128
    })],
    addressAddon: [(0, _validators.validateLength)({
      max: 128
    })],
    postcode: [(0, _validators.validateLength)({
      max: 12
    })],
    city: [(0, _validators.validateLength)({
      max: 64
    })],
    comment: [(0, _validators.validateLength)({
      max: 10000
    })]
  };
  _exports.default = _default;
});