define("coffeecup/models/contact", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Contact = _superModel.default.extend({
    title: _emberData.default.attr('string'),
    department: _emberData.default.attr('string'),
    position: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    phoneMobile: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    addressAddon: _emberData.default.attr('string'),
    postcode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    birthday: _emberData.default.attr('datestamp'),
    country: _emberData.default.attr('string', {
      defaultValue: 'DE'
    }),
    status: _emberData.default.attr('number'),
    //1 = active, 0 = archived (-1 = deleted)
    // computed properties
    fullName: Ember.computed('firstname', 'lastname', {
      get: function get() {
        var firstname = this.get('firstname');
        var lastname = this.get('lastname');
        if (!firstname) firstname = '';
        if (!lastname) lastname = '';

        if (lastname && firstname) {
          lastname = ' ' + lastname;
        }

        return firstname + lastname;
      }
    }),
    // relations
    client: _emberData.default.belongsTo('client', {
      async: false
    }),
    clone: function clone() {
      return this.getProperties(['title', 'firstname', 'lastname', 'email', 'phone', 'phoneMobile', 'fax', 'street', 'addressAddon', 'postcode', 'city', 'comment', 'country']);
    }
  });

  var _default = Contact;
  _exports.default = _default;
});