define("coffeecup/initializers/controller-update-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var actions = {
      updateModelProperty: function updateModelProperty(model, property, key, value) {
        if (value.get && typeof value.get === 'function') {
          return model.set(property, value.get(key));
        } else {
          return model.set(property, value[key]);
        }
      },
      updateProperty: function updateProperty(model, key, value) {
        this.set(model, value.get(key));
      }
    };
    Ember.Controller.reopen({
      actions: actions
    });
    Ember.Component.reopen({
      actions: actions
    });
  }

  var _default = {
    name: 'controller-updateProperty',
    initialize: initialize
  };
  _exports.default = _default;
});