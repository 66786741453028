define("coffeecup/templates/account/integrations/billing/client-import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ejgX4DSq",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"onClose\",\"targetAttachment\",\"translucentOverlay\"],[[30,[36,1],[[32,0],\"onHide\"],null],\"center\",true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[19,[35,0],[]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"activePartial\",\"action\",\"modal-dialog\",\"openModal\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/account/integrations/billing/client-import.hbs"
    }
  });

  _exports.default = _default;
});