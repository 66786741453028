define("coffeecup/services/charts/gauge", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Service.extend({
    getChartData: function getChartData(bands, startValue, isSmall) {
      var _bands = _slicedToArray(bands, 3),
          lastMonthBand = _bands[0],
          currentMonthBand = _bands[1],
          surplusBand = _bands[2];

      var bandsArray = [{
        alpha: 1,
        color: '#F4F4F4',
        endValue: 200,
        id: 'Grey Overlay',
        innerRadius: '85%',
        radius: '100%',
        startValue: startValue
      }];

      if (lastMonthBand) {
        bandsArray.push(lastMonthBand);
      }

      if (currentMonthBand) {
        bandsArray.push(currentMonthBand);
      }

      if (surplusBand) {
        bandsArray.push(surplusBand);
      }

      return {
        type: 'gauge',
        fontSize: 12,
        arrows: [],
        minRadius: isSmall ? 70 : 110,
        axes: [{
          axisThickness: 0,
          bottomText: '',
          bottomTextYOffset: -21,
          endAngle: 140,
          endValue: 200,
          fontSize: 6,
          gridCount: 1,
          id: 'GaugeAxis-1',
          labelsEnabled: false,
          showFirstLabel: false,
          showLastLabel: false,
          startAngle: -140,
          tickAlpha: 0,
          unit: '%',
          valueInterval: 10,
          bands: bandsArray
        }],
        allLabels: [],
        balloon: {},
        titles: []
      };
    },
    getEffortChartBand: function getEffortChartBand(type, bandText, percentageOfLastMonth) {
      var baseBand = {
        id: type,
        alpha: 1,
        color: _environment.default.constants.REVENUE_COLOR,
        innerRadius: '85%',
        radius: '100%',
        startValue: 0,
        balloonText: bandText
      };

      switch (type) {
        case 'This Month':
          baseBand.endValue = percentageOfLastMonth;
          baseBand.innerRadius = '85%';
          return baseBand;

        case 'ToGo':
          baseBand.alpha = 0.3;
          baseBand.endValue = 100;
          return baseBand;

        case 'Last Period':
          if (percentageOfLastMonth > 100) {
            baseBand.endValue = percentageOfLastMonth;
            baseBand.radius = '95%';
            baseBand.startValue = 100;
            return baseBand;
          } else {
            return {
              alpha: 0,
              endValue: 0,
              innerRadius: '85%',
              radius: '95%',
              startValue: 0
            };
          }

      }
    }
  });

  _exports.default = _default;
});