define("coffeecup/templates/components/date-week-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "E5GQzJOT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,5,\"position: relative;\"],[15,\"data-project-tour\",[31,[[34,1]]]],[12],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[\"button-current-week \",[30,[36,3],[[35,2],\"is-current-week\"],null]]]],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"left-of-parent\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-default btn-sm btn-current-week\"],[24,\"data-test\",\"button-current-week\"],[4,[38,0],[[32,0],\"currentWeek\"],null],[12],[1,[34,4]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-noborder switch-btn\"],[4,[38,0],[[32,0],\"prevWeek\"],null],[12],[10,\"span\"],[14,0,\"icon-arrow-67 small\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n  \"],[10,\"a\"],[14,0,\"btn btn-noborder date-range-picker\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"h3\"],[12],[1,[34,5]],[10,\"br\"],[12],[13],[13],[2,\"\\n    \"],[1,[34,6]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn btn-noborder switch-btn\"],[4,[38,0],[[32,0],\"nextWeek\"],null],[12],[10,\"span\"],[14,0,\"icon-arrow-68 small\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"projectTourIndicator\",\"isCurrentWeek\",\"if\",\"currentWeekLabel\",\"weekNumber\",\"weekRange\",\"isInMaxWeek\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/date-week-picker.hbs"
    }
  });

  _exports.default = _default;
});