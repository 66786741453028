define("coffeecup/mixins/planner/label-position", ["exports", "coffeecup/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    viewport: Ember.inject.service('planner.viewport'),
    getAssignmentLabelLeft: function getAssignmentLabelLeft(offset) {
      var viewportStartDate = this.get('viewport.startDate');
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');

      if (startDate && (0, _date.isDateValid)(startDate) && endDate && (0, _date.isDateValid)(endDate)) {
        if (endDate.isBefore(viewportStartDate)) return 0;
        var daysBetween = this.get('viewport').numberOfDaysBetween(viewportStartDate, startDate) - offset;
        return startDate.isBefore(viewportStartDate) ? Math.abs(daysBetween) * this.get('viewport.dayWidth') : 0;
      }

      return 0;
    },
    moveAssignmentLabel: Ember.observer('left', 'viewport.startDate', function (offset) {
      offset = isFinite(offset) ? Math.round(offset) : 0;
      var left = this.getAssignmentLabelLeft(offset);
      this.$('> div.allocated-info').css('margin-left', left + 'px');
    }),
    setInitialOffset: Ember.on('didInsertElement', function () {
      this.moveAssignmentLabel();
    })
  });

  _exports.default = _default;
});