define("coffeecup/mixins/pagination-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     *  The number of pages in total, required
     */
    totalPages: null,

    /**
     *  The current page number, required
     */
    currentPage: null,

    /**
     *  The page page number
     */
    page: 0,

    /**
     * @inherit
     */
    actions: {
      /**
       * is called once a page has changed with the pagination control
       *
       * @param currentPage The currently displayed page
       * @param previous The previous page
       */
      pageChanged: function pageChanged(currentPage, previous) {
        this.set('page', Math.max(0, currentPage - 1));
      }
    }
  });

  _exports.default = _default;
});