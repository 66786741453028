define("coffeecup/validations/project-milestone", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    day: [(0, _validators.validatePresence)(true)],
    code: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 7
    })],
    comment: [(0, _validators.validateLength)({
      max: 250
    })],
    validations: {
      comment: {
        length: {
          maximum: 250
        }
      }
    }
  };
  _exports.default = _default;
});