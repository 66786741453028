define("coffeecup/routes/projects/expense-categories/copy", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    expenseCategoryService: Ember.inject.service('expense-category'),
    redirect: function redirect(context) {
      var _this = this;

      this.get('expenseCategoryService').copyExpenseCategory(context.clone()).then(function (category) {
        _this.transitionTo('projects.expense-categories.edit', category);
      });
    }
  });

  _exports.default = _default;
});