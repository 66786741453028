define("coffeecup/templates/components/icon-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tjs3YPbK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"icon \",[30,[36,1],[[35,0],[35,3],[35,2]],null],\" \",[30,[36,1],[[35,0],\"active\"],null]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"if\",\"iconOff\",\"iconOn\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/icon-switch.hbs"
    }
  });

  _exports.default = _default;
});