define("coffeecup/templates/components/time-entry/bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KTmbfoz6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[3,\" time entry bottom part \"],[2,\"\\n\"],[10,\"div\"],[14,0,\"bottom-gradient\"],[15,5,[34,0]],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"bottom-content clearfix\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"gradient\"],[15,5,[34,1]],[12],[13],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"start-end \",[30,[36,3],[[35,2],\"hidden\"],null],\" hide-if-running\"]]],[12],[2,\"\\n  \"],[1,[35,4,[\"startTimeHM\"]]],[2,\"-\"],[1,[35,4,[\"endTimeHM\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,5],null,[[\"type\"],[\"time-entry-handle\"]]]]],\"hasEval\":false,\"upvars\":[\"topGradientStyle\",\"gradientStyle\",\"showStartAndEndTime\",\"unless\",\"item\",\"resizable-item/handle\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/time-entry/bottom.hbs"
    }
  });

  _exports.default = _default;
});