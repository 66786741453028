define("coffeecup/templates/planner/absences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MrM/OdOK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"article\"],[15,0,[31,[\"absences-panel \",[34,7]]]],[12],[2,\"\\n  \"],[1,[30,[36,9],[[30,[36,8],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,10],[[32,0,[\"hasAbsenceIntegration\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],null,[[\"currentAbsenceRoute\",\"requestsOnly\",\"isPanelOpen\",\"clickedRequest\",\"absenceRequests\",\"teamId\",\"confirmAbsenceRequest\",\"deleteAbsenceRequest\",\"saveAbsenceRequest\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[35,0],\"confirmAbsenceRequest\",\"deleteAbsenceRequest\",\"saveAbsenceRequest\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"teamId\",\"absenceRequests\",\"clickedRequest\",\"isPanelOpen\",\"requestsOnly\",\"currentAbsenceRoute\",\"absence-tracking/request-panel\",\"additionalClass\",\"-outlet\",\"component\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/planner/absences.hbs"
    }
  });

  _exports.default = _default;
});