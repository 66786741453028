define("coffeecup/validators/validateWeeklyHours", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateWeeklyHours;

  function validateWeeklyHours(opts) {
    return function (key, newValue, oldValue, changes, model) {
      var workHoursType = changes['workHoursType'] || model.get('workHoursType');

      if (workHoursType === _environment.default.constants.WORK_HOURS_FIXED_WEEK) {
        if (newValue > 168) {
          return 'Hours weekly must be set for flexible work hours';
        }
      }

      return true;
    };
  }
});