define("coffeecup/templates/components/analytics/projects/budget-fmt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XNdg8fd3",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"byEffort\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"notBillable\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"budget\"],null]],[2,\":\\n  \"],[10,\"b\"],[12],[2,\" \"],[1,[30,[36,4],[[35,5]],null]],[13],[2,\"\\n\"],[6,[37,6],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"projectBudgetDescription\"],[[\"amount\"],[[30,[36,4],[[35,3]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,6],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"budget\"],null]],[2,\":\\n  \"],[10,\"b\"],[12],[1,[30,[36,2],[[35,1]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"hoursBudget\",\"hours-fmt\",\"expensesNotWithinBudget\",\"currency-fmt\",\"amountBudget\",\"if\",\"isByEffort\",\"isNotBillable\",\"isBudgetByAmount\",\"isBudgetByHours\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics/projects/budget-fmt.hbs"
    }
  });

  _exports.default = _default;
});