define("coffeecup/validators/validateRelation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateRelation;

  function validateRelation(opts) {
    return function (key, newValue, oldValue, changes, model) {
      var presence = opts.presence,
          onValue = opts.on;
      var hasNoNewValue = !newValue || !Ember.get(newValue, 'id') && !Ember.get(newValue, '_internalModel.id');

      if (presence && !onValue && hasNoNewValue) {
        return 'Relationship is required!';
      } else if (presence && onValue && Ember.get(model, onValue) && hasNoNewValue) {
        return 'Relationship is required!';
      } else if (!presence && onValue && Ember.get(model, onValue) && !hasNoNewValue) {
        return 'Relationship is not allowed!';
      } else if (!presence && onValue && !Ember.get(model, onValue) && hasNoNewValue) {
        return 'Relationship is required!';
      }

      return true;
    };
  }
});