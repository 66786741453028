define("coffeecup/initializers/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //initializers/clock.js
  var ClockInitializer = {
    name: 'clock',
    after: 'ember-data',
    initialize: function initialize(application) {
      /* inject clock service into all container objects  */
      application.inject('controller', 'clock', 'service:clock');
      application.inject('component', 'clock', 'service:clock');
      application.inject('helper', 'clock', 'service:clock');
      application.inject('model', 'clock', 'service:clock');
      application.inject('mixin', 'clock', 'service:clock');
      application.inject('route', 'clock', 'service:clock');
      application.inject('proxy', 'clock', 'service:clock');
      application.inject('view', 'clock', 'service:clock');
    }
  };
  var _default = ClockInitializer;
  _exports.default = _default;
});