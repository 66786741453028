define("coffeecup/models/account-setting", ["exports", "ember-data", "coffeecup/config/environment", "coffeecup/models/-super-model", "coffeecup/utils/color-hex2rgba"], function (_exports, _emberData, _environment, _superModel, _colorHex2rgba) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var AccountSettings = _superModel.default.extend({
    /**
     * the name of the company
     */
    companyName: _emberData.default.attr('string'),
    customerId: _emberData.default.attr('string'),

    /**
     * the logo of the company
     * should default to the CoffeeCup logo
     */
    companyLogo: _emberData.default.attr('string'),

    /**
     * the month of the fiscalYear start
     * 0 - 11 (zero based)
     */
    fiscalYearMonth: _emberData.default.attr('number', {
      defaultValue: 0
    }),

    /**
     * the currency used throughout the account
     */
    currency: _emberData.default.belongsTo('currency', {
      async: false
    }),

    /**
     * the format of numbers
     */
    numberFormat: _emberData.default.attr('string'),

    /**
     * the format of the currency
     */
    currencyFormat: _emberData.default.attr('string'),

    /**
     * rounding rules of times within reports and time-sheets
     */
    timeRoundingType: _emberData.default.attr('number'),

    /**
     * rounding step in minutes
     */
    timeRoundingStep: _emberData.default.attr('number'),
    adminUiState: _emberData.default.attr('string'),
    accountContacts: _emberData.default.hasMany('account-contact', {
      async: true
    }),
    activeWeekends: _emberData.default.attr('boolean'),
    featureFlags: _emberData.default.attr('feature-flags'),
    firstAccountContact: Ember.computed('accountContacts.firstObject', {
      get: function get() {
        return this.get('accountContacts.firstObject');
      }
    }),
    adminUiSettings: Ember.computed('adminUiState', {
      get: function get() {
        if (!this.get('adminUiState')) return {};
        return JSON.parse(this.get('adminUiState'));
      },
      set: function set(key, val) {
        var currentState = this.get('adminUiSettings');
        var newState = Ember.setProperties(currentState, val);
        this.set('adminUiState', JSON.stringify(newState));
        return newState;
      }
    }),
    easybillConfig: _emberData.default.attr('string'),
    easybillConfiguration: Ember.computed('easybillConfig', {
      get: function get() {
        if (!this.get('easybillConfig')) return {};
        return JSON.parse(this.get('easybillConfig'));
      },
      set: function set(key, val) {
        var currentState = this.get('easybillConfiguration');
        var newState = Ember.setProperties(currentState, val);
        this.set('easybillConfig', JSON.stringify(newState));
        return newState;
      }
    }),

    /**
     * colorScheme
     */
    colorScheme: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.COLOR_SCHEME_DEFAULT
    }),

    /**
     * Allow users to have multiple time entries at the same time.
     */
    allowConcurrentTimeEntries: _emberData.default.attr('boolean', {
      defaultValue: false
    }),

    /**
     * Allow users to add breaks as time entries.
     */
    allowBreaks: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    activeBreakValidation: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    activeCommentValidation: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    activeHoursValidation: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    activeTimeSheetValidation: _emberData.default.attr('boolean', {
      defaultValue: false
    }),

    /**
     * Timer Mode is either simple with duration only (default) or each timer has a startTime and endTime
     */
    timerMode: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.TIMER_MODE_DURATION
    }),
    timerModeIsDuration: Ember.computed.equal('timerMode', _environment.default.constants.TIMER_MODE_DURATION),
    timerModeIsStartTimeEndTime: Ember.computed.equal('timerMode', _environment.default.constants.TIMER_MODE_START_TIME_END_TIME),
    planningMode: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.PLANNING_MODE_WEEKLY
    }),
    planningModeIsWeekly: Ember.computed.equal('planningMode', _environment.default.constants.PLANNING_MODE_WEEKLY),

    /**
     * if set, the user needs to re-login before he can enter the user-analytics route
     */
    secureUserDetailsRoute: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    ////////////////////////////////////////////////////
    // DEFAULT VALUES
    ////////////////////////////////////////////////////

    /**
     * the default timezone to be used, when creating a new user
     */
    defaultTimezone: _emberData.default.attr('string'),

    /**
     * the default language to be used, when creating a new user
     */
    defaultLanguage: _emberData.default.attr('string'),

    /**
     * the default beginning of the week to be used, when creating a new user
     */
    defaultBeginningOfWeek: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.WEEKDAY_MONDAY
    }),

    /**
     * the default date format to be used, when creating a new user
     */
    defaultDateFormat: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.DATE_FORMAT_DDMMYYYY_POINT
    }),
    // eg. 24.12.2015, 12.24.2015, 24-12-2015, etc.

    /**
     * the default time format to be used, when creating a new user
     */
    defaultTimeFormat: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.TIME_FORMAT_24
    }),
    // eg. 12 hours or 24 hours

    /**
     * the default duartion format to be used, when creating a new user
     */
    defaultDurationFormat: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.DURATION_FORMAT_HM
    }),
    // eg. decimal or HH:MM
    defaultEmployerContribution: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    defaultWorkHoursForPlanner: _emberData.default.attr('number', {
      defaultValue: 8
    }),
    activateProjectAnalyticsForUser: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    activeTimeEntryQuality: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    defaultNoticePeriod: _emberData.default.attr('notice-period'),
    defaultProbationaryPeriodInMonths: _emberData.default.attr('number', {
      defaultValue: 6
    }),
    createdAt: _emberData.default.attr('datestamp'),
    ////////////////////////////////////////////////////
    // MODEL RELATIONS
    ////////////////////////////////////////////////////

    /**
     * the user ID of the account owner
     * (only assignable to admins)
     */
    accountOwner: _emberData.default.belongsTo('user', {
      async: true
    }),

    /**
     * naming convention
     * eg. agency, law office, etc.
     */
    wordingOption: _emberData.default.belongsTo('wording-option', {
      async: true
    }),
    colorSchemeDarker: Ember.computed('colorScheme', {
      get: function get() {
        var colorScheme = this.get('colorScheme');

        if (colorScheme) {
          var rgb = _colorHex2rgba.RGBA.convertFromHex(colorScheme);

          rgb.darken(20);
          return rgb.toCSS();
        }

        return null;
      }
    }),
    colorSchemeDarkerer: Ember.computed('colorScheme', {
      get: function get() {
        var colorScheme = this.get('colorScheme');

        if (colorScheme) {
          var rgb = _colorHex2rgba.RGBA.convertFromHex(colorScheme);

          rgb.darken(40);
          return rgb.toCSS();
        }

        return null;
      }
    }),
    colorSchemeLighter: Ember.computed('colorScheme', {
      get: function get() {
        var colorScheme = this.get('colorScheme');

        if (colorScheme) {
          var rgb = _colorHex2rgba.RGBA.convertFromHex(colorScheme, 0.3);

          return rgb.toCSS();
        }

        return null;
      }
    }),
    colorScheme70: Ember.computed('colorScheme', {
      get: function get() {
        var colorScheme = this.get('colorScheme');

        if (colorScheme) {
          var rgb = _colorHex2rgba.RGBA.convertFromHex(colorScheme, 0.7);

          return rgb.toCSS();
        }

        return null;
      }
    }),
    colorSchemeLight: Ember.computed('colorScheme', {
      get: function get() {
        var colorScheme = this.get('colorScheme');

        if (colorScheme) {
          var rgb = _colorHex2rgba.RGBA.convertFromHex(colorScheme, 0.8);

          return rgb.toCSS();
        }

        return null;
      }
    }),
    colorTransparent: Ember.computed('colorScheme', {
      get: function get() {
        var colorScheme = this.get('colorScheme');

        if (colorScheme) {
          var rgb = _colorHex2rgba.RGBA.convertFromHex(colorScheme, 0.1);

          return rgb.toCSS();
        }

        return null;
      }
    })
  });

  var _default = AccountSettings;
  _exports.default = _default;
});