define("coffeecup/templates/analytics/-details-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J8Fyjh2F",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"chart-container\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"empty-chart\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"state-wrapper\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"icon-wrapper\"],[12],[10,\"span\"],[14,0,\"icomoon-rocket\"],[12],[13],[13],[2,\"\\n        \"],[10,\"h2\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"currentlyReworking\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"workInProgress\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/analytics/-details-chart.hbs"
    }
  });

  _exports.default = _default;
});