define("coffeecup/templates/components/dashboard/my-week-module/upcoming-event-day-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Ux+Xw4m",
    "block": "{\"symbols\":[\"@event\"],\"statements\":[[6,[37,3],[[32,0,[\"eventIcon\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"tool-tipster\",[],[[\"@class\",\"@content\",\"@contentAsHTML\",\"@side\",\"@functionPosition\"],[\"upcomming-event-day-item\",[32,1,[\"tooltipContent\"]],true,\"top\",[30,[36,2],[[32,0,[\"positionTooltipArrow\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"small-space-right\"],[12],[2,\"\\n      \"],[8,\"icon-element\",[[16,5,[30,[36,1],[[30,[36,0],[\"background-color: \",[32,1,[\"color\"]],\";\"],null]],null]]],[[\"@icon\"],[[32,0,[\"eventIcon\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-overflow\"],[12],[2,\"\\n      \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"planner/milestone-tooltip\",[],[[\"@milestones\",\"@classNames\",\"@leftInset\",\"@day\"],[[32,1,[\"milestones\"]],\"upcomming-event-day-item\",11,[32,1,[\"day\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"small-space-right\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"milestone\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"rotated-square\"],[15,5,[30,[36,1],[[30,[36,0],[\"background-color: \",[32,1,[\"color\"]],\";\"],null]],null]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-overflow\"],[12],[2,\"\\n      \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"html-safe\",\"fn\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/my-week-module/upcoming-event-day-item.hbs"
    }
  });

  _exports.default = _default;
});