define("coffeecup/instance-initializers/time-entry-color-option", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'time-entry-color-option',
        id: _environment.default.constants.TIME_ENTRY_COLOR_TASK,
        attributes: {
          key: 'taskColorOption'
        }
      }, {
        type: 'time-entry-color-option',
        id: _environment.default.constants.TIME_ENTRY_COLOR_PROJECT,
        attributes: {
          key: 'projectColorOption'
        }
      }, {
        type: 'time-entry-color-option',
        id: _environment.default.constants.TIME_ENTRY_COLOR_CLEAN,
        attributes: {
          key: 'cleanColorOption'
        }
      } //{id: ENV.constants.TIME_ENTRY_COLOR_DARK, key: 'darkColorOption'}
      ]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'time-entry-color-option',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});