define("coffeecup/templates/components/dashboard/project-billing/header-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IaXME2kc",
    "block": "{\"symbols\":[\"@propertyLabel\",\"@hasInvoiceColumn\",\"@valueLabel\",\"@invoicesActive\"],\"statements\":[[10,\"div\"],[14,0,\"flex-row list-header-row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-2\"],[12],[2,\"\\n    \"],[1,[32,1]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex-1 text-right\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"dueDateLabel\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"flex-1 text-right project-value-header text-overflow\"],[15,\"title\",[32,3]],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"cc-dropdown\",[[24,0,\"invisible\"]],[[\"@icon\",\"@noBorder\",\"@smallSpace\"],[\"icomoon-more_horiz\",true,true]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/project-billing/header-row.hbs"
    }
  });

  _exports.default = _default;
});