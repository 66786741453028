define("coffeecup/templates/team/users/edit/overtime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q4RJCL6r",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"showOvertime\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"overtime/overview-panel\",[],[[\"@years\",\"@user\"],[[32,0,[\"years\"]],[32,0,[\"model\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/team/users/edit/overtime.hbs"
    }
  });

  _exports.default = _default;
});