define("coffeecup/services/intercom-analytics", ["exports", "coffeecup/services/base-analytics", "coffeecup/config/environment"], function (_exports, _baseAnalytics, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAnalytics.default.extend({
    store: Ember.inject.service(),
    timeEntry: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    permissions: Ember.inject.service(),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    subscription: Ember.computed.alias('accountService.subscription'),
    unreadCount: 0,
    trackingId: _environment.default.APP.intercomTrackingId,
    insertScript: function insertScript() {
      var trackingId = this.get('trackingId');

      if (!trackingId) {
        return; // Can't start Intercom without app ID
      }

      var intercomSettings = {
        app_id: trackingId
      };
      window.intercomSettings = {
        alignment: 'right',
        horizontal_padding: 40,
        vertical_padding: 40,
        custom_launcher_selector: '#menu-action-intercom',
        hide_default_launcher: true
      };
      /* jshint ignore:start */

      (function (service) {
        var w = window;
        var ic = w.Intercom;

        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', intercomSettings);
        } else {
          var d = document;

          var i = function i() {
            i.c(arguments);
          };

          i.q = [];

          i.c = function (args) {
            i.q.push(args);
          };

          w.Intercom = i;

          function l() {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/' + trackingId;
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          }

          i('onUnreadCountChange', function (unreadCount) {
            service.set('unreadCount', unreadCount);
          });
          l();
        }
      })(this);
      /* jshint ignore:end */

    },
    _started: false,
    _user: null,
    start: function start(user) {
      this.set('_user', user);
      var started = this.get('_started');
      var type = started ? 'update' : 'boot';
      var store = this.get('store');
      var data = {};
      var accountSettings = this.get('accountSettings');
      var allUsersOfAccount = store.peekAll('user');
      data.app_id = this.get('trackingId');
      data.app_revision = _environment.default.APP.version;

      if (user) {
        data.name = user.get('label');
        data.email = user.get('email');
        data.user_hash = user.get('emailHmacHash');
        data['Employment Type'] = this._getUserEmploymentType(user);
        data['Work Hours Type'] = this._getUserEmploymentWorkHoursType(user);
        data['User Role'] = user.get('role.label');
        data.easybill_is_enabed = !!user.get('easybillEnabledAt');
        data.language_override = user.get('language');
        data.permissions_canAccessPlanner = this.get('permissions.canAccessPlanner');
        data.permissions_canManageAbsenceRequests = this.get('permissions.canManageAbsenceRequests');
        data.permissions_canManageOwnProjects = this.get('permissions.canManageOwnProjects');
        data.permissions_canManageAllProjects = this.get('permissions.canManageAllProjects');
        data.permissions_canAccessProjectCosts = this.get('permissions.canAccessProjectCosts');
        data.permissions_canManageProjectRelations = this.get('permissions.canManageProjectRelations');
        data.permissions_canManagePlanner = this.get('permissions.canManagePlanner');
        data.permissions_canAccessUsers = this.get('permissions.canAccessUsers');
        data.permissions_canManageUsers = this.get('permissions.canManageUsers');
        data.permissions_canAccessRemunerations = this.get('permissions.canAccessRemunerations');
        data.permissions_canValidateWorkTime = this.get('permissions.canValidateWorkTime');
        data.has_users_to_validate = this.get('permissions.isRootAdmin') ? allUsersOfAccount.filter(function (el) {
          return el.get('supervisor.id');
        }).get('length') : allUsersOfAccount.filter(function (el) {
          return el.get('supervisor.id') === user.get('id');
        }).get('length');
        data.account_owner = this.get('accountSettings.accountOwner.id') === user.get('id');
        data.active_auto_approval = user.get('activeAutoApproval');
        data.onboarding_finished = user.get('featuresUnlocked.challengesUnlocked');
      }

      if (accountSettings) {
        var url = document.location.hostname;
        var urlComponents = url.split('.');
        var companyURL,
            companyName = accountSettings.get('companyName');

        if (urlComponents.length > 0) {
          companyURL = urlComponents[0];
        }

        data.company = {
          id: accountSettings.get('id'),
          name: companyName || companyURL,
          // created_at: accountSettings.get('account.id')
          plan: '',
          // (optional): Insert amount current company spends a month
          monthly_spend: 0,
          url: url,
          allow_concurrent_timeEntries: !!accountSettings.get('allowConcurrentTimeEntries'),
          allow_breaks: !!accountSettings.get('allowBreaks'),
          active_weekends: !!accountSettings.get('activeWeekends'),
          activate_project_analytics_for_user: !!accountSettings.get('activateProjectAnalyticsForUser'),
          active_time_sheet_validation: !!accountSettings.get('activeTimeSheetValidation'),
          active_break_validation: !!accountSettings.get('activeBreakValidation'),
          active_comment_validation: !!accountSettings.get('activeCommentValidation'),
          active_hours_validation: !!accountSettings.get('activeHoursValidation'),
          timer_mode: accountSettings.get('timerMode'),
          user_assignments: this.get('store').peekAll('user-assignment').get('length')
        };
      }

      var subscription = this.get('subscription');

      if (subscription) {
        data.company.plan = subscription.get('plan.id');
        data.company.monthly_spend = subscription.get('amount') / 100;
        data.company.size = subscription.get('quantity');
        data.company.created_at = moment(subscription.get('startedAt')).unix();
        data.company['Trial Remaining Days'] = subscription.get('remainingTrialDays');
        data.company['Trial Ends'] = moment(subscription.get('trialEnd')).unix();
        data.company['Creditcard'] = subscription.get('isCardValid');
      }

      if (!data.app_id) {
        this.log('No app ID defined, stopping analytics');
        return;
      }

      if (user && accountSettings) {
        this.set('_started', true);
      }

      this.send(type, data);
    },
    _getUserEmploymentWorkHoursType: function _getUserEmploymentWorkHoursType(user) {
      var effectiveEmploymentWorkHoursType = user.get('effectiveEmployment.workHoursType');

      switch (effectiveEmploymentWorkHoursType) {
        case _environment.default.constants.WORK_HOURS_FIXED_DAY:
          return 'FixedDay';

        case _environment.default.constants.WORK_HOURS_FIXED_WEEK:
          return 'FixedWeek';

        case _environment.default.constants.WORK_HOURS_FLEXIBLE:
          return 'FlexibleHours';

        default:
          return 'Unknown';
      }
    },
    _getUserEmploymentType: function _getUserEmploymentType(user) {
      var effectiveEmploymentType = user.get('effectiveEmployment.employmentType');

      switch (effectiveEmploymentType) {
        case _environment.default.constants.USER_TYPE_FORMER:
          return 'Former';

        case _environment.default.constants.USER_TYPE_EMPLOYEE:
        case _environment.default.constants.USER_TYPE_EMPLOYEE_HOURLY:
          return 'Employee';

        case _environment.default.constants.USER_TYPE_FREELANCER:
        case _environment.default.constants.USER_TYPE_FREELANCER_HOURLY:
          return 'Freelancer';

        default:
          return 'Unknown';
      }
    },
    stop: function stop() {
      this.set('_started', false);
      this.send('shutdown');
    },
    send: function send(type, args) {
      if (!window.Intercom) {
        this.log('Intercom not active!');
        return;
      }

      this.log('Sending to intercom: ' + type + ' with args:', args);
      window.Intercom(type, args);
    },
    exception: function exception(exOptions) {
      this.event('exception', exOptions);
    },
    event: function event(_event, args) {
      if (!window.Intercom) {
        return;
      }

      window.Intercom('trackEvent', _event, args);
    },
    notifyTransition: function notifyTransition(url) {
      this.event(url);

      this._trackCustomMetaData();
    },
    _trackCustomMetaData: function _trackCustomMetaData() {
      var data = {};
      var store = this.get('store');
      var user = this.get('_user'); // The following analytics values are loaded based on user activity, i.e. opening
      // dashboard or creating a new time entry. We don't want to load them in the analytics
      // so as not to add unnecessary requests. So, we just check with each transition
      // if there are values to include in the update.

      var dashboardModules = store.peekAll('dashboard-module');

      if (dashboardModules.get('length') > 0) {
        data['Dashboard Module Count'] = dashboardModules.get('length');
      }

      var timeEntry = this.get('timeEntry');
      var latestTimeEntry = timeEntry.peekLatestTimeEntry();

      if (latestTimeEntry) {
        data['Last TimeEntry'] = latestTimeEntry.get('firstObject.day');
      }

      if (user) {
        var userId = user.get('id');
        var absenceRequests = store.peekAll('absence-request');

        if (absenceRequests.get('isLoaded')) {
          var ownRequests = absenceRequests.filterBy('sender.id', userId);
          data['Absence Request Count'] = ownRequests.get('length');
        }

        var absences = store.peekAll('absence');

        if (absences.get('isLoaded')) {
          var ownAbsences = absences.filterBy('user.id', userId);
          data['Absence Count'] = ownAbsences.get('length');
        }
      }

      if (!Ember.$.isEmptyObject(data)) {
        this.send('update', data);
      }
    }
  });

  _exports.default = _default;
});