define("coffeecup/instance-initializers/wording-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'wording-option',
        id: 'default'
      }, {
        type: 'wording-option',
        id: 'agency'
      }, {
        type: 'wording-option',
        id: 'agency-bavaria'
      }, {
        type: 'wording-option',
        id: 'law-office'
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'wording-option',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});