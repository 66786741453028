define("coffeecup/mixins/list-when-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListWhenEditMixin = Ember.Mixin.create({
    activeRecord: undefined,
    listClass: Ember.computed('activeRecord.id', {
      get: function get() {
        return this.get('activeRecord.id') !== undefined ? 'has-detail-pane hidden-sm' : '';
      }
    })
  });
  var _default = ListWhenEditMixin;
  _exports.default = _default;
});