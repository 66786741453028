define("coffeecup/templates/analytics/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bIpz30u1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"analytics-entity\"],[12],[2,\"\\n\\n  \"],[19,\"analytics/users/-header\",[]],[2,\"\\n\\n  \"],[10,\"section\"],[14,0,\"has-header scrollable list\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"flex-box-wrapper scrollable\"],[12],[2,\"\\n\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,5],null,[[\"openModal\",\"user\",\"showStartEnd\"],[[35,4],[35,3],[35,2,[\"timerModeIsStartTimeEndTime\"]]]]]],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"-outlet\",\"component\",\"accountSettings\",\"selectedUser\",\"openModal\",\"modals/user-analytics/export\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/analytics/users.hbs"
    }
  });

  _exports.default = _default;
});