define("coffeecup/templates/onboarding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+63/yxsX",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"showOnboardingBasics\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"article\"],[14,0,\"dashboard\"],[12],[2,\"\\n    \"],[8,\"onboarding/basic/index\",[],[[\"@onDone\"],[[32,0,[\"finishOnboarding\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/onboarding.hbs"
    }
  });

  _exports.default = _default;
});