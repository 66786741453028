define("coffeecup/templates/components/analytics/time-entries/analytics-kpi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sKWi8WL9",
    "block": "{\"symbols\":[\"@analytics\",\"@isGroupedByDate\",\"@isDefaultUser\"],\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[8,\"analytics-module/analytics/worktime\",[],[[\"@analytics\",\"@hasData\"],[[32,1],true]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[8,\"analytics-module/analytics/worktime\",[],[[\"@analytics\",\"@hasData\"],[[32,1],true]],null],[2,\"\\n  \"],[8,\"analytics-module/analytics/billable-hours\",[],[[\"@analytics\",\"@hasData\"],[[32,1],true]],null],[2,\"\\n  \"],[8,\"analytics-module/analytics/total-hours\",[],[[\"@analytics\",\"@hasData\"],[[32,1],true]],null],[2,\"\\n  \"],[8,\"analytics-module/analytics/in-budget\",[],[[\"@isGroupedByDate\",\"@analytics\",\"@hasData\"],[[32,2],[32,1],true]],null],[2,\"\\n  \"],[8,\"analytics-module/analytics/billed\",[],[[\"@analytics\",\"@hasData\"],[[32,1],true]],null],[2,\"\\n  \"],[8,\"analytics-module/analytics/unbilled\",[],[[\"@analytics\",\"@hasData\"],[[32,1],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics/time-entries/analytics-kpi.hbs"
    }
  });

  _exports.default = _default;
});