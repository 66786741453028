define("coffeecup/models/overtime-reduction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    hours: _emberData.default.attr('number'),
    amount: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    date: _emberData.default.attr('datestamp'),
    comment: _emberData.default.attr('string'),
    // display value is used to visualize the correct value to the user
    // in CC -5 hours are actually +5 hours.
    // TODO: refactor value of hours in CC
    displayValue: Ember.computed('hours', {
      get: function get() {
        return this.get('hours') * -1;
      },
      set: function set(key, value) {
        this.set('hours', value * -1);
        return value;
      }
    })
  });

  _exports.default = _default;
});