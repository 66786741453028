define("coffeecup/routes/planner/absences/calendar", ["exports", "coffeecup/routes/_super-route", "coffeecup/utils/date", "coffeecup/config/environment", "moment"], function (_exports, _superRoute, _date, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    permissions: Ember.inject.service(),
    team: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    timeUnit: 'month',
    calendarUrlParamFormat: _environment.default.constants.URL_DATE_MONTH_FORMAT,
    queryParams: {
      month: {
        refreshModel: true
      }
    },
    titleToken: function titleToken() {
      return this.get('i18n').t('absence');
    },
    setupController: function setupController(controller, context
    /*, transition*/
    ) {
      controller.set('absenceTypes', context.absenceTypes);
      var absences = Ember.A([]);
      context.absences.forEach(function (a) {
        return absences.pushObject(a);
      });
      controller.set('absences', absences);
      var userHolidays = Ember.A([]);
      context.userHolidays.forEach(function (a) {
        return userHolidays.pushObject(a);
      });
      controller.set('userHolidays', userHolidays);
      var absenceRequests = Ember.A([]);
      context.absenceRequests.forEach(function (a) {
        return absenceRequests.pushObject(a);
      });
      controller.set('absenceRequests', absenceRequests);
      controller.set(this.get('timeUnit'), context[this.get('timeUnit')]);
      var absencesController = this.controllerFor('planner.absences');
      absencesController.set('currentAbsenceRoute', this.get('routeName'));

      this._super.apply(this, arguments);
    },
    _handleSocketNotifications: Ember.on('init', function () {
      var sailsSocket = this.get('sailsSocket');
      sailsSocket.on('absence.created', this, function (context) {
        var controller = this.get('controller');

        if (controller && context && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absences');

          if (!absences.findBy('id', id)) {
            this.store.find('absence', id).then(function (newEntry) {
              controller.send('absenceCreated', {
                entry: newEntry
              });
            });
          }
        }
      });
      sailsSocket.on('absence.destroyed', this, function (context) {
        var controller = this.get('controller');

        if (controller && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absences');
          var deletedEntry = absences.findBy('id', id);

          if (deletedEntry) {
            controller.send('absenceDeleted', {
              entry: deletedEntry
            });
          }
        }
      });
      sailsSocket.on('absence.updated', this, function (context) {
        var controller = this.get('controller');

        if (controller && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absences');
          var updatedEntry = absences.findBy('id', id);

          if (updatedEntry) {
            Ember.run.later(this, function () {
              controller.send('absenceUpdated', {
                entry: updatedEntry
              });
            }, 500);
          }
        }
      });
      sailsSocket.on('absencerequest.created', this, function (context) {
        var controller = this.get('controller');

        if (controller && context && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absenceRequests');

          if (!absences.findBy('id', id)) {
            this.store.find('absence-request', id).then(function (newEntry) {
              controller.send('absenceRequestCreated', {
                entry: newEntry
              });
            });
          }
        }
      });
      sailsSocket.on('absencerequest.destroyed', this, function (context) {
        var controller = this.get('controller');

        if (controller && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absenceRequests');
          var deletedEntry = absences.findBy('id', id);

          if (deletedEntry) {
            controller.send('absenceRequestDeleted', {
              entry: deletedEntry
            });
          }
        }
      });
      sailsSocket.on('absencerequest.updated', this, function (context) {
        var controller = this.get('controller');

        if (controller && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absenceRequests');
          var updatedEntry = absences.findBy('id', id);

          if (updatedEntry) {
            Ember.run.later(this, function () {
              controller.send('absenceRequestUpdated', {
                entry: updatedEntry
              });
            }, 500);
          }
        }
      });
    }),
    model: function model(params) {
      var unit = this.get('timeUnit');
      var from;

      if (!params[unit]) {
        from = this.get('clock.localDayAsUtcMoment').startOf(unit);
      } else {
        var month = (0, _date.validateDate)(params[unit], this.get('clock.now').format(_environment.default.constants.URL_DATE_MONTH_FORMAT));
        from = _moment.default.utc(month, _environment.default.constants.URL_DATE_MONTH_FORMAT).startOf(unit);
      }

      var to = from.clone().endOf(unit);
      var toWith10DaysPaddingJSON = to.clone().add(10, 'days').toJSON();
      var fromWith10DaysPaddingJSON = from.clone().subtract(10, 'days').toJSON();
      var toWith2YearsPaddingJSON = to.clone().add(2, 'years').toJSON();
      var fromWith2YearsPaddingJSON = from.clone().subtract(2, 'years').toJSON();
      var hashToResolve = {
        absenceTypes: this.store.peekAll('absence-type'),
        absences: this.store.query('absence', {
          sort: 'startDate DESC',
          limit: 10000,
          where: {
            or: [{
              startDate: {
                '>=': fromWith10DaysPaddingJSON,
                '<=': toWith10DaysPaddingJSON
              }
            }, {
              endDate: {
                '>=': fromWith10DaysPaddingJSON,
                '<=': toWith10DaysPaddingJSON
              }
            }]
          }
        }),
        absenceRequests: this.store.query('absence-request', {
          sort: 'startDate DESC',
          limit: 1000,
          where: {
            startDate: {
              '>=': fromWith10DaysPaddingJSON,
              '<=': toWith10DaysPaddingJSON
            },
            endDate: {
              '>=': fromWith10DaysPaddingJSON,
              '<=': toWith10DaysPaddingJSON
            },
            state: null
          }
        }),
        userHolidays: this.store.query('user-holiday', {
          sort: 'date ASC',
          limit: 1000,
          where: {
            date: {
              '>=': fromWith2YearsPaddingJSON,
              '<=': toWith2YearsPaddingJSON
            }
          }
        })
      };
      hashToResolve[this.get('timeUnit')] = from.format(this.get('calendarUrlParamFormat'));
      return Ember.RSVP.hash(hashToResolve);
    }
  });

  _exports.default = _default;
});