define("coffeecup/templates/components/stripe-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EMHD5woY",
    "block": "{\"symbols\":[\"progressElement\",\"@hideMaxValue\"],\"statements\":[[10,\"div\"],[14,0,\"stripe-progress-bar\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"stripe-progress-wrapper\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"progressElements\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"stripe-progress\\n          \",[30,[36,0],[[32,1,[\"intermediate\"]],\"alternative-background\"],null],\"\\n          \",[30,[36,0],[[32,1,[\"invisible\"]],\"bg-transparent\"],null]]]],[15,5,[30,[36,3],[[30,[36,1],[\"width: \",[32,0,[\"elementWidth\"]],\"%; \",[30,[36,2],[[32,1,[\"invisible\"]],[30,[36,1],[\"background-color:\",[32,0,[\"progressColor\"]],\";\"],null]],null]],null]],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"stripe-progress-values\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"start-value\"],[12],[2,\"\\n      \"],[1,[32,0,[\"minValue\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"current-value\"],[15,5,[30,[36,3],[[32,0,[\"currentValuePosition\"]]],null]],[12],[2,\"\\n      \"],[1,[32,0,[\"currentValue\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"end-value\"],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,0,[\"maxValue\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"unless\",\"html-safe\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/stripe-progress-bar.hbs"
    }
  });

  _exports.default = _default;
});