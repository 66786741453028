define("coffeecup/templates/components/modals/edit-time-entry/client-project-selected-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l7IcljhN",
    "block": "{\"symbols\":[\"@option\"],\"statements\":[[10,\"div\"],[14,0,\"trigger-selected-item\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"trigger-selected-item-client trigger-selected-item-row\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"client\",\"label\"]],[32,1,[\"client\",\"label\"]],[30,[36,0],[\"internalProject\"],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"trigger-selected-item-project trigger-selected-item-row\"],[12],[2,\"\\n    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/modals/edit-time-entry/client-project-selected-option.hbs"
    }
  });

  _exports.default = _default;
});