define("coffeecup/templates/components/planner/stretchable-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aPZbpwGe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],null,[[\"class\",\"triggerEvent\",\"content\",\"contentAsHTML\",\"side\"],[\"tooltipster\",\"custom\",[35,0],true,\"left\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tooltipContent\",\"tool-tipster\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/stretchable-overlay.hbs"
    }
  });

  _exports.default = _default;
});