define("coffeecup/instance-initializers/country", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'country',
        id: 0,
        attributes: {
          name: 'Afghanistan',
          code: 'AF'
        }
      }, {
        type: 'country',
        id: 1,
        attributes: {
          name: 'Åland Islands',
          code: 'AX'
        }
      }, {
        type: 'country',
        id: 2,
        attributes: {
          name: 'Albania',
          code: 'AL'
        }
      }, {
        type: 'country',
        id: 3,
        attributes: {
          name: 'Algeria',
          code: 'DZ'
        }
      }, {
        type: 'country',
        id: 4,
        attributes: {
          name: 'American Samoa',
          code: 'AS'
        }
      }, {
        type: 'country',
        id: 5,
        attributes: {
          name: 'Andorra',
          code: 'AD'
        }
      }, {
        type: 'country',
        id: 6,
        attributes: {
          name: 'Angola',
          code: 'AO'
        }
      }, {
        type: 'country',
        id: 7,
        attributes: {
          name: 'Anguilla',
          code: 'AI'
        }
      }, {
        type: 'country',
        id: 8,
        attributes: {
          name: 'Antarctica',
          code: 'AQ'
        }
      }, {
        type: 'country',
        id: 9,
        attributes: {
          name: 'Antigua and Barbuda',
          code: 'AG'
        }
      }, {
        type: 'country',
        id: 10,
        attributes: {
          name: 'Argentina',
          code: 'AR'
        }
      }, {
        type: 'country',
        id: 11,
        attributes: {
          name: 'Armenia',
          code: 'AM'
        }
      }, {
        type: 'country',
        id: 12,
        attributes: {
          name: 'Aruba',
          code: 'AW'
        }
      }, {
        type: 'country',
        id: 13,
        attributes: {
          name: 'Australia',
          code: 'AU'
        }
      }, {
        type: 'country',
        id: 14,
        attributes: {
          name: 'Austria',
          code: 'AT'
        }
      }, {
        type: 'country',
        id: 15,
        attributes: {
          name: 'Azerbaijan',
          code: 'AZ'
        }
      }, {
        type: 'country',
        id: 16,
        attributes: {
          name: 'Bahamas',
          code: 'BS'
        }
      }, {
        type: 'country',
        id: 17,
        attributes: {
          name: 'Bahrain',
          code: 'BH'
        }
      }, {
        type: 'country',
        id: 18,
        attributes: {
          name: 'Bangladesh',
          code: 'BD'
        }
      }, {
        type: 'country',
        id: 19,
        attributes: {
          name: 'Barbados',
          code: 'BB'
        }
      }, {
        type: 'country',
        id: 20,
        attributes: {
          name: 'Belarus',
          code: 'BY'
        }
      }, {
        type: 'country',
        id: 21,
        attributes: {
          name: 'Belgium',
          code: 'BE'
        }
      }, {
        type: 'country',
        id: 22,
        attributes: {
          name: 'Belize',
          code: 'BZ'
        }
      }, {
        type: 'country',
        id: 23,
        attributes: {
          name: 'Benin',
          code: 'BJ'
        }
      }, {
        type: 'country',
        id: 24,
        attributes: {
          name: 'Bermuda',
          code: 'BM'
        }
      }, {
        type: 'country',
        id: 25,
        attributes: {
          name: 'Bhutan',
          code: 'BT'
        }
      }, {
        type: 'country',
        id: 26,
        attributes: {
          name: 'Bolivia',
          code: 'BO'
        }
      }, {
        type: 'country',
        id: 27,
        attributes: {
          name: 'Bosnia and Herzegovina',
          code: 'BA'
        }
      }, {
        type: 'country',
        id: 28,
        attributes: {
          name: 'Botswana',
          code: 'BW'
        }
      }, {
        type: 'country',
        id: 29,
        attributes: {
          name: 'Bouvet Island',
          code: 'BV'
        }
      }, {
        type: 'country',
        id: 30,
        attributes: {
          name: 'Brazil',
          code: 'BR'
        }
      }, {
        type: 'country',
        id: 31,
        attributes: {
          name: 'British Indian Ocean Territory',
          code: 'IO'
        }
      }, {
        type: 'country',
        id: 32,
        attributes: {
          name: 'Brunei Darussalam',
          code: 'BN'
        }
      }, {
        type: 'country',
        id: 33,
        attributes: {
          name: 'Bulgaria',
          code: 'BG'
        }
      }, {
        type: 'country',
        id: 34,
        attributes: {
          name: 'Burkina Faso',
          code: 'BF'
        }
      }, {
        type: 'country',
        id: 35,
        attributes: {
          name: 'Burundi',
          code: 'BI'
        }
      }, {
        type: 'country',
        id: 36,
        attributes: {
          name: 'Cambodia',
          code: 'KH'
        }
      }, {
        type: 'country',
        id: 37,
        attributes: {
          name: 'Cameroon',
          code: 'CM'
        }
      }, {
        type: 'country',
        id: 38,
        attributes: {
          name: 'Canada',
          code: 'CA'
        }
      }, {
        type: 'country',
        id: 39,
        attributes: {
          name: 'Cape Verde',
          code: 'CV'
        }
      }, {
        type: 'country',
        id: 40,
        attributes: {
          name: 'Cayman Islands',
          code: 'KY'
        }
      }, {
        type: 'country',
        id: 41,
        attributes: {
          name: 'Central African Republic',
          code: 'CF'
        }
      }, {
        type: 'country',
        id: 42,
        attributes: {
          name: 'Chad',
          code: 'TD'
        }
      }, {
        type: 'country',
        id: 43,
        attributes: {
          name: 'Chile',
          code: 'CL'
        }
      }, {
        type: 'country',
        id: 44,
        attributes: {
          name: 'China',
          code: 'CN'
        }
      }, {
        type: 'country',
        id: 45,
        attributes: {
          name: 'Christmas Island',
          code: 'CX'
        }
      }, {
        type: 'country',
        id: 46,
        attributes: {
          name: 'Cocos (Keeling) Islands',
          code: 'CC'
        }
      }, {
        type: 'country',
        id: 47,
        attributes: {
          name: 'Colombia',
          code: 'CO'
        }
      }, {
        type: 'country',
        id: 48,
        attributes: {
          name: 'Comoros',
          code: 'KM'
        }
      }, {
        type: 'country',
        id: 49,
        attributes: {
          name: 'Congo',
          code: 'CG'
        }
      }, {
        type: 'country',
        id: 50,
        attributes: {
          name: 'Congo, The Democratic Republic of the',
          code: 'CD'
        }
      }, {
        type: 'country',
        id: 51,
        attributes: {
          name: 'Cook Islands',
          code: 'CK'
        }
      }, {
        type: 'country',
        id: 52,
        attributes: {
          name: 'Costa Rica',
          code: 'CR'
        }
      }, {
        type: 'country',
        id: 53,
        attributes: {
          name: "Cote D'Ivoire",
          code: 'CI'
        }
      }, {
        type: 'country',
        id: 54,
        attributes: {
          name: 'Croatia',
          code: 'HR'
        }
      }, {
        type: 'country',
        id: 55,
        attributes: {
          name: 'Cuba',
          code: 'CU'
        }
      }, {
        type: 'country',
        id: 56,
        attributes: {
          name: 'Cyprus',
          code: 'CY'
        }
      }, {
        type: 'country',
        id: 57,
        attributes: {
          name: 'Czech Republic',
          code: 'CZ'
        }
      }, {
        type: 'country',
        id: 58,
        attributes: {
          name: 'Denmark',
          code: 'DK'
        }
      }, {
        type: 'country',
        id: 59,
        attributes: {
          name: 'Djibouti',
          code: 'DJ'
        }
      }, {
        type: 'country',
        id: 60,
        attributes: {
          name: 'Dominica',
          code: 'DM'
        }
      }, {
        type: 'country',
        id: 61,
        attributes: {
          name: 'Dominican Republic',
          code: 'DO'
        }
      }, {
        type: 'country',
        id: 62,
        attributes: {
          name: 'Ecuador',
          code: 'EC'
        }
      }, {
        type: 'country',
        id: 63,
        attributes: {
          name: 'Egypt',
          code: 'EG'
        }
      }, {
        type: 'country',
        id: 64,
        attributes: {
          name: 'El Salvador',
          code: 'SV'
        }
      }, {
        type: 'country',
        id: 65,
        attributes: {
          name: 'Equatorial Guinea',
          code: 'GQ'
        }
      }, {
        type: 'country',
        id: 66,
        attributes: {
          name: 'Eritrea',
          code: 'ER'
        }
      }, {
        type: 'country',
        id: 67,
        attributes: {
          name: 'Estonia',
          code: 'EE'
        }
      }, {
        type: 'country',
        id: 68,
        attributes: {
          name: 'Ethiopia',
          code: 'ET'
        }
      }, {
        type: 'country',
        id: 69,
        attributes: {
          name: 'Falkland Islands (Malvinas)',
          code: 'FK'
        }
      }, {
        type: 'country',
        id: 70,
        attributes: {
          name: 'Faroe Islands',
          code: 'FO'
        }
      }, {
        type: 'country',
        id: 71,
        attributes: {
          name: 'Fiji',
          code: 'FJ'
        }
      }, {
        type: 'country',
        id: 72,
        attributes: {
          name: 'Finland',
          code: 'FI'
        }
      }, {
        type: 'country',
        id: 73,
        attributes: {
          name: 'France',
          code: 'FR'
        }
      }, {
        type: 'country',
        id: 74,
        attributes: {
          name: 'French Guiana',
          code: 'GF'
        }
      }, {
        type: 'country',
        id: 75,
        attributes: {
          name: 'French Polynesia',
          code: 'PF'
        }
      }, {
        type: 'country',
        id: 76,
        attributes: {
          name: 'French Southern Territories',
          code: 'TF'
        }
      }, {
        type: 'country',
        id: 77,
        attributes: {
          name: 'Gabon',
          code: 'GA'
        }
      }, {
        type: 'country',
        id: 78,
        attributes: {
          name: 'Gambia',
          code: 'GM'
        }
      }, {
        type: 'country',
        id: 79,
        attributes: {
          name: 'Georgia',
          code: 'GE'
        }
      }, {
        type: 'country',
        id: 80,
        attributes: {
          name: 'Germany',
          code: 'DE'
        }
      }, {
        type: 'country',
        id: 81,
        attributes: {
          name: 'Ghana',
          code: 'GH'
        }
      }, {
        type: 'country',
        id: 82,
        attributes: {
          name: 'Gibraltar',
          code: 'GI'
        }
      }, {
        type: 'country',
        id: 83,
        attributes: {
          name: 'Greece',
          code: 'GR'
        }
      }, {
        type: 'country',
        id: 84,
        attributes: {
          name: 'Greenland',
          code: 'GL'
        }
      }, {
        type: 'country',
        id: 85,
        attributes: {
          name: 'Grenada',
          code: 'GD'
        }
      }, {
        type: 'country',
        id: 86,
        attributes: {
          name: 'Guadeloupe',
          code: 'GP'
        }
      }, {
        type: 'country',
        id: 87,
        attributes: {
          name: 'Guam',
          code: 'GU'
        }
      }, {
        type: 'country',
        id: 88,
        attributes: {
          name: 'Guatemala',
          code: 'GT'
        }
      }, {
        type: 'country',
        id: 89,
        attributes: {
          name: 'Guernsey',
          code: 'GG'
        }
      }, {
        type: 'country',
        id: 90,
        attributes: {
          name: 'Guinea',
          code: 'GN'
        }
      }, {
        type: 'country',
        id: 91,
        attributes: {
          name: 'Guinea-Bissau',
          code: 'GW'
        }
      }, {
        type: 'country',
        id: 92,
        attributes: {
          name: 'Guyana',
          code: 'GY'
        }
      }, {
        type: 'country',
        id: 93,
        attributes: {
          name: 'Haiti',
          code: 'HT'
        }
      }, {
        type: 'country',
        id: 94,
        attributes: {
          name: 'Heard Island and Mcdonald Islands',
          code: 'HM'
        }
      }, {
        type: 'country',
        id: 95,
        attributes: {
          name: 'Holy See (Vatican City State)',
          code: 'VA'
        }
      }, {
        type: 'country',
        id: 96,
        attributes: {
          name: 'Honduras',
          code: 'HN'
        }
      }, {
        type: 'country',
        id: 97,
        attributes: {
          name: 'Hong Kong',
          code: 'HK'
        }
      }, {
        type: 'country',
        id: 98,
        attributes: {
          name: 'Hungary',
          code: 'HU'
        }
      }, {
        type: 'country',
        id: 99,
        attributes: {
          name: 'Iceland',
          code: 'IS'
        }
      }, {
        type: 'country',
        id: 100,
        attributes: {
          name: 'India',
          code: 'IN'
        }
      }, {
        type: 'country',
        id: 101,
        attributes: {
          name: 'Indonesia',
          code: 'ID'
        }
      }, {
        type: 'country',
        id: 102,
        attributes: {
          name: 'Iran, Islamic Republic Of',
          code: 'IR'
        }
      }, {
        type: 'country',
        id: 103,
        attributes: {
          name: 'Iraq',
          code: 'IQ'
        }
      }, {
        type: 'country',
        id: 104,
        attributes: {
          name: 'Ireland',
          code: 'IE'
        }
      }, {
        type: 'country',
        id: 105,
        attributes: {
          name: 'Isle of Man',
          code: 'IM'
        }
      }, {
        type: 'country',
        id: 106,
        attributes: {
          name: 'Israel',
          code: 'IL'
        }
      }, {
        type: 'country',
        id: 107,
        attributes: {
          name: 'Italy',
          code: 'IT'
        }
      }, {
        type: 'country',
        id: 108,
        attributes: {
          name: 'Jamaica',
          code: 'JM'
        }
      }, {
        type: 'country',
        id: 109,
        attributes: {
          name: 'Japan',
          code: 'JP'
        }
      }, {
        type: 'country',
        id: 110,
        attributes: {
          name: 'Jersey',
          code: 'JE'
        }
      }, {
        type: 'country',
        id: 111,
        attributes: {
          name: 'Jordan',
          code: 'JO'
        }
      }, {
        type: 'country',
        id: 112,
        attributes: {
          name: 'Kazakhstan',
          code: 'KZ'
        }
      }, {
        type: 'country',
        id: 113,
        attributes: {
          name: 'Kenya',
          code: 'KE'
        }
      }, {
        type: 'country',
        id: 114,
        attributes: {
          name: 'Kiribati',
          code: 'KI'
        }
      }, {
        type: 'country',
        id: 115,
        attributes: {
          name: "Korea, Democratic People's Republic of",
          code: 'KP'
        }
      }, {
        type: 'country',
        id: 116,
        attributes: {
          name: 'Korea, Republic of',
          code: 'KR'
        }
      }, {
        type: 'country',
        id: 117,
        attributes: {
          name: 'Kuwait',
          code: 'KW'
        }
      }, {
        type: 'country',
        id: 118,
        attributes: {
          name: 'Kyrgyzstan',
          code: 'KG'
        }
      }, {
        type: 'country',
        id: 119,
        attributes: {
          name: "Lao People's Democratic Republic",
          code: 'LA'
        }
      }, {
        type: 'country',
        id: 120,
        attributes: {
          name: 'Latvia',
          code: 'LV'
        }
      }, {
        type: 'country',
        id: 121,
        attributes: {
          name: 'Lebanon',
          code: 'LB'
        }
      }, {
        type: 'country',
        id: 122,
        attributes: {
          name: 'Lesotho',
          code: 'LS'
        }
      }, {
        type: 'country',
        id: 123,
        attributes: {
          name: 'Liberia',
          code: 'LR'
        }
      }, {
        type: 'country',
        id: 124,
        attributes: {
          name: 'Libyan Arab Jamahiriya',
          code: 'LY'
        }
      }, {
        type: 'country',
        id: 125,
        attributes: {
          name: 'Liechtenstein',
          code: 'LI'
        }
      }, {
        type: 'country',
        id: 126,
        attributes: {
          name: 'Lithuania',
          code: 'LT'
        }
      }, {
        type: 'country',
        id: 127,
        attributes: {
          name: 'Luxembourg',
          code: 'LU'
        }
      }, {
        type: 'country',
        id: 128,
        attributes: {
          name: 'Macao',
          code: 'MO'
        }
      }, {
        type: 'country',
        id: 129,
        attributes: {
          name: 'Macedonia, The Former Yugoslav Republic of',
          code: 'MK'
        }
      }, {
        type: 'country',
        id: 130,
        attributes: {
          name: 'Madagascar',
          code: 'MG'
        }
      }, {
        type: 'country',
        id: 131,
        attributes: {
          name: 'Malawi',
          code: 'MW'
        }
      }, {
        type: 'country',
        id: 132,
        attributes: {
          name: 'Malaysia',
          code: 'MY'
        }
      }, {
        type: 'country',
        id: 133,
        attributes: {
          name: 'Maldives',
          code: 'MV'
        }
      }, {
        type: 'country',
        id: 134,
        attributes: {
          name: 'Mali',
          code: 'ML'
        }
      }, {
        type: 'country',
        id: 135,
        attributes: {
          name: 'Malta',
          code: 'MT'
        }
      }, {
        type: 'country',
        id: 136,
        attributes: {
          name: 'Marshall Islands',
          code: 'MH'
        }
      }, {
        type: 'country',
        id: 137,
        attributes: {
          name: 'Martinique',
          code: 'MQ'
        }
      }, {
        type: 'country',
        id: 138,
        attributes: {
          name: 'Mauritania',
          code: 'MR'
        }
      }, {
        type: 'country',
        id: 139,
        attributes: {
          name: 'Mauritius',
          code: 'MU'
        }
      }, {
        type: 'country',
        id: 140,
        attributes: {
          name: 'Mayotte',
          code: 'YT'
        }
      }, {
        type: 'country',
        id: 141,
        attributes: {
          name: 'Mexico',
          code: 'MX'
        }
      }, {
        type: 'country',
        id: 142,
        attributes: {
          name: 'Micronesia, Federated States of',
          code: 'FM'
        }
      }, {
        type: 'country',
        id: 143,
        attributes: {
          name: 'Moldova, Republic of',
          code: 'MD'
        }
      }, {
        type: 'country',
        id: 144,
        attributes: {
          name: 'Monaco',
          code: 'MC'
        }
      }, {
        type: 'country',
        id: 145,
        attributes: {
          name: 'Mongolia',
          code: 'MN'
        }
      }, {
        type: 'country',
        id: 146,
        attributes: {
          name: 'Montserrat',
          code: 'MS'
        }
      }, {
        type: 'country',
        id: 147,
        attributes: {
          name: 'Morocco',
          code: 'MA'
        }
      }, {
        type: 'country',
        id: 148,
        attributes: {
          name: 'Mozambique',
          code: 'MZ'
        }
      }, {
        type: 'country',
        id: 149,
        attributes: {
          name: 'Myanmar',
          code: 'MM'
        }
      }, {
        type: 'country',
        id: 150,
        attributes: {
          name: 'Namibia',
          code: 'NA'
        }
      }, {
        type: 'country',
        id: 151,
        attributes: {
          name: 'Nauru',
          code: 'NR'
        }
      }, {
        type: 'country',
        id: 152,
        attributes: {
          name: 'Nepal',
          code: 'NP'
        }
      }, {
        type: 'country',
        id: 153,
        attributes: {
          name: 'Netherlands',
          code: 'NL'
        }
      }, {
        type: 'country',
        id: 154,
        attributes: {
          name: 'Netherlands Antilles',
          code: 'AN'
        }
      }, {
        type: 'country',
        id: 155,
        attributes: {
          name: 'New Caledonia',
          code: 'NC'
        }
      }, {
        type: 'country',
        id: 156,
        attributes: {
          name: 'New Zealand',
          code: 'NZ'
        }
      }, {
        type: 'country',
        id: 157,
        attributes: {
          name: 'Nicaragua',
          code: 'NI'
        }
      }, {
        type: 'country',
        id: 158,
        attributes: {
          name: 'Niger',
          code: 'NE'
        }
      }, {
        type: 'country',
        id: 159,
        attributes: {
          name: 'Nigeria',
          code: 'NG'
        }
      }, {
        type: 'country',
        id: 160,
        attributes: {
          name: 'Niue',
          code: 'NU'
        }
      }, {
        type: 'country',
        id: 161,
        attributes: {
          name: 'Norfolk Island',
          code: 'NF'
        }
      }, {
        type: 'country',
        id: 162,
        attributes: {
          name: 'Northern Mariana Islands',
          code: 'MP'
        }
      }, {
        type: 'country',
        id: 163,
        attributes: {
          name: 'Norway',
          code: 'NO'
        }
      }, {
        type: 'country',
        id: 164,
        attributes: {
          name: 'Oman',
          code: 'OM'
        }
      }, {
        type: 'country',
        id: 165,
        attributes: {
          name: 'Pakistan',
          code: 'PK'
        }
      }, {
        type: 'country',
        id: 166,
        attributes: {
          name: 'Palau',
          code: 'PW'
        }
      }, {
        type: 'country',
        id: 167,
        attributes: {
          name: 'Palestinian Territory, Occupied',
          code: 'PS'
        }
      }, {
        type: 'country',
        id: 168,
        attributes: {
          name: 'Panama',
          code: 'PA'
        }
      }, {
        type: 'country',
        id: 169,
        attributes: {
          name: 'Papua New Guinea',
          code: 'PG'
        }
      }, {
        type: 'country',
        id: 170,
        attributes: {
          name: 'Paraguay',
          code: 'PY'
        }
      }, {
        type: 'country',
        id: 171,
        attributes: {
          name: 'Peru',
          code: 'PE'
        }
      }, {
        type: 'country',
        id: 172,
        attributes: {
          name: 'Philippines',
          code: 'PH'
        }
      }, {
        type: 'country',
        id: 173,
        attributes: {
          name: 'Pitcairn',
          code: 'PN'
        }
      }, {
        type: 'country',
        id: 174,
        attributes: {
          name: 'Poland',
          code: 'PL'
        }
      }, {
        type: 'country',
        id: 175,
        attributes: {
          name: 'Portugal',
          code: 'PT'
        }
      }, {
        type: 'country',
        id: 176,
        attributes: {
          name: 'Puerto Rico',
          code: 'PR'
        }
      }, {
        type: 'country',
        id: 177,
        attributes: {
          name: 'Qatar',
          code: 'QA'
        }
      }, {
        type: 'country',
        id: 178,
        attributes: {
          name: 'Reunion',
          code: 'RE'
        }
      }, {
        type: 'country',
        id: 179,
        attributes: {
          name: 'Romania',
          code: 'RO'
        }
      }, {
        type: 'country',
        id: 180,
        attributes: {
          name: 'Russian Federation',
          code: 'RU'
        }
      }, {
        type: 'country',
        id: 181,
        attributes: {
          name: 'RWANDA',
          code: 'RW'
        }
      }, {
        type: 'country',
        id: 182,
        attributes: {
          name: 'Saint Helena',
          code: 'SH'
        }
      }, {
        type: 'country',
        id: 183,
        attributes: {
          name: 'Saint Kitts and Nevis',
          code: 'KN'
        }
      }, {
        type: 'country',
        id: 184,
        attributes: {
          name: 'Saint Lucia',
          code: 'LC'
        }
      }, {
        type: 'country',
        id: 185,
        attributes: {
          name: 'Saint Pierre and Miquelon',
          code: 'PM'
        }
      }, {
        type: 'country',
        id: 186,
        attributes: {
          name: 'Saint Vincent and the Grenadines',
          code: 'VC'
        }
      }, {
        type: 'country',
        id: 187,
        attributes: {
          name: 'Samoa',
          code: 'WS'
        }
      }, {
        type: 'country',
        id: 188,
        attributes: {
          name: 'San Marino',
          code: 'SM'
        }
      }, {
        type: 'country',
        id: 189,
        attributes: {
          name: 'Sao Tome and Principe',
          code: 'ST'
        }
      }, {
        type: 'country',
        id: 190,
        attributes: {
          name: 'Saudi Arabia',
          code: 'SA'
        }
      }, {
        type: 'country',
        id: 191,
        attributes: {
          name: 'Senegal',
          code: 'SN'
        }
      }, {
        type: 'country',
        id: 192,
        attributes: {
          name: 'Serbia and Montenegro',
          code: 'CS'
        }
      }, {
        type: 'country',
        id: 193,
        attributes: {
          name: 'Seychelles',
          code: 'SC'
        }
      }, {
        type: 'country',
        id: 194,
        attributes: {
          name: 'Sierra Leone',
          code: 'SL'
        }
      }, {
        type: 'country',
        id: 195,
        attributes: {
          name: 'Singapore',
          code: 'SG'
        }
      }, {
        type: 'country',
        id: 196,
        attributes: {
          name: 'Slovakia',
          code: 'SK'
        }
      }, {
        type: 'country',
        id: 197,
        attributes: {
          name: 'Slovenia',
          code: 'SI'
        }
      }, {
        type: 'country',
        id: 198,
        attributes: {
          name: 'Solomon Islands',
          code: 'SB'
        }
      }, {
        type: 'country',
        id: 199,
        attributes: {
          name: 'Somalia',
          code: 'SO'
        }
      }, {
        type: 'country',
        id: 200,
        attributes: {
          name: 'South Africa',
          code: 'ZA'
        }
      }, {
        type: 'country',
        id: 201,
        attributes: {
          name: 'South Georgia and the South Sandwich Islands',
          code: 'GS'
        }
      }, {
        type: 'country',
        id: 202,
        attributes: {
          name: 'Spain',
          code: 'ES'
        }
      }, {
        type: 'country',
        id: 203,
        attributes: {
          name: 'Sri Lanka',
          code: 'LK'
        }
      }, {
        type: 'country',
        id: 204,
        attributes: {
          name: 'Sudan',
          code: 'SD'
        }
      }, {
        type: 'country',
        id: 205,
        attributes: {
          name: 'Suriname',
          code: 'SR'
        }
      }, {
        type: 'country',
        id: 206,
        attributes: {
          name: 'Svalbard and Jan Mayen',
          code: 'SJ'
        }
      }, {
        type: 'country',
        id: 207,
        attributes: {
          name: 'Swaziland',
          code: 'SZ'
        }
      }, {
        type: 'country',
        id: 208,
        attributes: {
          name: 'Sweden',
          code: 'SE'
        }
      }, {
        type: 'country',
        id: 209,
        attributes: {
          name: 'Switzerland',
          code: 'CH'
        }
      }, {
        type: 'country',
        id: 210,
        attributes: {
          name: 'Syrian Arab Republic',
          code: 'SY'
        }
      }, {
        type: 'country',
        id: 211,
        attributes: {
          name: 'Taiwan, Province of China',
          code: 'TW'
        }
      }, {
        type: 'country',
        id: 212,
        attributes: {
          name: 'Tajikistan',
          code: 'TJ'
        }
      }, {
        type: 'country',
        id: 213,
        attributes: {
          name: 'Tanzania, United Republic of',
          code: 'TZ'
        }
      }, {
        type: 'country',
        id: 214,
        attributes: {
          name: 'Thailand',
          code: 'TH'
        }
      }, {
        type: 'country',
        id: 215,
        attributes: {
          name: 'Timor-Leste',
          code: 'TL'
        }
      }, {
        type: 'country',
        id: 216,
        attributes: {
          name: 'Togo',
          code: 'TG'
        }
      }, {
        type: 'country',
        id: 217,
        attributes: {
          name: 'Tokelau',
          code: 'TK'
        }
      }, {
        type: 'country',
        id: 218,
        attributes: {
          name: 'Tonga',
          code: 'TO'
        }
      }, {
        type: 'country',
        id: 219,
        attributes: {
          name: 'Trinidad and Tobago',
          code: 'TT'
        }
      }, {
        type: 'country',
        id: 220,
        attributes: {
          name: 'Tunisia',
          code: 'TN'
        }
      }, {
        type: 'country',
        id: 221,
        attributes: {
          name: 'Turkey',
          code: 'TR'
        }
      }, {
        type: 'country',
        id: 222,
        attributes: {
          name: 'Turkmenistan',
          code: 'TM'
        }
      }, {
        type: 'country',
        id: 223,
        attributes: {
          name: 'Turks and Caicos Islands',
          code: 'TC'
        }
      }, {
        type: 'country',
        id: 224,
        attributes: {
          name: 'Tuvalu',
          code: 'TV'
        }
      }, {
        type: 'country',
        id: 225,
        attributes: {
          name: 'Uganda',
          code: 'UG'
        }
      }, {
        type: 'country',
        id: 226,
        attributes: {
          name: 'Ukraine',
          code: 'UA'
        }
      }, {
        type: 'country',
        id: 227,
        attributes: {
          name: 'United Arab Emirates',
          code: 'AE'
        }
      }, {
        type: 'country',
        id: 228,
        attributes: {
          name: 'United Kingdom',
          code: 'GB'
        }
      }, {
        type: 'country',
        id: 229,
        attributes: {
          name: 'United States',
          code: 'US'
        }
      }, {
        type: 'country',
        id: 230,
        attributes: {
          name: 'United States Minor Outlying Islands',
          code: 'UM'
        }
      }, {
        type: 'country',
        id: 231,
        attributes: {
          name: 'Uruguay',
          code: 'UY'
        }
      }, {
        type: 'country',
        id: 232,
        attributes: {
          name: 'Uzbekistan',
          code: 'UZ'
        }
      }, {
        type: 'country',
        id: 233,
        attributes: {
          name: 'Vanuatu',
          code: 'VU'
        }
      }, {
        type: 'country',
        id: 234,
        attributes: {
          name: 'Venezuela',
          code: 'VE'
        }
      }, {
        type: 'country',
        id: 235,
        attributes: {
          name: 'Viet Nam',
          code: 'VN'
        }
      }, {
        type: 'country',
        id: 236,
        attributes: {
          name: 'Virgin Islands, British',
          code: 'VG'
        }
      }, {
        type: 'country',
        id: 237,
        attributes: {
          name: 'Virgin Islands, U.S.',
          code: 'VI'
        }
      }, {
        type: 'country',
        id: 238,
        attributes: {
          name: 'Wallis and Futuna',
          code: 'WF'
        }
      }, {
        type: 'country',
        id: 239,
        attributes: {
          name: 'Western Sahara',
          code: 'EH'
        }
      }, {
        type: 'country',
        id: 240,
        attributes: {
          name: 'Yemen',
          code: 'YE'
        }
      }, {
        type: 'country',
        id: 241,
        attributes: {
          name: 'Zambia',
          code: 'ZM'
        }
      }, {
        type: 'country',
        id: 242,
        attributes: {
          name: 'Zimbabwe',
          code: 'ZW'
        }
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'country',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});