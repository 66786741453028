define("coffeecup/routes/account/company", ["exports", "coffeecup/routes/_super-form-route"], function (_exports, _superFormRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superFormRoute.default.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('generalSettings');
    },
    setupController: function setupController(controller, context, tranistion) {
      controller.set('model', this.get('accountService.accountSettings'));
      controller.set('existingTags', this.store.peekAll('tag'));
      controller.set('existingTagsObjects', this.store.peekAll('tag').toArray());
      /* static models */

      controller.set('timezones', this.store.peekAll('timezone'));
      controller.set('languages', this.store.peekAll('language'));
      controller.set('beginningOfWeeks', this.store.peekAll('beginning-of-week'));
      controller.set('dateFormats', this.store.peekAll('date-format'));
      controller.set('currencies', this.store.peekAll('currency'));
      controller.set('wordingOptions', this.store.peekAll('wording-option'));
      controller.set('currencyFormats', this.store.peekAll('currency-format')).toArray();
      controller.set('numberFormats', this.store.peekAll('number-format'));
      controller.set('timeRoundingTypes', this.store.peekAll('time-rounding-type'));
      controller.set('countries', this.store.peekAll('country'));
    }
  });

  _exports.default = _default;
});