define("coffeecup/routes/planner/projects/milestone", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    templateName: 'planner.milestone',
    viewport: Ember.inject.service('planner.viewport'),
    activeModal: Ember.inject.service('planner.active-modal'),
    model: function model(params, transition) {
      if (params && params.milestone_id) {
        transition.send('transitionToPlanner'); // TODO: enable deeplinking, note: transformDataIntoStreams must be called in order to have user.absences populated
        // return this.store.findRecord('planner-assignment', params.assignment_id);
      }
    },
    actions: {
      transitionToPlanner: function transitionToPlanner() {
        var _this$controller;

        this.get('activeModal').setMilestone(null);
        (_this$controller = this.controller) === null || _this$controller === void 0 ? void 0 : _this$controller.set('options', null);
        this.transitionTo('planner.projects');
      },
      afterSaveMilestone: function afterSaveMilestone(milestone) {
        this.controllerFor('planner.projects').send('afterSaveMilestone', milestone);
      },
      deleteMilestone: function deleteMilestone(milestone, project) {
        this.controllerFor('planner.projects').send('deleteMilestone', milestone, project);
      }
    }
  });

  _exports.default = _default;
});