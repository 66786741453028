define("coffeecup/templates/time-entries/tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qovvuy8h",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"time-entries\"],[12],[2,\"\\n  \"],[19,\"time-entries/tracking/-header\",[]],[2,\"\\n  \"],[19,\"time-entries/tracking/-body\",[]],[2,\"\\n  \"],[19,\"time-entries/tracking/-footer\",[]],[2,\"\\n\"],[13],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":true,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/time-entries/tracking.hbs"
    }
  });

  _exports.default = _default;
});