define("coffeecup/templates/components/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vAQmAWOH",
    "block": "{\"symbols\":[\"@user\",\"@customLink\",\"@size\"],\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"isImageInitials\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"initials-avatar\",[],[[\"@name\",\"@color\",\"@size\"],[[32,1,[\"label\"]],[32,1,[\"color\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[14,\"data-test\",\"current-user\"],[15,\"alt\",[31,[[32,1,[\"label\"]]]]],[15,\"data-test-email\",[31,[[32,1,[\"email\"]]]]],[15,0,[31,[[32,0,[\"imageSizeClass\"]]]]],[15,\"src\",[31,[[30,[36,0],[[32,2],[32,0,[\"imageLink\"]]],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/user-avatar.hbs"
    }
  });

  _exports.default = _default;
});