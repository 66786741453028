define("coffeecup/mixins/cascade-activation", ["exports", "coffeecup/config/environment", "coffeecup/mixins/cascade-archivation"], function (_exports, _environment, _cascadeArchivation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for Models to support cascading activation.
   */
  var _default = Ember.Mixin.create(_cascadeArchivation.default, {
    status: _environment.default.constants.STATUS_ENABLED,
    invalidState: 'isActive',
    filterStatus: 'isInactive',
    optionKey: 'cascadeActivation'
  });

  _exports.default = _default;
});