define("coffeecup/templates/components/planner/allocated-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HCF5nflR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"allocated-info\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"allocated-amount\"],[12],[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isOvertime\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"icomoon-flash\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/allocated-frame.hbs"
    }
  });

  _exports.default = _default;
});