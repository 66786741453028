define("coffeecup/templates/components/select-items/time-entry-comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C+0vGr9i",
    "block": "{\"symbols\":[],\"statements\":[],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/select-items/time-entry-comment.hbs"
    }
  });

  _exports.default = _default;
});