define("coffeecup/utils/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDateValid = isDateValid;
  _exports.validateDate = validateDate;
  _exports.removeTimeZone = removeTimeZone;
  _exports.isInRange = isInRange;
  _exports.isRangeWithinRange = isRangeWithinRange;
  _exports.isSameDay = isSameDay;
  _exports.numberOfBusinessDays = numberOfBusinessDays;
  _exports.numberOfDays = numberOfDays;
  _exports.numberOfWeeks = numberOfWeeks;
  _exports.shift = shift;
  _exports.eachDay = eachDay;
  _exports.eachBusinessDay = eachBusinessDay;
  _exports.enumerateDaysBetweenDates = enumerateDaysBetweenDates;

  //utils/date.js
  function isDateValid(date) {
    return moment.isMoment(date) && date.isValid();
  }

  function validateDate(date, defaultDate) {
    if (isDateValid(moment(date))) {
      return date;
    } else {
      return defaultDate;
    }
  }
  /**
   * removes all timezones and offsets from a moment object
   * @param {Moment}
   * @return {Moment}
   */


  function removeTimeZone(date) {
    // window.cc_timer = window.cc_timer || new Date().getTime();
    // window.cc_timer_end =  (new Date().getTime() - window.cc_timer);
    // Remove proxy from object if necessary
    if (date.content) {
      date = date.content;
    }

    if (date.isDateAligned && date.isDateAligned()) {
      return date;
    } // return moment(date);


    var formattedDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
    var utcDate = moment.utc(formattedDate);

    if (!isDateValid(utcDate)) {
      formattedDate = moment(date).format('YYYY-MM-DD');
      utcDate = moment.utc(formattedDate);
    }

    if (!isDateValid(utcDate)) {
      utcDate = moment.utc(date);
    }

    return utcDate;
  }
  /**
   *
   * @param {object} date Moment
   * @param {object} startDate Moment
   * @param {object} endDate Moment
   *
   * @return {boolean} true if date (e.g. 14.1.2018 is between 13.1.2018 and 14.1.2018)
   */


  function isInRange(date, startDate, endDate) {
    if (isDateValid(date) && isDateValid(startDate) && isDateValid(endDate)) {
      // if all dates have zero hours/minutes/seconds, we can just compare directly
      if (date.isDateAligned() && startDate.isDateAligned() && endDate.isDateAligned()) {
        return date >= startDate && date <= endDate;
      }

      var _date = removeTimeZone(date);

      var _startDate = removeTimeZone(startDate);

      var _endDate = removeTimeZone(endDate);

      if (_date.isSameOrAfter(_startDate, 'day') && _date.isSameOrBefore(_endDate, 'day')) {
        return true;
      }
    }

    return false;
  }
  /**
   *
   * @param {object} startDate Moment
   * @param {object} endDate Moment
   * @param {object} startDate2 Moment
   * @param {object} endDate2 Moment
   *
   * @return {boolean} true if startDate OR endDate are in range of startDate2 AND endDate2
   */


  function isRangeWithinRange(startDate, endDate, startDate2, endDate2) {
    if (isDateValid(startDate) && isDateValid(endDate) && isDateValid(startDate2) && isDateValid(endDate2)) {
      var _startDate = removeTimeZone(startDate);

      var _endDate = removeTimeZone(endDate);

      var _startDate2 = removeTimeZone(startDate2);

      var _endDate2 = removeTimeZone(endDate2);

      if (isInRange(_startDate, _startDate2, _endDate2)) return true;
      if (isInRange(_endDate, _startDate2, _endDate2)) return true;
      if (_startDate.isBefore(_startDate2, 'day') && _endDate.isAfter(_endDate2, 'day')) return true;
    }

    return false;
  }

  function isSameDay(date1, date2) {
    if (isDateValid(date1) && isDateValid(date2)) {
      var _date1 = removeTimeZone(date1);

      var _date2 = removeTimeZone(date2);

      return _date1.dateAlignedSame(_date2, 'day');
    }

    return false;
  }
  /**
   *
   * @param {object} startDate Moment
   * @param {object} endDate Moment
   * @returns {Integer|null} number of work days between two dates
   */


  function numberOfBusinessDays(startDate, endDate) {
    if (!isDateValid(startDate) || !isDateValid(endDate)) {
      return null;
    }

    var _endDate = removeTimeZone(endDate);

    var _startDate = removeTimeZone(startDate);

    return _endDate.businessDiff(_startDate);
  }
  /**
   *
   * @param {object} startDate Moment
   * @param {object} endDate Moment
   * @returns {Integer|null} number of days between two dates
   */


  function numberOfDays(startDate, endDate) {
    if (!isDateValid(startDate) || !isDateValid(endDate)) {
      return null;
    }

    var _endDate = removeTimeZone(endDate);

    var _startDate = removeTimeZone(startDate);

    return _endDate.diff(_startDate, 'days');
  }
  /**
   *
   * @param {object} startDate Moment
   * @param {object} endDate Moment
   * @returns {Integer|null} number of started weeks between two dates
   */


  function numberOfWeeks(startDate, endDate) {
    if (!isDateValid(startDate) || !isDateValid(endDate)) {
      return null;
    }

    var _endDate = removeTimeZone(endDate);

    var _startDate = removeTimeZone(startDate);

    return Math.round(_endDate.diff(_startDate, 'weeks', true));
  }
  /**
   * shifts a day by giving working days
   *
   * @param {object} date Moment Date Object
   * @param {Integer} days, accepts negative values to substract days
   * @param {Boolean} showWeekends
   * @returns {object} Moment Date object
   */


  function shift(date, days, showWeekends) {
    if (!isDateValid(date) || isNaN(days)) {
      return null;
    }

    var _date = removeTimeZone(date);

    if (0 === (days = Math.round(days))) {
      return _date;
    }

    if (showWeekends) {
      return _date.add(days, 'days');
    } else {
      return _date.businessAdd(days, 'days');
    }
  }
  /**
   * iterator function, which iterates from startDate to endDate, providing a callback of every single day inbetween
   *
   * @param {object} startDate Moment
   * @param {object} endDate Moment
   * @param {function} cb
   */


  function eachDay(startDate, endDate, cb) {
    if (!isDateValid(startDate) || !isDateValid(endDate)) {
      return null;
    }

    var _endDate = removeTimeZone(endDate);

    var _startDate = removeTimeZone(startDate);

    for (var date = _startDate.clone(); !date.isAfter(_endDate, 'day'); date.add(1, 'day')) {
      cb(date);
    }
  }
  /**
   * iterator function, which iterates from startDate to endDate, including only the business days,
   * providing a callback of every single day inbetween
   *
   * @param {object} startDate Moment
   * @param {object} endDate Moment
   * @param {function} cb
   */


  function eachBusinessDay(startDate, endDate, cb) {
    if (!isDateValid(startDate) || !isDateValid(endDate)) {
      return null;
    }

    var _endDate = removeTimeZone(endDate);

    var _startDate = removeTimeZone(startDate);

    for (var date = _startDate.clone(); !date.dateAlignedAfter(_endDate, 'day'); date.add(1, 'day')) {
      if (!date.isWeekend()) {
        cb(date);
      }
    }
  }

  function enumerateDaysBetweenDates(startDate, endDate) {
    var dates = [];
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while (currDate.diff(lastDate) <= 0) {
      dates.push(currDate.clone());
      currDate.add(1, 'days');
    }

    return dates;
  }
});