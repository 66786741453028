define("coffeecup/models/invoice", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    project: _emberData.default.belongsTo('project'),
    amount: _emberData.default.attr('number'),
    startDate: _emberData.default.attr('datestamp'),
    endDate: _emberData.default.attr('datestamp'),
    easybillId: _emberData.default.attr('number'),
    invoicedAt: _emberData.default.attr('utc-moment'),
    createdAt: _emberData.default.attr('utc-moment'),
    updatedAt: _emberData.default.attr('utc-moment'),
    externalReference: _emberData.default.belongsTo('invoice-external-integration', {
      async: false
    }),
    label: Ember.computed('id', {
      get: function get() {
        return "CC-".concat(this.get('id'));
      }
    })
  });

  _exports.default = _default;
});