define("coffeecup/models/analytics/client/task", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "ember-data/relationships", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attr, _relationships, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * serialized Project analytics task model fragment
   * @see //serializers/analytics/project.js for mappings
   */
  var AnalyticsClientTask = _fragment.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    // hours
    hoursSpent: (0, _attr.default)('number'),
    hoursTotal: (0, _attr.default)('number'),
    hoursNonBillable: (0, _attr.default)('number'),
    // hours rounded
    hoursRoundedSpent: (0, _attr.default)('number'),
    hoursRoundedNonBillable: (0, _attr.default)('number'),
    hoursRoundedTotal: (0, _attr.default)('number'),
    // amounts
    amountSpent: (0, _attr.default)('number'),
    // costs
    amountInternal: (0, _attr.default)('number'),
    // percentage of the task part in the project
    percentage: (0, _attr.default)('number'),
    histogram: (0, _attributes.fragmentArray)('analytics/task_histogram/histogram'),

    /**
     * ModelFragments don't support relationships,
     * @see https://github.com/lytics/ember-data.model-fragments/issues/18
     */
    task_id: (0, _attr.default)('string'),
    //task: DS.belongsTo('task'),
    task: Ember.computed('task_id', {
      get: function get() {
        return this.store.peekRecord('task', this.get('task_id'));
      }
    }),
    color: (0, _relationships.belongsTo)('color', {
      async: false
    })
  });

  var _default = AnalyticsClientTask;
  _exports.default = _default;
});