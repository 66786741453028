define("coffeecup/models/analytics/client", ["exports", "coffeecup/config/environment", "ember-data/attr", "ember-data", "ember-data/relationships", "coffeecup/models/-super-model"], function (_exports, _environment, _attr, _emberData, _relationships, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //models/analytics/client.js
  var AnalyticsClient = _superModel.default.extend({
    amountSpent: (0, _attr.default)('number'),
    amountBudget: (0, _attr.default)('number'),
    amountOutOfBudget: (0, _attr.default)('number'),
    amountBilled: (0, _attr.default)('number'),
    hoursTotal: (0, _attr.default)('number'),
    hoursSpent: (0, _attr.default)('number'),
    hoursNonBillable: (0, _attr.default)('number'),
    hoursBudget: (0, _attr.default)('number'),
    hoursOutOfBudget: (0, _attr.default)('number'),
    hoursBilled: (0, _attr.default)('number'),
    invoicesTotal: _emberData.default.attr('number'),
    userCosts: (0, _attr.default)('number'),
    expensesTotal: (0, _attr.default)('number'),
    expensesBilled: (0, _attr.default)('number'),
    expensesNotBilled: (0, _attr.default)('number'),
    expensesWithinBudget: (0, _attr.default)('number'),
    expensesNotWithinBudget: (0, _attr.default)('number'),
    expensesCosts: (0, _attr.default)('number'),
    expensesCostsWithinBudget: (0, _attr.default)('number'),
    expensesCostsNotWithinBudget: (0, _attr.default)('number'),
    documents: (0, _attr.default)('number'),
    histogram: (0, _attr.default)(),
    projects: (0, _attr.default)(),
    tasks: (0, _attr.default)(),
    users: (0, _attr.default)(),
    // relations
    client: (0, _relationships.belongsTo)('client', {
      async: false
    }),
    // computed properties
    amountSpentTotal: Ember.computed('amountSpent', 'expensesTotal', function () {
      var amountSpent = this.get('amountSpent') || 0;
      var expensesTotal = this.get('expensesTotal') || 0;
      return amountSpent + expensesTotal;
    }),
    totalCosts: Ember.computed('userCosts', 'expensesCosts', function () {
      var expensesCosts = this.get('expensesCosts') || 0;
      var userCosts = this.get('userCosts') || 0;
      return userCosts + expensesCosts;
    }),
    amountSpentTotalFromProjects: Ember.computed('projects', function () {
      return (this.get('projects') || []).reduce(function (sum, project) {
        if (project.get('project.isCompletedFixedBudgetProject')) {
          return sum + (project.get('invoicesTotal') || 0);
        } else {
          return sum + (project.get('amountSpent') || 0) + project.get('expensesTotal');
        }
      }, 0);
    }),

    /**
     * @returns {number} the remaining amount that has not yet been charged
     */
    chargeableAmountRemaining: Ember.computed('amountSpentTotal', 'invoicesTotal', function () {
      var amountSpentTotal = this.get('amountSpentTotal') || 0;
      var invoicesTotal = this.get('invoicesTotal') || 0;
      return amountSpentTotal - invoicesTotal;
    }),
    chargeableRemaining: Ember.computed('amountBudget', 'invoicesTotal', function () {
      var amountBudget = this.get('amountBudget') || 0;
      var invoicesTotal = this.get('invoicesTotal') || 0;
      var chargeableRemaining = Math.floor((amountBudget - invoicesTotal) * 100) / 100;
      return chargeableRemaining;
    }),
    // polymorphic with project analytics
    amountBudgetTotal: Ember.computed.alias('amountBudget'),

    /**
     * @returns {Boolean} true if more charged then spent
     */
    isOverCharged: Ember.computed.lt('chargeableAmountRemaining', 0),

    /**
     * @returns {number} Percentage of chargeable amount remaining
     */
    chargeableAmountRemainingInPercent: Ember.computed('invoicesTotal', 'amountSpentTotal', function () {
      var invoicesTotal = this.get('invoicesTotal') || 0;
      var amountSpentTotal = this.get('amountSpentTotal') || 0;
      return amountSpentTotal > 0 ? invoicesTotal / amountSpentTotal : 0;
    }),

    /**
     * @returns {number} the profit calculated by invoices and costs
     */
    profitabilityTotal: Ember.computed('amountSpentTotalFromProjects', 'totalCosts', function () {
      var total = this.get('amountSpentTotalFromProjects') || 0;
      var totalCosts = this.get('totalCosts') || 0;
      return total - totalCosts;
    }),

    /**
     * @returns {number} the profit in relation to the invoices
     */
    profitabilityInPercent: Ember.computed('profitabilityTotal', 'amountSpentTotalFromProjects', function () {
      var profitabilityTotal = this.get('profitabilityTotal') || 0;
      var total = this.get('amountSpentTotalFromProjects') || 0;
      return total > 0 ? profitabilityTotal / total : 0;
    }),

    /**
     * @returns {Boolean} true if the costs are higher then the total of invoices
     */
    isNotProfitable: Ember.computed.lt('profitabilityTotal', 0),

    /**
     * @returns {number} all hours that are billable. Billed state is not taken into account
     */
    hoursBillable: Ember.computed('hoursSpent', 'hoursNonBillable', 'hoursOutOfBudget', {
      get: function get() {
        var hoursSpent = this.get('hoursSpent') || 0;
        var hoursNonBillable = this.get('hoursNonBillable') || 0;
        var hoursOutOfBudget = this.get('hoursOutOfBudget') || 0;
        return hoursSpent - hoursNonBillable - hoursOutOfBudget;
      }
    }),

    /**
     * @returns {number} percent of billable hours in relation to spent hours
     */
    hoursBillableInPercent: Ember.computed('hoursSpent', 'hoursBillable', function () {
      var hoursSpent = this.get('hoursSpent') || 0;
      var hoursBillable = this.get('hoursBillable') || 0;
      return hoursSpent > 0 ? hoursBillable / hoursSpent : 0;
    }),
    amountNotBilled: Ember.computed('amountSpent', 'amountBilled', {
      get: function get() {
        var amountSpent = this.get('amountSpent') || 0;
        var amountBilled = this.get('amountBilled') || 0;
        return amountSpent - amountBilled;
      }
    }),
    amountNotBilledTotal: Ember.computed('amountNotBilled', 'expensesNotBilled', function () {
      var amountNotBilled = this.get('amountNotBilled') || 0;
      var expensesNotBilled = this.get('expensesNotBilled') || 0;
      return amountNotBilled + expensesNotBilled;
    }),
    amountNotBilledTotalInPercent: Ember.computed('amountNotBilledTotal', 'amountSpentTotal', function () {
      var amountNotBilledTotal = this.get('amountNotBilledTotal') || 0;
      var amountSpentTotal = this.get('amountSpentTotal') || 0;
      return amountSpentTotal > 0 ? amountNotBilledTotal / amountSpentTotal : 0;
    }),
    amountRemaining: Ember.computed('amountBudget', 'amountSpentTotal', {
      get: function get() {
        var amountBudget = this.get('amountBudget') || 0;
        var amountSpentTotal = this.get('amountSpentTotal') || 0;
        return amountBudget - amountSpentTotal;
      }
    }),
    isOverBilled: Ember.computed.lt('amountNotBilledTotal', 0),
    amountBilledTotal: Ember.computed('amountBilled', 'expensesBilled', function () {
      var amountBilled = this.get('amountBilled') || 0;
      var expensesBilled = this.get('expensesBilled') || 0;
      return amountBilled + expensesBilled;
    }),
    firstMonthInDayFormat: Ember.computed('histogram.firstObject', function () {
      return moment(this.get('histogram.firstObject.date')).startOf('month').format(_environment.default.constants.DATE_FORMAT_DAY);
    }),
    lastMonthInDayFormat: Ember.computed('histogram.lastObject', function () {
      return moment(this.get('histogram.lastObject.date')).endOf('month').format(_environment.default.constants.DATE_FORMAT_DAY);
    })
  });

  var _default = AnalyticsClient;
  _exports.default = _default;
});