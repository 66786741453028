define("coffeecup/templates/components/dashboard-module/project-worktime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sceWr+QD",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],[[32,0,[\"listElements\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"project-worktime-module\"],[12],[2,\"\\n    \"],[8,\"manage-table/manage-table\",[],[[\"@config\",\"@rows\"],[[32,0,[\"tableConfig\"]],[32,0,[\"rows\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard-module/project-worktime.hbs"
    }
  });

  _exports.default = _default;
});