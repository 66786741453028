define("coffeecup/templates/components/validation/footer-submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A9yaTO5U",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex-1\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[\"status\"],null]],[2,\":\\n  \"],[1,[30,[36,1],[[30,[36,8],[[35,4],[35,4,[\"localizableState\"]],\"open\"],null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"flex-1\"],[12],[2,\"\\n\"],[6,[37,8],[[35,9]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,0],[[32,0],\"submitValidation\"],[[\"preventDefault\"],[true]]],[12],[1,[30,[36,1],[\"submit\"],null]],[13],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"openModal\",\"reason\",\"recipient\",\"validation\",\"week\",\"state\"],[[35,6],[35,5],[35,4,[\"validatedBy\"]],[35,4],[35,3],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"VALIDATION_SUBMITTED\",\"week\",\"validation\",\"reason\",\"openModal\",\"modals/reject-validation\",\"if\",\"showSubmitButton\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/validation/footer-submit.hbs"
    }
  });

  _exports.default = _default;
});