define("coffeecup/services/charts/pie", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    indicatorColors: [_environment.default.constants.REVENUE_COLOR_LIGHT, _environment.default.constants.WARN_COLOR_LIGHT, _environment.default.constants.WARN_COLOR_LIGHT, _environment.default.constants.ALERT_COLOR_LIGHT],
    getIndicatorColor: function getIndicatorColor(percentageSpent) {
      var indicatorColor;

      if (percentageSpent !== undefined) {
        var indicatorColors = this.get('indicatorColors');

        if (percentageSpent < 75) {
          indicatorColor = indicatorColors[0];
        } else if (percentageSpent < 90) {
          indicatorColor = indicatorColors[1];
        } else if (percentageSpent < 100) {
          indicatorColor = indicatorColors[2];
        } else if (percentageSpent >= 100) {
          indicatorColor = indicatorColors[3];
        }
      }

      return indicatorColor;
    },
    getChartData: function getChartData(data, colors) {
      return {
        type: 'pie',
        dataProvider: data,
        valueField: 'value',
        startDuration: 0,
        pullOutRadius: 0,
        pullOutDuration: 0,
        pullOutOnlyOne: true,
        hoverAlpha: 1,
        addClassNames: true,
        radius: 100,
        balloon: {
          enabled: false
        },
        colors: colors,
        titleField: 'title',
        labelsEnabled: false,
        innerRadius: '85%'
      };
    }
  });

  _exports.default = _default;
});