define("coffeecup/instance-initializers/time-rounding-type", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'time-rounding-type',
        id: _environment.default.constants.TIME_ROUNDING_TYPE_NONE,
        attributes: {
          key: 'roundingTypeNone'
        }
      }, {
        type: 'time-rounding-type',
        id: _environment.default.constants.TIME_ROUNDING_TYPE_UP,
        attributes: {
          key: 'roundingTypeUp'
        }
      }, {
        type: 'time-rounding-type',
        id: _environment.default.constants.TIME_ROUNDING_TYPE_DOWN,
        attributes: {
          key: 'roundingTypeDown'
        }
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'time-rounding-type',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});