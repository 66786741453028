define("coffeecup/models/tag", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    label: _emberData.default.attr('string'),
    tagAssignments: _emberData.default.hasMany('tag-assignment', {
      async: true,
      cascadeDelete: true
    })
  });

  _exports.default = _default;
});