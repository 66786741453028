define("coffeecup/models/analytics/task_histogram/histogram", ["exports", "ember-data/attr", "ember-data-model-fragments/fragment"], function (_exports, _attr, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //models/analytics/user_histogram/histogram.js
  var AnalyticsTaskHistogram = _fragment.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    date: (0, _attr.default)('date'),
    timestamp: (0, _attr.default)('date'),
    // TODO, this is not a string, it's a timestamp, check if attr('date') is the correct format for this property
    hoursTotal: (0, _attr.default)('number'),
    hoursSpent: (0, _attr.default)('number'),
    hoursNonBillable: (0, _attr.default)('number'),
    amountSpent: (0, _attr.default)('number'),
    amountBudget: DS.attr('number'),
    amountOutOfBudget: DS.attr('number'),
    percentage: (0, _attr.default)('number')
  });

  var _default = AnalyticsTaskHistogram;
  _exports.default = _default;
});