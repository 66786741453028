define("coffeecup/templates/components/code-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SNU8A1uQ",
    "block": "{\"symbols\":[\"@icon\",\"@isExpandable\",\"@size\",\"&attrs\",\"@label\"],\"statements\":[[11,\"span\"],[16,0,[31,[\"label label-default code-label text-overflow \",[32,3],\" \",[30,[36,0],[[32,2],\"expandable\"],null]]]],[16,5,[32,0,[\"style\"]]],[17,4],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[\"icomoon-\",[32,1]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[32,5]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/code-label.hbs"
    }
  });

  _exports.default = _default;
});