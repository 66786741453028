define("coffeecup/routes/projects/tasks/new", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    taskService: Ember.inject.service('task'),
    redirect: function redirect() {
      var _this = this;

      this.get('taskService').createDefaultTask().then(function (task) {
        _this.transitionTo('projects.tasks.edit', task);
      });
    }
  });

  _exports.default = _default;
});