define("coffeecup/mixins/reconnect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Mixin.create({
    session: Ember.inject.service(),
    _request: function _request(out, url, method, options) {
      var _arguments = arguments,
          _this = this;

      var session = this.get('session');
      var sessionData = session.get('data.authenticated');
      if (!sessionData || !sessionData['authenticator']) return this._super.apply(this, arguments);
      var authenticator = Ember.getOwner(this).lookup(sessionData['authenticator']);

      if (new Date().getTime() > sessionData['expires_at']) {
        return authenticator._refreshAccessToken(sessionData['expires_in'], sessionData['refresh_token']).then(function () {
          return _this._request.apply(_this, _toConsumableArray(_arguments));
        }).catch(function (error) {
          if (error) {
            _this.get('session').invalidate();

            window.location.reload();
          } else {
            var sleep = new Promise(function (resolve) {
              return Ember.run.later(_this, function () {
                resolve();
              }, 500);
            });
            return sleep.then(function () {
              return _this._request.apply(_this, _toConsumableArray(_arguments));
            });
          }
        });
      } else {
        url = this._appendAccessToken(url);
        return this._super(out, url, method, options);
      }
    }
  });

  _exports.default = _default;
});