define("coffeecup/transforms/datestamp", ["exports", "ember-data", "coffeecup/config/environment", "coffeecup/utils/date"], function (_exports, _emberData, _environment, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      // what the client expects
      if (!serialized) return null;
      var momentObj = moment.utc(serialized, _environment.default.constants.DATE_FORMAT_DAY);
      if (!(0, _date.isDateValid)(momentObj)) return null;
      return momentObj;
    },
    serialize: function serialize(deserialized) {
      return (0, _date.isDateValid)(deserialized) ? deserialized.format(_environment.default.constants.DATE_FORMAT_DAY) : null;
    }
  });

  _exports.default = _default;
});