define("coffeecup/services/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sortAndGroupedByCategory: function sortAndGroupedByCategory(documents) {
      documents = documents.sortBy('category.order');
      var documentsGrouped = [];
      var found = false;
      var documentCategory;
      documents.forEach(function (document) {
        documentCategory = document.get('category.label');
        documentCategory = typeof documentCategory.toString === 'function' ? documentCategory.toString() : documentCategory;
        found = documentsGrouped.findBy('text', documentCategory);

        if (!found) {
          documentsGrouped.pushObject(Ember.Object.create({
            text: documentCategory,
            children: []
          }));
        }

        documentsGrouped.findBy('text', documentCategory).get('children').pushObject(document);
      });
      return documentsGrouped;
    }
  });

  _exports.default = _default;
});