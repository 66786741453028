define("coffeecup/models/analytics/project-period", ["exports", "coffeecup/models/analytics/project"], function (_exports, _project) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //models/analytics/project.js
  var AnalyticsProjectPeriod = _project.default.extend({});

  var _default = AnalyticsProjectPeriod;
  _exports.default = _default;
});