define("coffeecup/routes/projects/copy", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    projectService: Ember.inject.service('project'),
    model: function model(params) {
      var _this = this;

      var context = this.store.peekRecord('project', params.project_id);
      var currentUser = this.getCurrentUser();
      return this.get('projectService').copyProject(context.clone()).then(function (project) {
        return _this.get('projectService').copyTaskAssignments(context.get('taskAssignments'), project).then(function () {
          return _this.get('projectService').copyUserAssignments(context.get('userAssignments'), project, currentUser).then(function () {
            return _this.transitionTo('projects.edit', project.get('id'));
          });
        });
      });
    }
  });

  _exports.default = _default;
});