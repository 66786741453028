define("coffeecup/routes/projects/expense-categories/new", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    expenseCategoryService: Ember.inject.service('expense-category'),
    redirect: function redirect() {
      var _this = this;

      this.get('expenseCategoryService').createDefaultExpenseCategory().then(function (model) {
        _this.transitionTo('projects.expense-categories.edit', model);
      });
    }
  });

  _exports.default = _default;
});