define("coffeecup/routes/projects/clients/copy", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //routes/projects/clients/copy.js
  var _default = _superRoute.default.extend({
    clientService: Ember.inject.service('client'),
    redirect: function redirect(context) {
      var _this = this;

      this.get('clientService').copyClient(context.clone()).then(function (client) {
        _this.get('clientService').copyClientContacts(context.get('contacts'), client).then(function () {
          _this.transitionTo('projects.clients.edit', client);
        });
      });
    }
  });

  _exports.default = _default;
});