define("coffeecup/utils/ui/i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setLanguage = setLanguage;
  _exports.translateValidation = translateValidation;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * sets the language in i18n based on the users settings
   * @param i18n
   * @param user
   */
  function setLanguage(i18n, user) {
    if (Ember.isEmpty(i18n)) {
      return;
    }

    if (!user || Ember.isEmpty(user) || typeof user.get === 'undefined') {
      return;
    }
    /*
     sets the locale of the i18n service
     */


    var language = user.get('language');

    if (language) {
      language = language.substring(0, 2);

      if (i18n.get('locales').includes(language)) {
        i18n.set('locale', language);
      }
    } else {
      i18n.set('locale', 'en');
    }
  }

  function translateValidation(i18n, validationProperties) {
    if (!validationProperties) return '';
    var context = validationProperties.context,
        message = validationProperties.message,
        type = validationProperties.type,
        value = validationProperties.value;
    var validationMessage = i18n.t(message).toString();

    if (context) {
      for (var _i = 0, _Object$entries = Object.entries(context); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
            key = _Object$entries$_i[0],
            contextValue = _Object$entries$_i[1];

        var translatedValue = typeof contextValue === 'string' ? i18n.t(Ember.String.camelize(contextValue)).toString() : null;
        var useTranslation = translatedValue && translatedValue.indexOf('t: ') === -1;
        validationMessage = validationMessage.replace("{".concat(key, "}"), useTranslation ? translatedValue : contextValue);
      }
    }

    return validationMessage;
  }
});