define("coffeecup/validators/validateSameOrBefore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateSameOrBefore;

  function validateSameOrBefore(opts) {
    return function (key, newValue, oldValue, changes, model) {
      var comparisonKey = opts.comparisonKey,
          comparisonUnit = opts.comparisonUnit;

      if (!comparisonKey || !comparisonUnit) {
        return "".concat(comparisonKey, " and ").concat(comparisonUnit, " are required but not present!");
      }

      var valueForComparison = changes[comparisonKey] || model.get(comparisonKey);

      if (!valueForComparison) {
        return "".concat(comparisonKey, " value is required but not present!");
      }

      if (!moment(newValue).isValid()) {
        return "".concat(key, " date is invalid!");
      }

      if (!moment(valueForComparison).isValid()) {
        return "".concat(comparisonKey, " date is invalid!");
      }

      if (moment(newValue).isAfter(moment(valueForComparison), comparisonUnit)) {
        return "".concat(key, " is after ").concat(comparisonKey);
      }

      return true;
    };
  }
});