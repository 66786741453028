define("coffeecup/templates/components/dashboard/effort-costs/legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z+stJZiG",
    "block": "{\"symbols\":[\"@totalRevenue\",\"@currencySymbol\",\"@totalCosts\",\"@totalDifference\",\"@profitability\"],\"statements\":[[10,\"div\"],[14,0,\"chart-legend\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"dashboard/effort-costs/legend-item\",[[24,0,\"revenue\"]],[[\"@label\",\"@value\",\"@currencySymbol\"],[[30,[36,0],[\"effort\"],null],[32,1],[32,2]]],null],[2,\"\\n    \"],[8,\"dashboard/effort-costs/legend-item\",[[24,0,\"costs\"]],[[\"@label\",\"@value\",\"@currencySymbol\"],[[30,[36,0],[\"costs\"],null],[32,3],[32,2]]],null],[2,\"\\n    \"],[8,\"dashboard/effort-costs/legend-item\",[[16,0,[31,[\"performance-difference \",[30,[36,2],[[30,[36,3],[[32,4],0],null],\"text-danger\"],null]]]]],[[\"@label\",\"@value\",\"@currencySymbol\"],[[30,[36,2],[[30,[36,1],[[32,4],0],null],[30,[36,0],[\"surplus\"],null],[30,[36,0],[\"loss\"],null]],null],[32,4],[32,2]]],null],[2,\"\\n    \"],[8,\"dashboard/effort-costs/legend-item\",[[24,0,\"profit\"]],[[\"@label\",\"@value\",\"@preformatted\"],[[30,[36,0],[\"profitability\"],null],[32,5],true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"gte\",\"if\",\"lt\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/effort-costs/legend.hbs"
    }
  });

  _exports.default = _default;
});