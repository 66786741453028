define("coffeecup/validations/account-setting", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    companyName: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 64
    })],
    fiscalYearMonth: [(0, _validators.validatePresence)(true)],
    colorScheme: [(0, _validators.validatePresence)(true)],
    currency: [(0, _validators.validatePresence)(true)],
    timeRoundingStep: [(0, _validators.validateLength)({
      max: 2
    })],
    defaultTimezone: [(0, _validators.validatePresence)(true)],
    defaultLanguage: [(0, _validators.validatePresence)(true)],
    defaultBeginningOfWeek: [(0, _validators.validatePresence)(true)],
    defaultDateFormat: [(0, _validators.validatePresence)(true)],
    defaultTimeFormat: [(0, _validators.validatePresence)(true)],
    defaultDurationFormat: [(0, _validators.validatePresence)(true)],
    wordingOption: [(0, _validators.validatePresence)(true)],
    currencyFormat: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});