define("coffeecup/templates/components/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fYGjb2Cj",
    "block": "{\"symbols\":[\"@small\",\"@title\",\"@subTitle\",\"@description\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"pie-description text-center \",[30,[36,0],[[32,1],\"small\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pie-chart-title\"],[12],[2,\"\\n    \"],[1,[32,2]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pie-chart-sub-title\"],[12],[2,\"\\n    \"],[1,[32,3]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"hr\"],[15,\"width\",[31,[[30,[36,0],[[32,1],\"60px\",\"120px\"],null]]]],[14,\"align\",\"center\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pie-chart-description\"],[12],[2,\"\\n    \"],[1,[32,4]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,1,[31,[\"project-chart-div-\",[32,0,[\"elementId\"]]]]],[15,0,[31,[\"project-chart equal-sides \",[30,[36,0],[[32,1],\"small\"],null]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/pie-chart.hbs"
    }
  });

  _exports.default = _default;
});