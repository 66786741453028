define("coffeecup/routes/account/holidays", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('holidays');
    },
    setupController: function setupController(controller, context, transition) {
      this._resetController();
    },
    _resetController: function _resetController() {
      var accountSettings = this.store.peekAll('account-setting').get('firstObject');
      var allCountries = this.store.peekAll('country');
      var holidayCountries = Ember.A();
      holidayCountries.pushObject(allCountries.findBy('code', 'DE'));
      holidayCountries.pushObject(allCountries.findBy('code', 'AT'));
      this.set('controller.regions', this.store.peekAll('region'));
      this.set('controller.countries', holidayCountries);
      this.set('controller.selectedRegion', accountSettings.get('adminUiSettings.region'));
      this.set('controller.selectedCountry', accountSettings.get('adminUiSettings.country'));
      this.set('controller.initialUserPublicHoliday', accountSettings.get('adminUiSettings.useUserPublicHolidays'));
    }
  });

  _exports.default = _default;
});