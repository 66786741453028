define("coffeecup/initializers/i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'i18n',
    after: 'ember-i18n',
    initialize: function initialize(app) {
      app.inject('controller', 'i18n', 'service:i18n');
      app.inject('component', 'i18n', 'service:i18n');
      app.inject('helper', 'i18n', 'service:i18n');
      app.inject('model', 'i18n', 'service:i18n');
      app.inject('route', 'i18n', 'service:i18n');
      app.inject('validator', 'i18n', 'service:i18n');
    }
  };
  _exports.default = _default;
});