define("coffeecup/mixins/fsg-deferred", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filterTermChanged: Ember.observer('filterTerm', function () {
      var $scrollable = this.$().parents('.scrollable');
      $scrollable.trigger('refresh');
    })
  });

  _exports.default = _default;
});