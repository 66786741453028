define("coffeecup/templates/components/users/avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l65KoC6g",
    "block": "{\"symbols\":[\"@user\"],\"statements\":[[6,[37,1],[[32,1,[\"isImageInitials\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[\"initials-avatar \",[32,0,[\"size\"]],\" noselect \",[32,1,[\"color\"]]]]],[12],[2,\"\\n    \"],[1,[32,1,[\"initials\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"img\"],[15,\"src\",[32,1,[\"imageSmall\"]]],[15,\"alt\",[30,[36,0],[[32,1,[\"label\"]],\" avatar\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/users/avatar.hbs"
    }
  });

  _exports.default = _default;
});