define("coffeecup/templates/components/button-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SHRCmDFm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[1,[30,[36,1],[[35,0]],null]]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"label\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/button-switch.hbs"
    }
  });

  _exports.default = _default;
});