define("coffeecup/templates/components/manage-table/cells/project-billable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fR8ScxQk",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"classes\"]]],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"manage-table-cell-contents\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"isModeNotBillable\"]],0,[32,0,[\"amountNotBilledTotal\"]]],null],false,[30,[36,0],[[32,1,[\"isModeNotBillable\"]]],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"not\",\"if\",\"project-board-fmt\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-billable.hbs"
    }
  });

  _exports.default = _default;
});