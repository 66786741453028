define("coffeecup/models/currency", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Currency = _emberData.default.Model.extend({
    sign: _emberData.default.attr('string'),
    // computed properties
    label: Ember.computed('id', 'i18n.locale', {
      get: function get() {
        var tKey = this.get('id');
        var sign = this.get('sign');
        return this.get('i18n').t(tKey + 'Currency') + ' [' + sign + ']';
      }
    })
  });

  var _default = Currency;
  _exports.default = _default;
});