define("coffeecup/routes/planner/absences/calendar/new", ["exports", "coffeecup/routes/_super-route", "moment", "coffeecup/config/environment"], function (_exports, _superRoute, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    permissions: Ember.inject.service(),
    setupController: function setupController(controller, context, transition) {
      var applicationController = this.controllerFor('application');
      var currentUser = applicationController.get('currentUser');
      controller.set('closeModal', false);
      controller.set('currentUser', currentUser);
      controller.set('users', context.users);
      controller.set('alreadyAdded', false);

      this._super.apply(this, [controller, context.absence, transition]);

      Ember.run.later(function () {
        return controller.set('openModal', true);
      });
    },
    actions: {
      transitionToList: function transitionToList() {
        this.transitionTo('planner.absences.calendar');
      }
    },
    model: function model(params) {
      var applicationController = this.controllerFor('application');
      var users = applicationController.get('activeUsers');
      var currentUser = this.getCurrentUser();
      var userIsNotSelf = params.user && params.user !== currentUser.get('id');
      var canCreateForOtherUsers = userIsNotSelf && this.get('permissions.canAccessUsers');
      var userRecord = params.user ? this.store.peekRecord('user', params.user) : currentUser;
      var canCreateAbsenceForTeamMember = userIsNotSelf && this.get('permissions.canManageTeamAbsences') && userRecord.get('team.id') === currentUser.get('team.id');
      var canCreateAbsence = this.getCurrentUser().get('isAdmin') || canCreateForOtherUsers || canCreateAbsenceForTeamMember;
      var hashToResolve = {
        absence: canCreateAbsence ? this.getAbsence(params) : this.getAbsenceRequest(params, users),
        users: users
      };
      return Ember.RSVP.hash(hashToResolve);
    },
    getAbsence: function getAbsence(params) {
      var startDate;

      if (!params.absenceStartDate) {
        startDate = this.get('clock.localDayAsUtcMoment');
      } else {
        startDate = _moment.default.utc(params.absenceStartDate, _environment.default.constants.DATE_FORMAT_DAY);
      }

      var default_absenceType = this.store.peekAll('absence-type').filterBy('canBeRequested').findBy('isVacation', true);
      var absence = this.store.createRecord('absence');
      absence.set('startDate', _moment.default.utc(startDate));
      absence.set('endDate', _moment.default.utc(startDate));
      absence.set('absenceType', default_absenceType);

      if (params.user) {
        absence.set('user', this.store.peekRecord('user', params.user));
      } else {
        absence.set('user', this.getCurrentUser());
      }

      return absence;
    },
    getAbsenceRequest: function getAbsenceRequest(params, users) {
      console.log('getAbsenceRequest', params, users);
      var startDate;

      if (!params.absenceStartDate) {
        startDate = this.get('clock.localDayAsUtcMoment');
      } else {
        startDate = _moment.default.utc(params.absenceStartDate, _environment.default.constants.DATE_FORMAT_DAY);
      }

      var currentUser = this.getCurrentUser();
      var absenceRequest = this.store.createRecord('absence-request');
      var default_absenceType = this.store.peekAll('absence-type').filterBy('canBeRequested').findBy('isVacation', true);
      var defaultSupervisor = users.filter(function (user) {
        return user.get('role.canManageAbsenceRequests') && currentUser.get('id') !== user.get('id');
      }).get('firstObject');
      absenceRequest.set('startDate', _moment.default.utc(startDate));
      absenceRequest.set('endDate', _moment.default.utc(startDate));
      absenceRequest.set('absenceType', default_absenceType);
      absenceRequest.set('sender', currentUser);
      if (defaultSupervisor) absenceRequest.set('receiver', defaultSupervisor);

      if (params.user) {
        absenceRequest.set('user', this.store.peekRecord('user', params.user));
      } else {
        absenceRequest.set('user', this.getCurrentUser());
      }

      return absenceRequest;
    }
  });

  _exports.default = _default;
});