define("coffeecup/utils/format-duration", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatAsPersonDays = formatAsPersonDays;
  _exports.normalizeDuration = normalizeDuration;
  _exports.sec2time = sec2time;
  _exports.sec2hm = sec2hm;
  _exports.decimalHoursIsNow = decimalHoursIsNow;
  _exports.decimalHoursisNowOrEarlierToday = decimalHoursisNowOrEarlierToday;
  _exports.getRoundedDuration = getRoundedDuration;
  _exports.calculateUIHeightForTime = calculateUIHeightForTime;
  _exports.calculateTimeFromUIHeight = calculateTimeFromUIHeight;

  /**
   *
   * @param timeValue hours as floating string (e.g.: 17,5)
   * @param working_day_hours (e.g.: 8, default)
   * @returns {string} floored days (e.g.: ~ 2)
   */
  function formatAsPersonDays(timeValue, working_day_hours) {
    var budgetHours = parseFloat(timeValue || 0);
    var wdh = parseInt(working_day_hours || 8);
    var floored;
    var pt = parseFloat(budgetHours / wdh);
    floored = pt.toFixed(2);

    if (floored != pt) {
      return '~ ' + floored;
    } else {
      return pt;
    }
  }
  /**
   *  Normalizing Method that takes a time duration argument string,
   * either in a regular time format or a minutes representation in decimal:
   * For example: 120 hours and 45 minutes written in the form of:
   * 120 --> 120.00000000
   * 120:45 --> 120.75000000
   * 120.75 --> 120.75000000
   * 120,75 --> 120.75000000
   or 120 hours and 30 minutes written in the form of:
   * 120:30 --> 120.50000000
   * 120.5 --> 120.50000000
   * 120,5 --> 120.50000000
   * 2:14 --> 2.33333333
   *
   * @param {string|Number} val time duration in hours, various formats supported
   * @return {string}
   */


  function normalizeDuration(val) {
    var currentValue = (val || 0).toString();
    var durationFmt, durationTemp;

    if (currentValue.indexOf(':') != -1) {
      //Converting from 120:45 --> 120.75
      durationTemp = currentValue.split(':');
      var minutesTemp = durationTemp[1].slice(0, 2);

      if (minutesTemp.length == 1) {
        minutesTemp += '0';
      }

      var hours = parseInt(durationTemp[0]);
      var minutes = parseFloat(minutesTemp / 60);

      if (isNaN(hours) && !isNaN(minutes)) {
        durationFmt = parseFloat(minutes);
      } else if (isNaN(minutes)) {
        durationFmt = 0;
      } else {
        durationFmt = parseFloat(hours + minutes); //if there are entered more than 60 minutes the carry-over is added as well!
      }
    } else {
      //Converting from 120.75 or 120,75 --> 120.75
      durationFmt = parseFloat(currentValue.replace(',', '.'));

      if (!durationFmt || isNaN(durationFmt)) {
        durationFmt = 0;
      }
    } // make sure duration is positive


    durationFmt = Math.abs(durationFmt);
    return durationFmt.toFixed(12);
  }
  /**
   *
   * @param timeInSeconds in seconds
   * @returns {string} in hh:mm:ss
   */


  function sec2time(timeInSeconds, opts) {
    opts = opts || {};

    var pad = function pad(num, size) {
      return ('000' + num).slice(size * -1);
    };

    var time = parseFloat(timeInSeconds).toFixed(3);
    var hours = Math.floor(time / 60 / 60);
    var minutes = Math.floor(time / 60) % 60;
    var seconds = Math.floor(time - minutes * 60);
    var hideSeconds = !Ember.isEmpty(opts.hideSeconds) && opts.hideSeconds === true;
    return pad(hours, 2) + ':' + pad(minutes, 2) + (hideSeconds ? '' : ':' + pad(seconds, 2));
  }
  /**
   *
   * @param timeInSeconds in seconds
   * @returns {string} in h(*):mm
   */


  function sec2hm(timeInSeconds) {
    var pad = function pad(num, size) {
      return ('000' + Math.abs(num)).slice(size * -1);
    };

    var time = parseFloat(timeInSeconds).toFixed(3);
    var hours = Math.floor(time / 60 / 60);
    var minutes = Math.floor(time / 60) % 60;
    var sign = '';

    if (timeInSeconds < 0) {
      hours = Math.ceil(time / 60 / 60);
      minutes = Math.ceil(time / 60) % 60;

      if (hours === 0 && minutes !== 0) {
        sign = '-';
      }
    }

    return sign + hours + ':' + pad(minutes, 2);
  }
  /**
   *
   * @param hours in decimal hours
   * @returns boolean
   */


  function decimalHoursIsNow(hours) {
    var seconds = moment.duration(parseFloat(hours), 'hours').asSeconds();
    var time = moment(sec2time(seconds), 'HH:mm:ss').format('HH:mm');
    var now = moment().format('HH:mm');
    return time === now;
  }

  function decimalHoursisNowOrEarlierToday(hours) {
    var seconds = moment.duration(parseFloat(hours), 'hours').asSeconds();
    var m = moment(sec2time(seconds), 'HH:mm:ss');
    var diff = m.diff(moment(), 'seconds');
    return diff <= 0;
  }
  /**
   *
   * @param duration in seconds
   * @param projectRoundingAmount in minutes
   * @param projectRoundingType
   * @return roundedDuration in seconds
   */


  function getRoundedDuration(duration, projectRoundingAmount, projectRoundingType) {
    var projectRoundingAmountAsSeconds = parseInt(projectRoundingAmount, 10) * 60;
    var durationFlooredToMinute = 60 * Math.floor(duration / 60);
    var durationRounded;

    if (projectRoundingType === _environment.default.constants.ROUNDING_TYPE_UP) {
      durationRounded = Math.ceil(durationFlooredToMinute / projectRoundingAmountAsSeconds) * projectRoundingAmountAsSeconds;
    } else if (projectRoundingType === _environment.default.constants.ROUNDING_TYPE_DOWN) {
      durationRounded = Math.floor(durationFlooredToMinute / projectRoundingAmountAsSeconds) * projectRoundingAmountAsSeconds;
    } else {
      durationRounded = duration;
    }

    return durationRounded;
  }

  var TIME_ENTRY_HEIGHT_FACTOR = _environment.default.constants.TIME_ENTRY_ITEM_MIN_HEIGHT_DURATION / _environment.default.constants.TIME_ENTRY_ITEM_MIN_HEIGHT;

  function calculateUIHeightForTime(duration) {
    var height = Math.round(duration / TIME_ENTRY_HEIGHT_FACTOR);
    return Math.min(_environment.default.constants.TIME_ENTRY_ITEM_MAX_HEIGHT, Math.max(_environment.default.constants.TIME_ENTRY_ITEM_MIN_HEIGHT, height));
  }

  function calculateTimeFromUIHeight(uiHeight) {
    var duration = Math.max(Math.round(uiHeight * TIME_ENTRY_HEIGHT_FACTOR), 0);
    return duration;
  }
});