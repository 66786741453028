define("coffeecup/routes/team/users/copy", ["exports", "coffeecup/routes/team/users/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //routes/manage/users/copy.js
  var UserCopyRoute = _edit.default.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('user') + ' ' + this.get('i18n').t('duplicate');
    },
    controllerName: 'team.users.new',
    templateName: 'team.users.new',
    setupController: function setupController(controller, context) {
      var _this = this;

      var copy = this.store.createRecord('user', context.clone());
      copy.set('firstname', '(COPY) ' + context.get('firstname'));
      copy.set('lastname', '(COPY) ' + context.get('lastname'));
      copy.set('email', 'copy+' + context.get('email'));
      var copiedUserAssignments = [];
      var copiedTagAssignments = [];

      context._userAssignments.forEach(function (userAssignment) {
        copiedUserAssignments.push(_this.store.createRecord('user-assignment', userAssignment.clone()));
      });

      if (context.get('tagAssignments')) {
        context.get('tagAssignments').forEach(function (tagAssignment) {
          copiedTagAssignments.push(_this.store.createRecord('tag-assignment', tagAssignment.clone()));
        });
      }

      copy._employment = [];
      copy._userAssignments = copiedUserAssignments;
      copy.set('userAssignments', copiedUserAssignments);
      copy.set('tagAssignments', copiedTagAssignments);
      controller.set('model', copy);
      controller.set('isSaving', false);
    }
  });

  var _default = UserCopyRoute;
  _exports.default = _default;
});