define("coffeecup/templates/components/planner/non-working-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NGAElcL0",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"absence-wrapper absence-bg-light-color-\",[34,0],\" \",[30,[36,1],[[35,0],\"anonymous\"],null]]]],[12],[2,\"\\n\"],[6,[37,3],null,[[\"content\",\"class\",\"contentAsHTML\"],[[35,2],\"non-working-grid\",true]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"absence-border-top absence-bg-color-\",[34,0]]]],[12],[13],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"absence-item absence-bg-greyed-color-\",[34,0]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"absenceColor\",\"unless\",\"tooltip\",\"tool-tipster\",\"hideAbsenceOrAbsenceRequest\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/non-working-day.hbs"
    }
  });

  _exports.default = _default;
});