define("coffeecup/utils/attachment-load", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  /**
   * Load an attachment into memory.
   *
   * @param Blob file - The file to load
   * @return RSVP.Promise - Resolves with the binary data for the file
   */
  function _default(file) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var reader = new mOxie.FileReader();

      reader.onloadend = function () {
        resolve(reader.result);
      };

      reader.onerror = function () {
        reject(reader.error);
      };

      reader.readAsBinaryString(file);
    });
  }
});