define("coffeecup/models/vacation-entry", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    comment: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    year: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    vacationType: _emberData.default.attr('string'),
    vacationBudgetId: _emberData.default.attr('string'),
    absenceId: _emberData.default.attr('string'),
    mode: _emberData.default.attr('string')
  });

  _exports.default = _default;
});