define("coffeecup/templates/components/analytics/projects/billing/expense-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m6RYVXOW",
    "block": "{\"symbols\":[\"@expense\"],\"statements\":[[10,\"div\"],[14,0,\"flex-row align-items-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-col-2\"],[12],[2,\"\\n    \"],[8,\"input/cc-checkbox\",[],[[\"@value\"],[[32,0,[\"selectExpenseForBilling\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-1 clickable\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"list-title\"],[12],[1,[32,1,[\"expenseCategory\",\"label\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"list-subtitle\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-col-4 text-right clickable\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1,[\"day\"]],true],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-col-4 text-right clickable\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"priceExternal\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"date-fmt\",\"currency-fmt\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics/projects/billing/expense-row.hbs"
    }
  });

  _exports.default = _default;
});