define("coffeecup/mixins/click-outside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    onOutsideClick: function onOutsideClick() {},
    handleOutsideClick: function handleOutsideClick(event) {
      var $element = this.$();
      var $target = Ember.$(event.target);

      if ($element && $target && $target.closest($element).length === 0) {
        this.onOutsideClick();
      }
    },
    setupOutsideClickListener: Ember.on('didInsertElement', function () {
      var clickHandler = this.get('handleOutsideClick').bind(this);
      return Ember.$(document).on('click', clickHandler);
    }),
    removeOutsideClickListener: Ember.on('willDestroyElement', function () {
      var clickHandler = this.get('handleOutsideClick').bind(this);
      return Ember.$(document).off('click', Ember.run.cancel(this, clickHandler));
    })
  });

  _exports.default = _default;
});