define("coffeecup/templates/components/analytics-module/kpi/billable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sIR/9MIr",
    "block": "{\"symbols\":[\"@analytics\",\"@hasData\"],\"statements\":[[8,\"analytics-module/kpi/kpi-cell\",[],[[\"@icon\",\"@amount\",\"@label\",\"@hasData\"],[\"icomoon-coin-euro\",[30,[36,0],[[32,1,[\"amountNotBilledTotal\"]],false,true,true],null],[30,[36,1],[\"billable\"],null],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"kpi-tooltip\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"billableEffort\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"amountNotBilled\"]],false,true],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"billableExpenses\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"expensesNotBilled\"]],false,true],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row summary-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"totalLabel\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"amountNotBilledTotal\"]],false,true],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"currency-fmt\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/kpi/billable.hbs"
    }
  });

  _exports.default = _default;
});