define("coffeecup/utils/ui/cldr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setCLDR = setCLDR;

  //utils/ui/cldr.js

  /**
   * will set the locale for the CLDR framework
   * CLDR is a standardized repository of locale data along with a specification for its use and implementation
   *
   * @param i18n {Object} the ember-i18n service
   */
  function setCLDR(i18n) {
    if (i18n) {
      /**
       * initialize CLDR.js
       */
      CLDR.defaultLanguage = i18n.get('locale');
    }
  }
});