define("coffeecup/templates/components/input-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/4jd3//0",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[14,0,\"control-label\"],[15,\"for\",[34,0]],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"t-tip-icon\",[],[[\"@content\"],[[32,0,[\"labelHelp\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"controls\"],[12],[2,\"\\n  \"],[8,\"input\",[[16,1,[34,0]],[16,\"maxlength\",[34,4]],[16,0,[34,5]],[16,\"placeholder\",[34,7]],[16,4,[34,8]],[4,[38,9],[\"focus\",[32,0,[\"updateFocus\"]]],null],[4,[38,9],[\"blur\",[32,0,[\"updateBlur\"]]],null],[4,[38,9],[\"keypress\",[32,0,[\"onKeyPress\"]]],null],[4,[38,9],[\"keydown\",[32,0,[\"onKeyDown\"]]],null],[4,[38,9],[\"keyup\",[32,0,[\"onKeyUp\"]]],null]],[[\"@value\"],[[34,6]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"inputId\",\"label\",\"labelHelp\",\"if\",\"maxlength\",\"inputClasses\",\"value\",\"placeholder\",\"inputType\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input-field.hbs"
    }
  });

  _exports.default = _default;
});