define("coffeecup/router", ["exports", "coffeecup/config/environment", "coffeecup/mixins/analytics-transition-router"], function (_exports, _environment, _analyticsTransitionRouter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend(_analyticsTransitionRouter.default, {
    location: _environment.default.locationType
  });
  Router.map(function () {
    this.route('login');
    this.route('resetpassword', {
      resetNamespace: true,
      path: 'resetpassword/:token'
    });
    this.route('forgotpassword');
    this.route('welcome', {
      path: 'welcome/:token'
    });
    this.route('dashboard');
    this.route('time-entries', function () {
      this.route('tracking', function () {
        this.route('new');
        this.route('edit', {
          path: ':time_entry_id'
        });
      });
      this.route('verifying', function () {
        this.route('monthly');
        this.route('list');
      });
    });
    this.route('analytics', function () {
      this.route('clients', function () {
        this.route('details', {
          path: ':client_id'
        });
      });
      this.route('projects', function () {
        this.route('details', {
          path: ':project_id'
        }, function () {
          this.route('complete');
        });
        this.route('billing');
      });
      this.route('time-entries', function () {
        this.route('export');
        this.route('batch-update', {
          path: ':k/:v'
        });
        this.route('edit', {
          path: ':time_entry_id'
        });
      });
      this.route('users', function () {
        this.route('details', {
          path: ':user_id'
        });
      });
    });
    this.route('manage', function () {
      this.route('users', {
        path: 'users/*'
      });
      this.route('projects', {
        path: 'projects/*'
      });
      this.route('index', {
        path: '*'
      });
    });
    this.route('account', function () {
      this.route('billing');
      this.route('company');
      this.route('holidays');
      this.route('profile');
      this.route('settings');
      this.route('password');
      this.route('integrations', function () {
        this.route('billing', function () {
          this.route('index', {
            path: '/:name'
          });
          this.route('client-import', {
            path: '/:name/client-import'
          });
        });
      });
      this.route('roles');
    });
    this.route('not-found', {
      path: '/*path'
    });
    this.route('future-user');
    this.route('planner', function () {
      this.route('team', function () {
        this.route('edit', {
          path: 'asgnmt/:assignment_id'
        });
        this.route('split', {
          path: 'asgnmt/:assignment_id/split'
        });
      });
      this.route('projects', function () {
        this.route('edit', {
          path: 'asgnmt/:assignment_id'
        });
        this.route('milestone', {
          path: 'milestone/:milestone_id'
        });
        this.route('split', {
          path: 'asgnmt/:assignment_id/split'
        });
      });
      this.route('absences', function () {
        this.route('calendar', function () {
          this.route('edit', {
            path: ':absence_id'
          });
          this.route('new');
          this.route('feed');
          this.route('confirm', {
            path: ':absence_request_id/confirm'
          });
        });
        this.route('scheduler', function () {
          this.route('edit', {
            path: ':absence_id'
          });
          this.route('new');
          this.route('feed');
          this.route('confirm', {
            path: ':absence_request_id/confirm'
          });
        });
      });
    });
    this.route('onboarding');
    this.route('absences', function () {
      this.route('scheduler', function () {
        this.route('confirm', {
          path: ':absence_request_id/confirm'
        });
      });
    });
    this.route('projects', function () {
      this.route('new', {
        path: 'new'
      });
      this.route('edit', {
        path: 'edit/:project_id'
      });
      this.route('copy', {
        path: 'copy/:project_id'
      });
      this.route('analytics', {
        path: 'analytics/:project_id'
      });
      this.route('clients', function () {
        this.route('new');
        this.route('edit', {
          path: 'edit/:client_id'
        });
        this.route('copy', {
          path: 'copy/:client_id'
        });
        this.route('analytics', {
          path: 'analytics/:client_id'
        });
      });
      this.route('tasks', function () {
        this.route('new');
        this.route('edit', {
          path: 'edit/:task_id'
        });
        this.route('copy', {
          path: 'copy/:task_id'
        });
      });
      this.route('expense-categories', function () {
        this.route('new');
        this.route('edit', {
          path: 'edit/:expense-category_id'
        });
        this.route('copy', {
          path: 'copy/:expense-category_id'
        });
      });
    });
    this.route('team', function () {
      this.route('users', function () {
        this.route('new');
        this.route('analytics', {
          path: 'analytics/:user_id'
        });
        this.route('edit', {
          path: 'edit/:user_id'
        }, function () {
          this.route('vacation', {
            path: ':absence_id'
          });
        });
        this.route('copy', {
          path: 'copy/:user_id'
        });
      });
      this.route('new');
      this.route('edit', {
        path: 'edit/:team_id'
      });
      this.route('copy', {
        path: 'copy/:team_id'
      });
    });
    this.route('planning-actual');
  });
  var _default = Router;
  _exports.default = _default;
});