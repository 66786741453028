define("coffeecup/routes/time-entries/tracking/new", ["exports", "coffeecup/routes/time-entries/tracking/edit", "moment", "coffeecup/config/environment"], function (_exports, _edit, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TimeEntriesNewRoute = _edit.default.extend({
    queryParams: {
      date: {
        refreshModel: true
      },
      userId: {
        refreshModel: true
      },
      projectId: {
        refreshModel: true
      }
    },
    templateName: 'time-entries.tracking.edit',
    timeEntryService: Ember.inject.service('timeEntry'),
    accountService: Ember.inject.service('account'),
    _getTimeEntry: function _getTimeEntry(params) {
      var _this = this;

      var setUrlTimeParams = function setUrlTimeParams(timeEntry) {
        if (params.date && (0, _moment.default)(params.date).isValid()) {
          timeEntry.set('day', (0, _moment.default)(params.date));
        } else {
          timeEntry.set('day', _this.get('clock.day'));
        }

        return timeEntry;
      };

      if (params.type === _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_BREAK) {
        return new Ember.RSVP.Promise(function (resolve) {
          var timerModeIsDuration = _this.get('accountSettings.timerModeIsDuration');

          var timeEntry = _this.store.createRecord('time-entry');

          var user = params.userId !== undefined ? _this.store.peekRecord('user', params.userId) : _this.get('currentUserService').getCurrentUser();
          timeEntry.set('user', user);

          if (timerModeIsDuration) {
            timeEntry.set('duration', 0);
          }

          timeEntry.set('trackingType', params.type ? params.type : _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK);
          timeEntry.set('startTime', (0, _moment.default)().format('HH:mm:ss'));
          resolve(setUrlTimeParams(timeEntry));
        });
      } else {
        return this.get('timeEntryService').prepareNewTimeEntryFromLatest(params.userId, params.projectId).then(function (timeEntry) {
          if (params.projectId) {
            var project = _this.store.peekRecord('project', params.projectId);

            timeEntry.set('project', project);
          }

          return setUrlTimeParams(timeEntry);
        });
      }
    }
  });

  var _default = TimeEntriesNewRoute;
  _exports.default = _default;
});