define("coffeecup/templates/components/custom-icon-configurator/icon-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VemROoNK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"border-element\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"icon-element\"],[15,5,[31,[\"background-color: \",[34,0]]]],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[\"icon icomoon-\",[34,1]]]],[12],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"selectedColor\",\"icon\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/custom-icon-configurator/icon-picker.hbs"
    }
  });

  _exports.default = _default;
});