define("coffeecup/models/user-employment", ["exports", "ember-data", "coffeecup/models/-super-model", "coffeecup/config/environment"], function (_exports, _emberData, _superModel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var UserEmployment = _superModel.default.extend({
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    activeWeekends: Ember.computed.reads('accountSettings.activeWeekends'),
    user: _emberData.default.belongsTo('user'),
    validFrom: _emberData.default.attr('datestamp'),
    employmentType: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    employerContribution: _emberData.default.attr('number'),
    monthlyRemuneration: _emberData.default.attr('number'),
    hourlyRemuneration: _emberData.default.attr('number'),
    workHoursType: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    hoursWeekly: _emberData.default.attr('number'),
    hoursMonday: _emberData.default.attr('number'),
    hoursTuesday: _emberData.default.attr('number'),
    hoursWednesday: _emberData.default.attr('number'),
    hoursThursday: _emberData.default.attr('number'),
    hoursFriday: _emberData.default.attr('number'),
    hoursSaturday: _emberData.default.attr('number'),
    hoursSunday: _emberData.default.attr('number'),
    hoursTotal: Ember.computed('workHoursType', 'hoursWeekly', 'hoursMonday', 'hoursTuesday', 'hoursWednesday', 'hoursThursday', 'hoursFriday', 'hoursSaturday', 'hoursSunday', {
      get: function get() {
        var type = this.get('workHoursType');

        if (type === _environment.default.constants.WORK_HOURS_FIXED_DAY) {
          return this.get('hoursMonday') + this.get('hoursTuesday') + this.get('hoursWednesday') + this.get('hoursThursday') + this.get('hoursFriday') + this.get('hoursSaturday') + this.get('hoursSunday');
        } else if (type === _environment.default.constants.WORK_HOURS_FIXED_WEEK) {
          return this.get('hoursWeekly');
        } else {
          return 0;
        }
      }
    }),
    localizedEmploymentType: Ember.computed('employmentType', function () {
      var i18n = this.get('i18n');
      var employmentType = this.get('employmentType');
      return i18n.t(_environment.default.constants.USER_TYPE_I18N_KEYs[employmentType]);
    }),
    validFromTimestamp: Ember.computed('validFrom', {
      get: function get() {
        return moment(this.get('validFrom')).unix();
      }
    }),
    yearlyRemuneration: Ember.computed('monthlyRemuneration', {
      get: function get() {
        return this.get('monthlyRemuneration') * 12;
      }
    }),
    isWorkTypeFlexible: Ember.computed.equal('workHoursType', _environment.default.constants.WORK_HOURS_FLEXIBLE),
    isWorkTypeFixedDay: Ember.computed.equal('workHoursType', _environment.default.constants.WORK_HOURS_FIXED_DAY),
    isWorkTypeFixedWeek: Ember.computed.equal('workHoursType', _environment.default.constants.WORK_HOURS_FIXED_WEEK),
    isWorkTypeFixed: Ember.computed.or('isWorkTypeFixedDay', 'isWorkTypeFixedWeek'),
    isFormer: Ember.computed.equal('employmentType', _environment.default.constants.USER_TYPE_FORMER),
    isEmployee: Ember.computed.equal('employmentType', _environment.default.constants.USER_TYPE_EMPLOYEE),
    isFreelancer: Ember.computed.equal('employmentType', _environment.default.constants.USER_TYPE_FREELANCER),

    /**
     * @param {Integer} isoWeekDay the specific day as index
     * @return {Integer} the amount of of required hours for a specific day
     */
    getHoursByDay: function getHoursByDay(isoWeekDay) {
      switch (isoWeekDay) {
        case 1:
          return this.get('hoursMonday');

        case 2:
          return this.get('hoursTuesday');

        case 3:
          return this.get('hoursWednesday');

        case 4:
          return this.get('hoursThursday');

        case 5:
          return this.get('hoursFriday');

        case 6:
          return this.get('hoursSaturday');

        case 7:
          return this.get('hoursSunday');
      }
    },
    applyDefaults: function applyDefaults() {
      this._updateEmploymentDefaults();

      this._updateWorkHourDefaults();
    },
    hasFixedHourlyCosts: Ember.computed('employmentType', function () {
      return [_environment.default.constants.USER_TYPE_FREELANCER, _environment.default.constants.USER_TYPE_EMPLOYEE_HOURLY, _environment.default.constants.USER_TYPE_FREELANCER_HOURLY].includes(this.get('employmentType'));
    }),
    _updateEmploymentDefaults: function _updateEmploymentDefaults() {
      var model = this;

      switch (model.get('employmentType')) {
        case _environment.default.constants.USER_TYPE_EMPLOYEE:
          model.set('hourlyRemuneration', null);

          if (model.get('employerContribution') === null) {
            model.set('employerContribution', 0);
          }

          if (model.get('monthlyRemuneration') === null) {
            model.set('monthlyRemuneration', 0);
          }

          if (model.get('workHoursType') === null) {
            model.set('workHoursType', _environment.default.constants.WORK_HOURS_FIXED_DAY);
          }

          break;

        case _environment.default.constants.USER_TYPE_FREELANCER:
        case _environment.default.constants.USER_TYPE_EMPLOYEE_HOURLY:
        case _environment.default.constants.USER_TYPE_FREELANCER_HOURLY:
          model.set('monthlyRemuneration', null);
          model.set('employerContribution', null);

          if (model.get('hourlyRemuneration') === null) {
            model.set('hourlyRemuneration', 0);
          }

          if (model.get('workHoursType') === null) {
            model.set('workHoursType', _environment.default.constants.WORK_HOURS_FIXED_DAY);
          }

          break;

        case _environment.default.constants.USER_TYPE_FORMER:
        default:
          model.set('hourlyRemuneration', null);
          model.set('monthlyRemuneration', null);
          model.set('employerContribution', null);
          model.set('workHoursType', null);
          break;
      }
    },
    _updateWorkHourDefaults: function _updateWorkHourDefaults() {
      var model = this;
      var workHoursType = model.get('workHoursType');

      if (!this.get('activeWeekends')) {
        model.set('hoursSaturday', 0);
        model.set('hoursSunday', 0);
      }

      if (workHoursType === _environment.default.constants.WORK_HOURS_FIXED_WEEK) {
        model.set('hoursMonday', null);
        model.set('hoursTuesday', null);
        model.set('hoursWednesday', null);
        model.set('hoursThursday', null);
        model.set('hoursFriday', null);
        model.set('hoursSaturday', null);
        model.set('hoursSunday', null);

        if (model.get('hoursWeekly') === null) {
          model.set('hoursWeekly', 0);
        }
      } else if (workHoursType === _environment.default.constants.WORK_HOURS_FIXED_DAY) {
        model.set('hoursWeekly', null);

        if (isNaN(parseFloat(model.get('hoursMonday')))) {
          model.set('hoursMonday', 0);
        }

        if (isNaN(parseFloat(model.get('hoursTuesday')))) {
          model.set('hoursTuesday', 0);
        }

        if (isNaN(parseFloat(model.get('hoursWednesday')))) {
          model.set('hoursWednesday', 0);
        }

        if (isNaN(parseFloat(model.get('hoursThursday')))) {
          model.set('hoursThursday', 0);
        }

        if (isNaN(parseFloat(model.get('hoursFriday')))) {
          model.set('hoursFriday', 0);
        }

        if (isNaN(parseFloat(model.get('hoursSaturday')))) {
          model.set('hoursSaturday', 0);
        }

        if (isNaN(parseFloat(model.get('hoursSunday')))) {
          model.set('hoursSunday', 0);
        }
      } else {
        model.set('hoursWeekly', null);
        model.set('hoursMonday', null);
        model.set('hoursTuesday', null);
        model.set('hoursWednesday', null);
        model.set('hoursThursday', null);
        model.set('hoursFriday', null);
        model.set('hoursSaturday', null);
        model.set('hoursSunday', null);
      }
    }
  });

  var _default = UserEmployment;
  _exports.default = _default;
});