define("coffeecup/templates/components/planner/project-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jv5wxRDC",
    "block": "{\"symbols\":[\"@project\",\"@user\"],\"statements\":[[8,\"planner/project-user-info\",[],[[\"@editable\",\"@project\",\"@user\",\"@showTimeEntries\"],[[32,0,[\"editable\"]],[32,0,[\"project\"]],[32,0,[\"user\"]],[30,[36,2],[[32,0],\"showTimeEntries\"],null]]],null],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isSetup\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,0,[\"viewport\",\"showSummary\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"planner/user-project-summary\",[],[[\"@project\",\"@user\",\"@roundedHoursPlanned\",\"@tooltipText\"],[[32,0,[\"project\"]],[32,0,[\"user\"]],[32,0,[\"projectHoursPlannedForScope\"]],[30,[36,0],[\"plannedHoursScope\"],[[\"firstname\",\"project\"],[[32,2,[\"firstname\"]],[32,1,[\"label\"]]]]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"planner/project-user-timeline\",[],[[\"@user\",\"@assignments\",\"@project\",\"@editable\",\"@editAssignment\",\"@splitAssignment\",\"@afterUpdateAssignment\",\"@createAssignment\"],[[32,0,[\"user\"]],[32,0,[\"projectUserAssignments\"]],[32,0,[\"project\"]],[32,0,[\"editable\"]],\"editAssignment\",\"splitAssignment\",\"afterUpdateAssignment\",\"createAssignment\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/project-user.hbs"
    }
  });

  _exports.default = _default;
});