define("coffeecup/templates/components/input-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kh8FFmKB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,\"for\",[34,2]],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[1,[30,[36,11],null,[[\"id\",\"type\",\"maxlength\",\"class\",\"value\",\"placeholder\",\"key\"],[[35,2],[35,10],[35,9],[35,8],[35,7],[35,6],[35,5]]]]],[2,\"\\n\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"input-group-addon\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"lastAddon\",\"firstAddon\",\"inputId\",\"label\",\"if\",\"key\",\"placeholder\",\"value\",\"inputClasses\",\"maxlength\",\"inputType\",\"input\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input-group.hbs"
    }
  });

  _exports.default = _default;
});