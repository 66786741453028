define("coffeecup/templates/components/manage-table/cells/delta-time-advance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Al7rAYod",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isProject\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[32,1,[\"isModeNotBillable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      -\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[15,5,[30,[36,1],[[30,[36,0],[0,[32,0,[\"projectValue\"]]],null],\"color: #e25f77\"],null]],[12],[2,\"\\n        \"],[1,[30,[36,2],[[32,0,[\"projectValue\"]],\"%\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[15,5,[30,[36,1],[[30,[36,0],[0,[32,0,[\"projectValue\"]]],null],\"color: #e25f77\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,2],[[32,0,[\"clientValue\"]],\"%\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"gt\",\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/delta-time-advance.hbs"
    }
  });

  _exports.default = _default;
});