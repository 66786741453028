define("coffeecup/templates/components/dashboard-module/user-remuneration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jCjtGU5a",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"effort-module\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"module-title\"],[12],[2,\"\\n    \"],[1,[30,[36,10],null,[[\"title\",\"actionNames\",\"draggable\",\"modes\",\"startAction\"],[[30,[36,7],[\"remunerationDevelopment\"],null],[35,1],[35,9],[35,8],\"startAction\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"module-body\"],[12],[2,\"\\n\"],[6,[37,12],[[35,11]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"chart-replace text-center\"],[12],[2,\"\\n        \"],[1,[30,[36,7],[\"dashboardRemunerationNoEntries\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[15,1,[31,[\"salary-chart-div-\",[34,6]]]],[14,5,\"height: 265px; width: 100%;\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,14],[[30,[36,13],[[32,0,[\"salaryTypes\",\"length\"]],1],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"module-footer\"],[12],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"presentationModes\",\"selectedPresentationMode\",\"currentMode\",\"actionNames\",\"modes\",\"startAction\"],[[35,4],[35,3],[35,2],[35,1],[35,0],\"startAction\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"salaryTypes\",\"actionNames\",\"selectedSalaryType\",\"selectedPresentationMode\",\"presentationModes\",\"dashboard-module/summary-bar\",\"elementId\",\"t\",\"modes\",\"draggable\",\"dashboard-module/top-bar\",\"remunerationGraphData\",\"unless\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard-module/user-remuneration.hbs"
    }
  });

  _exports.default = _default;
});