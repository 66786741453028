define("coffeecup/instance-initializers/stripe", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var stripeService = appInstance.lookup('service:stripe');
    var stripeKey = _environment.default.APP && _environment.default.APP.STRIPE_CLIENT_KEY;

    if (stripeKey) {
      stripeService._stripe = Stripe(stripeKey);
    }
  }

  var _default = {
    name: 'stripe',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});