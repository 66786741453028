define("coffeecup/templates/planner/split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D4qY3k16",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"planner/popover-split\",[],[[\"@openModal\",\"@model\",\"@splitDate\",\"@transitionTo\",\"@afterSave\",\"@editAfterSave\"],[true,[32,1],[32,0,[\"splitDate\"]],[30,[36,0],[\"transitionToPlanner\"],null],[30,[36,0],[\"refreshPlannerData\"],null],[30,[36,0],[\"editAfterSave\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/planner/split.hbs"
    }
  });

  _exports.default = _default;
});