define("coffeecup/routes/account/password", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //
  var PasswordRoute = _superRoute.default.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('password');
    }
  });

  var _default = PasswordRoute;
  _exports.default = _default;
});