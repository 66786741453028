define("coffeecup/templates/components/list-item-analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LI7KvoCB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h6\"],[12],[1,[34,10]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"values \",[30,[36,2],[[35,11],\"small\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[35,12]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"amount \",[30,[36,2],[[35,7],\"negative-value\"],null],\" \",[30,[36,2],[[35,1],\"single\"],null]]]],[12],[1,[30,[36,9],[[35,8],\"auto\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[35,13]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"hours \",[30,[36,2],[[35,1],\"single\"],null]]]],[12],[1,[30,[36,6],[[35,5]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[35,14]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"hours \",[30,[36,2],[[35,1],\"single\"],null]]]],[12],[1,[30,[36,4],[[35,3]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[35,15]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"gray\"],[12],[1,[30,[36,0],[\"notAvailableShort\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"hasSingleValue\",\"if\",\"percent\",\"percent-fmt\",\"hours\",\"hours-fmt\",\"amountIsNegative\",\"amount\",\"currency-fmt\",\"label\",\"smallValues\",\"hasAmount\",\"hasHours\",\"hasPercent\",\"notAvailable\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/list-item-analytics.hbs"
    }
  });

  _exports.default = _default;
});