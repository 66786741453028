define("coffeecup/routes/analytics/time-entries/edit", ["exports", "coffeecup/routes/time-entries/tracking/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _edit.default.extend({
    controllerName: 'time-entries.tracking.edit',
    templateName: 'time-entries.tracking.edit',
    actions: {
      transitionToList: function transitionToList() {
        this.transitionTo('analytics.time-entries');
        var listController = this.controllerFor('analytics.time-entries');
        Ember.run.later(function () {
          return listController.refreshAnalytics();
        }, 1000);
      },
      timeEntryDeleted: function timeEntryDeleted() {}
    }
  });

  _exports.default = _default;
});