define("coffeecup/models/expense", ["exports", "ember-data", "coffeecup/utils/build-url", "coffeecup/models/-super-model"], function (_exports, _emberData, _buildUrl, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    session: Ember.inject.service(),
    formatter: Ember.inject.service(),
    project: _emberData.default.belongsTo('project', {
      async: true
    }),
    expenseCategory: _emberData.default.belongsTo('expense-category', {
      async: true
    }),
    description: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    priceInternal: _emberData.default.attr('number'),
    // the amount my company has to pay,
    priceExternal: _emberData.default.attr('number'),
    // the amount I charge the client; only applicable to time and material projects
    inBudget: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    isBillable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    billedAt: _emberData.default.attr('datestamp'),
    day: _emberData.default.attr('datestamp'),
    cost: Ember.computed.alias('priceExternal'),
    invoices: _emberData.default.belongsTo('invoice', {
      async: true
    }),
    calculatedPriceFormatted: Ember.computed('quantity', 'expenseCategory.pricePerUnit', function () {
      var formatter = this.get('formatter');
      var quantity = this.get('quantity');
      var pricePerUnit = formatter.formatCurrency(this.get('expenseCategory.pricePerUnit'));
      var unit = this.get('expenseCategory.unit');
      return "".concat(quantity, " ").concat(unit, " * ").concat(pricePerUnit, "/").concat(unit);
    }),
    _attachment: null,
    attachment: Ember.computed('_attachment', {
      get: function get() {
        var _this = this;

        //TODO this func as helper, similar to attachmentUpload
        var attachment = this.get('_attachment');
        var id = this.get('id');

        if (!id) {
          return;
        }

        if (attachment === null) {
          this.get('session').getJSON((0, _buildUrl.default)(this.store, 'files/list/expense/' + id)).then(function (attachments) {
            _this.set('_attachment', attachments.get(0));
          });
        } else {
          return attachment;
        }
      },
      set: function set(k, v) {
        if (v && v.id) {
          this.set('_attachment', v);
        }

        return v;
      }
    })
  });

  _exports.default = _default;
});