define("coffeecup/models/color", ["exports", "ember-data", "coffeecup/models/-super-model", "coffeecup/utils/color-hex2rgba"], function (_exports, _emberData, _superModel, _colorHex2rgba) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Color = _superModel.default.extend({
    status: _emberData.default.attr('number'),
    order: _emberData.default.attr('number'),
    label: _emberData.default.attr('string'),
    hex: _emberData.default.attr('string'),
    red: Ember.computed('hex', {
      get: function get() {
        var hex = this.get('hex');
        return parseInt(hex.substring(0, 2), 16);
      }
    }),
    green: Ember.computed('hex', {
      get: function get() {
        var hex = this.get('hex');
        return parseInt(hex.substring(2, 4), 16);
      }
    }),
    blue: Ember.computed('hex', {
      get: function get() {
        var hex = this.get('hex');
        return parseInt(hex.substring(4, 6), 16);
      }
    }),
    luminance: Ember.computed('hex', {
      get: function get() {
        var hex = this.get('hex');

        if (hex) {
          var r = parseInt(hex.substring(0, 2), 16);
          var g = parseInt(hex.substring(2, 4), 16);
          var b = parseInt(hex.substring(4, 6), 16); // standard luminance formula for rgb devices
          // http://stackoverflow.com/questions/596216/formula-to-determine-brightness-of-rgb-color

          return Math.floor(0.2126 * r + 0.7152 * g + 0.0722 * b);
        } else {
          return 1;
        }
      }
    }),
    // computed properties
    hexString: Ember.computed('hex', {
      get: function get() {
        var hex = this.get('hex');

        if (hex) {
          return '#' + hex.toLowerCase();
        }
      }
    }),
    hexString10: Ember.computed('hexString', {
      get: function get() {
        var hex = this.get('hexString');
        var opacity = 10;

        if (hex) {
          return (0, _colorHex2rgba.convertHex2Rgba)(hex, opacity);
        }
      }
    }),
    hexString15: Ember.computed('hexString', {
      get: function get() {
        var hex = this.get('hexString');
        var opacity = 15;

        if (hex) {
          return (0, _colorHex2rgba.convertHex2Rgba)(hex, opacity);
        }
      }
    }),
    hexString30: Ember.computed('hexString', {
      get: function get() {
        var hex = this.get('hexString');
        var opacity = 30;

        if (hex) {
          return (0, _colorHex2rgba.convertHex2Rgba)(hex, opacity);
        }
      }
    }),
    hexString40: Ember.computed('hexString', {
      get: function get() {
        var hex = this.get('hexString');
        var opacity = 40;

        if (hex) {
          return (0, _colorHex2rgba.convertHex2Rgba)(hex, opacity);
        }
      }
    }),
    hexString50: Ember.computed('hexString', {
      get: function get() {
        var hex = this.get('hexString');
        var opacity = 50;

        if (hex) {
          return (0, _colorHex2rgba.convertHex2Rgba)(hex, opacity);
        }
      }
    }),
    hexString60: Ember.computed('hexString', {
      get: function get() {
        var hex = this.get('hexString');
        var opacity = 60;

        if (hex) {
          return (0, _colorHex2rgba.convertHex2Rgba)(hex, opacity);
        }
      }
    }),
    hexString70: Ember.computed('hexString', {
      get: function get() {
        var hex = this.get('hexString');
        var opacity = 70;

        if (hex) {
          return (0, _colorHex2rgba.convertHex2Rgba)(hex, opacity);
        }
      }
    }),
    hexString80: Ember.computed('hexString', {
      get: function get() {
        var hex = this.get('hexString');
        var opacity = 80;

        if (hex) {
          return (0, _colorHex2rgba.convertHex2Rgba)(hex, opacity);
        }
      }
    }),
    hexString90: Ember.computed('hexString', {
      get: function get() {
        var hex = this.get('hexString');
        var opacity = 90;

        if (hex) {
          return (0, _colorHex2rgba.convertHex2Rgba)(hex, opacity);
        }
      }
    }),
    darker30: Ember.computed('hexString', function () {
      var hexString = this.get('hexString');

      var rgb = _colorHex2rgba.RGBA.convertFromHex(hexString);

      rgb.darken(30);
      return rgb.toCSS();
    })
  });

  var _default = Color;
  _exports.default = _default;
});