define("coffeecup/templates/components/bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kw4USH/J",
    "block": "{\"symbols\":[\"legendItem\"],\"statements\":[[6,[37,4],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"additional-chart-information hidden-xs\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[32,1,[\"hideElement\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"content\",\"class\"],[[32,1,[\"tooltip\"]],\"legend-item\"]],[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"color\"]]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[10,\"div\"],[14,0,\"marker\"],[15,5,[30,[36,0],[\"background: \",[32,1,[\"color\"]],\";\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[10,\"span\"],[12],[1,[30,[36,2],[[32,1,[\"title\"]]],null]],[2,\": \"],[10,\"b\"],[14,0,\"no-wrap\"],[12],[1,[32,1,[\"titleValue\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"loading-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"loading-pulse\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,1,[31,[\"chartdiv-\",[34,9]]]],[15,5,[34,10]],[12],[13]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"if\",\"t\",\"tool-tipster\",\"unless\",\"legendItems\",\"-track-array\",\"each\",\"isLoading\",\"chartId\",\"barChartStyle\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/bar-chart.hbs"
    }
  });

  _exports.default = _default;
});