define("coffeecup/models/planner-assignment", ["exports", "ember-data", "coffeecup/utils/date", "coffeecup/utils/allocation-math", "coffeecup/config/environment", "coffeecup/models/-super-model"], function (_exports, _emberData, _date, _allocationMath, _environment, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    workingDays: Ember.inject.service(),
    plannerViewport: Ember.inject.service('planner.viewport'),
    activeOnDaysOff: _emberData.default.attr('boolean'),
    allocation: _emberData.default.attr('hours-as-seconds'),
    allocationAsInt: Ember.computed('allocation', {
      get: function get() {
        return parseInt(this.get('allocation'));
      }
    }),

    /**
     * {String} startDate - Localized format which could be either DD.MM.YYYY or MM/DD/YYYY depending on global Moment settings
     */
    startDate: _emberData.default.attr('datestamp'),
    // observers should always use startDateFmt
    startDateFmt: Ember.computed('startDate', {
      get: function get() {
        var startDate = this.get('startDate');

        if (startDate && (0, _date.isDateValid)(startDate)) {
          return startDate.format('L');
        }
      }
    }),

    /**
     * {String} endDate - Localized format which could be either DD.MM.YYYY or MM/DD/YYYY depending on global Moment settings
     */
    endDate: _emberData.default.attr('datestamp'),
    // observers should always use endDateFmt
    endDateFmt: Ember.computed('endDate', {
      get: function get() {
        var endDate = this.get('endDate');

        if (endDate && (0, _date.isDateValid)(endDate)) {
          return endDate.format('L');
        }
      }
    }),

    /**
     * {String} comment
     */
    comment: _emberData.default.attr('string'),
    splitDate: _emberData.default.attr('datestamp'),
    updatedAt: _emberData.default.attr('utc-moment'),
    // relations
    user: _emberData.default.belongsTo('user', {
      async: false
    }),
    project: _emberData.default.belongsTo('project', {
      async: false
    }),
    // plannerAssignmentSet: DS.belongsTo('plannerAssignmentSet', {async: true}),
    //computed properties
    isActive: Ember.computed.and('user.isActive', 'project.isActiveAndLoaded'),
    isInactive: Ember.computed.not('isActive'),
    status: Ember.computed('isActive', function () {
      return this.get('isActive') ? _environment.default.constants.STATUS_ENABLED : _environment.default.constants.STATUS_ARCHIVED;
    }).readOnly(),
    isWeeklyPlanning: Ember.computed.reads('plannerViewport.isWeeklyPlanningMode'),
    totalAllocation: Ember.computed('allocation', 'startDate', 'endDate', 'user.id', 'user.absences.@each.duration', {
      get: function get() {
        // TODO cache these values
        var user = this.get('user');
        var startDate = this.get('startDate');
        var endDate = this.get('endDate');
        var absences = this.get('user.absences');
        var useHalfDays = this.get('planner.is');
        var totalDays = this.get('workingDays').getWorkingDaysOfTimePeriod(user, startDate, endDate, absences, this.get('isWeeklyPlanning'));
        return _allocationMath.default.multiplyAllocation(this.get('allocation'), totalDays);
      }
    }),
    allocationForPeriod: function allocationForPeriod(testStartDate, testEndDate, absences) {
      var thisStartDate = this.get('startDate');
      var thisEndDate = this.get('endDate');

      if (!(0, _date.isRangeWithinRange)(testStartDate, testEndDate, thisStartDate, thisEndDate)) {
        return 0;
      }

      var startDate = testStartDate;
      var endDate = testEndDate;

      if (thisStartDate > testStartDate) {
        startDate = thisStartDate;
      }

      if (thisEndDate < testEndDate) {
        endDate = thisEndDate;
      }

      var user = this.get('user');
      var userAbsences = absences || this.get('user.absences');
      var totalDays = this.get('workingDays').getWorkingDaysOfTimePeriod(user, startDate, endDate, userAbsences, true);
      return _allocationMath.default.multiplyAllocation(this.get('allocation'), totalDays);
    },
    includesDate: function includesDate(date) {
      return (0, _date.isInRange)(date, this.get('startDate'), this.get('endDate'));
    },
    isActiveOnDate: function isActiveOnDate(date) {
      if (this.includesDate(date)) {
        return true;
      }

      if (this.get('activeOnDaysOff')) {
        return true;
      }

      return false;
    },
    clone: function clone() {
      return this.getProperties(['startDate', 'endDate', 'user', 'project', 'comment', 'allocation', 'activeOnDaysOff', 'plannerAssignmentSet']);
    },
    overlaps: function overlaps(otherAssignment) {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      var otherAssignmentStartDate = otherAssignment.get('startDate');
      var otherAssignmentEndDate = otherAssignment.get('endDate');
      return (0, _date.isRangeWithinRange)(startDate, endDate, otherAssignmentStartDate, otherAssignmentEndDate);
    },
    siblings: function siblings() {
      return this.get('user').get('plannerAssignments').filterBy('project.id', this.get('project.id')).rejectBy('id', this.get('id'));
    },
    overlapsAnySibling: function overlapsAnySibling() {
      var siblings = this.siblings();
      var overlaps = false;

      for (var i = 0; i < siblings.length; i++) {
        if (!overlaps && this.overlaps(siblings.objectAt(i))) {
          overlaps = true;
        }
      }

      return overlaps;
    },
    inRange: function inRange(startDate, endDate) {
      return (0, _date.isRangeWithinRange)(this.get('startDate'), this.get('endDate'), startDate, endDate);
    },
    trimWeekends: Ember.observer('startDate', 'endDate', function () {
      Ember.run.once(this, '_trimWeekends');
    }),
    _trimWeekends: function _trimWeekends() {
      var showWeekends = this.get('workingDays.showWeekends');
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');

      if (!showWeekends && startDate && endDate) {
        if (startDate.isWeekend()) {
          startDate.nextBusinessDay();
        }

        if (endDate.isWeekend()) {
          endDate.prevBusinessDay();
        }

        if (endDate.isBefore(startDate)) {
          this.set('endDate', startDate.clone());
        }
      }
    }
  });

  _exports.default = _default;
});