define("coffeecup/instance-initializers/reference-platforms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'reference-platform',
        id: 'jira',
        attributes: {
          name: 'Jira',
          iconUrl: '/assets/images/reference-platforms/jira.svg',
          iconDarkUrl: '/assets/images/reference-platforms/jira.dark.svg',
          iconLightUrl: '/assets/images/reference-platforms/jira.light.svg',
          urlPlaceholder: 'https://coffeecupapp.atlassian.com',
          scopePlaceholder: 'CC',
          noScopePreview: 'TICKET',
          supportsAutoCollection: true,
          scopeLabelTranslationKey: 'referencesScopedProject',
          noScopeLabelTranslationKey: 'referencesAllProjects',
          scopeInputLabelTranslationKey: 'referencesScopedLabelProjectPrefix',
          displayShortCode: true
        }
      }, {
        type: 'reference-platform',
        id: 'github',
        attributes: {
          name: 'GitHub',
          iconUrl: '/assets/images/reference-platforms/github.svg',
          iconColorDarkUrl: '/assets/images/reference-platforms/github.dark.svg',
          iconDarkUrl: '/assets/images/reference-platforms/github.dark.svg',
          iconLightUrl: '/assets/images/reference-platforms/github.light.svg',
          urlPlaceholder: 'https://github.com/coffeecupapp',
          scopePlaceholder: 'repository-name',
          noScopePreview: 'all-repositories',
          supportsAutoCollection: true,
          scopeLabelTranslationKey: 'referencesScopedRepository',
          noScopeLabelTranslationKey: 'referencesAllRepositories',
          scopeInputLabelTranslationKey: 'referencesScopedLabelRepositoryName',
          displayShortCode: true
        }
      }, {
        type: 'reference-platform',
        id: 'monday',
        attributes: {
          name: 'Monday',
          iconUrl: '/assets/images/reference-platforms/monday.svg',
          iconDarkUrl: '/assets/images/reference-platforms/monday.dark.svg',
          iconLightUrl: '/assets/images/reference-platforms/monday.light.svg',
          urlPlaceholder: 'coffeecup.monday.com',
          scopePlaceholder: '123456789',
          noScopePreview: 'ALL-BOARDS',
          supportsAutoCollection: true,
          scopeLabelTranslationKey: 'referencesScopedBoard',
          noScopeLabelTranslationKey: 'referencesAllBoards',
          scopeInputLabelTranslationKey: 'referencesScopedLabelBoardId',
          displayShortCode: true
        }
      }, {
        type: 'reference-platform',
        id: 'gitlab',
        attributes: {
          name: 'GitLab',
          iconUrl: '/assets/images/reference-platforms/gitlab.svg',
          iconDarkUrl: '/assets/images/reference-platforms/gitlab.dark.svg',
          iconLightUrl: '/assets/images/reference-platforms/gitlab.light.svg',
          urlPlaceholder: 'gitlab.com/coffeecupapp',
          scopePlaceholder: 'repository-name',
          noScopePreview: 'all-repositories',
          supportsAutoCollection: true,
          scopeLabelTranslationKey: 'referencesScopedRepository',
          noScopeLabelTranslationKey: 'referencesAllRepositories',
          scopeInputLabelTranslationKey: 'referencesScopedLabelRepositoryName',
          displayShortCode: true
        }
      }, {
        type: 'reference-platform',
        id: 'azure',
        attributes: {
          name: 'Azure DevOps',
          iconUrl: '/assets/images/reference-platforms/azure.svg',
          iconDarkUrl: '/assets/images/reference-platforms/azure.dark.svg',
          iconLightUrl: '/assets/images/reference-platforms/azure.light.svg',
          urlPlaceholder: 'dev.azure.com/coffeecup',
          scopePlaceholder: 'Project Name',
          noScopePreview: 'all-projects',
          supportsAutoCollection: true,
          scopeLabelTranslationKey: 'referencesScopedProject',
          noScopeLabelTranslationKey: 'referencesAllProjects',
          scopeInputLabelTranslationKey: 'referencesScopedLabelProjectName',
          displayShortCode: true
        }
      }, {
        type: 'reference-platform',
        id: 'clickup',
        attributes: {
          name: 'ClickUp',
          iconUrl: '/assets/images/reference-platforms/clickup.svg',
          iconDarkUrl: '/assets/images/reference-platforms/clickup.dark.svg',
          iconLightUrl: '/assets/images/reference-platforms/clickup.light.svg',
          supportsAutoCollection: true,
          displayShortCode: true
        }
      }, {
        type: 'reference-platform',
        id: 'asana',
        attributes: {
          name: 'Asana',
          iconUrl: '/assets/images/reference-platforms/asana.svg',
          iconDarkUrl: '/assets/images/reference-platforms/asana.dark.svg',
          iconLightUrl: '/assets/images/reference-platforms/asana.light.svg',
          supportsAutoCollection: true,
          displayShortCode: false
        }
      }, {
        type: 'reference-platform',
        id: 'linear',
        attributes: {
          name: 'Linear',
          iconUrl: '/assets/images/reference-platforms/linear.svg',
          iconDarkUrl: '/assets/images/reference-platforms/linear.dark.svg',
          iconLightUrl: '/assets/images/reference-platforms/linear.light.svg',
          urlPlaceholder: 'https://linear.app/coffeecup/',
          scopePlaceholder: 'CC',
          noScopePreview: 'TICKET',
          supportsAutoCollection: true,
          scopeLabelTranslationKey: 'referencesScopedProject',
          noScopeLabelTranslationKey: 'referencesAllProjects',
          scopeInputLabelTranslationKey: 'referencesScopedLabelProjectPrefix',
          displayShortCode: true
        }
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'reference-platforms',
    after: 'ember-simple-auth',
    initialize: initialize
  };
  _exports.default = _default;
});