define("coffeecup/templates/components/manage-table/cells/boolean-check-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qL8KG/Gn",
    "block": "{\"symbols\":[\"@config\",\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell boolean-check-cell\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"check-icon icomoon-checkmark \",[30,[36,1],[[30,[36,0],[[32,2],[32,1,[\"propertyName\"]]],null],\"checked\",\"unchecked\"],null]]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"get\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/boolean-check-cell.hbs"
    }
  });

  _exports.default = _default;
});