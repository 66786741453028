define("coffeecup/templates/components/dashboard/my-week-module/planning-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n1zOLc+j",
    "block": "{\"symbols\":[\"@plannedTrackedData\"],\"statements\":[[11,\"div\"],[24,0,\"planning-progress-chart-element\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[32,0,[\"redirectToStatistics\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"planning-progress-chart-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"planning-progress-chart-label\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[30,[36,1],[[32,0,[\"permissions\",\"plannerActive\"]],[32,1,[\"plannedHours\"]],[32,1,[\"trackedHours\"]]],null],\"0.0\"],null]],[2,\"\\n        \"],[10,\"span\"],[14,0,\"hours-label\"],[12],[2,\"\\n          h\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"piechartdiv-\",[32,0,[\"elementId\"]],\" planning-progress-am-chart\"]]],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[15,\"title\",[32,1,[\"project\",\"uppercaseCode\"]]],[14,0,\"planning-progress-chart-project text-overflow text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"project\"]],[32,1,[\"project\",\"uppercaseCode\"]],[30,[36,3],[\"additional\"],[[\"total\"],[[32,1,[\"numberOfProjects\"]]]]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"if\",\"number-fmt\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/my-week-module/planning-chart.hbs"
    }
  });

  _exports.default = _default;
});