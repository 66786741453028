define("coffeecup/templates/components/input-percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a+MI2Mqm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"controls input-group\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"type\",\"class\",\"value\",\"focus-out\",\"focus-in\"],[\"text\",\"form-control\",[35,1],\"onBlur\",\"onFocus\"]]]],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"input-group-btn input-group-btn-stepper\"],[12],[2,\"\\n      \"],[11,\"a\"],[24,0,\"btn-plus btn btn-default has-border btn-small\"],[4,[38,0],[[32,0],\"add\"],null],[12],[10,\"i\"],[14,0,\"icon icomoon-plus3\"],[12],[13],[13],[2,\"\\n      \"],[11,\"a\"],[24,0,\"btn-minus btn btn-default has-border btn-small\"],[4,[38,0],[[32,0],\"sub\"],null],[12],[10,\"i\"],[14,0,\"icon-minus-1\"],[12],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"percentageFmt\",\"input\",\"steps\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input-percentage.hbs"
    }
  });

  _exports.default = _default;
});