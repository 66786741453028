define("coffeecup/validations/client", ["exports", "ember-changeset-validations/validators", "coffeecup/validators/validateUnique"], function (_exports, _validators, _validateUnique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      name: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      code: {
        presence: true,
        unique: true,
        length: {
          maximum: 5
        }
      },
      comment: {
        length: {
          maximum: 10000
        }
      },
      website: {
        length: {
          maximum: 255
        }
      },
      hourlyRate: {
        length: {
          maximum: 15
        }
      }
    },
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    code: [(0, _validators.validatePresence)(true), (0, _validateUnique.default)(), (0, _validators.validateLength)({
      max: 5
    })],
    comment: [(0, _validators.validateLength)({
      max: 10000
    })],
    website: [(0, _validators.validateLength)({
      max: 255
    }), (0, _validators.validateFormat)({
      allowBlank: true,
      regex: /^((http:\/\/www\.)|(www\.)|(http:\/\/)|(https:\/\/))*[a-zA-Z0-9._-]+\.[a-zA-Z.]{2,5}?([\d\w\.\/\%\+\-\=\&amp;\?\:\\\&quot;\'\,\|\~\;]*)$/
    })],
    hourlyRate: [(0, _validators.validateLength)({
      max: 15
    })]
  };
  _exports.default = _default;
});