define("coffeecup/templates/components/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jQiyYKwW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"button\"],[15,0,[31,[\"btn btn-noborder date-picker \",[34,0]]]],[15,\"disabled\",[34,1]],[14,4,\"button\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"h2\"],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icomoon-calendar\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"btnClass\",\"disabled\",\"dateFMT\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/date-picker.hbs"
    }
  });

  _exports.default = _default;
});