define("coffeecup/routes/planner/team/edit", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    templateName: 'planner.edit',
    parentRoute: 'planner.team',
    viewport: Ember.inject.service('planner.viewport'),
    activeModal: Ember.inject.service('planner.active-modal'),
    model: function model(params, transition) {
      if (params && params.assignment_id) {
        transition.send('transitionToPlanner'); // TODO: enable deeplinking, note: transformDataIntoStreams must be called in order to have user.absences populated
        // return this.store.findRecord('planner-assignment', params.assignment_id);
      }
    },
    actions: {
      transitionToPlanner: function transitionToPlanner() {
        this.get('activeModal').setPlannerAssignment(null);
        var parentRoute = this.get('parentRoute');

        if (parentRoute) {
          this.transitionTo(parentRoute);
        }
      },
      deleteAssignment: function deleteAssignment(assignment, user, project) {
        this.controllerFor('planner').send('deleteAssignment', assignment, user, project);
      },
      refreshPlannerData: function refreshPlannerData(assignment) {
        this.controllerFor('planner').send('refreshPlannerData', assignment);
      },
      assignToAnotherUser: function assignToAnotherUser(plannerAssignment, oldUser, newUser) {
        oldUser.get('plannerAssignments').removeObject(plannerAssignment);

        if (plannerAssignment.inRange(this.get('viewport.startDate'), this.get('viewport.endDate'))) {
          newUser.get('plannerAssignments').pushObject(plannerAssignment);
        }

        this.controllerFor('planner').send('refreshPlannerData');
      },
      createUserAssignmentIfNotExists: function createUserAssignmentIfNotExists(assignment) {
        this.controllerFor('planner').send('createUserAssignmentIfNotExists', assignment);
      }
    }
  });

  _exports.default = _default;
});