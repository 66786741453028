define("coffeecup/templates/components/manage-table/cells/user-holiday-region", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MYkZKf7H",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell user-holiday-region\"],[15,5,[32,0,[\"safeWidth\"]]],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isTeam\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,0,[\"regionLabel\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/user-holiday-region.hbs"
    }
  });

  _exports.default = _default;
});