define("coffeecup/services/working-days", ["exports", "coffeecup/utils/date"], function (_exports, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),

    /**
     * PROPERTIES
     */
    employmentEntries: [],
    absences: [],
    userHolidays: [],
    employmentEntriesValidFromAsc: ['validFromTimestamp:asc'],
    employmentEntriesSortedByValidFromAsc: Ember.computed.sort('employmentEntries', 'employmentEntriesValidFromAsc'),
    employmentEntriesValidFromDesc: ['validFromTimestamp:desc'],
    employmentEntriesSortedByValidFromDesc: Ember.computed.sort('employmentEntries', 'employmentEntriesValidFromDesc'),
    showWeekends: Ember.computed.reads('accountSettings.activeWeekends'),
    defaultWorkHours: Ember.computed.reads('accountSettings.defaultWorkHoursForPlanner'),

    /**
     * FUNCTIONS
     */
    getWorkingDaysOfAbsence: function getWorkingDaysOfAbsence(user, absence) {
      var _this = this;

      var dayIncrementer = absence.get('halfDay') ? 0.5 : 1;
      var startDate = absence.get('startDate');
      var endDate = absence.get('endDate');
      var employmentEntriesArray = this.getEmploymentEntriesByDate(user, startDate, endDate);
      var workingDays = 0;

      if (employmentEntriesArray.get('length') > 0) {
        employmentEntriesArray.forEach(function (el, index) {
          workingDays += _this._calculateActualWorkingDaysOfPeriod(user, el.startDate, el.endDate, el.employmentEntry, false);
        });
      } else {
        // Fallback if currentUser is not allowed to read employment entry
        workingDays += this._calculateActualWorkingDaysOfPeriod(user, startDate, endDate, null, false);
      }

      return dayIncrementer * workingDays;
    },
    getWorkingDaysOfAbsenceInYear: function getWorkingDaysOfAbsenceInYear(user, absence, year) {
      var _this2 = this;

      var dayIncrementer = absence.get('halfDay') ? 0.5 : 1;
      var startDate = absence.get('startDate');
      var endDate = absence.get('endDate');

      if (!startDate.isSame(endDate, 'year')) {
        startDate = startDate.year().toString() !== year.toString() ? endDate.clone().startOf('year') : startDate;
        endDate = endDate.year().toString() !== year.toString() ? startDate.clone().endOf('year') : endDate;
      }

      var employmentEntriesArray = this.getEmploymentEntriesByDate(user, startDate, endDate);
      var workingDays = 0;

      if (employmentEntriesArray.get('length') > 0) {
        employmentEntriesArray.forEach(function (el, index) {
          workingDays += _this2._calculateActualWorkingDaysOfPeriod(user, el.startDate, el.endDate, el.employmentEntry, false);
        });
      } else {
        // Fallback if currentUser is not allowed to read employment entry
        workingDays += this._calculateActualWorkingDaysOfPeriod(user, startDate, endDate, null, false);
      }

      return dayIncrementer * workingDays;
    },

    /**
     * Searches for the employment entry for that time, calculates amount of weekdays in time period.
     * Compares the with the working days of the employment entry and also checks if there are existing absences between that dates.
     *
     * @param user
     * @param startDate
     * @param endDate
     * @param absences
     * @returns number of used working days
     */
    getWorkingDaysOfTimePeriod: function getWorkingDaysOfTimePeriod(user, startDate, endDate, absences) {
      var _this3 = this;

      var includeHalfDays = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
      var employmentEntriesArray = this.getEmploymentEntriesByDate(user, startDate, endDate);
      var workingDays = 0;

      if (employmentEntriesArray.get('length') > 0) {
        employmentEntriesArray.forEach(function (el, index) {
          workingDays += _this3._calculateActualWorkingDaysOfPeriod(user, el.startDate, el.endDate, el.employmentEntry, absences, includeHalfDays);
        });
      } else {
        // Fallback if currentUser is not allowed to read employment entry
        workingDays = this._calculateActualWorkingDaysOfPeriod(user, startDate, endDate, null, absences, includeHalfDays);
      }

      return workingDays;
    },

    /**
     *
     * @param user
     * @param startDate
     * @param endDate
     * @param absences
     * @returns array of used working days
     */
    getWorkingHoursArray: function getWorkingHoursArray(user, startDate, endDate, absences) {
      var _this4 = this;

      var absenceRequests = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      var employmentEntriesArray = this.getEmploymentEntriesByDate(user, startDate, endDate);
      var workingHoursArray = Ember.A();

      if (employmentEntriesArray.get('length') > 0) {
        employmentEntriesArray.forEach(function (el, index) {
          var usedWeekDays = _this4._getActualWorkingDaysOfPeriod(user, el.startDate, el.endDate, el.employmentEntry, absences, absenceRequests);

          Array.prototype.push.apply(workingHoursArray, usedWeekDays);
        });
      } else {
        // Fallback if currentUser is not allowed to read employment entry
        workingHoursArray = this._getActualWorkingDaysOfPeriod(user, startDate, endDate, null, absences, absenceRequests);
      }

      workingHoursArray = workingHoursArray.sortBy('timestamp');

      if (workingHoursArray && workingHoursArray[0] && startDate.clone().startOf('day').isBefore(moment(workingHoursArray[0]['date']).startOf('day'), 'day')) {
        var _endDate = workingHoursArray[0]['date'].clone().subtract(1, 'day');

        var _weekdaysBeforeFirstEmployment = this._getActualWorkingDaysOfPeriod(user, startDate, _endDate, null, absences, absenceRequests);

        Array.prototype.push.apply(workingHoursArray, _weekdaysBeforeFirstEmployment);
        workingHoursArray = workingHoursArray.sortBy('timestamp');
      }

      return workingHoursArray;
    },

    /**
     *
     * @param {String }user
     * @param {Date} startDate
     * @param {Date} endDate
     * @returns {Array} an array of employment entries that are valid between startDate and endDate
     */
    getEmploymentEntriesByDate: function getEmploymentEntriesByDate(user, startDate, endDate) {
      var employmentEntriesArray = Ember.A();
      var start = startDate.isDateAligned() ? startDate : moment.utc((0, _date.removeTimeZone)(startDate)).startOf('day');
      var end = endDate.isDateAligned() ? endDate : moment.utc((0, _date.removeTimeZone)(endDate)).startOf('day');
      var employmentEntries = this.get('employmentEntriesSortedByValidFromDesc').filter(function (el) {
        return user && el.belongsTo('user').id() === user.get('id') && el.get('validFrom').dateAlignedSameOrBefore(end);
      });
      var endDateCalculated = end.clone(); // hint: `.some()` breaks when the callback returns true.

      employmentEntries.some(function (employmentEntry) {
        var validFrom = employmentEntry.get('validFrom');

        if (validFrom.isBefore(start)) {
          validFrom = start.clone();
        }

        employmentEntriesArray.pushObject({
          startDate: validFrom.clone(),
          endDate: endDateCalculated.clone(),
          employmentEntry: employmentEntry
        });
        endDateCalculated = validFrom.clone().subtract(1, 'day');

        if (employmentEntry.get('validFrom').dateAlignedSameOrBefore(start)) {
          return true;
        }
      });
      return employmentEntriesArray;
    },
    _getUserHolidaysOfPeriod: function _getUserHolidaysOfPeriod(startDate, endDate) {
      return this.get('userHolidays').filter(function (el) {
        return el.get('date').dateAlignedSameOrAfter(startDate) && el.get('date').dateAlignedSameOrBefore(endDate);
      });
    },
    _getActualWorkingDaysOfPeriod: function _getActualWorkingDaysOfPeriod(user, startDate, endDate, employmentEntry) {
      var _this5 = this;

      var absences = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      var absenceRequests = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
      var generalWorkingDaysArray = Ember.A();
      var showWeekends = this.get('showWeekends');
      var defaultWorkHours = this.get('defaultWorkHours'); // get user holidays

      var userHolidaysInTimePeriod = this._getUserHolidaysOfPeriod(startDate, endDate);

      var isWorkTypeFixedDay, isWorkTypeFlexible, isWorkTypeFixedWeek, hoursPerWeek, calculatedHoursPerDay;

      if (employmentEntry) {
        isWorkTypeFixedDay = employmentEntry.get('isWorkTypeFixedDay');
        isWorkTypeFlexible = employmentEntry.get('isWorkTypeFlexible');
        isWorkTypeFixedWeek = employmentEntry.get('isWorkTypeFixedWeek');
        hoursPerWeek = employmentEntry.get('hoursWeekly');
        calculatedHoursPerDay = hoursPerWeek / (showWeekends ? 7 : 5);
      }

      this.eachDay(startDate, endDate, function (date) {
        var day = date.clone();
        var generalWorkingDay = {
          timestamp: date.unix(),
          date: day,
          expectedHours: 0,
          isWorking: false,
          // is false when there is no employment entry, user is absent oder there is a general holiday
          isOff: false,
          // is true, there is no employment entry  oder there is a general holiday
          isAbsent: false,
          // there is absence on that day
          absenceType: null,
          absenceRangeType: null,
          absenceLength: 0,
          isAbsenceRequest: null
        }; // FIRST OF ALL THE GENERAL HOLIDAYS ARE EXCLUDED

        if (userHolidaysInTimePeriod) {
          var isHoliday = userHolidaysInTimePeriod.any(function (el) {
            return (0, _date.isSameDay)(el.get('date'), day);
          });

          if (isHoliday) {
            generalWorkingDay.isWorking = false;
            generalWorkingDay.isOff = true;
            generalWorkingDaysArray.pushObject(generalWorkingDay);
            return true;
          }
        }

        if (isWorkTypeFixedDay) {
          // employment entry can have empty day entries if the account uses showWeekends
          var hoursPerDay = employmentEntry.getHoursByDay(day.day()) || 0;
          generalWorkingDay.isWorking = !!(hoursPerDay && hoursPerDay > 0);
          generalWorkingDay.isOff = !generalWorkingDay.isWorking;
          generalWorkingDay.expectedHours = hoursPerDay;
          generalWorkingDay.originalExpectedHours = hoursPerDay;
          generalWorkingDaysArray.pushObject(generalWorkingDay);
          return true;
        }

        if (isWorkTypeFlexible) {
          generalWorkingDay.isWorking = true;
          generalWorkingDay.expectedHours = defaultWorkHours;
          generalWorkingDay.originalExpectedHours = defaultWorkHours;
          generalWorkingDaysArray.pushObject(generalWorkingDay);
          return true;
        }

        if (isWorkTypeFixedWeek) {
          generalWorkingDay.isWorking = true;
          generalWorkingDay.expectedHours = calculatedHoursPerDay;
          generalWorkingDay.originalExpectedHours = calculatedHoursPerDay;
          generalWorkingDaysArray.pushObject(generalWorkingDay);
          return true;
        }

        if (!employmentEntry) {
          generalWorkingDaysArray.pushObject(generalWorkingDay);
          return true;
        }
      });

      if (absences && absences.get('length') > 0) {
        // MERGE ABSENCES INTO THE ARRAY
        var len = generalWorkingDaysArray.length;
        absences.forEach(function (absence) {
          var absenceStartDate = showWeekends ? absence.get('startDate') : absence.get('startDateBusinessDay');
          var absenceEndDate = showWeekends ? absence.get('endDate') : absence.get('endDateBusinessDay');

          if ((0, _date.isDateValid)(absenceStartDate) && (0, _date.isDateValid)(absenceEndDate)) {
            var absenceType = absence.get('absenceType');
            var rangeType = absence.get('rangeType');
            var isHalfday = absence.get('halfDay');
            var workHoursExpected = absence.get('workHoursExpected');
            var absenceOverall = _this5.numberOfDaysBetween(absenceStartDate, absenceEndDate) + 1;

            var days = _this5.numberOfDaysBetween(startDate, absenceStartDate);

            var sum = days + absenceOverall;
            if (days < 0) days = 0;
            if (sum > len) sum = len;

            var absenceLength = _this5._calculateAbsenceLength(absence, generalWorkingDaysArray, days, sum);

            for (var i = days; i < sum; ++i) {
              if (!absence.isAbsentOnDate(generalWorkingDaysArray[i].date)) {
                continue;
              }

              generalWorkingDaysArray[i].absenceType = absenceType;
              generalWorkingDaysArray[i].absenceComment = absence.get('comment');
              generalWorkingDaysArray[i].isAbsent = absence.get('fullDay');
              generalWorkingDaysArray[i].absenceRangeType = rangeType;
              generalWorkingDaysArray[i].absenceLength = absenceLength;

              if (absence.get('absenceType.isPublicHoliday')) {
                generalWorkingDaysArray[i].isOff = true;
              }

              if (!workHoursExpected) {
                if (isHalfday) {
                  generalWorkingDaysArray[i].expectedHours = Math.max(0, generalWorkingDaysArray[i].expectedHours - generalWorkingDaysArray[i].originalExpectedHours * 0.5);

                  if (generalWorkingDaysArray[i].expectedHours === 0) {
                    generalWorkingDaysArray[i].isWorking = false;
                  }
                } else {
                  generalWorkingDaysArray[i].expectedHours = 0;
                  generalWorkingDaysArray[i].isWorking = false;
                }
              }
            }
          }
        });
      }

      if (absenceRequests && absenceRequests.get('length') > 0) {
        // MERGE ABSENCE-REQUESTS INTO THE ARRAY
        var _len = generalWorkingDaysArray.length;
        absenceRequests.forEach(function (absenceRequest) {
          var absenceStartDate = showWeekends ? absenceRequest.get('startDate') : absenceRequest.get('startDateBusinessDay');
          var absenceEndDate = showWeekends ? absenceRequest.get('endDate') : absenceRequest.get('endDateBusinessDay');

          if ((0, _date.isDateValid)(absenceStartDate) && (0, _date.isDateValid)(absenceEndDate)) {
            var absenceType = absenceRequest.get('absenceType');
            var rangeType = absenceRequest.get('rangeType');
            var absenceOverall = _this5.numberOfDaysBetween(absenceStartDate, absenceEndDate) + 1;

            var days = _this5.numberOfDaysBetween(startDate, absenceStartDate);

            var sum = days + absenceOverall;
            if (days < 0) days = 0;
            if (sum > _len) sum = _len;

            for (var i = days; i < sum; ++i) {
              if (!absenceRequest.isAbsentOnDate(generalWorkingDaysArray[i].date)) {
                continue;
              }

              generalWorkingDaysArray[i].isAbsenceRequest = true;
              generalWorkingDaysArray[i].absenceType = absenceType;
              generalWorkingDaysArray[i].absenceRangeType = rangeType;
            }
          }
        });
      }

      return generalWorkingDaysArray;
    },
    _calculateAbsenceLength: function _calculateAbsenceLength(absence, generalWorkingDaysArray, days, sum) {
      var absenceLength = 0;

      for (var i = days; i < sum; ++i) {
        if (!absence.isAbsentOnDate(generalWorkingDaysArray[i].date)) {
          continue;
        }

        if (generalWorkingDaysArray[i].isOff || !generalWorkingDaysArray[i].isWorking) {
          continue;
        }

        absenceLength++;
      }

      return absenceLength;
    },
    _calculateActualWorkingDaysOfPeriod: function _calculateActualWorkingDaysOfPeriod(user, startDate, endDate, employmentEntry) {
      var absences = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      var includeHalfDays = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;

      var usedWeekDays = this._getActualWorkingDaysOfPeriod(user, startDate, endDate, employmentEntry, absences);

      var actualWorkingDays = usedWeekDays.filterBy('isWorking', true);

      if (includeHalfDays) {
        return actualWorkingDays.toArray().reduce(function (workingDays, thisDay) {
          var daySum = thisDay.expectedHours / thisDay.originalExpectedHours; // If original hours is 0, but this is an actual working day, then user is a 0 working hours "utility"
          // user, and the whole day should be added to the day sum to fix the 0/0 NaN

          if (isNaN(daySum)) {
            daySum = 1;
          }

          return workingDays + daySum;
        }, 0);
      }

      return actualWorkingDays.get('length');
    },
    updateEmploymentEntries: function updateEmploymentEntries(employmentEntries) {
      this.set('employmentEntries', employmentEntries);
    },
    updateAbsences: function updateAbsences(absences) {
      this.set('absences', absences);
    },
    updateUserHolidays: function updateUserHolidays(userHolidays) {
      this.set('userHolidays', userHolidays);
    },
    eachDay: function eachDay(startDate, endDate, cb) {
      (this.get('showWeekends') ? _date.eachDay : _date.eachBusinessDay)(startDate, endDate, cb);
    },
    numberOfDaysBetween: function numberOfDaysBetween(startDate, endDate) {
      return this.get('showWeekends') ? (0, _date.numberOfDays)(startDate, endDate) : (0, _date.numberOfBusinessDays)(startDate, endDate);
    }
  });

  _exports.default = _default;
});