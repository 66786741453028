define("coffeecup/mixins/deferred-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isSetup: false,
    registerSetup: Ember.on('didInsertElement', function () {
      this.$().one('mouseover.deferred-setup focusin.deferred-setup', this.resolveSetup.bind(this));
    }),
    resolveSetup: function resolveSetup() {
      Ember.run.scheduleOnce('afterRender', this, 'set', 'isSetup', true);
    }
  });

  _exports.default = _default;
});