define("coffeecup/templates/components/manage/list-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/gw4Vg9m",
    "block": "{\"symbols\":[\"itemInSelect\",\"__arg0\",\"@canCreateEntity\",\"@closeAction\",\"@headerLabel\",\"@itemsGrouped\",\"@selectedItem\",\"@searchPath\",\"@placeholder\",\"@selectAction\",\"@showArchived\",\"@toggleArchived\"],\"statements\":[[8,\"detail-pane/detail-pane-header\",[],[[\"@closeAction\",\"@title\",\"@namedBlocksInfo\"],[[32,4],[30,[36,1],[[32,5]],null],[30,[36,2],null,[[\"headerActions\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[30,[36,3],[[32,2],\"headerActions\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"link-to\",[],[[\"@route\",\"@tagName\",\"@classNames\"],[[32,0,[\"newRoute\"]],\"div\",\"header-icon-link new\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[14,0,\"icomoon-plus2\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"detail-pane-controls\"],[12],[2,\"\\n  \"],[8,\"input/cc-select\",[[24,0,\"item-chooser\"]],[[\"@options\",\"@selected\",\"@searchField\",\"@placeholder\",\"@onchange\"],[[32,6],[32,7],[32,8],[32,9],[32,10]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1,[\"label\"]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[8,\"input/cc-slider-checkbox\",[],[[\"@value\",\"@label\",\"@onChange\"],[[32,11],\"showInactiveItems\",[32,12]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"hash\",\"-is-named-block-invocation\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage/list-header.hbs"
    }
  });

  _exports.default = _default;
});