define("coffeecup/routes/analytics/projects/details", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    session: Ember.inject.service(),
    projectService: Ember.inject.service('project'),
    setupController: function setupController(controller, context) {
      if (context && context.selectedProject && context.selectedProject.id) {
        controller.set('selectedProject', context.selectedProject);
        controller.set('projectInvoices', context.projectInvoices);
        controller.set('selectedProjectAnalytics', context.selectedProjectAnalytics.get('firstObject'));
        controller.set('projects', context.projects ? context.projects.toArray() : []);
      }
    },
    model: function model(params, transition) {
      var projectId = params.project_id;
      var interval = 'month';
      var histogramType = 'total';
      var selectedProject = this.store.peekRecord('project', projectId);

      if (!selectedProject || selectedProject.get('isDeleted')) {
        transition.abort();
        var defaultProject = this.get('projectService').getFirstProjectByLabel();
        this.transitionTo('analytics.projects.details', defaultProject.get('id'));
      }

      this.controllerFor('analytics.projects').set('selectedProject', selectedProject);
      var selectedProjectAnalytics = this.store.query('analytics/project', {
        project: projectId,
        histogramInterval: interval,
        histogramType: histogramType
      });
      var projectInvoices = this.store.query('invoice', {
        where: {
          project: projectId
        },
        sort: 'invoicedAt ASC'
      });
      var projects = this.store.peekAll('project');
      return Ember.RSVP.hash({
        selectedProject: selectedProject,
        selectedProjectAnalytics: selectedProjectAnalytics,
        projects: projects,
        projectInvoices: projectInvoices
      });
    }
  });

  _exports.default = _default;
});