define("coffeecup/routes/projects/expense-categories", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    permissions: Ember.inject.service(),
    titleToken: function titleToken() {
      return this.get('i18n').t('expense-categories');
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var upstreamPromise = this._super.apply(this, arguments);

      return Ember.RSVP.resolve(upstreamPromise).then(function () {
        var expensesDisabled = !_this.get('permissions.expensesActive');

        if (expensesDisabled) {
          transition.abort();

          _this.transitionTo('dashboard');
        }
      });
    },
    model: function model() {
      return this.store.peekAll('expense-category');
    }
  });

  _exports.default = _default;
});