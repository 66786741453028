define("coffeecup/validations/project", ["exports", "ember-changeset-validations/validators", "coffeecup/validators/validateUnique", "coffeecup/validators/validateRelation", "coffeecup/config/environment"], function (_exports, _validators, _validateUnique, _validateRelation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      name: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      code: {
        presence: true,
        length: {
          maximum: 18
        },
        unique: true
      },
      comment: {
        length: {
          maximum: 10000
        }
      }
    },
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    code: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 18
    }), (0, _validateUnique.default)()],
    comment: [(0, _validators.validateLength)({
      max: 10000
    })],
    codeColor: [(0, _validators.validateFormat)({
      allowBlank: true,
      regex: /^#(\d{6})|^#([A-F]{6})|^#([A-F]|[0-9]){6}/
    })],
    billBy: [(0, _validators.validateInclusion)({
      list: [_environment.default.constants.BILL_BY_CLIENT, _environment.default.constants.BILL_BY_NONE, _environment.default.constants.BILL_BY_PROJECT, _environment.default.constants.BILL_BY_TASK, _environment.default.constants.BILL_BY_USER]
    })],
    budgetBy: [(0, _validators.validateInclusion)({
      list: [_environment.default.constants.BUDGET_BY_EFFORT, _environment.default.constants.BUDGET_BY_TASK, _environment.default.constants.BUDGET_BY_TOTAL_AMOUNT, _environment.default.constants.BUDGET_BY_TOTAL_HOURS, _environment.default.constants.BUDGET_BY_USER]
    })],
    client: [(0, _validateRelation.default)({
      presence: false,
      on: 'isInternal'
    })],
    hourlyRate: [(0, _validators.validatePresence)({
      presence: true,
      on: 'isBillByProject'
    }), (0, _validators.validateNumber)({
      lte: 999999
    })],
    budget: [(0, _validators.validatePresence)({
      presence: true,
      on: 'isBudgetByTotalAmount'
    }), (0, _validators.validateNumber)({
      lte: 99999999
    })],
    budgetHours: [(0, _validators.validatePresence)({
      presence: true,
      on: 'isBudgetByTotalHours'
    }), (0, _validators.validateNumber)({
      lte: 999999
    })]
  };
  _exports.default = _default;
});