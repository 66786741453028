define("coffeecup/templates/modal/new-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oPtiQz6a",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"onClose\",\"targetAttachment\",\"translucentOverlay\"],[[30,[36,1],[[32,0],\"onHide\"],null],\"center\",true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"form\"],[24,\"role\",\"form\"],[4,[38,1],[[32,0],\"confirm\",[35,0,[\"confirmCallback\"]]],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-wrapper modal-confirm\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n          \"],[10,\"div\"],[15,0,[31,[[34,0,[\"icon\"]],\" modal-icon\"]]],[12],[13],[2,\"\\n          \"],[10,\"h1\"],[12],[2,\"\\n            \"],[1,[35,0,[\"title\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"col-md-8 col-md-offset-2\"],[12],[2,\"\\n              \"],[19,\"projects/clients/-edit-form-general-fields\",[]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n          \"],[11,\"a\"],[24,0,\"btn btn-default\"],[4,[38,1],[[32,0],\"onHide\"],null],[12],[2,\"\\n            \"],[1,[35,0,[\"closeBtnText\"]]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"submit\"],[4,[38,1],[[32,0],\"confirm\",[35,0,[\"confirmCallback\"]],[35,3]],[[\"disabled\"],[[35,2]]]],[12],[2,\"\\n            \"],[1,[35,0,[\"confirmBtnText\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"options\",\"action\",\"submitIsDisabled\",\"bind-attr\",\"modal-dialog\",\"openModal\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/modal/new-client.hbs"
    }
  });

  _exports.default = _default;
});