define("coffeecup/routes/analytics/users/details", ["exports", "coffeecup/routes/_super-route", "coffeecup/config/environment", "coffeecup/utils/build-url"], function (_exports, _superRoute, _environment, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    session: Ember.inject.service(),
    setupController: function setupController(controller, context) {
      if (context && context.selectedUser && context.selectedUser.id) {
        controller.set('selectedUser', context.selectedUser);
        controller.set('vacationEntries', context.vacationEntries);
        controller.set('userEmployments', context.userEmployments ? context.userEmployments.toArray() : []);
        controller.set('projects', context.projects ? context.projects.toArray() : []);
        controller.set('userAssignments', context.userAssignments ? context.userAssignments.toArray() : []);
      }
    },
    model: function model(params) {
      var userId = params.user_id; // selectedUser

      var selectedUser = this.store.peekRecord('user', userId);

      if (!selectedUser || selectedUser.get('isDeleted')) {
        transition.abort();
      }

      this.controllerFor('analytics.users').set('selectedUser', selectedUser); // userEmployments

      var userEmployments = this.store.query('user-employment', {
        sort: 'validFrom ASC',
        where: {
          user: userId
        }
      }); // userAssignments

      var userAssignments = this.store.query('user-assignment', {
        limit: 5000,
        // TODO LOAD MORE entries if meta.total is higher than 5000
        where: {
          user: userId
        }
      }); // vacationEntries

      var vacationEntries = this.get('session').getJSON((0, _buildUrl.default)(this.get('store'), 'vacationbudgets/calc', {
        user: userId,
        year: this.get('clock.now').format(_environment.default.constants.DATE_YEAR_PICKER_FORMAT)
      }));
      var projects = this.store.peekAll('project');
      return Ember.RSVP.hash({
        selectedUser: selectedUser,
        userEmployments: userEmployments,
        userAssignments: userAssignments,
        vacationEntries: vacationEntries,
        projects: projects
      });
    }
  });

  _exports.default = _default;
});