define("coffeecup/routes/index", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //routes/index.js
  var IndexRoute = _superRoute.default.extend({
    responsive: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    afterModel: function afterModel() {
      var isMobile = this.get('responsive').isMobileWidth();
      var currentUser = this.getCurrentUser();
      var isAccountOwner = currentUser.get('isAdmin') && this.get('accountSettings.accountOwner.id') === currentUser.get('id');
      var challengesUnlocked = currentUser.get('featuresUnlocked.challengesUnlocked');
      var showTimeEntriesInsteadOfDashboard = isMobile && (!isAccountOwner || challengesUnlocked);
      this.transitionTo(showTimeEntriesInsteadOfDashboard ? 'time-entries' : 'dashboard');
    }
  });

  var _default = IndexRoute;
  _exports.default = _default;
});