define("coffeecup/services/task", ["exports", "coffeecup/mixins/create-short-tag"], function (_exports, _createShortTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_createShortTag.default, {
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    createDefaultTask: function createDefaultTask() {
      var task = this.get('store').createRecord('task');
      var colors = this.get('store').peekAll('color'); // have been loaded into store in application.js route
      // set random color

      task.set('color', colors.objectAt(Math.floor(Math.random() * colors.get('length'))));
      var taskLabel = this.get('i18n').t('newTask').toString();
      task.set('label', taskLabel);
      var duplicateAppendix = 1;
      var existingTags = this.get('store').peekAll('task').getEach('code');
      var taskCode = this.createShortTag(taskLabel, 5, existingTags, duplicateAppendix);
      task.set('code', taskCode);
      task.setupTrackerMetaData();
      return task.save();
    },
    copyTask: function copyTask(properties) {
      var copy = this.get('store').createRecord('task', properties);
      copy.set('label', '(COPY) ' + properties.label);
      var duplicateAppendix = 1;
      var existingTags = this.get('store').peekAll('task').getEach('code');
      var taskCode = this.createShortTag(copy.get('label'), 5, existingTags, duplicateAppendix);
      copy.set('code', taskCode);
      copy.setupTrackerMetaData();
      return copy.save();
    },
    tasksSortedAndGroupedByInactive: function tasksSortedAndGroupedByInactive(tasks) {
      var tasksSorted = tasks.sortBy('label');
      var activeTasks = tasksSorted.filterBy('isActive');
      var inactiveTasks = tasksSorted.filterBy('isInactive');

      if (inactiveTasks.get('length') > 0) {
        return [Ember.Object.create({
          groupName: this.get('i18n').t('activeLabel'),
          options: activeTasks
        }), Ember.Object.create({
          groupName: this.get('i18n').t('inactiveLabel'),
          options: inactiveTasks
        })];
      } else {
        return activeTasks;
      }
    }
  });

  _exports.default = _default;
});