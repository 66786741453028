define("coffeecup/templates/components/menu-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lEiA5uit",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"badge \",[30,[36,2],[[35,6],\"pulsing\"],null],\" \",[30,[36,2],[[35,5],\"hidden-xs\"],null],\" \",[30,[36,2],[[35,4],\"alert\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"badge-text\"],[12],[2,\"\\n\"],[6,[37,2],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[34,3]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[34,0]],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"numberOfOpenValidations\",\"hasValidationBadge\",\"if\",\"numberOfOpenAbsenceRequests\",\"isAlertBadge\",\"hideOnSmall\",\"isPulsing\",\"hasPlannerBadge\",\"hasBadge\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/menu-badge.hbs"
    }
  });

  _exports.default = _default;
});