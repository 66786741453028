define("coffeecup/instance-initializers/project-states", ["exports", "coffeecup/models/project-state"], function (_exports, _projectState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'project-state',
        id: '1',
        attributes: {
          status: 1,
          label: 'In Planning',
          color: '#FFC520',
          style: _projectState.ProjectStateStyle.Solid,
          isComplete: false,
          isTrackable: false,
          isDefault: true
        }
      }, {
        type: 'project-state',
        id: '2',
        attributes: {
          status: 1,
          label: 'In Progress',
          color: '#4FBF88',
          style: _projectState.ProjectStateStyle.Solid,
          isComplete: false,
          isTrackable: true,
          isDefault: false
        }
      }, {
        type: 'project-state',
        id: '3',
        attributes: {
          status: 1,
          label: 'Completed',
          color: '#E0E0E0',
          style: _projectState.ProjectStateStyle.Solid,
          isComplete: true,
          isTrackable: false,
          isDefault: false
        }
      } // {
      //   type: 'project-state',
      //   id: '4',
      //   attributes: {
      //     status: 1,
      //     label: 'ERROR',
      //     color: '#AA0000',
      //     style: ProjectStateStyle.Solid,
      //     isComplete: true,
      //   },
      // },
      ]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'project-states',
    after: 'ember-simple-auth',
    initialize: initialize
  };
  _exports.default = _default;
});