define("coffeecup/templates/components/planner/day-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V8uFhMdK",
    "block": "{\"symbols\":[\"week\",\"day\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"timeline-week\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1,[\"days\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"timeline-day \",[30,[36,0],[[32,2,[\"isToday\"]],\"today\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\",\"weekGrid\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/day-grid.hbs"
    }
  });

  _exports.default = _default;
});