define("coffeecup/mixins/cascade-archivation", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for Models to support cascading archivation.
   */
  var _default = Ember.Mixin.create({
    status: _environment.default.constants.STATUS_ARCHIVED,
    invalidState: 'isInactive',
    filterStatus: 'isActive',
    optionKey: 'cascadeArchivation',
    didUpdate: function didUpdate() {
      this.checkForCascadingArchivation();

      this._super.apply(this, arguments);
    },
    checkForCascadingArchivation: function checkForCascadingArchivation() {
      var _this = this;

      if (!this.get(this.get('invalidState'))) {
        return;
      }

      var recordsToUpdate = []; // collect all records to unload into recordsToUnload variable

      this.eachRelationship(function (name, descriptor) {
        var options = descriptor.options,
            kind = descriptor.kind;
        var relationshipName = descriptor.key;

        if (options[_this.get('optionKey')]) {
          var records = [];

          if (kind === 'hasMany') {
            records = _this.get(relationshipName);
          } else {
            var relationshipItem = _this.get(relationshipName);

            if (relationshipItem && relationshipItem.content) records = [relationshipItem.content];
          }

          recordsToUpdate = recordsToUpdate.concat(records.filterBy(_this.get('filterStatus')).toArray());
        }
      });
      this.get('store').push({
        data: recordsToUpdate.map(function (record) {
          return {
            id: record.get('id'),
            type: record.get('constructor.modelName'),
            attributes: {
              status: _this.get('status')
            }
          };
        })
      });
      recordsToUpdate.forEach(function (record) {
        if (record.checkForCascadingArchivation && typeof record.checkForCascadingArchivation === 'function') {
          record.checkForCascadingArchivation();
        }
      });
    }
  });

  _exports.default = _default;
});