define("coffeecup/templates/components/manage-table/cells/project-profitability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6mB8n1YV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"classes\"]]],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,0,[\"profitabilityInPercent\"]],[30,[36,0],[[32,0,[\"profitabilityInPercent\"]]],null],\"-\"],null]],[2,\"\\n  \"],[8,\"cc-ember-tooltip\",[],[[\"@popperContainer\"],[\"main\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"analytics/profitability-tooltip\",[],[[\"@amountSpent\",\"@isCompletedFixedBudgetProject\",\"@isClientWithMixedProjects\",\"@userCosts\",\"@expensesCosts\",\"@profitabilityTotal\"],[[32,0,[\"amountSpent\"]],[32,0,[\"isCompletedFixedBudgetProject\"]],[32,0,[\"isClientWithMixedProjects\"]],[32,0,[\"userCosts\"]],[32,0,[\"expensesCosts\"]],[32,0,[\"profitabilityTotal\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"percent-fmt\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-profitability.hbs"
    }
  });

  _exports.default = _default;
});