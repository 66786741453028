define("coffeecup/templates/components/analytics/time-entries/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5orLAQ/v",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,28],[[30,[36,27],[[30,[36,27],[[35,26]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,25],null,[[\"item\",\"activeItemId\",\"isDefaultUser\",\"onCheckChange\",\"mainCheckbox\",\"checkedItems\",\"onButtonClick\",\"onSelectItem\",\"criteriaNumber\",\"showCommentType\",\"showedColumns\",\"isDateInTable\",\"isClientInTable\",\"isProjectInTable\",\"isTeamInTable\",\"isTaskInTable\",\"isReferenceInTable\",\"isStaffInTable\",\"isHoursInTable\",\"isValueInTable\",\"isTrackedInTable\",\"isTooltipHintsVisible\",\"selectClients\",\"selectUsers\",\"selectTasks\",\"selectTeams\",\"selectTimeEntryReferences\",\"selectProjects\"],[[32,1],[35,24],[35,23],[35,22],[35,21],[35,20],\"buttonClick\",\"_selectItem\",[35,19],[35,18],[35,17],[35,16],[35,15],[35,14],[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"selectProjects\",\"selectTimeEntryReferences\",\"selectTeams\",\"selectTasks\",\"selectUsers\",\"selectClients\",\"isTooltipHintsVisible\",\"isTrackedInTable\",\"isValueInTable\",\"isHoursInTable\",\"isStaffInTable\",\"isReferenceInTable\",\"isTaskInTable\",\"isTeamInTable\",\"isProjectInTable\",\"isClientInTable\",\"isDateInTable\",\"showedColumns\",\"showCommentType\",\"criteriaNumber\",\"checkedItems\",\"mainCheckbox\",\"onCheck\",\"isDefaultUser\",\"activeItemId\",\"analytics/time-entries/list-row\",\"fsgList\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics/time-entries/list.hbs"
    }
  });

  _exports.default = _default;
});