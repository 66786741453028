define("coffeecup/templates/components/dropdown-description-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "renKlMFY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"dropdown-headline\"],[12],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[\"icon \",[34,2]]]],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icon\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,0,\"gravatar\"],[14,\"src\",\"https://www.gravatar.com/avatar/?s=20\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"span\"],[14,0,\"dropdown-title\"],[12],[2,\" \"],[1,[30,[36,1],[[35,5]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dropdown-description\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[35,0]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"description\",\"t\",\"icon\",\"if\",\"gravatar\",\"headline\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dropdown-description-element.hbs"
    }
  });

  _exports.default = _default;
});