define("coffeecup/templates/components/dashboard/project-details/chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cQVXw7D7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"project-details-chart-wrapper\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"project-details-chart\"],[12],[2,\"\\n      \"],[8,\"loading-indicator\",[],[[],[]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"hasError\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"project-details-chart\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"chart-reload-label\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"errorWhileLoading\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,2],[\"click\",[32,0,[\"retryDataLoading\"]]],null],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"retry\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"noDataAvailable\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"project-details-chart\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"chart-reload-label\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"noDataAvailable\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"project-details-chart chart-div-\",[32,0,[\"elementId\"]]]]],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/project-details/chart.hbs"
    }
  });

  _exports.default = _default;
});