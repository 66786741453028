define("coffeecup/routes/_super-form-route", ["exports", "coffeecup/routes/_super-route", "coffeecup/utils/focus-error-input"], function (_exports, _superRoute, _focusErrorInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var SuperFormRoute = _superRoute.default.extend({
    _validate: function _validate() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var form = _this.get('controller.form');

        form.validate().then(resolve).catch(function (errors) {
          (0, _focusErrorInput.default)(_this.controller, errors);
        });
      });
    },
    _afterSave: function _afterSave() {},
    actions: {
      validate: function validate() {
        var _this2 = this;

        this._validate().catch(function (err) {
          _this2.get('notifications').info('pleaseCheckYourInputs');
        });
      },
      save: function save() {
        var _this3 = this;

        this.set('controller.submitting', true);
        var model = this.get('controller.model');
        var modelKey = model.get('constructor.modelName');
        var form = this.get('controller.form');

        this._validate().then(function () {
          form.execute();
          model.save().then(function () {
            _this3.set('controller.submitting', false);

            _this3.get('notifications').success('alert.saved', Ember.String.camelize(modelKey));

            _this3._afterSave();
          }).catch(function (err) {
            _this3.set('controller.submitting', false);

            _this3.get('notifications').errorWithObject(err, 'alert.errorOnSave', Ember.String.camelize(modelKey));
          });
        }).catch(function (err) {
          _this3.get('notifications').info('pleaseCheckYourInputs');
        });
      },
      reset: function reset() {
        Ember.run.later(this, function () {
          try {
            // default rollback
            this.get('controller.model').rollbackAttributes(); // discard buffered form

            this.get('controller.form').rollback();
          } catch (err) {//console.error(err);
          }
        }, 10);
      }
    }
  });

  var _default = SuperFormRoute;
  _exports.default = _default;
});