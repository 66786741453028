define("coffeecup/templates/components/planner/scope-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JzGLkb4f",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"scope-indicator-parent\"],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"scope-indicator-container \",[30,[36,0],[[32,0,[\"viewport\",\"linkScopeToViewport\"]],\"linked\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scope-indicator\"],[15,5,[32,0,[\"indicatorStyle\"]]],[12],[2,\"\\n      \"],[11,\"div\"],[24,0,\"scope-grabber-left\"],[24,\"role\",\"slider\"],[4,[38,1],[\"mousedown\",[32,0,[\"dragStartLeft\"]]],null],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"scope-handle-left\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[11,\"div\"],[24,0,\"scope-grabber-middle\"],[24,\"role\",\"slider\"],[4,[38,1],[\"mousedown\",[32,0,[\"dragStartMiddle\"]]],null],[12],[13],[2,\"\\n      \"],[11,\"div\"],[24,0,\"scope-grabber-right\"],[24,\"role\",\"slider\"],[4,[38,1],[\"mousedown\",[32,0,[\"dragStartRight\"]]],null],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"scope-handle-right\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scope-mask scope-mask-left\"],[15,5,[32,0,[\"maskLeftStyle\"]]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"scope-mask-line-parent\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"scope-mask-line\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scope-mask scope-mask-right\"],[15,5,[32,0,[\"maskRightStyle\"]]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"scope-mask-line-parent\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"scope-mask-line\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/scope-indicator.hbs"
    }
  });

  _exports.default = _default;
});