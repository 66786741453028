define("coffeecup/templates/components/time-entry-export-checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ywmaPUPb",
    "block": "{\"symbols\":[\"@isColumnStartEndVisible\",\"@isColumnClientVisible\",\"@isColumnUserVisible\",\"@isColumnDateVisible\",\"@isColumnProjectVisible\",\"@isColumnCommentVisible\",\"@isColumnTaskVisible\",\"@disableTaskCheckbox\",\"@isColumnTeamVisible\",\"@isColumnReferenceVisible\",\"@showStartEndCheckbox\"],\"statements\":[[10,\"div\"],[14,0,\"checkbox-grid form-group\"],[12],[2,\"\\n  \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\"],[[32,2],\"client\"]],null],[2,\"\\n  \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\"],[[32,3],\"staff\"]],null],[2,\"\\n  \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\"],[[32,4],\"date\"]],null],[2,\"\\n  \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\"],[[32,5],\"project\"]],null],[2,\"\\n  \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\"],[[32,6],\"comment\"]],null],[2,\"\\n  \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\",\"@disabled\"],[[32,7],\"task\",[32,8]]],null],[2,\"\\n  \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\"],[[32,9],\"team_\"]],null],[2,\"\\n  \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\"],[[32,10],\"reference\"]],null],[2,\"\\n\"],[6,[37,0],[[32,11]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\"],[[32,1],\"startEnd\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/time-entry-export-checkbox-group.hbs"
    }
  });

  _exports.default = _default;
});