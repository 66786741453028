define("coffeecup/models/analytics/client/project", ["exports", "ember-data-model-fragments/fragment", "ember-data/attr", "ember-data-model-fragments/attributes"], function (_exports, _fragment, _attr, _attributes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * serialized Project analytics user model fragment
   * @see //serializers/analytics/project.js for mappings
   */
  var AnalyticsClientProject = _fragment.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    // hours
    hoursTotal: (0, _attr.default)('number'),
    hoursSpent: (0, _attr.default)('number'),
    hoursBudget: (0, _attr.default)('number'),
    hoursNonBillable: (0, _attr.default)('number'),
    hoursBilled: (0, _attr.default)('number'),
    hoursOutOfBudget: (0, _attr.default)('number'),
    // hours rounded
    hoursRoundedBilled: (0, _attr.default)('number'),
    hoursRoundedBudget: (0, _attr.default)('number'),
    hoursRoundedNonBillable: (0, _attr.default)('number'),
    hoursRoundedOutOfBudget: (0, _attr.default)('number'),
    hoursRoundedSpent: (0, _attr.default)('number'),
    hoursRoundedTotal: (0, _attr.default)('number'),
    // amounts
    amountSpent: (0, _attr.default)('number'),
    amountInternal: (0, _attr.default)('number'),
    amountOutOfBudget: (0, _attr.default)('number'),
    amountBudget: (0, _attr.default)('number'),
    userCosts: (0, _attr.default)('number'),
    expensesTotal: (0, _attr.default)('number'),
    expensesBilled: (0, _attr.default)('number'),
    expensesNotBilled: (0, _attr.default)('number'),
    expensesWithinBudget: (0, _attr.default)('number'),
    expensesNotWithinBudget: (0, _attr.default)('number'),
    expensesCosts: (0, _attr.default)('number'),
    expensesCostsWithinBudget: (0, _attr.default)('number'),
    expensesCostsNotWithinBudget: (0, _attr.default)('number'),
    percentage: (0, _attr.default)('number'),
    histogram: (0, _attributes.fragmentArray)('analytics/project_histogram/histogram'),

    /**
     * ModelFragments don't support relationships,
     * @see https://github.com/lytics/ember-data.model-fragments/issues/18
     */
    project_id: (0, _attr.default)('string'),
    visible: (0, _attr.default)('boolean'),
    //user: DS.belongsTo('project'),
    project: Ember.computed('project_id', {
      get: function get() {
        return this.store.peekRecord('project', this.get('project_id'));
      }
    })
  });

  var _default = AnalyticsClientProject;
  _exports.default = _default;
});