define("coffeecup/utils/string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.purify = purify;
  _exports.toLowerCaseStart = toLowerCaseStart;

  /**
   * Takes a string and returns it as lowercase and removes all spaces.
   *
   * @param dirtyStr
   * @returns {string}
   */
  function purify(dirtyStr) {
    return dirtyStr ? dirtyStr.toLowerCase().replace(/\s+/g, '') : '';
  }
  /**
   * Returns the string with the first letter turned to lowercase
   *
   * @param {string|SafeString} string
   * @returns
   */


  function toLowerCaseStart(string) {
    var stringValue = string.toString();
    return stringValue[0].toLowerCase() + stringValue.slice(1);
  }
});