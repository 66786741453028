define("coffeecup/instance-initializers/menu", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var ROLE_DEFAULT = _environment.default.constants.ROLE_DEFAULT;
  var ROLE_STAFF_PM = _environment.default.constants.ROLE_STAFF_PM;
  var ROLE_PM = _environment.default.constants.ROLE_PM;
  var ROLE_ADMIN = _environment.default.constants.ROLE_ADMIN;
  var ROLE_UNKNOWN = 'UNKNOWN';
  var ROLE_ALL = [ROLE_ADMIN, ROLE_PM, ROLE_STAFF_PM, ROLE_DEFAULT, ROLE_UNKNOWN];
  var EXPENSES_ENABLED = 'expensesActive';
  var CAN_MANAGE_OWN_PROJECTS = 'canManageOwnProjects';
  var CAN_MANAGE_ALL_PROJECTS = 'canManageAllProjects';
  var CAN_MANAGE_PROJECT_RELATIONS = 'canManageProjectRelations';
  var IS_ROOT_ADMIN = 'isRootAdmin';
  var CAN_MANAGE_USERS = 'canManageUsers';
  var CAN_ACCESS_USERS = 'canAccessUsers';
  var LIMITED_TEAM_STAFF_ACCESS = 'limitedTeamStaffAccess';
  var CAN_VALIDATE_WORKTIME = 'canValidateWorkTime';
  var CAN_ACCESS_PLANNER = 'canAccessPlanner';
  var CAN_VIEW_PLANNER = 'canViewPlanner';
  var CAN_MANAGE_PLANNER = 'canManagePlanner';
  var IS_STAFF_PM = 'isStaffPM';
  var VALIDATION_ENABLED = 'worktimeValidationActive';
  var PLANNER_ENABLED = 'plannerActive';
  var CAN_VIEW_TIME_ENTRIES = 'canViewTeamTimeEntries';
  var CAN_MANAGE_ABSENCES = 'canManageTeamAbsences';

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var modelName = 'menu';
    var pushData = {
      menu: [// >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // DASHBOARD
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      {
        id: 'dashboard',
        label: 'dashboards',
        icon: 'dashboard1',
        hideOnSmall: true,
        parent: null,
        children: null,
        modelKey: 'dasboard',
        hideFromRoles: [ROLE_UNKNOWN]
      }, // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // DASHBOARD
      // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

      /**
       * Projects
       */
      {
        id: 'projects',
        label: 'clients&projects',
        icon: 'projects',
        parent: null,
        children: null,
        hideFromRoles: [ROLE_UNKNOWN],
        permissionChecks: [CAN_MANAGE_PROJECT_RELATIONS, CAN_MANAGE_OWN_PROJECTS]
      }, // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // TRACKING
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      {
        id: 'time-entries',
        label: 'workTime',
        singleMenuLabel: 'timeTracking',
        icon: 'time-entry',
        parent: null,
        children: ['time-entries.tracking', 'time-entries.verifying', 'analytics.time-entries'],
        hasSubmenuBadge: true,
        hideBadgeOnSmall: true,
        hideFromRoles: [ROLE_UNKNOWN]
      }, {
        id: 'time-entries.tracking',
        label: 'timeTrackingNav',
        subLabel: 'hoursAndBreaks',
        parent: 'time-entries',
        order: 1,
        children: null,
        hideFromRoles: [ROLE_UNKNOWN]
      }, {
        id: 'time-entries.verifying',
        label: 'timeSheetsNav',
        subLabel: 'validateAndApprove',
        parent: 'time-entries',
        order: 2,
        children: null,
        hideOnSmall: true,
        hideFromRoles: [ROLE_UNKNOWN],
        featureFlags: [VALIDATION_ENABLED],
        permissionChecks: [CAN_VALIDATE_WORKTIME],
        teamPermissions: [CAN_VIEW_TIME_ENTRIES]
      }, {
        id: 'analytics.time-entries',
        label: 'analytics',
        parent: 'time-entries',
        subLabel: 'analyticsTimeEntrySubLabel',
        order: 3,
        children: null,
        hideFromRoles: []
      }, // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // TRACKING
      // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // PLANNER
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      {
        id: 'planner',
        label: 'planning',
        singleMenuLabel: 'absences',
        icon: 'planner1',
        parent: null,
        children: ['planner.team', 'planner.projects', 'planner.absences'],
        hideFromRoles: [ROLE_UNKNOWN]
      }, {
        id: 'planner.team',
        label: 'team_',
        parent: 'planner',
        children: null,
        hideFromRoles: [],
        featureFlags: [PLANNER_ENABLED],
        permissionChecks: [CAN_VIEW_PLANNER]
      }, {
        id: 'planner.projects',
        label: 'projects',
        parent: 'planner',
        children: null,
        hideFromRoles: [],
        featureFlags: [PLANNER_ENABLED],
        permissionChecks: [CAN_VIEW_PLANNER]
      }, {
        id: 'planner.absences',
        label: 'absences',
        parent: 'planner',
        children: null,
        hideFromRoles: []
      }, // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // PLANNER
      // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

      /**
       * Team
       */
      {
        id: 'team',
        label: 'team',
        icon: 'team',
        parent: null,
        children: null,
        hideFromRoles: [ROLE_UNKNOWN],
        permissionChecks: [CAN_ACCESS_USERS, LIMITED_TEAM_STAFF_ACCESS]
      }, {
        id: 'analytics.users',
        label: 'myStatistics',
        icon: 'person',
        parent: null,
        children: null,
        hideFromRoles: [ROLE_UNKNOWN],
        hideFromPermissions: [CAN_ACCESS_USERS, LIMITED_TEAM_STAFF_ACCESS]
      }, // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // ANALYTICS
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // {
      //   id: 'analytics',
      //   label: 'analytics',
      //   icon: 'insights',
      //   parent: null,
      //   children: ['analytics.time-entries', 'analytics.users', 'analytics.projects', 'analytics.clients'],
      //   hideFromRoles: [ROLE_UNKNOWN],
      // },
      // {
      //   id: 'analytics.time-entries',
      //   label: 'workTime',
      //   parent: 'analytics',
      //   subLabel: 'analyticsTimeEntrySubLabel',
      //   order: 2,
      //   children: null,
      //   hideFromRoles: [],
      // },
      // {
      //   id: 'analytics.projects',
      //   label: 'projects',
      //   subLabel: 'analyticsProjectsSubLabel',
      //   order: 3,
      //   parent: 'analytics',
      //   children: null,
      //   hideFromRoles: [ROLE_UNKNOWN],
      //   permissionChecks: [CAN_MANAGE_OWN_PROJECTS, IS_STAFF_PM],
      // },
      // {
      //   id: 'analytics.users',
      //   label: 'team',
      //   subLabel: 'analyticsUsersSubLabel',
      //   order: 4,
      //   parent: 'analytics',
      //   children: null,
      //   hideFromRoles: [],
      // },
      // {
      //   id: 'analytics.clients',
      //   label: 'clients',
      //   subLabel: 'analyticsClientsSubLabel',
      //   order: 5,
      //   parent: 'analytics',
      //   children: null,
      //   hideFromRoles: [ROLE_UNKNOWN],
      //   permissionChecks: [CAN_MANAGE_ALL_PROJECTS],
      // },
      // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // ANALYTICS
      // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      {
        id: 'flex-grow',
        order: 6,
        parent: null,
        children: null,
        isFlexGrow: true
      }, {
        id: 'trialLength',
        label: 'trial',
        parent: null,
        children: null,
        isTrialCallout: true
      }, {
        id: 'roadmap',
        label: 'roadmap',
        icon: 'bell',
        parent: null,
        children: null,
        hideFromRoles: [ROLE_UNKNOWN],
        bottomAligned: true,
        externalUrl: 'https://roadmap.coffeecup.app'
      }, {
        id: 'intercom',
        label: 'support',
        icon: 'intercom',
        parent: null,
        children: null,
        hideFromRoles: [ROLE_UNKNOWN],
        bottomAligned: true,
        customAction: true
      }, // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // ADMIN
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // {
      //   id: 'admin.billing',
      //   label: 'billing',
      //   hideFromRoles: [ROLE_DEFAULT, ROLE_PM, ROLE_STAFF_PM]
      // },
      // {
      //   id: 'admin.account',
      //   label: 'account',
      //   hideFromRoles: [ROLE_DEFAULT, ROLE_PM, ROLE_STAFF_PM]
      // },
      // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // ADMIN
      // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      // ACCOUNT
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      {
        id: 'account',
        label: 'settings',
        icon: '',
        parent: null,
        children: ['account.billing', 'account.holidays', 'account.profile', 'account.roles'],
        hideFromRoles: [ROLE_UNKNOWN],
        bottomAligned: true,
        submenuShowsProfile: true
      }, {
        id: 'account.billing',
        label: 'billingSettings',
        parent: 'account',
        group: 'adminSettings',
        subLabel: 'billingSettingsSubLabel',
        order: 3,
        children: null,
        hideFromRoles: [ROLE_DEFAULT, ROLE_PM, ROLE_STAFF_PM],
        permissionChecks: [IS_ROOT_ADMIN]
      }, {
        id: 'account.roles',
        label: 'rolesAndRights',
        parent: 'account',
        group: 'adminSettings',
        hideOnSmall: true,
        subLabel: 'rolesAndRightsDescription',
        order: 1,
        children: null,
        hideFromRoles: [ROLE_DEFAULT, ROLE_PM, ROLE_STAFF_PM],
        permissionChecks: [IS_ROOT_ADMIN]
      }, {
        id: 'account.company',
        label: 'account',
        parent: 'account',
        group: 'adminSettings',
        subLabel: 'companySubLabel',
        order: 0,
        children: null,
        hideFromRoles: [ROLE_DEFAULT, ROLE_PM, ROLE_STAFF_PM],
        permissionChecks: [IS_ROOT_ADMIN]
      }, {
        id: 'account.holidays',
        label: 'absences',
        parent: 'account',
        group: 'adminSettings',
        subLabel: 'holidaysSubLabel',
        order: 2,
        children: null,
        hideFromRoles: [ROLE_DEFAULT, ROLE_UNKNOWN],
        permissionChecks: [IS_ROOT_ADMIN, CAN_ACCESS_USERS]
      }, {
        id: 'account.profile',
        label: 'profile',
        parent: 'account',
        group: 'adminSettings',
        subLabel: 'profileSubLabel',
        hideDivider: true,
        order: 4,
        children: null,
        hideFromRoles: []
      }, {
        id: 'account.integrations',
        label: 'integrations',
        icon: 'extensions',
        parent: 'account',
        group: 'appInfo',
        firstAlignBottom: true,
        order: 5,
        children: null,
        hideFromRoles: []
      }, {
        id: 'account.helpCenter',
        label: 'helpCenter',
        icon: 'help-center',
        parent: 'account',
        group: 'appInfo',
        order: 6,
        children: null,
        hideFromRoles: [],
        externalUrl: 'https://support.coffeecup.app/' // TODO: This should maybe be i18n'd

      }, {
        id: 'account.legal',
        label: 'privacy',
        icon: 'data-protection',
        parent: 'account',
        group: 'appInfo',
        order: 7,
        children: null,
        hideDivider: true,
        hideFromRoles: [],
        externalUrl: 'https://www.coffeecup.app/legal/privacy-policy'
      } // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      // ACCOUNT
      // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
      ]
    };
    store.pushPayload(modelName, pushData);
  }

  var _default = {
    name: 'menu',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});