define("coffeecup/utils/list-tooltip-builder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = buildListTooltip;
  _exports.buildListItems = buildListItems;

  function buildListTooltip(tooltipData) {
    var header = tooltipData.header,
        _tooltipData$listItem = tooltipData.listItems,
        listItems = _tooltipData$listItem === void 0 ? [] : _tooltipData$listItem;
    var headerEl = header ? "<div class=\"list-tooltip-header\">".concat(header, "</div>") : '';
    var listItemEls = buildListItems(listItems);
    return Ember.String.htmlSafe(
    /*html*/
    "\n    <div class=\"list-tooltip\">\n      ".concat(headerEl, "\n      ").concat(listItemEls ? "<div class=\"list-tooltip-list\">".concat(listItemEls, "</div>") : '', "\n    </div>\n  "));
  }

  function buildListItems(listItems) {
    return listItems === null || listItems === void 0 ? void 0 : listItems.map(function (_ref) {
      var content = _ref.content,
          _ref$contentClass = _ref.contentClass,
          contentClass = _ref$contentClass === void 0 ? '' : _ref$contentClass,
          subtitle = _ref.subtitle,
          _ref$noMargin = _ref.noMargin,
          noMargin = _ref$noMargin === void 0 ? true : _ref$noMargin;
      var extraClasses = "".concat(noMargin ? 'list-tooltip-item-no-margin' : '');
      var contentEl = content ? "<span class=\"".concat(contentClass, "\">").concat(content, "</span>") : '';
      var subtitleEl = subtitle ? "<span class=\"list-tooltip-item-subtitle\">".concat(subtitle, "</span>") : '';
      return (
        /*html*/
        "\n      <div class=\"list-tooltip-item ".concat(extraClasses, "\">\n        <div class=\"list-tooltip-item-body\">\n          ").concat(contentEl, "\n          ").concat(subtitleEl, "\n        </div>\n      </div>\n    ")
      );
    }).join('');
  }
});