define("coffeecup/utils/streamed-array-proxy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.ArrayProxy.extend({
    stream: function stream(e) {
      Ember.beginPropertyChanges();
      this.clear();
      this.pushObjects(e);
      Ember.endPropertyChanges();
      return this;
    }
  });

  _exports.default = _default;
});