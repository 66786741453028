define("coffeecup/templates/components/comment-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j089JhBB",
    "block": "{\"symbols\":[\"commentInSelect\"],\"statements\":[[6,[37,3],null,[[\"action\",\"class\"],[\"loadComments\",[35,2]]],[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"content\",\"side\"],[[30,[36,0],[\"previousComments\"],null],\"left\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"icon-group\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"icon icomoon-history\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"icon open icomoon-arrow-down2\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,7],null,[[\"options\",\"class\",\"triggerComponent\",\"selected\",\"tabindex\",\"searchField\",\"onchange\"],[[35,6],\"time-entry-history-select\",\"select-items/time-entry-comment\",[35,5],\"-1\",\"comment\",[30,[36,4],[[32,0],\"selectComment\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"comment\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"tool-tipster\",\"btnClass\",\"button-default\",\"action\",\"comment\",\"commentsGroup\",\"input/cc-select\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/comment-history.hbs"
    }
  });

  _exports.default = _default;
});