define("coffeecup/routes/resetpassword", ["exports", "coffeecup/routes/welcome"], function (_exports, _welcome) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ResetPasswordRoute = _welcome.default.extend({
    controllerName: 'resetpassword',
    analytics: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render('start.resetpassword', {
        outlet: 'formbox'
      });
      this.get('analytics').start();
    }
  });

  var _default = ResetPasswordRoute;
  _exports.default = _default;
});