define("coffeecup/routes/planner/absences/scheduler/new", ["exports", "coffeecup/routes/planner/absences/calendar/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    actions: {
      transitionToList: function transitionToList() {
        this.transitionTo('planner.absences.scheduler');
      }
    }
  });

  _exports.default = _default;
});