define("coffeecup/templates/components/input/cc-text-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "75c34fXP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,0,[34,2]],[15,\"for\",[34,3,[\"elementId\"]]],[12],[1,[34,4]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[34,6]],[12],[2,\"\\n  \"],[1,[30,[36,14],null,[[\"class\",\"viewName\",\"type\",\"value\",\"rows\",\"readonly\",\"disabled\",\"placeholder\",\"escape-press\"],[[35,13],\"cctextarea\",[35,12],[35,11],[35,10],[35,9],[35,8],[35,7],\"cancel\"]]]],[2,\"\\n\"],[6,[37,5],[[35,15]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"h6\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,16]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"expander\"],[14,5,\"position: absolute; top: -99999px; left: -99999px; visibility: hidden\"],[12],[2,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"valueForAutogrow\",\"charsLeftLabel\",\"labelClasses\",\"cctextarea\",\"label\",\"if\",\"inputContainerClasses\",\"placeholder\",\"disabled\",\"readonly\",\"rows\",\"value\",\"type\",\"inputClasses\",\"textarea\",\"showCharCounter\",\"autogrow\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/cc-text-area.hbs"
    }
  });

  _exports.default = _default;
});