define("coffeecup/templates/components/input/text-field-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6HI/VBFn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"label\"],[15,0,[34,0]],[15,\"for\",[34,1,[\"elementId\"]]],[12],[1,[34,2]],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[34,3]],[15,\"data-test\",[34,4]],[12],[2,\"\\n  \"],[1,[30,[36,12],null,[[\"class\",\"viewName\",\"type\",\"value\",\"maxlength\",\"disabled\",\"placeholder\",\"readonly\",\"escape-press\"],[[35,11],\"cctextfield\",[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],\"cancel\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"labelClasses\",\"cctextfield\",\"label\",\"inputContainerClasses\",\"data-test\",\"interpolatedReadonly\",\"interpolatedPlaceholder\",\"disabled\",\"maxlength\",\"value\",\"type\",\"inputClasses\",\"input\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/text-field-wrapper.hbs"
    }
  });

  _exports.default = _default;
});