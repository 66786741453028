define("coffeecup/templates/components/icon-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jzIDyRIF",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"icon icomoon-\",[34,3]]]],[12],[13],[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[\"btn-label \",[30,[36,1],[[35,0],\"hidden-xs\"],null]]]],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hideLabelOnMobile\",\"if\",\"label\",\"icon\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/icon-button.hbs"
    }
  });

  _exports.default = _default;
});