define("coffeecup/templates/team/edit/general-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tyL4xVS7",
    "block": "{\"symbols\":[],\"statements\":[[8,\"content-panel\",[],[[\"@headerLabel\"],[[30,[36,0],[\"general\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n      \"],[8,\"input/cc-text-field\",[[4,[38,1],[\"keyup\",[32,0,[\"updateShortCode\"]]],null]],[[\"@type\",\"@label\",\"@placeholder\",\"@data-test\",\"@key\",\"@value\",\"@focus\",\"@validations\",\"@errors\",\"@onBlur\",\"@onEnter\",\"@onEscape\"],[\"text\",[30,[36,0],[\"name\"],null],[30,[36,0],[\"enterKey\"],[[\"key\"],[[30,[36,0],[\"name\"],null]]]],\"team-name-input\",\"name\",[32,0,[\"teamToEdit\",\"name\"]],[32,0,[\"focus\"]],[32,0,[\"TeamValidations\",\"validations\",\"name\"]],[32,0,[\"errors\",\"name\"]],[32,0,[\"saveTeam\"]],[32,0,[\"saveTeam\"]],[32,0,[\"resetTeam\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n      \"],[8,\"input/cc-text-field\",[],[[\"@type\",\"@value\",\"@label\",\"@placeholder\",\"@data-test\",\"@key\",\"@focus\",\"@validations\",\"@errors\",\"@onBlur\",\"@onEnter\",\"@onEscape\"],[\"text\",[32,0,[\"teamToEdit\",\"department\"]],[30,[36,0],[\"department\"],null],[30,[36,0],[\"enterKey\"],[[\"key\"],[[30,[36,0],[\"department\"],null]]]],\"department-name-input\",\"department\",[32,0,[\"focus\"]],[32,0,[\"TeamValidations\",\"validations\",\"department\"]],[32,0,[\"errors\",\"department\"]],[32,0,[\"saveTeam\"]],[32,0,[\"saveTeam\"]],[32,0,[\"resetTeam\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/team/edit/general-data.hbs"
    }
  });

  _exports.default = _default;
});