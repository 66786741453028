define("coffeecup/routes/analytics/projects", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //routes/analytics/projects.js
  var _default = _superRoute.default.extend({
    projectService: Ember.inject.service('project'),
    notifications: Ember.inject.service(),
    titleToken: function titleToken() {
      return this.get('i18n').t('projects');
    },
    model: function model() {
      var currentUser = this.controllerFor('application').get('currentUser');
      var projects = this.store.peekAll('project').sortBy('label');
      projects = this.get('projectService').projectsWhereUserIsPm(projects, currentUser);
      return {
        projects: projects
      };
    },
    setupController: function setupController(controller, context, transition) {
      controller.set('projects', context.projects);

      if (transition.target !== 'analytics.projects.details') {
        controller.changeToProject();
      }
    },
    afterModel: function afterModel(context, transition) {
      this._super.apply(this, arguments);

      var defaultProject = context.projects.filterBy('isActive').get('firstObject');

      if (!this.controllerFor('analytics.projects').get('selectedProject')) {
        this.controllerFor('analytics.projects').set('selectedProject', defaultProject);
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'analytics.projects.index') {
          transition.abort();
        }
      },
      // Not positive that this is used; one of the only unique things imported from the old routes/manage/projects extension
      activate: function activate(project) {
        var _this = this;

        this.get('projectService').uncompleteProject(project.get('id')).then(function () {
          _this.get('notifications').success('alert.activated', 'project');
        }).catch(function (error) {
          _this.get('notifications').errorWithObject(error, 'alert.errorOnSave', 'project');
        });
      }
    }
  });

  _exports.default = _default;
});