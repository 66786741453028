define("coffeecup/templates/components/manage-table/cells/project-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "er+5zO6N",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell project-documents-cell icon-cell\"],[12],[2,\"\\n  \"],[1,[32,0,[\"documentIcons\"]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-documents.hbs"
    }
  });

  _exports.default = _default;
});