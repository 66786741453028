define("coffeecup/templates/components/multiselectable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zqhgA5UW",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,3],[[35,2]],[[\"item\",\"selection\",\"activeItemId\",\"onButtonClick\",\"onSelectItem\"],[[32,1],[35,1],[35,0],\"buttonClick\",\"_selectItem\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"activeItemId\",\"selection\",\"itemPartial\",\"component\",\"fsgList\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/multiselectable-list.hbs"
    }
  });

  _exports.default = _default;
});