define("coffeecup/templates/components/manage-table/cells/project-code-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pDvjkko1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell project-code-cell\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"manage-table-cell-contents\"],[12],[2,\"\\n    \"],[1,[32,0,[\"capitalized\"]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"tooLong\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,\"cc-ember-tooltip\",[],[[\"@popperContainer\"],[\"main\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,0,[\"capitalized\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-code-cell.hbs"
    }
  });

  _exports.default = _default;
});