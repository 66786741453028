define("coffeecup/templates/components/manage/projects/references/reference-overview-modal/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D2oFTCN4",
    "block": "{\"symbols\":[\"@timeEntryReference\"],\"statements\":[[10,\"div\"],[14,0,\"flex-row vertical-center list-row vertical-center\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-2 has-overflow space-right\"],[12],[2,\"\\n    \"],[8,\"tool-tipster\",[[24,0,\"text-overflow reference-title\"]],[[\"@content\"],[[32,1,[\"shortCodeAndPageTitle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"img\"],[14,0,\"platform-logo\"],[15,\"src\",[32,1,[\"projectReferencePlatform\",\"referencePlatform\",\"iconUrl\"]]],[15,\"alt\",[30,[36,0],[[32,1,[\"projectReferencePlatform\",\"referencePlatform\",\"name\"]],\" logo\"],null]],[12],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[32,1,[\"pageTitle\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-overflow reference-url\"],[12],[2,\"\\n      \"],[10,\"a\"],[15,6,[32,1,[\"url\"]]],[14,0,\"reference-url-link\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"\\n        \"],[1,[32,0,[\"url\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-col-4 text-center reference-count\"],[12],[2,\"\\n    \"],[1,[32,0,[\"usageCount\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-col-4 text-center date-created\"],[12],[2,\"\\n    \"],[1,[32,0,[\"dateCreated\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-col-4 text-center date-udpated\"],[12],[2,\"\\n    \"],[1,[32,0,[\"dateUpdated\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-col-1 d-flex reference-destroy\"],[12],[2,\"\\n    \"],[8,\"button-confirm\",[[24,0,\"delete-icon big-icon\"]],[[\"@isIcon\",\"@autoReset\",\"@confirm\",\"@value\",\"@action\"],[true,true,\"cross-exit\",\"bin2\",[32,0,[\"handleRemoveClick\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage/projects/references/reference-overview-modal/row.hbs"
    }
  });

  _exports.default = _default;
});