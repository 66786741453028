define("coffeecup/initializers/ember-data-sails", ["exports", "ember-data-sails/initializers/ember-data-sails"], function (_exports, _emberDataSails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = _exports.default = void 0;
  var _default = _emberDataSails.default;
  _exports.default = _default;
  var initialize = _emberDataSails.default.initialize;
  _exports.initialize = initialize;
});