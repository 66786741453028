define("coffeecup/validations/user-employment", ["exports", "ember-changeset-validations/validators", "coffeecup/validators/validateDate", "coffeecup/validators/validateDailyHours", "coffeecup/validators/validateWeeklyHours"], function (_exports, _validators, _validateDate, _validateDailyHours, _validateWeeklyHours) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validFrom: [(0, _validators.validatePresence)(true), (0, _validateDate.default)({
      onOrAfter: new Date('2000-01-01')
    })],
    employmentType: [(0, _validators.validatePresence)(true)],
    hoursWeekly: [(0, _validateWeeklyHours.default)()],
    hoursMonday: [(0, _validateDailyHours.default)()],
    hoursTuesday: [(0, _validateDailyHours.default)()],
    hoursWednesday: [(0, _validateDailyHours.default)()],
    hoursThursday: [(0, _validateDailyHours.default)()],
    hoursFriday: [(0, _validateDailyHours.default)()],
    hoursSaturday: [(0, _validateDailyHours.default)()],
    hoursSunday: [(0, _validateDailyHours.default)()]
  };
  _exports.default = _default;
});