define("coffeecup/templates/components/dashboard/my-week-module/element-background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ltWyitLo",
    "block": "{\"symbols\":[\"&attrs\",\"@tooltipContent\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"my-week-element\"],[17,1],[12],[2,\"\\n  \"],[8,\"tool-tipster\",[],[[\"@contentAsHTML\",\"@content\",\"@side\",\"@functionPosition\",\"@class\"],[true,[32,2],\"top\",[30,[36,0],[[32,0,[\"positionTooltip\"]]],null],\"my-week-element-tooltip\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"element-header\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[18,3,[[30,[36,1],[\"headerIcon\"],null]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"header-title\"],[12],[2,\"\\n        \"],[18,3,[[30,[36,1],[\"headerTitle\"],null]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"element-body\"],[12],[2,\"\\n      \"],[18,3,[[30,[36,1],[\"body\"],null]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"-named-block-invocation\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/my-week-module/element-background.hbs"
    }
  });

  _exports.default = _default;
});