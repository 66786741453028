define("coffeecup/templates/components/holidays/user-holiday-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rtG5x712",
    "block": "{\"symbols\":[\"regionInSelect\",\"countryInSelect\",\"@countryLabel\",\"@regionLabel\"],\"statements\":[[10,\"div\"],[14,0,\"row form-group\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-6\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[30,[36,1],[[32,3],[30,[36,0],[\"country\"],null]],null]],[13],[2,\"\\n    \"],[8,\"input/cc-select\",[],[[\"@options\",\"@selected\",\"@searchField\",\"@placeholder\",\"@onchange\"],[[32,0,[\"sortedCountries\"]],[32,0,[\"selectedCountry\"]],\"name\",[32,0,[\"placeholder\"]],[32,0,[\"selectRegion\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,2,[\"name\"]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-xs-6\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[30,[36,1],[[32,4],[30,[36,0],[\"region\"],null]],null]],[13],[2,\"\\n    \"],[8,\"input/cc-select\",[],[[\"@options\",\"@disabled\",\"@selected\",\"@searchField\",\"@placeholder\",\"@onchange\"],[[32,0,[\"availableRegions\"]],[32,0,[\"regionsDisabled\"]],[32,0,[\"selectedRegion\"]],\"name\",[32,0,[\"placeholder\"]],[32,0,[\"selectRegion\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"or\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/holidays/user-holiday-settings.hbs"
    }
  });

  _exports.default = _default;
});