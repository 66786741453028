define("coffeecup/templates/components/x-carousel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+RnSOjV9",
    "block": "{\"symbols\":[\"pane\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"active-pane\"],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,0],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"controls noselect\"],[12],[2,\"\\n    \"],[11,\"span\"],[24,0,\"toggle toggle-left\"],[24,\"data-test\",\"button-carousel-left\"],[4,[38,1],[[32,0],\"previous\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon-arrow-67\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"panes\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"span\"],[16,0,[31,[\"index \",[30,[36,0],[[32,1,[\"selected\"]],\"active\"],null]]]],[24,\"data-test\",\"carousel-pane\"],[4,[38,1],[[32,0],\"select\",[32,1]],null],[12],[2,\"●\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[11,\"span\"],[24,0,\"toggle toggle-right\"],[24,\"data-test\",\"button-carousel-right\"],[4,[38,1],[[32,0],\"next\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"icon-arrow-68\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"panes\",\"-track-array\",\"each\",\"showControls\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/x-carousel.hbs"
    }
  });

  _exports.default = _default;
});