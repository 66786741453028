define("coffeecup/mixins/components/tooltips", ["exports", "ember-tooltips/mixins/components/tooltips"], function (_exports, _tooltips) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @see https://github.com/sir-dunxalot/ember-tooltips#customizing-the-mixin
   */
  var _default = Ember.Mixin.create(_tooltips.default, {
    tooltipEffectClass: 'none',
    responsive: Ember.inject.service(),
    renderTooltip: Ember.on('didInsertElement', function () {
      // Only render tooltips if device is not mobile
      if (!this.get('responsive').isMobile()) {
        this._super.apply(this, arguments);
      }
    })
  });

  _exports.default = _default;
});