define("coffeecup/serializers/analytics/client", ["exports", "coffeecup/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO This should be taken out once client analytics IDs are unique based on query parameters
  var queryId = 1;

  var _default = _application.default.extend({
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload) {
        return {
          data: null
        };
      }

      var ret = normalizeObject(store, payload.analyticsClient || payload); // window.console.log('analytics serializer data is %o', ret);

      return {
        data: ret
      };
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      if (!payload) {
        return {
          data: null
        };
      }

      var payloadCleaned = (payload.analyticsClients || payload).filter(function (record) {
        return typeof record.id !== 'undefined' && record.id !== null;
      });
      var ret = payloadCleaned.map(function (record) {
        return normalizeObject(store, record);
      });
      return {
        data: ret
      };
    },

    /**
     @method normalizeQueryResponse
     @param {DS.Store} store
     @param {DS.Model} primaryModelClass
     @param {Object} payload
     @param {String|Number} id
     @param {String} requestType
     @return {Object} JSON-API Document
     */
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload && Ember.isArray(payload.analyticsClients)) {
        var json = this.normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType);
        json.data = json.data.map(function (client) {
          queryId++;
          client.id = queryId;
          return client;
        });
        return json;
      } else {
        var ret = this.normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType);
        return ret && ret.data ? {
          data: [ret.data]
        } : {
          data: []
        };
      }
    }
  });
  /**
   *
   * @param obj
   */


  _exports.default = _default;

  function normalizeObject(store, obj) {
    return {
      id: Ember.get(obj, 'id'),
      type: 'analytics/client',
      relationships: {
        client: {
          data: {
            type: 'client',
            id: Ember.get(obj, 'client')
          }
        }
      },
      attributes: {
        // Hours not rounded
        hoursBilled: Ember.get(obj, 'hours.billed') || 0,
        hoursBudget: Ember.get(obj, 'hours.budget') || 0,
        hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
        hoursOutOfBudget: Ember.get(obj, 'hours.outOfBudget') || 0,
        hoursSpent: Ember.get(obj, 'hours.spent') || 0,
        hoursTotal: Ember.get(obj, 'hours.total') || 0,
        // Hours rounded
        hoursRoundedBilled: Ember.get(obj, 'hoursRounded.billed') || Ember.get(obj, 'hours.billed') || 0,
        hoursRoundedBudget: Ember.get(obj, 'hoursRounded.budget') || Ember.get(obj, 'hours.budget') || 0,
        hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || Ember.get(obj, 'hours.nonBillable') || 0,
        hoursRoundedOutOfBudget: Ember.get(obj, 'hoursRounded.outOfBudget') || Ember.get(obj, 'hours.outOfBudget') || 0,
        hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || Ember.get(obj, 'hours.spent') || 0,
        hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || Ember.get(obj, 'hours.total') || 0,
        amountSpent: Ember.get(obj, 'amount.spent') || 0,
        amountBudget: Ember.get(obj, 'amount.budget') || 0,
        amountOutOfBudget: Ember.get(obj, 'amount.outOfBudget') || 0,
        amountBilled: Ember.get(obj, 'amount.billed') || 0,
        invoicesTotal: Ember.get(obj, 'invoices.total') || 0,
        userCosts: Ember.get(obj, 'costs') || 0,
        expensesTotal: Ember.get(obj, 'expenses.amount') || 0,
        expensesBilled: Ember.get(obj, 'expenses.amountBilled') || 0,
        expensesNotBilled: Ember.get(obj, 'expenses.amountNotBilled') || 0,
        expensesWithinBudget: Ember.get(obj, 'expenses.amountWithinBudget') || 0,
        expensesNotWithinBudget: Ember.get(obj, 'expenses.amountNotWithinBudget') || 0,
        expensesCosts: Ember.get(obj, 'expenses.costs') || 0,
        expensesCostsWithinBudget: Ember.get(obj, 'expenses.costsWithinBudget') || 0,
        expensesCostsNotWithinBudget: Ember.get(obj, 'expenses.costsNotWithinBudget') || 0,
        documents: Ember.get(obj, 'documents') || 0,
        histogram: (Ember.get(obj, 'histogram') || []).map(function (record) {
          return normalizeHistogram(record);
        }),
        users: (Ember.get(obj, 'users') || []).map(function (record) {
          return normalizeUsers(store, record, Ember.get(obj, 'hours.total'));
        }),
        tasks: (Ember.get(obj, 'tasks') || []).map(function (record) {
          return normalizeTasks(store, record, Ember.get(obj, 'hours.total'));
        }),
        projects: (Ember.get(obj, 'projects') || []).map(function (record) {
          return normalizeProjects(store, record, Ember.get(obj, 'hours.total'));
        })
      }
    };
  }
  /**
   * histogram model
   * @param obj
   */


  function normalizeHistogram(obj) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'timestamp'),
      date: Ember.get(obj, 'date'),
      timestamp: Ember.get(obj, 'timestamp'),
      hoursSpent: Ember.get(obj, 'hours.spent') || 0,
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || Ember.get(obj, 'hours.spent') || 0,
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || Ember.get(obj, 'hours.total') || 0,
      hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || Ember.get(obj, 'hours.nonBillable') || 0,
      userCosts: Ember.get(obj, 'costs') || 0,
      expensesTotal: Ember.get(obj, 'expenses.amount') || 0,
      expensesBilled: Ember.get(obj, 'expenses.amountBilled') || 0,
      expensesNotBilled: Ember.get(obj, 'expenses.amountNotBilled') || 0,
      expensesWithinBudget: Ember.get(obj, 'expenses.amountWithinBudget') || 0,
      expensesNotWithinBudget: Ember.get(obj, 'expenses.amountNotWithinBudget') || 0,
      expensesCosts: Ember.get(obj, 'expenses.costs') || 0,
      expensesCostsWithinBudget: Ember.get(obj, 'expenses.costsWithinBudget') || 0,
      expensesCostsNotWithinBudget: Ember.get(obj, 'expenses.costsNotWithinBudget') || 0,
      amountNotBilled: Ember.get(obj, 'amount.billable') || 0,
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      amountSpentTotal: Ember.computed('amountSpent', 'expensesTotal', function () {
        var amountSpent = this.get('amountSpent') || 0;
        var expensesTotal = this.get('expensesTotal') || 0;
        return amountSpent + expensesTotal;
      }),
      totalCosts: Ember.computed('userCosts', 'expensesCosts', function () {
        var expensesCosts = this.get('expensesCosts') ? this.get('expensesCosts') : 0;
        var userCosts = this.get('userCosts') ? this.get('userCosts') : 0;
        return userCosts + expensesCosts;
      }),
      // staff costs
      amountInternal: Ember.get(obj, 'amountInternal.total') || 0
    }).create();
  }
  /**
   * user model
   * @param obj
   * @returns {{id: (*|Object), user_id: (*|Object), hoursNonBillable: (*|Object), hoursSpent: (*|Object), hoursTotal: (*|Object), visible: boolean, amountSpent: (*|Object), amountInternal: (*|Object), histogram: *}}
   */


  function normalizeUsers(store, obj, hoursTotal) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'id'),
      user_id: Ember.get(obj, 'id'),
      // hours
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursSpent: Ember.get(obj, 'hours.spent') || 0,
      // hours rounded
      hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || 0,
      hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || 0,
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || 0,
      visible: false,
      user: Ember.computed('user_id', {
        get: function get() {
          return store.peekRecord('user', this.get('user_id'));
        }
      }),
      // amounts
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      amountInternal: Ember.get(obj, 'amountInternal.total') || 0,
      // staff costs
      costs: Ember.get(obj, 'costs') || 0,
      // the percentage of hours contributed
      percentage: hoursTotal > 0 ? (Ember.get(obj, 'hours.total') || 0) / hoursTotal : 0,
      // histogram data for the users working on the project
      histogram: (Ember.get(obj, 'histogram') || []).map(function (record) {
        return normalizeUserHistogram(record);
      })
    }).create();
  }
  /**
   * user histogram model
   * @param obj one entry of the user histogram
   */


  function normalizeUserHistogram(obj) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'timestamp'),
      date: Ember.get(obj, 'date'),
      timestamp: Ember.get(obj, 'timestamp'),
      // hours not rounded
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      // hours rounded
      hoursSpent: Ember.get(obj, 'hoursRounded.spent') || 0,
      amountSpent: Ember.get(obj, 'amount.spent') || 0
    }).create();
  }
  /**
   * task model
   * @param obj
   */


  function normalizeTasks(store, obj, hoursTotal) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'id'),
      task_id: Ember.get(obj, 'id'),
      // hours
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursSpent: Ember.get(obj, 'hours.spent') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      // hours rounded
      hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || 0,
      hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || 0,
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || 0,
      // amount
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      // the percentage of hours contributed
      percentage: hoursTotal > 0 ? (Ember.get(obj, 'hours.total') || 0) / hoursTotal : 0,
      visible: false,
      task: Ember.computed('task_id', {
        get: function get() {
          return store.peekRecord('task', this.get('task_id'));
        }
      }),
      // histogram data for the users working on the project
      histogram: (Ember.get(obj, 'histogram') || []).map(function (record) {
        return normalizeTaskHistogram(record);
      })
    }).create();
  }
  /**
   * task histogram model
   * @param obj one entry of the task histogram
   */


  function normalizeTaskHistogram(obj) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'timestamp'),
      date: Ember.get(obj, 'date'),
      timestamp: Ember.get(obj, 'timestamp'),
      // hours
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      // hours rounded
      hoursSpent: Ember.get(obj, 'hoursRounded.spent') || 0,
      amountSpent: Ember.get(obj, 'amount.spent') || 0
    }).create();
  }
  /**
   * project model
   * @param obj
   * @returns {{id: (*|Object), project_id: (*|Object), hoursSpent: (*|Object), hoursTotal: (*|Object), hoursBudget: (*|Object), hoursNonBillable: (*|Object), visible: boolean, amountSpent: (*|Object), amountBudget: (*|Object), amountInternal: (*|Object), histogram: *}}
   */


  function normalizeProjects(store, obj, hoursTotal) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'id'),
      project_id: Ember.get(obj, 'id'),
      // hours
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursSpent: Ember.get(obj, 'hours.spent') || 0,
      hoursBudget: Ember.get(obj, 'hours.budget') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      hoursBilled: Ember.get(obj, 'hours.billed') || 0,
      hoursOutOfBudget: Ember.get(obj, 'hours.outOfBudget') || 0,
      // hours rounded
      hoursRoundedBilled: Ember.get(obj, 'hoursRounded.billed') || 0,
      hoursRoundedBudget: Ember.get(obj, 'hoursRounded.budget') || 0,
      hoursRoundedNonBillable: Ember.get(obj, 'hoursRounded.nonBillable') || 0,
      hoursRoundedOutOfBudget: Ember.get(obj, 'hoursRounded.outOfBudget') || 0,
      hoursRoundedSpent: Ember.get(obj, 'hoursRounded.spent') || 0,
      hoursRoundedTotal: Ember.get(obj, 'hoursRounded.total') || 0,
      visible: false,
      // amounts
      amountSpent: Ember.get(obj, 'amount.spent') || 0,
      amountBudget: Ember.get(obj, 'amount.budget') || 0,
      amountOutOfBudget: Ember.get(obj, 'amount.outOfBudget') || 0,
      amountInternal: Ember.get(obj, 'amountInternal.total') || 0,
      userCosts: Ember.get(obj, 'costs') || 0,
      expensesTotal: Ember.get(obj, 'expenses.amount') || 0,
      expensesBilled: Ember.get(obj, 'expenses.amountBilled') || 0,
      expensesNotBilled: Ember.get(obj, 'expenses.amountNotBilled') || 0,
      expensesWithinBudget: Ember.get(obj, 'expenses.amountWithinBudget') || 0,
      expensesNotWithinBudget: Ember.get(obj, 'expenses.amountNotWithinBudget') || 0,
      expensesCosts: Ember.get(obj, 'expenses.costs') || 0,
      expensesCostsWithinBudget: Ember.get(obj, 'expenses.costsWithinBudget') || 0,
      expensesCostsNotWithinBudget: Ember.get(obj, 'expenses.costsNotWithinBudget') || 0,
      invoicesTotal: Ember.get(obj, 'invoices.total') || 0,
      // the percentage of hours contributed
      percentage: hoursTotal > 0 ? (Ember.get(obj, 'hours.total') || 0) / hoursTotal : 0,
      project: Ember.computed('project_id', {
        get: function get() {
          return store.peekRecord('project', this.get('project_id'));
        }
      }),
      // histogram data for the projects done for the client
      histogram: (Ember.get(obj, 'histogram') || []).map(function (record) {
        return normalizeProjectHistogram(record);
      })
    }).create();
  }
  /**
   * project histogram model
   * @param obj one entry of the project histogram
   */


  function normalizeProjectHistogram(obj) {
    return Ember.Object.extend({
      id: Ember.get(obj, 'timestamp'),
      date: Ember.get(obj, 'date'),
      timestamp: Ember.get(obj, 'timestamp'),
      // hours not rounded
      hoursTotal: Ember.get(obj, 'hours.total') || 0,
      hoursNonBillable: Ember.get(obj, 'hours.nonBillable') || 0,
      // hours rounded
      hoursSpent: Ember.get(obj, 'hoursRounded.spent') || 0,
      amountSpent: Ember.get(obj, 'amount.spent') || 0
    }).create();
  }
});