define("coffeecup/templates/team/users/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2ZwtLuhp",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@targetAttachment\",\"@translucentOverlay\",\"@containerClass\",\"@dismissibleByBackdrop\"],[[32,0,[\"onHide\"]],\"center\",false,\"invite-users-modal\",[30,[36,0],[[32,0,[\"showEditSeatsModal\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n      \"],[11,\"div\"],[24,0,\"modal-toggler close\"],[24,\"role\",\"button\"],[4,[38,1],[\"click\",[32,0,[\"onHide\"]]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[14,0,\"icon-cross\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"\\n          Close\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"inviteUser\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n      \"],[8,\"manage/users/invite-users\",[],[[\"@beforeSave\",\"@afterSave\",\"@skipLabel\",\"@activeUserCount\",\"@openManageSeatsModal\",\"@manageSeatsModalShown\"],[[32,0,[\"refreshUserList\"]],[32,0,[\"onHide\"]],[30,[36,2],[\"cancel\"],null],[32,0,[\"currentActiveUsers\"]],[32,0,[\"toggleEditSeatsModal\"]],[32,0,[\"showEditSeatsModal\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"modals/billing/edit-seats\",[],[[\"@openModal\",\"@onHide\",\"@activeUserCount\"],[[32,0,[\"showEditSeatsModal\"]],[32,0,[\"toggleEditSeatsModal\"]],[32,0,[\"currentActiveUsers\"]]]],null]],\"hasEval\":false,\"upvars\":[\"not\",\"on\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/team/users/new.hbs"
    }
  });

  _exports.default = _default;
});