define("coffeecup/templates/components/t-tip-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HUNQZ1Ma",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[31,[\"icomoon-\",[34,1],\" \",[34,0]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"additionalIconClasses\",\"icon\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/t-tip-icon.hbs"
    }
  });

  _exports.default = _default;
});