define("coffeecup/serializers/menu", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //serializers/menu.js
  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      children: {
        serialize: 'ids',
        deserialize: 'records'
      }
    }
  });

  _exports.default = _default;
});