define("coffeecup/models/analytics/planner", ["exports", "@ember-data/model", "coffeecup/models/-super-model"], function (_exports, _model, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //models/analytics/planner.js
  var AnalyticsPlanner = _superModel.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    hoursPlanned: (0, _model.attr)('number'),
    hoursTracked: (0, _model.attr)('number'),
    // relations
    project: (0, _model.belongsTo)('project', {
      async: false
    }),
    user: (0, _model.belongsTo)('user', {
      async: false
    })
  });

  var _default = AnalyticsPlanner;
  _exports.default = _default;
});