define("coffeecup/templates/projects/edit/budget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RweRChe4",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"panel-default panel\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-heading\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pull-left\"],[12],[2,\"\\n      \"],[10,\"h4\"],[12],[2,\"\\n        \"],[8,\"analytics/projects/budget-fmt\",[],[[\"@project\"],[[32,1]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"panel-body\"],[12],[2,\"\\n    \"],[8,\"manage/projects/budget-configurator\",[],[[\"@project\",\"@saveProject\",\"@resetProject\"],[[32,0,[\"model\"]],[30,[36,0],[[32,0],\"saveProject\"],null],[30,[36,0],[[32,0],\"resetProject\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/projects/edit/budget.hbs"
    }
  });

  _exports.default = _default;
});