define("coffeecup/templates/components/input/cc-toggle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0pQk4aqG",
    "block": "{\"symbols\":[\"@tooltipLeft\",\"@selected\",\"@firstVarLabel\",\"@tooltipRight\",\"@secondVarLabel\"],\"statements\":[[10,\"div\"],[14,0,\"cc-toggle-button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"onChange\"],null]],[12],[2,\"\\n  \"],[8,\"tool-tipster\",[],[[\"@content\"],[[30,[36,1],[[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,2],[[32,2],\"cc-toggle-button-var first selected\",\"cc-toggle-button-var first\"],null]],[12],[2,\"\\n      \"],[1,[32,3]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,\"tool-tipster\",[],[[\"@content\"],[[30,[36,1],[[32,4]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[30,[36,2],[[30,[36,3],[[32,2]],null],\"cc-toggle-button-var second selected\",\"cc-toggle-button-var second\"],null]],[12],[2,\"\\n      \"],[1,[32,5]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"if\",\"not\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/cc-toggle-button.hbs"
    }
  });

  _exports.default = _default;
});