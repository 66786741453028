define("coffeecup/utils/ui/numeral", ["exports", "coffeecup/config/environment", "numeral", "numeral/de"], function (_exports, _environment, _numeral, _de) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setCurrencyFormats = setCurrencyFormats;

  /**
   * will set the currency formats by user/account settings or
   * the default settings configured in the environment variables
   *
   * @param i18n {Object} the ember-i18n service
   * @param user {Object} the user object
   * @param [accountSettings] {Object} the general account settings object with other settings
   */
  function setCurrencyFormats(i18n, user, accountSettings) {
    if (Ember.isEmpty(i18n) || Ember.isEmpty(user) || Ember.isEmpty(accountSettings)) {
      return;
    }

    var locale = i18n.get('locale');
    locale = locale.substring(0, 2);

    var languageData = _numeral.default.localeData(locale);

    var currencyFormat = accountSettings.get('currencyFormat');
    var numberFormat = accountSettings.get('numberFormat');

    switch (currencyFormat) {
      case _environment.default.constants.CURRENCY_FORMAT_SIGN_PREFIX:
        // $0,0.00
        _environment.default.settings.CURRENCY_LABEL_FORMAT = ['$ 0a', '$ 0,0.0a', '$ 0,0.00'];
        _environment.default.settings.CURRENCY_LABEL_FORMAT_NO_COMMA = ['$ 0a', '$ 0,0a', '$ 0,0'];

        _numeral.default.defaultFormat('$ 0,0.00');

        break;

      default:
        // ENV.constants.CURRENCY_FORMAT_SIGN_SUFFIX: // 0,0.00$
        _environment.default.settings.CURRENCY_LABEL_FORMAT = ['0a $', '0,0.0a $', '0,0.00 $'];
        _environment.default.settings.CURRENCY_LABEL_FORMAT_NO_COMMA = ['0a $', '0,0a $', '0,0 $'];

        _numeral.default.defaultFormat('0,0.00 $');

        break;
    }

    switch (numberFormat) {
      case _environment.default.constants.NUMBER_FORMAT_COMMA_DELIMITER:
        languageData.delimiters = {
          thousands: ',',
          decimal: '.'
        };
        break;

      default:
        // ENV.constants.NUMBER_FORMAT_POINT_DELIMITER: // '0.0,00'
        languageData.delimiters = {
          thousands: '.',
          decimal: ','
        };
        break;
    }

    languageData.abbreviations = {
      thousand: i18n.t('thousandAbbreviation'),
      million: i18n.t('millionAbbreviation'),
      billion: i18n.t('billionAbbreviation'),
      trillion: i18n.t('trillionAbbreviation')
    };
    var currencySymbol;
    var currencyAbbreviation;
    var currency = accountSettings.get('currency');

    if (currency) {
      currencyAbbreviation = currency.get('id');
      currencySymbol = currency.get('sign');
    } else {
      currencyAbbreviation = _environment.default.constants.CURRENCY_EURO;
      currencySymbol = _environment.default.constants.CURRENCY_SYMBOL_EURO;
    }

    languageData.currency = {
      symbol: currencySymbol,
      abbreviation: currencyAbbreviation
    }; // set language specific values

    _numeral.default.localeData(locale, languageData); // set the locale


    _numeral.default.locale(locale);
  }
});