define("coffeecup/templates/components/dropdown-option-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lkz0Ig8Q",
    "block": "{\"symbols\":[\"key\",\"@icon\",\"@smallIcon\",\"@isChecked\",\"@iconName\",\"@label\",\"@untranslatedLabel\"],\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[[32,2],\" pull-right\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"span\"],[15,0,[31,[\"icon icomoon-\",[32,5],\" \",[30,[36,2],[[32,4],\"invisible\"],null],\" \",[30,[36,1],[[32,3],\"small\"],null]]]],[12],[13],[2,\"\\n\"],[10,\"span\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[32,6],[30,[36,3],[[32,6]],null]],null]],[2,\"\\n  \"],[1,[32,7]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"keys\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"pull-right keyboard-shortcut\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"capitalize-word\",\"if\",\"unless\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dropdown-option-content.hbs"
    }
  });

  _exports.default = _default;
});