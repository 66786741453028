define("coffeecup/utils/open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = open;

  /**
   * A wrapper for window.open which takes string promises as url.
   * The window is opened immediately to get around browser popup blocking.
   *
   * @param {string|Promise<string>} url
   * @param  {...any} args
   */
  function open(url) {
    var _window;

    var urlIsPromise = url && Ember.typeOf(url.then) == 'function';

    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }

    var w = (_window = window).open.apply(_window, [urlIsPromise ? 'about:blank' : url].concat(args));

    if (urlIsPromise) {
      url.then(function (url) {
        w.location.href = url;
      }, function () {
        w.close();
      });
    }

    return w;
  }
});