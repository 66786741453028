define("coffeecup/templates/components/planner/scope-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SCd44hcb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"scope-controls\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"scope-date-control\"],[12],[2,\"\\n    \"],[8,\"input/cc-date-field\",[],[[\"@openPickerForDisabled\",\"@disabled\",\"@notEditable\",\"@date\"],[true,[32,0,[\"linkScopeToViewport\"]],true,[32,0,[\"scopeStartDate\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"scope-date-control\"],[12],[2,\"\\n    \"],[8,\"input/cc-date-field\",[],[[\"@openPickerForDisabled\",\"@disabled\",\"@notEditable\",\"@date\"],[true,[32,0,[\"linkScopeToViewport\"]],true,[32,0,[\"scopeEndDate\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n    \"],[8,\"icon-switch\",[],[[\"@showAsButton\",\"@iconOn\",\"@iconOff\",\"@value\"],[true,\"icomoon-linked\",\"icomoon-unlinked\",[32,0,[\"linkScopeToViewport\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/scope-controls.hbs"
    }
  });

  _exports.default = _default;
});