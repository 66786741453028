define("coffeecup/templates/components/x-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iTcAkVkU",
    "block": "{\"symbols\":[\"pane\",\"&default\"],\"statements\":[[10,\"ul\"],[14,0,\"nav nav-tabs\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,\"role\",\"presentation\"],[15,0,[31,[[30,[36,0],[[32,1,[\"selected\"]],\"active\"],null]]]],[12],[2,\"\\n      \"],[11,\"a\"],[24,6,\"#\"],[4,[38,1],[[32,0],\"select\",[32,1]],null],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"tab-content\"],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"panes\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/x-tabs.hbs"
    }
  });

  _exports.default = _default;
});