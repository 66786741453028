define("coffeecup/services/employment", ["exports", "moment", "coffeecup/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var USER_TYPES = [_environment.default.constants.USER_TYPE_EMPLOYEE, _environment.default.constants.USER_TYPE_FREELANCER, _environment.default.constants.USER_TYPE_EMPLOYEE_HOURLY, _environment.default.constants.USER_TYPE_FREELANCER_HOURLY];

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    clock: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    createDefaultEmploymentEntry: function createDefaultEmploymentEntry(user, activeEmployment) {
      var newEmploymentEntry = this.get('store').createRecord('user-employment');
      newEmploymentEntry.set('monthlyRemuneration', 0);
      newEmploymentEntry.set('hourlyRemuneration', 0);
      newEmploymentEntry.set('employerContribution', 0);
      newEmploymentEntry.set('hoursWeekly', 40);
      newEmploymentEntry.set('hoursMonday', 8);
      newEmploymentEntry.set('hoursTuesday', 8);
      newEmploymentEntry.set('hoursWednesday', 8);
      newEmploymentEntry.set('hoursThursday', 8);
      newEmploymentEntry.set('hoursFriday', 8);
      newEmploymentEntry.set('hoursSaturday', 0);
      newEmploymentEntry.set('hoursSunday', 0);
      newEmploymentEntry.set('user', user);
      var accountSettings = this.get('accountSettings');
      newEmploymentEntry.set('employerContribution', accountSettings.get('defaultEmployerContribution'));
      var today = this.get('clock.localDayAsUtcMoment');

      if (activeEmployment) {
        newEmploymentEntry.set('employmentType', activeEmployment.employmentType);

        var lastValidFrom = _moment.default.utc(activeEmployment.get('validFrom'));

        if (today.diff(lastValidFrom, 'days') > 0) {
          newEmploymentEntry.set('validFrom', today.clone());
        } else {
          newEmploymentEntry.set('validFrom', lastValidFrom.add(1, 'day').clone());
        }

        switch (activeEmployment.get('employmentType')) {
          case _environment.default.constants.USER_TYPE_EMPLOYEE:
            newEmploymentEntry.set('monthlyRemuneration', activeEmployment.get('monthlyRemuneration'));
            break;

          case _environment.default.constants.USER_TYPE_FREELANCER:
          case _environment.default.constants.USER_TYPE_FREELANCER_HOURLY:
          case _environment.default.constants.USER_TYPE_EMPLOYEE_HOURLY:
            newEmploymentEntry.set('hourlyRemuneration', activeEmployment.get('hourlyRemuneration'));
            break;
        }

        var workHours = activeEmployment.get('workHoursType');

        if (workHours === _environment.default.constants.WORK_HOURS_FIXED_WEEK) {
          newEmploymentEntry.set('workHoursType', _environment.default.constants.WORK_HOURS_FIXED_WEEK);
          newEmploymentEntry.set('hoursWeekly', activeEmployment.get('hoursWeekly'));
        } else if (workHours === _environment.default.constants.WORK_HOURS_FIXED_DAY) {
          newEmploymentEntry.set('workHoursType', _environment.default.constants.WORK_HOURS_FIXED_DAY);
          newEmploymentEntry.set('hoursMonday', activeEmployment.get('hoursMonday'));
          newEmploymentEntry.set('hoursTuesday', activeEmployment.get('hoursTuesday'));
          newEmploymentEntry.set('hoursWednesday', activeEmployment.get('hoursWednesday'));
          newEmploymentEntry.set('hoursThursday', activeEmployment.get('hoursThursday'));
          newEmploymentEntry.set('hoursFriday', activeEmployment.get('hoursFriday'));
          newEmploymentEntry.set('hoursSaturday', activeEmployment.get('hoursSaturday'));
          newEmploymentEntry.set('hoursSunday', activeEmployment.get('hoursSunday'));
        }
      } else {
        newEmploymentEntry.set('validFrom', today.clone());
        var userEmploymentType = user.get('employmentType');

        if (USER_TYPES.includes(userEmploymentType)) {
          newEmploymentEntry.set('employmentType', userEmploymentType);
        } // Old workhours types for legacy users (that don't have a user employment entry)


        var HOURS_OF_WORK_TYPE_NONE = 0; // user must not fulfill a certain amount of daily hours

        var HOURS_OF_WORK_TYPE_DAILY = 1; // user must fulfill a certain amount of daily hours

        if (user.get('workHoursType') === HOURS_OF_WORK_TYPE_NONE) {
          newEmploymentEntry.set('workHoursType', _environment.default.constants.WORK_HOURS_FLEXIBLE);
        } else if (user.get('workHoursType') === HOURS_OF_WORK_TYPE_DAILY) {
          newEmploymentEntry.set('workHoursType', _environment.default.constants.WORK_HOURS_FIXED_DAY);
          newEmploymentEntry.set('hoursMonday', user.get('hoursMonday'));
          newEmploymentEntry.set('hoursTuesday', user.get('hoursTuesday'));
          newEmploymentEntry.set('hoursWednesday', user.get('hoursWednesday'));
          newEmploymentEntry.set('hoursThursday', user.get('hoursThursday'));
          newEmploymentEntry.set('hoursFriday', user.get('hoursFriday'));
          newEmploymentEntry.set('hoursSaturday', user.get('hoursSaturday'));
          newEmploymentEntry.set('hoursSunday', user.get('hoursSunday'));
        }
      }

      return newEmploymentEntry;
    }
  });

  _exports.default = _default;
});