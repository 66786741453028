define("coffeecup/templates/components/custom-icon-configurator/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5lrZu/t4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"border-element\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"color-element\"],[15,5,[31,[\"background-color: \",[34,0]]]],[12],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"colorSafe\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/custom-icon-configurator/color-picker.hbs"
    }
  });

  _exports.default = _default;
});