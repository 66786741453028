define("coffeecup/routes/team/users/edit", ["exports", "coffeecup/config/environment", "coffeecup/routes/manage/_super-route", "coffeecup/utils/build-url"], function (_exports, _environment, _superRoute, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    formatter: Ember.inject.service(),
    session: Ember.inject.service(),
    events: Ember.inject.service(),
    permissions: Ember.inject.service(),
    titleToken: function titleToken(model) {
      return model.get('label');
    },
    listController: 'team.users',
    setupController: function setupController(controller, context, transition) {
      this._super(controller, context);

      var employment = context._employment ? context._employment.toArray() : [];
      var tagAssignments = this.store.peekAll('tag-assignment').filter(function (el) {
        return el.get('model') === 'user' && el.get('record') === context.get('idAsInt');
      });
      var tags = tagAssignments.getEach('tag');
      controller.set('selectedTags', tags);
      controller.set('existingTags', this.store.peekAll('tag'));
      controller.set('pageLoadedAt', this.get('clock.now'));
      var roles = this.store.peekAll('role');

      if (!this.get('permissions.isRootAdmin')) {
        roles = roles.filter(function (el) {
          return el.get('baseType') !== 2;
        });
      }

      if (context._userAssignments) {
        controller.set('model.userAssignments', context._userAssignments);
      }

      controller.set('projects', context._projects);
      controller.set('employmentEntries', employment);
      controller.set('selectedTags', tags);
      controller.set('timezones', this.store.peekAll('timezone')); // fixtures

      controller.set('languages', this.store.peekAll('language')); // fixtures

      controller.set('beginningOfWeeks', this.store.peekAll('beginning-of-week')); // fixtures

      controller.set('countries', this.store.peekAll('country')); // fixtures

      controller.set('insuranceTypes', this.store.peekAll('insurance-type')); // fixtures

      controller.set('roles', roles); // have been initially loaded within super-route

      controller.set('dateFormats', this.store.peekAll('date-format'));
      controller.set('newVacationEntry', this.store.createRecord('vacationEntry', {
        mode: 'new'
      }));
      controller.set('focus', 'firstname');
      controller.set('vacationShowMore', false);
      controller.set('userAutoApproval', context.get('activeAutoApproval') ? _environment.default.constants.YES : _environment.default.constants.NO);
      controller.set('activateValidationForUser', !!context.get('supervisor.id'));
      controller.set('userAssignmentShowMore', false);
      controller.set('localImg', null);
      controller.set('userAssignments', context._userAssignments.toArray());
      controller.set('vacationDetailObjects', context._vacationDetailObjects);
      var currentYear = this.get('clock.localDayAsUtcMoment').startOf('year').format('YYYY');
      controller.set('selectedYear', currentYear);
      controller.set('activeEmploymentEntry', controller.determineActiveEmploymentEntry());

      if (transition.queryParams && transition.queryParams.isNewUser) {
        this._showAlert({
          icon: this._defineModalIcon('mail'),
          title: this.get('i18n').t('invitationSent'),
          text: this.get('i18n').t('invitationSentDescription', {
            name: context.get('label'),
            mail: context.get('email')
          })
        });
      }
    },
    model: function model(params) {
      var _arguments = arguments,
          _this2 = this;

      var currentUser = this.getCurrentUser();
      var user_id = params.user_id || currentUser.get('id');
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.RSVP.hash({
          user: _this2._super.apply(_this2, _arguments),
          employment: _this2._performLoadEmploymentHistory(user_id),
          // userAssignments: this.store.findAll('user-assignment'),
          userAssignments: _this2.store.query('user-assignment', {
            limit: 5000,
            // TODO LOAD MORE entries if meta.total is higher than 5000
            where: {
              user: user_id
            }
          }),
          projects: _this2.store.peekAll('project')
        }).then(function (hash) {
          var model = hash.user;
          var userAssignmentsForCurrentUser = hash.userAssignments;
          model.set('userAssigments', userAssignmentsForCurrentUser);
          model._employment = hash.employment;
          model._userAssignments = userAssignmentsForCurrentUser;
          model._projects = hash.projects;
          var projectIds = userAssignmentsForCurrentUser.getEach('project.id');

          if (projectIds && projectIds.length > 0) {
            _this2.store.query('user-assignment', {
              limit: 5000,
              // TODO LOAD MORE entries if meta.total is higher than 5000
              where: {
                project: {
                  in: projectIds
                }
              }
            });
          }

          resolve(model);
        });
      });
    },
    afterModel: function afterModel(model) {
      var _this3 = this;

      this._super.apply(this, arguments);

      return new Ember.RSVP.Promise(function (resolve) {
        _this3._loadVacationEntries(model).then(function (vacationDetailObjectsArrays) {
          var vacationDetailObjects = Ember.A();
          vacationDetailObjectsArrays.forEach(function (vacationDetailObject) {
            return vacationDetailObjects.pushObjects(vacationDetailObject);
          });
          model._vacationDetailObjects = vacationDetailObjects;
          resolve(model);
        });
      });
    },
    _loadVacationEntries: function _loadVacationEntries(model) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var currentYear = _this4.get('clock.localDayAsUtcMoment').add(1, 'year').startOf('year').format('YYYY');

        var firstYear = moment(model.get('firstEmploymentEntry.validFrom')).format('YYYY');
        var vacationPromises = [];
        /* jshint ignore:start */

        for (var i = currentYear; i >= firstYear; i--) {
          vacationPromises.push(_this4._performLoadVacationDetails(i, model.get('id'), firstYear));
        }
        /* jshint ignore:end */


        Ember.RSVP.all(vacationPromises).then(resolve);
      });
    },
    _performLoadEmploymentHistory: function _performLoadEmploymentHistory(userId) {
      return this.store.query('user-employment', {
        sort: 'validFrom ASC',
        where: {
          user: userId
        }
      });
    },
    _performLoadVacationDetails: function _performLoadVacationDetails(selectedYear, userId, firstYear) {
      var _this5 = this;

      var _this = this;

      var formatter = this.get('formatter');
      var i18n = this.get('i18n');
      var formattedYear = '' + (selectedYear || this.get('clock.localDayAsUtcMoment').startOf('year').format('YYYY'));

      if (!userId) {
        var yearlyBudgetEntry = this.store.createRecord('vacation-entry', {
          quantity: _environment.default.constants.DEFAULT_VACATION_DAYS,
          comment: i18n.t('yearlyVacation'),
          date: moment(formattedYear).startOf('Year').toISOString(),
          year: formattedYear,
          vacationType: _environment.default.constants.VACATION_TYPE_YEARLY_BUDGET
        });
        return new Ember.RSVP.Promise(function (resolve) {
          return resolve([yearlyBudgetEntry]);
        });
      }

      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var params = {
          user: userId,
          year: formattedYear
        };
        var url = (0, _buildUrl.default)(_this.store, 'vacationbudgets/calc', params);

        var existingRequest = _this.get('_runningVacationDetailsRequest');

        if (existingRequest && existingRequest.user === params.user && existingRequest.year === params.year) {
          reject('Identical request in progress');
          return;
        }

        _this.set('_runningVacationDetailsRequest', params);

        _this.get('session').getJSON(url).then(function (vacationDetails) {
          if (params.user !== userId) {
            reject('ID after request different');
            return;
          }

          _this.set('_runningVacationDetailsRequest', null);

          var absenceQuantities = [];
          var vacationBudgetIds = [];
          var vacationDetailsOfYear = [];
          vacationDetails.forEach(function (vacationDetail) {
            if (vacationDetail.vacationType === _environment.default.constants.VACATION_TYPE_EXTRA_BUDGET || vacationDetail.vacationType === _environment.default.constants.VACATION_TYPE_YEARLY_BUDGET) {
              vacationDetail.extra && vacationDetail.extra.vacationBudgetId && vacationBudgetIds.push(vacationDetail.extra.vacationBudgetId);
            } else if (vacationDetail.vacationType === _environment.default.constants.VACATION_TYPE_VACATION_TAKEN) {
              vacationDetail.extra && vacationDetail.extra.absenceId && absenceQuantities.push({
                id: vacationDetail.extra.absenceId,
                quantity: vacationDetail.quantity
              });
            } else if (vacationDetail.vacationType === _environment.default.constants.VACATION_TYPE_ALLOWANCE && selectedYear !== Math.abs(firstYear)) {
              var vacationAdditional = _this.store.createRecord('vacation-entry', {
                quantity: vacationDetail.quantity,
                comment: i18n.t('vacationCarryover'),
                date: moment(formattedYear).startOf('Year').toISOString(),
                year: formattedYear,
                vacationType: vacationDetail.vacationType
              });

              vacationDetailsOfYear.push(vacationAdditional);
            }
          });
          var loadingPromises = [];

          if (vacationBudgetIds.length > 0) {
            var vacationBudgetPromise = new Ember.RSVP.Promise(function (resolve) {
              _this5.store.query('vacation-budget', {
                where: {
                  id: {
                    in: vacationBudgetIds
                  }
                }
              }).then(function (vacationBudgets) {
                vacationBudgets.forEach(function (vacationBudget) {
                  var isYearlyBudget = vacationBudget.get('vacationType') === _environment.default.constants.VACATION_TYPE_YEARLY_BUDGET;

                  var budgetDate = moment(vacationBudget.get('updatedAt'));

                  if (budgetDate.isBefore(moment().year(formattedYear), 'year') || isYearlyBudget) {
                    budgetDate = moment(vacationBudget.get('startDate'));
                  }

                  var vacationBudgetObject = _this.store.createRecord('vacation-entry', {
                    quantity: vacationBudget.get('quantity'),
                    comment: isYearlyBudget ? i18n.t('yearlyVacationBudget') : vacationBudget.get('comment'),
                    date: budgetDate.toISOString(),
                    year: formattedYear,
                    vacationType: vacationBudget.get('vacationType'),
                    vacationBudgetId: vacationBudget.get('id')
                  });

                  vacationDetailsOfYear.push(vacationBudgetObject);
                });
                resolve();
              });
            });
            loadingPromises.push(vacationBudgetPromise);
          }

          if (absenceQuantities.length > 0) {
            var absencePromise = new Ember.RSVP.Promise(function (resolve) {
              _this5.store.query('absence', {
                where: {
                  id: {
                    in: absenceQuantities.map(function (aq) {
                      return aq.id;
                    })
                  }
                }
              }).then(function (absences) {
                absences.forEach(function (absence, index) {
                  var vacationAbsence = _this.store.createRecord('vacation-entry', {
                    quantity: absenceQuantities.find(function (x) {
                      return x.id === absence.get('idAsInt');
                    }).quantity,
                    comment: i18n.t('absenceDurationDescription', {
                      absenceType: absence.get('absenceType.localizedLabel'),
                      startDate: formatter.formatDate(moment(absence.get('startDate'))),
                      endDate: formatter.formatDate(moment(absence.get('endDate')))
                    }),
                    date: moment(absence.get('startDate')).toISOString(),
                    year: formattedYear,
                    vacationType: _environment.default.constants.VACATION_TYPE_VACATION_TAKEN,
                    vacationId: absence.get('id')
                  });

                  vacationDetailsOfYear.push(vacationAbsence);
                });
                resolve();
              });
            });
            loadingPromises.push(absencePromise);
          }

          Ember.RSVP.all(loadingPromises).then(function () {
            resolve(vacationDetailsOfYear);
          });
        }).catch(function (e) {
          console.error(e);
          resolve([]);
        });
      });
      promise.catch(function (e) {
        return console.error(e);
      });
      return promise;
    },
    _handleSocketNotifications: Ember.on('init', function () {
      var sailsSocket = this.get('sailsSocket');
      sailsSocket.on(_environment.default.constants.NOTIFICATION_MISSING_VACATION_BUDGETS_CREATED, this, function () {
        this.send('reloadVacationEntries');
      });
      this.sailsSocket.listenFor(_environment.default.constants.NOTIFICATION_MISSING_VACATION_BUDGETS_CREATED, true);
    }),
    actions: {
      reloadVacationEntries: function reloadVacationEntries() {
        var _this6 = this;

        this._loadVacationEntries(this.controller.get('model')).then(function (vacationDetailObjectsArrays) {
          var vacationDetailObjects = Ember.A();
          vacationDetailObjectsArrays.forEach(function (vacationDetailObject) {
            return vacationDetailObjects.pushObjects(vacationDetailObject);
          });

          _this6.controller.set('vacationDetailObjects', vacationDetailObjects);
        });
      }
    }
  });

  _exports.default = _default;
});