define("coffeecup/validators/validateUnique", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateUnique;

  /**
   *
   * @param records
   * @returns true if value is unique
   */
  var validateUniqueness = function validateUniqueness(key, newValue, model, records) {
    var currentId = model.get('id');
    return records.content.filter(function (item) {
      var value = item._record ? item._record.get(key) : item.get(key);
      return currentId != item.id && typeof value === 'string' && typeof newValue === 'string' && value.toUpperCase() === newValue.toUpperCase();
    }).get('length') <= 0;
  };
  /**
   *
   * @param records
   * @returns true if value is unique
   */


  var checkUniqueness = function checkUniqueness(key, newValue, model, records) {
    if (validateUniqueness(key, newValue, model, records)) {
      return true;
    } else {
      return 'Value already taken';
    }
  };

  function validateUnique(opts) {
    return function (key, newValue, oldValue, changes, model) {
      return new Ember.RSVP.Promise(function (resolve) {
        var store = model.get('store');
        var modelKey = model.constructor.modelName || model.typeKey || model._internalModel.modelName; // fallback for buffered-form

        var allRecords = store.peekAll(modelKey);

        if (!newValue) {
          return resolve(true);
        } // if it's in the store already, fire a custom query


        if (allRecords.get('length') > 0 && allRecords.every(function (item) {
          return item.get('_record') || item.get('isLoaded');
        })) {
          return resolve(checkUniqueness(key, newValue, model, allRecords));
        } else {
          var options = {};
          options[key] = newValue;
          store.query(modelKey, {
            where: options
          }).then(function (foundRecords) {
            return resolve(checkUniqueness(key, newValue, model, foundRecords));
          }).catch(function () {
            return resolve(true);
          });
        }
      });
    };
  }
});