define("coffeecup/routes/analytics/projects/billing", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    formatter: Ember.inject.service(),
    permissions: Ember.inject.service(),
    renderTemplate: function renderTemplate() {
      this.render('analytics.projects.billing', {
        into: 'application',
        outlet: 'modal-time-entries-export'
      });
    },
    setupController: function setupController(controller, context, transition) {
      this._super.apply(this, arguments);

      var parentController = this.controllerFor('analytics.time-entries');
      controller.set('from', parentController.get('from'));
      controller.set('to', parentController.get('to'));
      controller.set('page', 1);
      controller.set('isLoading', false);
      controller.set('fixedBilledAmount', 0);
      Ember.run.later(function () {
        return controller.set('openModal', true);
      });
    },
    model: function model() {},
    actions: {
      transitionAway: function transitionAway() {
        this.transitionTo('analytics.time-entries');
      }
    }
  });

  _exports.default = _default;
});