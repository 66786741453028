define("coffeecup/templates/components/planner/user-project-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rp86uw15",
    "block": "{\"symbols\":[\"@tooltipText\",\"@roundedHoursPlanned\"],\"statements\":[[10,\"div\"],[14,0,\"row-summary\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"summary-inner\"],[12],[2,\"\\n\"],[2,\"    \"],[8,\"planner/planner-tooltip\",[],[[\"@contentAsHTML\",\"@content\",\"@side\",\"@leftInset\",\"@class\"],[true,[32,1],\"top\",0,\"blue\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,2]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/user-project-summary.hbs"
    }
  });

  _exports.default = _default;
});