define("coffeecup/services/absence-type", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    absenceTypeSorting: Object.freeze(['order:asc']),
    _absenceTypesSorted: Ember.computed.sort('_absenceTypes', 'absenceTypeSorting'),
    getSortedAbsenceTypes: function getSortedAbsenceTypes(absenceTypes) {
      this.set('_absenceTypes', absenceTypes);
      absenceTypes = this.get('_absenceTypesSorted');
      var vacationType = absenceTypes.findBy('baseType', _environment.default.constants.ABSENCE_TYPE_VACATION);
      var illnessType = absenceTypes.findBy('baseType', _environment.default.constants.ABSENCE_TYPE_ILLNESS);
      var overtimeReductionType = absenceTypes.findBy('baseType', _environment.default.constants.ABSENCE_TYPE_OVERTIME_REDUCTION);
      absenceTypes.removeObject(vacationType);
      absenceTypes.removeObject(illnessType);
      absenceTypes.removeObject(overtimeReductionType);
      if (overtimeReductionType) absenceTypes.unshift(overtimeReductionType);
      if (illnessType) absenceTypes.unshift(illnessType);
      if (vacationType) absenceTypes.unshift(vacationType);
      return absenceTypes;
    }
  });

  _exports.default = _default;
});