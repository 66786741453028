define("coffeecup/templates/components/input/text-area-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i/XWxNIP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,0,[34,4]],[15,\"for\",[34,5,[\"elementId\"]]],[12],[1,[34,6]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[34,7]],[12],[2,\"\\n  \"],[8,\"textarea\",[[16,0,[34,8]],[24,\"viewName\",\"cctextarea\"],[16,\"rows\",[34,10]],[16,\"readonly\",[34,11]],[16,\"disabled\",[34,12]],[16,\"placeholder\",[34,13]]],[[\"@value\",\"@keyUp\"],[[34,9],[32,0,[\"handleKeyUp\"]]]],null],[2,\"\"],[6,[37,2],[[35,14]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[\"h6 \",[30,[36,2],[[32,0,[\"overCharLimit\"]],\"over-limit\"],null],\" \"]]],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,15]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"expander\"],[15,5,[31,[\"position: absolute; top: -99999px; left: -99999px; visibility: hidden; min-height: \",[34,0],\"px;\"]]],[12],[2,\"\\n    \"],[2,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"minHeight\",\"valueForAutogrow\",\"if\",\"charsLeftLabel\",\"labelClasses\",\"cctextarea\",\"label\",\"inputContainerClasses\",\"inputClasses\",\"value\",\"rows\",\"readonly\",\"disabled\",\"placeholder\",\"showCharCounter\",\"autogrow\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/text-area-wrapper.hbs"
    }
  });

  _exports.default = _default;
});