define("coffeecup/routes/time-entries/verifying/index", ["exports", "coffeecup/utils/build-url", "coffeecup/utils/date", "coffeecup/config/environment", "coffeecup/routes/_super-route"], function (_exports, _buildUrl, _date, _environment, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

  function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _superRoute.default.extend({
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    teamService: Ember.inject.service('team'),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    titleToken: function titleToken() {
      return this.get('i18n').t('workTime');
    },
    queryParams: {
      week: {
        refreshModel: true
      },
      validationFilter: {
        refreshModel: false
      }
    },
    model: function model(params) {
      var worktimeController = this.controllerFor('time-entries.verifying');
      var controller = this.controllerFor('time-entries.verifying.index');
      var teamFilter = this.paramsFor('time-entries.verifying').team;
      this.set('team', teamFilter);
      controller.set('isLoading', true);
      var week = (0, _date.validateDate)(params.week, worktimeController.get('week'));
      var from = moment(week, _environment.default.constants.DATE_FORMAT_DAY).startOf('week');
      var to = from.clone().endOf('week');
      var filteredUserHolidays = this.store.peekAll('user-holiday').filter(function (el) {
        return el.get('date').isSameOrAfter(from, 'day') && el.get('date').isSameOrBefore(to, 'day');
      });
      var allUsers = this.store.peekAll('user');
      var anyUserIsValidated = allUsers && allUsers.filter(function (el) {
        return el.get('supervisor.id');
      });
      var shouldISeeAllValidations = this.get('permissions.isRootAdmin') || this.get('permissions.canValidateWorkTime');
      var filteredTeamUserIds = [];

      if (shouldISeeAllValidations) {
        if (teamFilter) {
          var teamUserIds = this.get('teamService').getAllUserAssignedToTeam(this.get('store').peekRecord('team', teamFilter));
          filteredTeamUserIds = teamUserIds;
        }
      } else {
        var _teamUserIds = this.get('teamService').getAllUserAssignedToTeam(this.get('store').peekRecord('team', this.get('currentUser.currentUser.team.id')));

        filteredTeamUserIds = _teamUserIds;
      }

      if (anyUserIsValidated && anyUserIsValidated.length > 0) {
        return Ember.RSVP.hash({
          validationData: this.get('session').getJSON((0, _buildUrl.default)(this.store, 'validate/checkUserWorktime', _objectSpread({
            startDate: from.format(_environment.default.constants.DATE_FORMAT_DAY),
            endDate: to.format(_environment.default.constants.DATE_FORMAT_DAY)
          }, filteredTeamUserIds.length ? {
            users: JSON.stringify(filteredTeamUserIds)
          } : {}))),
          filteredUserHolidays: filteredUserHolidays,
          validations: this.store.query('validation', {
            where: _objectSpread({
              week: from.format(_environment.default.constants.DATE_FORMAT_DAY)
            }, filteredTeamUserIds.length ? {
              user: {
                in: filteredTeamUserIds
              }
            } : {})
          })
        });
      } else {
        return {
          noValidationActive: true
        };
      }
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      if (model.noValidationActive) {
        controller.set('isLoading', false);
        controller.set('noValidationActive', true);
      } else {
        controller.set('noValidationActive', false);
        controller.set('isLoading', false);
        var userData = model.validationData.userData.map(function (user) {
          user.user = _this.store.peekRecord('user', user.id);
          user.validation = user.isAlreadyValidated ? _this.store.peekRecord('validation', user.isAlreadyValidated) : null;
          user.holidays = model.filteredUserHolidays; // Strip timezone info from Valdiation day (should be fixed on Backend -- we should only be passing "dates" around, not timestamps)

          user.days = user.days.map(function (_ref) {
            var day = _ref.day,
                rest = _objectWithoutProperties(_ref, ["day"]);

            return _objectSpread(_objectSpread({}, rest), {}, {
              day: typeof day === 'string' ? day.substring(0, 10) : day
            });
          });
          return user;
        });
        controller.set('userData', userData);
      }
    }
  });

  _exports.default = _default;
});