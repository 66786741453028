define("coffeecup/templates/components/percentage-difference", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cgDT9iqo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-overflow\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"icon-arrow-3 up\"],[12],[13],[2,\"\\n    +\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"icon-arrow-4 down\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"icon-arrow-2 equal\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[1,[30,[36,5],[[35,4]],null]],[2,\"\\n  \"],[1,[30,[36,6],[\"toThe\"],null]],[2,\"\\n  \"],[1,[34,7]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[3,\"code>\\n    diff: {{!diff}},\\n    val: {{!val}},\\n    old: {{!old}},\\n    valRounded: {{!valRounded}},\\n    oldRounded: {{!oldRounded}}\\n</code\"]],\"hasEval\":false,\"upvars\":[\"up\",\"if\",\"down\",\"equal\",\"diff\",\"percent-fmt\",\"t\",\"label\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/percentage-difference.hbs"
    }
  });

  _exports.default = _default;
});