define("coffeecup/routes/projects/clients/analytics", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    setupController: function setupController(controller, context) {
      var client = context.client;
      var allInvoices = this.store.query('invoice', {
        // where: {
        //   project: project.id, // is it possible to where this correctly? Until the, filtering inthe controller
        // },
        sort: 'invoicedAt ASC'
      });
      var clientAnalytics = this.store.query('analytics/client', {
        client: client.id,
        histogramInterval: 'month',
        histogramType: 'total'
      });
      controller.set('client', context.client);
      controller.set('clientAnalyticsArray', clientAnalytics);
      controller.set('allInvoices', allInvoices);
    },
    model: function model(params, transition) {
      var clientId = params.client_id;
      var client = clientId && this.store.peekRecord('client', clientId);

      if (!client || client.get('isDeleted')) {
        transition.abort();
      }

      return {
        client: client
      };
    }
  });

  _exports.default = _default;
});