define("coffeecup/mixins/scrollable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ScrollableMixin = Ember.Mixin.create({
    $scrollElement: null,
    didInsertElement: function didInsertElement() {
      this.bindScrolling();
    },
    willRemoveElement: function willRemoveElement() {
      this.unbindScrolling();
    },
    scrolled: function scrolled() {
      var $scrollElement = this.get('$scrollElement') || this.$('.scrollable');
      var controller = this.get('controller');

      if ($scrollElement.scrollTop() > 1) {
        controller.set('scrolled', true);
      } else {
        controller.set('scrolled', false);
      }
    },
    bindScrolling: function bindScrolling() {
      var self = this;
      var onScroll;
      var $scrollElement = self.$('.scrollable');

      var scrollFunc = function scrollFunc() {
        return self.scrolled();
      };

      onScroll = function onScroll() {
        Ember.run.debounce(self, scrollFunc, 1);
      };

      $scrollElement.bind('touchmove', onScroll);
      $scrollElement.bind('scroll', onScroll);
      this.set('$scrollElement', $scrollElement);
    },
    unbindScrolling: function unbindScrolling() {
      var $scrollElement = this.get('$scrollElement') || this.$('.scrollable');
      $scrollElement.unbind('scroll');
      $scrollElement.unbind('touchmove');
    }
  });
  var _default = ScrollableMixin;
  _exports.default = _default;
});