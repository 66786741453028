define("coffeecup/templates/planner/absences/scheduler/feed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1BmueRF3",
    "block": "{\"symbols\":[],\"statements\":[[19,\"absences/calendar/feed\",[]]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/planner/absences/scheduler/feed.hbs"
    }
  });

  _exports.default = _default;
});