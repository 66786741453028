define("coffeecup/templates/components/manage-table/manage-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lwV0urFe",
    "block": "{\"symbols\":[\"columnGroup\",\"column\",\"@rowConfig\",\"@rowIndex\",\"@hoverAction\",\"@tableConfig\"],\"statements\":[[6,[37,7],[[32,0,[\"isHidden\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[16,0,[31,[\"table-row \",[32,0,[\"classes\"]]]]],[4,[38,6],[\"click\",[30,[36,5],[[30,[36,4],[[32,3,[\"rowAction\"]]],null],[32,3,[\"model\"]]],null]],null],[4,[38,6],[\"mouseenter\",[30,[36,5],[[32,5],[32,4]],null]],null],[4,[38,6],[\"mouseleave\",[30,[36,5],[[32,5],[32,4],true],null]],null],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,6,[\"columnGroups\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"column-group\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1,[\"columns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,1],[[30,[36,0],[[32,2,[\"componentName\"]],[32,2,[\"componentName\"]],\"manage-table/manage-table-cell\"],null]],[[\"model\",\"modelName\",\"childModels\",\"config\"],[[32,3,[\"model\"]],[32,3,[\"modelName\"]],[32,3,[\"childModels\"]],[32,2]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"component\",\"-track-array\",\"each\",\"optional\",\"fn\",\"on\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/manage-table-row.hbs"
    }
  });

  _exports.default = _default;
});