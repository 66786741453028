define("coffeecup/templates/account/integrations/billing/-client-import-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V2oNHtKH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"modal-wrapper scrollable\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n    \"],[11,\"div\"],[24,0,\"modal-toggler close\"],[4,[38,0],[[32,0],\"onHide\"],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[14,0,\"icon-cross\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"\\n        Close\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"importClientFrom\"],[[\"from\"],[[32,0,[\"service\",\"name\"]]]]]],[2,\"\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-body text-left\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"clientImportText\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"ul\"],[14,0,\"list\"],[12],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"clientImportItem1\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"clientImportItem2\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"li\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"clientImportItem3\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-default pull-left\"],[4,[38,0],[[32,0],\"onHide\"],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"cancel\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-primary pull-right\"],[16,\"disabled\",[34,2]],[24,4,\"submit\"],[4,[38,0],[[32,0],\"importClients\",[35,3,[\"confirmCallback\"]]],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"clientImportBtn\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"submitIsDisabled\",\"model\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/account/integrations/billing/-client-import-info.hbs"
    }
  });

  _exports.default = _default;
});