define("coffeecup/templates/components/fsg-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JfoHbZSj",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],[[35,4]],[[\"item\",\"activeItemId\",\"isDefaultUser\",\"onButtonClick\",\"onSelectItem\",\"criteriaNumber\",\"showCommentType\"],[[32,1],[35,3],[35,2],\"buttonClick\",\"_selectItem\",[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"showCommentType\",\"criteriaNumber\",\"isDefaultUser\",\"activeItemId\",\"itemPartial\",\"component\",\"fsgList\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/fsg-list.hbs"
    }
  });

  _exports.default = _default;
});