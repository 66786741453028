define("coffeecup/templates/analytics/projects/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QbK0zRvU",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,10],null,[[\"openModal\",\"selectedProject\",\"allProjects\",\"fixedBilledAmount\",\"billTimeEntries\",\"from\",\"to\",\"createdInvoice\",\"page\",\"showInvoiceSummary\",\"onHide\",\"completeProject\",\"onBillingSuccess\",\"showTimeEntries\"],[[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0],\"transitionAway\",\"completeProject\",\"handleBillingSuccess\",\"showTimeEntries\"]]]]],\"hasEval\":false,\"upvars\":[\"showInvoiceSummary\",\"page\",\"createdInvoice\",\"to\",\"from\",\"billTimeEntries\",\"fixedBilledAmount\",\"allProjects\",\"selectedProject\",\"openModal\",\"modals/billing-project\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/analytics/projects/billing.hbs"
    }
  });

  _exports.default = _default;
});