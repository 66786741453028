define("coffeecup/services/user-assignments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UserAssignmentsService = Ember.Service.extend({
    store: Ember.inject.service(),
    createUserAssignmentIfNotExists: function createUserAssignmentIfNotExists(user, project) {
      var userAssignments = user.get('userAssignments').filterBy('isDeleted', false);
      var store = this.get('store');

      if (userAssignments) {
        var userAssignment = userAssignments.findBy('project.id', project.get('id'));

        if (userAssignment) {
          return Ember.RSVP.Promise.resolve(userAssignment);
        } else {
          userAssignment = store.createRecord('user-assignment');
          userAssignment.set('user', user);
          userAssignment.set('project', project);
          userAssignment.applyUserDefaults();
          return userAssignment.save();
        }
      }

      return Ember.RSVP.Promise.resolve();
    }
  });
  var _default = UserAssignmentsService;
  _exports.default = _default;
});