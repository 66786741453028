define("coffeecup/routes/planner/index", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // route/planner/index.js
  var _default = _superRoute.default.extend({
    beforeModel: function beforeModel() {
      this.transitionTo('planner.team');
    }
  });

  _exports.default = _default;
});