define("coffeecup/mixins/analytics-transition-router", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    analytics: Ember.inject.service(),
    notifyTransition: Ember.on('didTransition', function () {
      var url = this.get('url');
      var currentRouteName = this.get('currentRouteName');

      if (currentRouteName === 'resetpassword' || currentRouteName === 'welcome') {
        this.get('analytics').event(currentRouteName);
      } else {
        this.get('analytics').notifyTransition(url);
      }
    })
  });

  _exports.default = _default;
});