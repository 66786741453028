define("coffeecup/models/user", ["exports", "ember-data", "coffeecup/config/environment", "coffeecup/models/-super-model", "coffeecup/mixins/image-preview", "coffeecup/mixins/cascade-archivation", "coffeecup/utils/initials", "coffeecup/utils/strip-tags", "coffeecup/utils/allocation-math", "moment", "numeral", "coffeecup/utils/date", "coffeecup/utils/streamed-array-proxy"], function (_exports, _emberData, _environment, _superModel, _imagePreview, _cascadeArchivation, _initials, _stripTags, _allocationMath, _moment, _numeral, _date, _streamedArrayProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //--> Regex checks for decimal time in the format: HH.mm or H.mm or H
  var EXP_DECIMAL_TIME = /((^[0-9]{1}|([0-1][0-9]|[2][0-3]))[.]\d{0,2})|(^24.00)|([01]?[0-9]|2[0-3])/;
  var VALIDATION_HOURSOFWORK = {
    format: {
      //numericality: true,
      with: EXP_DECIMAL_TIME,
      allowBlank: true
    },
    length: {
      // TODO: max 24 hours
      maximum: 5,
      tokenizer: function tokenizer(value) {
        return value ? value.toString().split('') : [];
      }
    }
  };

  var User = _superModel.default.extend(_imagePreview.default, _cascadeArchivation.default, {
    viewport: Ember.inject.service('planner.viewport'),
    workingDaysService: Ember.inject.service('working-days'),

    /**
     * general
     */
    status: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.STATUS_ENABLED
    }),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    emailHmacHash: _emberData.default.attr('string'),
    hourlyRate: _emberData.default.attr('number', {
      defaultValue: 100
    }),
    // TODO read from admin settings
    dateFormat: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.DATE_FORMAT_DDMMYYYY_POINT
    }),
    // eg. 24.12.2015, 12.24.2015, 24-12-2015, etc.
    timeFormat: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.TIME_FORMAT_24
    }),
    // eg. 12 hours or 24 hours
    durationFormat: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.DURATION_FORMAT_HM
    }),
    // eg. decimal or HH:MM
    language: _emberData.default.attr('string'),
    // TODO get language of account on creation
    timezone: _emberData.default.attr('string'),
    // TODO get timezone of account on creation
    beginningOfWeek: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.WEEKDAY_MONDAY
    }),
    // saturday, sunday, monday
    imageType: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.IMG_TYPE_INITIALS
    }),
    passwordStatus: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.PASSWORD_STATUS_NEEDS_RESET
    }),
    favorite: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // user should be automatically added to new projects
    showInPlanner: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    showInAbsencePlanner: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    easybillEnabledAt: _emberData.default.attr('utc-moment'),
    holidayRegion: _emberData.default.attr('string'),
    useDefaultHolidayRegion: _emberData.default.attr('boolean'),
    jobLabel: _emberData.default.attr('string'),
    department: _emberData.default.attr('string'),
    bankName: _emberData.default.attr('string'),
    ibanNumber: _emberData.default.attr('string'),
    bicNumber: _emberData.default.attr('string'),
    insuranceName: _emberData.default.attr('string'),
    insuranceType: _emberData.default.belongsTo('insurance-type'),
    street: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    postCode: _emberData.default.attr('string'),
    country: _emberData.default.attr('string', {
      defaultValue: 'DE'
    }),
    birthplace: _emberData.default.attr('string'),
    birthday: _emberData.default.attr('datestamp'),
    mobileNumber: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    privateEmail: _emberData.default.attr('string'),
    faxNumber: _emberData.default.attr('string'),
    supervisor: _emberData.default.belongsTo('user', {
      async: true,
      inverse: null
    }),
    autoMessageForValidation: _emberData.default.attr('boolean'),
    activeAutoApproval: _emberData.default.attr('boolean'),
    noticePeriod: _emberData.default.attr('notice-period'),
    probationaryPeriod: _emberData.default.attr('datestamp'),

    /**
     * time tracking
     */
    allowConcurrentTimeEntries: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // does the user allow multiple time entries at the same time

    /**
     * absence tracking / user monitoring
     */
    hoursMonday: Ember.computed.deprecatingAlias('effectiveEmployment.hoursMonday', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    hoursTuesday: Ember.computed.deprecatingAlias('effectiveEmployment.hoursTuesday', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    hoursWednesday: Ember.computed.deprecatingAlias('effectiveEmployment.hoursWednesday', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    hoursThursday: Ember.computed.deprecatingAlias('effectiveEmployment.hoursThursday', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    hoursFriday: Ember.computed.deprecatingAlias('effectiveEmployment.hoursFriday', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    hoursSaturday: Ember.computed.deprecatingAlias('effectiveEmployment.hoursSaturday', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    hoursSunday: Ember.computed.deprecatingAlias('effectiveEmployment.hoursSunday', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    hoursTotal: Ember.computed.deprecatingAlias('effectiveEmployment.hoursTotal', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    languageShort: Ember.computed('language', function () {
      return this.get('language').slice(3);
    }),

    /**
     * relations
     */
    role: _emberData.default.belongsTo('role', {
      async: true
    }),
    integrationServices: _emberData.default.hasMany('user-integration', {
      async: false
    }),
    analytics: _emberData.default.belongsTo('analytics/staff', {
      async: false
    }),
    userEmployments: _emberData.default.hasMany('user-employment', {
      async: true,
      cascadeDelete: true,
      cascadeArchivation: true
    }),
    userAssignments: _emberData.default.hasMany('user-assignment', {
      async: true,
      cascadeDelete: true
    }),
    teamAssignments: _emberData.default.hasMany('team-assignment', {
      async: true
    }),
    teamAssignment: Ember.computed.alias('teamAssignments.firstObject'),
    plannerAssignments: function () {
      return _streamedArrayProxy.default.create({
        content: Ember.A()
      });
    }.property(),
    plannerAssignmentsStartDateAsc: ['startDate:asc'],
    plannerAssignmentsSortedByStartDateAsc: Ember.computed.sort('plannerAssignments', 'plannerAssignmentsStartDateAsc'),
    plannerAssignmentsUpdatedAtHash: 0,
    // sum of updatedAt
    plannerAssignmentsLastUpdatedObserver: Ember.observer('plannerAssignments', 'plannerAssignments.@each.updatedAt', function () {
      var plannerAssignments = this.get('plannerAssignments');
      this.set('plannerAssignmentsUpdatedAtHash', plannerAssignments.reduce(function (sum, curr) {
        return curr.get('updatedAt').valueOf() + sum;
      }, 0));
    }),
    projects: function () {
      return _streamedArrayProxy.default.create({
        content: Ember.A()
      });
    }.property(),
    workingHours: [],
    // will be set in controllers/planner
    nonWorkingDays: [],
    timeEntries: _emberData.default.hasMany('time-entry', {
      async: true
    }),
    absences: function () {
      return _streamedArrayProxy.default.create({
        content: Ember.A()
      });
    }.property(),
    absenceRequests: function () {
      return _streamedArrayProxy.default.create({
        content: Ember.A()
      });
    }.property(),

    /**
     * helper properties
     */
    imageDirectoryURL: _emberData.default.attr('string'),

    /**
     * UI properties
     */
    uiState: _emberData.default.attr('ui-state'),
    featureFlags: _emberData.default.attr('feature-flags'),
    featuresUnlocked: _emberData.default.attr('features-unlocked'),
    allTagLabels: function () {
      if (this.get('tagAssignments.length') > 0) {
        return this.get('tagAssignments').getEach('tag.label').join(', ');
      }
    }.property('tagAssignments.[]'),
    // computed properties

    /**
     * * @return {Handlebars.SafeString} a string that will not be html escaped by Handlebars
     */
    fullName: Ember.computed('firstname', 'lastname', 'i18n.locale', {
      get: function get() {
        var components = this._getNameComponents();

        if (typeof components === 'string') {
          return components;
        }

        if (!components.firstname || !components.lastname) {
          return null;
        }

        return Ember.String.htmlSafe('<b>' + components.firstname + ' </b>' + components.lastname);
      }
    }),
    label: Ember.computed('firstname', 'lastname', 'i18n.locale', {
      get: function get() {
        var components = this._getNameComponents();

        if (typeof components === 'string') {
          return components;
        }

        if (!components.firstname || !components.lastname) {
          return null;
        }

        return components.firstname + ' ' + components.lastname;
      }
    }),
    initials: Ember.computed('firstname', 'lastname', {
      get: function get() {
        var firstname = this.get('firstname') || '';
        var lastname = this.get('lastname') || '';
        return (0, _initials.default)(firstname + ' ' + lastname);
      }
    }),
    code: Ember.computed('firstname', 'lastname', {
      get: function get() {
        var firstname = this.get('firstname') || '';
        var lastname = this.get('lastname') || '';
        if (firstname) firstname = firstname.substring(0, 1);
        if (lastname) lastname = lastname.substring(0, 1);
        return firstname + lastname;
      }
    }),

    /**
     *
     * @returns String or Object {firstname: 'foo', lastname: 'bar'}
     * @private
     */
    _getNameComponents: function _getNameComponents() {
      var firstname = (0, _stripTags.default)(this.get('firstname'));
      var lastname = (0, _stripTags.default)(this.get('lastname'));

      if (!firstname && !lastname) {
        if (Ember.isNone(this.get('id'))) {
          return '(' + this.get('i18n').t('newUser') + ')';
        } else {
          return this.get('i18n').t('placeholder.empty');
        }
      }

      if (!firstname) firstname = '';
      if (!lastname) lastname = '';
      return {
        firstname: firstname.trim(),
        lastname: lastname.trim()
      };
    },
    jobLabelAndDepartment: Ember.computed('jobLabel', 'department', 'isFuture', {
      get: function get() {
        var jobLabel = this.get('jobLabel') || '';
        var department = this.get('department') || '';
        var effectiveEmployment = this.get('effectiveEmployment');
        var isFutureUser = this.get('isFuture');

        if (department) {
          jobLabel = (jobLabel && jobLabel + ' - ') + department;
        }

        if (isFutureUser) {
          jobLabel = (jobLabel && jobLabel + ' - ') + this.get('i18n').t('effectiveFrom', {
            date: (0, _moment.default)(effectiveEmployment.get('validFrom')).format('L')
          });
        }

        return jobLabel;
      }
    }),
    effectiveEmployment: Ember.computed('clock.day', 'userEmployments.@each.isNew', 'userEmployments.@each.validFrom', 'userEmployments.@each.employmentType', {
      get: function get() {
        var effectiveEmployment = null;
        var employments = this.get('userEmployments');

        if (employments.get('length') < 1) {
          return null;
        }

        var now = this.get('clock.now');
        employments.forEach(function (employment) {
          if (!employment) return;

          if (employment.get('isDeleted')) {
            return;
          }

          if (employment.get('isNew')) {
            return;
          }

          var validFrom = employment.get('validFrom');

          if (validFrom.isAfter(now)) {
            return;
          }

          if (effectiveEmployment !== null) {
            if (validFrom.isBefore(effectiveEmployment.get('validFrom'))) {
              return;
            }
          }

          effectiveEmployment = employment;
        }); // HOTFIX for future users to being displayed correctly in the list view
        // This doesn't allow the users to login yet

        if (effectiveEmployment === null && employments.get('length') > 0) {
          employments.forEach(function (employment) {
            if (!employment) return;

            if (employment.get('isDeleted')) {
              return;
            }

            if (employment.get('isNew')) {
              return;
            }

            var validFrom = employment.get('validFrom');

            if (validFrom.isAfter(now)) {
              effectiveEmployment = employment;
            }
          });
        }

        return effectiveEmployment;
      }
    }),
    employmentEntriesSortedByDate: Ember.computed.sort('userEmployments.@each.validFrom', function (ue1, ue2) {
      if (ue1 && ue2) {
        var vf1 = (0, _moment.default)(ue1.get('validFrom'));
        var vf2 = (0, _moment.default)(ue2.get('validFrom'));
        var diff = vf1.diff(vf2, 'days');

        if (diff > 0) {
          return 1;
        } else if (diff < 0) {
          return -1;
        } else {
          return 0;
        }
      }
    }),
    firstEmploymentEntry: Ember.computed.alias('employmentEntriesSortedByDate.firstObject'),
    team: Ember.computed.alias('teamAssignment.team'),
    employmentType: Ember.computed.deprecatingAlias('effectiveEmployment.employmentType', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    workHoursType: Ember.computed.deprecatingAlias('effectiveEmployment.workHoursType', {
      id: 'cc.user.employment',
      until: '2.0.0'
    }),
    isPasswordPending: Ember.computed('passwordStatus', {
      get: function get() {
        return this.get('passwordStatus') == _environment.default.constants.PASSWORD_STATUS_NEEDS_RESET || this.get('passwordStatus') == _environment.default.constants.PASSWORD_STATUS_NEEDS_SET;
      }
    }),
    isInactive: Ember.computed.not('isActive'),
    isArchived: Ember.computed.not('isActive'),
    isActive: Ember.computed('status', {
      get: function get() {
        return this.get('status') === _environment.default.constants.STATUS_ENABLED;
      }
    }),
    isUser: Ember.computed('role', {
      get: function get() {
        return this.get('role.name') == _environment.default.constants.ROLE_DEFAULT;
      }
    }),
    isProjectManager: Ember.computed('role', {
      get: function get() {
        return this.get('role.name') == _environment.default.constants.ROLE_PM;
      }
    }),
    isAdmin: Ember.computed('role', {
      get: function get() {
        return this.get('role.name') == _environment.default.constants.ROLE_ADMIN;
      }
    }),
    isFreelancer: Ember.computed('clock.day', 'effectiveEmployment.employmentType', 'effectiveEmployment.validFrom', {
      get: function get() {
        var type = this.get('effectiveEmployment.employmentType');
        return [_environment.default.constants.USER_TYPE_FREELANCER, _environment.default.constants.USER_TYPE_FREELANCER_HOURLY].includes(type);
      }
    }),
    isEmployee: Ember.computed('clock.day', 'effectiveEmployment.employmentType', 'effectiveEmployment.validFrom', {
      get: function get() {
        var type = this.get('effectiveEmployment.employmentType');
        return [_environment.default.constants.USER_TYPE_EMPLOYEE, _environment.default.constants.USER_TYPE_EMPLOYEE_HOURLY].includes(type);
      }
    }),
    isFormer: Ember.computed('clock.day', 'effectiveEmployment.employmentType', 'effectiveEmployment.validFrom', {
      get: function get() {
        var type = this.get('hasFixedHourlyRate.employmentType');
        return [_environment.default.constants.USER_TYPE_FORMER].includes(type);
      }
    }),
    hasFixedHourlyCosts: Ember.computed.alias('effectiveEmployment.hasFixedHourlyCosts'),
    isFuture: Ember.computed('clock.day', 'effectiveEmployment.employmentType', 'effectiveEmployment.validFrom', {
      get: function get() {
        var now = this.get('clock.now');
        var effectiveEmployment = this.get('effectiveEmployment');

        if (effectiveEmployment) {
          var validFrom = effectiveEmployment.get('validFrom');
          return validFrom.isAfter(now);
        }
      }
    }),
    hourlyRateFmt: Ember.computed('hourlyRate', {
      get: function get() {
        var hourlyRate = this.get('hourlyRate') || 0; // return numeral(hourlyRate).format('$ 0,0.00');

        return (0, _numeral.default)(hourlyRate).format('$ 0.00');
      }
    }),

    /**
     * @return {boolean} true if user must NOT fulfill a certain amount of daily hours
     */
    hasFlexibleHours: Ember.computed.equal('effectiveEmployment.workHoursType', _environment.default.constants.WORK_HOURS_FLEXIBLE),

    /**
     * @return {boolean} true if user must  fulfill a certain amount of daily hours
     */
    hasFixedDay: Ember.computed.equal('effectiveEmployment.workHoursType', _environment.default.constants.WORK_HOURS_FIXED_DAY),

    /**
     * @return {boolean} true if user must  fulfill a certain amount of weekly hours
     */
    hasFixedWeek: Ember.computed.equal('effectiveEmployment.workHoursType', _environment.default.constants.WORK_HOURS_FIXED_WEEK),

    /**
     * @return {boolean} true if user has to fulfill a certain amount of daily hours
     */
    hasStrictWorkType: Ember.computed.not('effectiveEmployment.workHoursType', _environment.default.constants.WORK_HOURS_FLEXIBLE),
    hasBirthday: Ember.computed('birthday', function () {
      return this.get('birthday') && (0, _moment.default)().format('MM-DD') === this.get('birthday').format('MM-DD');
    }),

    /**
     * @return {String} url to image
     */
    image: Ember.computed('email', 'imageType', 'imageDirectoryURL', {
      get: function get() {
        var id = this.get('imageDirectoryURL');
        var email = this.get('email');
        var emailIsValid = !this.get('errors.email.firstObject');
        return this._image(id, 'm', email, emailIsValid);
      }
    }),
    imageSmall: Ember.computed('email', 'imageType', 'imageDirectoryURL', {
      get: function get() {
        var id = this.get('imageDirectoryURL');
        var email = this.get('email');
        var emailIsValid = !this.get('errors.email.firstObject');
        return this._image(id, 's', email, emailIsValid);
      }
    }),
    imageLarge: Ember.computed('email', 'imageType', 'imageDirectoryURL', {
      get: function get() {
        var id = this.get('imageDirectoryURL');
        var email = this.get('email');
        var emailIsValid = !this.get('errors.email.firstObject');
        return this._image(id, 'l', email, emailIsValid, 'jpg');
      }
    }),

    /**
     * return SafeString img
     */
    imageSmallHTML: Ember.computed('imageSmall', {
      get: function get() {
        var imageSmall = this.get('imageSmall');
        var isImageInitials = this.get('isImageInitials');

        if (isImageInitials) {
          return Ember.String.htmlSafe('<span class="initials-avatar small noselect ' + this.get('color') + '">' + this.get('initials') + '</span>');
        }

        return Ember.String.htmlSafe('<img src="' + imageSmall + '">');
      }
    }),

    /**
     * return SafeString img
     */
    imageExtraSmallHTML: Ember.computed('imageSmall', {
      get: function get() {
        var imageSmall = this.get('imageSmall');
        var isImageInitials = this.get('isImageInitials');

        if (isImageInitials) {
          return Ember.String.htmlSafe('<span class="initials-avatar extra-small noselect ' + this.get('color') + '">' + this.get('initials') + '</span>');
        }

        return Ember.String.htmlSafe('<img src="' + imageSmall + '">');
      }
    }),

    /**
     * return SafeString img with label
     */
    labelWithImageSmallHTML: Ember.computed('label', 'imageSmallHTML', {
      get: function get() {
        var label = (0, _stripTags.default)(this.get('label'));
        return Ember.String.htmlSafe(this.get('imageSmallHTML') + label);
      }
    }),
    activeUserAssignments: Ember.computed.filter('userAssignments', function (userAssignment) {
      return userAssignment && userAssignment.get('status') === _environment.default.constants.STATUS_ENABLED && !userAssignment.get('project.isInactive');
    }),

    /**
     * @param {Integer} isoWeekDay the specific day as index
     * @return {Integer} the amount of of required hours for a specific day
     */
    getHoursByDay: function getHoursByDay(isoWeekDay) {
      if (!this.get('effectiveEmployment')) return 0;
      return this.get('effectiveEmployment').getHoursByDay(isoWeekDay);
    },
    allocatedDays: function allocatedDays(startDate, endDate) {
      var viewport = this.get('viewport');
      var numberOfDaysBetween = viewport.numberOfDaysBetween.bind(viewport);
      var allocatedDaysArray = Ember.A();
      var workingHoursArray = this.get('workingHours') || [];
      workingHoursArray.forEach(function (workingHours) {
        if ((0, _date.isInRange)(workingHours.date, startDate, endDate)) {
          allocatedDaysArray.pushObject({
            date: workingHours.date,
            expectedHours: workingHours.expectedHours,
            originalExpectedHours: workingHours.originalExpectedHours,
            isWorking: workingHours.isWorking,
            isAbsent: workingHours.isAbsent,
            isOff: workingHours.isOff,
            absenceType: workingHours.absenceType,
            absenceRangeType: workingHours.absenceRangeType,
            absenceComment: workingHours.absenceComment,
            absenceLength: workingHours.absenceLength,
            isAbsenceRequest: workingHours.isAbsenceRequest,
            allocation: 0,
            activeOnDaysOff: false
          });
        }
      });

      if (allocatedDaysArray && allocatedDaysArray[0] && startDate.isBefore(allocatedDaysArray[0].date)) {
        startDate = (0, _moment.default)(allocatedDaysArray[0].date);
      }

      var len = allocatedDaysArray.length;
      var plannerAssignments = this.get('plannerAssignmentsSortedByStartDateAsc');
      plannerAssignments.filter(function (item) {
        return item.get('isActive') && item.inRange(startDate, endDate);
      }).forEach(function (assignment) {
        var assignmentStartDate = assignment.get('startDate');
        var assignmentEndDate = assignment.get('endDate');
        var days = numberOfDaysBetween(startDate, assignmentStartDate);
        var sum = days + (numberOfDaysBetween(assignmentStartDate, assignmentEndDate) + 1);
        if (days < 0) days = 0;
        if (sum > len) sum = len;

        for (var i = days; i < sum; ++i) {
          var allocatedDay = allocatedDaysArray[i];

          if (!assignment.isActiveOnDate(allocatedDay.date)) {
            continue;
          }

          allocatedDay.activeOnDaysOff = allocatedDay.activeOnDaysOff || assignment.get('activeOnDaysOff');

          if (allocatedDay.isWorking === true || allocatedDay.activeOnDaysOff) {
            var assignmentAllocation = assignment.get('allocation');

            if (assignment.get('isWeeklyPlanning')) {
              var allocationRatio = allocatedDay.expectedHours / allocatedDay.originalExpectedHours; // If user has zero expected hours, but is working on the day, this can NaN. This is the use case for a user with
              // 0 weekly working hours. Set the ratio to 1 in this case

              if (isNaN(allocationRatio)) {
                allocationRatio = 1;
              }

              assignmentAllocation *= allocationRatio;
            }

            allocatedDay.allocation = _allocationMath.default.addAllocation(allocatedDay.allocation, assignmentAllocation);
          }
        }
      });
      return allocatedDaysArray;
    },
    color: Ember.computed('id', function () {
      var colorOptions = ['preset-teal-3-bg', 'preset-teal-1-bg', 'preset-green-3-bg', 'preset-green-4-bg', 'preset-green-2-bg', 'preset-fuchsia-4-bg', 'preset-fuchsia-3-bg', 'preset-fuchsia-2-bg', 'preset-purple-4-bg', 'preset-purple-3-bg', 'preset-orange-3-bg', 'preset-orange-4-bg', 'preset-orange-1-bg', 'preset-brown-2-bg', 'preset-brown-3-bg', 'preset-dark-blue-3-bg', 'preset-dark-blue-1-bg', 'preset-purple-1-bg', 'preset-bright-blue-1-bg', 'preset-teal-2-bg', 'preset-gray-5-bg', 'preset-gray-4-bg', 'preset-gray-3-bg', 'preset-gray-2-bg', 'preset-gray-1-bg'];
      return colorOptions[this.get('id') % colorOptions.length];
    }),
    fontColor: Ember.computed('id', function () {
      var colorOptions = ['preset-teal-3-font', 'preset-teal-1-font', 'preset-green-3-font', 'preset-green-4-font', 'preset-green-2-font', 'preset-fuchsia-4-font', 'preset-fuchsia-3-font', 'preset-fuchsia-2-font', 'preset-purple-4-font', 'preset-purple-3-font', 'preset-orange-3-font', 'preset-orange-4-font', 'preset-orange-1-font', 'preset-brown-2-font', 'preset-brown-3-font', 'preset-dark-blue-3-font', 'preset-dark-blue-1-font', 'preset-purple-1-font', 'preset-bright-blue-1-font', 'preset-teal-2-font', 'preset-gray-5-font', 'preset-gray-4-font', 'preset-gray-3-font', 'preset-gray-2-font', 'preset-gray-1-font'];
      return colorOptions[this.get('id') % colorOptions.length];
    }),
    clone: function clone() {
      return this.getProperties(['status', 'firstname', 'lastname', 'email', 'hourlyRate', 'dateFormat', 'timeFormat', 'timeDisplay', 'language', 'timezone', 'beginningOfWeek', 'showHours', 'uiState', 'featureFlags', 'role']);
    }
  });

  var _default = User;
  _exports.default = _default;
});