define("coffeecup/templates/components/loading-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0oF/4mlf",
    "block": "{\"symbols\":[\"@isChartLoading\"],\"statements\":[[10,\"div\"],[14,0,\"loading-container\"],[15,5,[30,[36,1],[[32,1],\"height: unset\"],null]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"loading-pulse\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"indicator-label\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/loading-indicator.hbs"
    }
  });

  _exports.default = _default;
});