define("coffeecup/models/billing/token", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    last4: _emberData.default.attr('string'),
    expMonth: _emberData.default.attr('number'),
    expYear: _emberData.default.attr('number'),
    date: Ember.computed('expMonth', 'expYear', function () {
      return "".concat(this.get('expMonth'), "/").concat(this.get('expYear'));
    })
  });

  _exports.default = _default;
});