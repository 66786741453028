define("coffeecup/validations/task", ["exports", "ember-changeset-validations/validators", "coffeecup/validators/validateUnique"], function (_exports, _validators, _validateUnique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      label: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      code: {
        presence: true,
        unique: true,
        length: {
          maximum: 20
        }
      },
      hourlyRate: {
        presence: true
      }
    },
    label: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    code: [(0, _validators.validatePresence)(true), (0, _validateUnique.default)(), (0, _validators.validateLength)({
      max: 20
    })],
    hourlyRate: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      lte: 999999
    })]
  };
  _exports.default = _default;
});