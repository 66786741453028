define("coffeecup/mixins/image-preview", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isImageUpload: Ember.computed('imageType', function () {
      return this.get('imageType') === _environment.default.constants.IMG_TYPE_UPLOAD;
    }),
    isImageGravatar: Ember.computed('imageType', function () {
      return this.get('imageType') === _environment.default.constants.IMG_TYPE_GRAVATAR;
    }),
    isImageInitials: Ember.computed('imageType', function () {
      return this.get('imageType') === _environment.default.constants.IMG_TYPE_INITIALS;
    }),
    _image: function _image(id, size, email, emailIsValid, format) {
      format = format || 'png';
      var gravatarUrl = 'https://www.gravatar.com/avatar/';
      var gravatarSize = {
        l: 158,
        m: 112,
        s: 48
      };

      if (this.get('isImageGravatar') && email && emailIsValid) {
        return gravatarUrl + md5(email) + '?s=' + gravatarSize[size];
      }

      if (this.get('isImageUpload')) {
        return id && id + size + '.' + format;
      }

      return gravatarUrl + md5('info@coffeecupapp.com') + '?s=' + gravatarSize[size];
    }
  });

  _exports.default = _default;
});