define("coffeecup/mixins/planner/filter-terms", ["exports", "coffeecup/utils/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    queryParams: {
      filterTerm: {
        scope: 'controller',
        as: 'filter'
      }
    },
    selectedTags: [],
    filteredTagAssignments: [],
    filterTerms: [],
    tagAssignmentFits: function tagAssignmentFits(tagAssignment, model, modelName) {
      return tagAssignment.get('record') === model.get('idAsInt') && tagAssignment.get('model') === modelName;
    },
    filterTerm: null,
    actions: {
      selectTag: function selectTag() {
        var _this = this;

        var tagAssignments = this.get('tagAssignments').filter(function (tagAssignment) {
          return _this.get('selectedTags').any(function (tag) {
            return tag && tag.get('id') === tagAssignment.get('tag.id');
          });
        });
        var filterTerms = this.get('existingTags').filter(function (el) {
          if (!el) return false;
          if (!el.get('isFilterTerm')) return false;

          var isSelected = _this.get('selectedTags').any(function (tag) {
            return tag.get('id') === el.get('id').toString();
          });

          if (isSelected) {
            return true;
          } else {
            return false;
          }
        });
        var selectedTags = this.get('selectedTags');
        this.set('filterTerms', filterTerms);
        this.set('filterTerm', selectedTags.get('length') > 0 ? selectedTags.getEach('label').join(',') : null);
        this.set('filteredTagAssignments', tagAssignments);
      },
      createInlineTag: function createInlineTag(tag) {
        var purified = tag.replace(',', '');
        tag = Ember.Object.extend({
          id: purified,
          label: purified,
          isFilterTerm: true
        }).create();
        var existingTags = this.get('existingTags').toArray();
        var selectedTags = this.get('selectedTags').toArray();
        existingTags.pushObject(tag);
        selectedTags.pushObject(tag);
        var filterTerms = this.get('existingTags').filter(function (el) {
          return el.get('isFilterTerm');
        });
        this.set('filterTerms', filterTerms);
        this.set('existingTags', existingTags);
        this.set('selectedTags', selectedTags);
        this.send('selectTag');
      },
      canCreateFilterTag: function canCreateFilterTag(term) {
        var existingOption = this.get('existingTags').find(function (tag) {
          return (0, _string.purify)(tag.get('label')) === (0, _string.purify)(term);
        });
        return !existingOption;
      }
    }
  });

  _exports.default = _default;
});