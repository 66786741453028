define("coffeecup/utils/attachment-upload", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkForFileSizeError = _exports.attachmentUpload = _exports.attachmentUploadFile = _exports.ATTACHMENT_MODEL_USER_IMAGE = _exports.ATTACHMENT_MODEL_CLIENT_IMAGE = _exports.ATTACHMENT_MODEL_DOCUMENT = _exports.ATTACHMENT_MODEL_EXPENSE = void 0;
  var ATTACHMENT_MODEL_EXPENSE = 'expense';
  _exports.ATTACHMENT_MODEL_EXPENSE = ATTACHMENT_MODEL_EXPENSE;
  var ATTACHMENT_MODEL_DOCUMENT = 'document';
  _exports.ATTACHMENT_MODEL_DOCUMENT = ATTACHMENT_MODEL_DOCUMENT;
  var ATTACHMENT_MODEL_CLIENT_IMAGE = 'client_image';
  _exports.ATTACHMENT_MODEL_CLIENT_IMAGE = ATTACHMENT_MODEL_CLIENT_IMAGE;
  var ATTACHMENT_MODEL_USER_IMAGE = 'user_image';
  _exports.ATTACHMENT_MODEL_USER_IMAGE = ATTACHMENT_MODEL_USER_IMAGE;

  var prepareUrl = function prepareUrl(modelKey, id) {
    var uploadPath = '';

    switch (modelKey) {
      case ATTACHMENT_MODEL_EXPENSE:
        uploadPath = "/v1/files/upload/".concat(modelKey, "/").concat(id, "?delete_others=1");
        break;

      case ATTACHMENT_MODEL_USER_IMAGE:
      case ATTACHMENT_MODEL_CLIENT_IMAGE:
        uploadPath = "/v1/images/upload/".concat(modelKey, "/").concat(id, "?delete_others=1");
        break;

      case ATTACHMENT_MODEL_DOCUMENT:
        uploadPath = "/v1/files/upload/".concat(modelKey, "/").concat(id, "?delete_others=1");
        break;
    }

    if (uploadPath === '') {
      throw new Error("\"".concat(modelKey, "\" is not a registered modelKey in attachmentUpload."));
    }

    return uploadPath;
  };
  /**
   * Send a ember upload file to the server.
   *
   * @param Blob file - The file info
   * @param String modelKey - the model name
   * @param Int id - the model id
   * @param String accessToken - a authorization token
   * @return RSVP.Promise - Resolves when the upload is complete
   * @throws Error if modelKey is not registered as constant
   */


  var attachmentUploadFile = function attachmentUploadFile(file, modelKey, id, accessToken) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var uploadPath = prepareUrl(modelKey, id);
      var fileSizeError = checkForFileSizeError(file);

      if (fileSizeError) {
        reject(fileSizeError);
      }

      var url = _environment.default.apiHost + uploadPath;
      file.upload(url, {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      }).then(function (res) {
        console.log(res);
        resolve(res.body);
      }).catch(reject);
    });
  };
  /**
   * Send a moxie file to the server.
   *
   * @param Blob file - The file info
   * @param String modelKey - the model name
   * @param Int id - the model id
   * @param String accessToken - a authorization token
   * @return RSVP.Promise - Resolves when the upload is complete
   * @throws Error if modelKey is not registered as constant
   */


  _exports.attachmentUploadFile = attachmentUploadFile;

  var attachmentUpload = function attachmentUpload(file, modelKey, id, accessToken) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      var uploadPath = prepareUrl(modelKey, id);
      var fileSizeError = checkForFileSizeError(file);

      if (fileSizeError) {
        reject(fileSizeError);
      }

      var url = _environment.default.apiHost + uploadPath;
      var xhr = new mOxie.XMLHttpRequest();
      xhr.open('POST', url, true);

      if (accessToken) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
      }

      xhr.bind('load', function () {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(xhr.responseText);
        }
      });
      var form = new mOxie.FormData();
      form.append('upload', file); // file is a moxie.File from a FileInput

      xhr.send(form);
    });
  };

  _exports.attachmentUpload = attachmentUpload;

  var checkForFileSizeError = function checkForFileSizeError(file) {
    var maxFileSizeIsMB = _environment.default.constants.ATTACHMENT_MAX_FILE_SIZE_IN_MB;

    if (file.size > maxFileSizeIsMB * 1024 * 1024) {
      return {
        message: "File is to large ony ".concat(maxFileSizeIsMB, "MB is allowed"),
        i18nKey: 'fileToLarge',
        i18nData: maxFileSizeIsMB
      };
    }

    return null;
  };

  _exports.checkForFileSizeError = checkForFileSizeError;
});