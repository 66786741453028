define("coffeecup/templates/components/planner/project-timeline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9CTy6cBq",
    "block": "{\"symbols\":[\"frame\",\"milestone\"],\"statements\":[[10,\"div\"],[14,0,\"row-timeline\"],[12],[2,\"\\n\"],[6,[37,10],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,9],null,[[\"class\",\"onClick\",\"blockedTimeframes\",\"projectColor\"],[\"milestone add-milestone\",[30,[36,6],[[32,0],\"createMilestone\"],null],[35,0,[\"milestones\"]],[35,0,[\"color\",\"darker30\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,0,[\"milestones\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,8],null,[[\"milestone\",\"editable\",\"projectColor\",\"useProjectColor\",\"onClick\",\"onMoved\"],[[32,2],[35,7],[35,0,[\"color\",\"darker30\"]],true,[30,[36,6],[[32,0],\"editMilestone\"],null],[30,[36,6],[[32,0],\"updateMilestone\"],null]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"project-grid\"],[12],[2,\"\\n\"],[6,[37,10],[[35,11]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],null,[[\"project\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,10],[[35,12]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],null,[[\"project\",\"startDate\",\"endDate\"],[[35,0],[32,1,[\"startDate\"]],[32,1,[\"endDate\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"project\",\"planner/project-assigned\",\"frames\",\"-track-array\",\"each\",\"planner/project-span\",\"action\",\"editable\",\"planner/milestone-frame\",\"planner/milestone-overlay\",\"if\",\"showPlannerProjectDuration\",\"isSetup\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/project-timeline.hbs"
    }
  });

  _exports.default = _default;
});