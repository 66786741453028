define("coffeecup/templates/components/validation/footer-summary-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0eI38dmg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"flex-1 d-flex justify-content-center validation-check-element\"],[12],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"validation-check-icon space-right flex-center\\n        \",[30,[36,4],[[35,6],\"icomoon-checkmark success\",\"icomoon-cross-exit danger\"],null]]]],[12],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"space-right\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,9],[[35,6],[35,5]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[34,5]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n      \"],[1,[30,[36,2],[\"of\"],null]],[2,\"\\n      \"],[1,[34,3]],[2,\"\\n      \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n      \"],[1,[34,1]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"label\",\"t\",\"expectedValue\",\"if\",\"validLabel\",\"isValid\",\"hideIcon\",\"unless\",\"and\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/validation/footer-summary-element.hbs"
    }
  });

  _exports.default = _default;
});