define("coffeecup/templates/components/input/input-clearable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vy1zRtUF",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"span\"],[24,0,\"icon icomoon-cross22 form-control-button\"],[24,\"aria-hidden\",\"true\"],[4,[38,3],[[32,0],\"clearValue\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[31,[[34,0]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,[30,[36,7],null,[[\"class\",\"value\",\"placeholder\"],[\"form-control\",[35,6],[35,5]]]]]],\"hasEval\":false,\"upvars\":[\"iconCss\",\"icon\",\"if\",\"action\",\"showClear\",\"placeholder\",\"value\",\"input\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/input-clearable.hbs"
    }
  });

  _exports.default = _default;
});