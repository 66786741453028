define("coffeecup/routes/manage", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ManageRoute = _superRoute.default.extend();

  var _default = ManageRoute;
  _exports.default = _default;
});