define("coffeecup/templates/components/time-entry/background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZZsTKP4E",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"type-indicator\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"icomoon-pause2\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"img\"],[14,\"draggable\",\"false\"],[14,5,\"height: 100%; width: 100%; opacity: 0.2\"],[14,\"src\",\"/assets/images/estimate-grid.svg\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"isBreak\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/time-entry/background.hbs"
    }
  });

  _exports.default = _default;
});