define("coffeecup/templates/components/analytics-module/analytics/total-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o5C5UbmE",
    "block": "{\"symbols\":[\"@analytics\"],\"statements\":[[8,\"analytics-module/kpi/kpi-cell\",[],[[\"@amount\",\"@label\",\"@hasData\"],[[30,[36,0],[[32,1,[\"hoursSpent\"]],false,true,true],null],[30,[36,1],[\"totalBillableTime\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"kpi-tooltip\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"billableWorkTime\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"hoursBillable\"]],false,true],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"billableTimeSurplus\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"isBillableSurplusPositive\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          +\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[1,[30,[36,0],[[32,1,[\"billableSurplus\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row summary-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"totalBillableTime\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[32,1,[\"hoursSpent\"]],false,true],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hours-fmt\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/analytics/total-hours.hbs"
    }
  });

  _exports.default = _default;
});