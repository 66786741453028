define("coffeecup/templates/components/account/billing/plan-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UJTTmh3d",
    "block": "{\"symbols\":[\"benefit\",\"@plan\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"option-card plan-card \",[30,[36,1],[[32,0,[\"isSelected\"]],\"selected\"],null]]]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[32,0,[\"onClick\"]]],null],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"plan-name\"],[12],[2,\"\\n    \"],[1,[32,0,[\"planTranslations\",\"name\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isActive\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"current-plan-indicator\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"currentPlan\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"plan-price\"],[12],[2,\"\\n    \"],[1,[32,2,[\"monthlyPriceFmt\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"plan-price-description\"],[12],[2,\"\\n    \"],[1,[32,0,[\"planTranslations\",\"priceDescription\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"plan-benefits\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"planTranslations\",\"benefits\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"plan-benefit\"],[12],[2,\"\\n\"],[2,\"        \"],[10,\"div\"],[14,0,\"icon icomoon-success-check\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"benefit-text\"],[12],[2,\"\\n          \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"on\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/account/billing/plan-card.hbs"
    }
  });

  _exports.default = _default;
});