define("coffeecup/services/project", ["exports", "coffeecup/config/environment", "coffeecup/mixins/create-short-tag"], function (_exports, _environment, _createShortTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var CODE_MAX_LENGTH = 18;
  var PROJECT_NAME_MAX_LENGTH = 128;

  var _default = Ember.Service.extend(_createShortTag.default, {
    clock: Ember.inject.service(),
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    permissions: Ember.inject.service(),
    taskService: Ember.inject.service('task'),
    projectsWhereUserIsPmGrouped: function projectsWhereUserIsPmGrouped(projects, currentUser, allowAllFunction) {
      var _this = this;

      if (this.permissions.isRootAdmin || this.permissions.canManageAllProjects || (allowAllFunction === null || allowAllFunction === void 0 ? void 0 : allowAllFunction())) {
        return projects;
      } else {
        var filteredGroups = _toConsumableArray(projects).filter(function (originalGroup) {
          var projectGroup = Ember.Object.create(originalGroup);
          projectGroup.set('options', projectGroup.options.filter(function (project) {
            return _this.userIsAdminOrPmOfProject(project, currentUser);
          }));
          return projectGroup;
        });

        return filteredGroups.filter(function (projectGroup) {
          return projectGroup.options.length > 0;
        });
      }
    },
    projectsWhereUserIsPm: function projectsWhereUserIsPm(projects, currentUser) {
      var currentUserIsAdmin = currentUser.get('isAdmin');
      var canManageAllProjects = currentUser.get('role.canManageAllProjects');
      var currentUserId = currentUser.get('id');

      if (!projects || typeof projects.filter === 'undefined') {
        return [];
      }

      return projects.filter(function (project) {
        var userAssignments = project && project.get('userAssignments');

        if (currentUserIsAdmin || canManageAllProjects) {
          return true;
        }

        if (!userAssignments || userAssignments.get('length') < 1) {
          return false;
        }

        var assignmentCheck = function assignmentCheck(userAssignment) {
          return userAssignment && userAssignment.get('user.id') === currentUserId && userAssignment.get('isProjectManager');
        };

        return userAssignments.any(assignmentCheck);
      });
    },
    getFirstProjectByLabel: function getFirstProjectByLabel() {
      return this.get('store').peekAll('project').filterBy('isActive').sortBy('label').get('firstObject');
    },
    userIsAdminOrPmOfAnyProject: function userIsAdminOrPmOfAnyProject(projects, user) {
      var projectsWhereUserIsPm = this.projectsWhereUserIsPm(projects, user);
      return projectsWhereUserIsPm && projectsWhereUserIsPm.get('length') > 0;
    },
    userIsAdminOrPmOfProject: function userIsAdminOrPmOfProject(project, user) {
      return this.userIsAdminOrPmOfAnyProject([project], user);
    },
    projectsGroupedByClient: function projectsGroupedByClient(projects) {
      var alreadySorted = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var sortedProjects = projects;

      if (!alreadySorted) {
        // these are internal fields to leverage ember's build-in sorting functionality for projectsGroupedByClient
        var projectSortObject = Ember.Object.extend({
          _projects: [],
          projectSortKeys: _environment.default.constants.PROJECT_SORT_KEYS,
          _sortedProjects: Ember.computed.sort('_projects', 'projectSortKeys')
        }).create();
        projectSortObject.set('_projects', projects);
        sortedProjects = projectSortObject.get('_sortedProjects');
        projectSortObject.destroy();
      }

      var result = [];
      var found = false;
      var clientName;
      sortedProjects.forEach(function (project) {
        clientName = project.get('clientName');
        clientName = typeof clientName.toString === 'function' ? clientName.toString() : clientName;
        found = result.findBy('groupName', clientName);

        if (!found) {
          result.pushObject(Ember.Object.create({
            groupName: clientName,
            options: []
          }));
        }

        result.findBy('groupName', clientName).get('options').pushObject(project);
      });
      return result;
    },
    projectsGroupedByClientAndInactive: function projectsGroupedByClientAndInactive(projects) {
      var projectSortObject = Ember.Object.extend({
        _projects: [],
        projectSortKeys: _environment.default.constants.PROJECT_SORT_KEYS,
        _sortedProjects: Ember.computed.sort('_projects', 'projectSortKeys')
      }).create();
      projectSortObject.set('_projects', projects);
      var content = projectSortObject.get('_sortedProjects');
      projectSortObject.destroy();
      var archivedProjects = [];
      var projectsByClientName = {};
      content.forEach(function (project) {
        if (project.get('isArchived')) {
          archivedProjects.push(project);
        } else {
          var clientName = project.get('clientName');
          var clientNameString = typeof clientName.toString === 'function' ? clientName.toString() : clientName;

          if (!projectsByClientName[clientNameString]) {
            projectsByClientName[clientNameString] = [project];
          } else {
            projectsByClientName[clientNameString].push(project);
          }
        }
      });
      var result = Object.entries(projectsByClientName).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            clientName = _ref2[0],
            projects = _ref2[1];

        return Ember.Object.create({
          groupName: clientName,
          options: projects.sortBy('name')
        });
      });

      if (archivedProjects.length > 0) {
        var archivedElements = Ember.Object.create({
          groupName: this.get('i18n').t('archivedProjects'),
          options: archivedProjects.sortBy('name')
        });
        result.push(archivedElements);
      }

      return result;
    },
    uncompleteProject: function uncompleteProject(projectId) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var project = _this2.get('store').peekRecord('project', projectId);

        project.set('completedAt', null);
        project.set('status', _environment.default.constants.STATUS_ENABLED);
        project.save().then(function () {
          resolve();
        }).catch(function (err) {
          reject(err);
        });
      });
    },
    getProjectAndAnalytics: function getProjectAndAnalytics(projectId) {
      var interval = 'month';
      var histogramType = 'total';
      var selectedProject = this.get('store').peekRecord('project', projectId);
      var selectedProjectAnalytics = this.get('store').query('analytics/project', {
        project: projectId,
        histogramInterval: interval,
        histogramType: histogramType
      });
      return Ember.RSVP.hash({
        selectedProject: selectedProject,
        selectedProjectAnalytics: selectedProjectAnalytics
      });
    },
    createDefaultProject: function createDefaultProject(clientId) {
      var allProjects = this.get('store').peekAll('project');
      var allClients = this.get('store').peekAll('client');
      var project = this.get('store').createRecord('project');
      var colors = this.get('store').peekAll('color'); // have been loaded into store in application.js route
      // set random color

      project.set('color', colors.objectAt(Math.floor(Math.random() * colors.get('length'))));
      var projectState = this.get('store').peekAll('project-state').filterBy('isDefault').get('firstObject');
      project.set('projectState', projectState);
      var projectName = this.get('i18n').t('newProject').toString();
      project.set('name', projectName);
      project.set('startDate', this.get('clock.now'));
      var codeAppendix = 1;
      var latestProjectWithClient = allProjects.filterBy('isInternal', false).filterBy('isActive', true).sortBy('idAsInt').reverse().slice(0, 1);

      if (clientId) {
        project.set('client', this.get('store').peekRecord('client', clientId));
      } else if (latestProjectWithClient && latestProjectWithClient.get('length') > 0) {
        project.set('client', latestProjectWithClient.get('firstObject.client'));
      } else {
        if (allClients && allClients.get('length') > 0) {
          var client = allClients.filterBy('isActive', true).sortBy('name').slice(0, 1);
          project.set('client', client.get('firstObject'));
        }
      }

      var clientCode = project.get('client.code');
      var projectCode = this.createShortTag(projectName, CODE_MAX_LENGTH, allProjects.getEach('code'), codeAppendix, clientCode);
      project.set('code', projectCode);
      project.setupTrackerMetaData();
      return project.save();
    },
    createTaskAssignments: function createTaskAssignments(project, tasks) {
      var _this3 = this;

      var taskAssignments = []; // assign favorite tasks

      tasks = tasks.filter(function (task) {
        return task.get('favorite') === true && task.get('status') === _environment.default.constants.STATUS_ENABLED;
      });

      if (tasks.length > 1) {
        tasks.forEach(function (task) {
          var taskAssignment = _this3.get('store').createRecord('task-assignment');

          taskAssignment.set('task', task);
          taskAssignment.set('project', project);
          taskAssignment.applyTaskDefaults();
          taskAssignments.push(taskAssignment.save());
        });
        return Ember.RSVP.all(taskAssignments);
      } else {
        return this.get('taskService').createDefaultTask().then(function (task) {
          var taskAssignment = _this3.get('store').createRecord('task-assignment');

          taskAssignment.set('task', task);
          taskAssignment.set('project', project);
          taskAssignment.applyTaskDefaults();
          return taskAssignment.save();
        });
      }
    },
    createUserAssignments: function createUserAssignments(project, users, currentUser) {
      var _this4 = this;

      var userAssignments = [];
      var favoriteUsers = users.filter(function (user) {
        return currentUser.get('id') !== user.get('id') && user.get('favorite') && user.get('status') === _environment.default.constants.STATUS_ENABLED;
      }); // favoriteUsers.push(currentUser);

      favoriteUsers.forEach(function (user) {
        var userAssignment = _this4.get('store').createRecord('user-assignment');

        userAssignment.set('user', user);
        userAssignment.set('project', project);
        userAssignment.applyUserDefaults();
        userAssignment.set('isProjectManager', user.get('isProjectManager'));
        userAssignments.push(userAssignment.save());
      });
      return Ember.RSVP.all(userAssignments);
    },
    copyProject: function copyProject(properties) {
      var copyPrefixLength = 8;
      var copy = this.get('store').createRecord('project', properties);
      var charactersLeft = PROJECT_NAME_MAX_LENGTH - properties.name.length;

      if (charactersLeft < copyPrefixLength) {
        properties.name = properties.name.slice(0, charactersLeft - copyPrefixLength);
      }

      copy.set('name', '(COPY): ' + properties.name);
      var codeAppendix = 1;
      var projectCode = this.createShortTag(copy.get('name'), CODE_MAX_LENGTH, this.get('store').peekAll('project').getEach('code'), codeAppendix, copy.get('client.code'));
      copy.set('code', projectCode);
      copy.setupTrackerMetaData();
      return copy.save();
    },
    copyTaskAssignments: function copyTaskAssignments(originalTaskAssignments, project) {
      var _this5 = this;

      var taskAssignments = [];
      originalTaskAssignments.forEach(function (taskAssignment) {
        var newAssignment = _this5.get('store').createRecord('task-assignment', taskAssignment.clone());

        newAssignment.set('project', project);
        taskAssignments.push(newAssignment.save());
      });
      return Ember.RSVP.all(taskAssignments);
    },
    copyUserAssignments: function copyUserAssignments(originalUserAssignments, project, currentUser) {
      var _this6 = this;

      var userAssignments = [];
      originalUserAssignments.forEach(function (userAssignment) {
        if (userAssignment.get('user.id') !== currentUser.get('id')) {
          var newAssignment = _this6.get('store').createRecord('user-assignment', userAssignment.clone());

          newAssignment.set('project', project);
          userAssignments.push(newAssignment.save());
        }
      });
      return Ember.RSVP.all(userAssignments);
    }
  });

  _exports.default = _default;
});