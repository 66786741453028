define("coffeecup/services/analytics", ["exports", "coffeecup/services/base-analytics", "ember-data"], function (_exports, _baseAnalytics, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _baseAnalytics.default.extend({
    googleAnalytics: Ember.inject.service(),
    intercomAnalytics: Ember.inject.service(),
    analyticsEngines: Ember.computed('googleAnalytics', 'intercomAnalytics', {
      get: function get() {
        return [this.get('googleAnalytics'), this.get('intercomAnalytics')];
      }
    }),
    start: function start(user) {
      this.get('analyticsEngines').forEach(function (e) {
        return e.start(user);
      });
    },
    stop: function stop() {
      this.get('analyticsEngines').forEach(function (e) {
        return e.stop();
      });
    },
    send: function send(type, args) {
      this.log('send', type, args);
      this.get('analyticsEngines').forEach(function (e) {
        return e.send(type, args);
      });
    },
    event: function event(ev, args) {
      this.log('send event', ev, args);
      this.get('analyticsEngines').forEach(function (e) {
        return e.event(ev, args);
      });
    },
    notifyTransition: function notifyTransition(url) {
      this.log('notify transition', url);

      if (url && typeof url === 'string') {
        // we only track the main routes, query params should not be included
        var cleanUrl = url.split('?')[0]; // anonymize route details

        cleanUrl = cleanUrl.replace(/(\d+)/g, 'ID');
        this.get('analyticsEngines').forEach(function (e) {
          return e.notifyTransition(cleanUrl);
        });
      }
    },
    notifyError: function notifyError(error, fatal, transition) {
      this.log('notify error', error, fatal, transition);

      if (!this.get('trackErrors')) {
        return;
      }

      var app = this.get('app');
      var message; // STRING

      if (typeof error === 'string') {
        message = error;
      } // Ember AdapterError
      else if (error instanceof _emberData.default.AdapterError) {
          error = error.errors[0];
          message = "AdapterError: ".concat(error.status, " ").concat(error.title, " ").concat(error.detail);
        } // Default error object
        else if (error && typeof error.message !== 'undefined') {
            message = error.message;
          } // ajax error
          else if (error & error.statusText && error.status) {
              message = "AjaxError: ".concat(error.status, " ").concat(error.statusText);
            } // Sails error message
            else if (error.error && error.statusCode && error.error.summary) {
                message = "ApiError: ".concat(error.statusCode, " ").concat(error.error.summary);
              } else {
                message = 'Unkown Error';
                fatal = true;
              }

      if (_typeof(transition) === 'object') {
        message += ' - transition to: ' + Ember.get(transition, 'intent.name');
        message += ' params: ' + JSON.stringify(Ember.get(transition, 'intent.contexts'));
        message += ' from: ' + JSON.stringify(Ember.get(transition, 'router.oldState.params'));
      }

      var exOptions = {
        appVersion: app.version,
        appName: app.name,
        exDescription: message,
        exFatal: !!fatal
      };
      this.get('analyticsEngines').forEach(function (e) {
        return e.exception(exOptions);
      });
    }
  });

  _exports.default = _default;
});