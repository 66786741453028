define("coffeecup/utils/cache-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cacheProperty;

  /**
   * Computed property macro which behaves mostly like readOnly but locally caches the
   * value instead of relying on the cache of the dependentKey.
   * This allows caching of volatile properties, the cache property itself is not volatile.
   *
   * @param {string} dependentKey
   */
  function cacheProperty(dependentKey) {
    return Ember.computed(dependentKey, function () {
      return this.get(dependentKey);
    }).readOnly();
  }
});