define("coffeecup/templates/components/t-split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AoEXYgWM",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[34,0]],[2,\"\\n\"],[18,1,null],[2,\"\\n\"],[1,[34,1]]],\"hasEval\":false,\"upvars\":[\"firstPart\",\"secondPart\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/t-split.hbs"
    }
  });

  _exports.default = _default;
});