define("coffeecup/templates/components/planner/project-user-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "99/NRrBJ",
    "block": "{\"symbols\":[\"@users\",\"@project\"],\"statements\":[[10,\"div\"],[14,0,\"row-info\"],[12],[2,\"\\n\"],[2,\"  \"],[10,\"div\"],[14,0,\"select-plus-icon select-size-small w-100\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,\"role\",\"button\"],[16,0,[30,[36,2],[\"ember-power-select-trigger ember-basic-dropdown-trigger \",[30,[36,1],[[32,0,[\"showCapacityModal\"]],\"modal-reference\"],null]],null]],[24,4,\"button\"],[4,[38,3],[\"click\",[30,[36,0],[[32,0],\"toggleUserCapacityModal\"],null]],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"ember-power-select-placeholder\"],[12],[1,[30,[36,4],[\"assignUser\"],null]],[13],[2,\"\\n\\n      \"],[10,\"span\"],[14,0,\"ember-power-select-status-icon\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"viewport\",\"showSummary\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row-summary\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],[[32,0,[\"showCapacityModal\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"planner/user-capacity/user-capacity-modal\",[],[[\"@users\",\"@viewport\",\"@assignAction\",\"@onHide\",\"@project\"],[[32,1],[32,0,[\"viewport\"]],[30,[36,0],[[32,0],\"createAssignmentForUser\"],null],[30,[36,0],[[32,0],\"toggleUserCapacityModal\"],null],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"concat\",\"on\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/project-user-new.hbs"
    }
  });

  _exports.default = _default;
});