define("coffeecup/templates/components/analytics-module/kpi/invoiced", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NlA/NGQK",
    "block": "{\"symbols\":[\"invoice\",\"@analytics\",\"@hasData\"],\"statements\":[[8,\"analytics-module/kpi/kpi-cell\",[],[[\"@icon\",\"@amount\",\"@label\",\"@negative\",\"@hasData\"],[\"icomoon-coin-euro\",[30,[36,2],[[32,2,[\"invoicesTotal\"]],false,true,true],null],[30,[36,0],[\"invoiced\"],null],[32,0,[\"isOverBudget\"]],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[32,0,[\"limitedInvoices\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"kpi-tooltip\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,0,[\"limitedInvoices\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n          \"],[10,\"span\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"invoicedAt\"]]],null]],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"span\"],[12],[2,\"\\n            \"],[1,[30,[36,2],[[32,1,[\"amount\"]],false,true],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[10,\"div\"],[14,0,\"kpi-tooltip-row summary-row\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"invoicedTotal\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[30,[36,2],[[32,2,[\"invoicesTotal\"]],false,true],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"noInvoicesAvailable\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"date-fmt\",\"currency-fmt\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/kpi/invoiced.hbs"
    }
  });

  _exports.default = _default;
});