define("coffeecup/templates/components/color-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VhS80CSM",
    "block": "{\"symbols\":[\"dropdown\"],\"statements\":[[11,\"div\"],[24,0,\"dropdown color\"],[4,[38,0],[[32,0],\"empty\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n\"],[6,[37,4],null,[[\"horizontalPosition\"],[\"right\"]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"trigger\"]],\"expected `dropdown.trigger` to be a contextual component but found a string. Did you mean `(component dropdown.trigger)`? ('coffeecup/templates/components/color-picker.hbs' @ L3:C7) \"],null]],[[\"class\"],[\"btn btn-default has-border color\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"\\n        Toggle Colors\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"dropdown-toggle color\"],[15,5,[32,0,[\"fillStyle\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[30,[36,2],[[32,1,[\"content\"]],\"expected `dropdown.content` to be a contextual component but found a string. Did you mean `(component dropdown.content)`? ('coffeecup/templates/components/color-picker.hbs' @ L9:C7) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"color-menu-wrapper\"],[12],[2,\"\\n        \"],[11,\"div\"],[24,0,\"dropdown-menu color-picker-wrapper\"],[4,[38,0],[[32,0],[32,1,[\"actions\",\"close\"]]],null],[12],[2,\"\\n          \"],[8,\"color-picker-palette\",[],[[\"@colors\",\"@selectedColor\",\"@selectColor\"],[[32,0,[\"sortedColors\"]],[34,1],[30,[36,0],[[32,0],\"setColor\"],null]]],null],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"color\",\"-assert-implicit-component-helper-argument\",\"component\",\"basic-dropdown\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/color-picker.hbs"
    }
  });

  _exports.default = _default;
});