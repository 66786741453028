define("coffeecup/models/calendar-feed", ["exports", "ember-data", "coffeecup/config/environment", "coffeecup/models/-super-model"], function (_exports, _emberData, _environment, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    token: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    uri: Ember.computed('token', function () {
      var token = this.get('token');
      return _environment.default.APP.CALENDARFEED_URI_FORMAT.replace('%s', token);
    })
  });

  _exports.default = _default;
});