define("coffeecup/templates/components/planner/milestone-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yZy5G8xR",
    "block": "{\"symbols\":[],\"statements\":[[8,\"planner/milestone-tooltip\",[],[[\"@milestones\"],[[32,0,[\"milestone\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,0,[\"milestone\",\"icon\"]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"code upper\"],[12],[2,\"\\n    \"],[1,[32,0,[\"milestone\",\"code\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/milestone-frame.hbs"
    }
  });

  _exports.default = _default;
});