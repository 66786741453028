define("coffeecup/services/planner/viewport", ["exports", "moment", "coffeecup/config/environment", "coffeecup/utils/date"], function (_exports, _moment, _environment, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUserService: Ember.inject.service('currentUser'),
    zoom: Ember.inject.service('planner/zoom'),
    clock: Ember.inject.service(),
    sideBarType: false,
    showSummary: Ember.computed.reads('currentUserService.currentUser.uiState.plannerSummary'),
    showWeeklyHeatmap: false,
    showWeekends: false,
    showCapacityRow: true,
    planningMode: _environment.default.constants.PLANNING_MODE_WEEKLY,
    headerWidth: 332,
    setShowWeekends: function setShowWeekends(val) {
      this.set('showWeekends', val);
    },
    setPlanningMode: function setPlanningMode(val) {
      this.set('planningMode', val);
    },
    isWeeklyPlanningMode: Ember.computed.equal('planningMode', _environment.default.constants.PLANNING_MODE_WEEKLY),
    rowHeaderWidth: Ember.computed('showSummary', 'sideBarWidth', function () {
      var headerWidth = this.get('headerWidth'); // @see css of .row-info width + its padding (320 + 12)

      var drawerWidth = this.get('showSummary') ? 96 : 0;
      var sideBarWidth = this.get('sideBarWidth');
      return headerWidth + sideBarWidth + drawerWidth;
    }),
    subNavWidth: Ember.computed('sideBarType', function () {
      var sideBarWidth = 0;
      var sideBarType = this.get('sideBarType');

      switch (sideBarType) {
        case _environment.default.constants.SIDEBAR_TYPE_L:
          sideBarWidth = 200;
          break;
      }

      return sideBarWidth;
    }),
    sideBarWidth: Ember.computed('sideBarType', function () {
      var sideBarWidth = 0;
      var sideBarType = this.get('sideBarType');

      switch (sideBarType) {
        case _environment.default.constants.SIDEBAR_TYPE_L:
          sideBarWidth = 272;
          break;

        case _environment.default.constants.SIDEBAR_TYPE_M:
          sideBarWidth = 72;
          break;
      }

      return sideBarWidth;
    }),
    dayWidth: Ember.computed('zoom.level', function () {
      return 48 / this.get('zoom.level');
    }),
    weekWidth: Ember.computed('zoom.level', 'daysPerWeek', function () {
      return 48 / this.get('zoom.level') * this.get('daysPerWeek');
    }),
    assignmentWidth: Ember.computed('isWeeklyPlanningMode', 'daysPerWeek', 'zoom.level', function () {
      return this.get('isWeeklyPlanningMode') ? this.get('weekWidth') : this.get('dayWidth');
    }),
    weeksToDisplay: 3,
    ensureOneWeekMinimum: Ember.observer('weeksToDisplay', function () {
      this.get('weeksToDisplay') < 1 && this.set('weeksToDisplay', 1);
    }),
    startDate: Ember.computed('startDateFormatted', {
      get: function get() {
        return _moment.default.utc(this.get('startDateFormatted')).startOf('isoWeek').startOf('day');
      },
      set: function set(key, val) {
        var date = _moment.default.utc(val).startOf('isoWeek').startOf('day');

        if (!(0, _date.isDateValid)(val)) {
          date = this.get('clock.localDayAsUtcMoment').day(1);
        }

        this.set('startDateFormatted', date.format(_environment.default.constants.DATE_FORMAT_DAY));
        return date;
      }
    }),
    startDateFormatted: Ember.computed('clock.day', function () {
      return this.get('clock.localDayAsUtcMoment').day(1).format(_environment.default.constants.DATE_FORMAT_DAY);
    }),
    // startDateFormatted: computed('currentUserService.currentUser.uiState.plannerStartDate', {
    //   get() {
    //     return this.get('currentUserService.currentUser.uiState.plannerStartDate');
    //     // return this.get('clock.localDayAsUtcMoment').day(1).format(ENV.constants.DATE_FORMAT_DAY);
    //   },
    //   set(k, v) {
    //     this.get('currentUserService').saveUISettings({
    //       plannerStartDate: v
    //     });
    //     return v;
    //   }
    // }),
    startDateWithMargin: Ember.computed('startDate', {
      get: function get() {
        return this.get('startDate').clone().subtract(1, 'week');
      }
    }),
    endDateWithMargin: Ember.computed('endDate', {
      get: function get() {
        return this.get('endDate').clone().add(1, 'week');
      }
    }),
    endDate: Ember.computed('startDate', 'weeksToDisplay', {
      get: function get() {
        var weeksToDisplay = this.get('weeksToDisplay');
        var endDate = this.get('startDate').clone();
        endDate.add(weeksToDisplay, 'weeks').subtract(1, 'days');
        return endDate;
      }
    }),
    selectedScopeStartDate: null,
    scopeStartDate: Ember.computed('startDate', 'selectedScopeStartDate', {
      get: function get() {
        return this.get('selectedScopeStartDate') || this.get('startDate');
      },
      set: function set(key, val) {
        var _this = this;

        if (val && this.get('isWeeklyPlanningMode') && val.day() !== 1) {
          val.startOf('isoWeek');
        }

        var applicationController = Ember.getOwner(this).lookup('controller:application');
        applicationController.set('isLoading', true);
        /* Band-aid fix to get users unstuck if they enter a very, very old date */

        if (val && val.year() < (0, _moment.default)().year() - 1) {
          val.year((0, _moment.default)().year() - 1);
        }

        Ember.run.next(function () {
          _this.set('selectedScopeStartDate', val);

          if (val && _this.get('selectedScopeEndDate') === null) {
            _this.set('selectedScopeEndDate', _this.get('endDate').clone().add(-1, 'weeks'));
          } else if (val === null) {
            _this.set('selectedScopeEndDate', null);
          }

          _this.saveScopeDates();

          Ember.run.scheduleOnce('afterRender', _this, function () {
            applicationController.set('isLoading', false);
          });
        });
        return val || this.get('startDate');
      }
    }),
    saveScopeDates: function saveScopeDates() {
      this.currentUserService.saveUISettings({
        plannerScopeStartDate: this.get('selectedScopeStartDate'),
        plannerScopeEndDate: this.get('selectedScopeEndDate')
      });
    },
    selectedScopeEndDate: null,
    scopeEndDate: Ember.computed('endDate', 'selectedScopeEndDate', {
      get: function get() {
        return this.get('selectedScopeEndDate') || this.get('endDate').clone().add(-1, 'weeks');
      },
      set: function set(key, val) {
        var _this2 = this;

        var endOfWeekDay = this.get('showWeekends') ? 0 : 6;

        if (val && this.get('isWeeklyPlanningMode') && val.day() !== endOfWeekDay) {
          val.day(endOfWeekDay);
        }

        var applicationController = Ember.getOwner(this).lookup('controller:application');
        applicationController.set('isLoading', true);
        /* Band-aid fix to get users unstuck if they enter a very, very old date */

        if (val.year() < (0, _moment.default)().year() - 1) {
          val.year((0, _moment.default)().year() - 1);
        }

        Ember.run.next(function () {
          _this2.set('selectedScopeEndDate', val);

          if (val && _this2.get('selectedScopeStartDate') === null) {
            _this2.set('selectedScopeStartDate', _this2.get('startDate'));
          }

          _this2.saveScopeDates();

          Ember.run.scheduleOnce('afterRender', _this2, function () {
            applicationController.set('isLoading', false);
          });
        });
        return val || this.get('endDate').clone().add(-1, 'weeks');
      }
    }),
    linkScopeToViewport: Ember.computed('selectedScopeStartDate', 'selectedScopeEndDate', {
      get: function get() {
        return this.get('selectedScopeStartDate') === null && this.get('selectedScopeEndDate') === null;
      }
    }),
    todayIsInViewport: Ember.computed('startDate', 'endDate', 'clock.localDayAsUtcMoment', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      var today = this.get('clock.localDayAsUtcMoment');
      return today.isSameOrAfter(startDate) && today.isSameOrBefore(endDate);
    }),
    isOnThisWeek: Ember.computed('startDate', 'clock.localDayAsUtcMoment', function () {
      var startDate = this.get('startDate');
      var today = this.get('clock.localDayAsUtcMoment');
      return today.isSame(startDate, 'week');
    }),
    defaultAssignmentStartDate: Ember.computed('todayIsInViewport', 'clock.localDayAsUtcMoment', function () {
      var todayIsInViewport = this.get('todayIsInViewport');

      if (!todayIsInViewport) {
        return this.get('startDate');
      }

      var day = this.get('clock.localDayAsUtcMoment');

      if (day.isWeekend()) {
        day = day.day(1);
      }

      if (this.get('isWeeklyPlanningMode')) {
        var startOfWeek = day.clone().startOf('isoWeek');

        if (day.day() != startOfWeek.day()) {
          return startOfWeek.add(1, 'week');
        }
      }

      return day;
    }),
    defaultAssignmentEndDate: Ember.computed('defaultAssignmentStartDate', 'isWeeklyPlanningMode', function () {
      var startDate = this.get('defaultAssignmentStartDate');
      return this.get('isWeeklyPlanningMode') ? startDate.clone().endOf('isoWeek') : startDate.clone();
    }),
    selectedDay: null,
    selectDay: function selectDay(day) {
      var date = _moment.default.utc(day);

      if ((0, _date.isDateValid)(date)) {
        this.set('selectedDay', date.clone());
      }
    },
    selectedDayPosition: Ember.computed('startDate', 'selectedDay', 'dayWidth', 'rowHeaderWidth', function () {
      var startDate = this.get('startDate');
      var selectedDay = this.get('selectedDay');
      var numberOfDaysBetween = this.numberOfDaysBetween(startDate, selectedDay);
      var dayWidth = this.get('dayWidth');
      var width = numberOfDaysBetween * dayWidth + dayWidth / 2;
      return this.get('rowHeaderWidth') + width;
    }),
    daysPerWeek: Ember.computed('showWeekends', function () {
      return this.get('showWeekends') ? 7 : 5;
    }),
    shiftDate: function shiftDate(fromDate, days) {
      var showWeekends = this.get('showWeekends');
      return (0, _date.shift)(fromDate, days, showWeekends);
    },
    numberOfDaysBetween: function numberOfDaysBetween(startDate, endDate) {
      return this.get('showWeekends') ? (0, _date.numberOfDays)(startDate, endDate) : (0, _date.numberOfBusinessDays)(startDate, endDate);
    },
    endOfWeek: function endOfWeek(date) {
      var daysPerWeek = this.get('daysPerWeek');
      return _moment.default.utc(date).clone().day(daysPerWeek);
    },
    eachDay: function eachDay(startDate, endDate, cb) {
      (this.get('showWeekends') ? _date.eachDay : _date.eachBusinessDay)(startDate, endDate, cb);
    }
  });

  _exports.default = _default;
});