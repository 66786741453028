define("coffeecup/templates/start/-login-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L1ybMIl/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"footer\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    ©\\n    \"],[1,[30,[36,1],[\"YYYY\"],null]],[2,\"\\n    \"],[1,[30,[36,2],[\"coffeecup\"],null]],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"appVersion\",\"current-date\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/start/-login-footer.hbs"
    }
  });

  _exports.default = _default;
});