define("coffeecup/services/expense-category", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    createDefaultExpenseCategory: function createDefaultExpenseCategory() {
      var expenseCategory = this.get('store').createRecord('expense-category');
      expenseCategory.set('color', 1);
      expenseCategory.set('icon', 5);
      expenseCategory.set('label', this.get('i18n').t('newExpenseCategory'));
      expenseCategory.set('status', _environment.default.constants.STATUS_ENABLED);
      return expenseCategory.save();
    },
    copyExpenseCategory: function copyExpenseCategory(properties) {
      var copy = this.get('store').createRecord('expense-category', properties);
      copy.set('label', '(COPY) ' + properties.label);
      return copy.save();
    },
    expenseCategoriesSortedAndGroupedByInactive: function expenseCategoriesSortedAndGroupedByInactive(expenseCategories) {
      var expenseCategoriesSorted = expenseCategories.sortBy('label');
      var activeExpenseTypes = expenseCategoriesSorted.filterBy('isActive');
      var inactiveExpenseTypes = expenseCategoriesSorted.filterBy('isInactive');

      if (inactiveExpenseTypes.get('length') > 0) {
        return [Ember.Object.create({
          text: this.get('i18n').t('activeLabel'),
          children: activeExpenseTypes
        }), Ember.Object.create({
          text: this.get('i18n').t('inactiveLabel'),
          children: inactiveExpenseTypes
        })];
      } else {
        return activeExpenseTypes;
      }
    }
  });

  _exports.default = _default;
});