define("coffeecup/routes/welcome", ["exports", "coffeecup/utils/build-url"], function (_exports, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var WelcomeRoute = Ember.Route.extend({
    analytics: Ember.inject.service(),
    session: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      controller.set('user', model.user);
      controller.set('token', model.token);

      this._super.apply(this, arguments);
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        this.get('session').on('invalidationSucceeded', function () {
          _this.set('session._stopSessionInvalidation', true);

          document.location.reload();
        });
        this.get('session').invalidate();
        transition.abort();
      }
    },
    model: function model(params, transition) {
      var _this2 = this;

      var url = (0, _buildUrl.default)(this.store, 'users/findbytoken');
      var token = decodeURIComponent(params.token);
      var request = {
        url: url,
        type: 'POST',
        dataType: 'JSON',
        data: {
          passwordtokenrequest: {
            token: token
          }
        }
      };
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.ajax(request).then(function (response) {
          if (response.user) resolve({
            token: token,
            user: response.user
          });
        }).catch(function (e) {
          _this2.get('notifications').error('invalid.token');

          transition.abort();

          _this2.transitionTo('login');
        });
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('start.welcome', {
        outlet: 'formbox'
      });
      this.get('analytics').start();
    }
  });
  var _default = WelcomeRoute;
  _exports.default = _default;
});