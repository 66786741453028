define("coffeecup/models/custom-icon", ["exports", "ember-data", "coffeecup/utils/color-hex2rgba", "coffeecup/models/-super-model", "coffeecup/config/environment"], function (_exports, _emberData, _colorHex2rgba, _superModel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    color: _emberData.default.attr('number'),
    icon: _emberData.default.attr('number'),
    colorCode: Ember.computed('color', function () {
      var color = this.get('color');
      return _environment.default.constants.DEFAULT_COLORS[color - 1];
    }),
    darkerColorCode: Ember.computed('colorCode', function () {
      var bgColor = _colorHex2rgba.RGBA.convertFromHex(this.get('colorCode'));

      bgColor.darken(25);
      return bgColor.toCSS();
    }),
    lighterColorCode: Ember.computed('colorCode', function () {
      var bgColor = _colorHex2rgba.RGBA.convertFromHex(this.get('colorCode'));

      bgColor.a = 0.05;
      return bgColor.toCSS();
    }),
    typeImageHTML: Ember.computed('colorCode', 'iconClass', function () {
      var iconClass = this.get('iconClass');
      var color = this.get('colorCode');
      return Ember.String.htmlSafe('<div class="custom-icon icomoon-' + iconClass + '" style="background-color: ' + color + ';"></div>');
    }),
    iconFullClass: Ember.computed('iconClass', function () {
      return 'icomoon-' + this.get('iconClass');
    }),
    colorStyle: Ember.computed('color', function () {
      var color = _environment.default.constants.DEFAULT_COLORS[this.get('color') - 1];

      return Ember.String.htmlSafe('background-color: ' + color);
    }),
    iconClass: Ember.computed('icon', function () {
      var icon = this.get('icon');
      var iconSet = _environment.default.constants.DEFAULT_ICONS;

      if (this.constructor.modelName === 'expense-category') {
        iconSet = _environment.default.constants.EXPENSE_CATEGORY_ICONS;
      }

      var iconIndex = Math.min(icon, iconSet.length);
      return iconSet[iconIndex - 1];
    })
  });

  _exports.default = _default;
});