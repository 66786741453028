define("coffeecup/templates/components/dependent-value-holder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zKGcecy4",
    "block": "{\"symbols\":[\"@tooltipContent\",\"@color\",\"@fontColor\",\"@label\"],\"statements\":[[8,\"tool-tipster\",[],[[\"@content\"],[[30,[36,0],[[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"dependent-value-holder\"],[15,5,[30,[36,2],[[32,2],[30,[36,1],[\"background: \",[32,2],\";\"],null],\"\"],null]],[12],[2,\"\\n    \"],[10,\"span\"],[15,5,[30,[36,2],[[32,3],[30,[36,1],[\"color: \",[32,3],\";\"],null],\"\"],null]],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,4]],null]],[2,\"\\n      \"],[1,[32,0,[\"refactoredValue\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dependent-value-holder.hbs"
    }
  });

  _exports.default = _default;
});