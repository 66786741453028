define("coffeecup/templates/components/input/cc-time-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uy5NhZ4O",
    "block": "{\"symbols\":[],\"statements\":[[8,\"tool-tipster\",[],[[\"@content\"],[[34,0]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"has-feedback-small\"],[12],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"class\",\"type\",\"value\",\"placeholder\",\"focus-out\",\"escape-press\",\"disabled\"],[\"form-control textfield-time\",\"text\",[35,3],[35,2],\"resetIfEmpty\",\"cancel\",[35,1]]]]],[2,\"\\n    \"],[10,\"span\"],[14,0,\"form-control-feedback\"],[12],[2,\"h\"],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"person-days-label\"],[12],[1,[34,5]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"errorMsg\",\"disabled\",\"placeholder\",\"durationFMT\",\"input\",\"personDaysLabel\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/cc-time-field.hbs"
    }
  });

  _exports.default = _default;
});