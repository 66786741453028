define("coffeecup/transforms/feature-flags", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var featureFlags = Ember.Object.extend({
    state: null,
    absenceIntegrationActive: Ember.computed('state.{}', {
      get: function get() {
        return !!this.get('state').absenceIntegrationActive;
      }
    }),
    useHourlyCosts: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').useHourlyCosts !== false;
      }
    }),
    projectBoardPlannedVsActual: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').projectBoardPlannedVsActual || false;
      },
      set: function set(key, val) {
        this.get('state').projectBoardPlannedVsActual = val;
        return val;
      }
    })
  });

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      if (value && typeof value.get !== 'undefined') {
        return JSON.stringify({
          projectBoardPlannedVsActual: value.get('projectBoardPlannedVsActual')
        });
      }
    },
    deserialize: function deserialize(value) {
      value = value || '{}';
      return featureFlags.create({
        state: JSON.parse(value)
      });
    }
  });

  _exports.default = _default;
});