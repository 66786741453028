define("coffeecup/instance-initializers/currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'currency',
        id: 'EUR',
        attributes: {
          sign: '€'
        }
      }, {
        type: 'currency',
        id: 'GBP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'USD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'ALL',
        attributes: {
          sign: 'Lek'
        }
      }, {
        type: 'currency',
        id: 'AFN',
        attributes: {
          sign: '؋'
        }
      }, {
        type: 'currency',
        id: 'ARS',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'AWG',
        attributes: {
          sign: 'ƒ'
        }
      }, {
        type: 'currency',
        id: 'AUD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'AZN',
        attributes: {
          sign: 'ман'
        }
      }, {
        type: 'currency',
        id: 'BSD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'BBD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'BYR',
        attributes: {
          sign: 'p.'
        }
      }, {
        type: 'currency',
        id: 'BZD',
        attributes: {
          sign: 'BZ$'
        }
      }, {
        type: 'currency',
        id: 'BMD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'BOB',
        attributes: {
          sign: '$b'
        }
      }, {
        type: 'currency',
        id: 'BAM',
        attributes: {
          sign: 'KM'
        }
      }, {
        type: 'currency',
        id: 'BWP',
        attributes: {
          sign: 'P'
        }
      }, {
        type: 'currency',
        id: 'BGN',
        attributes: {
          sign: 'лв'
        }
      }, {
        type: 'currency',
        id: 'BRL',
        attributes: {
          sign: 'R$'
        }
      }, {
        type: 'currency',
        id: 'BND',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'KHR',
        attributes: {
          sign: '៛'
        }
      }, {
        type: 'currency',
        id: 'CAD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'KYD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'CLP',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'CNY',
        attributes: {
          sign: '¥'
        }
      }, {
        type: 'currency',
        id: 'COP',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'CRC',
        attributes: {
          sign: '₡'
        }
      }, {
        type: 'currency',
        id: 'HRK',
        attributes: {
          sign: 'kn'
        }
      }, {
        type: 'currency',
        id: 'CUP',
        attributes: {
          sign: '₱'
        }
      }, {
        type: 'currency',
        id: 'CZK',
        attributes: {
          sign: 'Kč'
        }
      }, {
        type: 'currency',
        id: 'DKK',
        attributes: {
          sign: 'kr'
        }
      }, {
        type: 'currency',
        id: 'DOP',
        attributes: {
          sign: 'RD$'
        }
      }, {
        type: 'currency',
        id: 'XCD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'EGP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'SVC',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'FKP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'FJD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'GHS',
        attributes: {
          sign: '¢'
        }
      }, {
        type: 'currency',
        id: 'GIP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'GTQ',
        attributes: {
          sign: 'Q'
        }
      }, {
        type: 'currency',
        id: 'GGP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'GYD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'HNL',
        attributes: {
          sign: 'L'
        }
      }, {
        type: 'currency',
        id: 'HKD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'HUF',
        attributes: {
          sign: 'Ft'
        }
      }, {
        type: 'currency',
        id: 'ISK',
        attributes: {
          sign: 'kr'
        }
      }, {
        type: 'currency',
        id: 'INR',
        attributes: {
          sign: '₹'
        }
      }, {
        type: 'currency',
        id: 'IDR',
        attributes: {
          sign: 'Rp'
        }
      }, {
        type: 'currency',
        id: 'IRR',
        attributes: {
          sign: '﷼'
        }
      }, {
        type: 'currency',
        id: 'IMP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'ILS',
        attributes: {
          sign: '₪'
        }
      }, {
        type: 'currency',
        id: 'JMD',
        attributes: {
          sign: 'J$'
        }
      }, {
        type: 'currency',
        id: 'JPY',
        attributes: {
          sign: '¥'
        }
      }, {
        type: 'currency',
        id: 'JEP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'KZT',
        attributes: {
          sign: 'лв'
        }
      }, {
        type: 'currency',
        id: 'KRW',
        attributes: {
          sign: '₩'
        }
      }, {
        type: 'currency',
        id: 'KGS',
        attributes: {
          sign: 'лв'
        }
      }, {
        type: 'currency',
        id: 'LAK',
        attributes: {
          sign: '₭'
        }
      }, {
        type: 'currency',
        id: 'LBP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'LRD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'MKD',
        attributes: {
          sign: 'ден'
        }
      }, {
        type: 'currency',
        id: 'MYR',
        attributes: {
          sign: 'RM'
        }
      }, {
        type: 'currency',
        id: 'MUR',
        attributes: {
          sign: '₨'
        }
      }, {
        type: 'currency',
        id: 'MXN',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'MNT',
        attributes: {
          sign: '₮'
        }
      }, {
        type: 'currency',
        id: 'MZN',
        attributes: {
          sign: 'MT'
        }
      }, {
        type: 'currency',
        id: 'NAD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'NPR',
        attributes: {
          sign: '₨'
        }
      }, {
        type: 'currency',
        id: 'ANG',
        attributes: {
          sign: 'ƒ'
        }
      }, {
        type: 'currency',
        id: 'NZD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'NIO',
        attributes: {
          sign: 'C$'
        }
      }, {
        type: 'currency',
        id: 'NGN',
        attributes: {
          sign: '₦'
        }
      }, {
        type: 'currency',
        id: 'NOK',
        attributes: {
          sign: 'kr'
        }
      }, {
        type: 'currency',
        id: 'OMR',
        attributes: {
          sign: '﷼'
        }
      }, {
        type: 'currency',
        id: 'PKR',
        attributes: {
          sign: '₨'
        }
      }, {
        type: 'currency',
        id: 'PAB',
        attributes: {
          sign: 'B/.'
        }
      }, {
        type: 'currency',
        id: 'PYG',
        attributes: {
          sign: 'Gs'
        }
      }, {
        type: 'currency',
        id: 'PEN',
        attributes: {
          sign: 'S/.'
        }
      }, {
        type: 'currency',
        id: 'PHP',
        attributes: {
          sign: '₱'
        }
      }, {
        type: 'currency',
        id: 'PLN',
        attributes: {
          sign: 'zł'
        }
      }, {
        type: 'currency',
        id: 'QAR',
        attributes: {
          sign: '﷼'
        }
      }, {
        type: 'currency',
        id: 'RON',
        attributes: {
          sign: 'lei'
        }
      }, {
        type: 'currency',
        id: 'RUB',
        attributes: {
          sign: 'руб'
        }
      }, {
        type: 'currency',
        id: 'SHP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'SAR',
        attributes: {
          sign: '﷼'
        }
      }, {
        type: 'currency',
        id: 'RSD',
        attributes: {
          sign: 'Дин.'
        }
      }, {
        type: 'currency',
        id: 'SCR',
        attributes: {
          sign: '₨'
        }
      }, {
        type: 'currency',
        id: 'SGD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'SBD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'SOS',
        attributes: {
          sign: 'S'
        }
      }, {
        type: 'currency',
        id: 'ZAR',
        attributes: {
          sign: 'R'
        }
      }, {
        type: 'currency',
        id: 'LKR',
        attributes: {
          sign: '₨'
        }
      }, {
        type: 'currency',
        id: 'SEK',
        attributes: {
          sign: 'kr'
        }
      }, {
        type: 'currency',
        id: 'CHF',
        attributes: {
          sign: 'CHF'
        }
      }, {
        type: 'currency',
        id: 'SRD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'SYP',
        attributes: {
          sign: '£'
        }
      }, {
        type: 'currency',
        id: 'TWD',
        attributes: {
          sign: 'NT$'
        }
      }, {
        type: 'currency',
        id: 'THB',
        attributes: {
          sign: '฿'
        }
      }, {
        type: 'currency',
        id: 'TTD',
        attributes: {
          sign: 'TT$'
        }
      }, {
        type: 'currency',
        id: 'TRY',
        attributes: {
          sign: '₺'
        }
      }, {
        type: 'currency',
        id: 'TVD',
        attributes: {
          sign: '$'
        }
      }, {
        type: 'currency',
        id: 'UAH',
        attributes: {
          sign: '₴'
        }
      }, {
        type: 'currency',
        id: 'UYU',
        attributes: {
          sign: '$U'
        }
      }, {
        type: 'currency',
        id: 'UZS',
        attributes: {
          sign: 'лв'
        }
      }, {
        type: 'currency',
        id: 'VEF',
        attributes: {
          sign: 'Bs'
        }
      }, {
        type: 'currency',
        id: 'VND',
        attributes: {
          sign: '₫'
        }
      }, {
        type: 'currency',
        id: 'YER',
        attributes: {
          sign: '﷼'
        }
      }, {
        type: 'currency',
        id: 'ZWD',
        attributes: {
          sign: 'Z$'
        }
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'currency',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});