define("coffeecup/instance-initializers/region", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'region',
        id: 1,
        attributes: {
          label: 'BW',
          name: 'Baden-Württemberg'
        }
      }, {
        type: 'region',
        id: 2,
        attributes: {
          label: 'BY',
          name: 'Bayern'
        }
      }, {
        type: 'region',
        id: 3,
        attributes: {
          label: 'BE',
          name: 'Berlin'
        }
      }, {
        type: 'region',
        id: 4,
        attributes: {
          label: 'BB',
          name: 'Brandenburg'
        }
      }, {
        type: 'region',
        id: 5,
        attributes: {
          label: 'HB',
          name: 'Bremen'
        }
      }, {
        type: 'region',
        id: 6,
        attributes: {
          label: 'HH',
          name: 'Hamburg'
        }
      }, {
        type: 'region',
        id: 7,
        attributes: {
          label: 'HE',
          name: 'Hessen'
        }
      }, {
        type: 'region',
        id: 8,
        attributes: {
          label: 'MV',
          name: 'Mecklenburg-Vorpommern'
        }
      }, {
        type: 'region',
        id: 9,
        attributes: {
          label: 'NI',
          name: 'Niedersachsen'
        }
      }, {
        type: 'region',
        id: 10,
        attributes: {
          label: 'NW',
          name: 'Nordrhein-Westfalen'
        }
      }, {
        type: 'region',
        id: 11,
        attributes: {
          label: 'RP',
          name: 'Rheinland Pfalz'
        }
      }, {
        type: 'region',
        id: 12,
        attributes: {
          label: 'SL',
          name: 'Saarland'
        }
      }, {
        type: 'region',
        id: 13,
        attributes: {
          label: 'SN',
          name: 'Sachsen'
        }
      }, {
        type: 'region',
        id: 14,
        attributes: {
          label: 'ST',
          name: 'Sachen-Anhalt'
        }
      }, {
        type: 'region',
        id: 15,
        attributes: {
          label: 'SH',
          name: 'Schleswig Holstein'
        }
      }, {
        type: 'region',
        id: 16,
        attributes: {
          label: 'TH',
          name: 'Thüringen'
        }
      }, {
        type: 'region',
        id: 17,
        attributes: {
          label: 'NATIONAL',
          name: 'National'
        }
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'region',
    after: 'country',
    initialize: initialize
  };
  _exports.default = _default;
});