define("coffeecup/routes/time-entries/verifying/list", ["exports", "coffeecup/config/environment", "coffeecup/routes/_super-route"], function (_exports, _environment, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    validationService: Ember.inject.service('validation'),
    store: Ember.inject.service(),
    permissions: Ember.inject.service(),
    queryParams: {
      adminFilter: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var listController = this.controllerFor('time-entries.verifying.list');
      var verifierController = this.controllerFor('time-entries.verifying');
      var adminFilter = params.adminFilter || listController.get('adminFilter');
      var teamFilterId = this.paramsFor('time-entries.verifying').team;
      listController.set('pageIsLoading', true);
      listController.set('team', teamFilterId);
      var allUsers = this.store.peekAll('user');
      var anyUserIsValidated = allUsers && allUsers.filter(function (el) {
        return el.get('supervisor.id');
      });

      if (anyUserIsValidated && anyUserIsValidated.length > 0) {
        return this.get('validationService').loadPendingValidations(null, null, adminFilter === 'assignedTimeSheets', teamFilterId);
      } else {
        return {
          noValidationActive: true
        };
      }
    },
    setupController: function setupController(controller, model) {
      if (model.noValidationActive) {
        controller.set('pageIsLoading', false);
        controller.set('noValidationActive', true);
      } else {
        this._super(controller, model);

        controller.set('openWeeks', model.openWeeks);
        controller.set('weeks', model.weekData);
        controller.set('noValidationActive', false);
        controller.set('pageIsLoading', false);

        if (model.loadPreviousWeek) {
          this.controller.send('loadPreviousWeek', moment().startOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY));
        }
      }
    },
    actions: {
      removeFromList: function removeFromList(userData) {
        this.get('userData').removeObject(userData);
      }
    }
  });

  _exports.default = _default;
});