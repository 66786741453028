define("coffeecup/templates/components/modals/prompt-billing-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YAc0FMIL",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[6,[37,5],null,[[\"onClose\",\"targetAttachment\",\"translucentOverlay\"],[[30,[36,0],[[32,0],\"onHide\"],null],\"center\",false]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"form\"],[14,\"role\",\"form\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"modal-wrapper scrollable\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n          \"],[11,\"div\"],[24,0,\"modal-toggler close\"],[4,[38,0],[[32,0],\"onHide\"],null],[12],[2,\"\\n            \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[14,0,\"icon-cross\"],[12],[13],[2,\"\\n            \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"Close\"],[13],[2,\"\\n          \"],[13],[2,\"\\n          \"],[10,\"h2\"],[12],[1,[34,1]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n          \"],[10,\"p\"],[14,0,\"text-left\"],[12],[2,\"\\n            \"],[10,\"br\"],[12],[13],[2,\"\\n            \"],[1,[34,2]],[2,\"\\n            \"],[10,\"br\"],[12],[13],[2,\" \\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-primary save-btn\"],[16,\"disabled\",[34,3]],[24,4,\"submit\"],[4,[38,0],[[32,0],\"goToBilling\"],null],[12],[2,\"\\n              \"],[1,[34,4]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"modalHeader\",\"modalDescription\",\"submitIsDisabled\",\"buttonText\",\"modal-dialog\",\"openModal\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/modals/prompt-billing-update.hbs"
    }
  });

  _exports.default = _default;
});