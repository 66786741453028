define("coffeecup/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ApplicationSerializer = _emberData.default.RESTSerializer.extend({
    isNewSerializerAPI: true,
    extractMeta: function extractMeta(store, type, payload) {
      if (payload && payload.meta && Ember.isPresent(payload.meta.total) && Ember.isPresent(payload.meta.limit) && Ember.isPresent(payload.meta.skip)) {
        payload.meta.totalPages = Math.ceil(payload.meta.total / payload.meta.limit); // The number of pages in total

        payload.meta.currentPage = payload.meta.skip == 0 ? 1 : Math.ceil(payload.meta.skip / payload.meta.limit) + 1; // The current page number
      }

      return this._super.apply(this, [store, type, payload]);
    }
  });

  var _default = ApplicationSerializer;
  _exports.default = _default;
});