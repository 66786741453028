define("coffeecup/routes/analytics/clients", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('clients');
    },
    model: function model() {
      var clients = this.store.peekAll('client').sortBy('label');
      return {
        clients: clients
      };
    },
    setupController: function setupController(controller, context, transition) {
      controller.set('clients', context.clients);
      if (transition.target !== 'analytics.clients.details') controller.changeToClient();
    },
    afterModel: function afterModel(context, transition) {
      this._super.apply(this, arguments);

      var defaultClient = context.clients.filterBy('isActive').get('firstObject');

      if (!this.controllerFor('analytics.clients').get('selectedClient')) {
        this.controllerFor('analytics.clients').set('selectedClient', defaultClient);
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'analytics.clients.index') {
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});