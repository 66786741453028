define("coffeecup/routes/projects/tasks", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TasksRoute = _superRoute.default.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('tasks');
    },
    model: function model() {
      return this.store.peekAll('task');
    }
  });

  var _default = TasksRoute;
  _exports.default = _default;
});