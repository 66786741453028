define("coffeecup/routes/projects/tasks/copy", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //routes/manage/task/copy.js
  var _default = _superRoute.default.extend({
    taskService: Ember.inject.service('task'),
    redirect: function redirect(context) {
      var _this = this;

      this.get('taskService').copyTask(context.clone()).then(function (task) {
        _this.transitionTo('projects.tasks.edit', task);
      });
    }
  });

  _exports.default = _default;
});