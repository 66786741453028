define("coffeecup/utils/tooltip-arrow-positioner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = tooltipArrowPositioner;
  var APPROX_TOOLTIP_WIDTH = 500; // used to swap the entire tooltip to the other side of the screen

  var DEFAULT_PARENT_LEFT_INSET = 25; // the distance from spot where the arrow should point in the parent to the left edge of the parent

  var DESIRED_ARROW_DISTANCE_TO_LEFT_EDGE = 24; // the distance that the pointer should be inset from the left edge of the tooltip

  var RIGHT_SCREEN_EDGE_MINIMUM = 24;

  function tooltipArrowPositioner(_ref) {
    var instance = _ref.instance,
        position = _ref.position,
        _ref$approxWidth = _ref.approxWidth,
        approxWidth = _ref$approxWidth === void 0 ? APPROX_TOOLTIP_WIDTH : _ref$approxWidth,
        _ref$leftInset = _ref.leftInset,
        leftInset = _ref$leftInset === void 0 ? DEFAULT_PARENT_LEFT_INSET : _ref$leftInset;

    var _instance$_$origin$pa = instance._$origin.parent()[0].getBoundingClientRect(),
        parentX = _instance$_$origin$pa.x;

    var target = parentX + leftInset;
    var screenWidth = document.body.clientWidth;
    position.target = target; // if we're going to hang off the right side of the screen

    if (parentX + approxWidth > screenWidth) {
      position.target = target;
      position.coord.left = screenWidth - approxWidth - RIGHT_SCREEN_EDGE_MINIMUM;
    } else {
      position.coord.left = target - DESIRED_ARROW_DISTANCE_TO_LEFT_EDGE;
      position.target = target;
    }

    return position;
  }
});