define("coffeecup/services/invoice", ["exports", "coffeecup/utils/build-url"], function (_exports, _buildUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    saveAndUpdateRelatedModels: function saveAndUpdateRelatedModels(invoice, timeEntries, expenses, shouldBillAllTimeEntries) {
      var _this = this;

      var url = (0, _buildUrl.default)(this.get('store'), 'invoices/createInvoice');
      var body = {
        invoice: invoice,
        expenses: expenses,
        shouldBillAllTimeEntries: shouldBillAllTimeEntries,
        timeEntries: timeEntries
      };
      return new Promise(function (resolve, reject) {
        _this.get('session').postJSON(url, body).then(function (newInvoice) {
          invoice.destroyRecord();

          _this.get('store').pushPayload({
            invoices: [newInvoice]
          });

          _this.get('store').findAll('expense');

          _this.get('store').findRecord('analytics/project', newInvoice.project);

          resolve(_this.get('store').peekRecord('invoice', newInvoice.id));
        }).catch(function (error) {
          return reject(error);
        });
      });
    }
  });

  _exports.default = _default;
});