define("coffeecup/templates/components/detail-pane/detail-pane-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HUZJvpXC",
    "block": "{\"symbols\":[\"tag\",\"@closeAction\",\"@closeIcon\",\"@closeWordKey\",\"@subtitle\",\"@title\",\"&default\",\"@tags\"],\"statements\":[[10,\"header\"],[14,0,\"detail-pane-header\"],[12],[2,\"\\n\"],[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"close-pane\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[15,0,[31,[[30,[36,1],[[32,3],\"icon-cross\"],null]]]],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[30,[36,1],[[32,4],\"close\"],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[15,0,[31,[\"header-content \",[30,[36,3],[[32,5],\"with-subtitle\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"header-title\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"header-title-text\"],[12],[2,\"\\n        \"],[1,[32,6]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[18,7,[[30,[36,4],[\"headerMiddle\"],null]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"header-title-tags\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[32,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"header-tag\"],[12],[2,\"\\n            \"],[1,[32,1]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"header-subtitle\"],[12],[2,\"\\n      \"],[1,[32,5]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[18,7,[[30,[36,4],[\"headerActions\"],null]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"or\",\"t\",\"if\",\"-named-block-invocation\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/detail-pane/detail-pane-header.hbs"
    }
  });

  _exports.default = _default;
});