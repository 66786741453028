define("coffeecup/serializers/analytics/planner", ["exports", "coffeecup/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import DS from "ember-data";
  var _default = _application.default.extend({
    _typeKey: 'analyticsPlanner',
    _modelName: 'analytics/planner',
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      throw new Error("".concat([this], " has no normalizeFindRecordResponse method"));
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      var returnObj = {
        data: []
      };

      if (!payload || !payload[this._typeKey]) {
        return returnObj;
      }

      var projects = Ember.get(payload[this._typeKey], 'projects');

      if (projects) {
        projects.forEach(function (project) {
          var projectId = Ember.get(project, 'id');
          if (!projectId) return;
          Ember.get(project, 'users').forEach(function (user) {
            var userId = Ember.get(user, 'id');
            if (!userId) return;
            returnObj.data.push({
              id: "p".concat(projectId, "u").concat(userId),
              // ember-data needs an id, just any for that matter
              type: _this._modelName,
              relationships: {
                project: {
                  data: {
                    type: 'project',
                    id: projectId
                  }
                },
                user: {
                  data: {
                    type: 'user',
                    id: userId
                  }
                }
              },
              attributes: {
                hoursPlanned: Ember.get(user, 'hours.planned'),
                hoursTracked: Ember.get(user, 'hours.tracked')
              }
            });
          });
        });
      }

      return returnObj;
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload) {
        return this.normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType);
      }
    }
  });

  _exports.default = _default;
});