define("coffeecup/serializers/planner-assignment", ["exports", "coffeecup/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //serializers/planner-assignment.js
  var _default = _application.default.extend({
    attrs: {
      splitDate: {
        serialize: false
      } // makes the attribute readOnly

    }
  });

  _exports.default = _default;
});