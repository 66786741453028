define("coffeecup/templates/components/invited-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t7mpqkeG",
    "block": "{\"symbols\":[\"@isPending\"],\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"code-label\",[],[[\"@label\",\"@type\",\"@icon\",\"@size\",\"@isExpandable\"],[[30,[36,0],[\"invited\"],null],\"primary-background\",\"paperplane\",\"large\",true]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/invited-label.hbs"
    }
  });

  _exports.default = _default;
});