define("coffeecup/templates/components/tag-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OhG+C/rV",
    "block": "{\"symbols\":[\"@label\",\"@property\"],\"statements\":[[8,\"tool-tipster\",[],[[\"@content\",\"@tagName\",\"@side\"],[[30,[36,0],[\"filterTags\"],[[\"property\",\"value\"],[[32,2],[32,1]]]],\"span\",\"bottom\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"span\"],[24,0,\"icomoon-cross22\"],[24,5,\"margin-right: 4px; cursor: pointer\"],[4,[38,1],[[32,0],\"click\"],null],[12],[13],[2,\"\\n  \"],[10,\"span\"],[12],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"label\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/tag-button.hbs"
    }
  });

  _exports.default = _default;
});