define("coffeecup/templates/components/manage-table/cells/user-weekly-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HHCZ/nDD",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell\"],[15,5,[32,0,[\"safeWidth\"]]],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"manage-table-cell-contents\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isTeam\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"team-summary-cell-contents\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,0,[\"teamTotalHours\"]],\" h\"],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[32,1,[\"hasFlexibleHours\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],[\"flexibleWorkHours\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"effectiveEmployment\",\"hoursTotal\"]],0],null],\" h\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"or\",\"concat\",\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/user-weekly-hours.hbs"
    }
  });

  _exports.default = _default;
});