define("coffeecup/models/validation-log", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _superModel.default.extend({
    validation: belongsTo('validation'),
    author: belongsTo('user'),
    recipient: belongsTo('user'),
    message: attr('string'),
    isAutomatic: Ember.computed('author', function () {
      return Number(this.belongsTo('author').id) === -1;
    })
  });

  _exports.default = _default;
});