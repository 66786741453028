define("coffeecup/templates/components/onboarding/basic/done", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0N/hllXL",
    "block": "{\"symbols\":[\"@nextStep\"],\"statements\":[[10,\"div\"],[14,0,\"text-center w-100\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"onboarding-check-wrapper\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"onboarding-check primary-border-color primary-font-color\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"icomoon-checkmark\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"onboardingDone\"],null]],[13],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary done-btn btn-pill large-pill\"],[24,\"role\",\"button\"],[24,\"aria-pressed\",\"false\"],[24,4,\"button\"],[4,[38,1],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"letsGo\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/onboarding/basic/done.hbs"
    }
  });

  _exports.default = _default;
});