define("coffeecup/templates/components/dashboard-module/user-revenue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f09lZdgx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"effort-module\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"module-title\"],[12],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"title\",\"year\",\"draggable\",\"showDateRangePicker\",\"rangeTypeChanged\",\"from\",\"to\",\"actionNames\",\"startAction\"],[[30,[36,7],[\"performanceOverview\"],null],[35,6],[35,5],[35,4],[30,[36,3],[[32,0],\"rangeTypeChanged\"],null],[35,2],[35,1],[35,0],\"startAction\"]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"module-body\"],[12],[2,\"\\n    \"],[1,[30,[36,12],null,[[\"isLoading\",\"chartId\",\"legendItems\",\"height\"],[[35,11],[35,10],[35,9],255]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"actionNames\",\"to\",\"from\",\"action\",\"showGauge\",\"draggable\",\"year\",\"t\",\"dashboard-module/top-bar\",\"legendItems\",\"elementId\",\"isLoading\",\"bar-chart\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard-module/user-revenue.hbs"
    }
  });

  _exports.default = _default;
});