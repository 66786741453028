define("coffeecup/mixins/format-currency", ["exports", "coffeecup/config/environment", "numeral"], function (_exports, _environment, _numeral) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currencyIconClass: Ember.computed('Locale.current', function () {
      return this.getCurrencyIconClass();
    }),
    formatAsCurrencyDecimal: function formatAsCurrencyDecimal(val) {
      var currency = val || 0;
      var currencyFmt = (0, _numeral.default)(currency).format('0.00');
      return currencyFmt;
    },
    formatAsCurrencyThousand: function formatAsCurrencyThousand(val) {
      var currency = val || 0;
      var currencyFmt = (0, _numeral.default)(currency).format('0,0.00');
      return currencyFmt;
    },
    normalizeCurrency: function normalizeCurrency(val) {
      // Replace ensures that if the delimeter is a space, it is removed from formatted number
      var currentValue = (val || 0).toString().replace(/ /g, '');
      var result = parseFloat(this._normalizeDecimalDelimiter(currentValue));

      if (!result || isNaN(result)) {
        result = 0;
      }

      return Number(result.toFixed(4));
    },
    _normalizeDecimalDelimiter: function _normalizeDecimalDelimiter(val) {
      var unnormalizedValue = (val || 0).toString();
      var normalizedValue = unnormalizedValue.replace(',', '.');
      return normalizedValue;
    },
    cleanUpDelimiters: function cleanUpDelimiters(currentValue) {
      var result = currentValue;
      var decimals = 2;
      var re = /\.|,/;
      var disassembledValue = currentValue.split(re); //Correct the value if an second delimiter is set. Only possible by marking and replacing a part of the value in the input field.

      if ((disassembledValue.length - 1 || 0) > 1) {
        var correctedValue = disassembledValue.join('');
        result = correctedValue.slice(0, correctedValue.length - decimals) + '.' + correctedValue.slice(correctedValue.length - decimals, correctedValue.length);
      }

      return result;
    },
    getCurrencyAbbreviation: function getCurrencyAbbreviation() {
      return _numeral.default.localeData().currency.abbreviation;
    },
    getCurrencyIconClass: function getCurrencyIconClass() {
      var abbreviation = this.getCurrencyAbbreviation();
      var iconClass;

      switch (abbreviation) {
        case _environment.default.constants.CURRENCY_EURO:
          iconClass = 'icon-euro-currency-1';
          break;

        case _environment.default.constants.CURRENCY_POUND:
          iconClass = 'icon-pound-currency-1';
          break;

        case _environment.default.constants.CURRENCY_YEN:
          iconClass = 'icon-yen-currency-1';
          break;

        default:
          iconClass = 'icon-dollar-currency-1';
          break;
      }

      return iconClass;
    }
  });

  _exports.default = _default;
});