define("coffeecup/templates/components/input/cc-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "en1TdUaz",
    "block": "{\"symbols\":[\"@infoTooltip\",\"@leftAlign\",\"@label\",\"@translateKey\",\"@class\",\"@inputClass\",\"@value\",\"@disabled\",\"@indeterminate\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"checkbox \",[32,5]]]],[15,\"data-test\",[31,[\"checkbox-\",[32,4]]]],[12],[2,\"\\n  \"],[8,\"input\",[[16,0,[32,6]],[16,\"disabled\",[32,8]],[16,1,[30,[36,2],[[32,0],[32,4]],null]]],[[\"@type\",\"@checked\",\"@indeterminate\"],[\"checkbox\",[32,7],[32,9]]],null],[2,\"\\n  \"],[11,\"label\"],[16,\"for\",[31,[[30,[36,2],[[32,0],[32,4]],null]]]],[4,[38,3],[\"click\",[32,0,[\"handleClick\"]]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"space-left\"],[15,5,[30,[36,1],[[32,2],\"text-align: left\"],null]],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,4],[30,[36,0],[[32,4]],null]],null]],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"t-tip-icon\",[],[[\"@content\",\"@contentAsHTML\",\"@maxWidth\"],[[30,[36,0],[[32,1]],null],true,[32,0,[\"maxTooltipWidth\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"space-left\"],[15,5,[30,[36,1],[[32,2],\"text-align: left\"],null]],[12],[2,\"\\n        \"],[1,[32,3]],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"t-tip-icon\",[],[[\"@content\"],[[30,[36,0],[[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"guid-for\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/cc-checkbox.hbs"
    }
  });

  _exports.default = _default;
});