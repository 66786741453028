define("coffeecup/templates/components/planner/assignment-frame", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ef8/RkNK",
    "block": "{\"symbols\":[\"@assignment\"],\"statements\":[[8,\"tool-tipster\",[],[[\"@class\",\"@content\",\"@triggerEvent\",\"@side\",\"@contentAsHTML\"],[\"allocated-info\",[32,0,[\"tooltipContent\"]],\"hover\",\"bottom\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"b\"],[15,0,[31,[[30,[36,1],[[32,0,[\"showTotalAllocation\"]],\"text-center\"],null]]]],[12],[2,\"\\n      \"],[1,[32,0,[\"allocationPerPeriod\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"showTotalAllocation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"allocated-amount\"],[12],[2,\"\\n        \"],[1,[32,0,[\"totalAllocation\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,0,[\"editable\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"planner/split-overlay\",[],[[\"@assignment\",\"@offset\",\"@splitDate\",\"@onClick\"],[[32,1],[32,1,[\"startDate\"]],[32,1,[\"splitDate\"]],[30,[36,0],[[32,0],\"onSplit\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/assignment-frame.hbs"
    }
  });

  _exports.default = _default;
});