define("coffeecup/templates/components/dashboard/team-activity/header-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1VIDIZBg",
    "block": "{\"symbols\":[\"@showWeekends\"],\"statements\":[[10,\"div\"],[14,0,\"flex-row list-header-row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-2\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-1 hidden-xs\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"trackedTime\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"worktime-day-element text-uppercase text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"abbr.monday\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"worktime-day-element text-uppercase text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"abbr.tuesday\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"worktime-day-element text-uppercase text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"abbr.wednesday\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"worktime-day-element text-uppercase text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"abbr.thursday\"],null]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"worktime-day-element text-uppercase text-center\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"abbr.friday\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"worktime-day-element text-uppercase text-center\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"abbr.saturday\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"worktime-day-element text-uppercase text-center\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"abbr.sunday\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"hidden-xs\"],[12],[2,\"\\n    \"],[8,\"cc-dropdown\",[[24,0,\"invisible\"]],[[\"@icon\",\"@noBorder\",\"@smallSpace\"],[\"icomoon-more_horiz\",true,true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/team-activity/header-row.hbs"
    }
  });

  _exports.default = _default;
});