define("coffeecup/templates/components/dropdown-option-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4XDh8mN",
    "block": "{\"symbols\":[\"@disabled\",\"@hasSwitches\",\"@icon\",\"@remainOpen\",\"@handleClick\",\"&default\",\"@route\",\"@query\",\"@model\"],\"statements\":[[6,[37,0],[[32,7]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,9]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"link-to\",[[16,0,[31,[\"clearfix \",[30,[36,0],[[32,3],\"has-icon\"],null],\" \",[30,[36,0],[[32,2],\"has-switches\"],null],\" \",[30,[36,0],[[32,1],\"disabled\"],null]]]]],[[\"@route\",\"@model\",\"@query\"],[[32,7],[32,9],[32,8]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,6,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"link-to\",[[16,0,[31,[\"clearfix \",[30,[36,0],[[32,3],\"has-icon\"],null],\" \",[30,[36,0],[[32,2],\"has-switches\"],null],\" \",[30,[36,0],[[32,1],\"disabled\"],null]]]]],[[\"@route\",\"@query\"],[[32,7],[32,8]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[18,6,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"a\"],[24,6,\"#\"],[16,0,[31,[\"clearfix \",[30,[36,0],[[32,3],\"has-icon\"],null],\" \",[30,[36,0],[[32,2],\"has-switches\"],null],\" \",[30,[36,0],[[32,1],\"disabled\"],null]]]],[16,\"data-remain-open\",[30,[36,0],[[32,4],\"true\"],null]],[4,[38,1],[\"click\",[32,5]],null],[12],[2,\"\\n    \"],[18,6,null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[12],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dropdown-option-wrapper.hbs"
    }
  });

  _exports.default = _default;
});