define("coffeecup/validations/account-contact", ["exports", "ember-changeset-validations/validators", "coffeecup/validators/validateUnique"], function (_exports, _validators, _validateUnique) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      firstname: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      lastname: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      phone: {
        length: {
          maximum: 45
        }
      },
      street: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      postcode: {
        presence: true,
        length: {
          maximum: 12
        }
      },
      city: {
        presence: true,
        length: {
          maximum: 64
        }
      },
      country: {
        presence: true,
        length: {
          maximum: 64
        }
      },
      vat: {
        length: {
          maximum: 64
        }
      },
      billingEmail: {
        presence: true,
        length: {
          maximum: 128
        },
        Unique: true
      }
    },
    firstname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    lastname: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    phone: [(0, _validators.validateLength)({
      max: 45
    })],
    street: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    postcode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 12
    })],
    city: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 64
    })],
    country: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 64
    })],
    vat: [(0, _validators.validateLength)({
      max: 64
    })],
    billingEmail: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    }), (0, _validateUnique.default)(), (0, _validators.validateFormat)({
      type: 'email'
    })]
  };
  _exports.default = _default;
});