define("coffeecup/templates/components/input/area-radio-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k4129hJI",
    "block": "{\"symbols\":[\"@descriptionKey\",\"@infoTooltip\",\"@translateKey\",\"@name\",\"@checked\",\"@value\",\"@disabled\",\"@showArrow\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"radio-box-area \",[30,[36,2],[[32,0,[\"isChecked\"]],\"selected\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"radio\"],[12],[2,\"\\n    \"],[8,\"radio-button\",[[16,1,[30,[36,3],[[32,0],[32,3]],null]]],[[\"@name\",\"@checked\",\"@value\",\"@disabled\"],[[32,4],[32,5],[32,6],[32,7]]],null],[2,\"\\n    \"],[10,\"label\"],[15,\"for\",[31,[[30,[36,3],[[32,0],[32,3]],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"space-left\"],[12],[2,\"\\n          \"],[1,[30,[36,2],[[32,3],[30,[36,1],[[32,3]],null]],null]],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"t-tip-icon\",[],[[\"@content\"],[[30,[36,1],[[32,2]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"space-left radio-box-description\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[32,1]],null]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[32,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"radio-box-arrow \",[30,[36,0],[[32,0,[\"isChecked\"]],\"not-active\"],null]]]],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"icomoon-arrow-right3\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"unless\",\"t\",\"if\",\"guid-for\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/area-radio-box.hbs"
    }
  });

  _exports.default = _default;
});