define("coffeecup/transforms/features-unlocked", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UiState = Ember.Object.extend({
    featuresUnlocked: null,
    challengesUnlocked: Ember.computed('state.{}', {
      get: function get() {
        return this.get('state').challengesUnlocked || false;
      },
      set: function set(key, val) {
        this.get('state').challengesUnlocked = val;
        return val;
      }
    })
  });

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      if (value && typeof value.get !== 'undefined') {
        return JSON.stringify({
          challengesUnlocked: value.get('challengesUnlocked')
        });
      }
    },
    deserialize: function deserialize(value) {
      value = value || '{}';
      return UiState.create({
        state: JSON.parse(value)
      });
    }
  });

  _exports.default = _default;
});