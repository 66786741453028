define("coffeecup/templates/start/-resetpassword-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "589QPMBL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"box box-back\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"box-top\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"thankyou\"],null]],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\" \"],[13],[2,\"\\n    \"],[10,\"h4\"],[12],[1,[30,[36,0],[\"youWillBeLoggedIn\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"box-bottom success\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"resetPasswordSuccess\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/start/-resetpassword-back.hbs"
    }
  });

  _exports.default = _default;
});