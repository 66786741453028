define("coffeecup/templates/components/planner/planned-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QSN9HDM7",
    "block": "{\"symbols\":[\"week\",\"day\"],\"statements\":[[10,\"div\"],[14,0,\"week-grid capacity-grid\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"capacityGrid\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"timeline-week\"],[12],[2,\"\\n      \"],[10,\"ol\"],[14,0,\"timeline-week-days capacity-days\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"li\"],[14,0,\"timeline-day capacity-day planned-day\"],[15,5,[32,0,[\"cellWidth\"]]],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"capacity-bar-chart\"],[15,5,[32,2,[\"heightCss\"]]],[12],[13],[2,\"\\n            \"],[10,\"div\"],[15,0,[31,[\"capacity-container \",[32,0,[\"weeklyCapacityClass\"]]]]],[12],[2,\"\\n              \"],[10,\"span\"],[14,0,\"capacity-value\"],[12],[2,\"\\n                \"],[1,[32,2,[\"allocation\"]]],[2,\"\\n              \"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"showCapacit\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"capacity-slash\"],[12],[2,\"/\"],[13],[2,\"\\n                \"],[10,\"span\"],[14,0,\"capacity-planned\"],[12],[2,\"\\n                  \"],[1,[32,2,[\"capacity\"]]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n            \"],[8,\"planner/planner-tooltip\",[],[[\"@class\",\"@content\",\"@contentAsHTML\",\"@approxWidth\"],[\"capacity-tooltip-parent\",[32,2,[\"tooltipContent\"]],true,350]],null],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/planned-grid.hbs"
    }
  });

  _exports.default = _default;
});