define("coffeecup/models/user-assignment", ["exports", "coffeecup/config/environment", "ember-data", "coffeecup/models/-super-model"], function (_exports, _environment, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var UserAssignment = _superModel.default.extend({
    isProjectManager: _emberData.default.attr('boolean'),
    hourlyRate: _emberData.default.attr('number'),
    budgetHours: _emberData.default.attr('number'),
    // isReadOnly is private and will not be pushed to the api
    // it is used to make sure that a PM does not delete his own assignment
    isReadOnly: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    // relations
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    project: _emberData.default.belongsTo('project', {
      async: true
    }),
    //computed properties
    isActive: Ember.computed.and('user.isActive', 'project.isActive'),
    isInactive: Ember.computed.not('isActive'),
    status: Ember.computed('isActive', function () {
      return this.get('isActive') ? _environment.default.constants.STATUS_ENABLED : _environment.default.constants.STATUS_ARCHIVED;
    }).readOnly(),
    // events
    applyUserDefaults: function applyUserDefaults() {
      var user = this.get('user');

      if (user) {
        this.set('hourlyRate', user.get('hourlyRate'));
        this.set('isProjectManager', user.get('isProjectManager'));
      }
    },
    isValid: Ember.computed('validators.@each.isValid', {
      get: function get() {
        return this.get('validators').compact().filterBy('isValid', false).get('length') === 0;
      }
    }),
    clone: function clone() {
      return this.getProperties(['isProjectManager', 'hourlyRate', 'budgetHours', 'user', 'project']);
    }
  });

  var _default = UserAssignment;
  _exports.default = _default;
});