define("coffeecup/templates/components/analytics-module/analytics/worktime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4MXAaFfL",
    "block": "{\"symbols\":[\"@analytics\"],\"statements\":[[8,\"analytics-module/kpi/kpi-cell\",[],[[\"@amount\",\"@label\",\"@hasData\"],[[30,[36,0],[[32,1,[\"hoursTotal\"]],false,true,true],null],[30,[36,1],[\"budgetByTotalWorkTime\"],null],true]],null]],\"hasEval\":false,\"upvars\":[\"hours-fmt\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/analytics/worktime.hbs"
    }
  });

  _exports.default = _default;
});