define("coffeecup/utils/i18n/missing-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(locale, key, context) {
    var values = Object.keys(context).map(function (key) {
      return context[key];
    }); // TODO Log missing locales, @see CC-1379
    //return 't: ' + key + ': ' + (values.join(', '));

    if (Ember.typeOf(key) === 'number') {
      return key;
    }

    return 't: ' + key + (values.length > 0 ? ': ' + values.join(', ') : '');
  }
});