define("coffeecup/validations/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    inclusion: 'errors.inclusion',
    // '{description} is not included in the list',
    exclusion: 'errors.exclusion',
    // '{description} is reserved',
    invalid: 'errors.invalid',
    // '{description} is invalid',
    confirmation: 'errors.confirmation',
    // "{description} doesn't match {on}",
    accepted: 'errors.accepted',
    // '{description} must be accepted',
    empty: 'errors.empty',
    // "{description} can't be empty",
    blank: 'errors.blank',
    // '{description} must be blank',
    present: 'errors.present',
    // "{description} can't be blank",
    collection: 'errors.collection',
    // '{description} must be a collection',
    singular: 'errors.singular',
    // "{description} can't be a collection",
    tooLong: 'errors.tooLong',
    // '{description} is too long (maximum is {max} characters)',
    tooShort: 'errors.tooShort',
    // '{description} is too short (minimum is {min} characters)',
    between: 'errors.between',
    // '{description} must be between {min} and {max} characters',
    before: 'errors.before',
    // '{description} must be before {before}',
    onOrBefore: 'errors.onOrBefore',
    // '{description} must be on or before {onOrBefore}',
    after: 'errors.after',
    // '{description} must be after {after}',
    onOrAfter: 'errors.onOrAfter',
    // '{description} must be on or after {onOrAfter}',
    wrongDateFormat: 'errors.wrongDateFormat',
    // '{description} must be in the format of {format}',
    wrongLength: 'errors.wrongLength',
    // '{description} is the wrong length (should be {is} characters)',
    notANumber: 'errors.notANumber',
    // '{description} must be a number',
    notAnInteger: 'errors.notAnInteger',
    // '{description} must be an integer',
    greaterThan: 'errors.greaterThan',
    // '{description} must be greater than {gt}',
    greaterThanOrEqualTo: 'errors.greaterThanOrEqualTo',
    // '{description} must be greater than or equal to {gte}',
    equalTo: 'errors.equalTo',
    // '{description} must be equal to {is}',
    lessThan: 'errors.lessThan',
    // '{description} must be less than {lt}',
    lessThanOrEqualTo: 'errors.lessThanOrEqualTo',
    // '{description} must be less than or equal to {lte}',
    otherThan: 'errors.otherThan',
    // '{description} must be other than {value}',
    odd: 'errors.odd',
    // '{description} must be odd',
    even: 'errors.even',
    // '{description} must be even',
    positive: 'errors.positive',
    // '{description} must be positive',
    multipleOf: 'errors.multipleOf',
    // '{description} must be a multiple of {multipleOf}',
    date: 'errors.date',
    // '{description} must be a valid date',
    email: 'errors.email',
    // '{description} must be a valid email address',
    phone: 'errors.phone',
    // '{description} must be a valid phone number',
    url: 'errors.url' // '{description} must be a valid url'

  };
  _exports.default = _default;
});