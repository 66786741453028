define("coffeecup/templates/components/modals/billing-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N4SzEOXY",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,6]],null,[[\"default\"],[{\"statements\":[[6,[37,5],null,[[\"onClose\",\"targetAttachment\",\"containerClass\",\"translucentOverlay\"],[[30,[36,4],[[32,0],\"onHide\"],null],\"center\",\"modal-wide\",false]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"modal-wrapper scrollable modal-billing page-\",[34,0]]]],[12],[2,\"\\n      \"],[19,[35,1],[]],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"modal-loading-overlay\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"loading-container\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"loading-pulse\"],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"page\",\"activePartial\",\"isLoading\",\"if\",\"action\",\"modal-dialog\",\"openModal\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/modals/billing-project.hbs"
    }
  });

  _exports.default = _default;
});