define("coffeecup/templates/components/dashboard/team-hours/legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDwE58bq",
    "block": "{\"symbols\":[\"@teamWorkload\",\"@billableRatio\",\"@nonBillableRatio\"],\"statements\":[[10,\"div\"],[14,0,\"chart-legend\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[8,\"dashboard/team-hours/legend-item\",[[24,0,\"billable-difference\"]],[[\"@label\",\"@percentage\"],[[30,[36,0],[\"expectedRatio\"],null],[30,[36,1],[[32,1]],null]]],null],[2,\"\\n    \"],[8,\"dashboard/team-hours/legend-item\",[[24,0,\"billable\"]],[[\"@label\",\"@percentage\"],[[30,[36,0],[\"billable\"],null],[30,[36,1],[[32,2]],null]]],null],[2,\"\\n    \"],[8,\"dashboard/team-hours/legend-item\",[[24,0,\"non-billable\"]],[[\"@label\",\"@percentage\"],[[30,[36,0],[\"nonBillable\"],null],[30,[36,1],[[32,3]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"percent-fmt\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dashboard/team-hours/legend.hbs"
    }
  });

  _exports.default = _default;
});