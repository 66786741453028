define("coffeecup/validators/validateDailyHours", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDailyHours;

  function validateDailyHours(opts) {
    return function (key, newValue, oldValue, changes, model) {
      var workHoursType = changes['workHoursType'] || model.get('workHoursType');

      if (workHoursType === _environment.default.constants.WORK_HOURS_FIXED_DAY) {
        if (newValue > 24) {
          return 'Hours must be set for fixed work hours';
        }
      }

      return true;
    };
  }
});