define("coffeecup/templates/components/manage/expense-categories/icon-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pxNBrYAo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,5,[34,0]],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[[34,1]]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"style\",\"icon\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage/expense-categories/icon-cell.hbs"
    }
  });

  _exports.default = _default;
});