define("coffeecup/services/session", ["exports", "ember-simple-auth/services/session"], function (_exports, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _session.default.extend({
    /**
     * @param {String} url
     * @return {Ember.RSVP.Promise} A promise that resolves when the session was invalidated successfully and rejects otherwise
     * @public
     */
    getJSON: function getJSON(url) {
      return this.ajax({
        type: 'GET',
        url: url
      });
    },

    /**
     * @param {String} url
     * @param {} body
     * @return {Ember.RSVP.Promise} A promise that resolves when the session was invalidated successfully and rejects otherwise
     * @public
     */
    postJSON: function postJSON(url, body) {
      return this.ajax({
        type: 'POST',
        processData: false,
        data: JSON.stringify(body),
        contentType: 'application/json; charset=utf-8',
        url: url
      });
    },

    /**
     * @param {String} url
     * @param {} body
     * @return {Ember.RSVP.Promise} A promise that resolves when the session was invalidated successfully and rejects otherwise
     * @public
     */
    putJSON: function putJSON(url, body) {
      return this.ajax({
        type: 'PUT',
        processData: false,
        data: JSON.stringify(body),
        contentType: 'application/json; charset=utf-8',
        url: url
      });
    },

    /**
     * @param {} opts
     * @return {Ember.RSVP.Promise} A promise that resolves when the session was invalidated successfully and rejects otherwise
     * @public
     */
    ajax: function ajax(opts) {
      var session = this;
      opts = opts || {};
      return this.awaitAuthenticatorIdle().then(function () {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          return Ember.$.ajax(Ember.$.extend({
            type: 'GET',
            beforeSend: function beforeSend(xhr) {
              session.authorize('authorizer:oauth2', function (headerName, headerValue) {
                xhr.setRequestHeader(headerName, headerValue);
              });
            },
            cache: false,
            success: function success(data) {
              Ember.run(null, resolve, data);
            },
            error: function error(err) {
              Ember.run(null, reject, err);
            }
          }, opts));
        });
      });
    },

    /**
     * This method returns a promise that resovles when the current session authenticator considers itself idle.
     * When there is no authenticator, the promise resolves immediatly.
     */
    awaitAuthenticatorIdle: function awaitAuthenticatorIdle() {
      var name = this.get('session.authenticator');

      if (!name) {
        return Promise.resolve();
      }

      var authenticator = Ember.getOwner(this).lookup(name);

      if (!(authenticator && authenticator.awaitIdle)) {
        return Promise.resolve();
      }

      return authenticator.awaitIdle();
    }
  });

  _exports.default = _default;
});