define("coffeecup/templates/components/manage-table/cells/project-budget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bNnBagL0",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell\"],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"manage-table-cell-contents\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isProject\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[30,[36,1],[[32,1,[\"isModeNotBillable\"]],[32,1,[\"budgetHours\"]],[32,1,[\"amountBudgetTotal\"]]],null],[32,1,[\"isModeNotBillable\"]]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,0],[[32,0,[\"clientValue\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"project-board-fmt\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-budget.hbs"
    }
  });

  _exports.default = _default;
});