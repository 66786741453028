define("coffeecup/templates/components/planner/user-project-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lVmpP/Ud",
    "block": "{\"symbols\":[\"projectInSelect\"],\"statements\":[[10,\"div\"],[14,0,\"row-info\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"w-100\"],[12],[2,\"\\n\"],[6,[37,5],null,[[\"options\",\"selected\",\"showPlusIcon\",\"isSmall\",\"searchField\",\"placeholder\",\"onchange\"],[[35,4],[35,0],true,true,\"fullNameWithHiddenCodeAndClientHTML\",[30,[36,3],[\"assignToProject\"],null],[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,7],[[35,6,[\"showSummary\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row-summary\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"selectedProject\",\"mut\",\"action\",\"t\",\"projectsGrouped\",\"input/cc-select\",\"viewport\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/user-project-new.hbs"
    }
  });

  _exports.default = _default;
});