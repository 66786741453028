define("coffeecup/templates/components/pagination-sorter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yrJQAcr0",
    "block": "{\"symbols\":[\"dropdown\",\"sortOption\"],\"statements\":[[6,[37,10],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"span\"],[24,0,\"dropdown\"],[4,[38,0],[[32,0],\"empty\"],[[\"bubbles\"],[false]]],[12],[2,\"\\n    \"],[8,\"basic-dropdown\",[],[[\"@class\"],[\"dropdown\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1,[\"trigger\"]],[],[[\"@class\"],[\"btn btn-default btn-nobg\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[12],[1,[34,3]],[2,\"-\"],[1,[34,4]],[2,\" \"],[1,[30,[36,5],[\"of\"],null]],[2,\" \"],[1,[34,6]],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"ul\"],[24,0,\"dropdown-menu dropdown-menu-narrow\"],[4,[38,0],[[32,0],[32,1,[\"actions\",\"close\"]]],null],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,2],null,[[\"label\",\"value\",\"current\",\"action\"],[[32,2],[32,2],[35,1],[30,[36,0],[[32,0],\"setSortOption\"],null]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"sort\",\"dropdown-option\",\"from\",\"to\",\"t\",\"total\",\"sortOptions\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/pagination-sorter.hbs"
    }
  });

  _exports.default = _default;
});