define("coffeecup/routes/planner/projects/split", ["exports", "coffeecup/routes/planner/team/split"], function (_exports, _split) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _split.default.extend({
    parentRoute: 'planner.projects'
  });

  _exports.default = _default;
});