define("coffeecup/services/time-entry", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  /**
   * Service for complicated queries with Time Entries.
   *
   * @author Alexander Stonehouse
   */
  var TimeEntryService = Ember.Service.extend({
    currentUserService: Ember.inject.service('currentUser'),
    accountService: Ember.inject.service('account'),
    permissions: Ember.inject.service(),
    store: Ember.inject.service(),
    clock: Ember.inject.service(),
    _allTimeEntries: Ember.computed(function () {
      return this.get('store').peekAll('time-entry');
    }).readOnly(),
    _usersTimeEntries: Ember.computed.filter('_allTimeEntries', function (entry) {
      return this.get('currentUserService.currentUser.id') == entry.get('user.id');
    }).property('currentUserService.currentUser', '_allTimeEntries.@each.user'),
    _usersExistingTimeEntries: Ember.computed.filterBy('_usersTimeEntries', 'isNew', false),
    _usersRunningTimeEntries: Ember.computed.filterBy('_usersExistingTimeEntries', 'running', true),
    _usersTimeEntriesForToday: Ember.computed.filter('_usersExistingTimeEntries.@each.day', function (entry) {
      var now = this.get('clock.day');
      var day = entry.get('day');
      return now.isSame(day, 'day');
    }).property('_usersExistingTimeEntries.@each.day', 'clock.day'),
    _usersMostRecentlyUpdatedEntryForToday: Ember.computed('_usersTimeEntriesForToday', function () {
      var todaysTEs = this.get('_usersTimeEntriesForToday');

      if (todaysTEs.get('length') < 1) {
        return null;
      }

      return todaysTEs.reduce(function (prev, item) {
        var prevUpdatedAt = prev.get('updatedAt');
        var updatedAt = item.get('updatedAt');
        return moment(updatedAt).isAfter(prevUpdatedAt) ? item : prev;
      });
    }).readOnly(),
    _latestTimeEntry: null,
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    allowConcurrentTimeEntries: Ember.computed('currentUserService.currentUser.allowConcurrentTimeEntries', 'accountSettings.allowConcurrentTimeEntries', {
      get: function get() {
        return this.get('currentUserService.currentUser.allowConcurrentTimeEntries') && this.get('accountSettings.allowConcurrentTimeEntries');
      }
    }),
    findLatestTimeEntryForUser: function findLatestTimeEntryForUser() {
      var _this = this;

      var userId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var projectId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var limit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
      userId = userId || this.get('currentUserService').getCurrentUserId();
      var query = {
        sort: 'updatedAt DESC',
        limit: limit,
        where: {
          user: userId,
          trackingType: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK
        }
      };

      if (projectId) {
        query.where.project = projectId;
      }

      var latestTimeEntry = this.get('store').query('time-entry', query);
      var currentUserId = this.get('currentUserService').getCurrentUserId();

      if (userId === currentUserId) {
        // Cache latest if current user
        latestTimeEntry.then(function (latest) {
          _this.set('_latestTimeEntry', latest);
        });
      }

      return latestTimeEntry;
    },
    getLatestTimeEntriesWithCommentForUser: function getLatestTimeEntriesWithCommentForUser(term) {
      var query = {
        '!': ''
      };

      if (term) {
        query = {
          like: '%' + jQuery.trim(term) + '%'
        };
      }

      return this.get('store').query('time-entry', {
        sort: 'updatedAt DESC',
        limit: 50,
        where: {
          comment: query,
          user: this.get('currentUserService').getCurrentUserId(),
          trackingType: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK
        }
      });
    },
    findLastTimeEntryOfDayBySorting: function findLastTimeEntryOfDayBySorting(userId, day) {
      return this.get('store').query('time-entry', {
        sort: 'sorting DESC',
        limit: 1,
        where: {
          user: userId,
          day: moment(day).format(_environment.default.constants.DATE_FORMAT_DAY),
          trackingType: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK
        }
      });
    },
    peekLatestTimeEntry: function peekLatestTimeEntry() {
      return this.get('_latestTimeEntry');
    },

    /**
     * This is the time entry that we assume is the "most relevant" to the user right now.
     * Can be used for things like a quick start / stop functionality.
     */
    currentTimeEntry: Ember.computed.or('_usersRunningTimeEntries.firstObject', '_usersMostRecentlyUpdatedEntryForToday'),
    stopCurrentTimeEntry: function stopCurrentTimeEntry() {
      var timeEntry = this.get('currentTimeEntry');
      this.stopTimeEntry(timeEntry);
    },
    startCurrentTimeEntry: function startCurrentTimeEntry() {
      var timeEntry = this.get('currentTimeEntry');
      this.startTimeEntry(timeEntry);
    },
    saveTimeEntryAndKeepRunning: function saveTimeEntryAndKeepRunning(timeEntry) {
      if (!timeEntry.get('running')) {
        return timeEntry.save();
      }

      var timerModeIsStartTimeEndTime = this.get('accountSettings.timerModeIsStartTimeEndTime');

      if (timerModeIsStartTimeEndTime) {
        if (timeEntry.get('running') && timeEntry.get('endTime') && timeEntry.get('isToday')) {
          timeEntry.set('endTime', null);
        }
      }

      return timeEntry.save();
    },
    stopTimeEntry: function stopTimeEntry(timeEntry, opts) {
      opts = opts || {};

      if (timeEntry.get('running')) {
        var timerModeIsStartTimeEndTime = this.get('accountSettings.timerModeIsStartTimeEndTime');

        if (timerModeIsStartTimeEndTime && !opts.dontSetEndTime) {
          var now = this.get('clock.now').startOf('minute').format('HH:mm:ss');
          timeEntry.set('endTime', now);

          this._handleEndTimeIsEqualToStartTime(timeEntry);
        }

        timeEntry.set('running', false);

        if (!opts.noSave) {
          return timeEntry.save();
        }
      }

      if (!opts.noSave) {
        return timeEntry.save();
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        resolve(timeEntry);
      });
    },
    startTimeEntry: function startTimeEntry(entry) {
      var timeEntry;
      var timerModeIsStartTimeEndTime = this.get('accountSettings.timerModeIsStartTimeEndTime');

      if (timerModeIsStartTimeEndTime) {
        if (!entry.get('isNew')) {
          timeEntry = this.cloneTimeEntry(entry);
          var now = this.get('clock.now').startOf('minute').format('HH:mm:ss');
          timeEntry.set('startTime', now);
          timeEntry.set('endTime', null);
          timeEntry.set('durationRounded', null);
          timeEntry.set('running', true);
        } else {
          timeEntry = entry;
          timeEntry.set('running', true);
          timeEntry.set('endTime', null);
        }
      } else {
        timeEntry = entry;
        timeEntry.set('running', true);
      }

      var savePromise;

      if (this.get('allowConcurrentTimeEntries')) {
        savePromise = timeEntry.save();
      } else {
        savePromise = this.stopRunningTimeEntries(timeEntry.get('user.id')).then(function () {
          return timeEntry.save();
        }).catch(function (err) {
          timeEntry.rollbackAttributes();
          throw err;
        });
      }

      return savePromise;
    },
    cloneTimeEntry: function cloneTimeEntry(timeEntry) {
      var timeEntryDuplicate = this.get('store').createRecord('time-entry', timeEntry.clone());
      return timeEntryDuplicate;
    },
    setDuration: function setDuration(timeEntry, duration) {
      var timerModeIsStartTimeEndTime = this.get('accountSettings.timerModeIsStartTimeEndTime');

      if (timerModeIsStartTimeEndTime) {
        var startTimeAsSeconds = timeEntry.get('startTimeAsSeconds');
        var endTimeAsSeconds = startTimeAsSeconds + duration;

        if (endTimeAsSeconds >= 86400) {
          endTimeAsSeconds = endTimeAsSeconds - 86400;
        }

        if (endTimeAsSeconds === startTimeAsSeconds) {
          endTimeAsSeconds--;
        }

        timeEntry.set('endTimeAsSeconds', endTimeAsSeconds);
      } else {
        timeEntry.set('duration', duration);
      }
    },
    _handleEndTimeIsEqualToStartTime: function _handleEndTimeIsEqualToStartTime(timeEntry) {
      if (timeEntry.get('running')) {
        var startTimeDecimal = timeEntry.get('startTimeDecimal');
        var endTimeDecimal = timeEntry.get('endTimeDecimal');

        if (startTimeDecimal === endTimeDecimal) {
          var endTimeAsSeconds = Math.floor((endTimeDecimal * 3600 + 60) / 60) * 60;
          timeEntry.set('endTimeAsSeconds', endTimeAsSeconds);
        }
      }
    },

    /**
     * @param timeEntry
     * @returns {Promise}
     */
    beforeSave: function beforeSave(timeEntry) {
      var timerModeIsStartTimeEndTime = this.get('accountSettings.timerModeIsStartTimeEndTime');

      if (timerModeIsStartTimeEndTime) {
        timeEntry.set('sorting', null); // if the record has no start AND no endTime, we give him one

        if (!timeEntry.get('hasStartAndEndTime') && !timeEntry.get('isNew')) {
          var duration = timeEntry.get('duration');
          timeEntry.set('startTimeAsSeconds', 0);
          timeEntry.set('endTimeAsSeconds', duration);
        }

        this._handleEndTimeIsEqualToStartTime(timeEntry);

        if (timeEntry.get('running') && !timeEntry.get('isToday')) {
          timeEntry.set('running', false);
        }

        return new Ember.RSVP.Promise(function (resolve, reject) {
          resolve(timeEntry);
        });
      } else {
        if (!timeEntry.get('duration')) {
          timeEntry.set('duration', 0);
        }

        if (timeEntry.get('isNew') && !timeEntry.get('sorting')) {
          // if the timeentry has no sorting, we give him the next sorting of the timeentries of the day
          var userId = timeEntry.get('user.id');
          var day = timeEntry.get('day');
          return this.findLastTimeEntryOfDayBySorting(userId, day).then(function (result) {
            var sorting = result.get('firstObject.sortingOrStartTime') + 1;
            timeEntry.set('sorting', sorting);
            return new Ember.RSVP.Promise(function (resolve, reject) {
              resolve(timeEntry);
            });
          });
        } else {
          return new Ember.RSVP.Promise(function (resolve, reject) {
            resolve(timeEntry);
          });
        }
      }
    },

    /**
     * get promise of TimeEntry by ID or Object
     * @param timeEntry
     * @returns {Promise}
     */
    getTimeEntry: function getTimeEntry(timeEntry) {
      if (_typeof(timeEntry) === 'object') {
        return new Ember.RSVP.Promise(function (resolve, reject) {
          resolve(timeEntry);
        });
      } else {
        return this.get('store').findRecord('time-entry', timeEntry);
      }
    },

    /**
     * boolean promise checks if current logged in user is pm of timeEntry
     * also returns true if user is admin
     * @param timeEntry
     * @returns {Promise}
     */
    currentUserIsPmOfTimeEntry: function currentUserIsPmOfTimeEntry(timeEntry) {
      var _this2 = this;

      var userIsPM = false;
      var currentUser = this.get('currentUserService').getCurrentUser();
      var timeEntryPromise = this.getTimeEntry(timeEntry);
      var userAssignmentsPromise = timeEntryPromise.then(function (result) {
        return result.get('project.userAssignments');
      });
      var timeEntryProjectAssignmentsPromise = userAssignmentsPromise.then(function (userAssignments) {
        var userId = currentUser.get('id');

        if (userAssignments && typeof userAssignments.filter === 'function') {
          return userAssignments.filter(function (item) {
            return userId === item.get('user.id');
          });
        } else {
          return false;
        }
      });
      return timeEntryProjectAssignmentsPromise.then(function (userAssignment) {
        if (currentUser.get('isAdmin')) {
          userIsPM = true;
        } else if (_this2.get('permissions.canManageAllProjects')) {
          userIsPM = true;
        } else if (userAssignment) {
          userIsPM = userAssignment.get('firstObject.isProjectManager');
        }

        return userIsPM;
      });
    },
    isTimeEntryAssignedToUserProjects: function isTimeEntryAssignedToUserProjects(timeEntry) {
      var myProjectAssignments = this.get('currentUserService.currentUser').userAssignments;
      var assignedProject = myProjectAssignments.findBy('project.id', timeEntry.get('project.id'));
      var isAssignedToProject = typeof assignedProject !== 'undefined';
      return isAssignedToProject;
    },
    prepareNewTimeEntryFromLatest: function prepareNewTimeEntryFromLatest() {
      var _this3 = this;

      var userId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var projectId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var RECENT_ENTRIES_TO_SHOW = 4; // This is equally the same as the amount of "Recent Combinations" that we wanna show

      var store = this.get('store');
      var timerModeIsStartTimeEndTime = this.get('accountSettings.timerModeIsStartTimeEndTime');
      var latestTEsPromise = this.findLatestTimeEntryForUser(userId, projectId, RECENT_ENTRIES_TO_SHOW * 2);
      return latestTEsPromise.then(function (result) {
        var latestTimeEntry;
        var resultArrify = result.toArray();

        for (var i = 0; i < resultArrify.length; i++) {
          var isAssignedToProject = _this3.isTimeEntryAssignedToUserProjects(resultArrify[i]);

          if (isAssignedToProject) {
            latestTimeEntry = resultArrify[i];
            break;
          }
        }

        var timeEntry = store.createRecord('time-entry');
        var user = userId === false ? _this3.get('currentUserService').getCurrentUser() : store.peekRecord('user', userId);
        timeEntry.set('user', user);
        timeEntry.set('day', _this3.get('clock.now'));

        if (timerModeIsStartTimeEndTime) {
          var now = _this3.get('clock.now').startOf('minute').format('HH:mm:ss');

          timeEntry.set('startTime', now);
        } else {
          timeEntry.set('duration', 0);
        }

        if (latestTimeEntry) {
          var latestProject = latestTimeEntry.get('project');

          if (user.get('activeUserAssignments').isAny('project.id', latestProject.get('id')) && !latestProject.get('isInactive')) {
            timeEntry.set('project', latestProject);
            var latestTask = latestTimeEntry.get('task');

            if (latestProject.get('activeTaskAssignments').isAny('task.id', latestTask.get('id'))) {
              timeEntry.set('task', latestTask);
            }

            timeEntry.set('timeEntryReference', latestTimeEntry.get('timeEntryReference'));
          }
        }

        return timeEntry;
      });
    },
    stopRunningTimeEntries: function stopRunningTimeEntries(userId) {
      var _this4 = this;

      var store = this.get('store');
      var timerModeIsStartTimeEndTime = this.get('accountSettings.timerModeIsStartTimeEndTime');
      return store.query('time-entry', {
        where: {
          user: userId,
          running: true
        }
      }).then(function (runningTimeEntries) {
        return Ember.RSVP.all(runningTimeEntries.map(function (runningEntry) {
          runningEntry.set('running', false);

          if (timerModeIsStartTimeEndTime) {
            var now = _this4.get('clock.now').startOf('minute').format('HH:mm:ss');

            runningEntry.set('endTime', now);
          }

          return runningEntry.save().catch(function (err) {
            runningEntry.rollbackAttributes();
            throw err;
          });
        }));
      });
    },
    markAsBilled: function markAsBilled(timeEntry) {
      timeEntry.set('running', false);
      timeEntry.set('billedAt', this.get('clock.now').toDate());
      return this.beforeSave(timeEntry).then(function (timeEntry) {
        return timeEntry.save();
      });
    },
    markAsUnBilled: function markAsUnBilled(timeEntry) {
      timeEntry.set('running', false);
      timeEntry.set('billedAt', null);
      return this.beforeSave(timeEntry).then(function (timeEntry) {
        return timeEntry.save();
      });
    }
  });
  var _default = TimeEntryService;
  _exports.default = _default;
});