define("coffeecup/models/user-holiday", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    name: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    date: _emberData.default.attr('utc-moment'),
    type: Ember.computed('origin', {
      get: function get() {
        if (this.get('origin') === 2) {
          return 'freeday';
        } else {
          return 'holiday';
        }
      }
    }),
    isHoliday: Ember.computed('origin', {
      get: function get() {
        return this.get('origin') === 3;
      }
    }),
    origin: _emberData.default.attr('number'),
    isHolidayOnDay: function isHolidayOnDay(ts) {
      var timestamp = moment(ts);
      var date = this.get('date');
      return timestamp.isSame(date, 'day');
    }
  });

  _exports.default = _default;
});