define("coffeecup/initializers/glimmer-nested-transaction-error-handler", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * CC-2508
   * Reload app and try to log to Google Analytics when a Glimmer nested exception occures.
   */
  function windowErrorHandler(event) {
    if (event && event.message && event.message.indexOf('You may have a nested transaction') >= 0) {
      if (window.ga) {
        window.ga('send', 'event', 'error', window.location.pathname, event.message);
      }

      window.location.reload();
    }
  }

  var _default = {
    name: 'glimmer-nested-transaction-error-handler',
    initialize: function initialize() {
      if (_environment.default.environment === 'production') {
        window.addEventListener('error', windowErrorHandler);
      }
    }
  };
  _exports.default = _default;
});