define("coffeecup/templates/components/input/term-tag-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V0vFPAFB",
    "block": "{\"symbols\":[\"tag\",\"@classNames\"],\"statements\":[[8,\"input/cc-create-select-multiple\",[[4,[38,0],[[32,0,[\"onInsert\"]]],null]],[[\"@classNames\",\"@options\",\"@selected\",\"@searchField\",\"@placeholder\",\"@oncreate\",\"@createPlaceholder\",\"@showCreateWhen\",\"@suggestedOptionComponent\",\"@onchange\",\"@hideArrow\"],[[32,2],[32,0,[\"selectableTags\"]],[32,0,[\"selectedTags\"]],\"label\",[32,0,[\"placeholder\"]],[32,0,[\"createTag\"]],\"clickEnterToFilter\",[32,0,[\"canCreateTag\"]],\"input/cc-select/create-placeholder\",[32,0,[\"selectTag\"]],false]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/term-tag-search.hbs"
    }
  });

  _exports.default = _default;
});