define("coffeecup/services/client", ["exports", "coffeecup/mixins/create-short-tag"], function (_exports, _createShortTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_createShortTag.default, {
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    createDefaultClient: function createDefaultClient() {
      var client = this.get('store').createRecord('client');
      var clientName = this.get('i18n').t('newClient').toString();
      client.set('name', clientName);
      var codeAppendix = 1;
      var clientCode = this.createShortTag(clientName, 5, this.get('store').peekAll('client').getEach('code'), codeAppendix);
      client.set('code', clientCode);
      client.setupTrackerMetaData();
      return client.save();
    },
    copyClient: function copyClient(properties) {
      var copy = this.get('store').createRecord('client', properties);
      copy.set('name', '(COPY): ' + properties.name);
      var codeAppendix = 1;
      var clientCode = this.createShortTag(copy.get('name'), 5, this.get('store').peekAll('client').getEach('code'), codeAppendix);
      copy.set('code', clientCode);
      copy.setupTrackerMetaData();
      return copy.save();
    },
    copyClientContacts: function copyClientContacts(clients, copiedClient) {
      var _this = this;

      if (!clients || clients.length === 0) return Ember.RSVP.Promise.resolve();
      var promises = [];
      clients.forEach(function (client) {
        var clientCopy = _this.get('store').createRecord('contact', client.clone());

        clientCopy.set('client', copiedClient);
        promises.push(clientCopy.save());
      });
      return Ember.RSVP.all(promises);
    },
    getFirstClientByLabel: function getFirstClientByLabel() {
      return this.get('store').peekAll('client').filterBy('isActive').sortBy('label').get('firstObject');
    },
    clientsSortedAndGroupedByInactive: function clientsSortedAndGroupedByInactive(clients) {
      var clientsSorted = clients.sortBy('label');
      var activeClients = clientsSorted.filterBy('isActive');
      var inactiveClients = clientsSorted.filterBy('isInactive');

      if (inactiveClients.get('length') > 0) {
        return [Ember.Object.create({
          groupName: this.get('i18n').t('activeLabel'),
          options: activeClients
        }), Ember.Object.create({
          groupName: this.get('i18n').t('inactiveLabel'),
          options: inactiveClients
        })];
      } else {
        return activeClients;
      }
    }
  });

  _exports.default = _default;
});