define("coffeecup/models/user-score", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var UserScore = _superModel.default.extend({
    userId: _emberData.default.attr('number'),
    currentWeek: _emberData.default.attr('number'),
    currentMonth: _emberData.default.attr('number'),
    // relations
    user: _emberData.default.belongsTo('user', {
      async: false
    })
  });

  var _default = UserScore;
  _exports.default = _default;
});