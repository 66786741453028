define("coffeecup/templates/components/button-group-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zzOuKYKV",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[15,0,[31,[[34,1]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"icon\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/button-group-option.hbs"
    }
  });

  _exports.default = _default;
});