define("coffeecup/templates/components/input/cc-text-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hZ9Tn58R",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[15,0,[34,0]],[15,\"for\",[34,1,[\"elementId\"]]],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[34,4]],[15,\"data-test\",[34,5]],[12],[2,\"\\n  \"],[1,[30,[36,15],null,[[\"class\",\"viewName\",\"type\",\"value\",\"maxlength\",\"disabled\",\"placeholder\",\"readonly\",\"escape-press\",\"blur\",\"click\"],[[35,14],\"cctextfield\",[35,13],[35,12],[35,11],[35,10],[35,9],[35,8],\"cancel\",[35,7],[35,6]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"labelClasses\",\"cctextfield\",\"label\",\"if\",\"inputContainerClasses\",\"data-test\",\"onClick\",\"onBlur\",\"readonly\",\"placeholder\",\"disabled\",\"maxlength\",\"value\",\"type\",\"inputClasses\",\"input\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/cc-text-field.hbs"
    }
  });

  _exports.default = _default;
});