define("coffeecup/templates/components/color-picker-palette", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n7MGr2zz",
    "block": "{\"symbols\":[\"color\",\"index\",\"@selectedColor\",\"@class\",\"@colors\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"color-picker \",[32,4]]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"custom-icon-configurator/color-picker\",[],[[\"@index\",\"@color\",\"@selectedColor\",\"@selectColor\"],[[32,2],[32,1],[32,3],[30,[36,0],[[32,0],\"selectColor\",[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/color-picker-palette.hbs"
    }
  });

  _exports.default = _default;
});