define("coffeecup/templates/components/analytics-module/kpi/forecast", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zEOhB8Jt",
    "block": "{\"symbols\":[\"@analytics\",\"@hasData\",\"@isModeFixedBudget\"],\"statements\":[[8,\"analytics-module/kpi/kpi-cell\",[],[[\"@icon\",\"@amount\",\"@label\",\"@hasData\"],[\"icomoon-coin-euro\",[30,[36,1],[[32,1,[\"billableForecast\"]],false,true,true],null],[30,[36,0],[\"billableForecast\"],null],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"kpi-tooltip\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"billable\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"amountNotBilledTotal\"]],false,true],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"kpi-tooltip-row\"],[12],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"plannedBillable\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"plannedAmount\"]],false,true],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"kpi-tooltip-row summary-row\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"totalLabel\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"span\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[32,1,[\"billableForecast\"]],false,true],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"currency-fmt\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/kpi/forecast.hbs"
    }
  });

  _exports.default = _default;
});