define("coffeecup/models/goal/fixed-value", ["exports", "ember-data/attr", "ember-data-model-fragments/fragment"], function (_exports, _attr, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //models/analytics/client/histogram.js
  var _default = _fragment.default.extend({
    value: (0, _attr.default)('number'),
    source: (0, _attr.default)('string')
  });

  _exports.default = _default;
});