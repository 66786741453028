define("coffeecup/routes/account/billing", ["exports", "coffeecup/routes/_super-form-route"], function (_exports, _superFormRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superFormRoute.default.extend({
    accountService: Ember.inject.service('account'),
    stripeService: Ember.inject.service('stripe'),
    titleToken: function titleToken() {
      return this.get('i18n').t('billingSettings');
    },
    setupController: function setupController(controller, context, transition) {
      var accountSettings = this.accountService.get('accountSettings');

      if (accountSettings) {
        if (!accountSettings.get('firstAccountContact')) {
          var accountContact = this.store.createRecord('account-contact');
          accountContact.set('accountSettings', accountSettings);
          controller.set('accountContact', accountContact);
        } else {
          controller.set('accountContact', accountSettings.get('firstAccountContact'));
        }
      } else {
        console.error('No account settings...?');
      }

      controller.set('legacyInvoices', []);
      controller.set('subscription', context.subscriptions.firstObject);
      controller.set('plans', context.plans);
      controller.set('stripeInvoices', context.stripeInvoices);
      console.log(context.customerData);
      controller.set('customer', context.customers.firstObject); // Used for seat calculation

      controller.set('users', this.store.peekAll('user'));
    },
    model: function model() {
      return Ember.RSVP.hash({
        subscriptions: this.store.findAll('stripe/subscription'),
        plans: this.store.findAll('stripe/plan'),
        customers: this.store.findAll('stripe/customer'),
        stripeInvoices: this.store.findAll('stripe/invoice'),
        accountContacts: this.store.findAll('account-contact')
      });
    }
  });

  _exports.default = _default;
});