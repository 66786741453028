define("coffeecup/validations/team", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    validations: {
      name: {
        presence: true,
        length: {
          maximum: 128
        }
      },
      department: {
        length: {
          maximum: 128
        }
      },
      slogan: {
        length: {
          maximum: 30
        }
      },
      shortCode: {
        presence: true,
        length: {
          maximum: 10
        }
      }
    },
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 128
    })],
    department: [(0, _validators.validateLength)({
      max: 128
    })],
    slogan: [(0, _validators.validateLength)({
      max: 30
    })],
    shortCode: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 10
    })]
  };
  _exports.default = _default;
});