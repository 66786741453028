define("coffeecup/templates/components/account/billing/billing-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jJwXTEwb",
    "block": "{\"symbols\":[\"invoice\",\"@invoices\"],\"statements\":[[8,\"content-panel\",[[24,0,\"split-panel billing-history\"]],[[\"@headerLabel\"],[[30,[36,0],[\"billingHistory\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"flex-row header-row flex-bottom\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-2\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"billNumber\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-2\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"date\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-1\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"amount\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-col-4 hidden-xs\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"status\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex-col-1 hidden-xs\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"account/billing/invoice-row\",[],[[\"@invoice\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"flex-row horizontal-center space-top\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"noInvoicesYet\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"unless\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/account/billing/billing-history.hbs"
    }
  });

  _exports.default = _default;
});