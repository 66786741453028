define("coffeecup/models/time-entry", ["exports", "ember-data", "coffeecup/config/environment", "coffeecup/models/-super-model", "coffeecup/utils/format-duration"], function (_exports, _emberData, _environment, _superModel, _formatDuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RunningTimeEntries = {};

  var TimeEntry = _superModel.default.extend({
    currentUserService: Ember.inject.service('current-user'),
    formatterService: Ember.inject.service('formatter'),
    permissions: Ember.inject.service(),

    /**
     * trackingType is either WORK (default) or PAUSE
     */
    trackingType: _emberData.default.attr('string', {
      defaultValue: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK
    }),

    /**
     * {moment} day 2017-11-23
     */
    day: _emberData.default.attr('datestamp'),

    /**
     * {String} startTime 13:30:00
     */
    startTime: _emberData.default.attr('string'),

    /**
     * {String} endTime 15:45:00
     */
    endTime: _emberData.default.attr('string'),

    /**
     * {Number} duration in seconds
     */
    duration: _emberData.default.attr('number'),

    /**
     * {Number} sorting ASC
     */
    sorting: _emberData.default.attr('number'),

    /**
     * {Number} duration rounded based on selected project settings
     */
    durationRoundedOverride: _emberData.default.attr('number'),

    /**
     * {boolean} time-entry is currently running
     */
    running: _emberData.default.attr('boolean'),

    /**
     * {String} comment
     */
    comment: _emberData.default.attr('string'),

    /**
     * {Number} hourlyRate
     */
    hourlyRate: _emberData.default.attr('number'),

    /**
     * {boolean} billable
     */
    billable: _emberData.default.attr('boolean'),

    /**
     * {Date} billed at
     */
    billedAt: _emberData.default.attr('date'),

    /**
     * {Date} updated at
     */
    updatedAt: _emberData.default.attr('date'),
    validation: _emberData.default.belongsTo('validation'),
    // TODO: enable again {async: false}),

    /**
     * {boolean} isBilled
     */
    isBilled: Ember.computed('billedAt', {
      get: function get() {
        return !!this.get('billedAt');
      }
    }),

    /**
     * {boolean} isValidated
     */
    isValidated: Ember.computed('validation', 'permissions.worktimeValidationActive', {
      get: function get() {
        return !!this.get('validation.id') && this.get('permissions.worktimeValidationActive');
      }
    }),

    /**
     * {boolean} isProjectInactive
     */
    isProjectInactive: Ember.computed.reads('project.isInactive'),

    /**
     * {boolean} isTimeEntryLocked
     */
    isTimeEntryLocked: Ember.computed.reads('isBilled'),

    /**
     * {boolean} determining if all edit functions for this model are enabled or disabled
     */
    isEditDisabled: Ember.computed.or('isTimeEntryLocked', 'isProjectInactive'),

    /**
     * {boolean} isEditEnabled
     */
    isEditEnabled: Ember.computed.not('isEditDisabled'),
    // relations
    project: _emberData.default.belongsTo('project', {
      async: true
    }),
    task: _emberData.default.belongsTo('task', {
      async: true
    }),
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    team: _emberData.default.belongsTo('team', {
      async: true
    }),
    timeEntryReference: _emberData.default.belongsTo('time-entry-reference', {
      async: true
    }),
    invoice: _emberData.default.belongsTo('invoice', {
      async: true
    }),
    isBreak: Ember.computed.equal('trackingType', _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_BREAK),
    isWork: Ember.computed.equal('trackingType', _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK),
    finalHourlyRate: Ember.computed('billable', 'hourlyRate', function () {
      return this.get('billable') && this.get('hourlyRate') || 0;
    }),
    sortingOrStartTime: Ember.computed('sorting', 'startTimeAsSeconds', {
      get: function get() {
        var sorting = this.get('sorting');
        var startTimeAsSeconds = this.get('startTimeAsSeconds');
        return parseInt(sorting ? sorting : startTimeAsSeconds ? startTimeAsSeconds : 1, 10);
      }
    }),
    startTimeHM: Ember.computed('startTimeDecimal', function () {
      var startTimeDecimal = this.get('startTimeDecimal');

      if (!Ember.isEmpty(startTimeDecimal)) {
        return this.get('formatterService').formatDuration(startTimeDecimal, true, false, _environment.default.constants.DURATION_FORMAT_HM);
      }
    }),
    endTimeHM: Ember.computed('endTimeDecimal', function () {
      var endTimeDecimal = this.get('endTimeDecimal');

      if (!Ember.isEmpty(endTimeDecimal)) {
        return this.get('formatterService').formatDuration(endTimeDecimal, true, false, _environment.default.constants.DURATION_FORMAT_HM);
      }
    }),
    startTimeDecimal: Ember.computed('startTime', {
      get: function get() {
        var startTime = this.get('startTime');

        if (!Ember.isEmpty(startTime)) {
          var seconds = moment.duration(startTime).asSeconds();
          seconds = Math.floor(seconds / 60) * 60;
          return seconds / 3600;
        }
      },
      set: function set(key, hoursDecimal) {
        if (!Ember.isEmpty(hoursDecimal)) {
          hoursDecimal = parseFloat(hoursDecimal);
          var seconds = moment.duration(parseFloat(hoursDecimal), 'hours').asSeconds();
          var time = (0, _formatDuration.sec2time)(seconds, {
            hideSeconds: true
          });
          this.set('startTime', time);
        }

        return hoursDecimal;
      }
    }),
    endTimeDecimal: Ember.computed('endTime', {
      get: function get() {
        var endTime = this.get('endTime');

        if (!Ember.isEmpty(endTime)) {
          var seconds = moment.duration(endTime).asSeconds();
          seconds = Math.floor(seconds / 60) * 60;
          return seconds / 3600;
        }
      },
      set: function set(key, hoursDecimal) {
        if (!Ember.isEmpty(hoursDecimal)) {
          hoursDecimal = parseFloat(hoursDecimal);
          var seconds = moment.duration(parseFloat(hoursDecimal), 'hours').asSeconds();
          var time = (0, _formatDuration.sec2time)(seconds, {
            hideSeconds: true
          });
          this.set('endTime', time);
        }

        return hoursDecimal;
      }
    }),
    startTimeAsSeconds: Ember.computed('startTimeDecimal', {
      get: function get() {
        var startTimeDecimal = this.get('startTimeDecimal');
        return startTimeDecimal * 3600;
      },
      set: function set(key, val) {
        this.set('startTimeDecimal', val / 3600);
        return val;
      }
    }),
    endTimeAsSeconds: Ember.computed('endTimeDecimal', {
      get: function get() {
        var endTimeDecimal = this.get('endTimeDecimal');
        return endTimeDecimal * 3600;
      },
      set: function set(key, val) {
        this.set('endTimeDecimal', val / 3600);
        return val;
      }
    }),
    hasStartAndEndTime: Ember.computed.and('startTime', 'endTime'),
    userHasChangedTheDurationRounded: Ember.computed('durationRoundedOverride', {
      get: function get() {
        var durationRoundedOverride = this.get('durationRoundedOverride');
        return typeof durationRoundedOverride === 'number';
      }
    }),
    projectRoundingAmount: Ember.computed('project.roundingAmount', 'isBreak', function () {
      var projectRoundingAmount = this.get('project.roundingAmount');

      if (this.get('isBreak')) {
        return 0;
      } else {
        return projectRoundingAmount;
      }
    }),

    /**
     * {Number} duration rounded based on selected project settings
     */
    durationRounded: Ember.computed('duration', 'durationRoundedOverride', 'projectRoundingAmount', 'project.roundingType', {
      get: function get() {
        var durationRoundedOverride = this.get('durationRoundedOverride');
        var duration = this.get('duration');
        var projectRoundingType = this.get('project.roundingType');
        var projectRoundingAmount = this.get('projectRoundingAmount');

        if (this.get('userHasChangedTheDurationRounded')) {
          return durationRoundedOverride;
        }

        return (0, _formatDuration.getRoundedDuration)(duration, projectRoundingAmount, projectRoundingType);
      },
      set: function set(key, value) {
        var newDurationRoundedOverride = null;
        var duration = this.get('duration');
        var projectRoundingType = this.get('project.roundingType');
        var projectRoundingAmount = this.get('project.roundingAmount');
        var durationRounded = (0, _formatDuration.getRoundedDuration)(duration, projectRoundingAmount, projectRoundingType); // only set durationRoundedOverride if it's different from the auto-rounded duration

        if (typeof value === 'number') {
          value = 60 * Math.round(parseFloat(value) / 60);

          if (value != durationRounded) {
            newDurationRoundedOverride = value;
          }
        }

        this.set('durationRoundedOverride', newDurationRoundedOverride);
        return value;
      }
    }),
    durationRoundedAsHours: Ember.computed('durationRounded', {
      get: function get() {
        var durationRounded = this.get('durationRounded');

        if (Ember.isEmpty(durationRounded)) {
          return null;
        }

        return durationRounded / 3600;
      },
      set: function set(key, value) {
        this.set('durationRounded', Math.round(parseFloat(value) * 3600));
        return value;
      }
    }),
    durationRoundedOverrideAsHours: Ember.computed('durationRoundedOverride', {
      get: function get() {
        var durationRounded = this.get('durationRoundedOverride');

        if (Ember.isEmpty(durationRounded)) {
          return null;
        }

        return durationRounded / 3600;
      },
      set: function set(key, value) {
        this.set('durationRoundedOverride', Math.round(parseFloat(value) * 3600));
        return value;
      }
    }),
    durationAsHours: Ember.computed('duration', {
      get: function get() {
        var duration = this.get('duration');

        if (Ember.isEmpty(duration)) {
          return null;
        }

        return duration / 3600;
      },
      set: function set(key, value) {
        this.set('duration', Math.round(parseFloat(value) * 3600));
        return value;
      }
    }),
    // computed properties
    amount: Ember.computed('finalHourlyRate', 'billable', 'durationRounded', 'durationRoundedOverride', {
      get: function get() {
        var hourlyRate = this.get('finalHourlyRate') || 0;
        var billable = this.get('billable') ? 1 : 0;
        var durationRounded = this.get('durationRoundedAsHours');
        return hourlyRate * billable * durationRounded;
      }
    }),
    billableAmount: Ember.computed('amount', 'isBilled', {
      get: function get() {
        var amount = this.get('amount') || 0;
        var isBilled = this.get('isBilled') ? 0 : 1;
        return amount * isBilled;
      }
    }),
    dayFmt: null,
    _dayFmt: Ember.observer('day', function () {
      var day = this.get('day');
      this.set('dayFmt', moment(day).format(_environment.default.constants.DATE_FORMAT_DAY));
    }).on('init'),
    isToday: Ember.computed('dayFmt', 'clock.day', {
      get: function get() {
        var today = this.get('clock.localDayAsUtcMoment');
        var day = this.get('day');
        return today.isSame(day, 'day');
      }
    }),
    dayIndex: Ember.computed('dayFmt', {
      get: function get() {
        var day = this.get('day');
        return moment(day).weekday();
      }
    }),
    week: Ember.computed('dayFmt', {
      get: function get() {
        var day = this.get('day');
        return moment(day).startOf('week').format(_environment.default.constants.DATE_FORMAT_DAY);
      }
    }),
    taskLabel: Ember.computed('task', 'project', 'currentUserService.currentUser.uiState.timeEntryLabelStyle', function () {
      var currentUser = this.get('currentUserService.currentUser');

      var labelStyle = currentUser.get('uiState.timeEntryLabelStyle') || _environment.default.constants.LONG_TIME_ENTRY_LABEL;

      var task = this.get('task');
      var project = this.get('project');
      var client = this.get('project.client');
      var clientName = client && client.get('id') ? client.get('label') : this.get('i18n').t('internal');
      labelStyle = labelStyle.replace('%taskShort%', task ? task.get('code') : '');
      labelStyle = labelStyle.replace('%projectShort%', project ? project.get('code') : '');
      labelStyle = labelStyle.replace('%task%', task ? task.get('label') : '');
      labelStyle = labelStyle.replace('%project%', project ? project.get('label') : '');
      labelStyle = labelStyle.replace('%client%', clientName ? clientName : '');
      return labelStyle;
    }),
    clone: function clone() {
      return this.getProperties(['trackingType', 'day', 'sorting', 'startTime', 'endTime', 'duration', 'durationRoundedOverride', 'running', 'comment', 'hourlyRate', 'billable', 'billedAt', 'isBilled', 'project', 'task', 'user']);
    },
    // events

    /**
     * Reloads currently running time-entries every 60 seconds
     * @private
     */
    _reloadRunningTimeEntry: function _reloadRunningTimeEntry(seconds) {
      var _this = this;

      seconds = 60 || seconds;

      this._cancelRunningTimeEntry();

      RunningTimeEntries[this.get('id')] = setInterval(function () {
        Ember.run(function () {
          var isClientOffline = navigator && navigator.onLine === false;

          if (!isClientOffline) {
            if (!_this.get('isDestroyed') && !_this.get('isDeleted') && _this.get('running') == true) {
              _this.reload();
            } else {
              _this._cancelRunningTimeEntry();
            }
          }
        });
      }, seconds * 1000); //every 60 seconds
    },
    _cancelRunningTimeEntry: function _cancelRunningTimeEntry() {
      if (RunningTimeEntries[this.get('id')]) {
        clearInterval(RunningTimeEntries[this.get('id')]);
      }
    },
    didLoad: Ember.computed('isLoaded', function () {
      var _this2 = this;

      Ember.run.later(this, function () {
        if (_this2.get('running') == true) {
          Ember.run.once(_this2, '_reloadRunningTimeEntry');
        }
      }, 500);

      this._super.apply(this, arguments);
    })
  });

  var _default = TimeEntry;
  _exports.default = _default;
});