define("coffeecup/templates/components/planner/user-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x8+rjhDk",
    "block": "{\"symbols\":[],\"statements\":[[8,\"planner/user-project-info\",[],[[\"@editable\",\"@project\",\"@user\",\"@customAction\",\"@showTimeEntries\"],[[32,0,[\"editable\"]],[32,0,[\"project\"]],[32,0,[\"user\"]],\"customAction\",[30,[36,2],[[32,0],[35,1]],null]]],null],[2,\"\\n\"],[6,[37,0],[[32,0,[\"isSetup\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,0,[\"viewport\",\"showSummary\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"planner/user-project-summary\",[],[[\"@project\",\"@user\",\"@roundedHoursPlanned\",\"@tooltipText\"],[[32,0,[\"project\"]],[32,0,[\"user\"]],[32,0,[\"userRoundedHoursPlanned\"]],[32,0,[\"userSummaryTooltip\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[8,\"planner/user-project-timeline\",[],[[\"@user\",\"@assignments\",\"@project\",\"@editable\",\"@splitAssignment\",\"@editAssignment\",\"@afterUpdateAssignment\",\"@createAssignment\"],[[32,0,[\"user\"]],[32,0,[\"projectUserAssignments\"]],[32,0,[\"project\"]],[32,0,[\"editable\"]],\"splitAssignment\",\"editAssignment\",\"afterUpdateAssignment\",\"createAssignment\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"showTimeEntries\",\"action\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/user-project.hbs"
    }
  });

  _exports.default = _default;
});