define("coffeecup/templates/projects/new-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LV4qoDR2",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],null,[[\"targetAttachment\",\"overlayPosition\",\"translucentOverlay\"],[\"center\",\"sibling\",false]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"modal-dialog\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/projects/new-loading.hbs"
    }
  });

  _exports.default = _default;
});