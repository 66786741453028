define("coffeecup/routes/application", ["exports", "ember-data", "coffeecup/config/environment", "ember-simple-auth/mixins/application-route-mixin", "coffeecup/mixins/modals"], function (_exports, _emberData, _environment, _applicationRouteMixin, _modals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ApplicationRoute = Ember.Route.extend(_applicationRouteMixin.default, _modals.default, {
    responsive: Ember.inject.service(),
    analytics: Ember.inject.service(),
    router: Ember.inject.service(),
    currentUserService: Ember.inject.service('currentUser'),
    isConnected: Ember.computed.alias('sailsSocket.isConnected'),
    timeEntryService: Ember.inject.service('time-entry'),
    projectService: Ember.inject.service('project'),
    renderTemplate: function renderTemplate(controller, model) {
      this._super.apply(this, arguments);

      this.render('modal/alert', {
        into: 'application',
        outlet: 'modal-alert',
        controller: 'modal/alert'
      });
      this.render('modal/team-reasignment', {
        into: 'application',
        outlet: 'modal-team-reasignment',
        controller: 'modal/team-reasignment'
      });
      this.render('modal/not-enough-seats', {
        into: 'application',
        outlet: 'modal-not-enough-seats',
        controller: 'modal/not-enough-seats'
      });
      this.render('modal/confirm', {
        into: 'application',
        outlet: 'modal-confirm',
        controller: 'modal/confirm'
      });
      this.render('modal/new-client', {
        into: 'application',
        outlet: 'modal-new-client',
        controller: 'modal/new-client'
      });
      this.render('modal/new-task', {
        into: 'application',
        outlet: 'modal-new-task',
        controller: 'modal/new-task'
      });
    },
    setupController: function setupController(controller, context, transition) {
      if (context && context.menu) {
        controller.set('menu', context.menu);
      }

      controller.set('isMobile', this.get('responsive').isMobile());

      this._super(controller, context);
    },

    /**
     * Used for the browser title
     * @param tokens
     * @returns {string}
     */
    title: function title(tokens) {
      var companyName = this.get('controller.accountSettings.companyName');
      var base = 'CoffeeCup';
      var titleSuffix = (companyName ? companyName + ' | ' : '') + base;
      var hasTokens = tokens && tokens.length;
      return (hasTokens ? tokens.join(' > ') + ' | ' : '') + titleSuffix;
    },

    /**
     * @temporary
     * @see: CC-1172
     * TODO: refactor this temporary workaround
     *
     * @private
     */
    _reloadRunningTimeentries: Ember.observer('session.isDisconnected', function () {
      if (this.get('session.isDisconnected') === false) {
        if (this.get('session.isAuthenticated')) {
          this.store.query('time-entry', {
            limit: 100,
            sort: 'day DESC',
            where: {
              running: 1
            }
          });
        }
      }
    }),
    // _handleSailsConnection: Ember.on('init', function() {
    //
    //   let sailsSocket = this.get('sailsSocket');
    //
    //   // Server is temporarily unavailable.
    //   sailsSocket.on('didDisconnect', this, function() {
    //
    //     this.set('session.isDisconnected', true);
    //
    //     Ember.run.later(() => {
    //       if (this.get('session.isDisconnected')) {
    //         this.set('controller.serverAlertType', 'danger');
    //         this.set('controller.serverAlertMsg', 'serverOffline');
    //         this.set('controller.serverAlertBlocks', true);
    //         this.set('controller.serverAlertReload', false);
    //         this.set('controller.serverAlertActive', true);
    //       }
    //     }, 10000); // show offline alert only after being offline for at least 10 seconds
    //
    //   });
    //   // Server did reconnect
    //   sailsSocket.on('didConnect', this, function() {
    //
    //     if (this.get('session.isDisconnected')) {
    //
    //       if (this.get('session.isAuthenticated')) {
    //         let authenticator = this.get('oauth-authenticator');
    //         let refresh_token = this.get('session.refresh_token');
    //         let expires_in = this.get('session.expires_in');
    //
    //         authenticator.refreshAccessToken(expires_in, refresh_token).then(()=> {
    //           this.set('session.isDisconnected', false);
    //           this.set('controller.serverAlertActive', false);
    //         }).catch(() => {
    //           try {
    //             this.send('invalidateSession');
    //           }
    //           catch(e) {}
    //         });
    //       }
    //       else {
    //         try {
    //           this.send('invalidateSession');
    //         }
    //         catch(e) {}
    //       }
    //     }
    //     else {
    //       try {
    //         this.set('controller.serverAlertActive', false);
    //       }
    //       catch(e){}
    //     }
    //
    //     Ember.$( "#maintainer" ).empty();
    //   });
    //
    //   // show maintenance screen if socket is not online after 10 seconds
    //   Ember.run.later(this, function() {
    //     if (!this.get('isConnected')) {
    //       Ember.$( "#maintainer" ).load( "assets/offline.html" );
    //     }
    //   }, 10000);
    // }),
    _handleSailsRestartAlerts: Ember.on('init', function () {
      this.sailsSocket.on('serverRestart', this, function (data) {
        var _this = this;

        Ember.run.later(function () {
          data = data || {};

          _this.set('controller.serverAlertType', typeof data.type !== 'undefined' ? data.type : 'info');

          _this.set('controller.serverAlertMsg', typeof data.msg !== 'undefined' ? data.msg : 'serverRestartAlert');

          _this.set('controller.serverAlertBlocks', typeof data.blocks !== 'undefined' ? data.blocks : false);

          _this.set('controller.serverAlertReload', typeof data.reload !== 'undefined' ? data.reload : true);

          _this.set('controller.serverAlertActive', true);
        }, Math.floor(Math.random() * 25000 + 5000)); // show reload alert after 5-30 seconds
      });
      this.sailsSocket.listenFor('serverRestart', true);
    }),
    _handleSocketUpdates: Ember.on('init', function () {
      var sailsSocket = this.get('sailsSocket');
      sailsSocket.on('accountsetting.updated', this, function (context) {// context && context.data && this.set('controller.accountSettings', context.data);
      });
      sailsSocket.on('timeentry.created', this, function (context) {
        var controller = this.controllerFor('time-entries.tracking');

        if (controller && context && context.id) {
          var id = '' + context.id;
          var timeEntries = controller.get('timeEntries');

          if (timeEntries && !timeEntries.findBy('id', id)) {
            this.store.find('time-entry', id).then(function (newEntry) {
              controller.send('timeEntryCreated', {
                entry: newEntry
              });
            });
          }
        }
      });
      sailsSocket.on('timeentry.destroyed', this, function (context) {
        var controller = this.controllerFor('time-entries.tracking');

        if (controller && context.id) {
          var id = '' + context.id;
          var timeEntries = controller.get('timeEntries');

          if (timeEntries) {
            var deletedEntry = timeEntries.findBy('id', id);

            if (deletedEntry) {
              controller.send('timeEntryDeleted', {
                entry: deletedEntry
              });
            }
          }
        }
      });
      sailsSocket.on('absence.created', this, function (context) {
        var controller = this.controllerFor('time-entries.tracking');

        if (controller && context && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absences');

          if (absences && !absences.findBy('id', id)) {
            this.store.find('absence', id).then(function (newEntry) {
              var currentUserId = controller.get('user'); // Need to make sure time entry is relevant to currently displayed user

              if (newEntry.get('user.id') === currentUserId) {
                controller.send('absenceCreated', {
                  entry: newEntry
                });
              }
            });
          }
        }
      });
      sailsSocket.on('absence.updated', this, function (context) {
        var controller = this.controllerFor('time-entries.tracking');

        if (controller && context && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absences');

          if (absences && !absences.findBy('id', id)) {
            this.store.find('absence', id).then(function (newEntry) {
              var currentUserId = controller.get('user'); // Need to make sure time entry is relevant to currently displayed user

              if (newEntry.get('user.id') === currentUserId) {
                controller.send('absenceCreated', {
                  entry: newEntry
                });
              }
            });
          }
        }
      });
      sailsSocket.on('absence.destroyed', this, function (context) {
        var controller = this.controllerFor('time-entries.tracking');

        if (controller && context.id) {
          var id = '' + context.id;
          var timeEntries = controller.get('absences');

          if (timeEntries) {
            var deletedEntry = timeEntries.findBy('id', id);

            if (deletedEntry) {
              controller.send('absenceDeleted', {
                entry: deletedEntry
              });
            }
          }
        }
      });
      sailsSocket.on('userassignment.created', this, function (context) {
        if (context && context.data && context.data.user === this.get('currentUserService.currentUser.idAsInt')) {
          this.store.findAll('task');
          this.store.findAll('task-assignment');
        }
      });
    }),
    model: function model() {
      var hashToResolve = {
        menu: this.store.peekAll('menu'),
        // fixtures
        countries: this.store.peekAll('country'),
        // fixtures
        currencies: this.store.peekAll('currency'),
        // fixtures
        timezones: this.store.peekAll('timezone'),
        // fixtures
        timeEntryColorOptions: this.store.peekAll('time-entry-color-option'),
        // fixtures,
        languages: this.store.peekAll('language'),
        // fixtures
        dateFormats: this.store.peekAll('date-format'),
        // fixtures
        beginningOfWeeks: this.store.peekAll('beginning-of-week'),
        // fixtures
        expenseTypeIcons: this.store.peekAll('expense-type-icon'),
        // fixtures
        wordingOptions: this.store.peekAll('wording-option'),
        // fixtures
        currencyFormats: this.store.peekAll('currency-format'),
        // fixtures
        numberFormats: this.store.peekAll('number-format'),
        // fixtures
        timeRoundingTypes: this.store.peekAll('time-rounding-type'),
        // fixtures
        absenceTypes: this.store.peekAll('absence-type'),
        // fixtures
        insuranceTypes: this.store.peekAll('insurance-type') // fixtures

      };
      return Ember.RSVP.hash(hashToResolve);
    },

    /**
     This method handles the session's
     {{#crossLink "SessionService/authenticationSucceeded:event"}}{{/crossLink}}
     event. If there is a transition that was previously intercepted by the
     {{#crossLink "AuthenticatedRouteMixin/beforeModel:method"}}
     AuthenticatedRouteMixin's `beforeModel` method{{/crossLink}} it will retry
     it. If there is no such transition, the `ember_simple_auth-redirectTarget`
     cookie will be checked for a url that represents an attemptedTransition
     that was aborted in Fastboot mode, otherwise this action transitions to the
     {{#crossLink "Configuration/routeAfterAuthentication:property"}}{{/crossLink}}.
       @method sessionAuthenticated
     @public
     */
    sessionAuthenticated: function sessionAuthenticated() {
      var attemptedTransition = this.get('session.attemptedTransition');
      var cookies = getOwner(this).lookup('service:cookies');
      var redirectTarget = cookies.read('ember_simple_auth-redirectTarget');
      var reLoginForTransition = cookies.read('reLoginForTransition');
      var reLoginForUser = cookies.read('reLoginForUser');

      if (reLoginForTransition) {
        cookies.clear('reLoginForTransition');
        cookies.clear('reLoginUsername');
        cookies.clear('reLoginForUser');
        cookies.write('oneTimeAccessForTransition', reLoginForTransition, {
          path: '/'
        });
        this.transitionTo(reLoginForTransition, reLoginForUser);
      } else if (attemptedTransition) {
        attemptedTransition.retry();
        this.set('session.attemptedTransition', null);
      } else if (redirectTarget) {
        this.transitionTo(redirectTarget);
        cookies.clear('ember_simple_auth-redirectTarget');
      } else {
        this.transitionTo(this.get('routeAfterAuthentication'));
      }
    },

    /**
     This method handles the session's
     {{#crossLink "SessionService/invalidationSucceeded:event"}}{{/crossLink}}
     event. __It reloads the Ember.js application__ by redirecting the browser
     to the application's root URL so that all in-memory data (such as Ember
     Data stores etc.) gets cleared.
      If the Ember.js application will be used in an environment where the users
     don't have direct access to any data stored on the client (e.g.
     [cordova](http://cordova.apache.org)) this action can be overridden to e.g.
     simply transition to the index route.
      @method sessionInvalidated
     @public
     */
    sessionInvalidated: function sessionInvalidated() {
      if (!Ember.testing) {
        if (this.get('session._stopSessionInvalidation')) {
          this.set('session._stopSessionInvalidation', null);
          return;
        }

        this._super.apply(this, arguments);
      }
    },
    activeOutlet: null,
    actions: {
      error: function error(_error, transition) {
        if (_error) {
          this.get('analytics').notifyError(_error, false, transition);
        } // STRING


        if (typeof _error === 'string') {
          _error = {
            message: _error,
            status: 0
          };
        } // Ember AdapterError
        else if (_error instanceof _emberData.default.AdapterError) {
            _error = _error.errors[0];
            _error.message = _error.title;
          }

        switch ('' + _error.status) {
          case '0':
            this.get('notifications').error('generalError', '');
            break;

          case '403':
          case '404':
            this.get('notifications').errorWithObject(_error, _error.status);
            this.transitionTo('index');
            break;
        }

        transition.abort();
        return true;
      },
      loading: function loading(transition, originRoute) {
        var controller = this.controllerFor('application');
        controller.set('isLoading', true);
        transition.promise.finally(function () {
          controller.set('isLoading', false);
          controller.set('isFirstLoading', false);
        });
        return true;
      },

      /**
       * edit a specific user
       * @param userid The id of the user
       */
      editUser: function editUser(userid) {
        var route = 'team.users.edit';
        this.get('router').transitionTo(route, userid);
      },

      /**
       * edit a specific task
       * @param taskid The id of the task
       */
      editTask: function editTask(taskid) {
        var route = 'projects.tasks.edit';
        this.get('router').transitionTo(route, taskid);
      },

      /**
       * edit a specific project
       * @param projectId The id of the project
       */
      editProject: function editProject(projectId) {
        var route = 'projects.edit';
        this.get('router').transitionTo(route, projectId);
      },

      /**
       * edit project or client
       * @param model
       */
      edit: function edit(model) {
        var modelType = model.get('constructor.modelName');
        var route = 'manage.' + modelType + 's.edit';
        this.get('router').transitionTo(route, model.id);
      },

      /**
       * goto client report
       * @param item The project of the client
       */
      showClientReport: function showClientReport(item) {
        var clientId = parseInt(item.get('client.id'));
        this.get('router').transitionTo('projects.clients.analytics', clientId);
      },

      /**
       * show timeentries for project, task, user or client
       * @param {DS} item
       * @param {String} from
       * @param {String} to
       * @param {boolean} all
       */
      showTimeEntries: function showTimeEntries(item, from, to) {
        var _this2 = this;

        var all = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        var prm = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

        var transitionToAnalytics = function transitionToAnalytics(params) {
          // Next workarounds some TransitionAbortedError that I couldn't figure out
          Ember.run.next(function () {
            _this2.get('router').transitionTo('analytics.time-entries', {
              queryParams: params
            });
          });
        };

        var id = item && item.get('idAsInt');
        var modelType = item && item.get('constructor.modelName');

        var params = _objectSpread(_objectSpread({}, {
          client: [],
          project: [],
          user: [],
          task: [],
          team: [],
          timeEntryReference: [],
          status: _environment.default.constants.SHOW_ALL,
          from: this.get('clock.now').startOf('month').format(_environment.default.constants.DATE_FORMAT_DAY),
          to: this.get('clock.now').endOf('month').format(_environment.default.constants.DATE_FORMAT_DAY)
        }), prm);

        if (from && to) {
          params.from = from;
          params.to = to;
        }

        if (modelType == 'project') {
          params.project = [id];
        } else if (modelType == 'client') {
          params.client = [id];
        } else if (modelType == 'user') {
          params.user = [id];
        } else if (modelType == 'task') {
          params.task = [id];
        } else if (modelType == 'time-entry-reference') {
          params.timeEntryReference = [id];
        } else if (modelType == 'team') {
          params.team = [id];
        }

        if (all === true) {
          switch (modelType) {
            case 'project':
            case 'client':
              var _p = {
                histogramInterval: 'month',
                histogramType: 'total'
              };
              _p[modelType] = item.get('id');
              this.store.query("analytics/".concat(modelType), _p).then(function () {
                var _item$get, _item$get2;

                params.from = (_item$get = item.get('analytics.firstMonthInDayFormat')) !== null && _item$get !== void 0 ? _item$get : _this2.get('clock.now').startOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
                params.to = (_item$get2 = item.get('analytics.lastMonthInDayFormat')) !== null && _item$get2 !== void 0 ? _item$get2 : _this2.get('clock.now').endOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
                transitionToAnalytics(params);
              });
              break;

            default:
              params.from = this.get('clock.now').startOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
              params.to = this.get('clock.now').endOf('year').format(_environment.default.constants.DATE_FORMAT_DAY);
              transitionToAnalytics(params);
              break;
          }
        } else {
          transitionToAnalytics(params);
        }
      },
      editTimeEntry: function editTimeEntry(timeEntry) {
        var _this3 = this;

        this.render('time-entries.tracking.edit', {
          into: 'application',
          outlet: 'modal-time-entry',
          model: timeEntry
        });
        this.set('activeOutlet', 'modal-time-entry');
        Ember.run.next(function () {
          _this3.controllerFor('time-entries.tracking.edit').set('focus', 'comment');

          _this3.controllerFor('time-entries.tracking.edit').set('openModal', true);
        });
      },
      createTimeEntry: function createTimeEntry() {
        var _this4 = this;

        this.get('timeEntryService').prepareNewTimeEntryFromLatest().then(function (timeEntry) {
          _this4.render('time-entries.tracking.edit', {
            into: 'application',
            outlet: 'modal-time-entry',
            model: timeEntry
          });

          _this4.set('activeOutlet', 'modal-time-entry');

          Ember.run.next(function () {
            _this4.controllerFor('time-entries.tracking.edit').set('focus', 'project');

            _this4.controllerFor('time-entries.tracking.edit').set('openModal', true);
          });
        });
      },
      billProject: function billProject(projectId, startDate, endDate, amount) {
        var _this5 = this;

        var invoice = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
        var page = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 'settlement';
        var controller = this.controllerFor('application');
        this.render('analytics.projects.billing', {
          into: 'application',
          outlet: 'modal-bill-project'
        });
        this.set('activeOutlet', 'modal-bill-project');
        Ember.run.next(function () {
          if (projectId) {
            _this5.controllerFor('analytics.projects.billing').set('selectedProject', _this5.store.peekRecord('project', projectId));
          }

          _this5.controllerFor('analytics.projects.billing').set('allProjects', controller.get('allProjects'));

          _this5.controllerFor('analytics.projects.billing').set('from', (startDate ? startDate : _this5.get('clock.month').subtract(1, 'month').startOf('month')).format(_environment.default.constants.DATE_FORMAT_DAY));

          _this5.controllerFor('analytics.projects.billing').set('to', (endDate ? endDate : _this5.get('clock.month').subtract(1, 'month').endOf('month')).format(_environment.default.constants.DATE_FORMAT_DAY));

          _this5.controllerFor('analytics.projects.billing').set('fixedBilledAmount', amount || 0);

          _this5.controllerFor('analytics.projects.billing').set('billTimeEntries', true);

          if (page !== 'settlement' && invoice !== null) {
            _this5.controllerFor('analytics.projects.billing').set('showInvoiceSummary', true);
          }

          _this5.controllerFor('analytics.projects.billing').set('createdInvoice', invoice);

          _this5.controllerFor('analytics.projects.billing').set('page', page);

          _this5.controllerFor('analytics.projects.billing').set('openModal', true);
        });
      },
      completeProject: function completeProject(projectId, projectState) {
        var _this6 = this;

        this.get('projectService').getProjectAndAnalytics(projectId).then(function (context) {
          _this6.render('analytics.projects.details.complete', {
            into: 'application',
            outlet: 'modal-project-complete'
          });

          _this6.set('activeOutlet', 'modal-project-complete');

          Ember.run.next(function () {
            _this6.controllerFor('analytics.projects.details.complete').set('selectedProject', context.selectedProject);

            _this6.controllerFor('analytics.projects.details.complete').set('selectedProjectState', projectState);

            _this6.controllerFor('analytics.projects.details.complete').set('projectStates', _this6.store.peekAll('project-state'));

            _this6.controllerFor('analytics.projects.details.complete').set('selectedProjectAnalytics', context.selectedProjectAnalytics.get('firstObject'));
          });
        });
      },
      transitionAway: function transitionAway() {
        var activeOutlet = this.get('activeOutlet');

        if (activeOutlet) {
          this.disconnectOutlet({
            outlet: activeOutlet.toString(),
            parentView: 'application'
          });
          this.set('activeOutlet', null);
        }
      },
      transitionToList: function transitionToList() {
        this.send('transitionAway');
      },
      refreshModel: function refreshModel() {
        this.refresh();
      },
      invalidateSession: function invalidateSession() {
        this.get('analytics').stop();
        var applicationController = this.controllerFor('application');
        applicationController.set('currentUser', null);
        this.get('currentUserService').updateCurrentUser(null);

        this._super();
      },
      didTransition: function didTransition() {
        var controller = this.controllerFor('application'); // close mobile menu after transition

        controller.set('mobileMenuOpen', false);
      }
    }
  });
  var _default = ApplicationRoute;
  _exports.default = _default;
});