define("coffeecup/mixins/cascade-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // http://thejsguy.com/2017/02/10/cascade-deleting-relationships-in-ember-data.html
  // The example code doesn't quite work (maybe related to our ember version?). I've fixed the code
  // branch for 'hasMany' relations and removed the 'belongsTo' path, because we're not using it atm
  var _default = Ember.Mixin.create({
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var recordsToUnload = []; // collect all records to unload into recordsToUnload variable

      snapshot.record.eachRelationship(function (name, descriptor) {
        var options = descriptor.options,
            kind = descriptor.kind;
        var relationshipName = descriptor.key;

        if (options.cascadeDelete && kind === 'hasMany') {
          var hasManySnapshots = snapshot.hasMany(relationshipName);

          if (hasManySnapshots) {
            var hasManyRecords = hasManySnapshots.map(function (snapshot) {
              return snapshot.record;
            });
            recordsToUnload = recordsToUnload.concat(hasManyRecords);
          }
        }
      });
      return this._super.apply(this, arguments).then(function (response) {
        recordsToUnload.forEach(function (childRecord) {
          store.unloadRecord(childRecord);
        });
        return response;
      });
    }
  });

  _exports.default = _default;
});