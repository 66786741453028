define("coffeecup/templates/components/planner/planner-assignment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HWSjiQIu",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],null,[[\"assignment\",\"isActive\",\"editable\",\"useProjectColor\",\"onClick\",\"onMoved\",\"onSplit\"],[[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"onSplit\",\"onMoved\",\"onClick\",\"useProjectColor\",\"editable\",\"isActive\",\"assignment\",\"planner/assignment-frame\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/planner-assignment.hbs"
    }
  });

  _exports.default = _default;
});