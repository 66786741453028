define("coffeecup/models/billing/invoice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    formatter: Ember.inject.service(),
    accountService: Ember.inject.service('account'),
    dueDate: _emberData.default.attr('date'),
    invoiceUrl: _emberData.default.attr('string'),
    recurring: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    priceType: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    total: _emberData.default.attr('number'),
    amountPaid: _emberData.default.attr('number'),
    paidAt: _emberData.default.attr('date'),
    creditsApplied: _emberData.default.attr('number'),
    amountDue: _emberData.default.attr('number'),
    currencyCode: _emberData.default.attr('string'),
    paid: Ember.computed.equal('status', 'paid'),
    displayName: Ember.computed.alias('id'),
    totalComputed: Ember.computed('total', 'creditsApplied', function () {
      var total = this.get('total');
      var creditsApplied = this.get('creditsApplied') || 0;
      return total - creditsApplied;
    }),
    totalFmt: Ember.computed('totalComputed', 'currencySign', function () {
      var formattedPrice = this.get('formatter').formatNumber(this.get('totalComputed') / 100, '0.00');
      return formattedPrice + ' ' + this.get('currencySign');
    }),
    currencySign: Ember.computed('currencyCode', function () {
      var currencyCode = this.get('currencyCode');
      var currency = currencyCode && this.get('store').peekRecord('currency', currencyCode);
      if (!currency) currency = this.get('accountService.accountSettings.currency');
      return currency.get('sign');
    })
  });

  _exports.default = _default;
});