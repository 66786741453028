define("coffeecup/utils/environment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEnvStaging = _exports.isEnvTest = _exports.isEnvBeta = _exports.isEnvDevelopment = void 0;

  var isEnvDevelopment = function isEnvDevelopment(environment) {
    return environment === 'development';
  };

  _exports.isEnvDevelopment = isEnvDevelopment;

  var isEnvBeta = function isEnvBeta(environment) {
    return environment === 'beta';
  };

  _exports.isEnvBeta = isEnvBeta;

  var isEnvTest = function isEnvTest(environment) {
    return environment === 'test';
  };

  _exports.isEnvTest = isEnvTest;

  var isEnvStaging = function isEnvStaging(environment) {
    return environment === 'staging';
  };

  _exports.isEnvStaging = isEnvStaging;
});