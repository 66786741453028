define("coffeecup/routes/admin", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //
  var AdminRoute = _superRoute.default.extend({
    afterModel: function afterModel() {
      this._super.apply(this, arguments);

      this.transitionTo('admin.general');
    }
  });

  var _default = AdminRoute;
  _exports.default = _default;
});