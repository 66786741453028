define("coffeecup/templates/components/manage/projects/project-state-pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Uy6ZurF",
    "block": "{\"symbols\":[],\"statements\":[[11,\"button\"],[16,0,[31,[\"manage-table-pill \",[32,0,[\"pillClass\"]],\" \",[30,[36,0],[[32,0,[\"showPopover\"]],\"modal-reference\"],null]]]],[16,5,[32,0,[\"pillStyle\"]]],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"togglePopover\"]]],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"pill-label\"],[12],[2,\"\\n    \"],[1,[32,0,[\"pillLabel\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"showPopover\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"manage-table/cells/project-state-popover\",[],[[\"@openModal\",\"@onHide\",\"@onStateClick\",\"@projectStates\"],[[32,0,[\"showPopover\"]],[32,0,[\"togglePopover\"]],[32,0,[\"handleStateClick\"]],[32,0,[\"projectStates\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage/projects/project-state-pill.hbs"
    }
  });

  _exports.default = _default;
});