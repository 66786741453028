define("coffeecup/transforms/hours-as-seconds", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      // what the client expects
      if (!Ember.isEmpty(serialized)) {
        serialized /= 36;
        serialized = Math.round(serialized); // enforce double digit precision

        serialized /= 100;
      }

      return !isNaN(serialized) ? serialized : null;
    },
    serialize: function serialize(deserialized) {
      if (!Ember.isEmpty(deserialized)) {
        deserialized = Math.round(3600 * deserialized);
      }

      return !isNaN(deserialized) ? deserialized : null;
    }
  });

  _exports.default = _default;
});