define("coffeecup/templates/components/input/cc-checkbox-with-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e1GyWMLA",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"value\",\"translateKey\",\"infoTooltip\",\"onChange\"],[[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onConfirm\"],null]]]]],[2,\"\\n\"],[1,[30,[36,7],null,[[\"showModal\",\"modalOptions\",\"onHide\"],[[35,6],[35,5],[30,[36,0],[[32,0],\"hideModal\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"infoTooltip\",\"translateKey\",\"value\",\"input/cc-checkbox\",\"modalOptions\",\"showModal\",\"modals/confirm-change\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input/cc-checkbox-with-confirm.hbs"
    }
  });

  _exports.default = _default;
});