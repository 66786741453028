define("coffeecup/templates/components/analytics-module/user/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Etg0L6HY",
    "block": "{\"symbols\":[\"documentsGroup\",\"document\"],\"statements\":[[6,[37,9],[[30,[36,12],[[35,11],[35,10,[\"length\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"analytics-module\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"module-title\"],[12],[2,\"\\n      \"],[1,[30,[36,7],null,[[\"draggable\",\"title\"],[false,[30,[36,0],[\"documents\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"module-body scrollable\"],[12],[2,\"\\n\"],[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,6],null,[[\"label\"],[[30,[36,0],[\"loading\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"entity-list\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,5]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,1,[\"children\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"              \"],[1,[30,[36,2],null,[[\"document\",\"readOnly\",\"onDownloadAttachment\"],[[32,2],true,[30,[36,1],[[32,0],\"downloadAttachment\"],null]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]},{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"h-100 flex-center\"],[12],[2,\"\\n              \"],[10,\"div\"],[14,0,\"list-replace text-center\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"entityHasNotDocumentsYet\"],null]],[2,\"\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"document-list/row\",\"-track-array\",\"each\",\"documentsGrouped\",\"loading-indicator\",\"dashboard-module/top-bar\",\"isLoading\",\"if\",\"documents\",\"documentUploadActive\",\"some\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/analytics-module/user/documents.hbs"
    }
  });

  _exports.default = _default;
});