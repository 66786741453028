define("coffeecup/models/analytics/time-entry", ["exports", "ember-data", "coffeecup/models/-super-model"], function (_exports, _emberData, _superModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var floorToMinutes = function floorToMinutes(hours) {
    return Math.floor(hours * 60) / 60;
  };

  var AnalyticsTimeEntry = _superModel.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    hoursTotal: _emberData.default.attr('number'),
    hoursSpent: _emberData.default.attr('number'),
    hoursRoundedTotal: _emberData.default.attr('number'),
    hoursNonBillable: _emberData.default.attr('number'),
    hoursBudget: _emberData.default.attr('number'),
    hoursOutOfBudget: _emberData.default.attr('number'),
    hoursBilled: _emberData.default.attr('number'),
    amountSpent: _emberData.default.attr('number'),
    amountBudget: _emberData.default.attr('number'),
    amountOutOfBudget: _emberData.default.attr('number'),
    amountBilled: _emberData.default.attr('number'),
    groupedBy: _emberData.default.attr(),
    // computed properties
    secondsTotal: Ember.computed('hoursTotal', {
      get: function get() {
        return this.get('hoursTotal') * 3600;
      },
      set: function set(key, value) {
        this.set('hoursTotal', parseFloat(value) / 3600);
      }
    }),
    secondsSpent: Ember.computed('hoursSpent', {
      get: function get() {
        return this.get('hoursSpent') * 3600;
      },
      set: function set(key, value) {
        this.set('hoursSpent', parseFloat(value) / 3600);
      }
    }),
    secondsNonBillable: Ember.computed('hoursNonBillable', {
      get: function get() {
        return this.get('hoursNonBillable') * 3600;
      },
      set: function set(key, value) {
        this.set('hoursNonBillable', parseFloat(value) / 3600);
      }
    }),
    hoursNotBilled: Ember.computed('hoursSpent', 'hoursBilled', 'hoursOutOfBudget', {
      get: function get() {
        var hoursSpent = this.get('hoursSpent');
        var hoursOutOfBudget = this.get('hoursOutOfBudget');
        var hoursBilled = this.get('hoursBilled');
        return hoursSpent - hoursOutOfBudget - hoursBilled;
      }
    }),
    amountNotBilled: Ember.computed('amountSpent', 'amountBilled', function () {
      var amountSpent = this.get('amountSpent') || 0;
      var amountBilled = this.get('amountBilled') || 0;
      var totalAmount = Math.round(amountSpent * 1000) / 1000 - Math.round(amountBilled * 1000) / 1000;
      return Math.max(0, totalAmount);
    }),
    amountInBudget: Ember.computed('amountSpent', 'amountOutOfBudget', function () {
      var amountSpent = this.get('amountSpent') || 0;
      var amountOutOfBudget = this.get('amountOutOfBudget') || 0;
      var amountInBudget = Math.round(amountSpent * 1000) / 1000 - Math.round(amountOutOfBudget * 1000) / 1000;
      return Math.max(0, amountInBudget);
    }),
    amountNotBilledInBudget: Ember.computed('amountNotBilled', 'amountOutOfBudget', function () {
      var amountNotBilled = this.get('amountNotBilled') || 0;
      var amountOutOfBudget = this.get('amountOutOfBudget') || 0;
      var amountNotBilledInBudget = Math.round(amountNotBilled * 1000) / 1000 - Math.round(amountOutOfBudget * 1000) / 1000;
      return Math.max(0, amountNotBilledInBudget);
    }),
    // hoursBillable: (2:16)
    // (hoursTotal - hoursNonBillable)
    hoursBillable: Ember.computed('hoursTotal', 'hoursNonBillable', function () {
      var hoursTotal = this.get('hoursTotal') || 0;
      var hoursNonBillable = this.get('hoursNonBillable') || 0;
      return hoursTotal - hoursNonBillable;
    }),
    // hoursBillableInPercent:
    // (hoursTotal - hoursNonBillable) / hoursTotal (11.2 - 9) / 11.2
    hoursBillableInPercent: Ember.computed('hoursBillable', 'hoursTotal', function () {
      var hoursBillable = this.get('hoursBillable') || 0;
      var hoursTotal = this.get('hoursTotal') || 0;
      return floorToMinutes(hoursBillable) / floorToMinutes(hoursTotal);
    }),
    // billableSurplus ( :44)
    // (hoursSpent - hoursBillable)
    billableSurplus: Ember.computed('hoursSpent', 'hoursBillable', function () {
      var hoursSpent = this.get('hoursSpent') || 0;
      var hoursBillable = this.get('hoursBillable') || 0;
      return hoursSpent - hoursBillable;
    }),
    isBillableSurplusPositive: Ember.computed('hoursSpent', 'hoursBillable', function () {
      return this.get('billableSurplus') >= 0;
    })
  });

  var _default = AnalyticsTimeEntry;
  _exports.default = _default;
});