define("coffeecup/services/validation", ["exports", "coffeecup/utils/build-url", "coffeecup/config/environment"], function (_exports, _buildUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    openValidationWeeks: null,
    numberOfOpenValidations: 0,
    checkForInvalidValidations: function checkForInvalidValidations(startDate, endDate, restrictOnAssignedUsers) {
      var options = {};
      if (startDate) options.startDate = startDate;
      if (endDate) options.endDate = endDate;
      options.restrictOnAssignedUsers = restrictOnAssignedUsers;
      return this.get('session').getJSON((0, _buildUrl.default)(this.get('store'), 'validate/invalid', options));
    },
    loadRemainingValidations: function loadRemainingValidations(week) {
      return this.get('store').queryRecord('validation', {
        where: {
          week: week
        }
      });
    },
    checkUserWorktime: function checkUserWorktime(startDate, endDate, users) {
      var options = {
        startDate: startDate,
        endDate: endDate
      };
      if (users) options.users = users;
      return this.get('session').getJSON((0, _buildUrl.default)(this.get('store'), 'validate/checkUserWorktime', options));
    },
    loadPendingValidations: function loadPendingValidations(startDate, endDate, restrictOnAssignedUsers, teamId) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this.checkForInvalidValidations(startDate, endDate, restrictOnAssignedUsers).then(function (rawDataValid) {
          var weekKeys = Object.keys(rawDataValid.weeks);
          var weekKey = weekKeys[0];
          var weekData = rawDataValid.weeks[weekKey];

          if (weekData && weekData.openUsers && weekData.openUsers.get('length') > 0) {
            weekData.userObjs = weekData.openUsers.map(function (user) {
              return _this.get('store').peekRecord('user', user);
            });
            var firstStartDate = weekKey;
            var firstEndDate = moment(weekKey).endOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY);

            _this.loadRemainingValidations(weekKey).then(function () {
              _this.checkUserWorktime(firstStartDate, firstEndDate, JSON.stringify(weekData.openUsers)).then(function (rawUserData) {
                var weekData = {
                  startDate: firstStartDate,
                  endDate: firstEndDate
                };
                var userData = rawUserData.userData.map(function (user) {
                  user.user = _this.get('store').peekRecord('user', user.id);
                  user.validation = user.isAlreadyValidated ? _this.get('store').peekRecord('validation', user.isAlreadyValidated) : null;
                  user.holidays = _this.get('store').peekAll('user-holiday').filter(function (el) {
                    return el.get('date').isSameOrAfter(startDate, 'day') && el.get('date').isSameOrBefore(endDate, 'day');
                  });
                  return user;
                }).filter(function (userData) {
                  return !teamId || userData.user.get('team.id') === teamId;
                });
                weekData.userData = userData;
                var rawData = {};

                if (weekData && weekData.userData && weekData.userData.length > 0) {
                  rawData[firstStartDate] = weekData;
                }

                delete rawDataValid.weeks[weekKey];
                resolve({
                  weekData: rawData,
                  openWeeks: rawDataValid
                });
              });
            }).catch(reject);
          } else {
            resolve({
              loadPreviousWeek: true
            });
          }
        }).catch(reject);
      });
    },
    loadRejectedValidations: function loadRejectedValidations(startDate, endDate) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('store').query('validation', {
          where: {
            week: startDate,
            state: _environment.default.constants.VALIDATION_REJECTED
          }
        }).then(function () {
          _this2.checkUserWorktime(startDate, endDate).then(function (rawUserData) {
            var weekData = {
              startDate: startDate,
              endDate: endDate
            };
            var userData = rawUserData.userData.filter(function (user) {
              return !!user.isAlreadyValidated && _this2.get('store').peekRecord('validation', user.isAlreadyValidated) && _this2.get('store').peekRecord('validation', user.isAlreadyValidated).get('isRejected');
            });

            if (userData && userData.length > 0) {
              userData = userData.map(function (user) {
                user.user = _this2.get('store').peekRecord('user', user.id);
                user.validation = user.isAlreadyValidated ? _this2.get('store').peekRecord('validation', user.isAlreadyValidated) : null;
                return user;
              });
              weekData.userData = userData;
              resolve({
                weekData: weekData,
                weekKey: startDate
              });
            } else {
              resolve({
                loadPreviousWeek: true
              });
            }
          }).catch(reject);
        }).catch(reject);
      });
    },
    updateOpenValidationCount: function updateOpenValidationCount(openValidationWeeks) {
      this.set('openValidationWeeks', openValidationWeeks);

      if (openValidationWeeks) {
        var numberOfOpenValidations = 0;
        var weekKeys = Object.keys(openValidationWeeks.weeks);
        weekKeys.forEach(function (weekKey) {
          numberOfOpenValidations += openValidationWeeks.weeks[weekKey].openUsers.length;
        });

        if (numberOfOpenValidations > 99) {
          numberOfOpenValidations = '99+';
        }

        this.set('numberOfOpenValidations', numberOfOpenValidations);
      } else {
        this.set('numberOfOpenValidations', 0);
      }
    }
  });

  _exports.default = _default;
});