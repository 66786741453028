define("coffeecup/templates/components/dropdown-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pWvglxHB",
    "block": "{\"symbols\":[\"@route\",\"@model\",\"@query\",\"@disabled\",\"@hasSwitches\",\"@smallIcon\",\"@untranslatedLabel\",\"@key\"],\"statements\":[[8,\"dropdown-option-wrapper\",[],[[\"@route\",\"@model\",\"@query\",\"@remainOpen\",\"@hasSwitches\",\"@handleClick\",\"@icon\",\"@disabled\"],[[32,1],[32,2],[32,3],[30,[36,0],[[32,4],[32,0,[\"remainOpen\"]]],null],[32,5],[30,[36,1],[[32,0],\"handleClick\"],null],[32,0,[\"icon\"]],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"dropdown-option-content\",[],[[\"@icon\",\"@smallIcon\",\"@iconName\",\"@isChecked\",\"@label\",\"@untranslatedLabel\",\"@key\"],[[32,0,[\"icon\"]],[32,6],[32,0,[\"iconName\"]],[32,0,[\"isChecked\"]],[32,0,[\"label\"]],[32,7],[32,8]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"action\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/dropdown-option.hbs"
    }
  });

  _exports.default = _default;
});