define("coffeecup/mixins/form-validate-submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * boolean, true when the form is being submitted
     */
    submitting: false,

    /**
     * disables the submit button when the form is not valid or it's currently being submitted
     * @return boolean
     */
    submitIsDisabled: Ember.computed('submitting', 'formIsInvalid', function () {
      var submitting = this.get('submitting');
      var formIsInvalid = this.get('formIsInvalid');
      return submitting || formIsInvalid;
    }),
    formIsInvalid: Ember.computed.not('form.isValid')
  });

  _exports.default = _default;
});