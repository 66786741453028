define("coffeecup/mixins/buffered-form", ["exports", "ember-changeset", "ember-changeset-validations"], function (_exports, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     * create buffered-proxy with ember-changeset
     */
    form: Ember.computed('model', function () {
      if (!this.get('model')) return;

      if (this.get('formValidation')) {
        return (0, _emberChangeset.Changeset)(this.get('model'), (0, _emberChangesetValidations.default)(this.get('formValidation')), this.get('formValidation'));
      } else {
        if (false
        /* DEBUG */
        ) {
          console.warn('Using a buffered form without validation!');
        }

        return (0, _emberChangeset.Changeset)(this.get('model'));
      }
    }),

    /**
     * Checks if the form has changes
     * @return boolean true when changes were made
     */
    hasChanges: Ember.computed.alias('form.hasChanges'),

    /**
     * Name (key) of input field to put focus on
     */
    focus: null
  });

  _exports.default = _default;
});