define("coffeecup/mixins/modals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /**
     *
     * @param opts { icon, title, text }
     * @private
     */
    _showAlert: function _showAlert(opts) {
      var modalController = this.controllerFor('modal.alert');
      modalController.set('showModal', true);
      modalController.set('model', {
        icon: opts.icon,
        title: opts.title,
        text: opts.text
      });
      modalController.set('openModal', true);
    },

    /**
     *
     * @param opts { icon, title, text, items[] }
     * @param callback
     * @private
     */
    _showConfirm: function _showConfirm(opts, callback) {
      var modalController = this.controllerFor('modal.confirm');
      modalController.set('showModal', true);
      modalController.set('model', {
        icon: opts.icon,
        title: opts.title,
        text: opts.text,
        items: opts.items,
        closeBtnText: opts.closeBtnText,
        confirmBtnText: opts.confirmBtnText,
        confirmCallback: function confirmCallback() {
          callback();
        },
        action: callback
      });
      modalController.set('openModal', true);
    }
  });

  _exports.default = _default;
});