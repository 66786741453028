define("coffeecup/models/validation", ["exports", "ember-data", "coffeecup/models/-super-model", "coffeecup/config/environment"], function (_exports, _emberData, _superModel, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _superModel.default.extend({
    validatedBy: belongsTo('user', {
      async: true
    }),
    user: belongsTo('user', {
      async: true
    }),
    week: attr('datestamp'),
    state: attr('string', {
      defaultValue: _environment.default.constants.VALIDATION_OPEN
    }),
    createdAt: attr('utc-moment'),
    remindedAt: attr('utc-moment'),
    localizableState: Ember.computed('state', function () {
      return this.get('state').toLowerCase();
    }),
    isClosed: Ember.computed.or('isApproved', 'isRejected'),
    isNotApproved: Ember.computed.not('isApproved'),
    isApproved: Ember.computed.equal('state', _environment.default.constants.VALIDATION_APPROVED),
    isOpen: Ember.computed.equal('state', _environment.default.constants.VALIDATION_OPEN),
    isRejected: Ember.computed.equal('state', _environment.default.constants.VALIDATION_REJECTED),
    isSubmitted: Ember.computed.equal('state', _environment.default.constants.VALIDATION_SUBMITTED),
    isAutomatic: Ember.computed('validatedBy', function () {
      return Number(this.belongsTo('validatedBy').id()) === -1;
    })
  });

  _exports.default = _default;
});