define("coffeecup/templates/forgot-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JJXEe3rB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,5,\"color: #FFFFFF\"],[12],[2,\"Link gesendet\"],[13],[2,\" \"],[3,\" TODO template \"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/forgot-password.hbs"
    }
  });

  _exports.default = _default;
});