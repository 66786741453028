define("coffeecup/templates/components/planner/nav-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kD8g/qvD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[6,[37,0],null,null,[[\"default\"],[{\"statements\":[[18,1,null]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"navbar-top/left\",\"plannerActive\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/nav-bar.hbs"
    }
  });

  _exports.default = _default;
});