define("coffeecup/templates/components/date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nbKwAYlc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,\"disabled\",[34,6]],[24,0,\"btn btn-noborder switch-btn btn-left\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"jump\",\"prev\"],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon-arrow-67 small\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"a\"],[14,0,\"btn btn-noborder date-range-picker\"],[12],[2,\"\\n\"],[6,[37,5],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"h4\"],[12],[2,\"\\n\"],[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[34,3]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"h3\"],[12],[1,[34,2]],[13],[10,\"br\"],[12],[13],[2,\"\\n    \"],[1,[34,3]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,5],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,\"disabled\",[34,0]],[24,0,\"btn btn-noborder switch-btn btn-right\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"jump\",\"next\"],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon-arrow-68 small\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"nextDisabled\",\"action\",\"formattedDateRange\",\"intervalTitle\",\"rangeType\",\"if\",\"prevDisabled\",\"showPagination\",\"isSizeSmall\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/date-range-picker.hbs"
    }
  });

  _exports.default = _default;
});