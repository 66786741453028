define("coffeecup/templates/components/planner/week-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "49Uckrwb",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"week-picker\"],[4,[38,0],[[32,0,[\"onInsert\"]]],null],[4,[38,1],[[32,0,[\"onDestroy\"]]],null],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"week-picker-date\"],[12],[2,\"\\n    \"],[1,[32,0,[\"label\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"icon icomoon-calendar\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"will-destroy\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/week-picker.hbs"
    }
  });

  _exports.default = _default;
});