define("coffeecup/routes/planner/absences/scheduler", ["exports", "coffeecup/routes/planner/absences/calendar", "coffeecup/config/environment", "coffeecup/utils/string"], function (_exports, _calendar, _environment, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _createForOfIteratorHelper(o) { if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) { var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var it, normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _calendar.default.extend({
    setupController: function setupController(controller, context
    /*, transition*/
    ) {
      controller.set('existingTags', this.store.peekAll('tag'));
      controller.set('tagAssignments', this.store.peekAll('tag-assignment'));

      if (controller.get('filterTerm')) {
        var filterTerms = (0, _string.purify)(controller.get('filterTerm')).split(',');
        var allTags = this.store.peekAll('tag');
        controller.set('selectedTags', []);

        var _iterator = _createForOfIteratorHelper(filterTerms),
            _step;

        try {
          var _loop = function _loop() {
            var filterTerm = _step.value;
            var existingTag = allTags.find(function (tag) {
              return tag.get('label').toLowerCase() === filterTerm;
            });

            if (existingTag) {
              controller.get('selectedTags').pushObject(existingTag);
            } else {
              controller.send('createInlineTag', filterTerm);
            }
          };

          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            _loop();
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }

        controller.send('selectTag');
      }

      this._super.apply(this, arguments);
    },
    queryParams: {
      year: {
        refreshModel: true
      },
      teamId: {
        refreshModel: true
      }
    },
    timeUnit: 'year',
    calendarUrlParamFormat: _environment.default.constants.DATE_YEAR_PICKER_FORMAT
  });

  _exports.default = _default;
});