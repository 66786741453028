define("coffeecup/templates/components/manage-table/cells/user-employment-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KCJTy+lu",
    "block": "{\"symbols\":[\"barStyle\"],\"statements\":[[10,\"div\"],[14,0,\"manage-table-cell\"],[12],[2,\"\\n\"],[6,[37,2],[[32,0,[\"isTeam\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"multi-pill-container\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"teamTypeBarStyle\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"multi-pill-bar \",[32,1,[\"className\"]]]]],[15,5,[32,1,[\"style\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"manage-table-pill no-hover \",[32,0,[\"pillClass\"]]]]],[14,4,\"div\"],[12],[2,\"\\n      \"],[1,[32,0,[\"pillLabel\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/user-employment-type.hbs"
    }
  });

  _exports.default = _default;
});