define("coffeecup/templates/components/manage-table/cells/project-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c+1QBE/k",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[11,\"div\"],[24,0,\"manage-table-cell project-progress-cell\"],[16,\"role\",[30,[36,1],[[32,0,[\"isProject\"]],\"button\"],null]],[4,[38,0],[\"click\",[32,0,[\"stopPropagation\"]]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isClient\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[32,0,[\"progress\"]],\"%\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,0,[\"isEditing\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"project-progress-select-container\"],[12],[2,\"\\n      \"],[8,\"input/cc-numerical-select\",[],[[\"@options\",\"@onchange\",\"@onclose\",\"@initiallyOpened\",\"@searchEnabled\",\"@focus\",\"@selected\",\"@key\"],[[32,0,[\"progressOptions\"]],[32,0,[\"onSelect\"]],[32,0,[\"stopEditing\"]],true,false,\"project-progress\",[32,0,[\"progress\"]],\"project-progress\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"project-progress-parent\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[32,0,[\"startEditing\"]]],null],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"project-progress-percent\"],[12],[2,\"\\n        \"],[1,[32,0,[\"progress\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"project-progress-container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"project-progress-bar\"],[15,5,[32,0,[\"progressStyle\"]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-progress.hbs"
    }
  });

  _exports.default = _default;
});