define("coffeecup/instance-initializers/language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'language',
        id: 'en_EN',
        attributes: {
          key: 'english'
        }
      }, {
        type: 'language',
        id: 'de_DE',
        attributes: {
          key: 'german'
        }
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'language',
    after: 'ember-simple-auth',
    initialize: initialize
  };
  _exports.default = _default;
});