define("coffeecup/routes/planner/absences", ["exports", "coffeecup/routes/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superRoute.default.extend({
    currentUser: Ember.inject.service('currentUser'),
    permissions: Ember.inject.service(),
    team: Ember.inject.service(),
    titleToken: function titleToken() {
      return this.get('i18n').t('absence');
    },
    setupController: function setupController(controller, model) {
      var applicationController = this.controllerFor('application');
      var currentUser = applicationController.get('currentUser');
      controller.set('requestsOnly', !currentUser.get('isAdmin') && !this.get('permissions.canManageAbsenceRequests') && !this.get('permissions.canManageTeamAbsences'));
      var absenceRequests = Ember.A([]);
      model.absenceRequests.forEach(function (a) {
        return absenceRequests.pushObject(a);
      });

      if (absenceRequests.findBy('state', null)) {
        controller.set('isPanelOpen', true);
      }

      controller.set('absenceRequests', absenceRequests);
    },
    _handleSocketNotifications: Ember.on('init', function () {
      var sailsSocket = this.get('sailsSocket');
      sailsSocket.on('absencerequest.destroyed', this, function (context) {
        var controller = this.get('controller');

        if (controller && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absenceRequests');
          var deletedEntry = absences.findBy('id', id);

          if (deletedEntry) {
            controller.send('absenceRequestDeleted', {
              entry: deletedEntry
            });
          }
        }
      });
      sailsSocket.on('absencerequest.updated', this, function (context) {
        var controller = this.get('controller');

        if (controller && context.id) {
          var id = '' + context.id;
          var absences = controller.get('absenceRequests');
          var updatedEntry = absences.findBy('id', id);

          if (updatedEntry) {
            controller.send('absenceRequestUpdated', {
              entry: updatedEntry
            });
          }
        }
      });
    }),
    model: function model() {
      var absenceRequests = this.store.query('absence-request', {
        sort: 'startDate DESC',
        limit: 1000,
        where: {
          or: [{
            startDate: {
              '>=': this.get('clock.year').toJSON()
            }
          }]
        }
      });
      return Ember.RSVP.hash({
        absenceRequests: absenceRequests
      });
    },
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var upstreamPromise = this._super.apply(this, arguments);

      return Ember.RSVP.resolve(upstreamPromise).then(function () {
        if (transition.targetName === 'planner.absences.index') {
          if (_this.get('permissions.canAccessUsers')) {
            _this.transitionTo('planner.absences.scheduler');
          } else {
            _this.transitionTo('planner.absences.calendar');
          }
        }
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'planner.absences.index') {
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});