define("coffeecup/models/project", ["exports", "coffeecup/config/environment", "ember-data", "coffeecup/models/-super-model", "coffeecup/mixins/create-short-tag", "coffeecup/utils/format-duration", "coffeecup/utils/strip-tags", "coffeecup/utils/streamed-array-proxy", "coffeecup/utils/date", "coffeecup/utils/allocation-math", "coffeecup/mixins/cascade-activation"], function (_exports, _environment, _emberData, _superModel, _createShortTag, _formatDuration, _stripTags, _streamedArrayProxy, _date, _allocationMath, _cascadeActivation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Project = _superModel.default.extend(_createShortTag.default, _cascadeActivation.default, {
    changeTracker: {
      except: ['analytics']
    },
    // properties
    name: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    billBy: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.BILL_BY_PROJECT
    }),
    budgetBy: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.BUDGET_BY_TOTAL_AMOUNT
    }),
    hourlyRate: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    budget: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    budgetHours: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    roundingType: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.ROUNDING_TYPE_NONE
    }),
    roundingAmount: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.ROUNDING_AMOUNT_5
    }),
    status: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.STATUS_ENABLED
    }),
    contact: _emberData.default.belongsTo('contact', {
      async: true
    }),
    startDate: _emberData.default.attr('datestamp'),
    endDate: _emberData.default.attr('datestamp'),
    completedAt: _emberData.default.attr('utc-moment'),
    progress: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // relations
    // expenses: DS.hasMany('expense'),
    // timeEntries: DS.hasMany('time-entry', {async: true}),
    color: _emberData.default.belongsTo('color', {
      async: true
    }),
    client: _emberData.default.belongsTo('client', {
      async: true,
      cascadeActivation: true
    }),
    projectState: _emberData.default.belongsTo('project-state', {
      async: true
    }),
    userAssignments: _emberData.default.hasMany('user-assignment', {
      async: true,
      cascadeDelete: true
    }),
    taskAssignments: _emberData.default.hasMany('task-assignment', {
      async: true,
      cascadeDelete: true
    }),
    invoices: _emberData.default.hasMany('invoice', {
      async: true,
      cascadeDelete: true
    }),
    projectReferencePlatforms: _emberData.default.hasMany('project-reference-platform', {
      async: true,
      cascadeDelete: true
    }),
    plannerAssignments: function () {
      return _streamedArrayProxy.default.create({
        content: Ember.A()
      });
    }.property(),
    plannerAssignmentsUpdatedAtHash: 0,
    // sum of updatedAt
    plannerAssignmentsLastUpdatedObserver: Ember.observer('plannerAssignments', 'plannerAssignments.@each.updatedAt', function () {
      var plannerAssignments = this.get('plannerAssignments');
      this.set('plannerAssignmentsUpdatedAtHash', plannerAssignments.reduce(function (sum, curr) {
        return curr.get('updatedAt').valueOf() + sum;
      }, 0));
    }),
    analytics: _emberData.default.belongsTo('analytics/project', {
      async: false
    }),
    plannerAnalytics: function () {
      return _streamedArrayProxy.default.create({
        content: Ember.A()
      });
    }.property(),
    milestones: function () {
      return _streamedArrayProxy.default.create({
        content: Ember.A()
      });
    }.property(),
    plannedHoursTotal: Ember.computed('plannerAnalytics', 'plannerAnalytics.[]', 'plannerAnalytics.@each.hoursPlanned', {
      get: function get() {
        var plannerAnalytics = this.get('plannerAnalytics');

        if (plannerAnalytics && typeof plannerAnalytics.reduce !== 'undefined') {
          return plannerAnalytics.reduce(function (sum, hours) {
            return _allocationMath.default.addAllocation(sum, hours.get('hoursPlanned'));
          }, 0);
        }
      }
    }),
    // computed properties
    label: Ember.computed.alias('name'),
    // private properties
    // isProjectManager is private and will not be pushed to the api
    isProjectManager: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    uppercaseCode: Ember.computed('code', {
      get: function get() {
        var code = this.get('code');
        return code ? code.toUpperCase() : '';
      }
    }),
    codeHTML: Ember.computed('uppercaseCode', {
      get: function get() {
        return Ember.String.htmlSafe('<div style="background-color: ' + this.get('color.hexString') + '" class="task-label-item label label-default small expandable code-label text-overflow"><span class="icomoon-folder"></span> ' + (0, _stripTags.default)(this.get('uppercaseCode')) + '</div>');
      }
    }),
    fullNameWithHiddenCodeHTML: Ember.computed('fullName', 'code', {
      get: function get() {
        return Ember.String.htmlSafe((0, _stripTags.default)(this.get('fullName')) + '<h>' + (0, _stripTags.default)(this.get('code')) + '</h>');
      }
    }),
    fullNameWithHiddenCodeAndClientHTML: Ember.computed('fullName', 'code', 'clientName', 'clientCode', {
      get: function get() {
        return Ember.String.htmlSafe((0, _stripTags.default)(this.get('fullName')) + '<h>' + (0, _stripTags.default)(this.get('code')) + (0, _stripTags.default)(this.get('clientName')) + (0, _stripTags.default)(this.get('clientCode')) + '</h>');
      }
    }),
    fullNameWithCodeHTML: Ember.computed('uppercaseCode', 'fullName', {
      get: function get() {
        return Ember.String.htmlSafe((0, _stripTags.default)(this.get('fullName')) + this.get('codeHTML'));
      }
    }),
    shortNameWithCodeHTML: Ember.computed('uppercaseCode', 'shortName', {
      get: function get() {
        return Ember.String.htmlSafe((0, _stripTags.default)(this.get('shortName')) + ' ' + this.get('codeHTML'));
      }
    }),
    fullNameWithBillingTypeHTML: Ember.computed('uppercaseCode', 'fullName', 'budgetBy', 'billBy', function () {
      return Ember.String.htmlSafe((0, _stripTags.default)(this.get('fullName')) + this.get('billingTypeHTML'));
    }),
    fullNameWithSecondLineHTML: Ember.computed('uppercaseCode', 'fullName', 'budgetBy', 'billBy', function () {
      var i18n = this.get('i18n');
      var billingType = 'byEffort';

      if (this.get('isModeFixedBudget')) {
        billingType = 'fixedBudget';
      }

      return Ember.String.htmlSafe("\n      <div class=\"list-title\">".concat((0, _stripTags.default)(this.get('fullName')), "</div>\n      <div class=\"list-subtitle\">").concat(i18n.t(billingType), "</div>\n    "));
    }),
    billingTypeHTML: Ember.computed('uppercaseCode', function () {
      var billingType = 'byEffort';

      if (this.get('isModeFixedBudget')) {
        billingType = 'fixedBudget';
      }

      return Ember.String.htmlSafe("\n      <span class=\"label label-default code-label label-outline text-overflow mini expandable ember-view\">\n          ".concat((0, _stripTags.default)(this.get('i18n').t(billingType)), "\n      </span>\n    "));
    }),
    shortName: Ember.computed('name', {
      get: function get() {
        var name = this.get('name');

        if (typeof name !== 'undefined' && name.length > 20) {
          name = name.substring(0, 20) + '...';
        }

        return name;
      }
    }),
    fullName: Ember.computed('name', {
      get: function get() {
        var name = this.get('name');

        if (!name) {
          if (Ember.isNone(this.get('id'))) {
            return '(New Project)';
          } else {
            return '(N.A.)';
          }
        }

        return name;
      }
    }),
    budgetInPersonDays: Ember.computed('budgetHours', 'i18n.locale', {
      get: function get() {
        return (0, _formatDuration.formatAsPersonDays)(this.get('budgetHours'), _environment.default.constants.STANDARD_WORKDAY_HOURS) + ' ' + this.get('i18n').t('abbr.personDays');
      }
    }),
    isInactive: Ember.computed.not('isActive'),
    isActive: Ember.computed('status', 'completedAt', function () {
      return this.get('status') === _environment.default.constants.STATUS_ENABLED && !this.get('completedAt');
    }),
    isCompleted: Ember.computed.bool('completedAt'),
    isArchived: Ember.computed.equal('status', _environment.default.constants.STATUS_ARCHIVED),
    expensesNotWithinBudget: Ember.computed.alias('analytics.expensesNotWithinBudget'),
    amountBudgetTotal: Ember.computed.alias('analytics.amountBudgetTotal'),
    hoursBudget: Ember.computed.alias('analytics.hoursBudget'),
    averageHourlyRate: Ember.computed('userAssignments.[]', 'taskAssignments.[]', 'hourlyRate', 'billBy', function () {
      if (this.get('isBillByUser')) {
        var totalHourlyRate = this.get('userAssignments').reduce(function (prev, el) {
          return prev + el.get('hourlyRate');
        }, 0);
        return totalHourlyRate / this.get('userAssignments.length');
      } else if (this.get('isBillByTask')) {
        var _totalHourlyRate = this.get('taskAssignments').reduce(function (prev, el) {
          return prev + el.get('hourlyRate');
        }, 0);

        return _totalHourlyRate / this.get('taskAssignments.length');
      } else {
        return this.get('hourlyRate');
      }
    }),
    isModeFixedBudget: Ember.computed('budgetBy', 'billBy', function () {
      var isByAmount = this.get('isBudgetByTotalAmount') && (this.get('isBillByProject') || this.get('isBillByTask') || this.get('isBillByUser'));
      var isByHours = this.get('isBudgetByTotalHours') && this.get('isBillByProject');
      var isByTask = this.get('isBudgetByTask') && (this.get('isBillByProject') || this.get('isBillByTask'));
      return isByAmount || isByHours || isByTask;
    }),
    isModeByEffort: Ember.computed('budgetBy', 'billBy', function () {
      return this.get('isBudgetByEffort') && (this.get('isBillByProject') || this.get('isBillByTask') || this.get('isBillByUser'));
    }),
    // isModeNotBillable: alias('isBillByNone'),
    isModeNotBillable: Ember.computed('budgetBy', 'billBy', function () {
      return (this.get('isBudgetByTotalHours') || this.get('isBudgetByTask') || this.get('isBudgetByEffort')) && this.get('isBillByNone');
    }),
    isBudgetByTotalHours: Ember.computed('budgetBy', {
      get: function get() {
        return this.get('budgetBy') === _environment.default.constants.BUDGET_BY_TOTAL_HOURS;
      }
    }),
    isBudgetByTotalAmount: Ember.computed('budgetBy', {
      get: function get() {
        return this.get('budgetBy') === _environment.default.constants.BUDGET_BY_TOTAL_AMOUNT;
      }
    }),
    isBudgetByUser: Ember.computed('budgetBy', {
      get: function get() {
        return this.get('budgetBy') === _environment.default.constants.BUDGET_BY_USER;
      }
    }),
    isBudgetByTask: Ember.computed('budgetBy', {
      get: function get() {
        return this.get('budgetBy') === _environment.default.constants.BUDGET_BY_TASK;
      }
    }),
    isBudgetByEffort: Ember.computed('budgetBy', {
      get: function get() {
        return this.get('budgetBy') === _environment.default.constants.BUDGET_BY_EFFORT;
      }
    }),
    isBillByClient: Ember.computed('billBy', {
      get: function get() {
        return this.get('billBy') === _environment.default.constants.BILL_BY_CLIENT;
      }
    }),
    isBillByProject: Ember.computed('billBy', {
      get: function get() {
        return this.get('billBy') === _environment.default.constants.BILL_BY_PROJECT;
      }
    }),
    isBillByTask: Ember.computed('billBy', {
      get: function get() {
        return this.get('billBy') === _environment.default.constants.BILL_BY_TASK;
      }
    }),
    isBillByUser: Ember.computed('billBy', {
      get: function get() {
        return this.get('billBy') == _environment.default.constants.BILL_BY_USER;
      }
    }),
    isBillByNone: Ember.computed('billBy', {
      get: function get() {
        return this.get('billBy') == _environment.default.constants.BILL_BY_NONE;
      }
    }),
    isNotBillable: Ember.computed.and('isBillByNone', 'isBudgetByEffort'),
    isBillable: Ember.computed.not('isNotBillable'),
    isInternal: Ember.computed('client', 'client.id', {
      get: function get() {
        return !(this.get('client') && this.get('client.id'));
      }
    }),
    isCompletedFixedBudgetProject: Ember.computed.and('completedAt', 'isModeFixedBudget'),
    clientName: Ember.computed('isInternal', 'client.name', 'i18n.locale', {
      get: function get() {
        var client = this.get('client');
        var isInternal = this.get('isInternal');

        if (isInternal) {
          return this.get('i18n').t('internalProject');
        } else {
          return client.get('fullName');
        }
      }
    }),
    clientCode: Ember.computed('isInternal', 'client.code', function () {
      var client = this.get('client');
      var isInternal = this.get('isInternal');

      if (isInternal) {
        return '';
      } else {
        return client.get('code');
      }
    }),
    activeTaskAssignments: Ember.computed.filterBy('taskAssignments', 'status', _environment.default.constants.STATUS_ENABLED),
    startDateBusinessDay: Ember.computed('startDate', 'endDate', {
      get: function get() {
        var startDate = (0, _date.isDateValid)(this.get('startDate')) && this.get('startDate').clone();
        var endDate = (0, _date.isDateValid)(this.get('endDate')) && this.get('endDate').clone();

        if (!endDate || !startDate) {
          return this.get('startDate');
        }

        if (startDate.isWeekend()) {
          startDate.nextBusinessDay();
        }

        if (endDate.isWeekend()) {
          endDate.prevBusinessDay();
        }

        if (startDate.isSameOrBefore(endDate)) {
          return startDate;
        } else {
          return false;
        }
      }
    }),
    endDateBusinessDay: Ember.computed('startDate', 'endDate', {
      get: function get() {
        var startDate = (0, _date.isDateValid)(this.get('startDate')) && this.get('startDate').clone();
        var endDate = (0, _date.isDateValid)(this.get('endDate')) && this.get('endDate').clone();

        if (!endDate || !startDate) {
          return this.get('endDate');
        }

        if (startDate.isWeekend()) {
          startDate.nextBusinessDay();
        }

        if (endDate.isWeekend()) {
          endDate.prevBusinessDay();
        }

        if (endDate.isSameOrAfter(startDate)) {
          return endDate;
        } else {
          return false;
        }
      }
    }),
    isBillingFeatureCompatible: Ember.computed('isNotBillable', 'isBudgetByTotalHours', 'isBillByNone', function () {
      return this.get('isModeFixedBudget') || this.get('isModeByEffort');
    }),
    isValid: Ember.computed('validators.@each.isValid', 'userAssignments.[]', 'userAssignments.@each.isValid', 'taskAssignments.[]', 'taskAssignments.@each.isValid', function () {
      var self = this;

      var _isValid = function _isValid(key) {
        return self.get(key).compact().filterBy('isValid', false).get('length') === 0;
      };

      return _isValid('validators') && _isValid('userAssignments') && _isValid('taskAssignments');
    }),
    tagTitle: Ember.computed('shortName', 'clientName', function () {
      return "".concat(this.get('shortName'), " / ").concat(this.get('clientName'));
    }),

    /**
     * @returns {number | null} remaining days of the project till end date. if no end date exits it returns null
     */
    remainingDaysForProject: Ember.computed('endDate', function () {
      var endDate = this.get('endDate');
      if (!endDate) return null;
      return moment(endDate).diff(this.get('clock.now'), 'days');
    }),

    /**
     * @returns {Boolean} true if end date of project is in the past
     */
    isOverDue: Ember.computed.lt('remainingDaysForProject', 0),

    /**
     * @returns {number} number of days from project start till today
     */
    daysSinceProjectStart: Ember.computed('startDate', function () {
      var startDate = this.get('startDate');
      if (!startDate) return 0;
      return this.get('clock.now').diff(moment(startDate), 'days');
    }),

    /**
     * @returns {number} number of weeks between start and end date
     */
    projectDurationInWeeks: Ember.computed('startDate', 'endDate', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      if (!startDate || !endDate) return null;
      return endDate.diff(startDate, 'weeks');
    }),
    projectDurationInDays: Ember.computed('startDate', 'endDate', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      if (!startDate || !endDate) return null;
      return endDate.diff(startDate, 'days');
    }),
    projectElapsedDurationPercent: Ember.computed('projectDurationInDays', 'remainingDaysForProject', 'startDate', 'endDate', function () {
      var startDate = this.get('startDate');
      var endDate = this.get('endDate');
      var duration = this.get('projectDurationInDays');
      if (!startDate || !endDate || !duration) return null;
      var elapsed = this.get('remainingDaysForProject');
      return (duration - elapsed) / duration;
    }),
    projectElapsedDurationPercentWithCompleted: Ember.computed('projectElapsedDurationPercent', function () {
      return this.get('completedAt') ? 1 : this.get('projectElapsedDurationPercent');
    }),
    clone: function clone() {
      return this.getProperties(['name', 'comment', 'billBy', 'budgetBy', 'hourlyRate', 'budget', 'budgetHours', 'color', 'client', 'roundingType', 'roundingAmount', 'startDate', 'endDate']);
    }
  });

  var _default = Project;
  _exports.default = _default;
});