define("coffeecup/templates/components/planner/timeline-overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z5oKi93V",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[10,\"span\"],[14,0,\"icomoon-plus3\"],[12],[13],[2,\" \"],[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"color\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/planner/timeline-overlay.hbs"
    }
  });

  _exports.default = _default;
});