define("coffeecup/models/expense-category", ["exports", "ember-data", "coffeecup/models/custom-icon", "coffeecup/config/environment"], function (_exports, _emberData, _customIcon, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customIcon.default.extend({
    label: _emberData.default.attr('string'),
    unit: _emberData.default.attr('string'),
    pricePerUnit: _emberData.default.attr('number'),
    status: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.STATUS_ENABLED
    }),
    isPricedPerUnit: Ember.computed('pricePerUnit', {
      get: function get() {
        return !!this.get('pricePerUnit');
      },
      set: function set(k, v) {
        if (v === false) {
          this.set('pricePerUnit', null);
        }

        return v;
      }
    }),
    isActive: Ember.computed('status', function () {
      return this.get('status') === _environment.default.constants.STATUS_ENABLED;
    }),
    isInactive: Ember.computed.not('isActive'),
    clone: function clone() {
      return this.getProperties(['label', 'icon', 'color', 'unit', 'pricePerUnit', 'status']);
    }
  });

  _exports.default = _default;
});