define("coffeecup/templates/components/user-avatar-with-indicators", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5RSJ/Gu9",
    "block": "{\"symbols\":[\"@hasBirthdayIndicator\",\"@user\",\"@borderColor\",\"&attrs\",\"@hasPmIndicator\"],\"statements\":[[8,\"tool-tipster\",[],[[\"@content\"],[[32,2,[\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[11,\"div\"],[24,0,\"user-avatar-with-indicators\"],[16,5,[30,[36,0],[[32,3],[30,[36,2],[[30,[36,1],[\"border-color: \",[32,3],\";\"],null]],null],\"\"],null]],[17,4],[12],[2,\"\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"birthday-indicator\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icomoon-special-holiday\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[15,0,[31,[\"pm-indicator primary-background-color \",[30,[36,0],[[32,1],\"move-left\"],null]]]],[12],[2,\"\\n        PM\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[8,\"user-avatar\",[],[[\"@user\",\"@size\"],[[32,2],\"small\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"concat\",\"html-safe\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/user-avatar-with-indicators.hbs"
    }
  });

  _exports.default = _default;
});