define("coffeecup/models/task", ["exports", "coffeecup/config/environment", "ember-data", "coffeecup/models/-super-model", "numeral", "coffeecup/utils/strip-tags"], function (_exports, _environment, _emberData, _superModel, _numeral, _stripTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    status: _emberData.default.attr('number', {
      defaultValue: _environment.default.constants.STATUS_ENABLED
    }),
    label: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    hourlyRate: _emberData.default.attr('number', {
      defaultValue: 100
    }),
    // TODO read from admin settings
    billable: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    favorite: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    color: _emberData.default.belongsTo('color', {
      async: true
    }),
    taskAssignments: _emberData.default.hasMany('task-assignment', {
      async: true,
      cascadeDelete: true
    }),
    // computed properties
    fullName: Ember.computed('label', 'i18n.locale', function () {
      var label = this.get('label');

      if (!label) {
        if (Ember.isNone(this.get('id'))) {
          return '(' + this.get('i18n').t('newTask') + ')';
        } else {
          return this.get('i18n').t('placeholder.empty');
        }
      }

      return label;
    }),
    hourlyRateFmt: Ember.computed('hourlyRate', function () {
      var hourlyRate = this.get('hourlyRate') || 0; // return numeral(hourlyRate).format('$ 0,0.00');

      return (0, _numeral.default)(hourlyRate).format('$ 0.00');
    }),
    uppercaseCode: Ember.computed('code', function () {
      var code = this.get('code');
      return code ? code.toUpperCase() : '';
    }),
    fullNameWithHiddenCodeHTML: Ember.computed('fullName', 'code', function () {
      return Ember.String.htmlSafe((0, _stripTags.default)(this.get('fullName')) + '<h>' + (0, _stripTags.default)(this.get('code')) + '</h>');
    }),
    codeHTML: Ember.computed('uppercaseCode', function () {
      return Ember.String.htmlSafe('<div style="background-color: ' + this.get('color.hexString') + '" class="task-label-item label label-default small expandable code-label text-overflow"><span class="icomoon-square-check"></span> ' + (0, _stripTags.default)(this.get('uppercaseCode')) + '</div>');
    }),
    fullNameWithCodeHTML: Ember.computed('uppercaseCode', 'fullName', function () {
      return Ember.String.htmlSafe((0, _stripTags.default)(this.get('fullName')) + this.get('codeHTML'));
    }),
    isInactive: Ember.computed.not('isActive'),
    isActive: Ember.computed('status', function () {
      return this.get('status') === _environment.default.constants.STATUS_ENABLED;
    }),
    clone: function clone() {
      return this.getProperties(['label', 'hourlyRate', 'billable', 'favorite', 'color']);
    }
  });

  _exports.default = _default;
});