define("coffeecup/models/billing-provider/invoice", ["exports", "coffeecup/models/-super-model", "ember-data/attr"], function (_exports, _superModel, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _superModel.default.extend({
    clock: Ember.inject.service(),
    number: (0, _attr.default)('string'),
    editedAt: (0, _attr.default)('datestamp'),
    documentDate: (0, _attr.default)('datestamp'),
    dueDate: (0, _attr.default)('datestamp'),
    draft: (0, _attr.default)('boolean'),
    clientName: (0, _attr.default)('string'),
    amount: (0, _attr.default)('number'),
    paid: (0, _attr.default)('boolean'),

    /**
     * This is the date we want to associate with the invoice (usually in the UI).
     * This field used to vary based on the status of the invoice.
     */
    associatedDate: Ember.computed.alias('documentDate'),
    overdue: Ember.computed('dueDate', 'clock.day', function () {
      var today = this.get('clock.today');
      var dueDate = this.get('dueDate');
      return dueDate.isValid() && dueDate.isBefore(today);
    }),
    isNearby: Ember.computed('dueDate', 'clock.day', function () {
      var today = this.get('clock.today');
      var dueDate = this.get('dueDate');
      return dueDate.isValid() && Math.abs(dueDate.diff(today, 'days')) < 7;
    })
  });

  _exports.default = _default;
});