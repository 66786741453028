define("coffeecup/templates/components/manage-table/cells/project-remaining-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tVTiDNca",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"classes\"]]],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isProject\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"projectValue\"]],true],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"clientValue\"]],true],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"project-board-fmt\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage-table/cells/project-remaining-hours.hbs"
    }
  });

  _exports.default = _default;
});