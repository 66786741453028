define("coffeecup/routes/account/profile", ["exports", "coffeecup/routes/manage/_super-route"], function (_exports, _superRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //routes/account/profile.js
  var _default = _superRoute.default.extend({
    titleToken: function titleToken() {
      return this.get('i18n').t('profile');
    },
    model: function model() {
      var currentUser = this.getCurrentUser();
      return Ember.RSVP.hash({
        user: this.store.peekRecord('user', currentUser.id)
      });
    },
    setupController: function setupController(controller, context, transition) {
      controller.set('timezones', this.store.peekAll('timezone')); // fixtures

      controller.set('languages', this.store.peekAll('language')); // fixtures

      controller.set('dateFormats', this.store.peekAll('date-format')); // fixtures

      controller.set('beginningOfWeeks', this.store.peekAll('beginning-of-week')); // fixtures

      controller.set('insuranceTypes', this.store.peekAll('insurance-type')); // fixtures

      controller.set('timeEntryBackgroundColorOptions', this.store.peekAll('time-entry-color-option')); // fixtures

      controller.set('initialFooterHideTimeEntries', context.user.get('uiState.footerHideTimeEntries'));
    }
  });

  _exports.default = _default;
});