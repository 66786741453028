define("coffeecup/templates/planner/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gH/4XCvE",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"planner/popover-assignment\",[],[[\"@openModal\",\"@model\",\"@users\",\"@isInitialEdit\",\"@transitionTo\",\"@onDelete\",\"@onAssignToAnotherUser\",\"@afterSave\",\"@afterHide\"],[true,[32,1],[32,0,[\"sortedUsers\"]],[32,0,[\"isInitialEdit\"]],[30,[36,0],[\"transitionToPlanner\"],null],[30,[36,0],[\"deleteAssignment\"],null],[30,[36,0],[\"assignToAnotherUser\"],null],[30,[36,0],[\"createUserAssignmentIfNotExists\"],null],[30,[36,0],[\"refreshPlannerData\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/planner/edit.hbs"
    }
  });

  _exports.default = _default;
});