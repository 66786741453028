define("coffeecup/utils/allocation-math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _round(num) {
    //Math.round(100 * num)
    return 100 * num;
  }

  var allocationMath = {
    addAllocation: function addAllocation(a, b) {
      return (_round(a) + _round(b)) / 100;
    },
    subtractAllocation: function subtractAllocation(a, b) {
      return (_round(a) - _round(b)) / 100;
    },
    multiplyAllocation: function multiplyAllocation(a, b) {
      return _round(a) * b / 100;
    },
    divideAllocation: function divideAllocation(a, b) {
      return _round(a / b) / 100;
    }
  };
  var _default = allocationMath;
  _exports.default = _default;
});