define("coffeecup/utils/model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _isActive(m) {
    return m && m.get('isActive');
  }

  function _isNew(m) {
    return m && m.get('isNew');
  }

  var model = {
    isInactive: function isInactive(m) {
      return !_isActive(m);
    },
    isActive: function isActive(m) {
      return _isActive(m);
    },
    isSaved: function isSaved(m) {
      return !_isNew(m);
    },
    isNew: function isNew(m) {
      return _isNew(m);
    },
    isSavedAndActive: function isSavedAndActive(m) {
      return !_isNew(m) && _isActive(m);
    }
  };
  var _default = model;
  _exports.default = _default;
});