define("coffeecup/templates/time-entries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PC157YbG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"article\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/time-entries.hbs"
    }
  });

  _exports.default = _default;
});