define("coffeecup/utils/stream-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = streamMap;

  function streamMap(arrayProxy, fk, targetArray) {
    var map = targetArray.reduce(function (accum, model) {
      accum.set(model, []);
      return accum;
    }, new Map());
    return {
      push: function push(newItem) {
        var model = newItem.get(fk);
        var arr = map.get(model);

        if (!arr) {
          arr = [];
          map.set(model, arr);
        }

        arr.push(newItem);
      },
      stream: map.forEach.bind(map, function (t, model) {
        if (!model) return;
        model.get(arrayProxy).stream(t);
      })
    };
  }
});