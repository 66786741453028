define("coffeecup/store", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //store.js
  var Store = _emberData.default.Store.extend({
    revision: 1,
    adapter: 'App.ApplicationAdapter'
  });

  var _default = Store;
  _exports.default = _default;
});