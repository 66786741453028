define("coffeecup/templates/time-entries/verifying", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8bAfoCw7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"analytics-time-entries\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"time-entry-validation\"],[12],[2,\"\\n\\n    \"],[19,\"time-entries/verifying/-header\",[]],[2,\"\\n\\n    \"],[10,\"section\"],[14,0,\"has-header scrollable\"],[12],[2,\"\\n\\n      \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":true,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/time-entries/verifying.hbs"
    }
  });

  _exports.default = _default;
});