define("coffeecup/mixins/create-short-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.createShortTag = void 0;
  var TAG_LENGTH_MAX = 5; // TODO when setting a different TAG_LENGTH_MAX, the website should also get the new value for the warning

  var MV_F = 'ąàáäâãåæăćčĉęèéëêĝĥìíïîĵłľńňòóöőôõðøśșšŝťțŭùúüűûñÿýçżźž'; // mutated vowels from

  var MV_T = 'aaaaaaaaaccceeeeeghiiiijllnnoooooooossssttuuuuuunyyczzz'; // mutated vowels to

  var MV_REGEX = new RegExp('[' + MV_F + ']', 'ig');
  /**
   * removes all vowels from a word
   *
   * @param str a string from which all vowels will be removed
   * @returns {*}
   * @private
   */

  var _removeVowels = function _removeVowels(str) {
    return str.replace(/[aeiou]/gi, '');
  };
  /**
   * removes all vowels in a word except the first
   *
   * @param str a string from which vowels will be removed if it is not a starting vowel
   * @returns {*}
   * @private
   */


  var _removeInsignificantVowels = function _removeInsignificantVowels(str) {
    return str.replace(/([a-zA-Z])([aeiou]*)/g, '$1');
  };
  /**
   * if the str is a number then the algorithm will accept up to 5 digits of the string
   *
   * @param str a number or non-number string
   * @param a splice start
   * @param b splice end
   * @returns {*} a number up to 3 digits or a tag from a to b (b excluded)
   * @private
   */


  var _considerNumbers = function _considerNumbers(str, a, b, maxLength) {
    if (!isNaN(str)) {
      return str.slice(0, maxLength);
    }

    return str.slice(a, b);
  };
  /**
   * removes all mutated vowels from a string
   * removes all characters that or not needed for generating the tag
   *
   * @param str a string from which special characters will be removed
   * @returns {*}
   * @private
   */


  var _removeSpecialCharacters = function _removeSpecialCharacters(str) {
    str = str.toLowerCase();
    str = str.replace(MV_REGEX, function ($0) {
      return MV_T.charAt(MV_F.indexOf($0)) || '-';
    }); //console.log(str);

    return str.replace(/[^0-9a-zA-Z\s]/g, '');
  };
  /**
   * removes insignificant vowels and returns the splice of a string
   *
   * @param str string to be prepared
   * @param a splice start
   * @param b splice end
   * @returns {*}
   * @private
   */


  var _tagFragment = function _tagFragment(str, a, b, maxLength) {
    // return _removeVowels(str).slice(a, b);
    str = _removeInsignificantVowels(str);
    return _considerNumbers(str, a, b, maxLength);
  };
  /**
   * generates a tag of two separate strings.
   * this function is used in a special case when the tag is composed from two words.
   * the function decides whether more letters are taken from the first or the second word.
   *
   * @param str1 first word
   * @param str2 second word
   * @private
   */


  var _tag2Fragment = function _tag2Fragment(str1, str2, maxLength) {
    str1 = _tagFragment(str1, 0, maxLength - 1, maxLength);
    str2 = _tagFragment(str2, 0, maxLength - 1, maxLength);
    var len1 = maxLength - Math.min(maxLength / 2, str2.length);
    var len2 = maxLength - Math.min(maxLength / 2 + maxLength % 2, str1.length);
    var tag = str1.slice(0, len1) + str2.slice(0, len2);
    return tag.slice(0, maxLength); // slice again in case of bigger numbers
  };
  /**
   * splits the string when camel-case was found
   *
   * @param str a camel-cased string that will be split at correct positions
   * @returns {*}
   * @private
   */


  var _removeCamelCase = function _removeCamelCase(str) {
    return str.replace(/([a-zäöüß])([A-ZÄÖÜ])/g, '$1 $2');
  };

  var _splitStringNumbers = function _splitStringNumbers(str) {
    return str.replace(/([a-zA-Z])([0-9])/g, '$1 $2');
  };
  /**
   * remove delimiters by replacing them with a space
   * this will cause the algorithm to see '-' and '_' as valid separators
   *
   * @param str the string from which certain delimiters will be removed
   * @returns {*}
   * @private
   */


  var _removeOtherDelimiters = function _removeOtherDelimiters(str) {
    return str.replace(/[-_]/g, ' ');
  };
  /**
   * splits the prepared string and builds the tag with one or up to five tag-fragments
   *
   * @param str the prepared string
   * @returns {*}
   * @private
   */


  var _composeTag = function _composeTag(str, maxLength) {
    var splitStr = str.trim().split(/[\s]+/);
    var len = Math.min(maxLength, splitStr.length);

    if (len === 1) {
      return _tagFragment(str, 0, maxLength);
    }

    if (len === 2) {
      return _tag2Fragment(splitStr[0], splitStr[1], maxLength);
    }

    var tag = '';

    for (var i = 0; i < len; i++) {
      tag += _considerNumbers(splitStr[i], 0, 1);
    }

    return tag.slice(0, maxLength); // slice the result in case of long numbers in the tag
  };
  /**
   * creates a short tag for the given string
   * special characters are removed and noticed as delimiters
   * vowels are removed when they are no starting-vowels
   *
   * @param str the string the tag is created from
   * @param maxLength
   * @param existingTags
   * @param duplicateAppendix
   * @param parentCode
   * @returns {*}
   */


  var _createShortTag = function createShortTag(str, maxLength) {
    var existingTags = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    var duplicateAppendix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
    var parentCode = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

    if (!maxLength) {
      maxLength = TAG_LENGTH_MAX;
    }

    str = _removeCamelCase(str);
    str = _removeSpecialCharacters(str);
    str = _removeOtherDelimiters(str);
    str = _splitStringNumbers(str);
    var parentLabelLength = parentCode ? parentCode.length + 1 : 0;

    var code = _composeTag(str, maxLength - parentLabelLength);

    if (parentCode) {
      code = parentCode + '-' + code;
    }

    if (existingTags && existingTags.length > 0) {
      while (existingTags.indexOf(code) >= 0) {
        code = _composeTag(str, maxLength - parentLabelLength - duplicateAppendix.toString().length);

        if (parentCode) {
          code = parentCode + '-' + code;
        }

        code += duplicateAppendix;
        duplicateAppendix++;
      }
    }

    return code;
  };

  _exports.createShortTag = _createShortTag;

  var _default = Ember.Mixin.create({
    createShortTag: function createShortTag(str, maxLength, existingTags, duplicateAppendix, parentCode) {
      return _createShortTag(str, maxLength, existingTags, duplicateAppendix, parentCode);
    },
    _generatedCodeTag: function _generatedCodeTag() {
      throw new Error("".concat([this], " has to implement _generatedCodeTag() method which is required to generate a Tag."));
    }
  });

  _exports.default = _default;
});