define("coffeecup/utils/ui", ["exports", "coffeecup/utils/ui/i18n", "coffeecup/utils/ui/moment", "coffeecup/utils/ui/numeral", "coffeecup/utils/ui/cldr"], function (_exports, _i18n, _moment, _numeral, _cldr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkForScrollbarPadding = _exports.default = void 0;
  //utils/ui.js
  //import ENV from 'coffeecup/config/environment';
  var UI = {
    /**
     * will set the settings for the given user
     * this includes language, all time and date format settings,
     * budget settings and currency format settings.
     *
     * @param i18n {Object} the ember-i18n service
     * @param user {Object} the user object
     * @param [accountSettings] {Object} the general account settings object with other settings
     */
    setLocales: function setLocales(i18n, user, accountSettings) {
      (0, _i18n.setLanguage)(i18n, user);
      (0, _moment.setTimeFormats)(i18n, user, accountSettings);
      (0, _numeral.setCurrencyFormats)(i18n, user, accountSettings);
      (0, _cldr.setCLDR)(i18n);
    }
  };
  var _default = UI;
  _exports.default = _default;

  var checkForScrollbarPadding = function checkForScrollbarPadding(baseSelector, elementSelector, elementToAdjustSelector) {
    var elementDom = document.querySelector(baseSelector + elementSelector);

    if (elementDom && elementDom.scrollHeight > elementDom.clientHeight && elementDom.offsetWidth > elementDom.scrollWidth) {
      var _document$querySelect;

      (_document$querySelect = document.querySelector(baseSelector + elementToAdjustSelector)) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.classList.add('scrollbar-extra');
    }
  };

  _exports.checkForScrollbarPadding = checkForScrollbarPadding;
});