define("coffeecup/templates/components/onboarding/basic/tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vUa4QDqv",
    "block": "{\"symbols\":[\"@nextStep\"],\"statements\":[[10,\"div\"],[14,0,\"text-center w-100\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"onboardingTracking\"],null]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"radio-group\"],[12],[2,\"\\n    \"],[8,\"input/cc-radiobox\",[],[[\"@value\",\"@name\",\"@onChange\",\"@checked\",\"@translateKey\"],[[32,0,[\"timerModeDuration\"]],\"timerMode\",[30,[36,1],[[32,0,[\"save\"]]],null],[32,0,[\"accountSettings\",\"timerMode\"]],\"timerModeDuration\"]],null],[2,\"\\n    \"],[8,\"input/cc-radiobox\",[],[[\"@value\",\"@name\",\"@onChange\",\"@checked\",\"@translateKey\"],[[32,0,[\"timerModeDurationStartTimeEndTime\"]],\"timerMode\",[30,[36,1],[[32,0,[\"save\"]]],null],[32,0,[\"accountSettings\",\"timerMode\"]],\"timerModeDurationStartTimeEndTime\"]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"form-group checkbox-group\"],[12],[2,\"\\n    \"],[8,\"input/cc-checkbox\",[],[[\"@value\",\"@translateKey\",\"@onChange\"],[[32,0,[\"accountSettings\",\"allowBreaks\"]],\"allowBreaks\",[30,[36,1],[[32,0,[\"save\"]]],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary btn-pill large-pill\"],[24,\"role\",\"button\"],[24,\"aria-pressed\",\"false\"],[24,4,\"button\"],[4,[38,2],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"continue\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/onboarding/basic/tracking.hbs"
    }
  });

  _exports.default = _default;
});