define("coffeecup/routes/time-entries/tracking", ["exports", "coffeecup/utils/date", "coffeecup/routes/_super-route", "coffeecup/config/environment"], function (_exports, _date, _superRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var TimeEntriesRoute = _superRoute.default.extend({
    accountService: Ember.inject.service('account'),
    permissions: Ember.inject.service(),
    session: Ember.inject.service(),
    accountSettings: Ember.computed.alias('accountService.accountSettings'),
    titleToken: function titleToken() {
      return this.get('i18n').t('timeEntries');
    },
    currentUserQueryParam: null,
    queryParams: {
      user: {
        refreshModel: true
      },
      week: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller, context, transition) {
      controller.set('week', context.week);
      controller.set('activeWeek', context.week);
      controller.set('isResizing', false);
      controller.set('isDragging', false);
      controller.set('timeEntries', context.timeEntries.toArray());
      controller.set('user', context.user);
      controller.set('users', context.users);
      controller.set('userAssignments', context.userAssignments);
      controller.set('validation', context.validation && context.validation.get('firstObject'));

      if (controller.get('validation')) {
        this.store.query('validation-log', {
          where: {
            validation: controller.get('validation.id'),
            recipient: controller.get('validation.user.id')
          }
        }).then(function (validationLogs) {
          controller.set('validationLogs', validationLogs);
        });
      }

      controller.set('timeEntryBackgroundColorOptions', this.store.peekAll('time-entry-color-option')); // fixtures
      // Filter out absences that don't belong to the current user (not currently done on server side)

      controller.set('absences', context.absences.filter(function (absence) {
        return absence.get('user.id') === context.user;
      }));

      if (this.get('permissions.plannerActive')) {
        controller.set('plannerAssignments', context.plannerAssignments.filter(function (plannerAssignment) {
          return plannerAssignment.get('user.id') === context.user;
        }));
      }

      if (!this.permissions.canManageAllProjects && context.milestones.length > 0) {
        var projectIds = this.store.peekAll('user-assignment').map(function (userAssignment) {
          return userAssignment.project.get('id');
        });
        controller.milestones = context.milestones.filter(function (milestone) {
          return projectIds.any(function (el) {
            return el === milestone.project.get('id');
          });
        });
      } else {
        controller.milestones = context.milestones;
      }

      controller.set('userHolidays', context.userHolidays);

      this._super.apply(this, [controller, context.timeEntries, transition]);
    },
    model: function model(params, transition) {
      var controller = this.controllerFor('time-entries.tracking');
      controller.set('isLoading', true);
      var week = (0, _date.validateDate)(params.week, controller.get('week'));
      var mode = 'week';
      var from = moment(week, _environment.default.constants.DATE_FORMAT_DAY).startOf(mode);
      var to = from.clone().endOf(mode);
      var applicationController = this.controllerFor('application');
      var timerModeIsStartTimeEndTime = this.get('accountSettings.timerModeIsStartTimeEndTime');
      var currentUser = applicationController.get('currentUser');
      var currentPermissions = applicationController.get('currentPermissions');
      var user = params.user || currentUser.get('id');
      var users = this.store.peekAll('user');
      var userObj = this.store.peekRecord('user', user); // default user only gets to see his own te's

      if (currentPermissions === _environment.default.constants.ROLE_DEFAULT && !this.get('permissions.canViewTeamTimeEntries')) {
        user = currentUser.get('id');
      } // requested user doesn't exist, fall back to currentuser


      if (!users.findBy('id', user)) {
        user = currentUser.get('id');
      }

      controller.set('validation', null);
      this.set('currentUserQueryParam', user);

      if (!user || !from || !to) {
        transition.abort();
        this.transitionTo('index');
      }

      var loadValidationData = this.get('permissions.worktimeValidationActive') && userObj.get('supervisor.id') && (userObj.get('supervisor.id') === currentUser.get('id') || userObj.get('id') === currentUser.get('id') || this.get('permissions.isRootAdmin'));
      var milestoneQuery = {
        day: {
          '>=': from.startOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY),
          '<=': to.endOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY)
        }
      };
      var hashToResolve = {
        user: user,
        week: week,
        users: users,
        projects: this.store.peekAll('project'),
        userAssignments: this.store.peekAll('user-assignment'),
        plannerAssignments: [],
        milestones: this.store.peekAll('user-assignment').get('length') > 0 ? this.store.query('project-milestone', {
          where: milestoneQuery
        }) : [],
        effectiveEmployment: this.store.query('user-employment', {
          sort: 'validFrom ASC',
          where: {
            user: user
          }
        }),
        validation: loadValidationData ? this.store.query('validation', {
          where: {
            week: from.startOf('isoWeek').format(_environment.default.constants.DATE_FORMAT_DAY),
            user: user
          }
        }) : null,
        timeEntries: this.store.query('time-entry', {
          sort: 'day ASC, ' + (timerModeIsStartTimeEndTime ? 'startTime' : 'sorting') + ' ASC',
          limit: 1000,
          // TODO LOAD MORE entries if meta.total is higher than 1000
          where: {
            user: user,
            day: {
              '>=': from.format(_environment.default.constants.DATE_FORMAT_DAY),
              '<=': to.format(_environment.default.constants.DATE_FORMAT_DAY)
            }
          }
        }),
        absences: this.store.query('absence', {
          sort: 'startDate DESC',
          limit: 1000,
          where: {
            or: [{
              user: user,
              startDate: {
                '>=': from.toJSON(),
                '<=': to.toJSON()
              }
            }, {
              user: user,
              endDate: {
                '>=': from.toJSON(),
                '<=': to.toJSON()
              }
            }, {
              user: user,
              startDate: {
                '<': from.toJSON()
              },
              endDate: {
                '>': to.toJSON()
              }
            }]
          }
        }),
        userHolidays: this.store.query('user-holiday', {
          sort: 'date DESC',
          limit: 1000,
          where: {
            date: {
              '>=': from.toJSON(),
              '<=': to.toJSON()
            }
          }
        })
      };

      if (this.get('permissions.plannerActive')) {
        hashToResolve['plannerAssignments'] = this.store.query('planner-assignment', {
          sort: 'startDate DESC',
          limit: 1000,
          where: {
            or: [{
              user: user,
              startDate: {
                '>=': from.toJSON(),
                '<=': to.toJSON()
              }
            }, {
              user: user,
              endDate: {
                '>=': from.toJSON(),
                '<=': to.toJSON()
              }
            }, {
              user: user,
              startDate: {
                '<': from.toJSON()
              },
              endDate: {
                '>': to.toJSON()
              }
            }]
          }
        });
      }

      return Ember.RSVP.hash(hashToResolve);
    },
    actions: {
      editTimeEntry: function editTimeEntry(timeEntry) {
        this.transitionTo('time-entries.tracking.edit', timeEntry.get('id'), {
          queryParams: {
            userId: this.get('currentUserQueryParam')
          }
        });
      },
      newTimeEntry: function newTimeEntry(params) {
        var day = params && params.day && typeof params.day === 'string' ? params.day : this.get('clock.day');
        day = params && params.day && params.day.format && typeof params.day.format === 'function' ? params.day.format(_environment.default.constants.DATE_FORMAT_DAY) : day;
        var dayFmt = moment(day).format(_environment.default.constants.DATE_FORMAT_DAY);
        var queryParams = {
          date: dayFmt,
          userId: this.get('currentUserQueryParam'),
          projectId: null,
          type: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_WORK
        };

        if (params && params.projectId) {
          queryParams.projectId = params.projectId;
        }

        this.transitionTo('time-entries.tracking.new', {
          queryParams: queryParams
        });
      },
      newBreak: function newBreak(params) {
        var day = params && params.day ? params.day : this.get('clock.day');
        var dayFmt = moment(day).format(_environment.default.constants.DATE_FORMAT_DAY);
        this.transitionTo('time-entries.tracking.new', {
          queryParams: {
            date: dayFmt,
            userId: this.get('currentUserQueryParam'),
            type: _environment.default.constants.TIME_ENTRY_TRACKING_TYPE_BREAK
          }
        });
      }
    }
  });

  var _default = TimeEntriesRoute;
  _exports.default = _default;
});