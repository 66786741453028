define("coffeecup/mixins/planner/expandable-rows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ExpandableRows = _exports.ExpandableRow = void 0;
  var ExpandableRow = Ember.Mixin.create({
    classNameBindings: ['isShowingAssignments:expanded-row'],
    actions: {
      toggleAssignments: function toggleAssignments() {
        this.get('isShowingAssignments') ? this.collapseRow() : this.expandRow();
      }
    },
    expandedRows: Ember.computed(function () {
      (false && Ember.warn('You must implement `expandedRows` when using the `ExpandableRow` mixin.', false, {
        id: 'coffeecup.expandable-rows.expanded-rows'
      }));
    }),
    activeModel: Ember.computed(function () {
      (false && Ember.warn('You must implement `activeModel` when using the `ExpandableRow` mixin.', false, {
        id: 'coffeecup.expandable-rows.active-model'
      }));
    }),
    isExpanded: function () {
      return this.get('expandedRows').includes(this.get('model'));
    }.property('expandedRows.[]'),
    isShowingAssignments: Ember.computed.and('isExpanded'),
    isCollapsed: Ember.computed.not('isShowingAssignments'),
    isActiveRow: function () {
      return this.get('activeModel') === this.get('model');
    }.property('activeModel.id', 'model.isLoaded'),
    activeRowObsever: Ember.observer('isActiveRow', 'model.id', function () {
      this.get('isActiveRow') && Ember.run.next(this, 'expandRow');
    }).on('init'),
    expandRow: function expandRow() {
      this.get('expandedRows').addObject(this.get('model'));
      this.afterToggle();
    },
    collapseRow: function collapseRow() {
      this.get('expandedRows').removeObject(this.get('model'));
      this.afterToggle();
    },
    afterToggle: function afterToggle() {
      var $scrollable = Ember.$('.planner .scrollable');
      $scrollable.trigger('refresh');
    }
  });
  _exports.ExpandableRow = ExpandableRow;
  var ExpandableRows = Ember.Mixin.create({
    actions: {
      expandAll: function expandAll() {
        this.get('expandedRows').addObjects(this.get('visibleRows'));
        this.afterToggle();
      },
      collapseAll: function collapseAll() {
        this.get('expandedRows').removeObjects(this.get('visibleRows'));
        this.afterToggle();
      }
    },
    afterToggle: function afterToggle() {
      var $scrollable = Ember.$('.planner .scrollable');
      $scrollable.trigger('refresh');
    },
    visibleRows: Ember.computed(function () {
      (false && Ember.warn('You must implement `visibleRows` when using the `ExpandableRows` mixin.', false, {
        id: 'coffeecup.expandable-rows.visible-rows'
      }));
    }),
    activeModel: Ember.computed('visibleRows.length', {
      get: function get() {
        var size = this.get('visibleRows.length');

        if (size === 1) {
          return this.get('visibleRows.firstObject');
        } else {
          return null;
        }
      }
    }),
    expandedRows: function () {
      return Ember.A();
    }.property(),
    rowsAreExpanded: false,
    _triggerExpandAll: Ember.observer('rowsAreExpanded', function () {
      var rowsAreExpanded = this.get('rowsAreExpanded');

      if (rowsAreExpanded) {
        this.send('expandAll');
      } else {
        this.send('collapseAll');
      }
    })
  });
  _exports.ExpandableRows = ExpandableRows;
});