define("coffeecup/templates/components/input-vacation-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "88OjLE9A",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"label\"],[14,0,\"control-label\"],[15,\"for\",[34,4]],[12],[2,\"\\n    \"],[1,[34,5]],[2,\"\\n\"],[6,[37,6],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],null,[[\"content\"],[[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"controls input-group\"],[12],[2,\"\\n  \"],[1,[30,[36,12],null,[[\"id\",\"type\",\"maxlength\",\"class\",\"disabled\",\"value\",\"placeholder\"],[[35,4],\"text\",[35,11],[35,10],[35,9],[35,8],[35,7]]]]],[2,\"\\n\"],[6,[37,6],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"input-currency\"],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,6],[[35,13]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"input-group-btn input-group-btn-stepper\"],[12],[2,\"\\n      \"],[11,\"a\"],[24,0,\"btn-plus btn btn-default has-border btn-small\"],[4,[38,0],[\"click\",[32,0,[\"add\"]]],null],[12],[10,\"i\"],[14,0,\"icon icomoon-plus3\"],[12],[13],[13],[2,\"\\n      \"],[11,\"a\"],[24,0,\"btn-minus btn btn-default has-border btn-small\"],[4,[38,0],[\"click\",[32,0,[\"sub\"]]],null],[12],[10,\"i\"],[14,0,\"icon-minus-1\"],[12],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"on\",\"currency\",\"tooltipText\",\"t-tip-icon\",\"inputId\",\"label\",\"if\",\"placeholder\",\"duration\",\"disabled\",\"inputClasses\",\"maxlength\",\"input\",\"steps\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/input-vacation-amount.hbs"
    }
  });

  _exports.default = _default;
});