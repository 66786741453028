define("coffeecup/utils/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.intersect = intersect;
  _exports.difference = difference;

  /**
   *
   * @param arrayOne
   * @param arrayTwo
   * @returns {array}
   */
  function intersect() {
    var arrayOne = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    var arrayTwo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

    if (arrayOne && arrayTwo && typeof arrayOne.filter === 'function' && typeof arrayTwo.includes === 'function') {
      return arrayOne.filter(function (e) {
        return arrayTwo.includes(e);
      });
    }

    return [];
  }
  /**
   *
   * @param arrayOne
   * @param arrayTwo
   * @return {{added, removed}}
   */


  function difference(arrayOne, arrayTwo) {
    return {
      added: arrayTwo.reject(function (t) {
        return arrayOne.includes(t);
      }),
      removed: arrayOne.reject(function (e) {
        return arrayTwo.includes(e);
      })
    };
  }
});