define("coffeecup/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R5N6cihG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"style\"],[14,4,\"text/css\"],[12],[2,\"\\n  article.loader { background-color: #028AC0; color: #fff; width: 100%; height: 100%; } .ember-application\\n  article.loader #loader { opacity: 1 }\\n\"],[13],[2,\"\\n\\n\"],[10,\"article\"],[14,0,\"loader\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,1,\"loader\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"smoke\"],[12],[10,\"img\"],[14,\"src\",\"/assets/images/logo-smoke.svg\"],[14,\"alt\",\"Coffee is brewing...\"],[12],[13],[13],[10,\"img\"],[14,\"src\",\"/assets/images/logo-white.svg\"],[14,\"alt\",\"CoffeeCup\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "coffeecup/templates/error.hbs"
    }
  });

  _exports.default = _default;
});