define("coffeecup/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "50QzXM09",
    "block": "{\"symbols\":[],\"statements\":[[10,\"article\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"table-centered\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table-centered-cell\"],[12],[2,\"\\n      \"],[10,\"h1\"],[12],[2,\"404\"],[13],[2,\"\\n      \"],[10,\"h2\"],[12],[1,[30,[36,0],[\"alert.errorOnForbiddenRouteVisit\"],null]],[13],[2,\"\\n      \"],[10,\"p\"],[12],[10,\"a\"],[14,6,\"http://support.coffeecupapp.com\"],[14,\"target\",\"_blank\"],[12],[1,[30,[36,0],[\"contact.support\"],null]],[13],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});