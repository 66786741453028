define("coffeecup/templates/components/labeled-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ANiQFPJ7",
    "block": "{\"symbols\":[\"&default\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[14,0,\"control-label\"],[12],[2,\"\\n  \"],[1,[34,1]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,2],\"default\",[27,[32,1]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"text\",\"label\",\"-has-block\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/labeled-text.hbs"
    }
  });

  _exports.default = _default;
});