define("coffeecup/instance-initializers/i18n", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //initializers/i18n.js
  var _default = {
    name: 'i18n',
    initialize: function initialize(appInstance) {
      var i18n = appInstance.lookup('service:i18n');
      var locale = calculateLocale(i18n.get('locales'));
      i18n.set('locale', locale);
      Ember.I18n = i18n;
    }
  };
  _exports.default = _default;

  function calculateLocale(locales) {
    var defaultLang = 'en';

    if (typeof navigator === 'undefined') {
      return defaultLang;
    }

    try {
      var language = navigator.languages && navigator.languages[0] || navigator.userLanguage || navigator.browserLanguage || navigator.systemLanguage || navigator.language;
      return locales.includes(language.toLowerCase()) ? language : defaultLang;
    } catch (e) {
      return defaultLang;
    }
  }
});