define("coffeecup/utils/ui/moment", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setTimeFormats = setTimeFormats;

  /**
   * will set the time and date formats by user/account settings or
   * the default settings configured in the environment variables
   *
   * @param i18n {Object} the ember-i18n service
   * @param user {Object} the user object
   */
  function setTimeFormats(i18n, user) {
    if (!Ember.isEmpty(i18n)) {
      var locale = i18n.get('locale'); // override language specific settings here
      // set the locale

      var beginningOfWeek = _environment.default.constants.WEEKDAY_MONDAY_VALUE;

      if (!Ember.isEmpty(user)) {
        switch (user.get('beginningOfWeek')) {
          case _environment.default.constants.WEEKDAY_SUNDAY:
            beginningOfWeek = _environment.default.constants.WEEKDAY_SUNDAY_VALUE;
            break;

          case _environment.default.constants.WEEKDAY_MONDAY:
            beginningOfWeek = _environment.default.constants.WEEKDAY_MONDAY_VALUE;
            break;

          case _environment.default.constants.WEEKDAY_SATURDAY:
            beginningOfWeek = _environment.default.constants.WEEKDAY_SATURDAY_VALUE;
            break;
        }
      }

      moment.updateLocale(locale, {
        week: {
          dow: beginningOfWeek
        }
      });
    }
  }
});