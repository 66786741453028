define("coffeecup/mixins/auth-data-adapter-mixin", ["exports", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_dataAdapterMixin.default, {
    /**
     * 401 is often raised after the tab was inactive for so long (esp. on Safari) that the access token expired
     * Instead of invalidating the session immediately, this will reload the page to cause a token refresh and
     * hopefully a sane application state.
     *
     * This overrides the implementation in DataAdapterMixin
     */
    ensureResponseAuthorized: function ensureResponseAuthorized(status
    /* ,headers, payload, requestData */
    ) {
      if (status === 401 && this.get('session.isAuthenticated')) {
        // Wait for the authenticator to settle to avoid loosing
        // a session to a site reload while the token is refreshed
        this.get('session').awaitAuthenticatorIdle().then(function () {
          return document.location.reload();
        });
      }
    }
  });

  _exports.default = _default;
});