define("coffeecup/templates/components/roles/description-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Z5BTggE",
    "block": "{\"symbols\":[\"@label\",\"@permission\",\"@description\"],\"statements\":[[10,\"div\"],[14,0,\"permission-label\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,1]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"permission-description \",[30,[36,1],[[32,2],\"primary-list-icon\"],null]]]],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,3]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/roles/description-row.hbs"
    }
  });

  _exports.default = _default;
});