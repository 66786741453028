define("coffeecup/models/absence-type", ["exports", "ember-data", "coffeecup/models/custom-icon", "coffeecup/config/environment"], function (_exports, _emberData, _customIcon, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customIcon.default.extend({
    label: _emberData.default.attr('string'),
    status: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    baseType: _emberData.default.attr('number'),
    canBeRequested: _emberData.default.attr('boolean'),
    defaultCountsAsVacation: _emberData.default.attr('boolean'),
    defaultWorkHoursExpected: _emberData.default.attr('boolean'),
    detailsVisibleToOtherUsers: _emberData.default.attr('boolean'),
    defaultRemunerationActive: _emberData.default.attr('boolean'),
    defaultReducesOvertime: _emberData.default.attr('boolean'),
    externalId: _emberData.default.attr('string'),
    immutableType: Ember.computed.or('isVacation', 'isIllness', 'isOvertimeReduction', 'isPublicHoliday', 'externalId'),
    order: Ember.computed('baseType', function () {
      if (this.get('isImmutable')) return this.get('baseType');
      return this.get('idAsInt');
    }),
    isImmutable: Ember.computed.reads('immutableType'),
    isVacation: Ember.computed.equal('baseType', _environment.default.constants.ABSENCE_TYPE_VACATION),
    isIllness: Ember.computed.equal('baseType', _environment.default.constants.ABSENCE_TYPE_ILLNESS),
    isOvertimeReduction: Ember.computed.equal('baseType', _environment.default.constants.ABSENCE_TYPE_OVERTIME_REDUCTION),
    isPublicHoliday: Ember.computed.equal('baseType', _environment.default.constants.ABSENCE_TYPE_PUBLIC_HOLIDAY),
    canNotEditCanBeRequested: Ember.computed.or('isVacation', 'isPublicHoliday', 'externalId'),
    canNotEditCountsAsVacation: Ember.computed.or('isVacation', 'isOvertimeReduction', 'isPublicHoliday', 'externalId'),
    canNotEditWorkHoursExpected: Ember.computed.or('isVacation', 'isOvertimeReduction', 'isPublicHoliday'),
    canNotEditVisibleToOthers: Ember.computed.or('isPublicHoliday'),
    canNotEditRemunerationActive: Ember.computed.or('isOvertimeReduction', 'isPublicHoliday'),
    canNotEditReducesOvertime: Ember.computed.or('isOvertimeReduction', 'isPublicHoliday'),
    showBorder: Ember.computed.reads('isOvertimeReduction'),
    isActive: Ember.computed.equal('status', _environment.default.constants.STATUS_ENABLED),
    isInactive: Ember.computed.not('isActive'),
    localizedLabel: Ember.computed('label', function () {
      var i18n = this.get('i18n');
      if (this.get('isVacation')) return i18n.t('vacation');
      if (this.get('isIllness')) return i18n.t('illness');
      if (this.get('isOvertimeReduction')) return i18n.t('overtimeReduction');
      if (this.get('isPublicHoliday')) return i18n.t('publicHoliday');
      return this.get('label');
    }),
    labelWithTypeImageHTML: Ember.computed('localizedLabel', 'typeImageHTML', function () {
      return Ember.String.htmlSafe(this.get('typeImageHTML') + ' ' + this.get('localizedLabel'));
    })
  });

  _exports.default = _default;
});