define("coffeecup/templates/components/absence-tracking/date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y2D3UAEp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"date-picker \",[30,[36,1],[[35,0],\"end-date-picker\"],null]]]],[12],[2,\"\\n  \"],[1,[34,2]],[2,\"\\n  \"],[10,\"span\"],[14,0,\"icon icomoon-calendar\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"minStartDate\",\"if\",\"dateFMT\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/absence-tracking/date-range-picker.hbs"
    }
  });

  _exports.default = _default;
});