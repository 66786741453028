define("coffeecup/templates/components/manage/board-filter-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3/C9JfL1",
    "block": "{\"symbols\":[\"@onHide\",\"&default\",\"@onReset\"],\"statements\":[[8,\"modal-dialog\",[],[[\"@onClose\",\"@onClickOverlay\",\"@overlayPosition\",\"@translucentOverlay\",\"@overlayClassNames\",\"@containerClass\",\"@openModal\"],[[32,1],[32,1],\"sibling\",true,[32,0,[\"overlayClassNames\"]],[32,0,[\"extendedContainerClass\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"arrow\"],[12],[13],[2,\"\\n  \"],[11,\"div\"],[16,0,[31,[\"flexible-modal-wrapper modal-wrapper scrollable \",[30,[36,0],[[32,0,[\"isTransparent\"]],\"transparent\"],null]]]],[4,[38,1],[[32,0,[\"onInsert\"]]],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n      \"],[11,\"div\"],[24,0,\"modal-toggler close\"],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[32,1]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[14,0,\"icon-cross\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"\\n          Close\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"h2\"],[14,5,\"width: fit-content\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"boardFilters\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n      \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-default close-btn pull-left\"],[24,4,\"button\"],[4,[38,2],[\"click\",[32,3]],null],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"reset\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-default close-btn pull-right\"],[24,4,\"button\"],[4,[38,2],[\"click\",[32,1]],null],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"close\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"did-insert\",\"on\",\"t\"]}",
    "meta": {
      "moduleName": "coffeecup/templates/components/manage/board-filter-modal.hbs"
    }
  });

  _exports.default = _default;
});