define("coffeecup/mixins/fiscal-year", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mixin for retrieving information about the fiscal year. Start of fiscal year is determined by account settings.
   *
   * @author Alexander Stonehouse
   */
  var FiscalYearMixin = Ember.Mixin.create({
    formatter: Ember.inject.service(),
    accountService: Ember.inject.service('account'),

    /**
     * @param moment
     * @returns moment reflecting start of quarter
     */
    getStartOfQuarter: function getStartOfQuarter(moment) {
      var quarter = this._getQuarter(moment);

      var fiscalYear = this._getFiscalYear();

      var startOfQuarter = moment.clone().month(fiscalYear[quarter - 1]).startOf('month');
      return startOfQuarter;
    },

    /**
     * @param moment
     * @returns moment reflecting start of fiscal year in which the moment is
     */
    getStartOfYear: function getStartOfYear(moment) {
      var quarter = this._getQuarter(moment);

      var startOfYear = this.getStartOfQuarter(moment);

      for (var i = quarter; i > 1; i--) {
        startOfYear.subtract(3, 'months');
      }

      return startOfYear;
    },
    _getQuarter: function _getQuarter(moment) {
      var fiscalYear = this._getFiscalYear();

      var month = moment.month();

      if (month >= fiscalYear[3] && month <= fiscalYear[3] + 2) {
        return 4;
      } else if (month >= fiscalYear[2] && month <= fiscalYear[2] + 2) {
        return 3;
      } else if (month >= fiscalYear[1] && month <= fiscalYear[1] + 2) {
        return 2;
      } else {
        return 1;
      }
    },
    _getFiscalYear: function _getFiscalYear() {
      var accountSettings = this.get('accountService.accountSettings');
      var quarter1 = accountSettings.get('fiscalYearMonth');
      var quarter2 = quarter1 + 3;

      if (quarter2 > 11) {
        quarter2 = quarter2 - 12;
      }

      var quarter3 = quarter2 + 3;

      if (quarter3 > 11) {
        quarter3 = quarter3 - 12;
      }

      var quarter4 = quarter3 + 3;

      if (quarter4 > 11) {
        quarter4 = quarter4 - 12;
      }

      return [quarter1, quarter2, quarter3, quarter4];
    }
  });
  var _default = FiscalYearMixin;
  _exports.default = _default;
});