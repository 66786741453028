define("coffeecup/models/analytics/project/histogram", ["exports", "ember-data/attr", "ember-data-model-fragments/fragment"], function (_exports, _attr, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // WARNING - This is also probably not used
  var AnalyticsProjectHistogram = _fragment.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    amountSpent: (0, _attr.default)('number'),
    amountNotBilled: (0, _attr.default)('number'),
    date: (0, _attr.default)('date'),
    hoursSpent: (0, _attr.default)('number'),
    hoursTotal: (0, _attr.default)('number'),
    hoursNonBillable: (0, _attr.default)('number'),
    hoursRoundedSpent: (0, _attr.default)('number'),
    hoursRoundedTotal: (0, _attr.default)('number'),
    hoursRoundedNonBillable: (0, _attr.default)('number'),
    userCosts: (0, _attr.default)('number'),
    expensesTotal: (0, _attr.default)('number'),
    expensesBilled: (0, _attr.default)('number'),
    expensesNotBilled: (0, _attr.default)('number'),
    expensesCosts: (0, _attr.default)('number'),
    expensesCostsNotWithinBudget: (0, _attr.default)('number'),
    expensesCostsWithinBudget: (0, _attr.default)('number'),
    timestamp: (0, _attr.default)('date'),
    // TODO, this is not a string, it's a timestamp, check if attr('date') is the correct format for this property
    // amountBilled
    amountSpentTotal: Ember.computed('amountSpent', 'expensesTotal', function () {
      var amountSpent = this.get('amountSpent') || 0;
      var expensesTotal = this.get('expensesTotal') || 0;
      return amountSpent + expensesTotal;
    }),
    totalCosts: Ember.computed('userCosts', 'expensesCosts', function () {
      var expensesCosts = this.get('expensesCosts') || 0;
      var userCosts = this.get('userCosts') || 0;
      return userCosts + expensesCosts;
    }),
    amountNotBilledTotal: Ember.computed('amountNotBilled', 'expensesNotBilled', function () {
      return (this.get('amountNotBilled') || 0) + (this.get('expensesNotBilled') || 0);
    })
  });

  var _default = AnalyticsProjectHistogram;
  _exports.default = _default;
});