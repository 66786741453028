define("coffeecup/serializers/analytics/staff", ["exports", "coffeecup/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    type: 'analytics/staff',
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var _this = this;

      if (!payload) {
        return {
          data: null
        };
      }

      var resp = payload.analyticsStaff.map(function (record) {
        return _this.normalizeObject(store, record, _this.get('type'));
      });
      return {
        data: resp
      };
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, id, requestType) {
      return this.normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType);
    },
    normalizeObject: function normalizeObject(store, record, type) {
      var returnObj = {
        id: Ember.get(record, 'id'),
        type: type,
        relationships: {
          user: {
            data: {
              type: 'user',
              id: Ember.get(record, 'user')
            }
          }
        },
        attributes: {
          // @ts-ignore
          totalHours: Ember.get(record, 'hours.totalHours') || 0,
          // @ts-ignore
          totalBillableHours: Ember.get(record, 'hours.totalBillableHours') || 0
        }
      }; // TODO: Process the histogram

      if (Ember.get(record, 'planned')) {
        var that = this;
        returnObj.attributes.planned = (Ember.get(record, 'planned') || []).map(function (record) {
          return that.normalizePlanned(store, record);
        });
      }

      return returnObj;
    },
    normalizePlanned: function normalizePlanned(store, record) {
      return Ember.Object.extend({
        id: Ember.get(record, 'id'),
        user_id: Ember.get(record, 'id'),
        project_id: Ember.get(record, 'project'),
        hourlyRate: Ember.get(record, 'hourlyRate'),
        effectiveHourlyRate: Ember.get(record, 'effectiveHourlyRate'),
        // @ts-ignore
        trackedHours: Ember.get(record, 'hours.tracked'),
        // @ts-ignore
        plannedHours: Ember.get(record, 'hours.planned'),
        user: Ember.computed('user_id', {
          get: function get() {
            return store.peekRecord('user', this.get('user_id'));
          }
        }),
        project: Ember.computed('project_id', {
          get: function get() {
            return store.peekRecord('project', this.get('project_id'));
          }
        })
      }).create();
    }
  });

  _exports.default = _default;
});