define("coffeecup/models/tag-assignment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    model: _emberData.default.attr('string'),
    record: _emberData.default.attr('number'),
    tag: _emberData.default.belongsTo('tag', {
      async: true
    }),
    clone: function clone() {
      return this.getProperties(['tag', 'model', 'record']);
    }
  });

  _exports.default = _default;
});