define("coffeecup/instance-initializers/currency-format", ["exports", "coffeecup/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    var store = appInstance.lookup('service:store');
    var pushData = {
      data: [{
        type: 'currency-format',
        id: _environment.default.constants.CURRENCY_FORMAT_SIGN_SUFFIX
      }, {
        type: 'currency-format',
        id: _environment.default.constants.CURRENCY_FORMAT_SIGN_PREFIX
      }]
    };
    store.push(pushData);
  }

  var _default = {
    name: 'currency-format',
    after: 'language',
    initialize: initialize
  };
  _exports.default = _default;
});