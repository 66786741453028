define("coffeecup/models/analytics/project_histogram/histogram", ["exports", "ember-data/attr", "ember-data-model-fragments/fragment"], function (_exports, _attr, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //models/analytics/project_histogram/histogram.js
  // WARNING - This is UNUSED
  var AnalyticsProjectHistogram = _fragment.default.extend({
    changeTracker: {
      only: []
    },
    // don't track analytic changes
    date: (0, _attr.default)('date'),
    timestamp: (0, _attr.default)('date'),
    // TODO, this is not a string, it's a timestamp, check if attr('date') is the correct format for this property
    hoursTotal: (0, _attr.default)('number'),
    hoursSpent: (0, _attr.default)('number'),
    hoursNonBillable: (0, _attr.default)('number'),
    amountSpent: (0, _attr.default)('number'),
    costs: (0, _attr.default)('number'),
    expensesTotal: DS.attr('number'),
    expensesBilled: DS.attr('number'),
    expensesNotBilled: DS.attr('number'),
    expensesWithinBudget: DS.attr('number'),
    expensesNotWithinBudget: DS.attr('number'),
    expensesCosts: DS.attr('number'),
    expensesCostsWithinBudget: DS.attr('number'),
    expensesCostsNotWithinBudget: DS.attr('number')
  });

  var _default = AnalyticsProjectHistogram;
  _exports.default = _default;
});